import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { AppConstants } from '../../../constants/AppConstants';
import { DataService } from '../../../services/DataService';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { LEARNER_PROFILE } from '../../../constants/AppConstants';
import dayjs from 'dayjs';
import formClass from '../../../common/FormStyling.module.css';
import classes from './AddRecommendationModal.module.css';
import ReactQuill, { Quill } from 'react-quill';

const AddRecommendationModal = ({
  show,
  handleClose,
  triggerRefresh,
  recommendationDetails,
  updateRecommendations,
  learnerSlug,
  initialRecommendationDetails,
  isUpdating,
  onSuccess,
  onError,
}) => {
  const [responseText, setResponseText] = useState(
    initialRecommendationDetails?.recommendation
      ? initialRecommendationDetails?.recommendation
      : AppConstants.emptyEditorString
  );
  const [skills, setSkills] = useState([]);
  const [payload, setPayload] = useState({});
  const reactQuillRef = useRef();

  useEffect(() => {
    if (initialRecommendationDetails?.recommendation) {
      setResponseText(initialRecommendationDetails.recommendation);
    } else {
      setResponseText(AppConstants.emptyEditorString);
    }
  }, [initialRecommendationDetails]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const url = AppConstants.getSkillsDataAPI;
        const response = await DataService.get(
          url,
          AppConstants.emptyString,
          AppConstants.emptyString
        );
        if (response) {
          const sortedSkills = response.sort((a, b) => {
            if (a.skill < b.skill) return -1;
            if (a.skill > b.skill) return 1;
            return 0;
          });
          setSkills(sortedSkills || []);
        }
      } catch (error) {
        console.error('Error fetching skills:', error);
        setSkills([]);
      }
    };

    fetchSkills();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setPayload((prevState) => ({
        ...prevState,
        ...values,
      }));

      let postPayload = {
        activityCategory: 'Recommendations',
        organizedByExpertName: values.expertName,
        organizedByExpertWebsiteLink: values.expertWebsite,
        recommendation: responseText,
        recommendedOn: values.citationDate,
        skillId: values.skillName,
        organizedByExpertDesignation: values.expertDesignation,
      };

      if (initialRecommendationDetails) {
        postPayload.id = initialRecommendationDetails.id;
      }

      try {
        const response = await AuthorizedDataService.post(
          LEARNER_PROFILE.addSelfDeclaredRecommendations,
          postPayload,
          '',
          '',
          ''
        );

        if (response) {
          // triggerRefresh();
          let url = `${AppConstants.fetchRecommendationsForLearnerProfile}/${learnerSlug}?timestamp=${Date.now()}`;
          const freshData = await DataService.get(url, '', '');

          if (freshData !== undefined) {
            updateRecommendations(freshData);
          }
          setResponseText(AppConstants.emptyEditorString);
          onSuccess(initialRecommendationDetails?.id);
        }
      } catch (error) {
        onError(initialRecommendationDetails?.id);
        console.error('Error saving recommendation', error);
      }

      handleClose();
    } catch (error) {
      onError(initialRecommendationDetails?.id);
      console.error('Error saving recommendation', error);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    citation: Yup.string().required('Recommendation is required'),
    citationDate: Yup.date()
      .required('Recommended On is required')
      .max(new Date(), 'Date must be in the past'),
    expertName: Yup.string().trim().required('Expert Name is required'),
    expertDesignation: Yup.string()
      .trim()
      .required('Expert Designation is required'),
    skillName: Yup.string().required('Skill is required'),
    expertWebsite: Yup.string()
      .test('is-valid-url', 'Enter a valid URL', (value) => {
        if (!value) return true;
        const urlPattern =
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(\/\S*)?$/;
        return urlPattern.test(value);
      })
      .nullable(),
  });
  const handleCloseModal = () => {
    if (initialRecommendationDetails?.recommendation) {
      setResponseText(initialRecommendationDetails?.recommendation);
    } else {
      setResponseText(AppConstants.emptyEditorString);
    }
    handleClose();
  };
  useEffect(() => {
    setResponseText(
      initialRecommendationDetails?.recommendation ||
        AppConstants.emptyEditorString
    );
  }, [initialRecommendationDetails]);

  //handle description change
  const handleTextChange = (value) => {
    setResponseText(value);
  };
  return (
    <Modal show={show} onHide={handleCloseModal} backdrop="static" centered>
      <Modal.Header closeButton className={classes.achievmentHeader}>
        <Modal.Title className={classes.achievmentTitle}>
          Add Recommendation
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          citation:
            initialRecommendationDetails?.recommendation ||
            AppConstants.emptyEditorString,
          citationDate: initialRecommendationDetails?.recommendedOn
            ? dayjs(initialRecommendationDetails?.recommendedOn).format(
                'YYYY-MM-DD'
              )
            : '',
          expertName: initialRecommendationDetails?.agencyName || '',
          expertDesignation: initialRecommendationDetails?.designation || '',
          skillName: initialRecommendationDetails?.skillId || '',
          expertWebsite: initialRecommendationDetails?.brandingLink || '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <FormikForm>
            <Modal.Body>
              <Form.Group>
                <Form.Label className={formClass.formLabel}>
                  Recommendation<span className="redAstrisk">*</span>
                </Form.Label>
                <ReactQuill
                  className={`form-control recommendationForm `}
                  placeholder="Enter a recommendation you received..."
                  ref={reactQuillRef}
                  formats={AppConstants.richTextEditorFormats}
                  modules={AppConstants.richTextEditorModules}
                  onChange={handleTextChange}
                  value={responseText}
                />
                <ErrorMessage
                  name="citation"
                  component="div"
                  className={classes.achievementErrorText}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className={formClass.formLabel}>
                  Recommended On<span className="redAstrisk">*</span>
                </Form.Label>
                <Field
                  type="date"
                  name="citationDate"
                  className={`form-control ${formClass.formInput}`}
                />
                <ErrorMessage
                  name="citationDate"
                  component="div"
                  className={classes.achievementErrorText}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className={formClass.formLabel}>
                  Expert Name<span className="redAstrisk">*</span>
                </Form.Label>
                <Field
                  name="expertName"
                  maxLength={100}
                  className={`form-control ${formClass.formInput}`}
                />
                <ErrorMessage
                  name="expertName"
                  component="div"
                  className={classes.achievementErrorText}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className={formClass.formLabel}>
                  Expert Designation<span className="redAstrisk">*</span>
                </Form.Label>
                <Field
                  name="expertDesignation"
                  maxLength={100}
                  className={`form-control ${formClass.formInput}`}
                />
                <ErrorMessage
                  name="expertDesignation"
                  component="div"
                  className={classes.achievementErrorText}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className={formClass.formLabel}>
                  Skill Name<span className="redAstrisk">*</span>
                </Form.Label>
                <Field
                  as="select"
                  name="skillName"
                  className={`form-control ${formClass.formInput}`}
                >
                  <option value="">Select a skill</option>
                  {skills.map(({ skill, skillUniqueId, skillActiveFlag }) => (
                    <option key={skillUniqueId} value={skillUniqueId}>
                      {skill}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="skillName"
                  component="div"
                  className={classes.achievementErrorText}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className={formClass.formLabel}>
                  Expert Website/Link (Optional)
                </Form.Label>
                <Field
                  // type="url"
                  name="expertWebsite"
                  className={`form-control ${formClass.formInput}`}
                />
                <ErrorMessage
                  name="expertWebsite"
                  component="div"
                  className={classes.achievementErrorText}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={classes.achievementBtn}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                className={classes.achievementBtn}
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddRecommendationModal;
