import { create } from 'zustand';
import { FetchMembershipStatus } from '../utils/SynchronizeMembershipStatus';
import { AppConstants } from '../constants/AppConstants';
import { DataService } from '../services/DataService';

const useSubscriptionModalStore = create((set) => ({
  isInitialized: false,
  modalData: null,
  subscriptionsPlanData: null,
  openModal: (data) => {
    set({ modalData: data });
  },
  closeModal: () => {
    set({ modalData: null });
  },
  setSubscriptionsPlanData: (data) => {
    set({ subscriptionsPlanData: data });
  },
  initializeSubscriptionModalStore: async () => {
    try {
      let url = AppConstants.getCreatorMembershipAPI;
      const data = await DataService.get(url, '', '');

      if (data) {
        set({ subscriptionsPlanData: data });
        set({ isInitialized: true });
      } else {
        set({ subscriptionsPlanData: null });
        set({ isInitialized: true });
      }
    } catch (error) {
      set({ subscriptionsPlanData: null });
      set({ isInitialized: true });
      throw error;
    }
  },
}));

export default useSubscriptionModalStore;
