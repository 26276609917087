import { create } from 'zustand';
import { FetchLearnerAchievementCards } from '../utils/FetchLearnerProfileAchievementCardsData';

const useRecentActivityCardStore = create((set, get) => ({
    learnerProfileRecentActivitiesCards: { recentActivities: [] },
    loading: false,
    error: null,
    visibilityData: [],
    learnerSlug: null,
    postPayload: {},

    setPostPayload: (data) => {
        set((state) => ({
            postPayload: data,
        }));
    },

    getPostPayload: () => get().postPayload,

    setLearnerSlug: (slug) => {
        set((state) => ({
            learnerSlug: slug,
        }));
    },

    setVisibilityData: (data) => {
        set((state) => {
            const updatedState = {
                visibilityData: data,
            };
            return updatedState;
        });
    },

    setLearnerProfileRecentActivityCardsData: (data) => {
        set((state) => ({
            learnerProfileRecentActivitiesCards: {
                ...state.learnerProfileRecentActivitiesCards,
                ...data,
            },
        }));
    },

    getLearnerProfileRecentActivityCardsData: () => get().learnerProfileRecentActivitiesCards,

    initializeLearnerProfileRecentActivityCardsData: async ({ learnerSlug }) => {
        try {
            const data = await FetchLearnerAchievementCards({
                userSlug: learnerSlug,
            });
            set((state) => ({
                learnerProfileRecentActivitiesCards: {
                    ...state.learnerProfileRecentActivitiesCards,
                    ...data,
                },
            }));
        } catch (error) {
            set(() => ({
                learnerProfileRecentActivitiesCards: {},
                error: 'An error occurred while fetching cards',
            }));
        }
    }
}));

export default useRecentActivityCardStore;
