import { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import classes from './LandingPageHeroCarousel.module.css';
import { AppConstants } from '../../constants/AppConstants';
import './HeroCarousel.css';
import { Link } from 'react-router-dom';
import { useLearnerPassStore } from '../../stores';
import parse from 'html-react-parser';
export const LandingPageHeroCarousel = (props) => {
  const [desktopCarouselDetails, setDesktopCarouselDetails] = useState(
    props.landingPageData.webCarousel
  );
  const [tabletCarouselDetails, setTabletCarouselDetails] = useState(
    props.landingPageData.tabletCarousel
  );
  const [mobileCarouselDetails, setMobileCarouselDetails] = useState(
    props.landingPageData.mobileCarousel
  );
  const { learnerPassSubscriptionStatus } = useLearnerPassStore();
  return (
    <>
      {desktopCarouselDetails.active === AppConstants.trueText && (
        <Carousel
          interval={AppConstants.intervalTime}
          pause={false}
          className={classes.desktopCarousel}
        >
          {desktopCarouselDetails.slides
            .filter((data) => data.active === AppConstants.trueText)
            .map((sliderDetail) => (
              <Carousel.Item
                key={sliderDetail.id}
                className={classes.carouselItem}
              >
                <a href={sliderDetail.link} target="_blank">
                  <img
                    className={classes.carouselImage}
                    src={sliderDetail.imagePath}
                    alt="Carousel Slide"
                  />
                </a>

                <Carousel.Caption className={classes.carouselCaption}>
                  <h3 className={classes.headingText}>{sliderDetail.title}</h3>
                  <p className={classes.subHeadingText}>
                    {parse(sliderDetail.subTitle)}
                  </p>
                  {sliderDetail.buttonText != null &&
                    !(
                      learnerPassSubscriptionStatus === 'Active' &&
                      sliderDetail.buttonText === 'Subscribe Now'
                    ) && (
                      <Link onClick={(e) => {
                        e.preventDefault(); // Prevent React Router navigation
                        window.location.replace(sliderDetail.link); // Reload & navigate
                      }}>
                        <Button className={classes.CarouselButton}>
                          {sliderDetail.buttonText}
                        </Button>
                      </Link>
                    )}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
        </Carousel>
      )}
      {tabletCarouselDetails.active === AppConstants.trueText && (
        <Carousel
          interval={AppConstants.intervalTime}
          pause={false}
          className={classes.tabletCarousel}
        >
          {tabletCarouselDetails.slides
            .filter((data) => data.active === AppConstants.trueText)
            .map((sliderDetail) => (
              <Carousel.Item
                key={sliderDetail.id}
                className={classes.carouselItem}
              >
                <a href={sliderDetail.link} target="_blank">
                  <img
                    className={classes.carouselImage}
                    src={sliderDetail.imagePath}
                    alt="Carousel Slide"
                  />
                </a>

                <Carousel.Caption className={classes.carouselCaption}>
                  <h3 className={classes.headingText}>{sliderDetail.title}</h3>
                  <p className={classes.subHeadingText}>
                    {parse(sliderDetail.subTitle)}
                  </p>
                  {sliderDetail.buttonText != null && (
                    <Link onClick={(e) => {
                      e.preventDefault(); // Prevent React Router navigation
                      window.location.replace(sliderDetail.link); // Reload & navigate
                    }}>
                      <Button className={classes.CarouselButton}>
                        {sliderDetail.buttonText}
                      </Button>
                    </Link>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
        </Carousel>
      )}
      {mobileCarouselDetails.active === AppConstants.trueText && (
        <Carousel
          interval={AppConstants.intervalTime}
          pause={false}
          className={classes.mobileCarousel}
        >
          {mobileCarouselDetails.slides
            .filter((data) => data.active === AppConstants.trueText)
            .map((sliderDetail) => (
              <Carousel.Item
                key={sliderDetail.id}
                className={classes.carouselItem}
              >
                <a href={sliderDetail.link} target="_blank">
                  <img
                    className={classes.carouselImage}
                    src={sliderDetail.imagePath}
                    alt="Carousel Slide"
                  />
                </a>
                <Carousel.Caption className={classes.carouselCaption}>
                  <h3 className={classes.headingText}>{sliderDetail.title}</h3>
                  <p className={classes.subHeadingText}>
                    {parse(sliderDetail.subTitle)}
                  </p>
                  {sliderDetail.buttonText != null && (
                    <Link onClick={(e) => {
                      e.preventDefault(); // Prevent React Router navigation
                      window.location.replace(sliderDetail.link); // Reload & navigate
                    }}>
                      <Button className={classes.CarouselButton}>
                        {sliderDetail.buttonText}
                      </Button>
                    </Link>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
        </Carousel>
      )}
    </>
  );
};
