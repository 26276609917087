import React, { useState, useEffect } from 'react';
import studentStyle from './ExpressionOfInterestStudentPass.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { Row } from 'react-bootstrap';

export const ExpressionOfInterestStudentPass = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;

  const [imageSrc, setImageSrc] = useState(
    `${mediaContent}${AppConstants.studentPassImage}`
  );

  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 567) {
        setImageSrc(`${mediaContent}${AppConstants.studentPassImage2}`);
      } else {
        setImageSrc(`${mediaContent}${AppConstants.studentPassImage}`);
      }
    };

    window.addEventListener('resize', updateImage);
    updateImage();

    return () => window.removeEventListener('resize', updateImage);
  }, [mediaContent]);

  return (
    <>
      <div className={studentStyle.studentPassSection}>
        <Row>
          <div
            className={`${studentStyle.studentPass1} col-md-12 col-sm-12 col-xs-12`}
          >
            <div className={studentStyle.studentPassImagecontent}>
              <h2>{AppConstants.studentPass}</h2>
              <p className={studentStyle.studentPassDescription}>
                {AppConstants.studentPassDescription}
              </p>
            </div>

            <div className={studentStyle.studentPassImageSection}>
              <img
                className={studentStyle.studentPassImage}
                src={imageSrc}
                alt="Student Pass"
              ></img>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};
