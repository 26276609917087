import React, { useEffect, useState } from 'react';
import { DataService } from '../../services/DataService';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../constants/AppConstants';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs';
import styles from './PaymentCallback.module.css';

function PaymentCallbackPage() {
  const [status, setStatus] = useState(AppConstants.emptyString);
  const [loading, setLoading] = useState(true);
  const [apiCallComplete, setApiCallComplete] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const handleEmailSupport = () => {
    const email = AppConstants.supportEmailAddress;
    const subject = 'PAYMENT ISSUE';
    const body = `Hi,\n\nI made a MEMBERSHIP FEE payment using the payment ID ${params.get('razorpay_payment_id')} but it did not go through. Can you help me figure this out?\n\nRegards,`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const getPaymentStatus = async () => {
    const payload = {
      razorpay_payment_id: params.get('razorpay_payment_id'),
      razorpay_payment_link_id: params.get('razorpay_payment_link_id'),
      razorpay_payment_link_reference_id: params.get(
        'razorpay_payment_link_reference_id'
      ),
      razorpay_payment_link_status: params.get('razorpay_payment_link_status'),
      razorpay_signature: params.get('razorpay_signature'),
    };

    try {
      const response = await DataService.postWithResponseCode(
        'verifyPaymentFromLink',
        payload,
        '',
        ''
      );

      if (response && response.ok && response.status === 200) {
        setStatus(AppConstants.paymentStatus.verified);
        setTimeout(() => navigate('/welcome-expert'), 3000);
        // navigate('/welcome-expert');
      } else {
        setStatus(AppConstants.paymentStatus.failed);
        setTimeout(() => navigate('/'), 2000);
      }
    } catch (error) {
      setStatus(AppConstants.paymentStatus.failed);
      setTimeout(() => navigate('/'), 1500);
    } finally {
      setLoading(false);
      setApiCallComplete(true);
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, [navigate]);

  // getPaymentStatus();
  return (
    <div className={styles.payment_callback_page}>
      {loading && (
        <div className={styles.loading_container}>
          <Spinner
            animation="border"
            role="status"
            className={styles.loading_spinner}
          />
          <p>Processing payment...</p>
        </div>
      )}

      {apiCallComplete && (
        <>
          {status === AppConstants.paymentStatus.verified && (
            <Alert key="success" variant="success" className={styles.alert}>
              <BsCheckCircleFill /> Your payment has been verified. Thank you
              for your payment!
            </Alert>
          )}
          {status === AppConstants.paymentStatus.failed && (
            <div>
              <Alert key="failed" variant="danger" className={styles.alert}>
                Your payment has failed. Please reach out to us so that we can
                sort this out for you.
              </Alert>
              <Button variant="secondary" onClick={handleEmailSupport}>
                Email Support
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PaymentCallbackPage;
