import { create } from 'zustand';

const useLearnerPassPaymentPopupStore = create((set) => ({
  showLearnerPassPaymentPopup: false,
  showLearnerPassWelcomeScreen: false,
  toggleLearnerPassPaymentPopup: () =>
    set((state) => ({
      showLearnerPassPaymentPopup: !state.showLearnerPassPaymentPopup,
    })),
  setShowLearnerPassPaymentPopup: (state) =>
    set({ showLearnerPassPaymentPopup: state }),
  setShowLearnerPassWelcomeScreen: (state) =>
    set({ showLearnerPassWelcomeScreen: state }),
}));

export default useLearnerPassPaymentPopupStore;
