import React, { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LearnerProfileLayout } from '../../component/learnerProfile/LearnerProfileLayout';
import { useLearnerProfileHeatmapStore } from '../../stores';

export const LearnerProfilePage = () => {
  const landingPageData = useLoaderData();
  return (
    <>
      <div className="learnerProfilePage">
        <LandingPageTopNavigation landingPageData={landingPageData} />
        <LearnerProfileLayout />
      </div>
    </>
  );
};
