import classes from './DailyAwardsPage.module.css';
import React, { useState,useEffect } from 'react';
import { CreateChampionshipTabs } from '../../component/createChampionshipComponents/CreateChampionshipTabs';

export const DailyAwardsPage = () => {

  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>Champion of the Day</h3>
      <CreateChampionshipTabs/>
    </div>
  );
};
