import CalHeatmap from 'cal-heatmap';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import styles from './Heatmap.module.css';
import { MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight } from "react-icons/md";

const EmptyHeatmap = () => {
  const heatmapRef = useRef();
  let isPainted = false;

  const cal = useRef(null);

  useEffect(() => {
    if (!isPainted) {
      isPainted = true;
      cal.current = new CalHeatmap();
      const currentDate = dayjs().format('YYYY-MM-DD');
      const twelveMonthsAgo = dayjs()
        .subtract(6, 'months')
        .format('DD MMM YYYY');

      cal.current.paint({
        itemSelector: heatmapRef.current,
        theme: 'light',
        date: {
          start: new Date(twelveMonthsAgo),
          highlight: new Date(currentDate),
        },
        data: {
          source: [],
          type: 'json',
          x: 'date',
          y: 'value',
          subDomainTitle: (date, value) => {
            return value;
          },
        },
        range: 7,
        domain: {
          type: 'month',
          gutter: 20,
          label: {
            text: (timestamp) => {
              const formattedMonthYear = dayjs(timestamp).format('MMMM YYYY');
              return formattedMonthYear;
            },
            height: 50
          },
        },
        subDomain: {
          type: 'day',
          width: 31,
          height: 31,
          gutter: 4,
          radius: 4,
          label: 'DD',
        },
      });
    }
  }, []);

  const handlePrevious = () => {
    if (cal.current) {
      cal.current.previous();
    }
  };

  const handleNext = () => {
    if (cal.current) {
      cal.current.next();
    }
  };
  return (
    <div className={styles.heatmapSection}>
      <h2 className={styles.heatmapHeading}>Learning Journey</h2>
      <div className={styles.heatmapContainer}>
        <div id="cal-heatmap" className={styles.calHeatmap}></div>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.chevronIcon}>
          <MdOutlineArrowCircleLeft onClick={handlePrevious} className={styles.iconLeft} />
          <MdOutlineArrowCircleRight onClick={handleNext} className={styles.iconRight} />
        </div>
      </div>
    </div>
  );
};

export default EmptyHeatmap;
