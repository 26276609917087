import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import styles from './PaymentFailSafeModal.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { FaWhatsapp } from 'react-icons/fa6';
import { CiPhone } from 'react-icons/ci';

const PaymentFailSafeModal = ({ show, onHide, errorMessage, paymentId }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const phoneNumber = AppConstants.helpContact;
  const whatsAppPhoneNumber = AppConstants.whatsAppChatbotProps.phoneNumber;

  const paymentIssueErrorMessage = `Looks like there was some issue processing your subscription. \nDon't worry, your money is safe with us and we are aware of this issue. We are working to resolve it as soon as possible. \nYou can reach out to us at ${phoneNumber} directly for immediate assistance.`;

  const whatsappPaymentFailureMessage = `Hi, I made a payment with the payment ID ${paymentId} , but my subscription is still pending. Could you please help me resolve this?`;

  const handleCopyPaymentId = () => {
    navigator.clipboard.writeText(paymentId).then(
      () => setCopySuccess(true),
      () => alert('Failed to copy Payment ID')
    );

    setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleEmailSupport = () => {
    const email = AppConstants.supportEmailAddress;
    const subject = 'PAYMENT ISSUE';
    const body = `Hi,\n\nI made a payment using the payment ID ${paymentId} but it did not go through. Can you help me figure this out?\n`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <button
          className={styles.closeButton}
          onClick={onHide}
          aria-label="Close"
        >
          &times;
        </button>

        <h3 className={styles.title}>Subscription Pending</h3>

        <div className={styles.textContainer}>
          <p>{paymentIssueErrorMessage}</p>
          <p>
            <strong>Payment ID:</strong> {paymentId}
            <FaCopy
              onClick={handleCopyPaymentId}
              className={styles.copyIcon}
              title="Copy Payment ID"
            />
            {copySuccess && (
              <p className={styles.copySuccess}>
                Payment ID copied to clipboard!
              </p>
            )}
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            href={`tel:${phoneNumber}`}
            variant="primary"
            aria-label="Call Us"
            className={styles.callUsButton}
          >
            <CiPhone /> Call Us
          </Button>

          <Button
            href={`https://wa.me/${whatsAppPhoneNumber}?text=${encodeURIComponent(whatsappPaymentFailureMessage)}`}
            target="_blank"
            rel="noopener noreferrer"
            variant="success"
            aria-label="Chat on WhatsApp"
            className={styles.whatsappButton}
          >
            <FaWhatsapp /> Chat on WhatsApp
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentFailSafeModal;
