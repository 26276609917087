import React from 'react';
import aboutUsStyle from './ExpressionOfInterestAboutUsSection.module.css';
import { Accordion, Row } from 'react-bootstrap';
import { AppConstants } from '../../../constants/AppConstants';
import { BiSolidPhoneCall } from 'react-icons/bi';

export const ExpressionOfInterestAboutUsSection = () => {
  return (
    <>
      <div className={aboutUsStyle.aboutUs}>
        <Row>
          <div className={`${aboutUsStyle.aboutUsHelp} col-md-6 col-sm-12`}>
            <h2>
              {AppConstants.haveDoubtsAndNeedHelp.split('help')[0]}
              <span className={aboutUsStyle.haveDoubtsAndNeedHelp}>help</span>
              {AppConstants.haveDoubtsAndNeedHelp.split('help')[1]}
            </h2>
            <p>{AppConstants.helpDescription}</p>
            <p
              className={aboutUsStyle.helpContact}
              onClick={() =>
                (window.location = `tel:${AppConstants.helpContact}`)
              }
            >
              <BiSolidPhoneCall />
              {AppConstants.helpContact}
            </p>
          </div>
          <div
            className={`${aboutUsStyle.aboutUsQuestions} col-md-6 col-sm-12`}
          >
            <Accordion defaultActiveKey="0">
              {AppConstants.AboutUs.map((item, index) => (
                <Accordion.Item
                  className={aboutUsStyle.accordionItem}
                  eventKey={String(index)}
                  key={index}
                >
                  <Accordion.Header className={aboutUsStyle.accordionButton}>
                    {item.question}
                  </Accordion.Header>
                  <Accordion.Body className={aboutUsStyle.accordionBody}>
                    {item.answer}
                    <a
                      href="https://calendly.com/qrenciaforcreators/free-demo"
                      target={AppConstants.openInNewTab}
                      rel="noopener noreferrer"
                    >
                      {item.LinkText}
                    </a>
                    {item.answerContinue}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Row>
      </div>
    </>
  );
};
