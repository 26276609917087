import { LEARNER_PASS_DASHBOARD } from '../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import Auth from '../../../utils/Auth';

export const checkReferralCodeValidity = async ({
  referralCode,
  userEmail,
  planId,
}) => {
  const learnerPassReferralVerifyURL =
    LEARNER_PASS_DASHBOARD.learnerPassVerifyReferralCodeAPI;
  try {
    const data = await AuthorizedDataService.postWithResponse(
      learnerPassReferralVerifyURL,
      { referralCode, email: userEmail, planId }
    );

    const response = await data.json();

    if (response.statusCode === 1) {
      return {
        status: 'valid',
        discount: response.discount,
        message: 'Referral code applied successfully!',
      };
    } else if (response.statusCode === -25) {
      return {
        status: 'invalid',
        discount: response.discount || 0,
        message: 'Invalid referral code',
      };
    } else if (response.statusCode === -11) {
      return {
        status: 'alreadyUsed',
        discount: response.discount || 0,
        message: 'The referral code has been consumed',
      };
    } else if (response.statusCode === -21) {
      return {
        status: 'expired',
        discount: response.discount || 0,
        message: 'Expired referral code',
      };
    }
  } catch (error) {
    console.error('Error:', error.message || error);
    throw error;
  }
};
