import { Button, Modal } from 'react-bootstrap';
import { FaAngleLeft } from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa';
import classes from './ImageGalleryModal.module.css';
import { AppConstants } from '../../constants/AppConstants';
import modalClass from '../cards/CompetitionsCards.module.css';
import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { MdFileDownload } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { ErrorSuccessAlertMessage } from './../errorSuccessMessage/ErrorSuccessAlertMessage';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { BiLoaderAlt } from 'react-icons/bi';

export const ImageGalleryModal = ({
  creatorName,
  creatorGalleryContent,
  selectedMediaContent,
  errorMessage,
  saveEditMyAlbum,
  hanldeGalleryClose,
  saveButton,
  showDeleteIcon = false,
  showDownloadIcon = false,
  removeSelectedMedia,
  headerName,
  albumSavedAlert,
}) => {
  const [showFullContent, setShowFullContent] = useState(
    AppConstants.falseText
  );
  const [showModal, setShowModal] = useState(AppConstants.trueText);
  const [selectedMedia, setSelectedMedia] = useState(AppConstants.emptyString);
  const [seletedPosition, setSelectedPosition] = useState(
    AppConstants.emptyString
  );
  const s3BucketContent = process.env.REACT_APP_MEDIA_CONTENT;
  const videoPlayIconUrl = `${s3BucketContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
  const leftSwipe = -1;
  const rightSwipe = 1;
  const [showUpdateAlertMessage, setShowUpdateAlertMessage] = useState(
    AppConstants.emptyString
  );
  const [alertVariant, setAlertVariant] = useState(AppConstants.emptyString);
  const [downloadingFile, setDownloadingFile] = useState(null);

  useEffect(() => {
    if (
      selectedMediaContent?.url &&
      window.innerWidth > AppConstants.creatorGallerySingleView
    ) {
      setSelectedPosition(
        creatorGalleryContent.findIndex(
          (item) => item.url === selectedMediaContent.url
        ) + 1
      );
      setSelectedMedia(selectedMediaContent);
      setShowFullContent(AppConstants.trueText);
      setShowModal(AppConstants.trueText);
    }
  }, [selectedMediaContent, creatorGalleryContent]);

  const showFullScreen = (value, index) => {
    if (window.innerWidth > AppConstants.creatorGallerySingleView) {
      setSelectedMedia(value);
      setSelectedPosition(index + 1);
      setShowFullContent(AppConstants.trueText);
    }
  };

  const closeFullScreen = () => {
    setSelectedMedia(AppConstants.emptyString);
    setSelectedPosition(AppConstants.emptyString);
    setShowFullContent(AppConstants.falseText);
  };

  const closeGallary = () => {
    setSelectedMedia(AppConstants.emptyString);
    setSelectedPosition(AppConstants.emptyString);
    setShowModal(AppConstants.falseText);
    hanldeGalleryClose();
  };

  const downloadFile = async (e, fileUrl) => {
    e.preventDefault();
    setDownloadingFile(fileUrl);
    try {
      let fileName = fileUrl.split('/').pop();
      await FileOperationsUtil.downloadFileFromS3(
        fileUrl,
        fileName.substring(0, fileName.lastIndexOf('.')),
        fileUrl.split('.').pop()
      );
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setDownloadingFile(null);
    }
  };

  const swipeContent = (step) => {
    if (seletedPosition + step < 1) {
      setSelectedPosition(creatorGalleryContent?.length);
      setSelectedMedia(
        creatorGalleryContent[creatorGalleryContent?.length - 1]
      );
    } else if (seletedPosition + step > creatorGalleryContent?.length) {
      setSelectedPosition(1);
      setSelectedMedia(creatorGalleryContent[0]);
    } else {
      setSelectedPosition(seletedPosition + step);
      setSelectedMedia(creatorGalleryContent[seletedPosition + step - 1]);
    }
  };

  const saveEditMyAlbumChanges = () => {
    saveEditMyAlbum(creatorGalleryContent);
  };
  useEffect(() => {
    if (albumSavedAlert === AppConstants.trueText) {
      setAlertVariant(AppConstants.alertVarient[0]);
      setShowUpdateAlertMessage(SuccessMessage.updateMyAlbumSuccess);
      setTimeout(() => {
        setShowUpdateAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    } else if (albumSavedAlert === AppConstants.falseText) {
      setAlertVariant(AppConstants.alertVarient[1]);
      setShowUpdateAlertMessage(ErrorMessages.updateAlbumError);
      setTimeout(() => {
        setShowUpdateAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  }, [albumSavedAlert]);
  return (
    <div>
      <Modal
        size="xl"
        show={showModal}
        onHide={closeGallary}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {creatorName && (
            <h1 className={classes.heading}>{creatorName}'s Spotlight</h1>
          )}
          {!creatorName && !headerName && (
            <h1 className={classes.heading}>Spotlight</h1>
          )}
          {headerName && <h1 className={classes.heading}>{headerName}</h1>}
        </Modal.Header>
        <Modal.Body className={modalClass.modalBody}>
          {errorMessage && creatorGalleryContent.length == 0 && (
            <p className="noDataAvailableError">{errorMessage}</p>
          )}
          <div className={classes.modal}>
            <div
              className={`${classes.contentContainer} ${showFullContent && classes.contentContainerFullScreen}`}
            >
              {creatorGalleryContent.length != 0 &&
                creatorGalleryContent.map((value, index) =>
                  value?.type === AppConstants.mediaTypes.image ? (
                    <div className={classes.imageBox} key={index}>
                      <img
                        className={`${classes.gallaryImage} ${seletedPosition === index + 1 ? classes.selectedImage : ''}`}
                        onClick={() => showFullScreen(value, index)}
                        src={value.url}
                        alt="Gallery"
                      />
                      <div className={classes.iconSection}>
                        {showDownloadIcon && !value.file?.name && (
                          <Button
                            className={classes.iconButton}
                            disabled={downloadingFile === value.url}
                          >
                            {downloadingFile === value.url ? (
                              <BiLoaderAlt className={classes.spinner} />
                            ) : (
                              <MdFileDownload
                                onClick={(e) => downloadFile(e, value.url)}
                              />
                            )}
                          </Button>
                        )}
                        {showDeleteIcon && (
                          <Button className={classes.iconButton}>
                            {' '}
                            <IoMdTrash
                              onClick={() => {
                                removeSelectedMedia(value);
                                closeFullScreen();
                              }}
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={classes.imageBox} key={index}>
                      <video
                        controls={
                          window.innerWidth <
                          AppConstants.creatorGallerySingleView
                            ? AppConstants.trueText
                            : AppConstants.falseText
                        }
                        onClick={() => showFullScreen(value, index)}
                        className={`${classes.gallaryImage} ${seletedPosition === index + 1 ? classes.selectedImage : ''}`}
                      >
                        <source src={value.url} />
                      </video>
                      {window.innerWidth >=
                        AppConstants.creatorGallerySingleView && (
                        <img
                          onClick={() => showFullScreen(value, index)}
                          className={classes.gallaryVideoPlayIcon}
                          src={videoPlayIconUrl}
                          alt="Video Play Button"
                        />
                      )}
                      <div className={classes.iconSection}>
                        {showDownloadIcon && !value.file?.name && (
                          <Button
                            className={classes.iconButton}
                            disabled={downloadingFile === value.url}
                          >
                            {downloadingFile === value.url ? (
                              <BiLoaderAlt className={classes.spinner} />
                            ) : (
                              <MdFileDownload
                                onClick={(e) => downloadFile(e, value.url)}
                              />
                            )}
                          </Button>
                        )}
                        {showDeleteIcon && (
                          <Button className={classes.iconButton}>
                            <IoMdTrash
                              onClick={() => {
                                removeSelectedMedia(value);
                                closeFullScreen();
                              }}
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  )
                )}
            </div>
            {showFullContent && (
              <div className={classes.fullScreenContent}>
                {selectedMedia?.type === AppConstants.mediaTypes.image ? (
                  <div>
                    <img
                      className={classes.fullScreenImage}
                      src={selectedMedia.url}
                      alt="Gallery"
                    />
                    <IoMdClose
                      className={classes.crossButton}
                      onClick={closeFullScreen}
                    />
                    {seletedPosition > 1 && (
                      <Button
                        onClick={() => swipeContent(leftSwipe)}
                        className={`${classes.swipeButtons} ${classes.leftSwipeButton}`}
                      >
                        <FaAngleLeft />
                      </Button>
                    )}
                    {seletedPosition < creatorGalleryContent.length && (
                      <Button
                        onClick={() => swipeContent(rightSwipe)}
                        className={`${classes.swipeButtons} ${classes.rightSwipeButton}`}
                      >
                        <FaAngleRight />
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    <video
                      key={selectedMedia.url}
                      autoPlay={true}
                      controls
                      className={classes.fullScreenImage}
                    >
                      <source src={selectedMedia.url} />
                    </video>
                    {seletedPosition > 1 && (
                      <Button
                        onClick={() => swipeContent(leftSwipe)}
                        className={`${classes.swipeButtons} ${classes.leftSwipeButton}`}
                      >
                        <FaAngleLeft />
                      </Button>
                    )}
                    {seletedPosition < creatorGalleryContent.length && (
                      <Button
                        onClick={() => swipeContent(rightSwipe)}
                        className={`${classes.swipeButtons} ${classes.rightSwipeButton}`}
                      >
                        <FaAngleRight />
                      </Button>
                    )}
                    <IoMdClose
                      className={classes.crossButton}
                      onClick={closeFullScreen}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        {saveButton && (
          <Modal.Footer>
            <Button
              onClick={saveEditMyAlbumChanges}
              className={classes.uploadButton}
            >
              {saveButton}
            </Button>
          </Modal.Footer>
        )}
        {showUpdateAlertMessage && (
          <ErrorSuccessAlertMessage
            message={showUpdateAlertMessage}
            varient={alertVariant}
          />
        )}
      </Modal>
    </div>
  );
};
