import React from 'react';
import { SkillProgressAssessStudentTabs } from '../../component/skillProgressAssessmentComponents/SkillProgressAssessStudentTabs';
export const SkillProgressAssessmentPage = () => {
  return (
    <>
      <div className="expertContentSection">
        <div className="containerHeadings">Manage Assessments</div>
        <SkillProgressAssessStudentTabs />
      </div>
    </>
  );
};
