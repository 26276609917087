import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import classes from './SignupPageForm.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { DataService } from '../../services/DataService';
import SignUpPopUp from '../../component/formControls/SignUpPopUp';
import {
  FaInfo,
  FaEye,
  FaEyeSlash,
  FaEdit,
  FaCheckCircle,
} from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import formClass from '../../common/FormStyling.module.css';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { DateTime } from 'luxon';
import { useLearnerPassStore } from '../../stores';

export const SignupPageForm = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [emailVerifyButtonDisable, setEmailVerifyButtonDisable] = useState(false);
  const [showError, setShowError] = useState([]);
  const [showSuccess, setShowSuccess] = useState([]);
  const [showSuccessScreen, setShowSuccessScreen] = useState(AppConstants.falseText);
  const [emailVerificationScreen, setEmailVerificationScreen] = useState(AppConstants.falseText);
  const [mobileVerificationScreen, setMobileVerificationScreen] = useState(AppConstants.falseText);
  const [successUserName, setSuccessUserName] = useState(AppConstants.emptyString);
  const [countriesList, setCountriesList] = useState([]);
  const [callingCode, setCallingCode] = useState([]);
  const [showVerifyEmailText, setShowVerifyEmailText] = useState(AppConstants.falseText);
  const [showVerifyMobileText, setShowVerifyMobileText] = useState(AppConstants.falseText);
  const [emailOtp, setEmailOtp] = useState(AppConstants.emptyString);
  const [emailVerified, setEmailVerified] = useState(AppConstants.falseText);
  const [mobileOtp, setMobileOtp] = useState(AppConstants.emptyString);
  const [capitalCity, setCapitalCity] = useState("Delhi");
  const [countryName, setCountryName] = useState("India");
  const [mobileVerified, setMobileVerified] = useState(AppConstants.trueText);
  const initialValues = { firstName: "", lastName: "", email: "", password: "", countryId: "1", mobileNumber: "", dob: "", capitalCity: "Delhi", gender: AppConstants.emptyString };
  const [passwordType, setPasswordType] = useState('password');
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loginUserEmail, setLoginUserEmail] = useState(AppConstants.emptyString);
  const [loginUserPassword, setLoginUserPassword] = useState(AppConstants.emptyString);
  const [selectedCountry, setSelectedCountry] = useState(AppConstants.emptyString);
  const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
  const index = 0;
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaTaglineLogoFile}`;
  const [showAboutLearnerPass, setShowAboutLearnerPass] = useState(false);

  const togglePassword = () => {
    if (passwordType === AppConstants.fieldTypePassword) {
      setPasswordType(AppConstants.fieldTypeText);
      return;
    } else {
      setPasswordType(AppConstants.fieldTypePassword);
      return;
    }
  };

  const numberInputs = document.querySelectorAll('input[type=number]');
  numberInputs.forEach((input) => {
    input.addEventListener('wheel', (e) => e.preventDefault());
  });

  const { setWantsToBuyLearnerPass } = useLearnerPassStore();
  const [lpChecked, setLPChecked] = useState(false);

  const handleLPCheckboxChange = () => {
    setLPChecked(!lpChecked);
    // setWantsToBuyLearnerPass(!lpChecked)
  };

  useEffect(() => {
    async function fetchCountries() {
      const url = AppConstants.fetchCountriesAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setCountriesList(response);
      setSelectedCountry(response.filter(country => country.countryCode === "IN")[0]);
      return null;
    };

    fetchCountries();

    const fetchConfigurableKeys = async () => {
      const url = AppConstants.fetchConfigurableKeysAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response) {
        setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinAgeKey).keyValue));
      }
    };

    fetchConfigurableKeys();

  }, []);

  //display calling on selecting country
  const onSelectingCountry = (e) => {
    setCallingCode(countriesList.filter((item) => item.id.toString() === e.target.value));
  }

  //display calling on selecting country
  const handleChangeMobileNumber = (e) => {
    setCallingCode(countriesList.filter((item) => item.id.toString() === formValues.countryId));
  }

  const changeCountryCapital = (e) => {
    let country = countriesList.filter((item) => item.id.toString() === e.target.value);
    if (country.length !== index) {
      setCapitalCity(country[0].capitalCity.trim());
      setCountryName(country[0].country.trim());
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "dob") {
      setFormValues({ ...formValues, [name]: value?.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3') });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     async function postUserDetails() {
  //       setShowSpinner(true);

  //       const trimmedFormValues = { ...formValues, capitalCity: capitalCity, countryName: countryName };
  //       trimmedFormValues.firstName = trimmedFormValues.firstName.trim();
  //       trimmedFormValues.lastName = trimmedFormValues.lastName.trim();

  //       const url = AppConstants.postStudentDetailsAPI;
  //       const data = trimmedFormValues;
  //       const response = await DataService.post(
  //         url,
  //         data,
  //         AppConstants.emptyString,
  //         AppConstants.emptyString
  //       );
  //       if (!response.ok) {
  //         if (response.status === AppConstants.httpResponseCodes.responseCode409) {
  //           setShowError(ErrorMessages.signupDuplicateEmailError);
  //           setShowSuccess(AppConstants.emptyString);
  //           setShowSpinner(false);
  //           const timer = setTimeout(() => {
  //             setShowError(AppConstants.emptyString);
  //           }, AppConstants.messageDisappearTime);
  //           return () => clearTimeout(timer);
  //         }
  //         else if (response.status === AppConstants.httpResponseCodes.responseCode400) {
  //           setShowError(ErrorMessages.signupInvalidReferralError);
  //           setShowSuccess(AppConstants.emptyString);
  //           setShowSpinner(false);
  //           const timer = setTimeout(() => {
  //             setShowError(AppConstants.emptyString);
  //           }, AppConstants.messageDisappearTime);
  //           return () => clearTimeout(timer);
  //         }
  //         else if (response.status !== AppConstants.httpResponseCodes.responseCode400 && response.status !== AppConstants.httpResponseCodes.responseCode409) {
  //           setShowError(ErrorMessages.signupError);
  //           setShowSuccess(AppConstants.emptyString);
  //           setShowSpinner(false);
  //           const timer = setTimeout(() => {
  //             setShowError(AppConstants.emptyString);
  //           }, AppConstants.messageDisappearTime);
  //           return () => clearTimeout(timer);
  //         }
  //       }
  //       else if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
  //         setShowSuccess(SuccessMessage.signupSuccess);
  //         setShowSuccessScreen(AppConstants.trueText);
  //         setShowError(AppConstants.emptyString);
  //         setShowSpinner(false);
  //         setSuccessUserName(formValues.firstName);
  //         setLoginUserEmail(formValues.email);
  //         setFormValues(initialValues);
  //         setIsSubmit(false);
  //         setCallingCode(countriesList.filter((item) => item.id === initialValues.countryId));
  //         const timer = setTimeout(() => {
  //           setShowSuccess(AppConstants.emptyString);
  //         }, AppConstants.messageDisappearTime);
  //         return () => clearTimeout(timer);
  //       }
  //       return null;
  //     };
  //     postUserDetails();
  //   }
  // }, [formErrors]);

  const registerUser = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const loginUser = async (userName, password) => {
    const authData = {
      userName: userName,
      userPassword: password,
    };

    const resp = await DataService.post(
      AppConstants.authenticationUri,
      authData,
      AppConstants.emptyString
    );

    const body = await resp.json();

    if (body !== AppConstants.nullText) {
      const token = body.accessToken;
      if (token !== AppConstants.nullText && token !== undefined) {
        localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
        localStorage.setItem(
          AppConstants.sessionKeyUserDetails,
          JSON.stringify(body)
        );
        setTimeout(function () {
          window.location.href = '../' + AppConstants.studentHomePath;
          window.location.reload();
        }, 3000);
        // Delay of 5000 milliseconds (5 seconds)
      }
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      async function postUserDetails() {
        setShowSpinner(true);

        const trimmedFormValues = {
          ...formValues,
          capitalCity: capitalCity,
          countryName: countryName,
        };
        trimmedFormValues.firstName = trimmedFormValues.firstName.trim();
        trimmedFormValues.lastName = trimmedFormValues.lastName.trim();

        const url = AppConstants.postStudentDetailsAPI;
        const data = trimmedFormValues;
        const response = await DataService.post(
          url,
          data,
          AppConstants.emptyString,
          AppConstants.emptyString
        );
        if (!response.ok) {
          if (
            response.status === AppConstants.httpResponseCodes.responseCode409
          ) {
            setShowError(ErrorMessages.signupDuplicateEmailError);
            setShowSuccess(AppConstants.emptyString);
            setShowSpinner(false);
            const timer = setTimeout(() => {
              setShowError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
          } else if (
            response.status === AppConstants.httpResponseCodes.responseCode400
          ) {
            setShowError(ErrorMessages.signupInvalidReferralError);
            setShowSuccess(AppConstants.emptyString);
            setShowSpinner(false);
            const timer = setTimeout(() => {
              setShowError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
          } else if (
            response.status !==
            AppConstants.httpResponseCodes.responseCode400 &&
            response.status !== AppConstants.httpResponseCodes.responseCode409
          ) {
            setShowError(ErrorMessages.signupError);
            setShowSuccess(AppConstants.emptyString);
            setShowSpinner(false);
            const timer = setTimeout(() => {
              setShowError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
          }
        } else if (
          response.ok &&
          response.status === AppConstants.httpResponseCodes.responseCode201
        ) {
          setShowSuccess(SuccessMessage.signupSuccess);
          setShowSuccessScreen(AppConstants.trueText);
          setShowError(AppConstants.emptyString);
          setShowSpinner(false);
          setSuccessUserName(formValues.firstName);
          setLoginUserEmail(formValues.email);
          setFormValues(initialValues);
          setIsSubmit(false);
          setCallingCode(
            countriesList.filter((item) => item.id === initialValues.countryId)
          );
          setWantsToBuyLearnerPass(lpChecked);
          loginUser(formValues.email, formValues.password);
        }
        return null;
      }
      postUserDetails();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const nameRegex = /^[a-zA-Z ]*$/;
    const indianMobileRegex = /^\d{10}$/;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const dob = new Date(values.dob);
    dob.setHours(0, 0, 0, 0);
    if (!values.firstName.trim()) {
      errors.firstName = ErrorMessages.invalidUserFirstName;
    }
    if (!values.lastName.trim()) {
      errors.lastName = ErrorMessages.invalidUserLastName;
    }
    if (!nameRegex.test(values.firstName)) {
      errors.firstName = ErrorMessages.invalidUserFirstNameWithRegex;
    }
    if (!nameRegex.test(values.lastName)) {
      errors.lastName = ErrorMessages.invalidUserLastNameWithRegex;
    }
    if (values.gender === AppConstants.emptyString) {
      errors.gender = ErrorMessages.invalidUserGender;
    }
    if (!emailRegex.test(values.email)) {
      errors.email = ErrorMessages.invalidEmailFormat;
    } else {
      if (!emailVerified) {
        errors.email = ErrorMessages.signupVerifyEmailError;
      }
    }
    if (!values.password.trim()) {
      errors.password = ErrorMessages.invalidPassword;
    }
    if (values.countryId === AppConstants.countryIdIndia) {
      if (!indianMobileRegex.test(values.mobileNumber)) {
        errors.mobileNumber = ErrorMessages.invalidMobileIndian;
      } else {
        if (!mobileVerified) {
          errors.mobileNumber = ErrorMessages.signupVerifyMobileError;
        }
      }
    } else if (values.countryId !== AppConstants.countryIdIndia) {
      if (
        values.mobileNumber.length < AppConstants.nonIndianMinMobileNumber ||
        values.mobileNumber.length > AppConstants.nonIndianMaxMobile
      ) {
        errors.mobileNumber = ErrorMessages.invalidMobileNonIndian;
      } else {
        if (!mobileVerified) {
          errors.mobileNumber = ErrorMessages.signupVerifyMobileError;
        }
      }
    }
    if (values.dob !== '' && values.dob !== null && values.dob !== undefined) {
      if (new Date(values.dob) >= currentDate) {
        errors.dob = ErrorMessages.signupDateOfBirthGreaterThanToday;
      } else if (
        DateTime.fromJSDate(new Date(currentDate)).diff(
          DateTime.fromJSDate(new Date(dob)),
          'years'
        ).years < signupMinimumAge
      ) {
        if (signupMinimumAge === 1) {
          errors.dob =
            ErrorMessages.signupDateOfBirthLessThanAllowedYears +
            signupMinimumAge +
            ' year' +
            ErrorMessages.signupDateOfBirthLessThanAllowedYearsPart2;
        } else {
          errors.dob =
            ErrorMessages.signupDateOfBirthLessThanAllowedYears +
            signupMinimumAge +
            ' years' +
            ErrorMessages.signupDateOfBirthLessThanAllowedYearsPart2;
        }
      }
    } else {
      errors.dob = ErrorMessages.signupInvalidDateOfBirth;
    }
    return errors;
  };

  //show verify email screen
  async function handleVerifyEmail() {
    setEmailVerifyButtonDisable(true);
    const url = AppConstants.generateEmailOtpAPI;
    const data = { email: formValues.email };
    const response = await DataService.post(
      url,
      data,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    if (response?.status === AppConstants.httpResponseCodes.responseCode201) {
      setEmailVerifyButtonDisable(false);
      setMobileVerificationScreen(AppConstants.falseText);
      setEmailVerificationScreen(AppConstants.trueText);
      setFormErrors((formErrors.errors = { email: AppConstants.emptyString }));
      setEmailOtp(AppConstants.emptyString);
    }
    if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
      setEmailVerifyButtonDisable(false);
      setEmailVerified(AppConstants.trueText);
      setFormErrors((formErrors.errors = { email: AppConstants.emptyString }));
    }
    if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
      setEmailVerifyButtonDisable(false);
      setFormErrors(
        (formErrors.errors = { email: ErrorMessages.signupDuplicateEmailError })
      );
    }
    if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
      setEmailVerifyButtonDisable(false);
      setFormErrors(
        (formErrors.errors = {
          email: ErrorMessages.signupGenerateMobileOtpError,
        })
      );
    }
  }

  //show verify mobile screen
  // async function handleVerifyMobile() {
  //     const url = AppConstants.signupPageGenerateMobileOtpAPI;
  //     const mobileWithCountryCode = callingCode[0]?.countryCallingCode + formValues.mobileNumber;
  //     const data = { "mobileNumber": mobileWithCountryCode?.replace("+", "") };
  //     const response = await DataService.post(
  //         url,
  //         data,
  //         AppConstants.emptyString,
  //         AppConstants.emptyString
  //     );
  //     if (response?.status === AppConstants.httpResponseCodes.responseCode201) {
  //         setMobileVerificationScreen(AppConstants.trueText);
  //         setEmailVerificationScreen(AppConstants.falseText);
  //         setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
  //         setMobileOtp(AppConstants.emptyString);
  //     }
  //     if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
  //         setMobileVerified(AppConstants.trueText);
  //         setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
  //     }
  //     if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
  //         setFormErrors(formErrors.errors = { mobileNumber: ErrorMessages.signupGenerateMobileOtpError });
  //     }
  // }

  //show signup form
  function backToForm() {
    setMobileVerificationScreen(AppConstants.falseText);
    setEmailVerificationScreen(AppConstants.falseText);
  }

  //handle email change for verification
  function handleEmailChange(e) {
    const value = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (emailRegex.test(value)) {
      setShowVerifyEmailText(AppConstants.trueText);
      setEmailVerified(AppConstants.falseText);
    } else {
      setShowVerifyEmailText(AppConstants.falseText);
      setEmailVerified(AppConstants.falseText);
    }
  }

  //handle mobile change for verification
  function handleMobileChange(e) {
    const value = e.target.value;
    const indianMobileRegex = /^\d{10}$/;
    if (formValues?.countryId === AppConstants.countryIdIndia) {
      if (indianMobileRegex.test(value)) {
        setMobileVerified(AppConstants.trueText);
        // setShowVerifyMobileText(AppConstants.trueText);
        // setMobileVerified(AppConstants.falseText);
      } else {
        setMobileVerified(AppConstants.trueText);
        // setShowVerifyMobileText(AppConstants.falseText);
        // setMobileVerified(AppConstants.falseText);
      }
    } else if (formValues?.countryId !== AppConstants.countryIdIndia) {
      if (
        value?.length >= AppConstants.nonIndianMinMobileNumber &&
        value?.length <= AppConstants.nonIndianMaxMobile
      ) {
        setMobileVerified(AppConstants.trueText);
        // setShowVerifyMobileText(AppConstants.trueText);
        // setMobileVerified(AppConstants.falseText);
      } else {
        setMobileVerified(AppConstants.trueText);
        // setShowVerifyMobileText(AppConstants.falseText);
        // setMobileVerified(AppConstants.falseText);
      }
    }
  }

  //on country change handle
  useEffect(() => {
    function handleCountryChange() {
      if (formValues?.mobileNumber) {
        const indianMobileRegex = /^\d{10}$/;
        if (formValues?.countryId === AppConstants.countryIdIndia) {
          if (indianMobileRegex.test(formValues?.mobileNumber)) {
            setMobileVerified(AppConstants.trueText);
            // setShowVerifyMobileText(AppConstants.trueText);
            // setMobileVerified(AppConstants.falseText);
          } else {
            setMobileVerified(AppConstants.trueText);
            // setShowVerifyMobileText(AppConstants.falseText);
            // setMobileVerified(AppConstants.falseText);
          }
        } else if (formValues?.countryId !== AppConstants.countryIdIndia) {
          if (
            formValues?.mobileNumber?.length >=
            AppConstants.nonIndianMinMobileNumber &&
            formValues?.mobileNumber?.length <= AppConstants.nonIndianMaxMobile
          ) {
            setMobileVerified(AppConstants.trueText);
            // setShowVerifyMobileText(AppConstants.trueText);
            // setMobileVerified(AppConstants.falseText);
          } else {
            setMobileVerified(AppConstants.trueText);
            // setShowVerifyMobileText(AppConstants.falseText);
            // setMobileVerified(AppConstants.falseText);
          }
        }
      }
    }
    handleCountryChange();
  }, [formValues?.countryId]);

  //resend OTP button
  const renderButton = (buttonProps) => {
    return (
      <button className={classes.resendOtpButton} {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend code (in ${buttonProps.remainingTime} sec)`
          : 'Resend'}
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  //verify email OTP
  useEffect(() => {
    async function handleVerifyEmailOtp() {
      if (emailOtp?.length === 6) {
        const url = AppConstants.verifyEmailOtpSignUpAPI;
        const data = { email: formValues.email, otp: emailOtp };
        const response = await DataService.post(
          url,
          data,
          AppConstants.emptyString,
          AppConstants.emptyString
        );
        if (
          response?.status === AppConstants.httpResponseCodes.responseCode200
        ) {
          setEmailOtp(AppConstants.emptyString);
          setMobileVerificationScreen(AppConstants.falseText);
          setEmailVerificationScreen(AppConstants.falseText);
          setEmailVerified(AppConstants.trueText);
          setFormErrors(
            (formErrors.errors = { emailOtpError: AppConstants.nullText })
          );
        }
        if (
          response?.status === AppConstants.httpResponseCodes.responseCode409
        ) {
          setMobileVerificationScreen(AppConstants.falseText);
          setEmailVerificationScreen(AppConstants.trueText);
          setEmailVerified(AppConstants.falseText);
          setFormErrors(
            (formErrors.errors = {
              emailOtpError: ErrorMessages.signupEmailOtpVerifyError,
            })
          );
        }
        if (
          response?.status === AppConstants.httpResponseCodes.responseCode500
        ) {
          setMobileVerificationScreen(AppConstants.falseText);
          setEmailVerificationScreen(AppConstants.trueText);
          setEmailVerified(AppConstants.falseText);
          setFormErrors(
            (formErrors.errors = {
              emailOtpError: ErrorMessages.signupEmailOtpVerifyError,
            })
          );
        }
      }
    }
    handleVerifyEmailOtp();
  }, [emailOtp]);

  const validateMobileNumber = (event) => {
    let excludedKeywords = ['-', 'e', '+', 'E', '.', AppConstants.emptyString];
    if (excludedKeywords.includes(event.key)) event.preventDefault();
  };

  //verify mobile OTP
  // useEffect(() => {
  //     async function handleVerifyMobileOtp() {
  //         if (mobileOtp?.length === 6) {
  //             const url = AppConstants.signupPageVerifyMobileAPI;
  //             const mobileWithCountryCode = callingCode[0]?.countryCallingCode + formValues.mobileNumber;
  //             const data = { "mobileNumber": mobileWithCountryCode?.replace("+", ""), "otp": mobileOtp };
  //             const response = await DataService.post(
  //                 url,
  //                 data,
  //                 AppConstants.emptyString,
  //                 AppConstants.emptyString
  //             );
  //             if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
  //                 setMobileOtp(AppConstants.emptyString);
  //                 setMobileVerificationScreen(AppConstants.falseText);
  //                 setEmailVerificationScreen(AppConstants.falseText);
  //                 setMobileVerified(AppConstants.trueText);
  //                 setFormErrors(formErrors.errors = { mobileOtpError: AppConstants.nullText });
  //             }
  //             if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
  //                 setMobileVerificationScreen(AppConstants.trueText);
  //                 setEmailVerificationScreen(AppConstants.falseText);
  //                 setMobileVerified(AppConstants.falseText);
  //                 setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
  //             }
  //             if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
  //                 setMobileVerificationScreen(AppConstants.trueText);
  //                 setEmailVerificationScreen(AppConstants.falseText);
  //                 setMobileVerified(AppConstants.falseText);
  //                 setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
  //             }
  //         }
  //     }
  //     handleVerifyMobileOtp();
  // }, [mobileOtp])

  const handleLearnerPassAboutPopup = () => {
    setShowAboutLearnerPass(false);
  }
  return (
    <>
      {!showSuccessScreen && (
        <div className={classes.signUpFormSection}>
          <div>
            {/* <h1 className={classes.formHeading}>{AppConstants.signupHeadingText}</h1> */}
            <div className={classes.qrenciaLogoSection}>
              <NavLink to="/">
                <img
                  className={classes.qrenciaLogo}
                  src={qrenciaLogoUrl}
                  alt="Qrencia Logo"
                />
              </NavLink>
            </div>
            <h1 className={classes.formHeading}>
              {AppConstants.signupHeadingText}
            </h1>
            <form
              id="signUpForm"
              className={classes.formSection}
              onSubmit={registerUser}
            >
              <div className={classes.searchFormElementsSection}>
                <div className={classes.nameSection}>
                  <div className={classes.nameField}>
                    <div className={classes.inputContainer}>
                      <Form.Label
                        className={`${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        {AppConstants.enterFirstNameLabelText}
                      </Form.Label>
                      <Form.Control
                        className={`${formClass.formInput} ${classes.formInputBox}`}
                        name="firstName"
                        value={formValues.firstName}
                        onChange={handleChange}
                        minLength={3}
                        maxLength={40}
                        type="text"
                        required
                      />
                    </div>
                    <p className={classes.errorMessage}>
                      {formErrors.firstName}
                    </p>
                  </div>
                  <div className={classes.nameField}>
                    {' '}
                    <div className={classes.inputContainer}>
                      <Form.Label
                        className={`${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        {AppConstants.enterLastNameLabelText}
                      </Form.Label>
                      <Form.Control
                        name="lastName"
                        value={formValues.lastName}
                        onChange={handleChange}
                        minLength={3}
                        maxLength={40}
                        className={`${formClass.formInput} ${classes.formInputBox}`}
                        type="text"
                        required
                      />
                    </div>
                    <p className={classes.errorMessage}>
                      {formErrors.lastName}
                    </p>
                  </div>
                </div>
                <div className={classes.nameSection}>
                  <div className={classes.nameField}>
                    <div
                      className={`${classes.inputContainer} ${formClass.dateTimeInput}`}
                    >
                      <Form.Label
                        className={`${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        {AppConstants.enterDobSignup}{' '}
                        <span
                          data-tooltip-place="top"
                          data-tooltip-id="dobTooltip"
                        >
                          <FaInfo className={classes.iButton} />
                        </span>
                      </Form.Label>
                      <Form.Control
                        name="dob"
                        value={formValues.dob}
                        onChange={handleChange}
                        className={`${formClass.formInput} ${classes.formInputBox}`}
                        type="date"
                        required
                      />
                      <Tooltip
                        className={classes.tooltipMessage}
                        id="dobTooltip"
                      >
                        <p>{AppConstants.iButtonMessageForDOB}</p>
                      </Tooltip>
                    </div>
                    <p className={classes.errorMessage}>{formErrors.dob}</p>
                  </div>
                  <div className={classes.nameField}>
                    <div className={classes.inputContainer}>
                      <Form.Label
                        className={`${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        Gender
                      </Form.Label>
                      <Form.Select
                        className={formClass.selectInput}
                        value={formValues.gender}
                        onChange={handleChange}
                        name="gender"
                      >
                        <option value={AppConstants.emptyString}>
                          Please select gender
                        </option>
                        {AppConstants.gender.map((gender) => (
                          <option value={gender} key={gender}>
                            {gender}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <p className={classes.errorMessage}>{formErrors.gender}</p>
                  </div>
                </div>
                {!emailVerificationScreen && (
                  <div
                    className={`${classes.inputContainer} ${classes.positionRelative}`}
                  >
                    <Form.Label
                      className={`${formClass.formLabel} ${classes.inputLabel}`}
                    >
                      {AppConstants.enterEmailLabelText}
                      <span
                        data-tooltip-place="top"
                        data-tooltip-id="emailToolTip"
                      >
                        <FaInfo className={classes.iButton} />
                      </span>
                    </Form.Label>
                    <Tooltip
                      className={classes.tooltipMessage}
                      id="emailToolTip"
                    >
                      <p>{AppConstants.iButtonMessageForEmail}</p>
                    </Tooltip>
                    <Form.Control
                      name="email"
                      value={formValues.email}
                      onKeyUp={handleEmailChange}
                      onChange={handleChange}
                      className={`${formClass.formInput} ${classes.formInputBox}`}
                      type="email"
                      required
                      disabled={emailVerified}
                    />
                    <div className={classes.verifyOtpText}>
                      {!emailVerified && showVerifyEmailText && (
                        <p
                          onClick={
                            !emailVerifyButtonDisable ? handleVerifyEmail : null
                          }
                          style={{
                            cursor: emailVerifyButtonDisable
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        >
                          Verify
                        </p>
                      )}{' '}
                      {emailVerified && (
                        <p>
                          <FaCheckCircle color="green" fontSize="1.2em" />
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {!emailVerificationScreen && (
                  <p className={classes.errorMessage}>{formErrors.email}</p>
                )}

                {emailVerificationScreen && (
                  <div>
                    <Form.Label
                      className={`${formClass.formLabel} ${classes.inputLabel}`}
                    >
                      {AppConstants.enterEmailLabelText}
                    </Form.Label>
                    <div className={classes.verifyEmailContainer}>
                      <div className={classes.emailContainer}>
                        <p className={classes.emailDisabledText}>
                          {formValues.email}
                        </p>
                        <div
                          onClick={backToForm}
                          className={classes.editEmailText}
                        >
                          <FaEdit /> Edit
                        </div>
                      </div>
                      <div className={`${classes.emailOtpInputContainer}`}>
                        <OTPInput
                          value={emailOtp}
                          onChange={setEmailOtp}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          className={classes.emailOtpInputBox}
                        />
                        <ResendOTP
                          onResendClick={() => handleVerifyEmail()}
                          renderButton={renderButton}
                          renderTime={renderTime}
                        />
                      </div>
                      {formErrors.emailOtpError ? (
                        <p className={classes.errorMessage}>
                          {formErrors.emailOtpError}
                        </p>
                      ) : (
                        <p className={classes.otpInfoText}>
                          Please enter the 6-digit code sent on your email
                        </p>
                      )}
                    </div>
                  </div >
                )}

                {
                  !emailVerificationScreen && (
                    <div
                      className={`${classes.inputContainer} ${classes.positionRelative}`}
                    >
                      <Form.Label
                        className={`${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        {AppConstants.enterPasswordLabelText}
                      </Form.Label>
                      <Form.Control
                        name="password"
                        value={formValues.password}
                        onChange={handleChange}
                        className={`${formClass.formInput} ${classes.formInputBox}`}
                        minLength={8}
                        maxLength={16}
                        type={passwordType}
                        required
                      />
                      <div className={classes.showHidePasswordIcon}>
                        {passwordType === AppConstants.fieldTypePassword ? (
                          <FaEye onClick={togglePassword} />
                        ) : (
                          <FaEyeSlash onClick={togglePassword} />
                        )}
                      </div>
                    </div>
                  )
                }
                {
                  !emailVerificationScreen && (
                    <p className={classes.errorMessage}>{formErrors.password}</p>
                  )
                }
                {
                  !emailVerificationScreen && (
                    <div className={classes.selectCountry}>
                      <Form.Label
                        className={`${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        {AppConstants.selectCountryLabelText}
                      </Form.Label>
                      <Form.Select
                        className={`${formClass.selectInput} ${classes.formInputBox}`}
                        value={formValues.countryId}
                        name="countryId"
                        selected={selectedCountry}
                        onChange={(e) => {
                          handleChange(e);
                          onSelectingCountry(e);
                          changeCountryCapital(e);
                        }}
                        required
                      >
                        <option value="" defaultValue></option>
                        {countriesList.map((countries) => (
                          <option value={countries.id} key={countries.id}>
                            {countries.country}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  )
                }

                {
                  !emailVerificationScreen && !mobileVerificationScreen && (
                    <div
                      className={`${classes.inputContainer} ${classes.positionRelative}`}
                    >
                      <label
                        className={`${classes.mobileLabel} ${formClass.formLabel} ${classes.inputLabel}`}
                      >
                        {AppConstants.enterMobileText}
                        <span
                          data-tooltip-place="top"
                          data-tooltip-id="mobileToolTip"
                        >
                          <FaInfo className={classes.iButton} />
                        </span>
                      </label>
                      <Tooltip
                        className={classes.tooltipMessage}
                        id="mobileToolTip"
                      >
                        <p>{AppConstants.iButtonMessageForMobile}</p>
                      </Tooltip>
                      <div className={`${classes.unitInput}`}>
                        <span
                          className={`${classes.unitInputPrepend} ${callingCode.length === 0 ? classes.displayNone : classes.unitInputPrepend}`}
                        >
                          {callingCode.length === 0
                            ? null
                            : callingCode[0].countryCallingCode}
                        </span>
                        <input
                          name="mobileNumber"
                          value={formValues.mobileNumber}
                          onKeyUp={handleMobileChange}
                          onChange={(e) => {
                            handleChange(e);
                            handleChangeMobileNumber(e);
                          }}
                          min={0}
                          step={1}
                          className={`${classes.formInputBox} ${classes.unitInputBox}`}
                          onKeyDown={validateMobileNumber}
                          type="number"
                          required
                        />
                      </div>

                      {/* <div className={classes.verifyOtpText}>{!mobileVerified && showVerifyMobileText && <p onClick={handleVerifyMobile}>Verify</p>} {mobileVerified && <p><FaCheckCircle color="green" fontSize="1.2em" /></p>}</div> */}
                    </div>
                  )
                }
                {
                  !emailVerificationScreen && !mobileVerificationScreen && (
                    <p className={classes.errorMessage}>
                      {formErrors.mobileNumber}
                    </p>
                  )
                }

                {/* {mobileVerificationScreen && <div>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterMobileText}</Form.Label>
                                    <div className={classes.verifyEmailContainer}>
                                        <div className={classes.emailContainer}>
                                            <p className={classes.emailDisabledText}>{callingCode[0]?.countryCallingCode + "-" + formValues.mobileNumber}</p>
                                            <div onClick={backToForm} className={classes.editEmailText}><FaEdit /> Edit</div>
                                        </div>
                                        <div className={`${classes.emailOtpInputContainer}`}>
                                            <OTPInput
                                                value={mobileOtp}
                                                onChange={setMobileOtp}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                className={classes.emailOtpInputBox}
                                            />
                                            <ResendOTP onResendClick={() => handleVerifyMobile()} renderButton={renderButton} renderTime={renderTime} />
                                        </div>
                                        {formErrors.mobileOtpError ? <p className={classes.errorMessage}>{formErrors.mobileOtpError}</p> : <p className={classes.otpInfoText}>Please enter the 6-digit code sent on your mobile number</p>}
                                    </div>
                                </div>} */}
                {
                  !emailVerificationScreen && !mobileVerificationScreen && (<div className={classes.signupButtonContainer}>
                    <div className={classes.checkboxContainer}>
                      <input type="checkbox"
                        onChange={handleLPCheckboxChange}
                        checked={lpChecked} />
                      <p>{' '}I want to buy the{' '}
                        <span className={classes.aboutLearnerPassText} onClick={() => setShowAboutLearnerPass(true)} >Learner Pass</span>{' '}
                        to enjoy additional benefits! ✨
                      </p>
                    </div>
                  </div>)
                }
                {
                  !emailVerificationScreen && !mobileVerificationScreen && (
                    <div className={classes.signupButtonContainer}>
                      {showSpinner && (
                        <Button className={classes.signupButton}>
                          <BiLoaderAlt className={classes.spinner} />
                        </Button>
                      )}
                      {!showSpinner && (
                        <Button
                          type="submit"
                          value="Submit"
                          className={classes.signupButton}
                        >
                          {AppConstants.signUpButtonText}
                        </Button>
                      )}
                    </div>
                  )
                }
                <div>
                  <p
                    className={`${showError.length === 0 ? classes.displayNone : classes.errorMessageText}`}
                  >
                    {showError}
                  </p>
                  <p
                    className={`${showSuccess.length === 0 ? classes.displayNone : classes.successMessageText}`}
                  >
                    {showSuccess}
                  </p>
                </div>
              </div >
            </form >
          </div >
          <div>
            {!emailVerificationScreen && !mobileVerificationScreen && (
              <div className={classes.tncTextContainer}>
                <p className={classes.tncText}>
                  By Signing Up, you agree to Qrencia{' '}
                  <NavLink
                    target="_blank"
                    className={classes.tncTextHyper}
                    to={AppConstants.termsAndConditions}
                  >
                    Terms & Conditions
                  </NavLink>
                  &nbsp;and{' '}
                  <NavLink
                    target="_blank"
                    className={classes.tncTextHyper}
                    to={AppConstants.privacyPolicy}
                  >
                    Privacy Policy
                  </NavLink>
                </p>
              </div>
            )}
            <div className={classes.loginTextContainer}>
              <p className={classes.tncText}>
                Already a member ? Please{' '}
                <NavLink
                  className={classes.tncTextHyper}
                  to={AppConstants.loginPageUrl}
                >
                  Login
                </NavLink>
              </p>
            </div>
          </div>
        </div >
      )}
      {
        showSuccessScreen && (
          <div className={classes.successScreenContainer}>
            <p className={classes.signupSuccessSalutation}>
              {SuccessMessage.signupSuccessSalutation1}{' '}
              <span className={classes.signupUsername}>{successUserName}</span>
              {SuccessMessage.signupSuccessSalutation2}{' '}
            </p>
            <p className={classes.signupSuccessSubHeading}>
              You have been successfully signed up.
            </p>
            <p className={`${classes.tncText} ${classes.signupSuccessSubtitle}`}>
              Please wait while we log you in <BiLoaderAlt className="spinner" />
            </p>
          </div>
        )
      }

      {
        showAboutLearnerPass &&
        <SignUpPopUp onClose={handleLearnerPassAboutPopup} />
      }
    </>
  );
};
