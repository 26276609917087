import { create } from 'zustand';
import { FetchLearnerProfileHeatmapData } from '../utils/FetchLearnerProfileHeatmapData';
import { LEARNER_PROFILE_DATE } from '../constants/AppConstants';

const useLearnerProfileHeatmapStore = create((set) => ({
  learnerProfileHeatmapData: null,
  setLearnerProfileHeatmapData: (data) =>
    set(() => ({
      learnerProfileHeatmapData: data,
    })),
  initializeLearnerProfileHeatmapData: async (learnerSlug) => {
    try {
      const data2023 = await FetchLearnerProfileHeatmapData({
        userSlug: learnerSlug,
        date: LEARNER_PROFILE_DATE.qrenciaInceptionDate,
      });

      set(() => ({
        learnerProfileHeatmapData: Array.isArray(data2023)
          ? data2023
          : [],
      }));
    } catch (error) {
      set(() => ({
        learnerProfileHeatmapData: [],
      }));
    }
  },
}));

export default useLearnerProfileHeatmapStore;
