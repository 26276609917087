import { AgGridReact } from 'ag-grid-react';
import classes from './ExpertStudentPII.module.css';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  AppConstants,
  EXPERT_STUDENT_DETAILS,
  subscriptionSummaryPiiDataAPI,
} from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { Button, Row } from 'react-bootstrap';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { Badge } from "react-bootstrap";
import dayjs from 'dayjs';


import moment from 'moment';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
const ExpertStudentPII = () => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const gridRef = useRef(null); // Reference for AG Grid
  const [rowData, setRowData] = useState([]);

  const fetchGridData = useCallback(async () => {
    const url = subscriptionSummaryPiiDataAPI;
    const response = await AuthorizedDataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    setRowData(response);
  }, []);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  const studentPIIColumnDefs = [
    { headerName: 'QID', field: 'qid', sortable: true, filter: true, width: 80},
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filter: true,
      sortable: true,
      sort: 'asc',
      width: 140
    },
    {
      headerName: 'Course Name',
      field: 'courseTitle',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 170,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Enroled On',
      field: 'enrolledOn',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 123,
      valueGetter: ({ data }) => {
        return data.enrolledOn
          ? `${moment.utc(data.enrolledOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      tooltipValueGetter: ({ data }) =>
        data.enrolledOn
          ? `${moment.utc(data.enrolledOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      comparator: (valueA, valueB) => {
        const dateA = moment.utc(valueA, 'D MMM YYYY');
        const dateB = moment.utc(valueB, 'D MMM YYYY');
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      headerName: 'Last Renewal',
      field: 'lastEnrolledOn',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 125,
      valueGetter: ({ data }) => {
        return data.lastEnrolledOn
          ? `${moment.utc(data.lastEnrolledOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      tooltipValueGetter: ({ data }) =>
        data.lastEnrolledOn
          ? `${moment.utc(data.lastEnrolledOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      comparator: (valueA, valueB) => {
        const dateA = moment.utc(valueA, 'D MMM YYYY');
        const dateB = moment.utc(valueB, 'D MMM YYYY');
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      headerName: 'DOB',
      field: 'dob',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 87,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format('DD-MM-YYYY') : AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.value ? dayjs(params.value).format('DD-MM-YYYY') : AppConstants.notAvailableText,
    },
    {
      headerName: 'Gender',
      field: 'gender',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      valueFormatter: (params) => params.value ?? AppConstants.notAvailableText,
      width: 105,
      
    },
    {
      headerName: 'Address',
      field: 'address',
      width: 110,
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      valueFormatter: (params) => params.value ?? AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Phone',
      field: 'mobileNumber',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 110,
      valueFormatter: (params) => params.value ?? AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Father Name',
      field: 'fatherName',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 130,
      valueFormatter: (params) => params.value ?? AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Mother Name',
      field: 'motherName',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 130,
      valueFormatter: (params) => params.value ?? AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'School',
      field: 'schoolName',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      valueFormatter: (params) => params.value ?? AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
  ];

  //Export CSV data to Excel sheet
  const exportToExcel = useCallback(() => {
    const csvData = gridRef.current?.api.getDataAsCsv({
      allColumns: true,
      columnSeparator: AppConstants.agGridColumnSeparator,
      suppressQuotes: AppConstants.trueText,
    });
    const columnDefs = gridRef.current?.api.getColumnDefs(); // Access column definitions
    FileOperationsUtil.downloadCsvDataAsExcelWithStyling(
      csvData,
      'Student-PII.xlsx',
      columnDefs
    );
  }, []);

  return (
    <div>
      <h3 className={classes.heading}>Student Personal Information</h3>
      <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={studentPIIColumnDefs}
          rowData={rowData}
          pagination={true}
          paginationPageSize={
            EXPERT_STUDENT_DETAILS.paginationPerPageResultSize
          }
          suppressRowHoverHighlight={true}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          defaultColDef={{
            resizable: true,
          }}
        />
      </div>
      {rowData?.length > 0 && (
        <Row className="mt-4">
          <div>
            <Button className={classes.exportButton} onClick={exportToExcel}>
              Download
            </Button>
          </div>
        </Row>
      )}
    </div>
  );
};

export default ExpertStudentPII;
