import React from 'react';
import classes from './LeadStatus.module.css';

// 'New', 'Not Interested (Call 1)', 'Demo Scheduled', 'Demo Completed (High)', 'Demo Completed (Low)', 'Payment Link Sent', 'Not Interested & Closed', 'Subscribed')

const defaultStatusColor = {
  backgroundColor: '#f5f5f5',
  color: '#9e9e9e',
};

const statusColors = {
  New: {
    backgroundColor: '#e9f8ff',
    color: '#7ebbd7',
  },
  'Not Interested (Call 1)': {
    backgroundColor: '#fff6d6',
    color: '#bd9300',
  },
  'Demo Scheduled': {
    backgroundColor: '#fff0fc',
    color: '#e373cc',
  },
  'Demo Completed (High)': {
    backgroundColor: '#dafff3',
    color: '#40c79b',
  },
  'Demo Completed (Low)': {
    backgroundColor: '#ffecdd',
    color: '#f79e54',
  },
  'Payment Link Sent': {
    backgroundColor: '#f3ecfd',
    color: '#a374e7',
  },
  'Not Interested & Closed': {
    backgroundColor: '#f8e8e8',
    color: '#d17a7a',
  },
  Subscribed: {
    backgroundColor: '#e8ffe9',
    color: '#77d17a',
  },
};

const LeadStatus = ({ status }) => {
  const chipBackgroundColor = statusColors[status]?.['backgroundColor'] || defaultStatusColor['backgroundColor'];
  const chipTextColor = statusColors[status]?.['color'] || defaultStatusColor['color'];
  return (
    <div className={classes.statusWrapper} style={{ backgroundColor: chipBackgroundColor, color: chipTextColor }}>
      <div className={classes.statusChipText}>{status}</div>
    </div>
  );
};

export default LeadStatus;
