import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  AppConstants,
  LEARNER_PASS_DASHBOARD,
} from '../constants/AppConstants';
import { FetchMembershipStatus } from '../utils/SynchronizeMembershipStatus';
import Auth from '../utils/Auth';
import { getNumberOfDaysUntilLearnerPassExpiry } from '../utils/calculateNumberOfRemainingDays';
import { fetchLearnerPassSubscriptionPlans } from '../component/subscription/LearnerPass/fetchSubscriptionPlans';

const useLearnerPassStore = create(
  persist(
    (set) => ({
      wantsToBuyLearnerPass: false,
      learnerPassSubscriptionStatus: null,
      learnerPassExpiryDate: null,
      learnerPassNotificationThreshold:
        LEARNER_PASS_DASHBOARD.learnerPassNotificationThreshold,
      numberOfDaysRemaining: null,
      learnerPassSubscriptionPlans: null,
      setLearnerPassSubscriptionStatus: (status) =>
        set({ learnerPassSubscriptionStatus: status }),
      setLearnerPassExpiryDate: (date) => set({ learnerPassExpiryDate: date }),
      setLearnerPassNotificationThreshold: (threshold) =>
        set({ learnerPassNotificationThreshold: threshold }),
      setWantsToBuyLearnerPass: (wantsToBuyLearnerPass) =>
        set({ wantsToBuyLearnerPass }),
      setNumberOfDaysRemaining: (days) => set({ numberOfDaysRemaining: days }),
      setLearnerPassSubscriptionPlans: (plans) =>
        set({ learnerPassSubscriptionPlans: plans }),
      isInitialized: false,

      initializeLearnerPassStore: async () => {
        try {
          const data = await FetchMembershipStatus({
            userEmail: Auth.getLoggedInUserDetails().userEmail,
            membershipType: 'Learner Pass',
          });

          if (data) {
            set({
              learnerPassSubscriptionStatus:
                data.membershipStatus ?? 'Not Purchased',
              learnerPassExpiryDate: data.membershipExpiryOn,
            });
          } else {
            set({
              learnerPassSubscriptionStatus: 'Not Purchased',
              learnerPassExpiryDate: null,
            });
          }
        } catch (error) {
          set({
            learnerPassSubscriptionStatus: 'Not Purchased',
            learnerPassExpiryDate: null,
          });
          throw error;
        }

        try {
          const data = await fetchLearnerPassSubscriptionPlans();
          if (data) {
            set({
              learnerPassSubscriptionPlans: data,
            });
          } else {
            console.warn('No valid subscription plans found.');
            set({
              learnerPassSubscriptionPlans: null,
            });
          }
        } catch (error) {
          set({
            learnerPassSubscriptionPlans: null,
          });
          throw error;
        }
        set({ isInitialized: true });
      },
    }),
    { name: 'learnerPassStorage' }
  )
);

export default useLearnerPassStore;
