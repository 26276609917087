import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { AgencyHeader } from '../../layout/AgencyPageLayout/AgencyHeader';
import { AgencyLeftNav } from '../../layout/AgencyPageLayout/AgencyLeftNav';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { AgencyFooter } from '../../layout/AgencyPageLayout/AgencyFooter';
import { AgencyRightWidgetSpace } from '../../layout/AgencyPageLayout/AgencyRightWidgetSpace';
import classes from './AgencyTemplate.module.css';
import { useLoaderData } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const AgencyTemplate = () => {
  const [footerDetails, agencyDetails, isUserEligibleForNudges] =
    useLoaderData();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const hiddenPaths = [
    '/agency/skill-progress/create-assessment',
    '/agency/skill-progress/create-template',
  ]; // Add the paths where you want to hide sections
  const shouldHideSections = hiddenPaths.includes(location.pathname);

  const myActivitiesHandler = () => {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <AgencyHeader
        agencyDetails={agencyDetails}
        isUserEligibleForNudges={isUserEligibleForNudges}
      />
      <div>
        {!shouldHideSections && (
          <div className={classes.myActivitiesSection}>
            <Button
              className={classes.myActivitiesButton}
              onClick={myActivitiesHandler}
            >
              My Activities{' '}
              {!open ? (
                <RiArrowDownSLine className={classes.activityIcon} />
              ) : (
                <RiArrowUpSLine className={classes.activityIcon} />
              )}
            </Button>
          </div>
        )}
        <Row>
          <Col lg={2}>
            <AgencyLeftNav
              agencyDetails={agencyDetails}
              isUserEligibleForNudges={isUserEligibleForNudges}
            />
          </Col>
          <div className={classes.mobileViewRightWidgetSection}>
            {open && <AgencyRightWidgetSpace />}
          </div>
          <Col
            xs={12}
            sm={12}
            md={shouldHideSections ? 12 : 8}
            lg={shouldHideSections ? 10 : 7}
          >
            <Outlet />
          </Col>
          {!shouldHideSections && (
            <Col md={4} lg={3} className="d-none d-md-block d-lg-block">
              <AgencyRightWidgetSpace />
            </Col>
          )}
        </Row>
      </div>
      {footerDetails && <AgencyFooter footerDetails={footerDetails} />}
    </>
  );
};
