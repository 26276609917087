import React from 'react';
import { SKILL_PROGRESS_ASSESSMENT_CONSTANTS } from '../../../constants/AppConstants';
import classes from './SkillAssessmentReport.module.css';
import { Row } from 'react-bootstrap';

const SkillAssessmentReportFooter = () => {
  return (
    <>
      <Row>
        <div className={`${classes.SkillAssessmentReportFooter} col-12`}>
          {SKILL_PROGRESS_ASSESSMENT_CONSTANTS.poweredByQrencia}
        </div>
      </Row>
    </>
  );
};

export default SkillAssessmentReportFooter;
