import { useState } from "react";
// import { Carousel } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import classes from './SignupPageCarousel.module.css';
import { AppConstants } from "../../constants/AppConstants";

export const SignupAndLinkToSchoolPageCarousel = (props) => {
    const [carouselDetails, setCarouselDetails] = useState(props.signupPageData.slides);

    const responsiveSlider = {
        desktop: {
            breakpoint: { max: 3000, min: 991 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 990, min: 481 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div className={classes.mobileCarouselSignup}>
                <Carousel responsive={responsiveSlider} autoPlay={AppConstants.trueText}
                    autoPlaySpeed={AppConstants.ambassadorChallengeCarouselDuration}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={AppConstants.trueText}
                    className="eoiBannerCarouselContainerImage">
                    {carouselDetails.filter((data) => data.active === AppConstants.trueText).map((slide, index) => (
                        <div className={`${classes.imageContainer} ${classes[`backgroundSlide${index + 1}`]}`}>
                            <div className={classes.imageContainer}>
                                <img key={slide.id} alt="Banner" className={classes.carouselImage} src={slide.imagePath} />
                            </div>
                            <div className={classes.carouselCaption}>
                                <h3 className={classes.headingText}>{slide.title}</h3>
                                <p className={classes.subHeadingText}>
                                    {slide.subTitle}
                                </p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </>
    );
}