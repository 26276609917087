import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LEARNER_PASS_POPUP_IMAGES } from '../../../constants/AppConstants';
import styles from './LearnerWelcomeScreen.module.css';
// import commonStyles from './LearnerP';
import Auth from '../../../utils/Auth';
import {
  useLearnerPassPaymentPopupStore,
  useLearnerPassStore,
} from '../../../stores';

const LearnerWelcomeScreen = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const illustrationURL = `${mediaContent}${LEARNER_PASS_POPUP_IMAGES.learnerPassWelcomeImage}`;
  const username = Auth.getLoggedInUserDetails().firstName;

  // const buyLater = () => {
  //   onClose(); // Close the modal
  // };

  const {
    showLearnerPassWelcomeScreen,
    showLearnerPassPaymentPopup,
    toggleLearnerPassPaymentPopup,
    setShowLearnerPassWelcomeScreen,
  } = useLearnerPassPaymentPopupStore();

  const { wantsToBuyLearnerPass, setWantsToBuyLearnerPass } =
    useLearnerPassStore();

  const handleBuyLater = () => {
    setWantsToBuyLearnerPass(false);
    setShowLearnerPassWelcomeScreen(false);
  };

  const handleProceed = () => {
    setWantsToBuyLearnerPass(false);
    setShowLearnerPassWelcomeScreen(false);
    toggleLearnerPassPaymentPopup();
  };

  return (
    <div className={styles.modalSection}>
      <Modal
        show={showLearnerPassWelcomeScreen}
        onHide={handleBuyLater}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Body className={styles.modalBody}>
          <div className={styles.container}>
            <div className={styles.illustrationContainer}>
              <img
                className={styles.illustration}
                src={illustrationURL}
                alt="Illustration"
              />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.textContainer}>
                <h4 className={styles.title}>Complete Payment</h4>

                <p className={styles.description}>
                  Hi {username}, let's complete your payment for the Learner
                  Pass.
                  {/* <span className="textHighlight">Learner Pass</span>. */}
                </p>
              </div>
              <div className="buttonContainer">
                <div className={styles.buttonContainer}>
                  <div
                    className={styles.laterButton}
                    variant="primary"
                    onClick={handleBuyLater}
                  >
                    Later
                  </div>
                  <Button
                    className={styles.button}
                    variant="primary"
                    onClick={handleProceed}
                  >
                    Proceed to Pay
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LearnerWelcomeScreen;
