import { useCallback, useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Col, Row } from 'react-bootstrap';
import classes from '../monitorNonOnboardedLeads/MonitorNonOnboardedLeads.module.css';
import moment from 'moment';
import { AppConstants } from '../../../../constants/AppConstants';
import { BiLoaderAlt } from 'react-icons/bi';
import { BsAlarm, BsEye } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa6';
import { LeadDetailsModal } from '../../../modal/LeadDetailsModal';
import { AuthorizedDataService } from '../../../../services/AuthorizedDataService';
import { DateTimeUtil } from '../../../../utils/DateTimeUtil';
import { OfferingsModal } from '../../../modal/OfferingsModal';
import { ErrorSuccessAlertMessage } from '../../../errorSuccessMessage/ErrorSuccessAlertMessage';
import { SuccessMessage } from '../../../../constants/SuccessMessage';
import { ErrorMessages } from '../../../../constants/ErrorMessages';
import { FileOperationsUtil } from '../../../../utils/FileOperationsUtil';
import { MathUtil } from '../../../../utils/MathUtil';
import MembershipStatusChip from '../../MembershipStatusChip/MembershipStatusChip';
import AccountStatusIndicator from '../../AccountStatusIndicator/AccountStatusIndicator';
import LearnerPassProgressBar from '../../LearnerPassProgressBar/LearnerPassProgressBar';

export const MonitorOnboardedLeads = (props) => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const gridRef = useRef(null);
  const [showLeadDetailsModal, setShowLeadDetailsModal] = useState(
    AppConstants.falseText
  );
  const [selectedLead, setSelectedLead] = useState({});
  const [creatorName, setCreatorName] = useState(AppConstants.emptyString);
  const [offeringDetails, setOfferingDetails] = useState({});
  const [showOfferingModal, setShowOfferingModal] = useState(
    AppConstants.falseText
  );
  const [reminderAlertVarient, setReminderAlertVarient] = useState(0);
  const [reminderAlertMessage, setReminderAlertMessage] = useState(
    AppConstants.emptyString
  );
  const [sendReminderSpinner, setSendReminderSpinner] = useState(
    new Array(props.leadsList?.length).fill(AppConstants.falseText)
  );
  const [
    sendReminderRenewMembershipSpinner,
    setSendReminderRenewMembershipSpinner,
  ] = useState(new Array(props.leadsList?.length).fill(AppConstants.falseText));
  const [sendReminderLearnerPassSpinner, setSendReminderLearnerPassSpinner] =
    useState(new Array(props.leadsList?.length).fill(AppConstants.falseText));
  const icons = AppConstants.qrenciaApplicationIcons;

  const getIconPath = (icon) => {
    return `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.iconImagesS3Path}${AppConstants.forwardSlash}${icon}`;
  };

  useEffect(() => {
    if (props.leadsList !== null && props.leadsList !== undefined) {
      setSendReminderSpinner(
        new Array(props.leadsList?.length).fill(AppConstants.falseText)
      );
    }
  }, [props.leadsList]);

  const toggleViewComponent = (data) => {
    let skillIntegerArrays = data.skills?.split(',').map(Number);
    let selectedSkills = props.skillDetails
      .filter((item) => {
        return skillIntegerArrays?.some(
          (skillId) => skillId === item.skillUniqueId
        );
      })
      ?.map((item) => ({
        skillId: item.skillId,
        skillName: item.skill,
        skillIntegerId: item.skillUniqueId,
      }));

    setSelectedLead({ ...data, selectedSkills });
    setShowLeadDetailsModal(AppConstants.trueText);
  };

  const closeLeadDetailsModal = () => {
    setShowLeadDetailsModal(AppConstants.falseText);
    setSelectedLead({});
  };

  const getValidTextClass = ({ value }) => {
    if (
      value === AppConstants.expertObrStatus.statusAccepted ||
      value === AppConstants.yText
    ) {
      return `${classes.obrGreenText}`;
    } else if (
      value === AppConstants.expertObrStatus.statusRejected ||
      value === AppConstants.nText
    ) {
      return `${classes.obrRedText}`;
    } else if (value === AppConstants.expertObrStatus.statusDraft) {
      return `${classes.obrOrangeColorText}`;
    } else if (value === AppConstants.expertObrStatus.statusInReview) {
      return `${classes.obrBlueDarkText}`;
    }
  };

  const viewOfferingModal = async (data) => {
    let url = `${AppConstants.getCreatorWiseLearningActivityDetailsAPI}${data.leadType === AppConstants.leadTypes.expert ? data.expertQrenciaId : data.agencyQrenciaId}`;

    const response = await AuthorizedDataService.getRequest(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );

    if (response) {
      setOfferingDetails(response);
      setCreatorName(
        data.leadType === AppConstants.leadTypes.expert
          ? `${data.firstName} ${data.lastName}`
          : data.instituteName
      );
      setShowOfferingModal(AppConstants.trueText);
    } else {
      setCreatorName(AppConstants.emptyString);
      setOfferingDetails({});
      setShowOfferingModal(AppConstants.falseText);
    }

    setShowOfferingModal(AppConstants.trueText);
  };

  const hideDetailsSection = () => {
    setShowOfferingModal(AppConstants.falseText);
    setCreatorName(AppConstants.emptyString);
    setOfferingDetails({});
  };

  function getSKillValueString(skills) {
    if (!skills) return AppConstants.notAvailableText;

    let skillIntegerArrays = skills?.split(',').map(Number);
    return props.skillDetails
      .filter((item) => {
        return skillIntegerArrays?.some(
          (skillId) => skillId === item.skillUniqueId
        );
      })
      ?.map((item) => item.skill)
      .join(',');
  }

  const sendOfferingReminderEmail = async (data, index) => {
    try {
      const payload = {
        leadUniqueId: data.leadUniqueId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        leadType: data.leadType,
        instituteName: data.instituteName
          ? data.instituteName
          : AppConstants.nullText,
      };
      setSendReminderSpinner((sendReminderSpinner) =>
        sendReminderSpinner.map((state, idx) =>
          idx === index ? AppConstants.trueText : state
        )
      );
      const url = AppConstants.sendReminderEmailToListOfferingsAPI;
      const response = await AuthorizedDataService.postRequestWithResponseCode(
        url,
        payload,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (
        response.ok &&
        response.status === AppConstants.httpResponseCodes.responseCode200
      ) {
        setReminderAlertMessage(
          `${SuccessMessage.offeringsReminderEmailSentSuccessfully}${AppConstants.space}${data.firstName}${AppConstants.space}${data.lastName}`
        );
        setSendReminderSpinner((sendReminderSpinner) =>
          sendReminderSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setReminderAlertVarient(AppConstants.alertVarient[0]);
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else if (
        response.status === AppConstants.httpResponseCodes.responseCode500
      ) {
        const body = await response.json();
        if (body === -31) {
          setReminderAlertMessage(
            `${SuccessMessage.offeringsReminderEmailSentSuccessfully}${AppConstants.space}${data.firstName}${AppConstants.space}${data.lastName}`
          );
          setReminderAlertVarient(AppConstants.alertVarient[0]);
        } else if (body === -25) {
          setReminderAlertMessage(
            ErrorMessages.sendReminderToListOfferingsError
          );
          setReminderAlertVarient(AppConstants.alertVarient[1]);
        }
        setSendReminderSpinner((sendReminderSpinner) =>
          sendReminderSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else {
        setReminderAlertMessage(ErrorMessages.sendReminderToListOfferingsError);
        setSendReminderSpinner((sendReminderSpinner) =>
          sendReminderSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setReminderAlertVarient(AppConstants.alertVarient[1]);
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      }
    } catch (error) {
      setReminderAlertMessage(ErrorMessages.sendReminderToListOfferingsError);
      setSendReminderSpinner((sendReminderSpinner) =>
        sendReminderSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setReminderAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const sendRenewSubscriptionReminderEmail = async ({ data, index }) => {
    try {
      const url = `${AppConstants.sendRenewSubscriptionReminderEmailAPI}?creatorEmail=${encodeURIComponent(data.email)}&dateOfExpiry=${encodeURIComponent(data.membershipExpiredOn)}&preferredTimeZone=${data.preferredTimezone}`;

      setSendReminderRenewMembershipSpinner(
        (sendReminderRenewMembershipSpinner) =>
          sendReminderRenewMembershipSpinner.map((state, idx) =>
            idx === index ? AppConstants.trueText : state
          )
      );
      const response = await AuthorizedDataService.getRequestWithResponseCode(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response === 1) {
        setReminderAlertMessage(
          `${SuccessMessage.renewMembershipReminderEmailSentSuccessfully}${AppConstants.space}${
            data.leadType === 'Expert'
              ? `${data.firstName}${AppConstants.space}${data.lastName}`
              : data.instituteName
          }`
        );
        setSendReminderRenewMembershipSpinner(
          (sendReminderRenewMembershipSpinner) =>
            sendReminderRenewMembershipSpinner.map((state, idx) =>
              idx === index ? AppConstants.falseText : state
            )
        );
        setReminderAlertVarient(AppConstants.alertVarient[0]);
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else if (
        response.status === AppConstants.httpResponseCodes.responseCode500
      ) {
        const body = await response.json();
        if (body === -31) {
          setReminderAlertMessage(
            ErrorMessages.sendReminderToRenewMembershipError
          );
          setReminderAlertVarient(AppConstants.alertVarient[0]);
        } else if (body === -25) {
          setReminderAlertMessage(
            ErrorMessages.sendReminderToRenewMembershipError
          );
          setReminderAlertVarient(AppConstants.alertVarient[1]);
        }
        setSendReminderRenewMembershipSpinner(
          (sendReminderRenewMembershipSpinner) =>
            sendReminderRenewMembershipSpinner.map((state, idx) =>
              idx === index ? AppConstants.falseText : state
            )
        );
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else {
        setReminderAlertMessage(
          ErrorMessages.sendReminderToRenewMembershipError
        );
        setSendReminderRenewMembershipSpinner(
          (sendReminderRenewMembershipSpinner) =>
            sendReminderRenewMembershipSpinner.map((state, idx) =>
              idx === index ? AppConstants.falseText : state
            )
        );
        setReminderAlertVarient(AppConstants.alertVarient[1]);
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      }
    } catch (error) {
      setReminderAlertMessage(ErrorMessages.sendReminderToRenewMembershipError);
      setSendReminderRenewMembershipSpinner(
        (sendReminderRenewMembershipSpinner) =>
          sendReminderRenewMembershipSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
      );
      setReminderAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const sendIncreaseLearnerPassSubscriptionReminderEmail = async ({
    data,
    index,
  }) => {
    try {
      const url = `${AppConstants.sendIncreaseLearnerPassSubscriptionReminderEmailAPI}?creatorEmail=${encodeURIComponent(data.email)}&learnerPassPercentage=${encodeURIComponent(data.learnerPassPercentage)}`;
      setSendReminderLearnerPassSpinner((sendReminderLearnerPassSpinner) =>
        sendReminderLearnerPassSpinner.map((state, idx) =>
          idx === index ? AppConstants.trueText : state
        )
      );

      const response = await AuthorizedDataService.getRequestWithResponseCode(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response === 1) {
        setReminderAlertMessage(
          `Learner Pass Reminder sent successfully to ${
            data.leadType === 'Expert'
              ? `${data.firstName} ${data.lastName}`
              : data.instituteName
          }`
        );
        setSendReminderLearnerPassSpinner((sendReminderLearnerPassSpinner) =>
          sendReminderLearnerPassSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setReminderAlertVarient(AppConstants.alertVarient[0]);
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else if (
        response.status === AppConstants.httpResponseCodes.responseCode500
      ) {
        const body = await response.json();
        if (body === -31) {
          setReminderAlertMessage(
            ErrorMessages.sendReminderLearnerPassEmailError
          );
          setReminderAlertVarient(AppConstants.alertVarient[1]);
        } else if (body === -25) {
          setReminderAlertMessage(
            ErrorMessages.sendReminderLearnerPassEmailError
          );
          setReminderAlertVarient(AppConstants.alertVarient[1]);
        }

        setSendReminderLearnerPassSpinner((sendReminderLearnerPassSpinner) =>
          sendReminderLearnerPassSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else {
        setReminderAlertMessage(
          ErrorMessages.sendReminderLearnerPassEmailError
        );
        setSendReminderLearnerPassSpinner((sendReminderLearnerPassSpinner) =>
          sendReminderLearnerPassSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setReminderAlertVarient(AppConstants.alertVarient[1]);
        setTimeout(() => {
          setReminderAlertMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      }
    } catch (error) {
      setReminderAlertMessage(ErrorMessages.sendReminderLearnerPassEmailError);
      setSendReminderLearnerPassSpinner((sendReminderLearnerPassSpinner) =>
        sendReminderLearnerPassSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setReminderAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const onBtnExport = useCallback(() => {
    const selectedColumns = [
      'Qrencia Id',
      'name',
      'skills',
      'phoneNumber',
      'email',
      'leadType',
      'firstSubscribedOn',
      'membershipRenewedOn',
      'accountStatus',
      'learnerPassPercentage',
      'totalRevenue',
      'offeringListed',
    ]; // Define the columns to export

    const csvData = gridRef.current?.api.getDataAsCsv({
      columnKeys: selectedColumns,
      columnSeparator: AppConstants.agGridColumnSeparator,
      suppressQuotes: AppConstants.trueText,
    });

    FileOperationsUtil.downloadCsvDataAsExcel(csvData, 'Onboarded-Leads.xlsx');
  }, []);

  const redirectToBrandingPage = (data) => {
    let brandingPageLink =
      data.leadType === AppConstants.leadTypes.expert
        ? `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expert}${AppConstants.forwardSlash}${data.userSlug}`
        : `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${data.userSlug}`;
    window.open(brandingPageLink, AppConstants.openInNewTab);
  };

  const onboardedColumnDefs = [
    {
      headerName: `Qrencia ID`,
      field: 'Qrencia Id',
      maxWidth: 90,
      tooltipValueGetter: ({ data }) => {
        return data.leadType === AppConstants.leadTypes.expert
          ? data.expertQrenciaId
          : data.agencyQrenciaId;
      },
      valueGetter: ({ data }) => {
        return data.leadType === AppConstants.leadTypes.expert
          ? data.expertQrenciaId
          : data.agencyQrenciaId;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: `Name`,
      field: 'name',
      width: 400,
      tooltipValueGetter: ({ data }) => {
        return data.leadType === AppConstants.leadTypes.expert
          ? `${data.firstName} ${data.lastName}`
          : data.instituteName;
      },
      valueGetter: ({ data }) => {
        return data.leadType === AppConstants.leadTypes.expert
          ? `${data.firstName} ${data.lastName}`
          : data.instituteName;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Skill',
      field: 'skills',
      width: 400,
      tooltipValueGetter: (params) => {
        return getSKillValueString(params.data.skills);
      },
      valueGetter: (params) => {
        return getSKillValueString(params.data.skills);
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Mobile No.',
      field: 'phoneNumber',
      minWidth: 110,
      tooltipValueGetter: ({ data }) =>
        `${data.countryMobileNumberCode}${data.phoneNumber}`,
      valueGetter: ({ data }) => {
        return `${data.countryMobileNumberCode}${data.phoneNumber}`;
      },
      filter: 'agTextColumnFilter',
      cellClass: 'no-type-conversion',
    },
    {
      headerName: `Email`,
      field: 'email',
      width: 400,
      tooltipValueGetter: ({ data }) => `${data.email}`,
      valueGetter: (params) => {
        return `${params.data.email}`;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Type',
      field: 'leadType',
      maxWidth: 90,
      tooltipValueGetter: ({ data }) => `${data.leadType}`,
      valueGetter: (params) => {
        return `${params.data.leadType}`;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'First Subscribed On',
      field: 'firstSubscribedOn',
      width: 400,
      tooltipValueGetter: ({ data }) =>
        data.firstMembershipTakenOn
          ? `${moment.utc(data.firstMembershipTakenOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.firstMembershipTakenOn
          ? `${moment.utc(data.firstMembershipTakenOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Last Subscribed On',
      field: 'membershipRenewedOn',
      width: 400,
      tooltipValueGetter: ({ data }) =>
        data.membershipRenewedOn
          ? `${moment.utc(data.membershipRenewedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.membershipRenewedOn
          ? `${moment.utc(data.membershipRenewedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Membership Status',
      field: 'membershipActiveStatus',
      minWidth: 120,
      tooltipValueGetter: ({ data }) => `${data.membershipActiveStatus}`,
      valueGetter: (params) => {
        return `${params.data.membershipActiveStatus}`;
      },
      cellRendererFramework: (params) => {
        return <MembershipStatusChip status={params.value} />;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: `Operational Status`,
      field: 'accountStatus',
      width: 400,
      tooltipValueGetter: ({ data }) => `${data.accountStatus}`,
      valueGetter: (params) => {
        return `${params.data.accountStatus}`;
      },
      filter: 'agTextColumnFilter',
      cellRendererFramework: (params) => {
        return <AccountStatusIndicator status={params.value} />;
      },
    },
    {
      headerName: 'Learner Pass %',
      field: 'learnerPassPercentage',
      minWidth: 200,
      tooltipValueGetter: ({ data }) =>
        `Learner Pass - ${data.learnerPassCount} | Enrolments - ${data.courseEnrolmentCount} | Percentage - ${data.learnerPassPercentage}%`,
      valueGetter: (params) => {
        return `${params.data.learnerPassPercentage}`;
      },
      filter: 'agTextColumnFilter',
      cellRendererFramework: (params) => {
        return <LearnerPassProgressBar percentage={params.value} />;
      },
    },
    {
      headerName: 'Learner Pass Status',
      field: 'learnerPassPercentage',
      minWidth: 150,
      valueGetter: (params) => {
        const percentage = params.data.learnerPassPercentage;
        return percentage < 75
          ? 'Less than 75%'
          : percentage > 75
            ? 'More than 75%'
            : 'Equal to 75%';
      },
      cellStyle: (params) => {
        const percentage = params.data.learnerPassPercentage;
        return {
          color: percentage >= 75 ? 'green' : 'red',
          fontWeight: 'bold',
          textAlign: 'center',
        };
      },
      tooltipValueGetter: (params) => {
        const percentage = params.data.learnerPassPercentage;
        return percentage >= 75
          ? 'Excellent performance (>= 75%)'
          : 'Needs improvement (< 75%)';
      },
    },
    {
      headerName: 'Revenue (INR)',
      field: 'totalRevenue',
      maxWidth: 80,
      tooltipValueGetter: ({ data }) =>
        MathUtil.formatNumberToLocaleString(data.totalRevenue),
      valueGetter: ({ data }) => {
        return MathUtil.formatNumberToLocaleString(data.totalRevenue);
      },
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
    },
    {
      headerName: 'Offering Listed',
      field: 'offeringListed',
      maxWidth: 80,
      tooltipValueGetter: ({ data }) =>
        data.offeringListedFlag === AppConstants.yText
          ? AppConstants.yText
          : AppConstants.nText,
      valueGetter: ({ data }) => {
        return data.offeringListedFlag === AppConstants.yText
          ? AppConstants.yText
          : AppConstants.nText;
      },
      filter: 'agTextColumnFilter',
      cellClass: getValidTextClass,
      headerClass: 'multi-line-header',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRendererFramework: (params) => (
        <div className={classes.obrGridActionBox}>
          <Button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => toggleViewComponent(params.data)}
            disabled={params.data.isActiveFlag === AppConstants.falseFlag}
          >
            <BsEye />
          </Button>
          <Button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Branding Page Link"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => redirectToBrandingPage(params.data)}
          >
            <img
              src={getIconPath(icons.brandingPageIconWhite)}
              alt="brandingpage"
              className={classes.imageIcons}
            />
          </Button>
          <Button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Show Offerings"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => {
              viewOfferingModal(params.data);
            }}
          >
            <img
              src={getIconPath(icons.offeringsIconWhite)}
              alt="offerings"
              className={classes.imageIcons}
            />
          </Button>
          <Button
            disabled={
              (params.data.leadType !== AppConstants.leadTypes.expert &&
                params.data.leadType !== AppConstants.leadTypes.academy) ||
              params.data.offeringListedFlag === AppConstants.yText ||
              sendReminderSpinner[params.rowIndex]
            }
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Reminder to list offering"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => {
              sendOfferingReminderEmail(params.data, params.rowIndex);
            }}
          >
            {sendReminderSpinner[params.rowIndex] ? (
              <BiLoaderAlt className="spinner" />
            ) : (
              <BsAlarm />
            )}
          </Button>
        </div>
      ),
      minWidth: 140,
    },
    {
      headerName: 'Reminders',
      field: 'membershipAction',
      cellRendererFramework: (params) => (
        <div className={classes.obrGridActionBox}>
          <Button
            disabled={
              (params.data.leadType !== AppConstants.leadTypes.expert &&
                params.data.leadType !== AppConstants.leadTypes.academy) ||
              params.data.membershipActiveStatus == 'Active' ||
              sendReminderRenewMembershipSpinner[params.rowIndex]
            }
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Send reminder to renew membership"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => {
              sendRenewSubscriptionReminderEmail({
                data: params.data,
                index: params.rowIndex,
              });
            }}
          >
            {sendReminderRenewMembershipSpinner[params.rowIndex] ? (
              <BiLoaderAlt className="spinner" />
            ) : (
              <FaRegMoneyBillAlt />
            )}
          </Button>

          {params.data.learnerPassPercentage <
            AppConstants.learnerPassPercentageThreshold && (
            <Button
              disabled={
                (params.data.leadType !== AppConstants.leadTypes.expert &&
                  params.data.leadType !== AppConstants.leadTypes.academy) ||
                sendReminderLearnerPassSpinner[params.rowIndex]
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Send reminder to increase Learner Pass sales"
              className={`gridIconButton ${classes.gridIcon}`}
              onClick={() => {
                sendIncreaseLearnerPassSubscriptionReminderEmail({
                  data: params.data,
                  index: params.rowIndex,
                });
              }}
            >
              {sendReminderLearnerPassSpinner[params.rowIndex] ? (
                <BiLoaderAlt className="spinner" />
              ) : (
                <FaGraduationCap />
              )}
            </Button>
          )}
        </div>
      ),
      minWidth: 140,
    },
  ];

  return (
    <div className={classes.sectionContainer}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              suppressExcelExport={true}
              className="expertObrDetailsGrid"
              columnDefs={onboardedColumnDefs}
              pagination={true}
              paginationPageSize={AppConstants.adminDashboardPaginationSize}
              rowData={props.leadsList || []}
              enableBrowserTooltips={true}
              domLayout="autoHeight"
              suppressRowHoverHighlight={true}
              defaultColDef={{
                filter: true,
                sortable: true,
                flex: 1,
                minWidth: 100,
                resizable: true,
                suppressMenu: true,
                suppressHorizontalScroll: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                floatingFilter: true,
              }}
              enableFilter={true}
              enableSorting={true}
              enableSearching={true}
              headerClass={classes.obrGridHeader}
              rowClass={classes.obrGridTable}
            />
          </div>
        </Col>
        {reminderAlertMessage && (
          <ErrorSuccessAlertMessage
            message={reminderAlertMessage}
            varient={reminderAlertVarient}
          />
        )}
      </Row>
      {props.leadsList && props.leadsList?.length > 0 && (
        <Row className="mt-4">
          <div>
            <Button className={classes.exportButton} onClick={onBtnExport}>
              Download
            </Button>
          </div>
        </Row>
      )}
      {showLeadDetailsModal && (
        <LeadDetailsModal
          refreshOnboardedLeads={props.refreshOnboardedLeads}
          skillDetails={props.skillDetails}
          selectedLead={selectedLead}
          closeLeadDetailsModal={closeLeadDetailsModal}
          stateList={props.stateList}
          countryList={props.countryList}
          onboardedFlag={AppConstants.trueText}
        />
      )}
      {showOfferingModal && (
        <OfferingsModal
          offeringDetails={offeringDetails}
          hideDetailsSection={hideDetailsSection}
          creatorName={creatorName}
        />
      )}
    </div>
  );
};
