import { LEARNER_PASS_DASHBOARD } from '../../../constants/AppConstants';
import { DataService } from '../../../services/DataService';

export const fetchSubscriptionPlans = async () => {
  const learnerPassSubscriptionPlansURL =
    LEARNER_PASS_DASHBOARD.learnerPassSubscriptionPlansAPI;
  try {
    let data = await DataService.get(learnerPassSubscriptionPlansURL, '', '');

    let planId = await data?.id;

    return planId;
  } catch (error) {
    throw error;
  }
};

export const fetchLearnerPassSubscriptionPlans = async () => {
  const learnerPassSubscriptionPlansURL =
    LEARNER_PASS_DASHBOARD.learnerPassSubscriptionPlansAPI;
  try {
    let data = await DataService.get(learnerPassSubscriptionPlansURL, '', '');

    return data[0];
  } catch (error) {
    // throw error;
    return null;
  }
};
