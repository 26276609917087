import { useCallback, useEffect, useRef, useState } from "react"
import { AppConstants } from "../../constants/AppConstants"
import classes from "./AssignCoursesComponent.module.css";
import { BiLoaderAlt } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { StringUtils } from "../../utils/StringUtils";
import { Button, Col, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const AssignCoursesComponent = ({ selectedCourse, onHideAssign }) => {
    const [showLoader, setShowLoader] = useState(AppConstants.trueText);
    const [expertList, setExpertList] = useState([]);
    const [saveButtonSpinner, setSaveButtonSpinner] = useState(AppConstants.falseText);
    const [selectedExperts, setSelectedExperts] = useState([]);
    const [assignedCheckboxValues, setAssignedCheckboxValues] = useState([]);
    const [assignCourseMessage, setAssignCourseMessage] = useState(AppConstants.emptyString);
    const [alertVarient, setAlertVarient] = useState(AppConstants.emptyString);
    const gridRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAlertVarient(AppConstants.emptyString);
            setAssignCourseMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [assignCourseMessage]);

    const fetchExpertDetails = useCallback(async () => {
        const url = AppConstants.getAssociatedExpertsWithCourseAssignedStatusAPI;
        const response = await AuthorizedDataService.getRequest(StringUtils.createURLWithParams(url, { courseUniqueId: selectedCourse.uniqueId }), AppConstants.emptyString, AppConstants.emptyString);
        if (response) {
            setShowLoader(AppConstants.falseText);
            setExpertList(response);
            setAssignedCheckboxValues(response.map(expert => expert.assignedStatus === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText));
        } else {
            setExpertList([]);
            setShowLoader(AppConstants.falseText);
        }
    }, [selectedCourse.uniqueId]);


    useEffect(() => {
        fetchExpertDetails();
    }, [fetchExpertDetails]);

    const updateSelectedExpertsArray = (event, selectedExpert, index) => {
        let initialStatus = expertList[index].assignedStatus;
        let currentStatus = event.target?.checked ? AppConstants.yText : AppConstants.nText;
        setAssignedCheckboxValues(assignedCheckboxValues => assignedCheckboxValues.map((state, idx) => idx === index ? event.target.checked : state));
        if (initialStatus === currentStatus) {
            // remove from list
            setSelectedExperts(selectedExperts => selectedExperts.filter(expert => expert.userEmail !== selectedExpert.userEmail));
        } else {
            // add to list
            setSelectedExperts([...selectedExperts, {
                userEmail: selectedExpert.userEmail,
                userName: selectedExpert.userName,
                userQrenciaId: selectedExpert.userQrenciaId,
                isActiveFlag: currentStatus
            }]);
        }
    }
    const CustomCheckbox = ({ checked, onChange }) => {
        return (
            <div className={classes.assignCheckbox}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    className={classes.checkBox}
                    onClick={(e) => e.stopPropagation()}  // Prevent row selection
                />
            </div>
        );
    };
    const columnDefs = [
        {
            headerName: "Expert Id",
            field: "userQrenciaId",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => `${data.userQrenciaId}`,
            valueGetter: (params) => {
                return params.data.userQrenciaId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Name",
            field: "userName",
            maxWidth: 110,
            tooltipValueGetter: ({ data }) => `${data.userName}`,
            valueGetter: (params) => {
                return `${params.data.userName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Email",
            field: "userEmail",
            tooltipValueGetter: ({ data }) => `${data.userEmail}`,
            valueGetter: (params) => {
                return `${params.data.userEmail}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Status",
            field: "assignedStatus",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => `${data.assignedStatus === AppConstants.yText ? "Assigned" : "Unassigned"}`,
            valueGetter: ({ data }) => {
                return `${data.assignedStatus === AppConstants.yText ? "Assigned" : "Unassigned"}`
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Assigned On",
            field: "assignedOn",
            tooltipValueGetter: ({ data }) => `${data.assignedOn ? data.assignedOn : AppConstants.notAvailableText}`,
            valueGetter: ({ data }) => {
                return `${data.assignedOn ? data.assignedOn : AppConstants.notAvailableText}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Assign",
            maxWidth: 80,
            cellRendererFramework: (params) => (
                <CustomCheckbox
                    checked={assignedCheckboxValues[params.rowIndex]}
                    onChange={(e) => updateSelectedExpertsArray(e, params.data, params.rowIndex)}
                />
            ),
        }

    ];


    const saveChanges = async () => {
        setSaveButtonSpinner(AppConstants.trueText);

        const url = AppConstants.assingCoursesAPI;
        const data = {
            courseUniqueId: selectedCourse.uniqueId,
            courseTitle: selectedCourse.courseTitle,
            experts: selectedExperts
        };

        const response = await AuthorizedDataService.postRequest(url, data, AppConstants.emptyString, AppConstants.emptyString);

        if (response) {
            setSaveButtonSpinner(AppConstants.falseText);
            setAssignCourseMessage(SuccessMessage.assignCourseSuccessMessage);
            setAlertVarient(AppConstants.alertVarient[0]);
            setSelectedExperts([]);
            fetchExpertDetails();
        } else {
            setSaveButtonSpinner(AppConstants.falseText);
            setAlertVarient(AppConstants.alertVarient[1]);
            setAssignCourseMessage(ErrorMessages.assignCourseErrorMessage)
        }
    }

    return (
        <div className="expertCompDetailsAccordion">
            {showLoader ? <div className={classes.pageLoader}><BiLoaderAlt className="spinner" /></div>
                : <div>
                    <div><FaArrowLeft onClick={onHideAssign} className={classes.goBackButton} /></div>

                    <Row>
                        <h3 className={classes.assingedHeading}>Associated Experts</h3>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    className="expertObrDetailsGrid"
                                    columnDefs={columnDefs}
                                    pagination={true}
                                    ref={gridRef}
                                    paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                    rowData={expertList}
                                    enableBrowserTooltips={true}
                                    domLayout='autoHeight'
                                    suppressRowHoverHighlight={true}
                                    rowSelection="multiple"
                                    defaultColDef={{
                                        filter: true,
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        resizable: true,
                                        suppressMenu: true,
                                        suppressHorizontalScroll: true,
                                        floatingFilterComponentParams: { suppressFilterButton: true },
                                        floatingFilter: true,
                                    }}
                                    enableFilter={true}
                                    enableSorting={true}
                                    enableSearching={true}
                                    headerClass={classes.obrGridHeader}
                                    rowClass={classes.obrGridTable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <div>
                            <Button onClick={saveChanges} className={classes.saveButton} disabled={saveButtonSpinner || selectedExperts.length === 0}>Save {saveButtonSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        </div>
                    </Row>
                    {assignCourseMessage && <ErrorSuccessAlertMessage message={assignCourseMessage} varient={alertVarient} />}
                </div>
            }
        </div>
    )
}