import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AppConstants } from '../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import useRecentActivityCardStore from '../../../stores/recentActivitiesCardStore';
import dayjs from 'dayjs';
import formClass from '../../../common/FormStyling.module.css';
import classes from './AddRecentActivityModal.module.css';

const AddRecentActivityModal = ({
  show,
  onHide,
  prepopulatedActivities = null,
  onSuccess,
  onError,
}) => {
  const initializeLearnerProfileRecentActivityCardsData =
    useRecentActivityCardStore(
      (state) => state.initializeLearnerProfileRecentActivityCardsData
    );

  const learnerSlug = useRecentActivityCardStore((state) => state.learnerSlug);
  const [activityType, setActivityType] = useState(
    prepopulatedActivities?.learningActivityType || ''
  );
  useEffect(() => {
    setActivityType(prepopulatedActivities?.learningActivityType || '');
  }, [prepopulatedActivities]);

  // 🛠️ Validation Schema with Yup
  const validationSchema = Yup.object().shape({
    learningActivityType: Yup.string()
      .trim()
      .required('Activity type is required'),
    learningActivityName: Yup.string()
      .trim()
      .required('Activity name is required')
      .max(100, 'Activity name must be less than 100 characters'),
    organizer: Yup.string()
      .trim()
      .required('Organizer is required')
      .max(100, 'Organizer name must be less than 100 characters'),
    learningActivityStartDate: Yup.date().required('Start date is required'),
    // .max(new Date(), 'Start date cannot be in the future'),
    learningActivityEndDate: Yup.date()
      .required('End date is required')
      .min(
        Yup.ref('learningActivityStartDate'),
        'End date must be after start date'
      ),
    // .when('learningActivityType', {
    //   is: (activityType) => activityType !== 'Course',
    //   then: (schema) =>
    //     schema.max(new Date(), 'End date must be in the past'),
    // }),
    website: Yup.string()
      .test('is-valid-url', 'Enter a valid URL', (value) => {
        if (!value) return true;

        const urlPattern =
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

        if (!urlPattern.test(value)) {
          return false;
        }

        const domainPart = value.replace(/^(https?:\/\/)?(www\.)?/, '');
        if (domainPart.split('.').length !== 2) {
          return false;
        }

        return true;
      })
      .nullable(),
  });

  const handleSubmit = async (values) => {
    let insertNewRecentActivityPayload = {
      activityCategory: 'Recent Activities',
      activityType: activityType,
      activityName: values.learningActivityName,
      activityStartDate: values.learningActivityStartDate,
      activityEndDate: values.learningActivityEndDate,
      organizedByExpertName: values.organizer,
      organizedByExpertWebsiteLink: values.website,
      rankAwarded: values.learnerRank,
      medalAwarded: values.learnerMedal,
      certificatePath: values.certificate,
    };

    try {
      let url = 'saveLearnerDeclaredInformation';
      if (prepopulatedActivities) {
        url = 'saveLearnerDeclaredInformation';
        insertNewRecentActivityPayload.id =
          prepopulatedActivities.learningActivityId;
      }

      const response = await AuthorizedDataService.post(
        url,
        insertNewRecentActivityPayload,
        '',
        ''
      );

      if (response) {
        onSuccess(!!prepopulatedActivities);
        initializeLearnerProfileRecentActivityCardsData({ learnerSlug });
      }
    } catch (error) {
      onError();
      return;
    }

    setActivityType(AppConstants.emptyString);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setActivityType('');
        onHide();
      }}
      centered
      className={classes.achievementHead}
    >
      <Modal.Header closeButton className={classes.recentActivityHeader}>
        <Modal.Title className={classes.recentActivityTitle}>
          Add Recent Activity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            learningActivityName:
              prepopulatedActivities?.learningActivityName || '',
            organizer: prepopulatedActivities?.organizer || '',
            website: prepopulatedActivities?.organizerWebsiteLink || null,
            learningActivityStartDate:
              prepopulatedActivities?.learningActivityStartDate
                ? dayjs(
                  prepopulatedActivities?.learningActivityStartDate
                ).format('YYYY-MM-DD')
                : '',
            learningActivityEndDate:
              prepopulatedActivities?.learningActivityEndDate
                ? dayjs(prepopulatedActivities?.learningActivityEndDate).format(
                  'YYYY-MM-DD'
                )
                : '',
            learningActivityType:
              prepopulatedActivities?.learningActivityType || '',
            certificate:
              prepopulatedActivities?.selfDeclaredAchievementCertificatePath ||
              null,
            learnerRank: prepopulatedActivities?.learnerRank || '',
            learnerMedal: prepopulatedActivities?.learnerMedal || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, isSubmitting, resetForm }) => {
            return (
              <FormikForm>
                <Form.Group>
                  <Form.Label className={formClass.formLabel}>
                    Activity Type
                  </Form.Label>
                  <Form.Select
                    className={formClass.selectInput}
                    value={activityType}
                    onChange={(e) => {
                      setActivityType(e.target.value);
                      setFieldValue('learningActivityType', e.target.value);
                    }}
                  >
                    <option value={AppConstants.emptyString} defaultValue>
                      Select Activity Type
                    </option>
                    <option value="Course">Course</option>
                    <option value="Competition">Competition</option>
                    <option value="Workshop">Workshop</option>
                  </Form.Select>
                  <ErrorMessage
                    name="learningActivityType"
                    component="div"
                    className={classes.recentActivityErrorText}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Name of Activity<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="text"
                    name="learningActivityName"
                  />
                  <ErrorMessage
                    name="learningActivityName"
                    component="div"
                    className={classes.recentActivityErrorText}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Organized by<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="text"
                    name="organizer"
                  />
                  <ErrorMessage
                    name="organizer"
                    component="div"
                    className={classes.recentActivityErrorText}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Organization Website (optional)
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    // type="url"
                    name="website"
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    className={classes.recentActivityErrorText}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Activity Start Date<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="date"
                    name="learningActivityStartDate"
                  />
                  <ErrorMessage
                    name="learningActivityStartDate"
                    component="div"
                    className={classes.recentActivityErrorText}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Activity End Date<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="date"
                    name="learningActivityEndDate"
                  />
                  <ErrorMessage
                    name="learningActivityEndDate"
                    component="div"
                    className={classes.recentActivityErrorText}
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className={classes.recentActivityBtn}
                    onClick={() => {
                      resetForm();
                      setActivityType('');
                      onHide();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.recentActivityBtn}
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {prepopulatedActivities ? 'Update' : 'Save'}
                  </Button>
                </Modal.Footer>
              </FormikForm>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddRecentActivityModal;
