import { get } from 'react-scroll/modules/mixins/scroller';

export const AppConstants = {
  getSkillsDataAPI: 'getAllSkills',
  getAllSkillsBanner: 'getAllSkillsBanner',
  getPrivacyPolicyPageContent: 'getPrivacyPolicyPageContent',
  skipCompleteStudentProfile: 'skipCompleteStudentProfile',
  getIndividualSkillBanner: 'getIndividualSkillBanner',
  getTermsAndConditionsPageContent: 'getTermsAndConditionsPageContent',
  getAllQtalksBannerAPI: 'getAllQtalksBanner',
  getIndividualSkillFooterSection: 'getIndividualSkillFooterSection',
  landingPageDataAPI: 'getLandingPageContent',
  studentAdvertisementDataAPI: 'getAdForUserRole',
  studentFeaturedLearningActivitiesAPI:
    'getFeaturedLearningActivitiesForStudent?preferredTimezone=',
  studentRecentlyPublishedLearningActivitiesAPI:
    'getRecentlyPublishedLearningActivities?preferredTimezone=',
  studentMyActivities: 'getEnrolledCompetitionForToday',
  getAllQtalksDataAPI: 'getAllActiveQtalks',
  qtalksPostAPI: 'postQtalk',
  getStudentUpcomingCompetitionsOnDate: 'getStudentUpcomingCompetitionsOnDate',
  forwardSlash: '/',
  dot: '.',
  underscore: '_',
  applicationRejectedImage: 'Application_Rejected.png',
  skills: 'extra-curricular-skills',
  qtalks: 'qtalks',
  admin: 'admin',
  sales: 'sales',
  marketing: 'marketing',
  partnerSuccess: 'partner-success',
  demo: 'Demo',
  individualSkillsRoute: 'extra-curricular-skills/:categorySlug/:skillSlug',
  individualQtalksRoute: 'qtalks/:categorySlug/:skillSlug/:qTalkId',
  skillCategoryRoute: 'extra-curricular-skills/:categorySlug',
  qtalksCategoryRoute: 'qtalks/:categorySlug',
  expertMicrositeRoute: 'expert/:micrositeSlug',
  facebookShareURI: 'https://www.facebook.com/sharer.php?u=',
  linkedInShareURI: 'https://www.linkedin.com/shareArticle?url=',
  twitterShareURI: 'https://twitter.com/intent/tweet?url=',
  whatsAppShareURI: 'https://web.whatsapp.com/send?text=',
  whatsAppShareText: 'Checkout this link',
  mailToURI: 'mailto:?body=',
  categorySlug: 'categorySlug',
  asterisk: '*',
  breadcrumbHome: 'Home',
  greaterThanSign: '>',
  exploreSkillsText: 'Explore Skill',
  wanttoLearn: 'Want to Learn',
  space: ' ',
  followUsOn: 'Follow us on',
  questionMark: '?',
  whatIs: 'What is ',
  histroyOf: 'History of ',
  funFact: '- Fun Fact',
  relevanceOfSkill: 'Relevance of ',
  signUpExtendedPage: 'Sign Up Now!',
  hyphen: '-',
  years: ' Years',
  yearText: ' years',
  allAgeGroups: 'All age groups',
  aboveText: 'Above',
  uptoText: 'Upto',
  couldBeForYou: 'This skill could be for you if you like',
  skillsRequiredToBeGoodIn: 'Key competencies required',
  whereCanYouPut: 'Where can you put this skill to use',
  toUse: ' to use',
  image: 'abc.png',
  AllText: 'All',
  searchSkill: 'Search Skill',
  emptyString: '',
  plusYears: '+ years',
  trueText: true,
  falseText: false,
  trueTextString: 'true',
  falseTextString: 'false',
  colon: ':',
  verticalBar: '|',
  timeZero: '00',
  logoImageS3Path: 'logo-images',
  designImagesS3Path: 'design-images',
  profileImageS3Path:
    'static-content-images/student-profile/default-avatar.gif',
  profileOverlayImageS3Path:
    'static-content-images/student-profile/image-overlay.png',
  studentProfileBackgroundDesktop:
    'static-content-images/student-profile/backdrop-element-web.png',
  studentProfileBackgroundMobile:
    'static-content-images/student-profile/backdrop-element-mob.png',
  backdropOverlayImageS3Path: '',
  qrenciaLogoFilePath: 'Qrencia-logo.png',
  qrenciaWhiteLogoFilePath: 'Qrencia-white-logo.png',
  blueTriangleImagePath: 'Blue-triangle.svg',
  pinkTriangleImagePath: 'Pink-triangle.svg',
  orangeTriangleImagePath: 'Orange-triangle.svg',
  lightBlueTriangleImagePath: 'Light-blue-triangle.svg',
  orangeRectangleImagePath: 'Orange-rectangle.svg',
  blueRectangleImagePath: 'Blue-rectangle.svg',
  videoPlayIconImagePath: 'Video-play-icon.svg',
  backgroundGreyDotsPath: 'Background-grey-dots.svg',
  backgroundGreyDotsSmallPath: 'Background-grey-dots-small.svg',
  whatMoreYouGetSectionBgImagePath: 'Additional-offerings-section-bg-image.svg',
  purpleTriangleImagePath: 'Purple-triangle.svg',
  youtubeIconImagePath: 'qTalk_youtube_icon.png',
  facebookIconPath: 'Qrencia_Facebook_Icon.png',
  instagramIconPath: 'Qrencia_Instragram_Icon.png',
  linkedInIconPath: 'Qrencia_LinkedIn_Icon.png',
  twitterIconPath: 'Qrencia_Twitter_Icon.png',
  youtubeIconPath: 'Qrencia_Youtube_Icon.png',
  websiteIconPath: 'Qrencia_website_Icon.png',
  copyIconPath: 'Qrencia_Copy_Icon.png',
  mailIconPath: 'Qrencia_Mail_Icon.png',
  whatsAppIconPath: 'Qrencia_WhatsApp_Icon.png',
  whatMoreYouGetSectionBgDiamondImagePath:
    'Additional-offerings-section-bg-diamond-image.svg',
  whatMoreYouGetSectionBgLargeDotsImagePath:
    'Additional-offerings-section-bg-large-dots-image.svg',
  impactOfQrenciaSectionBgImagePath: 'Impact-section-bg-image.svg',
  pinkBgOvalImagePath: 'Pink-bg-oval-image.svg',
  blueBgOvalImagePath: 'Blue-bg-oval-image.svg',
  lightPinkBgOvalImagePath: 'Light-pink-bg-oval-image.svg',
  forSchoolText: 'For School',
  intervalTime: 10000,
  purpleBgOvalImagePath: 'Purple-bg-oval-image.svg',
  redRectangleImagePath: 'Red-rectangle.svg',
  selectAge: 'Select Age',
  ageGroupArray: ['5-7 Years', '8-10 Years', '11-13 Years', '14-17 Years'],
  inPresentTime: ' in Present Time!',
  linkFacebook: 'https://www.facebook.com/qrenciaskills',
  linkInstagram: 'https://www.instagram.com/qrenciaskills/',
  linkYoutube: 'https://www.youtube.com/@qrenciaskills',
  linkTwitter: 'https://twitter.com/qrenciaskills',
  forExpertText: 'For Expert',
  forStudentsText: 'For Students',
  trueFlag: 'Y',
  nullText: null,
  qrenciaNewsAutoPlaySpeed: 5000,
  qrenciaNewsCustomTransition: 'all 1s ease 0s',
  qrenciaNewsTransitionDuration: 1500,
  extraCurricularImagesPath: 'static-content-images/extra-curricular-skills/',
  BannerImage: 'extra-curricular-skills-banner.png',
  qrenciaSkillsBackgroundImagePath: 'Skills-section-bg-image.svg',
  noCompetitionsForSearchText:
    "We couldn't find any competition for your search.",
  signUpforCompetitionAlertsText:
    'Sign-up for alerts and we will email you as soon as new competitions become available.',
  bannerImage: 'Banner-Individual-Skills-Page.png',
  bannerImageTablet: 'Banner-Individual-Skills-Page-Mobile.png',
  bannerImageMobile: 'Banner-Individual-Skills-Page-Tablet.png',
  funFactIconImage: 'fun-fact-icon.png',
  fivePlusYears: '5+ years',
  mobileText: 'mobile',
  tabletText: 'tablet',
  desktopText: 'desktop',
  webText: 'web',
  eighteenPlusYears: '18+ Years',
  uploadS3Folder: 'uploads',
  expert: 'expert',
  expertHomePath: '/expert/home',
  myTeachingExpertTemplatePath: '/expert/my-teaching',
  myStudentsExpertTemplatePath: '/expert/my-students',
  expertCreateCompetitionsPath: '/expert/create-competition',
  expertManageCompetitionsPath: '/expert/manage-competitions',
  expertJudgeCompetitionsPath: '/expert/judge-competition',
  expertCreateSkillLabsPath: '/expert/create-skill-lab',
  expertManageSkillLabsPath: '/expert/manage-skill-labs',
  expertNudgesPath: '/expert/nudges',
  agencyNudgesPath: '/agency/nudges',
  expertCreateWorkshopPath: '/expert/create-workshop',
  expertManageWorkshopPath: '/expert/manage-workshop',
  expertMySchoolPath: '/expert/my-school',
  adminDashboardPath: '/admin/dashboard',
  adminNumberTrackingPath: '/admin/number-tracking',
  adminManageExpertPath: '/admin/manage-onboarding',
  adminManageAdminPath: '/admin/manage-admin',
  adminManageStudentSubsciptionPath: '/admin/manage-student-subscription',
  adminContentManagementPath: '/admin/manage-content',
  adminCreateQtalksPath: '/admin/create-qtalk',
  adminManageQtalksPath: '/admin/manage-qtalk',
  adminCompetitionsPath: '/admin/competitions',
  adminSkillLabsPath: '/admin/skill-labs',
  adminWorkshopsPath: '/admin/workshops',
  adminClubQrenciaPath: '/admin/club-qrencia',
  adminReferralsAndRewardsPath: '/admin/referrals-and-rewards',
  signup: '/signup',
  signupHeadingText: 'Fill in the learner details',
  signupSubHeadingText: 'Create your account now',
  enterFirstNameLabelText: 'First Name',
  enterLastNameLabelText: 'Last Name',
  enterEmailLabelText: 'Email',
  selectCountryLabelText: 'Country',
  selectStateLabelText: 'State',
  selectCityLabelText: 'City',
  enterMobileText: 'Mobile Number',
  enterReferenceCodeLabelText: 'Referral Code (Optional)',
  iButtonMessageForEmail:
    'You will use this email when you login and if you ever need to reset your password. Email address should be unique for each sign up.',
  iButtonMessageForDOB:
    'You will get recommendation of learning activities as per your age.',
  iButtonMessageForMobile:
    'You will get important updates on this number. Same phone number can be used for multiple sign ups.',
  signUpButtonText: 'Sign Up',
  copiedText: 'Copied',
  enterPasswordLabelText: 'Enter Password',
  student: '/student',
  studentCompleteProfilePath: '/student/complete-profile',
  studentUpdateProfilePagePath: '/student/update-profile',
  StudentCompleteProfilePagePath: '/student/complete-profile',
  studentMyLearningPath: '/student/my-learning',
  studentMyAssessementPath: '/student/my-assessments',
  studentMyAnalysisPath: '/student/my-analysis',
  studentHomePath: '/student/home',
  studentCompetitionsPath: '/student/competitions',
  studentSkillLabsPath: '/student/skill-labs',
  studentMentorMePath: '/student/mentor-me',
  studentExploreSkillsPath: '/student/explore-skills',
  studentWorkshopsPath: '/student/workshops',
  studentQtalksPath: '/student/qtalks',
  studentMySchoolPath: '/student/my-school',
  fetchCountriesAPI: 'getCountries',
  postStudentDetailsAPI: 'postStudentDetails',
  guestSpeaker: 'Guest Speaker',
  competitionUploadFolder: 'Competitions',
  challengesUploadFolder: 'Challenges',
  certificateUploadFolder: 'CertificateDetails',
  competitionDetailsUploadFolder: 'CompetitionDetails',
  createCompetitionActions: [
    'Add Competition Details',
    'Add Challenge',
    'Add Judging Parameters',
    'Configure Certificate',
    'Add Judges',
    'Advanced Settings',
  ],
  createGymnasticsCompetitionActions: [
    'Add Competition Details',
    'Add Challenge',
    'Add Judging Parameters',
    'Configure Certificate',
    'Add Judges',
    'Advanced Settings',
    'Add Award Criteria',
  ],
  editCompetitionActions: [
    'Edit Competition Details',
    'Edit Challenge',
    'Edit Judging Parameters',
    'Edit Certificate',
    'Edit Judges',
    'Advanced Settings',
  ],
  editGymnasticsCompetitionActions: [
    'Edit Competition Details',
    'Edit Challenge',
    'Edit Judging Parameters',
    'Edit Certificate',
    'Edit Judges',
    'Advanced Settings',
    'Edit Award Criteria',
  ],
  competitionTypes: ['Online', 'Offline'],
  successText: 'Success',
  pendingText: 'Pendig',
  activeText: 'Active',
  inactiveText: 'Inactive',
  nextText: 'Next',
  emptyEditorString: '<p><br></p>',
  uploadFileText: 'Upload File',
  enterProblemStatementText: 'Enter Problem Statement',
  generalInformationText: 'General Information',
  addCompetitionDetailsLabels: {
    title: { label: 'Title', name: 'title' },
    description: { label: 'Description', name: 'description' },
    date: { label: 'Competition Start Date', name: 'date' },
    endDate: { label: 'Competition End Date', name: 'endDate' },
    startTime: { label: 'Competition Start Time', name: 'startTime' },
    endTime: { label: 'Competition End Time', name: 'endTime' },
    registrationClosingDate: {
      label: 'Registration Closing Date',
      name: 'registrationClosingDate',
    },
    registrationClosingTime: {
      label: 'Registration Closing Time',
      name: 'registrationClosingTime',
    },
    allowedNumberOfParticipants: {
      label: 'Max Participation Limit',
      name: 'allowedNumberOfParticipants',
    },
    competitionType: { label: 'Competition Type', name: 'competitionType' },
    venueLocation: { label: 'Location', name: 'venueLocation' },
    schoolId: { label: 'School Id', name: 'schoolId' },
    vcLink: { label: 'VC Link', name: 'vcLink' },
    geoLocation: { label: 'Venue', name: 'geoLocation' },
    thumbnailImage: { label: 'Thumbnail Image', name: 'thumbnailImage' },
    promotionImageForMobile: {
      label: 'Promotion Image for Mobile',
      name: 'promotionImageForMobile',
    },
    promotionImageForTablet: {
      label: 'Promotion Image for Tablet',
      name: 'promotionImageForTablet',
    },
    promotionImageForWeb: {
      label: 'Promotion Image for Web',
      name: 'promotionImageForWeb',
    },
    skill: { label: 'Skill', name: 'skill' },
    ageLimit: { label: 'Age Limit', name: 'ageLimit' },
    maxAge: { label: 'Maximum Age', name: 'maxAge' },
    minAge: { label: 'Minimum Age', name: 'minAge' },
    submission: { label: 'Allow Submissions', name: 'submission' },
    clubQrenciaFlag: {
      label: 'Only for School Participants',
      name: 'clubQrenciaFlag',
    },
    guidelines: { label: 'Guidelines', name: 'guidelinesText' },
    uploadGuidelines: { label: 'Upload Guidelines', name: 'guidelinesFile' },
    resultDeclareDate: { label: 'Result Date', name: 'resultDeclareDate' },
    resultDeclareBy: { label: 'Declare Results', name: 'resultDeclareBy' },
    cost: { label: 'Competition Cost', name: 'cost' },
  },
  rubricsGeneralInfo: [
    '1.  Criteria are the high-level standards representing the characteristics which the student should exhibit to excel in a particular extracurricular skill.',
    '2.  Aspects are the low-level performance indicators against each characteristic on which the student is evaluated.',
    '3.  Please update the checkbox in action column to include/ exclude the respective aspect.',
  ],
  gymnasticsGeneralInfo: [
    '1.  Participants will be marked for different apparatus. Each apparatus has its own set of judging criteria that focus on the execution, difficulty, and overall performance of the routine.',
    '2.  Update the checkbox in action column to include/ exclude the specific parameters for each apparatus.',
  ],
  rubricsTableHeaders: ['Criteria', 'Aspect', 'Marks', 'Action'],
  yesText: 'Yes',
  noText: 'No',
  addCertificateText: 'Add Certificate',
  addAspectText: 'Add Aspect',
  includeText: 'Include',
  excludeText: 'Exclude',
  thumbnailImageText: 'thumbnail image',
  cost: 'cost',
  addCertificateFormLabels: {
    logoOne: { label: 'Logo One', name: 'logoOne' },
    logoTwo: { label: 'Logo Two', name: 'logoTwo' },
    logoThree: { label: 'Logo Three', name: 'logoThree' },
    logoFour: { label: 'Logo Four', name: 'logoFour' },
    certificateTitle: { label: 'Title', name: 'certificateTitle' },
    certificateDescription: {
      label: 'Description',
      name: 'certificateDescription',
    },
    meritRange: { label: 'Number of merit certificates', name: 'meritRange' },
    certificateMessage: { label: 'Message', name: 'certificateMessage' },
    signatoryImageOne: {
      label: 'Signatory Image One',
      name: 'signatoryImageOne',
    },
    signatoryOneName: { label: 'Signatory One Name', name: 'signatoryOneName' },
    signatoryOneDesignation: {
      label: 'Signatory One Designation',
      name: 'signatoryOneDesignation',
    },
    signatoryTwoName: { label: 'Signatory Two Name', name: 'signatoryTwoName' },
    signatoryImageTwo: {
      label: 'Signatory Image Two',
      name: 'signatoryImageTwo',
    },
    signatoryTwoDesignation: {
      label: 'Signatory Two Designation',
      name: 'signatoryTwoDesignation',
    },
    signatoryThreeName: {
      label: 'Signatory Three Name',
      name: 'signatoryThreeName',
    },
    signatoryImageThree: {
      label: 'Signatory Image Three',
      name: 'signatoryImageThree',
    },
    signatoryThreeDesignation: {
      label: 'Signatory Three Designation',
      name: 'signatoryThreeDesignation',
    },
    signatoryOneOrganization: {
      label: 'Signatory One Organization',
      name: 'signatoryOneOrganization',
    },
    signatoryTwoOrganization: {
      label: 'Signatory Two Organization',
      name: 'signatoryTwoOrganization',
    },
    signatoryThreeOrganization: {
      label: 'Signatory Three Organization',
      name: 'signatoryThreeOrganization',
    },
  },
  competitionTypeText: 'competition type',
  promotionalImageForWeb: 'promotion image for web',
  getJudgingParametersForSkillApi: 'getJudgingParametersForSkill?skillId=',
  getJudgingCriteriaForSkillApi: 'getJudgingCriteriaForSkill?skillId=',
  postCompetitionApi: 'postCompetition',
  fetchExpertSkillsApi: 'getSkillOfExpert',
  fetchCertificateTemplateApi: 'fetchCertificateTemplates',
  fetchClubQrenciaFlag: 'isExpertAssociatedWithClubQrencia',
  loginButtonText: 'Login',
  loginPageUrl: '/login',
  pageSpecificContentAPI: 'getPageSpecificContent?pageName=',
  loginPageName: 'Login-page',
  fieldTypePassword: 'password',
  fieldTypeText: 'text',
  onlineText: 'online',
  offlineText: 'offline',
  freeText: 'FREE',
  newWorkshopsHeading: 'New Workshops Coming Soon!',
  newCompetitionsHeading: 'New Competitions Coming Soon!',
  newCoursesHeading: 'New Courses Coming Soon!',
  newCourseText:
    'Experts are actively preparing new courses. While you wait, why not explore exciting Competitions?',
  newWorkshopText:
    'Experts are actively preparing new workshops. While you wait, why not explore exciting Competitions?',
  newCoursesText:
    'Experts are actively preparing new competitions. While you wait, why not explore exciting Courses?',
  newWorkshopsSubText: 'Stay tuned for upcoming workshops!',
  newCoursesSubText: 'Stay tuned for upcoming courses!',
  newCompetitionsSubText: 'Stay tuned for upcoming competitions!',
  competitionSearchPlaceholder: 'Search by Expert, Competition, Skill name',
  courseSearchPlaceholder: 'Search by Expert, Course, Skill name',
  workshopSerachPlaceholder: 'Search by Expert, Workshop, Skill name',
  judgingScreenDesktopPageName: 'Expert-judging-scree-desktop-page',
  judgingScreenTabletPageName: 'Expert-judging-scree-tablet-page',
  judgingScreenMobilePageName: 'Expert-judging-scree-mobile-page',
  individualSkillPageKeyHighlightsHeading:
    'Individual-skills-page-key-highlights-heading',
  authenticationUri: 'authenticate',
  refreshTokenApi: 'refresh?userName=',
  logoutApi: 'logoutUser',
  messageDisappearTime: 7000,
  newPageTargetSelf: '_self',
  sessionKeyAccesToken: 'accessToken',
  sessionKeyUserDetails: 'userDetails',
  gender: ['Male', 'Female', 'Other'],
  indianMaxMobileNumber: 10,
  nonIndianMaxMobile: 16,
  countryIdIndia: '1',
  footerText: 'footer',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  refundPolicyPage: '/refund-policy',
  upcomingQtalks: 'Upcoming Qtalks',
  upcomingSkillLabsText: 'Upcoming Skill Labs',
  announcementsText: 'Announcements',
  qTalksHeading: 'Qtalks',
  pastQtalks: 'Past Qtalks',
  nudgesText: 'Nudges',
  studenyMyActivitiesText: 'My Activities for Today',
  qTalksImageAlt: 'Qtalks Event Image',
  createQTalkFormLabels: {
    title: 'Title',
    description: 'Description',
    date: 'Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    webBackgroundImage: 'Web Background Image',
    tabBackgroundImage: 'Tablet Background Image',
    mobileBackgroundImage: 'Mobile Background Image',
    thumbnailImage: 'Thumbnail Image',
    youtubeVideoLink: 'Youtube Video Link',
    youtubeVideoId: 'Youtube Video Id',
    eventImage: 'Event Image',
    keyHighlights: 'Key Highlights',
    skillCategory: 'Skill Category',
    skill: 'Skill',
    speakerName: 'Name',
    speakerDesignation: 'Designation',
    speakerProfileImage: 'Speaker Profile Image',
    speakerHighlightPoints: 'Highlights',
    speakerFacebookProfileLink: 'Facebook Profile Link',
    speakerTwitterProfileLink: 'Twitter Profile Link',
    speakerInstagramProfileLink: 'Instagram Profile Link',
    speakerLinkedInProfileLink: 'LinkedIn Profile Link',
    speakerYoutubeProfileLink: 'Youtube Profile Link',
    speakerWebsiteProfileLink: 'Website Profile Link',
  },
  alertVarient: ['success', 'danger'],
  publishText: 'Publish',
  monthNames: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
  dayName: [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ],
  watchForText: 'WATCH FOR',
  WatchNowButtonText: 'Watch Now',
  ViewMoreQtalksLink: 'View More ->',
  openInNewTab: '_blank',
  qPrefix: 'q',
  qTalkHighlights: 'Key Highlights',
  verifyEmailOtpAPI: 'verifyEmail',
  requestEmailOtpAPI: 'generateEmailUserVerificationToken',
  requestMobileOtpAPI: 'generateMobileNumberUserVerificationToken',
  verifyMobileOtpAPI: 'verifyMobileNumber',
  nonIndianMinMobileNumber: 6,
  getSignupPagespecificContentAPI:
    'getPageSpecificContent?pageName=Signup-page',

  watchMoreQtalks: 'Watch more Qtalks',
  youtubeWatchLinkPrefix: 'https://www.youtube.com/watch?v=',
  youtubeEmbedLinkPrefix: 'https://youtu.be/',
  iButtonMessageForCompetitionOnlineMode:
    'Please check competition details to know more',
  noUpcomingCompetitionsAvailable:
    'You have no competitions scheduled for the selected date.',
  todaysActivitiesText: "Today's Activities",
  noTodaysActivitiesAvailable: 'You have no activities scheduled for today.',
  noNudgesAvailable: 'No nudges available',
  noSkillLabsAvailable: 'No skill labs available',
  noAnnouncementsAvailable: 'No announcements available',
  jpegImageType: 'image/jpeg',
  pngImageType: 'image/png',
  APIKeyToFetchStates:
    'OEJWOEFyeHAyUlM1WkNQbjU3Y1NCWW9BSmloNW04VjMwZ3Z5Q3QybQ==',
  postCompleteProfileDetailsAPI: 'completeStudentProfile',
  fetchLoggedInStudentInfoAPI: 'fetchStudentProfileInformation?userQrenciaId=',
  expertManageCompetitionHeadingText: 'Manage Competitions',
  allCompetitionsText: 'All',
  ongoingCompetitionsText: 'Ongoing Competitions',
  upcomingCompetitionsText: 'Upcoming Competitions',
  pastCompetitionsText: 'Past Competitions',
  cancelledCompetitionsText: 'Cancelled',
  allCompetitionsString: 'allCompetitions',
  cancelledCompetitionsString: 'cancelledCompetitions',
  competitions: 'competitions',
  individualCompetitionsRoute:
    'competitions/:categorySlug/:skillSlug/:competitionSlug',
  competitionsText: 'competitions',
  liveCompetitionsString: 'liveCompetitions',
  relevanceText: '- Relevance',
  liveCompetitionsText: 'Live',
  viewCompetitionBasicDetailsText: 'Competition Details',
  viewCompetitionParticipantsDetailsText: 'Participants',
  viewCompetitionTeamDetailsText: 'Team Participant Details',
  viewCompetitionJudgesDetailsText: 'Judges',
  notAvailableText: 'N/A',
  expertCancelCompetitionAlertText: 'Are you sure, you want to cancel ',
  onlineText: 'ONLINE',
  offlineText: 'OFFLINE',
  expertText: 'expert',
  viewCompetitionResultText: 'Result & Certificate',
  rubricsFileName: 'rubrics.xlsx',
  fetchUpcomingCompetitionsAPI: 'getCompetitionbyExpert?status=Upcoming',
  fetchOngoingCompetitionsAPI: 'getCompetitionbyExpert?status=Ongoing',
  fetchCancelledCompetitionAPI: 'getCompetitionbyExpert?status=Cancelled',
  fetchCompetitionDetailsWithParameterAPI:
    'getExpertCompetitionSpecificDetails',
  cancelCompetitionWithParameterAPI: 'cancelCompetition?competitionId=',
  downloadRubricsWithParameterAPI: 'downloadCompetitionRubrics?competitionId=',
  completeCompetitionWithParameterAPI:
    'updateCompetitionCompletionStatus?completionStatus=Complete&competitionId=',
  registeredParticipantsFileText: 'Registered-Participants.xlsx',
  downloadParticipantsWithParameterAPI:
    'downloadRegisteredParticiants?competitionId=',
  downloadCourseParticipants:
    'downloadCourseEnrolledParticiant?courseUniqueId=',
  downloadWorkshopParticipants:
    'downloadWorkshopParticiantList?workshopUniqueId=',
  falseFlag: 'N',
  expertEnableDisableJudgeAPI: 'updateJudgingEnabledFlag?competitionId=',
  expertEnableDisableJudgeAPIStatusParameter: '&status=',
  competitionStatus: {
    notComplete: 'Not Complete',
    upcoming: 'Upcoming',
    ongoing: 'Ongoing',
    complete: 'Complete',
    past: 'Past',
    published: 'Published',
    unPublished: 'Not Published',
    cancelled: 'Cancelled',
  },
  completitionStatus: {
    incomplete: 'Incomplete',
    complete: 'Complete',
  },
  getExpertCompetitionsForStatusApi: 'getExpertCompetitionsForStatus?status=',
  getJudgesInCompetitionApi: 'getJudgesInCompetitionApi?competitionId=',
  postJudgeInCompetitionApi: 'postJudgeInCompetitionApi',
  deleteJudgeInCompetitionApi: 'deleteJudgeInCompetition?judgeUniqueId=',
  judgeCompetitionsUriPart: 'judge-competitions',
  addChallengeHeading: 'Drag and Drop Problem Statement',
  competitionText: 'competition',
  physicalText: 'PHYSICAL',
  twelveHoursTimeFormat: 'hh:mm A',
  competitionCardsDateFormat: 'Do MMM, YYYY',
  competitionDatesNewDateFormat: 'DD MMM, YYYY',
  preReadFile: 'PreRead.pdf',
  getIndividualCompetitionPageAPIWithParameter:
    'getIndividualCompetitionPageDetails?competitionSlug=',
  getExpertMicrositeDetailsAPI: 'getMicrositeDetailsForSlug/',
  competitionBannerText: 'Competition Banner',
  micrositeBannerText: 'Microsite Banner',
  competitionUploadChallengeAPI: 'postSolutionForChallenge',
  executableFileTypes: [
    'EXE',
    'BAT',
    'COM',
    'CMD',
    'INF',
    'IPA',
    'OSX',
    'PIF',
    'RUN',
    'WSH',
    'MSI',
    'ISO',
  ],
  solutionsText: 'solutions',
  competitionTypeOnline: 'Online',
  competitionTypeOffline: 'Offline',
  tText: 'T',
  zText: 'Z',
  guidelinesFile: 'Guidelines.pdf',
  tabCompetitionValueText: 'Competition',
  tabSkillLabValueText: 'Skill Lab',
  tabCoursesValueText: 'Course',
  courseText: 'course',
  getCompetitionResultAPIWithParameter:
    'getResultForCompetition?competitionId=',
  getResultForTeamCompetition: 'getResultForTeamCompetition?competitionId=',
  getSelectedStudentMarksWithParam:
    'getMarksForStudentInCompetition?competitionId=',
  getSelectedStudentMarksAPI: 'getMarksForStudentInCompetition',
  competitionEndedText: 'Competition has ended',
  getCompetitionSummaryAPIPart2: '&preferredTimezone=',
  markParticipantsPageGetCompetitionDetailsAPIPart2: '&preferredTimezone=',
  publishedText: 'Published',
  userRoleStudent: 'Student',
  userRoleExpert: 'Expert',
  userRoleAdmin: 'Admin',
  userRoleAgency: 'Agency',
  userRoleTeam: 'Team',
  enrolInCompetitionApi: 'enrolInCompetition?competitionId=',
  userSelectedDateParam: '?userSelectedDate=',
  localeParam: '&locale=',
  getCountriesApi: 'https://api.countrystatecity.in/v1/countries',

  getStatesApi: 'https://api.countrystatecity.in/v1/countries/IN/states',
  getCityUri: '/cities',
  selectStateDefault: 'Please select state',
  selectCityDefault: 'Please select city',
  senJudgingLinkAPIWithParameter: 'sendJudgingLinkToAllJudge?competitionId=',
  studentProfileImageLimit: 2,
  ApiToFetchStudentsMarkingStatus: 'getStudentMarkingFlag?competitionId=',
  shareCompetitionLinkText: 'Share Competition Link',
  signatoryDefaultOrganization: 'Qrencia Passion Skill Pvt',
  supportedImageFormats: ['image/jpg', 'image/jpeg', 'image/png'],
  APItofetchExpertSchoolDetails: 'getExpertSchoolDetails',
  selectedDateStartTimeParam: 'selectedDateStartTime=',
  selectedDateEndTimeParam: 'selectedDateEndTime=',
  competitionTabHeading: ['Upcoming', 'Ongoing', 'Past', 'Cancelled'],
  fetchPastCompetitionAPI: 'getCompetitionbyExpert?status=Past',
  ibuttonMessageDownloadChallenge:
    'Download the challenge document to attempt competition',
  ibuttonMessageUploadSolution:
    'Upload the solution before competition ends. You can select multiple files but upload the solution ONLY ONCE.',
  ibuttonMessageWhenCompetitionNotLive:
    'Challenge will be available once competition starts',
  contentTypeMap: {
    zip: 'application/zip',
    pdf: 'application/pdf',
    doc: 'application/msword',
    txt: 'text/plain',
    html: 'text/html',
    css: 'text/css',
    js: 'application/javascript',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    aac: 'audio/aac',
    mp3: 'audio/mpeg',
    aiff: 'audio/aiff',
    amr: 'audio/amr',
    ogg: 'video/ogg',
    wav: 'audio/wav',
    avi: 'video/x-msvideo',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    webm: 'video/webm',
    xlsx: 'application/vnd.ms-excel',
    jpg: 'image/jpeg',
  },
  submissionWindowName: 'Submissions For Competition ',
  submissionWindowNamePartTwo: ' and participant ',
  gridConstants: {
    headerHeight: 30,
  },
  studentNudgesPath: '/student/nudges',
  studentraiseNudgeAPI: 'raiseNudge',
  nudgesTextForUploadFilePath: 'nudges',
  getActiveNudgesStudentAPIWithParam:
    'getNudgesRaisedByStudentForStatus?status=Active',
  getClosedNudgesStudentAPIWithParam:
    'getNudgesRaisedByStudentForStatus?status=Closed',
  expertCreateObrForm: {
    email: { label: 'Email', name: 'email' },
    expertObrId: { label: 'OBR Id', name: 'expertObrId' },
    firstName: { label: 'First Name', name: 'expertFirstName' },
    lastName: { label: 'Last Name', name: 'expertLastName' },
    phoneNumber: { label: 'Phone Number', name: 'phoneNumber' },
    dateOfBirth: { label: 'Date of Birth', name: 'expertDob' },
    country: { label: 'Country', name: 'country' },
    state: { label: 'State', name: 'name' },
    statenotIndia: { label: 'State', name: 'expertState' },
    city: { label: 'City', name: 'name' },
    cityNotIndia: { label: 'City', name: 'expertCity' },
    selectedSkills: { label: 'Skills', name: 'skill' },
    obrStatus: { label: 'OBR Status', name: 'obrStatus' },
    isActiveFlag: { label: 'Is Declined', name: 'obrIsdeclined' },
    obrStatusUpdatedOn: { label: 'Updated On', name: 'obrStatusUpdatedOn' },
    emailSentOn: { label: 'Email Sent On', name: 'emailSentOn' },
    expertObrUniqueId: { label: 'Unique Id', name: 'expertObrUniqueId' },
    expertObrLinkExpiryDate: {
      label: 'Link Expiry Date',
      name: 'expertObrLinkExpiryDate',
    },
    expertObrLinkExpirationStatus: {
      label: 'Link Status',
      name: 'expertObrLinkExpirationStatus',
    },
    organizationName: { label: 'Organisation Name', name: 'organizationName' },
    schoolId: { label: 'School Id', name: 'schoolId' },
    schoolName: { label: 'School Name', name: 'schoolName' },
    schoolAddress: { label: 'School Address', name: 'schoolAddress' },
    skillDocumentPath: {
      label: 'Skill Document(s)',
      name: 'skillDocumentPath',
    },
    identityProofPath: { label: 'ID Proof(s)', name: 'identityProofPath' },
    addressProofPath: { label: 'Address Proof(s)', name: 'addressProofPath' },
    tncCheck: { label: 'Terms and Condition', name: 'tncCheck' },
    comment: { label: 'Comment', name: 'comment' },
    agencyName: { label: 'Institute Name', name: 'agencyName' },
  },
  expertCreateObrButtons: {
    loadMoreBtn: { label: 'Load More', name: 'loadMore' },
    sendLinkBtn: { label: 'Send Link', name: 'sendLink' },
    deActivateBtn: { label: 'Deactivate', name: 'deActivate' },
    activateBtn: { label: 'Activate', name: 'activate' },
    approveBtn: { label: 'Approve', name: 'approve' },
    rejectBtn: { label: 'Reject', name: 'reject' },
    createBtn: { label: 'Create', name: 'create' },
    submitObrBtn: { label: 'Submit', name: 'submit' },
    askForChangeBtn: { label: 'In Draft', name: 'inDraft' },
    editBtn: { label: 'Change', name: 'change' },
    showDetailsBtn: { label: 'View', name: 'more' },
  },
  expertCreateObrHeading: {
    onBoradRequestForm: 'Create Onboarding Request',
    noActionTakenObr: 'New OBR',
    actionTakenObr: 'In Transit OBR',
    detailsObr: 'OBR Details',
  },
  expertObrStatus: {
    statusCreated: 'Created',
    statusDraft: 'Draft',
    statusApproved: 'Accepted',
    statusRejected: 'Rejected',
    statusInReview: 'In Review',
    statusAccepted: 'Accepted',
    statusDeclined: 'Declined',
  },
  attachmentsText: 'Attachments',
  getRespondedExpertsAPIWithParam:
    'getExpertsRespondedForNudgeId?nudgeUniqueId=',
  getExpertResponseAPIWithParamOne: 'getNudgeResponsesByExpert?nudgeUniqueId=',
  getExpertResponseAPIWithParamTwo: '&expertUniqueId=',
  getActiveNudgesAssignedToExpertAPIWithParam:
    'getNudgesAssociatedWithLoggedInExpertForStatus?status=Active',
  getClosedNudgesAssignedToExpertAPIWithParam:
    'getNudgesAssociatedWithLoggedInExpertForStatus?status=Resolved',
  rateNudgeAPIWithParamOne: 'rateExpertForNudge?nudgeUniqueId=',
  rateNudgeAPIWithParamTwo: '&expertUniqueId=',
  rateNudgeAPIWithParamThree: '&rating=',
  studentRateNudgeMessageText: 'How would you rate this nudge ?',
  sendExpertObrLinkAPI: 'resendObrLinkToExpert',
  getAllExpertObrDetails: 'getExpertObrDetailsByActionStatus?actionStatus=',
  expertRevertNudgeAPI: 'postNudgeResponse',
  rateNudge5starArray: [1, 2, 3, 4, 5],
  incompleteText: 'Incomplete',
  completeText: 'Complete',
  checkTheResetPasswordLinkValidityAPI:
    'checkTheResetPasswordLinkValidity?userQrenciaId=',
  sendResetPasswordLinkAPI: 'sendResetPasswordLink?userEmail=',
  updateUserPasswordAPI: 'updateUserPassword',
  getExpertsResponsesAPIWithParam:
    'getLoggedInExpertResponsesForNudgeId?nudgeUniqueId=',
  studentNudgesTabHeading: ['Raise Nudge', 'Open Nudges', 'Resolved Nudges'],
  fetchStudentsOfCompetitionAPI:
    'fetchStudentsForBulkEmail?uniqueCompetitionId=',
  fetchAllActiveCompetitionsOfExpertAPI: 'getAllActiveCompetitionsForExpert',
  sendBulkEmailsAPI: 'sendBulkEmails',
  expertBulkMessagingPath: '/expert/bulk-messaging',
  sendObrLinkToExpertAPI: 'resendObrLinkToExpert',
  updateObrStatusAPI: 'updateExpertObrStatus',
  getObrForExpertAPI: 'getExpertObrDetails?expertUserEmail=',
  submitObrAPI: 'postExpertObr',
  enterKey: 'Enter',
  backspaceKey: 'Backspace',
  getCompetitonSpecificCertificateDetailsApi:
    'getCompetitonSpecificCertificateDetails?competitionId=',
  uploadChallengeText: 'uploadChallenge',
  updateCompetitionAPI: 'updateCompetition',
  expertOnboardingStatusRoute: '/expert-onboarding/:obrId',
  sendLinkToIndividualJudgeAPI: 'sendJudgingLinkToIndividualJudge?judgeId=',
  closedText: 'Closed',
  countryIndia: 'India',
  getExpertResponsesForNudgeAPI: 'getExpertsResponseForNudgeId?nudgeUniqueId=',
  httpResponseCodes: {
    responseCode200: 200,
    responseCode201: 201,
    responseCode202: 202,
    responseCode400: 400,
    responseCode403: 403,
    responseCode404: 404,
    responseCode406: 406,
    responseCode409: 409,
    responseCode500: 500,
    responseCode502: 502,
    responseCode504: 504,
  },
  updateObrFormAPI: 'postExpertDetails',
  updateExpertObrIsActiveFlagAPIpartOne:
    'updateExpertObrIsActiveFlag?expertObrUniqueId=',
  updateExpertObrIsActiveFlagAPIparttwo: '&flagNumber=',
  skillDocumentText: 'skillDocument',
  idProofText: 'idProof',
  addressProofText: 'addressProof',
  sendLinkToJudgeSecondParam: '&competitionId',
  webBanner: 'webBannerImage',
  tabletBanner: 'tabletBannerImage',
  mobileBanner: 'mobileBannerImage',
  videoMessage: 'videoMessage',
  profilePicture: 'userProfilePicture',
  expertObrValidityCode: {
    activate: 111,
    deactivate: 100,
  },
  expertObrDocumentsName: {
    skillDocument: 'skill-document',
    addressProofDocument: 'address-proof-document',
    identityProofDocument: 'identity-proof-document',
    userProfileImage: 'user-profile-image',
    userVideoMessage: 'video-message',
    userWebBannerImage: 'web-banner-image',
    userTabletBannerImage: 'tablet-banner-image',
    userMobileBannerImage: 'mobile-banner-image',
    videoMessageThumbnail: 'video-message-thumbnail-image',
  },
  obrDetailsUploadFolder: 'ExpertObr',
  checkObrLinkValidityAPI: 'checkExpertObrLinkValidity?expertObrUniqueId=',
  getExpertObrDetailsAPI: 'getSpecificExpertObrDetails?expertObrUniqueId=',
  expertObrDesktopPageName: 'Expert-OBR-desktop-page',
  expertObrMobilePageName: 'Expert-OBR-mobile-page',
  expertObrTabletPageName: 'Expert-OBR-tablet-page',
  recentlyPublishedCompetitionFilter: 'Competition',
  comma: ',',
  publicText: 'Public',
  createCoursesActions: [
    'Add Course Details',
    'Add FAQ',
    'Configure Certificate',
    'Manage Attendance',
    'Advanced Settings',
  ],
  createCourseText: 'Create Course',
  courseDetailsFormLabels: {
    courseType: { label: 'Course Type', name: 'courseType' },
    title: { label: 'Title', name: 'title' },
    description: { label: 'Description', name: 'description' },
    descriptionDocument: {
      label: 'Course Brochure',
      name: 'descriptionDocument',
    },
    skill: { label: 'Skill', name: 'skill' },
    startDate: { label: 'Start Date', name: 'startDate' },
    windowStartTime: { label: 'Start Time', name: 'windowStartTime' },
    windowEndTime: { label: 'End Time', name: 'windowEndTime' },
    courseWeekDays: { label: 'Course Week Days', name: 'courseWeekDays' },
    endDate: { label: 'End Date', name: 'endDate' },
    numberOfClasses: { label: 'Number of classes', name: 'numberOfClasses' },
    durationInHours: { label: 'Total Learning Hours', name: 'durationInHours' },
    modeOfDelivery: { label: 'Mode of Delivery', name: 'modeOfDelivery' },
    geoLocation: { label: 'Geo Location', name: 'geoLocation' },
    address: { label: 'Address', name: 'address' },
    sessionLink: { label: 'Session Link', name: 'sessionLink' },
    cost: { label: 'Cost (INR)', name: 'cost' },
    discount: { label: 'Discount (INR)', name: 'discount' },
    contactDetails: { label: 'Contact Details', name: 'contactDetails' },
    expertName: { label: 'Name', name: 'expertName' },
    expertEmail: { label: 'Email Id', name: 'expertEmail' },
    expertMobile: { label: 'Mobile number', name: 'expertMobile' },
    thumbnailImage: { label: 'Course Thumbnail Image', name: 'thumbnailImage' },
    promotionImageForWeb: {
      label: 'Promotion image for web',
      name: 'promotionImageForWeb',
    },
    promotionImageForTablet: {
      label: 'Promotion image for tablet',
      name: 'promotionImageForTablet',
    },
    promotionImageForMobile: {
      label: 'Promotion image for mobile',
      name: 'promotionImageForMobile',
    },
    promotionVideo: { label: 'Promotion Video', name: 'promotionVideo' },
    registrationClosingDate: {
      label: 'Registration Closing Date',
      name: 'registrationClosingDate',
    },
    registrationClosingTime: {
      label: 'Registration Closing Time',
      name: 'registrationClosingTime',
    },
    trackingType: { label: 'Tracking Type', name: 'trackingType' },
  },
  modeOfDelivery: ['Online', 'Offline'],
  modeOfDeliveryText: 'mode of delivery',
  studentPerformanceReportPageRoute:
    'student-performance/:competitionUniqueId/:studentUniqueId',
  courseCertificateLabels: {
    logoOne: { label: 'Logo One', name: 'logoOne' },
    logoTwo: { label: 'Logo Two', name: 'logoTwo' },
    logoThree: { label: 'Logo Three', name: 'logoThree' },
    logoFour: { label: 'Logo Four', name: 'logoFour' },
    certificateTitle: { label: 'Title', name: 'certificateTitle' },
    certificateDescription: {
      label: 'Description',
      name: 'certificateDescription',
    },
    certificateMessage: {
      label: 'Certificate Message',
      name: 'certificateMessage',
    },
    signatoryImageOne: {
      label: 'Signatory Image One',
      name: 'signatoryImageOne',
    },
    signatoryOneName: { label: 'Signatory One Name', name: 'signatoryOneName' },
    signatoryOneDesignation: {
      label: 'Signatory One Designation',
      name: 'signatoryOneDesignation',
    },
    signatoryTwoName: { label: 'Signatory Two Name', name: 'signatoryTwoName' },
    signatoryImageTwo: {
      label: 'Signatory Image Two',
      name: 'signatoryImageTwo',
    },
    signatoryTwoDesignation: {
      label: 'Signatory Two Designation',
      name: 'signatoryTwoDesignation',
    },
    signatoryThreeName: {
      label: 'Signatory Three Name',
      name: 'signatoryThreeName',
    },
    signatoryImageThree: {
      label: 'Signatory Image Three',
      name: 'signatoryImageThree',
    },
    signatoryThreeDesignation: {
      label: 'Signatory Three Designation',
      name: 'signatoryThreeDesignation',
    },
    signatoryOneOrganization: {
      label: 'Signatory One Organization',
      name: 'signatoryOneOrganization',
    },
    signatoryTwoOrganization: {
      label: 'Signatory Two Organization',
      name: 'signatoryTwoOrganization',
    },
    signatoryThreeOrganization: {
      label: 'Signatory Three Organization',
      name: 'signatoryThreeOrganization',
    },
  },
  createCourseAPI: 'postCourseDetails',
  expertpublishUnpublishCoursePath: 'publish-courses',
  coursesUploadPath: 'Courses',
  coursesText: 'courses',
  courseDetailsFolder: 'CourseDetails',
  publishedCourseText: 'Published',
  unpublishedCourseText: 'Unpublished',
  fetchCoursesByStatusAPI: 'getCourseByStatus?status=',
  fetchAssignedCoursesByStatusAPI: 'getAssignedCourseByStatus?status=',
  updateCourseStatusAPI: 'updateCourseStatus?courseUniqueId=',
  coursePublishedStatus: {
    published: 'Published',
    unpublished: 'Not Published',
  },
  publishConfirmation: 'Publish course',
  unpublishConfirmation: 'Unpublish course',
  expertCreateCoursesPath: '/expert/create-course',
  expertManageCoursesPath: '/expert/manage-courses',
  editCourseActions: [
    'Edit Course Details',
    'Edit FAQ',
    'Edit Certificate',
    'Manage Attendance',
    'Advanced Settings',
  ],
  toastDelay: 5000,
  staticContentImagesS3Path: 'static-content-images',
  errorPageDesktopImageS3Path: 'error-page/error-bg-desktop.jpg',
  performanceAnalysisPageS3Path: 'performance-analysis-page',
  bgAbstractBnwImage: 'bgAbstractBnW.png',
  handWithMagnifyingGlassImage: 'handWithMagnifyingGlass.png',
  rankCardboardCutoutImage: 'rankCardboardCutout.png',
  bgAbstractColorImage: 'bgAbstractColor.png',
  maleFemaleCharacterBgImage: 'maleFemaleCharacterBg.png',
  handWithRocketImage: 'handWithRocket.png',
  qrenciaGlobeImage: 'qrenciaGlobe.png',
  performanceAnalysisPageRankSectionStaticText: {
    rankOutOfText: 'Rank out of',
    congratulatingTextPartOne: 'Congratulations for participating in ',
    positionInText: 'position in ‘',
    heldOnText: ' held on',
    heldFromText: ' held from',
    toText: 'to',
    letsAnalyzText: 'Let’s Analyze!',
    yourPerformanceText: 'YOUR PERFORMANCE',
  },
  fullMonthDateFormat: 'Do MMMM, YYYY',
  performanceAnalysisPageRaiseNudgeSectionStaticText: {
    headingText: 'Take your skills to the next level',
    subHeadingText:
      'Connect with experts to seek feedback, specially on below areas',
  },
  performanceAnalysisPageLearningActivitySectionText: {
    headingTextPartOne: 'Unlock your potential',
    headingTextPartTwo: 'by engaging in recommended learning activities!',
    subHeadingText: 'Try these out,',
  },
  updateCourseDetailsAPI: 'updateCourseDetails',
  courseStatus: {
    upcoming: 'Upcoming',
    ongoing: 'Ongoing',
    past: 'Past',
  },
  assignCoursesTabs: {
    upcoming: 'Upcoming',
    ongoing: 'Ongoing',
  },
  getSpecificCourseDetailsAPI: 'getSpecificCourseDetails?courseUniqueId=',
  performanceAnalysisPageRewardSectionText: {
    congratulationsText: 'Congratulations!',
    rewardText: 'you have been rewarded',
    pointsText: 'Points',
    getDiscountText:
      'Use this to get discounts on learning activities on Qrencia!',
  },
  performanceAnalysisPageRewardBagImagePath: 'discountBag.png',
  badgeBackgroundImagePath: 'BadgeBackground.png',
  skillStampImagePath: 'SkillStamp.png',
  performanceReportWeakAspectsSectionText: {
    coverHeading: 'Elevating Your Performance',
    coverSubHeading: 'Let’s identify your areas of improvements',
  },
  performanceReportCompetitionSectionText: {
    headingPartOne: 'Let’s take a look into the',
    headingPartTwo: 'highlights of the thrilling contest',
    subHeading: 'A snapshot of the competition',
    competitiorsText: 'Competitors',
    averageScoreText: 'Average Score',
    higestScoreText: 'Highest Score',
    lowestScoreText: 'Lowest Score',
    yourScoreText: 'Your Score',
  },
  performanceReportRaiseNudgeSectionText: {
    wantToAceText: 'Want to ace the next competition?',
    weHaveSomeIdeaText: 'We have some ideas for you to become better!',
    subHeading: 'A snapshot of the competition',
    competitiorsText: 'Competitors',
    averageScoreText: 'Average Score',
    higestScoreText: 'Highest Score',
    lowestScoreText: 'Lowest Score',
    yourScoreText: 'Your Score',
  },
  performanceReportAdvertismentStaticText: 'This report was brought to you by',
  expertRespondToStudentQueryAPI: 'postQueryResponse',
  expertObrMicrositeWebBannerImageText: 'obrWebBannerImage',
  expertObrMicrositeTabletBannerImageText: 'obrTabletBannerImage',
  expertObrMicrositeMobileBannerImageText: 'obrMobileBannerImage',
  expertObrMicrositeUserImageText: 'obrUserImage',
  expertObrMicrositeUserVideoMessageText: 'obrVideoMessage',
  dedicatedCoursePageRoute: 'courses/:categorySlug/:skillSlug/:courseSlug',
  getIndividualCoursePageDetailsAPI: 'getIndividualCoursePageDetails/',
  getCourseReviewCommentsAPI: 'getReviewComments?courseUniqueId=',
  postCourseReviewCommentAPI: 'postReviewComment',
  postStudentQueryAPI: 'postStudentQuery',
  expertObrFormMicrositeIbutton:
    "Qrencia provides you a personalized web page dedicated solely to you and your achievements. It's like your digital portfolio, highlighting your background, organization affiliations, upcoming competitions, courses, and more. With this, you can expand your online presence, attract new opportunities, and connect with a wider audience in your field. Get ready to unlock new horizons for your professional journey",
  expertObrFormMicrositeWebBannerMessage:
    '<b>Desired dimension:</b><br> 1550px wide and 450px high',
  expertObrFormVideoMessageIButton:
    'To make your personalized web page even more engaging, we suggest you to record a short video introducing yourself, sharing your passion, and highlighting your most significant achievements',
  checkStudentEnrolmentStatusAPI: 'checkStudentEnrolmentStatus?courseUniqueId=',
  expertObrVideoThumbnailMessageDimension:
    '<b>Desired dimension:</b><br> 308px wide and 216px high',
  getEnroledCourseByStatusAPI: 'getEnrolledCoursesByStatus?status=',
  studentCoursesPath: '/student/courses',
  micrositeGetUpcomingCoursesAPIWithParam: 'getUpcomingCoursesForMicrosite',
  micrositeGetUpcomingCoursesAPIParamTwo: '&userRole=',
  micrositeGetUpcomingCompetitionsAPIWithParam:
    'getUpcomingCompetitionForMicrosite',
  micrositeGetUpcomingCompetitionsAPIParamTwo: '&userRole=',
  weekDaysOptions: [
    { key: 'Sun', name: 'Sunday' },
    { key: 'Mon', name: 'Monday' },
    { key: 'Tue', name: 'Tuesday' },
    { key: 'Wed', name: 'Wednesday' },
    { key: 'Thu', name: 'Thursday' },
    { key: 'Fri', name: 'Friday' },
    { key: 'Sat', name: 'Saturday' },
  ],
  expertMicroSiteStaticText: {
    ratingText: ' Rating',
    studentsEngagedText: ' Students Engaged',
    yearsOfExperienceText: ' Years Of Experience',
    workExperienceText: 'Work Experience',
    expertSkillsText: "Skills I'm Passionate About",
    socialLinksText: 'Social Links',
  },
  fetchStudentUpcomingCourseWidgetAPI:
    'getStudentUpcomingCourseOnSelectedDate?',
  expertMicroSiteS3ImagesPath: 'expert-microsite-page',
  expertVideoMessageThumbnailPath: 'expertVideoMessageThumbnail.jpg',
  expertMicrositeGetQtalksAPI: 'getQtalksForMicroSite',
  allDaysOfWeekText: 'All days of the week',
  performancePageLoaderText:
    'Analyzing your performance data. Sit tight, we are almost there!',
  reportPageLoaderText:
    'Generating your assessment report... Please wait a moment!',
  studentPerformancePageAPI: 'student-performance',
  learningActivitiesTypes: {
    competition: 'Competition',
    course: 'Course',
    nudge: 'Nudge',
    workshop: 'Workshop',
  },
  studentPerformanceText: 'student-performance',
  skillDocumentInfoIcon:
    'You may choose a certificate or any document which reflects your expertise in ',
  multipleFilesMessage:
    '. (In case of multiple files, you can upload a single zip file)',
  idProofInfoIcon:
    'You may choose from the following options: Passport, Aadhar Card, Driving License, or Voter ID. (In case of multiple files, you can upload a single zip file)',
  addressProofInfoIcon:
    'You may choose from the following options: Passport, Aadhar Card, Driving License, or Voter ID. (In case of multiple files, you can upload a single zip file)',
  expertObrFormMicrositeMobileBannerMessage:
    '<b>Desired dimension:</b><br> 375px wide and 400px high',
  expertObrFormMicrositeTabletBannerMessage:
    '<b>Desired dimension:</b><br> 800px wide and 350px high',
  downloadCourseCertificateAPI: 'downloadCourseCertificate?courseUniqueId=',
  invalidDateText: 'Invalid date',
  image5MBSizeLimit: 5000000,
  courseDescriptionInfo:
    "Enter about course, objective, guidelines and it's outcome",
  courseBrochureInfo:
    "Upload a file describing about the course, objective, guidelines and it's outcome",
  courseThumbnailInfo:
    'Please upload an image that can be used as thumbnail image for this course on Qrencia',
  courseWebBannerInfo:
    'Please upload web banner image in the dimension 1550 x 450',
  courseTabletBannerInfo:
    'Please upload tablet banner image in the dimension 800 x 350',
  courseMobileBannerInfo:
    'Please upload mobile banner image in the dimension 375 x 400',
  coursePromotionVideoInfo:
    'By uploading a promotion video, you can effectively spotlight your course, attracting students who are keen to enrol and take their learning journey to the next level',
  getPreSignedURLForUploadAPI: 'fetchPreSignedURLForUpload',
  spocEmailInformation: 'SPOC: Student Point of Contact',
  getRecentlyPublishedCompetitionsForStudentAPI:
    'getRecentlyPublishedCompetitionsForStudent',
  getFeaturedCompetitionsForStudentAPI: 'getFeaturedCompetitionsForStudent',
  s3filePermissionPrivate: 'private',
  s3filePermissionPublic: 'public-read',
  s3FileContentDispositionInline: 'inline',
  s3ProfilePictureFolder: 'userProfilePicture',
  qtlaksBackgroundImageWebS3Path: 'qtlaks/backgroundImageWeb/',
  qtlaksBackgroundImageTabS3Path: 'qtlaks/backgroundImageTab/',
  qtlaksBackgroundImageMobileS3Path: 'qtlaks/backgroundImageMobile/',
  qtlaksThumbnailS3Path: 'qtlaks/thumbnail/',
  qtlaksEventS3Path: 'qtlaks/events/',
  qtalksSeoS3Path: 'qtalks/seo/',
  qtalksSpeakerImageS3Path: 'qtlaks/speaker/',
  s3ContentTypeOctetStream: 'application/octet-stream',
  courseCertificateTilte: 'Certificate of Completion',
  getRecentlyPublishedCoursesForStudentAPI:
    'getRecentlyPublishedCoursesForStudent?preferredTimezone=',
  getFeaturedCoursesForStudentAPI:
    'getFeaturedCoursesForStudent?preferredTimezone=',
  downloadStudentReportSheetAPI: 'downloadStudentReportSheet?competitionId=',
  studentReportSheetDownloadFileName: 'result.xlsx',
  studentQrenciaIdParam: '&studentQrenciaId=',
  zeroIndex: 0,
  studentMylearningActivitySnapshotAPI: 'getStudentActivitySnapshot',
  studentMylearningActivitiesAPI:
    'getStudentMyLearningActivities?preferredTimezone=',
  studentTodaysActivityAPI: 'getLearningActivitiesForWidget',
  studentNudgesForWidgetAPI: 'getStudentNudgesForWidget',
  studentUpcomingCompetitionsAndCoursesAPI:
    'getStudentUpcomingLearningActivitiesForWidget',
  errorPageHeading: 'Whoops! We couldn’t find this page',
  errorPageSubHeading:
    'This link is outdated or incorrect. But fear not, fellow explorer! <br>You can always find your way back',
  getTodaysActivityForStudentAPI: 'getStudentTodaysActivities',
  landingPageS3Path: 'landing-page',
  discoverYourPassionS3Path: 'discover-your-passion-icons',
  brainBoosterIconImage: 'Brain-Boosters.png',
  competitionsIconImage: 'Unlimited-Skill-Competition.png',
  courseIconImage: 'Discount-on-Skill-Courses.png',
  feedbackFromExpertIconImage: 'Feedback-from-Experts.png',
  workshopIconImage: 'Discount-on-Mentorship.png',
  internetProtocols: {
    http: 'http',
    https: 'https',
  },
  enterDobSignup: 'Date of Birth',
  generateEmailOtpAPI: 'generateEmailVerificationOtp',
  verifyEmailOtpSignUpAPI: 'verifyUserEmail',
  feeWaivedCompetitionRoute:
    'fee-waived-competition/:categorySlug/:skillSlug/:competitionSlug',
  expertType: ['Individual', 'Organization'],
  expertTypeIndividual: 'Individual',
  expertTypeOrganization: 'Organization',
  obrVideoMessageThumbnail: 'videoMessageThumbnailImage',
  signupAndEnrolPageRoute: 'signup-and-enrol',
  signupPageGenerateMobileOtpAPI: 'generateMobileNumberOtp',
  signupPageVerifyMobileAPI: 'verifyUserMobileNumber',
  updateCompetitionFeeWaverApprovalFlagAPI:
    'updateCompetitionFeeWaverApprovalFlag',
  getFeeWaivedCompetitionDetailsAPI: 'getFeeWaivedCompetitionDetails',
  expertBulkEnrolRoute: '/expert/bulk-enrol',
  agencySkillDocumentOBRInfo:
    'You may upload a copy of the Articles of Association or Memorandum of Understanding that outlines the business structure, objectives, and legal framework',
  agencyIdProofInfoIcon: 'You may upload a copy of business PAN Card etc.',
  agencyAddressProofIcon:
    'You may upload a copy of the official business registration document, such as a Certificate of Incorporation etc.',
  expertCreateCompetitionsWebBannerMessage:
    '<b>Desired dimension:</b><br> 1550px wide and 450px high',
  expertCreateCompetitionsThumbnailMessage:
    '<b>Desired dimension:</b><br> 825px wide and 550px high',
  expertCreateCompetitionsTabletBannerMessage:
    '<b>Desired dimension:</b><br> 800px wide and 350px high',
  expertCreateCompetitionsMobileBannerMessage:
    '<b>Desired dimension:</b><br> 375px wide and 400px high',
  bannerImagesS3Path: 'banner-images',
  feeWaiverInfoIcon:
    'The competition fee will be waived for participants, if this option is selected.',
  expressionOfInterestPagePath: '/subscription',
  eoiPageFormLabels: {
    businessType: { name: 'businessType', label: 'Business Type' },
    academyName: { name: 'academyName', label: 'Academy Name' },
    firstName: { name: 'firstName', label: 'First Name' },
    lastName: { name: 'lastName', label: 'Last Name' },
    email: { name: 'email', label: 'Email' },
    mobile: { name: 'mobile', label: 'Phone Number' },
    dob: { name: 'dob', label: 'Date of Birth' },
    country: { name: 'country', label: 'Country' },
    countryDropdown: { name: 'countryDropdown', label: 'Country' },
    state: { name: 'state', label: 'State' },
    stateDropdown: { name: 'stateDropdown', label: 'State' },
    city: { name: 'city', label: 'City' },
    cityDropdown: { name: 'cityDropdown', label: 'City' },
    skill: { name: 'skill', label: 'Interested Skill(s)' },
    instituteName: { name: 'instituteName', label: 'Institute Name' },
    websiteLink: { name: 'websiteLink', label: 'Website Link' },
  },
  emailEoiDetailsAPI: 'emailEoiDetails',
  eoiPageBenifitsSectionHeading: 'Let Qrencia skyrocket your business',
  eoiPageBenifitsSection: `<ul>
    <li><p> Access and <strong>tap students globally</strong> across skills: <span style="color:#0061b5">Scale up your business and grow your community</span></p></li>
    <li><p> Introduce <strong>new modes of learning</strong> in your teaching:<span style="color:#0061b5"> Differentiate your brand with focus on feedback &amp; Integrated learning</span></p></li>
    <li><p> Use <strong>AI teaching assistant </strong>for personalization:<span style="color:#0061b5"> Measure, monitor, and recommend activities to your students</span><p></li>
    <li><p> Reduce operation overhead to <strong>boost productivity</strong>:<span style="color:#0061b5"> Leverage an integrated coaching management system</span></p></li> 
    <li><p> Benefit from <strong>high payouts</strong> &amp; rewards:<span style="color:#0061b5"> Earn from multiple learning sources with lowest commission fees</span></p></li>
    </ul>`,
  eoiPageAboutQrenciaSection:
    'Qrencia is an organized marketplace for people to pursue their passion. Creators like yourself can offer a bouquet of learning activities for people to develop skills efficiently. What Zomato is for Food, Qrencia is for Passion!',
  expressionOfInterestS3Path: 'Expression-of-Interest',
  eoiPageBenefitsTapStudentsGloballyPath: 'tap-students-globally.png',
  eoiPageBenefitsNewModesPath: 'new-modes-of-learning.png',
  eoiPageBenefitsAiTeachingPath: 'AI-teaching-assistant.png',
  eoiPageBenefitsBoostProductivityPath: 'boost-productivity.png',
  eoiPageBenefitsHighPayoutsPath: 'High-payouts.png',
  expertBrochurePdfPath: 'Expert-Brochure-Qrencia.pdf',
  eoiVideoThumbnailImagePath: 'Video-Thumbnail-Banner.png',
  eoiDesktopBannerPath: 'EOI-Desktop-Banner.png',
  eoiTabletBannerPath: 'EOI-Tablet-Banner.png',
  eoiMobileBannerPath: 'EOI-Mobile-Banner.png',
  eoiPageBannerSubHeading:
    'Elevate your entrepreneurial journey fueled by passion',
  eoiPageVideoId: 'CA6HRFeb_QQ',
  // "eoiAboutUsLink": "EOI-about-us.pdf",
  eoiAboutUsLink: 'Qrencia-Profile.pdf',
  declareResultsAPI: 'declareCompetitionResult',
  enrolInCourseVerifyPaymentAPI: 'enrolInCourseVerifyPayment',
  bulkEnrolStudentsAPI: 'bulkEnrolStudents',
  uploadParticipantSheetAPI: 'uploadParticipantSheet',
  excelSheetType:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  bulkEnrolmentSheetName: 'BulkEnrolmentSheet.xlsx',
  downloadBulkEnrolmentSheetAPI:
    'downloadBulkEnrolmentTemplateSheet?competitionId=',
  getCompetitionStatisticsForAdminAPI:
    'getCompetitionStatistics?competitionUniqueId=',
  getAllCompetitionsForAdminAPI: 'getAllCompetitions',
  competitionCardsDateMonthFormat: 'Do MMM, YYYY',
  richTextEditorModules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        {
          color: [
            'red',
            'blue',
            'green',
            'yellow',
            'purlpe',
            'orange',
            'black',
            'grey',
            '#224b7f',
            'white',
          ],
        },
      ],
      [
        {
          background: [
            'red',
            'blue',
            'green',
            'yellow',
            'purlpe',
            'orange',
            'black',
            'grey',
            '#224b7f',
            'white',
          ],
        },
      ],
      ['link'],
      ['clean'],
    ],
  },

  richTextEditorFormats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'clean',
    'link',
  ],
  meritCertificateInfoMessage:
    'If judging parameters are not configured, then all the participants will receive a Certificate of Participation. <br />If judging parameters are configured, then default is top 3',
  tabCoursesTitleText: 'Courses',
  tabWorkshopValueText: 'Workshop',
  tabAllText: 'All',
  tabCompetitionTitleText: 'Competitions',
  tabSkillLabTitleText: 'Skill Labs',
  tabWorkshopTitleText: 'Workshops',
  sText: 's',
  getSelectedStudentMarksSecondParam: '&studentId=',
  getSelectedStudentMarksThirdParam: '&skillName=',
  getAspectsOfCompetitionAPI: 'getAspectsForCompetition?competitionId=',
  alreadyEnrolledinCompetitionText: 'You are enrolled in this competition!',
  privateCompetitionText: 'Public enrolments are disabled',
  wantToParticipateText: 'Want to participate ?',
  registrationAreClosedText: 'Registrations are now closed',
  plusYearText: '+ Years',
  yearsText: ' Years',
  belowText: ' Below',
  yourPerformanceCardSolutionBgPath: 'individual-competition-solution-bg.png',
  yourPerformanceCardCertificateBgPath:
    'individual-competition-certificate-bg.png',
  yourPerformanceCardResultBgPath: 'individual-competition-result-bg.png',
  yourPerformanceCardPerformanceBgPath:
    'individual-competition-performance-analysis-bg.png',
  yText: 'Y',
  nText: 'N',
  expertJudgingScreen:
    'judge-competitions/:competitionSlug/judge/:judgeUniqueId',
  getJudgeDetailsAPI: 'getJudgeDetails?judgeUniqueId=',
  getJudgeDetailsAPISecondParam: '&competitionUniqueId=',
  getCompetitionSummaryAPI: 'getCompetitionSummary?competitionSlug=',
  getJudgingEnabledFlagAPI: 'isJudgingEnabled?competitionId=',
  getParticipantsInCompetitionAPIPartOne:
    'getParticipantsInCompetition?competitionId=',
  getParticipantsForJudgingScreenAPI: 'getParticipantsInCompetition',
  getParticipantsInCompetitionAPIPartTwo: '&judgeId=',
  getStudentCompetitionOnDateApi: 'getStudentCompetitionOnDate?',
  downloadCompetitionCertificateApi: 'downloadCompetitionCertificate',
  noClassesFoundText: 'No Classes Found',
  noMentorsFoundText: 'No Mentors Found',
  noQtalksFoundText: 'No Qtalks Found',
  noBrainBoostersFoundText: 'No Brain Boosters Found',
  noNudgesFoundText: 'No Nudges Found',
  noCompetitionsFoundText: 'No Competitions Found',
  rubricsText: 'Rubrics',
  certificateTest: 'Certificate',
  participantsListText: 'Participants List',
  competitionResultFileName: 'result.pdf',
  expertMarkParticipantsScreenRoute:
    'judge-competitions/:competitionSlug/judge/:judgeUniqueId/student/:participantQrenciaId',
  markParticipantsPageGetCompetitionDetailsAPI:
    'getCompetitionSummary?competitionSlug=',
  markParticipantsPageGetJudgesAPI: 'getJudgeDetails?judgeUniqueId=',
  markParticipantsPageGetAspectsAPI: 'getAspectsForJudging?competitionId=',
  getAspectsForJudgingAPI: 'getAspectsForJudging',
  markParticipantsPageGetAspectsAPIJudgeIdParam: '&judgeUniqueId=',
  markParticipantsPageGetAspectsAPIParticipantQrenciaIdParam:
    '&participantQrenciaId=',
  markParticipantsPageGetAspectsAPISkillNameParam: '&skillName=',
  markParticipantsInstructionsTitle: 'General Information :',
  markParticipantsInstructionsArray: [
    'Scoring Scale: The scoring scale ranges from 0 to 5, with 5 being the highest score and 0 being the lowest score.',
    'Judging Parameters: Assess the participant based on the judging parameters provided.',
  ],
  markTeamInstructionsArray: [
    'Scoring Scale: The scoring scale ranges from 0 to 5, with 5 being the highest score and 0 being the lowest score.',
    'Judging Parameters: Assess the team based on the judging parameters provided.',
  ],
  markParticipantsPagePostAllotedMarksAPI: 'postStudentMarksInCompetition',
  studentText: 'student',
  toDoText: 'To Do',
  doneText: 'Done',
  inProgressText: 'In Progress',
  notMarkedText: 'Not Marked',
  createCompetitionText: 'Create Competition',
  judgesLinkCopiedInterval: 1500,
  markParticipantsPageGetParticipantsAPI:
    'getParticipantsInCompetition?competitionId=',
  markParticipantsPageGetParticipantsAPIJudgeIdParam: '&judgeId=',
  markParticipantsDownloadJudgingSheetAPI:
    'downloadJudgingSheets?competitionId=',
  markParticipantsDownloadJudgingSheetAPIJudgeParam: '&judgeUniqueId=',
  markParticipantsDownloadJudgingSheetAPIStudentParam: '&studentUniqueId=',
  judgingSheetName: 'judging-sheet.xlsx',
  getCompetitionSpecificRubricsApi:
    'getCompetitionSpecificChallengeRubricsAspects?competitionId=',
  editCompetitionText: 'Edit Competitions',
  downloadCompetitionSummaryAPI: 'getCompetitionSummaryReport?competitionId=',
  downloadResultSummarySheetAPI: 'downloadResultSummarySheet?competitionId=',
  downloadCompetitionResultAPI: 'getStudentReportCard?competitionId=',
  downloadCompetitionResultSecondAPI: '&studentUniqueId=',
  competitionSummaryFileName: 'summary.pdf',
  competitionResultSummaryFileName: 'summary.xlsx',
  downloadCertificateTemplateWithParamAPI:
    'downloadCompetitionCertificate?competitionId=',
  certificateFileNameWithPDFExtension: 'certificate.pdf',
  publishedText: 'Published',
  userRoleStudent: 'Student',
  userRoleExpert: 'Expert',
  userRoleAdmin: 'Admin',
  enrolInCompetitionApi: 'enrolInCompetition?competitionId=',
  userSelectedDateParam: '?userSelectedDate=',
  localeParam: '&locale=',
  getStatesApi: 'https://api.countrystatecity.in/v1/countries/IN/states',
  getCountryStatesApi: 'https://api.countrystatecity.in/v1/countries/',
  getStateUri: '/states',
  getCityUri: '/cities',
  selectStateDefault: 'Please select state',
  selectCityDefault: 'Please select city',
  senJudgingLinkAPIWithParameter: 'sendJudgingLinkToAllJudge?competitionId=',
  studentProfileImageLimit: 2,
  ApiToFetchStudentsMarkingStatus: 'getStudentMarkingFlag?competitionId=',
  shareCompetitionLinkText: 'Share Competition Link',
  signatoryDefaultOrganization: 'Qrencia Passion Skill Pvt',
  APItofetchExpertSchoolDetails: 'getExpertSchoolDetails',
  selectedDateStartTimeParam: 'selectedDateStartTime=',
  selectedDateEndTimeParam: 'selectedDateEndTime=',
  competitionTabHeading: ['Upcoming', 'Ongoing', 'Past', 'Cancelled'],
  fetchPastCompetitionAPI: 'getCompetitionbyExpert?status=Past',
  ibuttonMessageDownloadChallenge:
    'Download the challenge document to attempt competition',
  ibuttonMessageWhenCompetitionNotLive:
    'Challenge will be available once competition starts',
  getSolutionForChallengeAPI: 'getSolutionForChallenge',
  submissionWindowName: 'Submissions For Competition ',
  submissionWindowNamePartTwo: ' and participant ',
  gridConstants: {
    headerHeight: 40,
  },
  studentNudgesPath: '/student/nudges',
  studentraiseNudgeAPI: 'raiseNudge',
  nudgesTextForUploadFilePath: 'nudges',
  agency: 'agency',
  agencyHomePath: '/agency/home',
  myTeachingAgencyTemplatePath: '/agency/my-teaching',
  myStudentsAgencyTemplatePath: '/agency/my-students',
  agencyCreateCompetitionsPath: '/agency/create-competition',
  agencyManageCompetitionsPath: '/agency/manage-competitions',
  agencyBulkMessagingPath: '/agency/bulk-messaging',
  agencyBulkEnrolRoute: '/agency/bulk-enrol',
  agencyCreateCoursesPath: '/agency/create-course',
  agencyManageCoursesPath: '/agency/manage-courses',
  agencyPublishUnpublishCoursePath: 'publish-courses',
  agencyCreateWorkshopPath: '/agency/create-workshop',
  agencyManageWorkshopPath: '/agency/manage-workshop',
  agencyMySchoolPath: '/agency/my-school',
  getClubQrenciaAssociationStatusAPI: 'checkLoggedInAgencyIsPartOfClubQrencia',
  agencyCreateObrForm: {
    agencyHeadEmail: { label: 'SPOC Email', name: 'agencyHeadEmail' },
    agencyObrId: { label: 'OBR Id', name: 'agencyObrId' },
    agencyHeadFirstName: {
      label: 'SPOC First Name',
      name: 'agencyHeadFirstName',
    },
    agencyHeadLastName: { label: 'SPOC Last Name', name: 'agencyHeadLastName' },
    agencyHeadPhoneNumber: {
      label: 'SPOC Phone Number',
      name: 'agencyHeadPhoneNumber',
    },
    country: { label: 'SPOC Country', name: 'country' },
    state: { label: 'SPOC State', name: 'name' },
    statenotIndia: { label: 'SPOC State', name: 'agencyHeadState' },
    city: { label: 'SPOC City', name: 'name' },
    cityNotIndia: { label: 'SPOC City', name: 'agencyHeadCity' },

    obrStatus: { label: 'OBR Status', name: 'obrStatus' },
    isActiveFlag: { label: 'Is Declined', name: 'obrIsdeclined' },
    obrStatusUpdatedOn: { label: 'Updated On', name: 'obrStatusUpdatedOn' },
    emailSentOn: { label: 'Email Sent On', name: 'emailSentOn' },
    agencyObrUniqueId: { label: 'Unique Id', name: 'agencyObrUniqueId' },
    agencyObrLinkExpiryDate: {
      label: 'Link Expiry Date',
      name: 'agencyObrLinkExpiryDate',
    },
    agencyObrLinkExpirationStatus: {
      label: 'Link Status',
      name: 'agencyObrLinkExpirationStatus',
    },
    organizationName: { label: 'Organisation Name', name: 'organizationName' },
    agencyName: { label: 'Agency Name', name: 'agencyName' },
    schoolId: { label: 'School Id', name: 'schoolId' },
    schoolName: { label: 'School Name', name: 'schoolName' },
    schoolAddress: { label: 'School Address', name: 'schoolAddress' },
    skillDocumentPath: {
      label: 'Skill Document(s)',
      name: 'skillDocumentPath',
    },
    identityProofPath: { label: 'ID Proof(s)', name: 'identityProofPath' },
    addressProofPath: { label: 'Address Proof(s)', name: 'addressProofPath' },
    tncCheck: { label: 'Terms and Condition', name: 'tncCheck' },
    comment: { label: 'Comment', name: 'comment' },
    clubQrenciaFlag: { label: 'Club Qrencia', name: 'clubQrenciaFlag' },
  },
  expertCreateObrButtons: {
    loadMoreBtn: { label: 'Load More', name: 'loadMore' },
    sendLinkBtn: { label: 'Send Link', name: 'sendLink' },
    deActivateBtn: { label: 'Deactivate', name: 'deActivate' },
    activateBtn: { label: 'Activate', name: 'activate' },
    approveBtn: { label: 'Approve', name: 'approve' },
    rejectBtn: { label: 'Reject', name: 'reject' },
    createBtn: { label: 'Create', name: 'create' },
    submitObrBtn: { label: 'Submit', name: 'submit' },
    askForChangeBtn: { label: 'In Draft', name: 'inDraft' },
    editBtn: { label: 'Change', name: 'change' },
    showDetailsBtn: { label: 'View', name: 'more' },
  },
  expertCreateObrHeading: {
    onBoradRequestForm: 'Create Onboarding Request',
    noActionTakenObr: 'New OBR',
    actionTakenObr: 'In Transit OBR',
    detailsObr: 'OBR Details',
  },
  expertObrStatus: {
    statusCreated: 'Created',
    statusDraft: 'Draft',
    statusApproved: 'Accepted',
    statusRejected: 'Rejected',
    statusInReview: 'In Review',
    statusAccepted: 'Accepted',
    statusDeclined: 'Declined',
  },
  attachmentsText: 'Attachments',
  getRespondedExpertsAPIWithParam:
    'getExpertsRespondedForNudgeId?nudgeUniqueId=',
  getExpertResponseAPIWithParamOne: 'getNudgeResponsesByExpert?nudgeUniqueId=',
  getExpertResponseAPIWithParamTwo: '&expertUniqueId=',
  getActiveNudgesAssignedToExpertAPIWithParam:
    'getNudgesAssociatedWithLoggedInExpertForStatus?status=Active',
  getClosedNudgesAssignedToExpertAPIWithParam:
    'getNudgesAssociatedWithLoggedInExpertForStatus?status=Resolved',
  rateNudgeAPIWithParamOne: 'rateExpertForNudge?nudgeUniqueId=',
  rateNudgeAPIWithParamTwo: '&expertUniqueId=',
  rateNudgeAPIWithParamThree: '&rating=',
  studentRateNudgeMessageText: 'How would you rate this nudge ?',
  sendExpertObrLinkAPI: 'resendObrLinkToExpert',
  getAllExpertObrDetails: 'getExpertObrDetailsByActionStatus?actionStatus=',
  expertRevertNudgeAPI: 'postNudgeResponse',
  rateNudge5starArray: [1, 2, 3, 4, 5],
  incompleteText: 'Incomplete',
  completeText: 'Complete',
  resetPasswordUrl: 'reset-password',
  resetPasswordFormHeading: 'Reset Password',
  passwordText: 'Password',
  confirmPasswordText: 'Confirm Password',
  checkTheResetPasswordLinkValidityAPI:
    'checkTheResetPasswordLinkValidity?userQrenciaId=',
  updateUserPasswordAPI: 'updateUserPassword',
  getExpertsResponsesAPIWithParam:
    'getLoggedInExpertResponsesForNudgeId?nudgeUniqueId=',
  studentNudgesTabHeading: ['Raise Nudge', 'Open Nudges', 'Resolved Nudges'],
  downloadCertificateAPI: 'downloadCertificate?competitionId=',
  downlaodCertificateForExpertAPI: 'downloadCertificate',
  fetchStudentsOfCompetitionAPI:
    'fetchStudentsForBulkEmail?uniqueCompetitionId=',
  fetchAllActiveCompetitionsOfExpertAPI: 'getAllActiveCompetitionsForExpert',
  sendBulkEmailsAPI: 'sendBulkEmails',
  expertBulkMessagingPath: '/expert/bulk-messaging',
  sendObrLinkToExpertAPI: 'resendObrLinkToExpert',
  updateObrStatusAPI: 'updateExpertObrStatus',
  getObrForExpertAPI: 'getExpertObrDetails?expertUserEmail=',
  enterKey: 'Enter',
  backspaceKey: 'Backspace',
  getCompetitonSpecificCertificateDetailsApi:
    'getCompetitonSpecificCertificateDetails?competitionId=',
  uploadChallengeText: 'uploadChallenge',
  updateCompetitionAPI: 'updateCompetition',
  expertOnboardingStatusRoute: '/expert-onboarding/:obrId',
  sendLinkToIndividualJudgeAPI: 'sendJudgingLinkToIndividualJudge?judgeId=',
  closedText: 'Closed',
  getExpertResponsesForNudgeAPI: 'getExpertsResponseForNudgeId?nudgeUniqueId=',
  httpResponseCodes: {
    responseCode200: 200,
    responseCode201: 201,
    responseCode202: 202,
    responseCode400: 400,
    responseCode404: 404,
    responseCode403: 403,
    responseCode409: 409,
    responseCode500: 500,
    responseCode502: 502,
    responseCode504: 504,
    responseCode406: 406,
  },
  updateObrFormAPI: 'postExpertDetails',
  updateExpertObrIsActiveFlagAPIpartOne:
    'updateExpertObrIsActiveFlag?expertObrUniqueId=',
  updateExpertObrIsActiveFlagAPIparttwo: '&flagNumber=',
  skillDocumentText: 'skillDocument',
  idProofText: 'idProof',
  addressProofText: 'addressProof',
  sendLinkToJudgeSecondParam: '&competitionId',
  webBanner: 'webBannerImage',
  tabletBanner: 'tabletBannerImage',
  mobileBanner: 'mobileBannerImage',
  videoMessage: 'videoMessage',
  profilePicture: 'userProfilePicture',
  expertObrValidityCode: {
    activate: 111,
    deactivate: 100,
  },
  expertObrDocumentsName: {
    skillDocument: 'skill-document',
    addressProofDocument: 'address-proof-document',
    identityProofDocument: 'identity-proof-document',
    userProfileImage: 'user-profile-image',
    userVideoMessage: 'video-message',
    userWebBannerImage: 'web-banner-image',
    userTabletBannerImage: 'tablet-banner-image',
    userMobileBannerImage: 'mobile-banner-image',
    videoMessageThumbnail: 'video-message-thumbnail-image',
  },
  obrDetailsUploadFolder: 'ExpertObr',
  checkObrLinkValidityAPI: 'checkExpertObrLinkValidity?expertObrUniqueId=',
  getExpertObrDetailsAPI: 'getSpecificExpertObrDetails?expertObrUniqueId=',
  expertObrDesktopPageName: 'Expert-OBR-desktop-page',
  expertObrMobilePageName: 'Expert-OBR-mobile-page',
  expertObrTabletPageName: 'Expert-OBR-tablet-page',
  recentlyPublishedCompetitionFilter: 'Competition',
  comma: ',',
  publicText: 'Public',
  createCourseText: 'Create Course',
  modeOfDelivery: ['Online', 'Offline'],
  modeOfDeliveryText: 'mode of delivery',
  studentPerformanceReportPageRoute:
    'student-performance/:competitionUniqueId/:studentUniqueId',
  courseCertificateLabels: {
    logoOne: { label: 'Logo One', name: 'logoOne' },
    logoTwo: { label: 'Logo Two', name: 'logoTwo' },
    logoThree: { label: 'Logo Three', name: 'logoThree' },
    logoFour: { label: 'Logo Four', name: 'logoFour' },
    certificateTitle: { label: 'Title', name: 'certificateTitle' },
    certificateDescription: {
      label: 'Description',
      name: 'certificateDescription',
    },
    certificateMessage: {
      label: 'Certificate Message',
      name: 'certificateMessage',
    },
    signatoryImageOne: {
      label: 'Signatory Image One',
      name: 'signatoryImageOne',
    },
    signatoryOneName: { label: 'Signatory One Name', name: 'signatoryOneName' },
    signatoryOneDesignation: {
      label: 'Signatory One Designation',
      name: 'signatoryOneDesignation',
    },
    signatoryTwoName: { label: 'Signatory Two Name', name: 'signatoryTwoName' },
    signatoryImageTwo: {
      label: 'Signatory Image Two',
      name: 'signatoryImageTwo',
    },
    signatoryTwoDesignation: {
      label: 'Signatory Two Designation',
      name: 'signatoryTwoDesignation',
    },
    signatoryThreeName: {
      label: 'Signatory Three Name',
      name: 'signatoryThreeName',
    },
    signatoryImageThree: {
      label: 'Signatory Image Three',
      name: 'signatoryImageThree',
    },
    signatoryThreeDesignation: {
      label: 'Signatory Three Designation',
      name: 'signatoryThreeDesignation',
    },
    signatoryOneOrganization: {
      label: 'Signatory One Organization',
      name: 'signatoryOneOrganization',
    },
    signatoryTwoOrganization: {
      label: 'Signatory Two Organization',
      name: 'signatoryTwoOrganization',
    },
    signatoryThreeOrganization: {
      label: 'Signatory Three Organization',
      name: 'signatoryThreeOrganization',
    },
  },
  createCourseAPI: 'postCourseDetails',
  expertpublishUnpublishCoursePath: 'publish-courses',
  coursesUploadPath: 'Courses',
  coursesText: 'courses',
  publishedCourseText: 'Published',
  unpublishedCourseText: 'Unpublished',
  updateCourseStatusAPI: 'updateCourseStatus?courseUniqueId=',
  coursePublishedStatus: {
    published: 'Published',
    unpublished: 'Not Published',
  },
  publishConfirmation: 'Publish course',
  unpublishConfirmation: 'Unpublish course',
  editWorkshopActions: [
    'Edit Workshop Details',
    'Edit FAQ',
    'Edit Certificate',
    'Advanced Settings',
  ],
  toastDelay: 5000,
  staticContentImagesS3Path: 'static-content-images',
  errorPageDesktopImageS3Path: 'error-page/error-bg-desktop.jpg',
  performanceAnalysisPageS3Path: 'performance-analysis-page',
  bgAbstractBnwImage: 'bgAbstractBnW.png',
  handWithMagnifyingGlassImage: 'handWithMagnifyingGlass.png',
  rankCardboardCutoutImage: 'rankCardboardCutout.png',
  bgAbstractColorImage: 'bgAbstractColor.png',
  maleFemaleCharacterBgImage: 'maleFemaleCharacterBg.png',
  handWithRocketImage: 'handWithRocket.png',
  qrenciaGlobeImage: 'qrenciaGlobe.png',
  performanceAnalysisPageRankSectionStaticText: {
    rankOutOfText: 'Rank out of',
    congratulatingTextPartOne: 'Congratulations for participating in ',
    positionInText: 'position in ‘',
    heldOnText: ' held on',
    heldFromText: ' held from',
    toText: 'to',
    letsAnalyzText: 'Let’s Analyze!',
    yourPerformanceText: 'YOUR PERFORMANCE',
  },
  fullMonthDateFormat: 'Do MMMM, YYYY',
  performanceAnalysisPageRaiseNudgeSectionStaticText: {
    headingText: 'Take your skills to the next level',
    subHeadingText:
      'Connect with experts to seek feedback, specially on below areas',
  },
  performanceAnalysisPageLearningActivitySectionText: {
    headingTextPartOne: 'Unlock your potential',
    headingTextPartTwo: 'by engaging in recommended learning activities!',
    subHeadingText: 'Try these out,',
  },
  performanceAnalysisPageRewardBagImagePath: 'discountBag.png',
  badgeBackgroundImagePath: 'BadgeBackground.png',
  skillStampImagePath: 'SkillStamp.png',
  performanceReportWeakAspectsSectionText: {
    coverHeading: 'Elevating Your Performance',
    coverSubHeading: 'Let’s identify your areas of improvements',
  },
  performanceReportCompetitionSectionText: {
    headingPartOne: 'Let’s take a look into the',
    headingPartTwo: 'highlights of the thrilling contest',
    subHeading: 'A snapshot of the competition',
    competitiorsText: 'Competitors',
    averageScoreText: 'Average Score',
    higestScoreText: 'Highest Score',
    lowestScoreText: 'Lowest Score',
    yourScoreText: 'Your Score',
  },
  performanceReportRaiseNudgeSectionText: {
    wantToAceText: 'Want to ace the next competition?',
    weHaveSomeIdeaText: 'We have some ideas for you to become better!',
    subHeading: 'A snapshot of the competition',
    competitiorsText: 'Competitors',
    averageScoreText: 'Average Score',
    higestScoreText: 'Highest Score',
    lowestScoreText: 'Lowest Score',
    yourScoreText: 'Your Score',
  },
  performanceReportAdvertismentStaticText: 'This report was brought to you by',
  expertRespondToStudentQueryAPI: 'postQueryResponse',
  expertObrMicrositeWebBannerImageText: 'obrWebBannerImage',
  expertObrMicrositeTabletBannerImageText: 'obrTabletBannerImage',
  expertObrMicrositeMobileBannerImageText: 'obrMobileBannerImage',
  expertObrMicrositeUserVideoMessageText: 'obrVideoMessage',
  expertObrFormMicrositeIbutton:
    "Qrencia provides you a personalized web page dedicated solely to you and your achievements. It's like your digital portfolio, highlighting your background, organization affiliations, upcoming competitions, courses, and more. With this, you can expand your online presence, attract new opportunities, and connect with a wider audience in your field. Get ready to unlock new horizons for your professional journey",
  expertObrFormMicrositeWebBannerMessage:
    '<b>Desired dimension:</b><br> 1550px wide and 450px high',
  expertObrFormVideoMessageIButton:
    'To make your personalized web page even more engaging, we suggest you to record a short video introducing yourself, sharing your passion, and highlighting your most significant achievements',
  expertObrVideoThumbnailMessageDimension:
    '<b>Desired dimension:</b><br> 308px wide and 216px high',
  obrProfilePictureDimensions:
    '<b>Desired dimension:</b><br> 460px wide and 400px high',
  getEnroledCourseByStatusAPI: 'getEnrolledCoursesByStatus?status=',
  studentCoursesPath: '/student/courses',
  weekDaysOptions: [
    { key: 'Sun', name: 'Sunday' },
    { key: 'Mon', name: 'Monday' },
    { key: 'Tue', name: 'Tuesday' },
    { key: 'Wed', name: 'Wednesday' },
    { key: 'Thu', name: 'Thursday' },
    { key: 'Fri', name: 'Friday' },
    { key: 'Sat', name: 'Saturday' },
  ],
  expertMicroSiteStaticText: {
    ratingText: ' Rating',
    studentsEngagedText: ' Students Engaged',
    yearsOfExperienceText: ' Years Of Experience',
    workExperienceText: 'Work Experience',
    expertSkillsText: "Skills I'm Passionate About",
    socialLinksText: 'Social Links',
  },
  fetchStudentUpcomingCourseWidgetAPI:
    'getStudentUpcomingCourseOnSelectedDate?',
  expertMicroSiteS3ImagesPath: 'expert-microsite-page',
  expertVideoMessageThumbnailPath: 'expertVideoMessageThumbnail.jpg',
  expertMicrositeGetQtalksAPI: 'getQtalksForMicroSite',
  skillDocumentInfoIcon:
    'You may choose a certificate or any document which reflects your expertise in ',
  multipleFilesMessage:
    '. (In case of multiple files, you can upload a single zip file)',
  idProofInfoIcon:
    'You may choose from the following options: Passport, Aadhar Card, Driving License, or Voter ID. (In case of multiple files, you can upload a single zip file)',
  addressProofInfoIcon:
    'You may choose from the following options: Passport, Aadhar Card, Driving License, or Voter ID. (In case of multiple files, you can upload a single zip file)',
  invalidDateText: 'Invalid date',
  courseDescriptionInfo:
    "Enter about course, objective, guidelines and it's outcome",
  courseBrochureInfo:
    "Upload a file describing about the course, objective, guidelines and it's outcome",
  courseThumbnailInfo:
    'Please upload an image that can be used as thumbnail image for this course on Qrencia',
  courseWebBannerInfo:
    'Please upload web banner image in the dimension 1550 x 450',
  courseTabletBannerInfo:
    'Please upload tablet banner image in the dimension 800 x 350',
  courseMobileBannerInfo:
    'Please upload mobile banner image in the dimension 375 x 400',
  coursePromotionVideoInfo:
    'By uploading a promotion video, you can effectively spotlight your course, attracting students who are keen to enrol and take their learning journey to the next level',
  getPreSignedURLForUploadAPI: 'fetchPreSignedURLForUpload',
  s3FileContentDispositionInline: 'inline',
  s3ProfilePictureFolder: 'userProfilePicture',
  qtlaksBackgroundImageWebS3Path: 'qtlaks/backgroundImageWeb/',
  qtlaksBackgroundImageTabS3Path: 'qtlaks/backgroundImageTab/',
  qtlaksBackgroundImageMobileS3Path: 'qtlaks/backgroundImageMobile/',
  qtlaksThumbnailS3Path: 'qtlaks/thumbnail/',
  qtlaksEventS3Path: 'qtlaks/events/',
  qtalksSeoS3Path: 'qtalks/seo/',
  qtalksSpeakerImageS3Path: 'qtlaks/speaker/',
  s3ContentTypeOctetStream: 'application/octet-stream',
  workshopCertificateTilte: 'Certificate of Participation',
  downloadStudentReportSheetAPI: 'downloadStudentReportSheet?competitionId=',
  studentReportSheetDownloadFileName: 'result.xlsx',
  zeroIndex: 0,
  studentMylearningActivitySnapshotAPI: 'getStudentActivitySnapshot',
  studentTodaysActivityAPI: 'getLearningActivitiesForWidget',
  errorPageHeading: 'Whoops! We couldn’t find this page',
  errorPageSubHeading:
    'This link is outdated or incorrect. But fear not, fellow explorer! <br>You can always find your way back',
  landingPageS3Path: 'landing-page',
  discoverYourPassionS3Path: 'discover-your-passion-icons',
  brainBoosterIconImage: 'Brain-Boosters.png',
  competitionsIconImage: 'Unlimited-Skill-Competition.png',
  courseIconImage: 'Discount-on-Skill-Courses.png',
  feedbackFromExpertIconImage: 'Feedback-from-Experts.png',
  workshopIconImage: 'Discount-on-Mentorship.png',
  internetProtocols: {
    http: 'http',
    https: 'https',
  },
  enterDobSignup: 'Date of Birth',
  generateEmailOtpAPI: 'generateEmailVerificationOtp',
  verifyEmailOtpSignUpAPI: 'verifyUserEmail',
  feeWaivedCompetitionRoute:
    'fee-waived-competition/:categorySlug/:skillSlug/:competitionSlug',
  expertType: ['Individual', 'Organization'],
  expertTypeIndividual: 'Individual',
  expertTypeOrganization: 'Organization',
  obrVideoMessageThumbnail: 'videoMessageThumbnailImage',
  signupAndEnrolPageRoute: 'signup-and-enrol',
  signupPageGenerateMobileOtpAPI: 'generateMobileNumberOtp',
  signupPageVerifyMobileAPI: 'verifyUserMobileNumber',
  updateCompetitionFeeWaverApprovalFlagAPI:
    'updateCompetitionFeeWaverApprovalFlag',
  getFeeWaivedCompetitionDetailsAPI: 'getFeeWaivedCompetitionDetails',
  expertBulkEnrolRoute: '/expert/bulk-enrol',
  agencySkillDocumentOBRInfo:
    'You may upload a copy of the Articles of Association or Memorandum of Understanding that outlines the business structure, objectives, and legal framework.',
  agencyIdProofInfoIcon: 'You may upload a copy of business PAN Card etc.',
  agencyAddressProofIcon:
    'You may upload a copy of the official business registration document, such as a Certificate of Incorporation etc.',
  expertCreateCompetitionsWebBannerMessage:
    '<b>Desired dimension:</b><br> 1550px wide and 450px high',
  expertCreateCompetitionsThumbnailMessage:
    '<b>Desired dimension:</b><br> 825px wide and 550px high',
  expertCreateCompetitionsTabletBannerMessage:
    '<b>Desired dimension:</b><br> 800px wide and 350px high',
  expertCreateCompetitionsMobileBannerMessage:
    '<b>Desired dimension:</b><br> 375px wide and 400px high',
  bannerImagesS3Path: 'banner-images',
  competitionDefaultThumbnailImage: 'competition-thumbnail.png',
  competitionDefaultWebBanner: 'competition-web-banner.png',
  competitionDefaultTabletBanner: 'competition-tablet-banner.png',
  competitionDefaultMobileBanner: 'competition-mobile-banner.png',
  courseDefaultThumbnailImage: 'course-thumbnail.png',
  courseDefaultWebBanner: 'course-web-banner.png',
  courseDefaultTabletBanner: 'course-tablet-banner.png',
  courseDefaultMobileBanner: 'course-mobile-banner.png',
  signUpAndEnrolInCompetitionAPI: 'signUpAndEnrolInCompetition',
  checkStudentEligibilityToEnrolPartOneAPI:
    'checkStudentEligibilityToEnrol?competitionSlug=',
  checkStudentEligibilityToEnrolPartTwoAPI: '&userEmail=',
  disablePublicEnrolmentInfoIcon:
    'If selected, public enrolments will be disabled. If you opt for this choice and intend to enrol students, you will need to manually upload the participant sheet.',
  disablePerformanceAnalysisInfoIcon:
    'If selected, participants will not be able to view the performance analysis',
  enableTeamEnrolmentsInfo:
    'If selected, only team enrolments will be allowed for this competition',
  getDisabledPublicEnromentCompetitionsAPI:
    'getDisabledPublicEnromentCompetitions',
  individualText: 'Individual',
  emailEoiDetailsAPI: 'emailEoiDetails',
  eoiPageBenifitsSection: `<ul>
    <li><p> Access and <strong>tap students globally</strong> across skills: <span style="color:#0061b5">Scale up your business and grow your community</span></p></li>
    <li><p> Introduce <strong>new modes of learning</strong> in your teaching:<span style="color:#0061b5"> Differentiate your brand with focus on feedback &amp; Integrated learning</span></p></li>
    <li><p> Use <strong>AI teaching assistant </strong>for personalization:<span style="color:#0061b5"> Measure, monitor, and recommend activities to your students</span><p></li>
    <li><p> Reduce operation overhead to <strong>boost productivity</strong>:<span style="color:#0061b5"> Leverage an integrated coaching management system</span></p></li> 
    <li><p> Benefit from <strong>high payouts</strong> &amp; rewards:<span style="color:#0061b5"> Earn from multiple learning sources with no commission fees</span></p></li>
    </ul>`,
  eoiPageAboutQrenciaSection:
    'Qrencia is an organized marketplace for people to pursue their passion. Creators like yourself can offer a bouquet of learning activities for people to develop skills efficiently. What Zomato is for Food, Qrencia is for Passion!',
  expressionOfInterestS3Path: 'Expression-of-Interest',
  eoiPageBenefitsTapStudentsGloballyPath: 'tap-students-globally.png',
  eoiPageBenefitsNewModesPath: 'new-modes-of-learning.png',
  eoiPageBenefitsAiTeachingPath: 'AI-teaching-assistant.png',
  eoiPageBenefitsBoostProductivityPath: 'boost-productivity.png',
  eoiPageBenefitsHighPayoutsPath: 'High-payouts.png',
  // "expertBrochurePdfPath": "Expert-Brochure-Qrencia.pdf",
  expertBrochurePdfPath: 'Qrencia-Profile.pdf',
  eoiVideoThumbnailImagePath: 'Video-Thumbnail-Banner.png',
  eoiPageBenefitsTapStudentsText:
    'Scale up your business and grow your community',
  eoiPageBenefitsNewModesText:
    'Differentiate your brand with focus on feedback and Integrated learning',
  eoiPageBenefitsAiTeachingText:
    ' Measure, monitor, and recommend activities to your students',
  eoiPageBenefitsBoostProductivityText:
    'Leverage an integrated coaching management system',
  eoiPageBenefitsHighPayoutsText:
    'Earn from multiple learning sources with no commission fees',
  eoiDesktopBannerPath: 'EOI-Desktop-Banner.png',
  eoiTabletBannerPath: 'EOI-Tablet-Banner.png',
  eoiMobileBannerPath: 'EOI-Mobile-Banner.png',
  eoiPageBannerSubHeading:
    'Elevate your entrepreneurial journey fueled by passion',
  eoiAboutUsLink: 'EOI-about-us.pdf',
  declareResultsAPI: 'declareCompetitionResult',
  enrolInCourseVerifyPaymentAPI: 'enrolInCourseVerifyPayment',
  bulkEnrolStudentsAPI: 'bulkEnrolStudents',
  uploadParticipantSheetAPI: 'uploadParticipantSheet',
  bulkEnrolmentSheetName: 'BulkEnrolmentSheet.xlsx',
  downloadBulkEnrolmentSheetAPI:
    'downloadBulkEnrolmentTemplateSheet?competitionId=',
  updateIsProblemStatementFlagAPI: 'updateStudentQuestionPaperDownloadStatus',
  getCompetitionStatisticsForAdminAPI:
    'getCompetitionStatistics?competitionUniqueId=',
  getAllCompetitionsForAdminAPI: 'getAllCompetitions',
  competitionCardsDateMonthFormat: 'Do MMM, YYYY',
  richTextEditorModules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        {
          color: [
            'red',
            'blue',
            'green',
            'yellow',
            'purlpe',
            'orange',
            'black',
            'grey',
            '#224b7f',
            'white',
          ],
        },
      ],
      [
        {
          background: [
            'red',
            'blue',
            'green',
            'yellow',
            'purlpe',
            'orange',
            'black',
            'grey',
            '#224b7f',
            'white',
          ],
        },
      ],
      ['link'],
      ['clean'],
    ],
  },

  richTextEditorFormats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'clean',
    'link',
  ],
  viewExpertNudgeAttachment: 'View Attachment',
  downloadCourseCertificateForStudentAPI:
    'downloadCourseCertificateForStudent?courseUniqueId=',
  meritCertificateDefaultMessage: '<p>Default is top 3</p>',
  resultDeclareInfoText:
    'You can add result declaration date only when the judging parameters are configured',
  adminAIFeedbackPath: '/admin/ai-assessment',
  getDrawingFeedbackAPI: 'getDrawingFeedback',
  size6MB: 6 * 1024 * 1024,
  expertNudgeResponseFeedbackText: 'Feedback',
  certificateVerificationPageRoute:
    'verify-certificate/:eventType/:certificateNumber',
  getIndividualCompetitionPageRewardsAPI: 'getRewards?competitionId=',
  performanceAnalysisRewardBgPath: 'performance-rewards.png',
  certificateRewardBgPath: 'certificate-rewards.png',
  prizeRewardBgPath: 'prize-rewards.png',
  certificateVerificationWebBanner: 'web-banner-certificate-verification.jpeg',
  certificateVerificationMobileBanner:
    'mobile-baner-certificate-verification.jpeg',
  certificateVerificationTabletBanner:
    'tablet-banner-certificate-verification.jpeg',
  feedbackPageSpinnerText: [
    'Please wait while we analyze your drawing... 🧐⏳',
    'Evaluating color scheme... 🌈✍️',
    'Examining artistic quality... 🎨👀',
    'Looking for smudge marks... 🖌️',
    'Reviewing the thought process... 🧠',
    'Looking for eraser lines... ✏️',
    'Reviewing professionalism... 🧐',
  ],
  feedbackPageSpinnerLastText: 'Compiling the results... 🗒️✅',
  verifyCertificateAPI: 'verify-certificate',
  certificateVerificationEventTypes: ['course', 'competition', 'workshop'],
  courseUTCDateFormat: 'yyyy-MM-DD',
  greenVerificationGif: 'green-verification-loader.gif',
  preferredLogoHeightMessage: 'The preferred height of the logo is 75px',
  getRefundPolicyPageContent: 'getRefundPolicyPageContent',
  businessType: ['Expert', 'Academy'],
  obrUserRoles: ['Expert', 'Agency'],
  obrAgencyTypes: [
    'Academy',
    'Housing Society',
    'School',
    'Skill Organization',
  ],
  agencyOnboardingStatusRoute: '/agency-onboarding/:obrId',
  agencyObrDetailsUploadFolder: 'AgencyObr',
  agencyMicrositeRoute: '/agency/:micrositeSlug',
  getAgencyObrDetailsAPI: 'getAgencyObrDetails?agencyHeadEmail=',
  postAgencyObrDetailsAPI: 'postAgencyObr',
  agencyObrUploadsFolder: 'AgencyObr',
  sendObrLinkToAgencyAPI: 'resendObrLinkToAgency',
  updateAgencyObrStatusAPI: 'updateAgencyObrStatus',
  updateAgencyObrIsActiveFlagAPI:
    'updateAgencyObrIsActiveFlag?agencyObrUniqueId=',
  checkAgencyObrLinkValidityAPI:
    'checkAgencyObrLinkValidity?agencyObrUniqueId=',
  getSpecificAgencyObrDetailAPI:
    'getSpecificAgencyObrDetails?agencyObrUniqueId=',
  agencyObrFormVideoMessageIButton:
    "To make your institute's web page even more engaging, we suggest you to record a short video introducing yourself, sharing your passion, and highlighting your most significant achievements",
  getAgencyObrDetailsByActionStatusAPI:
    'getAgencyObrDetailsByActionStatus?actionStatus=',
  postAgencyDetailsAPI: 'postAgencyDetails',
  getAgencyMicrositeDetailsAPI: 'getAgencyMicrositeDetails/',
  getUpcomingCoursesByAgencyForMicrositeAPI:
    'getUpcomingCoursesByAgencyForMicrosite?agencyUniqueId=',
  getUpcomingCompetitionByAgencyForMicrositeAPI:
    'getUpcomingCompetitionByAgencyForMicrosite?agencyUniqueId=',
  agencyMapStudentsPath: '/agency/map-students',
  agencyMapTeachersPath: '/agency/map-teachers',
  fetchSectionsForSelectedClassAPI: 'getStudentClassSection?studentClass=',
  schoolClassArray: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ],
  studentMapBulkUploadAPI: 'studentBulkUpload',
  fetchSchoolStudentListAPI: 'getMyStudentDetails?studentClass=',
  downloadStudentTemplateSheetAPI: 'downloadStudentTemplateSheet',
  mapStudentTemplateSheet: 'StudentTemplateSheet.xlsx',
  inputFieldTypes: {
    text: 'text',
    file: 'file',
    number: 'number',
    date: 'date',
    time: 'time',
    email: 'email',
    button: 'button',
    checkbox: 'checkbox',
    password: 'password',
    radio: 'radio',
    url: 'url',
  },
  getExpertDetailsForQrenciaIdAPI:
    'getExpertDetailsForQrenciaId?expertQrenciaId=',
  signupAndLinkToSchoolPageRoute: 'signup-and-link',
  checkStudentEligibilityToLinkPartOneAPI:
    'checkStudentEligibilityToMap?studentEmail=',
  checkStudentEligibilityToLinkPartTwoAPI: '&schoolHeadQrenciaId=',
  signUpAndLinkToSchoolAPI: 'signUpAndMapWithAgency',
  enterSchoolNameLabelText: 'School Name',
  enterClassLabelText: 'Class',
  enterSectionLabelText: 'Section',
  sendMapRequestToExpertByAgencyAPI:
    'sendMapRequestToTeacherBySchool?teacherUniqueId=',
  expertUpdateInvitationStatusPath:
    '/association-invitation/:agencyUniqueId/:expertUniqueId',
  fetchExpertMappingPageAgencyDetailsAPI:
    'getSchoolDetailsForMappingTeacher?schoolUniqueId=',
  getTeachersMappingDetailsWithSchoolAPI: 'getTeachersMappingDetailsWithSchool',
  agencyMonitoringDashboardPath: '/agency/monitoring-dashboard',
  agencyMonitoringDashboardStatisticsAPI: 'fetchSchoolStatistics',
  agencyMonitoringDashboardLearningActivityAPI:
    'getAllCompetitionsUnderSchool?status=All',
  agencyExpertInvitationStatus: {
    accepted: 'Accepted',
    rejected: 'Rejected',
    sent: 'Sent',
    associated: 'Associated',
    notAssociated: 'Not Associated',
  },
  updateExpertSchoolMappingStatusAPI: 'updateExpertSchoolMappingStatus',
  updateExpertSchoolMappingAPIParams: {
    schoolUniqueId: 'schoolUniqueId',
    status: 'status',
    teacherUniqueId: 'teacherUniqueId',
  },
  excludedKeywordsNumberField: ['-', 'e', '+', 'E', '.', ' '],
  fetchSchoolLearningActivityStatsAPI:
    'fetchSchoolLearningActivityStatistics?learningActivityUniqueId=',
  getExpertMySchoolCompetitionsAPI: 'getExpertMySchoolCompetitions',
  getStudentAssociationWithSchoolAPI:
    'checkLoggedInStudentIsAssociatedToSchool',
  getClassAndSectionsForSchoolIdAPI: 'getClassAndSectionsForSchoolId?schoolId=',
  expertFetchCompetitionsStatsUnderMySchoolAPI:
    'fetchCompetitionsStatsUnderMySchool',
  getUpcomingCompetitionsForStudentUnderMySchoolAPI:
    'getUpcomingCompetitionsForStudentUnderMySchool',
  learningActivityGridDateTimeFormat: 'D MMM, YYYY hh:mm A',
  preferredTimezoneParam: 'preferredTimezone=',
  utcTimezone: 'UTC',
  initiatePaymentAPI: 'initiatePayment',
  getClassesInSchoolAPI: 'getStudentClassesUnderSchool',
  paymentStatus: {
    pending: 'Pending',
    verified: 'Verified',
    initiated: 'Initiated',
    failed: 'Failed',
    success: 'Success',
  },
  VerifyPaymentStatusAPI: 'verifyPaymentStatus',
  agencyObrDesktopPageName: 'Agency-OBR-desktop-page',
  agencyObrMobilePageName: 'Agency-OBR-mobile-page',
  agencyObrTabletPageName: 'Agency-OBR-tablet-page',
  activateNudgeAPI: 'activateNudge?uniqueId=',
  paymentVerificationTimeout: 5000,
  paymentGatewayRedirectTimeout: 3000,
  individualSkillPageActivitySection:
    'Individual-skills-page-activity-section-heading',
  activeExpertsAPI: 'getQrenciaActiveExperts',
  activitiesType: ['Experts', 'Courses', 'Competitions', 'Workshops', 'Nudges'],
  getAllActivitiesAPI: 'getAllActivities',
  ISPgetAllActivitiesForSkillAPI: 'getAllActivitiesForSkill/',
  courseCardsTimeDateFormat: 'hh:mm a, dd LLL yyyy',
  courseCardsDateFormat: 'dd LLL yyyy',
  ISPcourseDateFormat: 'Do MMM YYYY',
  individualSkillsCompetitionBannerPath:
    'individual-skills-competition-background.png',
  ISPLearningActivityCardsLimit: 9,
  getAIBannerImagesAPI: 'getRecommendedAIGeneratedCompetitionImages',
  fetchConfigurableKeysAPI: 'fetchApplicationConfigurableKeys',
  competitionMinCostKey: 'Competition_Cost_Minimum_Value',
  selectFileText: 'Select a file',
  checkIfActiveExpertExistsForSkillAPI:
    'checkIfActiveExpertExistsForSkillUniqueId?skillUniqueId=',
  fetchSkillsForNudgeAPI: 'fetchSkillsMappedWithCreator',
  getSkillExpertsAPI: 'getSkillExperts?skillId=',
  quizEventPageRoute: 'events/quiz/:quizSlug',
  getRecaptchaSiteKeyAPI: 'getRecaptchaSiteKey',
  onLeaveMessage: 'Are you sure you want to leave? Your progress will be lost.',
  validCaptchaTokenAPI: 'validateCaptchaToken',
  getQuizDetailsAPI: 'getQuizDetailsForSlug/',
  postSuccessCodeForQuizAPI: 'postSuccessCodeForQuizUniqueId',
  alphaNumericCharSet:
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  quizType: {
    mcq: 'Mcq',
    text: 'Text',
  },
  correctAnswerDelimiter: ';',
  quizSuccessCodeGeneratorLength: 8,
  quizCompletionCodeMessage: 'Your quiz completion code is',
  quizPageHeading: "Welcome to Qrencia's Quiz",
  quizPageSubHeading: "Let's get started",
  quizPageParticipationMessage: 'Thank you for your participation!',
  certificateOfParticipation: 'Certificate of Participation',
  editCompetitionAPI: 'updateCompetition',
  judgingStartedAPI: 'isJudgingStarted?competitionUniqueId=',
  expertAssociationWithSchoolAPI: 'checkLoggedInExpertIsAssociatedToSchool',
  jsDateTimeStartFormat: 'EEE MMM dd yyyy HH:mm:ss',
  aiImagGenDefaultPath: 'create-competition-page/ai-image-gen-static-image.png',
  browserDefaultDateFormat: 'yyyy-LL-dd',
  createWorkshopText: 'Create Workshop',
  createWorkshopActions: [
    'Add Workshop Details',
    'Add FAQ',
    'Configure Certificate',
    'Advanced Settings',
  ],
  workshopDetailsFormLabels: {
    title: { label: 'Title', name: 'title' },
    description: { label: 'Description', name: 'description' },
    descriptionDocument: {
      label: 'Workshop Brochure',
      name: 'descriptionDocument',
    },
    skill: { label: 'Skill', name: 'skill' },
    startDate: { label: 'Start Date', name: 'startDate' },
    endDate: { label: 'End Date', name: 'endDate' },
    startTime: { label: 'Start Time', name: 'startTime' },
    endTime: { label: 'End Time', name: 'endTime' },
    durationInHours: {
      label: 'Total Duration (in hours)',
      name: 'durationInHours',
    },
    modeOfDelivery: { label: 'Mode of Delivery', name: 'modeOfDelivery' },
    address: { label: 'Address', name: 'address' },
    workshopLink: { label: 'Workshop Link', name: 'workshopLink' },
    cost: { label: 'Cost (INR)', name: 'cost' },
    geoLocation: { label: 'Geo Location', name: 'geoLocation' },
    contactDetails: { label: 'Contact Details', name: 'contactDetails' },
    expertName: { label: 'Name', name: 'expertName' },
    expertEmail: { label: 'Email Id', name: 'expertEmail' },
    expertMobile: { label: 'Mobile number', name: 'expertMobile' },
    thumbnailImage: { label: 'Thumbnail Image', name: 'thumbnailImage' },
    promotionImageForWeb: {
      label: 'Promotion image for web',
      name: 'promotionImageForWeb',
    },
    promotionImageForTablet: {
      label: 'Promotion image for tablet',
      name: 'promotionImageForTablet',
    },
    promotionImageForMobile: {
      label: 'Promotion image for mobile',
      name: 'promotionImageForMobile',
    },
    promotionVideo: { label: 'Promotion Video', name: 'promotionVideo' },
    registrationClosingDate: {
      label: 'Registration Closing Date',
      name: 'registrationClosingDate',
    },
    registrationClosingTime: {
      label: 'Registration Closing Time',
      name: 'registrationClosingTime',
    },
  },
  workshopDescriptionInfo:
    "Enter about workshop, objective, guidelines and it's outcome",
  workshopBrochureInfo:
    "Upload a file describing about the workshop, objective, guidelines and it's outcome",
  workshopPromotionVideoInfo:
    'By uploading a promotion video, you can effectively spotlight your workshop, attracting students who are keen to enrol and take their learning journey to the next level',
  workshopTypeOnline: 'Online',
  workshopTypeOffline: 'Offline',
  expertCreateWorkshopWebBannerMessage:
    '<b>Desired dimension:</b><br> 1550px wide and 450px high',
  expertCreateWorkshopThumbnailMessage:
    '<b>Desired dimension:</b><br> 825px wide and 550px high',
  expertCreateWorkshopTabletBannerMessage:
    '<b>Desired dimension:</b><br> 800px wide and 350px high',
  expertCreateWorkshopMobileBannerMessage:
    '<b>Desired dimension:</b><br> 375px wide and 400px high',
  workshopDetailsFolder: 'WorkshopDetails',
  workshopCertificateUploadFolder: 'CertificateDetails',
  workshopsUploadPath: 'Workshops',
  createWorkshopAPI: 'createWorkshop',
  workshopText: 'workshop',
  expertpublishUnpublishWorkshopPath: '/expert/publish-workshops',
  agencypublishUnpublishWorkshopPath: '/agency/publish-workshops',
  fetchWorkshopsByStatusAPI: 'getWorkshopByStatus?status=',
  workshopPublishedStatus: {
    published: 'Published',
    unpublished: 'Not Published',
  },
  workshopDefaultThumbnailImage: 'workshop-thumbnail.png',
  workshopsText: 'workshops',
  publishWorkshopConfirmation: 'Publish workshop',
  unpublishWorkshopConfirmation: 'Unpublish workshop',
  updateWorkshopStatusAPI: 'updateWorkshopStatus?workshopUniqueId=',
  workshopStatus: {
    upcoming: 'Upcoming',
    ongoing: 'Ongoing',
    past: 'Past',
  },
  getSpecificWorkshopDetailsAPI:
    'getWorkshopDetailsForUniqueId?workshopUniqueId=',
  downloadWorkshopCertificateAPI:
    'downloadWorkshopCertificate?workshopUniqueId=',
  updateWorkshopDetailsAPI: 'updateWorkshopDetails',
  loadMoreWorkshopCardsCount: 6,
  promotionalVideoAllowedFormats: [
    'video/mp4',
    'video/mkv',
    'video/x-m4v',
    'video/quicktime',
    'video/ai',
    'video/ogg',
    'video/webm',
    'video/x-msvideo',
  ],
  individualWorkshopPageRoute:
    'workshops/:categorySlug/:skillSlug/:workshopSlug',
  getIndividualWorkshopPageDetailsAPI: 'getIndividualWorkshopPageDetails/',
  checkStudentWorkshopEnrolmentStatusAPI:
    'checkStudentWorkshopEnrolmentStatus?workshopUniqueId=',
  getWorkshopReviewCommentsAPI: 'getWorkshopReviewComments?workshopUniqueId=',
  postWorkshopReviewCommentAPI: 'postWorkshopReviewComment',
  brochureFile: 'Brochure.pdf',
  enrolInWorkshopAPI: 'enrolInWorkshop?workshopUniqueId=',
  paymentUrlParam: 'p',
  workshopDefaultWebBanner: 'workshop-web-banner.png',
  workshopDefaultTabletBanner: 'workshop-tablet-banner.png',
  workshopDefaultMobileBanner: 'workshop-mobile-banner.png',
  getFeaturedWorkshopsForStudentAPI: 'getFeaturedWorkshopsForStudent',
  micrositeGetUpcomingWorkshopsAPIWithParam: 'getUpcomingWorkshopsForMicrosite',
  micrositeGetUpcomingWorkshopsAPIParamTwo: '&userRole=',
  cardCounts: {
    todaysActivityDesktopCardsCount: 2,
    todaysActivityTabCardsCount: 2,
    todaysActivityMobileCardsCount: 1,
    workshopTodaysActivitiesCardsCount: 1,
    studentUpcomingWorkshopsCardsCount: 2,
    learningActivityWorkshopCardsCount: 2,
    studentWorkshopPageMobileCardsCount: 2,
    studentWorkshopPageTabCardsCount: 4,
    studentWorkshopPageWebCardsCount: 3,
    expertMicrositeWorkshopDesktopCardsCount: 3,
    expertMicrositeWorkshopTabletCardsCount: 4,
    expertMicrositeWorkshopMobileCardsCount: 2,
    skillPageWorkshopPageMobileCardsCount: 1,
    skillPageWorkshopPageTabCardsCount: 2,
    skillPageWorkshopPageWebCardsCount: 4,
    learningActivityDesktopCount: 9,
    learningActivityTabCount: 10,
    learningActivityMobileCount: 10,
    expertsPageCardsCount: 16,
    competitionsPageCardCount: 16,
    micrositeReviewCardCount: 3,
    individualSkillsReviewCount: 2,
    rightWidgetCardCounts: 2,
    myLearningCardCount: 4,
  },
  submissionStartedWindowInHrs: 0.5,
  getRecentlyPublishedWorkshopsForStudentAPI:
    'getRecentlyPublishedWorkshopsForStudent',
  downloadWorkshopCertificateForStudentAPI:
    'downloadWorkshopCertificate?workshopUniqueId=',
  getQuizDataAPI: 'getAllActiveQuizzes',
  getQuizUrl: '/events/quiz/',
  expertHomeAdDefaultImagePath: 'expertHomeAdDefaultImage.png',
  agencyHomeAdDefaultImagePath: 'agencyHomeAdDefaultImage.png',
  signupPageText: 'signupPage',
  enrolAndSignupPageText: 'enrolAndSignupPage',
  followUnfollowStatus: {
    follow: 'Follow',
    unfollow: 'Unfollow',
    following: 'Following',
  },
  checkUserFollowStatusAPI: 'checkUserFollowStatus?userUniqueId=',
  followUnfollowUserAPI: 'followUnfollowUser?userUniqueId=',
  discoverYourPassionButtonTextDelim: ',',
  getAssociatedExpertsAPI: 'getExpertsFollowedByAgency?agencyUniqueId=',
  agencyMicrositeDefaultWebBanner: 'agency-microsite-default-web-banner.png',
  agencyMicrositeDefaultTabBanner: 'agency-microsite-default-tab-banner.png',
  agencyMicrositeDefaultMobileBanner:
    'agency-microsite-default-mobile-banner.png',
  expertMicrositeDefaultWebBanner: 'expert-microsite-default-web-banner.png',
  expertMicrositeDefaultTabBanner: 'expert-microsite-default-tab-banner.png',
  expertMicrositeDefaultMobileBanner:
    'expert-microsite-default-mobile-banner.png',
  minimumFollowersCountConfigurableKey: 'Minimum_Followers_Count',
  twitterXIconPath: 'Qrencia_TwitterX_Icon.png',
  eoiPageSkyrocketQrenciaSection: {
    buildBrand:
      'Increase visibility with Search Engine Optimized branding page',
    earnMore: 'Offer diverse learning activities with no commission',
    expandReach: 'Easy access to learner community across skills',
    reduceTime: 'Fully automated coaching management system',
    growBusiness: 'Personalization at global scale with growth tools',
  },
  expertPageRoute: 'experts',
  competitionsPageRoute: 'competitions',
  courseListingPageRoute: 'courses',
  workshopListingPageRoute: 'workshops',
  expertPageSkillCategoryRoute: 'experts/:categorySlug',
  expertPageName: 'Experts-listing-page',

  generateCertificateZipForCourseAPI:
    'generateCertificateZipForCourse?uniqueId=',
  generateCertificateZipForWorkshopAPI:
    'generateCertificateZipForWorkshop?uniqueId=',
  generateCertificateZipForCompetitionAPI:
    'generateCertificateZipForCompetition',
  quotesRegex: /^[^"]*$/,
  getPageContentAPI: 'getPageContent',
  allSkillsPageName: 'All-skills-page',
  certificatesZipFileName: 'certificates.zip',
  loginFollowMessage: 'Login to follow',
  signupFollowMessage: 'Signup to follow',
  agencyTypes: {
    academy: 'Academy',
    school: 'School',
    skillOrganization: 'Skill Organization',
    housingSociety: 'Housing Society',
  },
  getLoggedInAgencyTypeAPI: 'getLoggedInAgencyType',
  getSkillsofAgencyAPI: 'getSkillsOfAgency',
  getUsersFollowedByAgencyAPI: 'getUsersFollowedByAgency?agencyUniqueId=',
  eoiMessageDisappearTime: 15000,
  enrolInCourseAPI: 'enrolInCourse',
  updateCourseEnrolmentFlagAPI:
    'updateStudentCourseEnrolmentFlag?courseUniqueId=',
  copyLearningActivityActions: {
    select: 'SELECT',
    reset: 'RESET',
  },
  getCompetitionDetailsForCompetitionIdAPI:
    'getCompetitionDetailsForCompetitionId?competitionId=',
  getCourseDetailsForCourseIdAPI: 'getCourseDetailsForCourseId?courseId=',
  getWorkshopDetailsForWorkshopIdAPI:
    'getWorkshopDetailsForWorkshopId?workshopId=',
  getStudentQueriesAPI: 'getStudentQueries?learningActivityId=',
  studentHomePageName: 'Student-Home-Page',
  expressionOfInterestThankyouRoute: '/eoi/thankyou',
  expressionOfInterestSalutation: 'Dear ',
  expressionOfInterestThankyouMessage:
    '<br><br>Thank you for expressing your interest! Your onboarding application has been received and is under review. Our team will reach out to you soon. <br><br> Regards,<br> Ram<br>Team Qrencia',
  competitionRegistrationDeadlineInfo:
    "If no registration deadline is specified, it will automatically default to 1 minute before the competition's end time.",
  courseRegistrationDeadlineInfo:
    'If no registration deadline is specified, it will automatically default to 1 minute before the course end time.',
  workshopRegistrationDeadlineInfo:
    "If no registration deadline is specified, it will automatically default to 1 minute before the workshop's end time.",
  getLearningActivitiesForTypeAPI:
    'getLearningActivities?learningActivityType=',
  getActiveCoursesForFeedbackAPI: 'getActiveCoursesForFeedback',
  submitReviewAPI: 'rateAndReviewCreator',
  getCreatorReviewsForMicrositeAPI: 'getCreatorReviews?userUniqueId=',
  getProfanityApiDetailsAPI: 'getProfanityApiDetails',
  checkStudentEligibilityToRateCreatorAPI:
    'checkStudentEligibilityToRateCreator?reviewedToUserId=',
  getReviewsForCreatorAPI: 'getCreatorReviewsForSkill?skillUniqueId=',
  getLearningActivityReviewsForSkillAPI:
    'getLearningActivityReviewsForSkill?skillUniqueId=',
  micrositeReviewTextLimit: 250,
  emojiArray: ['😔', '😕', '😐', '😊', '🤩'],
  whatLearnersSayAboutText: 'What learners say about',
  moreReviewsText: 'More reviews',
  checkStudentEligibilityToPostCourseReview:
    'checkStudentEligibilityToReviewLearningActivity?uniqueId=',
  searchSkillSectionTexts: [
    'Looking for Experts?',
    'Looking for Workshops?',
    'Looking for Courses?',
    'Looking for Competitions?',
    'Looking for Feedback?',
  ],
  searchSkillTextChangeDuration: 3000,
  eoiPageName: 'Expression-of-interestPage',
  eoiMobileCarouselDuration: 1000,
  learningActivityTypesArray: ['Competition', 'Course', 'Workshop', 'Nudge'],
  dateFilterPresets: {
    allTime: { label: 'All Time', key: 'allTime', from: '', to: '' },
    today: { label: 'Today', key: 'today', from: 'today', to: 'today' },
    weekToDate: {
      label: 'Week to Date',
      key: 'weekToDate',
      from: 'week',
      to: 'today',
    },
    monthToDate: {
      label: 'Month to Date',
      key: 'monthToDate',
      from: 'month',
      to: 'today',
    },
    yearToDate: {
      label: 'Year to Date',
      key: 'yearToDate',
      from: '',
      to: 'today',
    },
    previousMonth: {
      label: 'Previous Month',
      key: 'previousMonth',
      from: '',
      to: '',
    },
    previousYear: {
      label: 'Previous Year',
      key: 'previousYear',
      from: '',
      to: '',
    },
    lastThreeMonths: {
      label: 'Last 3 Months',
      key: 'lastThreeMonths',
      from: '',
      to: '',
    },
    lastSixMonths: {
      label: 'Last 6 Months',
      key: 'lastSixMonths',
      from: '',
      to: '',
    },
  },
  adminDashboardPaginationSize: 10,
  getQrenciaStatsForSelectedDateRangeAPI: 'getQrenciaStatsForSelectedDateRange',
  getQrenciaStatsForAdminAPI: 'getQrenciaStats',
  getSelectedLearningInfoForAdminAPI:
    'getSelectedLearningInfo?learningActivityType=',
  getPublishedLearningActivitiesForAdminAPI:
    'getPublishedLearningActivities?learningActivityType=',
  downloadEnrolledStudentsForAdminAPI:
    'downloadEnrolledStudents?learningActivityType=',
  downloadQrenciaActiveLearnersAPI: 'downloadQrenciaActiveLearners',
  qrenciaActiveLearnersFileName: 'QrenciaActiveLearners.xlsx',
  getQrenciaLearnersForAdminAPI: 'getQrenciaLearners',
  getCreatorsInfoForSelectedDateRangeAPI: 'getCreatorsInfoForSelectedDateRange',
  getCreatorsInfoForAdminAPI: 'getCreatorsInfo',
  getCreatorWiseLearningActivityDetailsAPI:
    'getCreatorWiseLearningActivityDetails?userQrenciaId=',
  fromText: 'From',
  toText: 'To',
  sourceTypeEOI: 'EOI',
  leadTypes: {
    expert: 'Expert',
    academy: 'Academy',
    school: 'School',
    housingSociety: 'Housing Society',
    skillOrganization: 'Skill Organization',
  },
  teamInstitueType: ['Academy', 'School'],
  captureQrenciaLeadsAPI: 'captureQrenciaLeads',
  captureSubscriptionLeadsAPI: 'postQrenciaLeadsEoi',
  getLeadsDetailsAPI: 'getQrenciaLeads?status=',
  onboardingStatus: {
    onboarded: 'Onboarded',
    notOnboarded: 'Not Onboarded',
  },
  generateLeadsForExpertLabel: {
    firstName: { name: 'firstName', label: 'First Name' },
    lastName: { name: 'lastName', label: 'Last Name' },
    email: { name: 'email', label: 'Email' },
    phoneNumber: { name: 'phoneNumber', label: 'Phone Number' },
    dob: { name: 'dob', label: 'Date of Birth' },
    country: { name: 'country', label: 'Country' },
    countryDropdown: { name: 'countryDropdown', label: 'Country' },
    state: { name: 'state', label: 'State' },
    stateNotIndia: { name: 'stateNotIndia', label: 'State' },
    city: { name: 'city', label: 'City' },
    cityNotIndia: { name: 'cityNotIndia', label: 'City' },
    selectedSkills: { name: 'selectedSkills', label: 'Skill(s)' },
    instituteName: { name: 'instituteName', label: 'Institute Name' },
    websiteLink: { name: 'websiteLink', label: 'Website Link' },
    rating: { name: 'rating', label: 'Rating' },
    topCreatorFlag: { name: 'topCreatorFlag', label: 'Top Expert' },
    demoDate: { name: 'demoDate', label: 'Demo Date' },
    isExistingBussinessOnQ: {
      name: 'isExistingBussinessOnQ',
      label: 'Existing Business',
    },
    currentStatus: { name: 'ucurrentStatus', label: 'Current Status' },
    leadStatus: { name: 'leadStatus', label: 'Lead Status' },
  },
  generateLeadsForAgencyLabel: {
    firstName: { name: 'firstName', label: 'SPOC First Name' },
    lastName: { name: 'lastName', label: 'SPOC Last Name' },
    email: { name: 'email', label: 'SPOC Email' },
    phoneNumber: { name: 'phoneNumber', label: 'SPOC Phone Number' },
    country: { name: 'country', label: 'SPOC Country' },
    countryDropdown: { name: 'countryDropdown', label: 'Country' },
    state: { name: 'state', label: 'SPOC State' },
    stateNotIndia: { name: 'stateNotIndia', label: 'SPOC State' },
    city: { name: 'city', label: 'SPOC City' },
    cityNotIndia: { name: 'cityNotIndia', label: 'SPOC City' },
    selectedSkills: { name: 'selectedSkills', label: 'Skill(s)' },
    instituteName: { name: 'instituteName', label: 'Agency Name' },
    websiteLink: { name: 'websiteLink', label: 'Website Link' },
    rating: { name: 'rating', label: 'Rating' },
    topCreatorFlag: { name: 'topCreatorFlag', label: 'Top Agency' },
    demoDate: { name: 'demoDate', label: 'Demo Date' },
    isExistingBussinessOnQ: {
      name: 'isExistingBussinessOnQ',
      label: 'Existing Business',
    },
    currentStatus: { name: 'ucurrentStatus', label: 'Current Status' },
    leadStatus: { name: 'leadStatus', label: 'Lead Status' },
  },
  creatorRating: {
    fiveStar: '5 Star',
    fourStar: '4 Star',
    threeStar: '3 Star',
    twoStar: '2 Star',
    oneStar: '1 Star',
  },
  adminManageLeadsRoute: '/admin/manage-leads',
  existingBusinessOptions: ['No', 'Low', 'Medium', 'High'],
  userRoleSales: 'Sales',
  userRoleMarketing: 'Marketing',
  salesDashboardPath: '/sales/dashboard',
  signupMinAgeKey: 'Signup_Minimum_Age',
  expertOnboardingText: 'expert-onboarding',
  agencyOnboardingText: 'agency-onboarding',
  signupEnrolmentModalTimer: 10000,
  signupEnrolmentModalDurationKey: 'Enrolment_Modal_Timer_Duration_Seconds',
  sendObrReminderEmailAPI: 'sendObrReminderEmail',
  sendReminderEmailToListOfferingsAPI: 'sendReminderEmailToListOfferings',
  adminBulkEnrolPath: '/admin/bulk-enrol',
  adminBulkSignupPath: '/admin/bulk-signup',
  currentLeadStatusOptions: ['Low', 'Medium', 'High'],
  checkIfLoginIsRequiredForQuizAPI: 'checkIfLoginIsRequiredForQuiz?quizSlug=',
  adminBulkEnrolStudentList: 'BulkEnrolLearnersSheet.xlsx',
  adminBulkSignupStudentList: 'BulkSignupLearnersSheet.xlsx',
  downloadStudentSignUpAndEnrolmentTemplateAPI:
    'downloadStudentSignUpAndEnrolmentTemplate',
  studentBulkSignUpAndEnrolAPI: 'studentBulkSignUpAndEnrol',
  adminStudentBulkSignUpAPI: 'studentBulkSignUp',
  downloadStudentBulkSignUpTemplateAPI: 'downloadStudentBulkSignUpTemplate',
  agGridColumnSeparator: '##',
  salesLeadsPath: '/sales/manage-leads',
  marketingBulkEnrolPath: '/marketing/bulk-enrol',
  marketingBulkSignupPath: '/marketing/bulk-signup',
  qiuzCorrectAnswerDisplayDuration: 2000,
  nudgesPageRoute: '/nudges',
  nudgePageName: 'Nudge-Page',
  courseTypes: ['Structured', 'Flexible'],
  structuredCourseType: 'Structured',
  flexibleCourseType: 'Flexible',
  flexibleCourseMinimumCost: 1,
  courseTypeDropdownInfo:
    '<b>Structured courses</b> have a start and end date and will be completed once the end date has passed.<br /> <b>Flexible courses</b> do not have a start and end date. New learners can enrol in these course at any time.',
  updateEnrolmentsAllowedStatusForCourseAPI:
    'updateEnrolmentsAllowedStatusForCourse?courseUniqueId=',
  bannerTypeDropdownInfo: 'Image preferred size is: 1497 × 435 px',
  minimumCourseReviewsCardDisplay: 'Course_Card_Minimum_Review_Count',
  markAttendancePath: '/course/mark-attendance',
  markAttendanceForSpecificCoursePath:
    '/course/mark-attendance/:courseUniqueId',
  sendReminderEmailToRenewFlexiCourseSubscriptionAPI:
    'sendReminderEmailToRenewFlexiCourseSubscription?courseUniqueId=',
  attendanceStatus: {
    present: 'Present',
    absent: 'Absent',
  },
  getAvailableCoursesForAttendanceAPI: 'getAvailableCoursesForAttendance',
  fetchCreatorBannerDetailsAPI: 'getCreatorBannerDetails',
  fetchAttendanceDatesForStructuredCoursAPI:
    'structured-courses/available-dates?courseUniqueId=',
  addClassText: 'Add Class',
  renewPlanText: 'Renew Plan',
  getStudentAttendanceDetailsAPI: 'getStudentAttendanceDetails?courseUniqueId=',
  markAttendanceAPI: 'markCourseAttendance',
  subscriptionStatus: {
    active: 'Active',
    expired: 'Expired',
  },
  eoiCarouselPage: 'Expression-Of-Interest-Carousel-Page',
  marketingDashboardPath: '/marketing/dashboard',
  marketingNumberTrackingPath: '/marketing/number-tracking',
  trackingTypes: ['Not Enabled', 'Class Based', 'Duration Based'],
  countAbsentAsClassTooltip:
    'If selected, marking a learner as absent will reduce their remaining class count by 1. If not selected, marking a learner as absent will not reduce their remaining classes.',
  flexiAttendanceTrackingOptions: {
    durationBasedTracking: {
      label: 'Duration Based Tracking',
      value: 'Duration Based',
      description:
        '1. In duration-based tracking, A package duration (in days) is defined, indicating the number of days after which enrolment of a learner will expire.',
    },
    classsBasedTracking: {
      label: 'Class Based Tracking',
      value: 'Class Based',
      description:
        "2. In class-based tracking, tracking is done based on number of classes and class validity. Learner's enrolment will expire when there are no classes remaining or if the class validity has expired.",
    },
  },
  structuredAttendanceTrackingOptions: {
    notEnabled: {
      label: 'No Tracking',
      value: 'Not Enabled',
      description:
        '1. If no tracking option is selected, then option to manage attendance will not be available',
    },
    classsBasedTracking: {
      label: 'Class Based Tracking',
      value: 'Class Based',
      description:
        '2. If class-based tracking is selected, then option to manage attendance will be available',
    },
  },
  trackingNotEnabledText: 'Not Enabled',
  classBasedTrackingText: 'Class Based',
  durationBasedTrackingText: 'Duration Based',
  enableAttendanceForClassesTooltip:
    'If selected, you will be able to manage the attendance and tracking of enrolled learners. If not selected, you can reduce the remaining classes of learner through a counter.',
  enableAttendanceForDurationTooltip:
    'If selected, you will be able to manage the attendance and tracking of enrolled learners.',
  paymentMethod: {
    offline: 'Offline',
    online: 'Online',
  },
  saveClassTrackingDetailsAPI: 'updateCourseClasses',
  expertOfflinePaymentPath: '/expert/pending-enrolments',
  agencyOfflinePaymentPath: '/agency/pending-enrolments',
  getPendingOfflinePaymentRequestsForLearningActivityAPI:
    'getOfflinePaymentRequestsForLearningActivity',
  adminGetOfflinePaymentRequestDetailsAPI: 'getOfflinePaymentRequestsDetails',
  getLoggedInStudentOfflinePaymentRequestDetailsAPI:
    'getLoggedInStudentOfflinePaymentRequestDetails',
  updateOfflinePaymentRequestStatusAPI:
    'updateOfflinePaymentRequestApprovalStatus',
  raisePayOfflineRequestAPI: 'raisePayOfflineRequest',
  offlinePaymentTooltipInfo:
    'By enabling offline payment, you will have an option to collect fees directly from the learners.',
  pendingCODStatus: {
    approve: 'Approved',
    cancel: 'Cancelled',
    pending: 'Pending',
  },
  approveText: 'Approve',
  rejectText: 'Reject',
  viewText: 'View',
  approvePendingEnrolmentsRoute: '/pending-enrolment-requests',
  qrenciaEnrolledLearnersAPI: 'qrenciaEnrolledLearners',
  workshopOnboardingPage: '/onboarding-workshop',
  adminLearnerAgeGroupDisplay: 'Learners_Age_Group_Display',
  onboardingWorkshopLinkKey: 'Onboarding_Workshop_Link',
  expertHomePageName: 'Expert-Home-Page',
  agencyHomePageName: 'Agency-Home-Page',
  ambassadorText: 'Ambassador',
  acceptAmbassadorChallengeAPI: 'acceptAmbassadorChallenge',
  hasCreatorAcceptedAmbassadorChallengeAPI:
    'hasCreatorAcceptedAmbassadorChallenge',
  ambassadorChallengeCarouselDuration: 10000,
  creatorHomePageName: 'Creator-Home-Page',
  razorpayInitiatePaymentsAPI: 'createOrder',
  razorpayVerifyPaymentsAPI: 'verifyUserPayments',
  obrGalleryInfoIconText:
    'These images/videos will be featured as a spotlight on your branding page, highlighting your experiences, teaching environment and achievements.',
  creatorGallerySingleView: 560,
  enrolmentDelayTimeout: 4000,
  mediaTypes: {
    image: 'Image',
    video: 'Video',
  },
  micrositeGalleryImagesCount: 4,
  partnerSuccessRecommendCreatorsPath: '/partner-success/recommend-creators',
  userRolePartnerSuccess: 'Partner Success',
  qrenciaTaglineLogoFile: 'Qrencia_logo_with_tagline.png',
  qLogo: 'Qrencia-logo-Q.png',
  razorpaypaymentFailed: 'payment.failed',
  capturePaymentFailureStateAPI: 'capturePaymentFailureState',
  marketingBulkUploadLeads: '/marketing/upload-leads',
  adminBulkUploadLeads: '/admin/upload-leads',
  salesBulkUploadLeads: '/sales/upload-leads',
  downloadLeadTemplateAPI: 'downloadLeadTemplate',
  adminBulkUploadLeadsList: 'BulkUploadLeadsSheet.xlsx',
  uploadBulkLeadsAPI: 'importBulkLeads',
  partnerSuccessNumberTracking: '/partner-success/number-tracking',
  partnerSuccessDashboardPath: '/partner-success/dashboard',
  partnerSuccessBulkEnrolPath: '/partner-success/bulk-enrol',
  partnerSuccessBulkSignupPath: '/partner-success/bulk-signup',
  partnerSuccessBulkUploadLeads: '/partner-success/upload-leads',
  partnerSuccessManageLeadsRoute: '/partner-success/manage-leads',
  partnerSuccessManageExpertPath: '/partner-success/manage-onboarding',
  pageReloadWarningMessage:
    'Warning! your enrolment is in progress. Do you want to close this window?',
  quizLisitngPageRoute: '/quizzes',
  quizzesText: 'quizzes',
  quizPageName: 'Quiz-listing-page',
  quizThumbnailImage: 'quiz-thumbnail.png',
  qrenciaApplicationIcons: {
    competitionIcon: 'competition-icon.png',
    competitionIconWhite: 'competition-icon-white.png',
    workshopIcon: 'workshop-icon.png',
    workshopIconWhite: 'workshop-icon-white.png',
    courseIcon: 'course-icon.png',
    courseIconWhite: 'course-icon-white.png',
    nudgeIcon: 'nudge-icon.png',
    nudgeIconWhite: 'nudge-icon-white.png',
    brandingPageIconWhite: 'branding-page-icon-white.png',
    offeringsIconWhite: 'offerings-icon-white.png',
  },
  competitionPageName: 'LA-listing-competitions-page',
  coursesPageName: 'LA-listing-courses-page',
  workshopsPageName: 'LA-listing-workshops-page',
  iconImagesS3Path: 'icon-images',
  shareCourseFeedbackPath: '/course/share-feedback',
  shareFeedbackForSpecificCoursePath: '/course/share-feedback/:courseUniqueId',
  fetchEnrolledLearnerRecordsAPI: 'getParticipantRecordsForLA',
  submitFeedbackAPI: 'captureFeedback',
  getCourseFeedbackForLearnerAPI: 'getCourseFeedbackForLearner',
  actionModes: {
    view: 'view',
    create: 'create',
    edit: 'edit',
    delete: 'delete',
  },
  editFeedbackConfirmation:
    'This feedback was brodcasted to all the learners. Any changes made in the message will be reflected to all the learners. Do you want to continue?',
  deleteFeedbackConfirmation: 'Are you sure you want to delete this feedback?',
  welcomeExpertPageRoute: '/welcome-expert',
  expertWelcomePageName: 'Expert-welcome-page',
  welcomeExpertPageBannerText: 'You have been onboarded on Qrencia',
  salesNumberTrackingPath: '/sales/number-tracking',
  courseReviewCount: 3,
  getQrenciaInsightsAPI: 'getQrenciaInsights',
  countryAbbreviationIndia: 'IN',
  learnerCompleteProfileStaticTexts: {
    welcomeHeading: 'Before you start learning,',
    welcomeDescription:
      ' let us know more about your interests for a personalised experience on Qrencia',
    finishHeading: 'Finishing up...',
    finishDescription: 'your personalised content is getting ready',
    updateCompleteDescription:
      '{{firstName}}, your personalised content is ready! Your learning journey on Qrencia begins now..',
  },
  deepLinkWhatsapp: 'whatsapp://send?text=',
  studentProfileS3Path: 'student-profile',
  studentProfileImageUpload: 'upload-image-file.jpg',
  studentProfileManAvatar: 'man-avatar.png',
  studentProfileWonamAvatar: 'woman-avatar.png',
  laDropdownValues: 'Learning-Activities',
  profilePictureDefault: 'userProfilePicture.png',
  studentProfileManDefaultAvatar: 'man-avatar',
  studentProfileWonamDefaultAvatar: 'woman-avatar',
  nudgeResponseTime: 'Raise_Nudge_Success_Message_Response_Time',
  micrositeTopNavigationSections: {
    courses: 'Popular Courses',
    competitions: 'Upcoming Competitions',
    workshops: 'Interesting Workshops',
    reviews: 'Reviews from Learners',
  },
  creatorRespondNudgePage: '/respond-to-nudges',
  creatorRespondSpecificNudgePage: '/respond-to-nudges/:nudgeUniqeId',
  checkUserEligibilityForNudgesAPI: 'checkUserEligibilityToResponseNudge',
  getAssignedCreatorsForNudgeIdAPI: 'getAssignedCreatorsForNudgeId',
  expertNudgeWebBannerImage: 'expert-nudge-web-banner.png',
  expertNudgeTabBannerImage: 'expert-nudge-tab-banner.png',
  expertNudgeMobileBannerImage: 'expert-nudge-mobile-banner.png',
  agencyAssignCoursesPath: '/agency/assign-courses',
  getAssociatedExpertsWithCourseAssignedStatusAPI:
    'getAssociatedExpertsWithCourseAssignedStatus',
  expertAssignedCoursesPath: '/expert/assigned-courses',
  assingCoursesAPI: 'assign-unassign-courses',
  nudgeRaisedOnTimeFormat: 'hh:mm A, D MMM YYYY',
  signupMinimumAgeKey: 'Signup_Minimum_Age',
  gymnasticsSkillId: 'skill-31',
  keyboardUtilKeys: ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'],
  gymnasticsGenderValues: ['Male', 'Female', 'Both'],
  bothText: 'Both',
  gymnasticsJudgementLabels: {
    difficultyMaxScore: { label: 'Max D-Score', name: 'difficultyMaxScore' },
    levelWiseOutOf: { label: 'Out of Score', name: 'levelWiseOutOf' },
    shortExerciseMaxScore: {
      label: 'Max Short Exercise',
      name: 'shortExerciseMaxScore',
    },
    showPercentage: { label: 'Show Percentage', name: 'showPercentage' },
    gender: { label: 'Gender', name: 'gender' },
  },
  gymnasticsSkillName: 'Gymnastics',
  gymnasticsMedals: {
    gold: {
      label: 'Gold Medal',
      name: 'gold',
      medalName: 'Gold',
      medalType: 'Gold Medal',
    },
    silver: {
      label: 'Silver Medal',
      name: 'silver',
      medalName: 'Silver',
      medalType: 'Silver Medal',
    },
    bronze: {
      label: 'Bronze Medal',
      name: 'bronze',
      medalName: 'Bronze',
      medalType: 'Bronze Medal',
    },
  },
  moreThanEqualsText: 'More than equals',
  gymnasticsStaticAspects: [
    'D-Score',
    'D Score',
    'Penalty/Other Deductions',
    'Penalty',
  ],
  gymnasticsStatisAspectMapping: {
    penalty: 'Penalty/Other Deductions',
    dScore: 'D-Score',
  },
  scoreboardText: 'scoreboard',
  postStudentMarksForGymnasticsCompetitionAPI:
    'postStudentMarksForGymnasticsCompetition',
  competitionScoreboardRoute: '/scoreboard/:competitionSlug',
  scoreboardTableHeaders: ['Id', 'Name', 'Final Score', 'Percentage', 'Medal'],
  scoreboardAPI: 'scoreboard',
  maxEScoreKeyName: 'Max_E_Score',
  gymnasticsAwardType: {
    medalBased: 'Medal Based',
    rankBased: 'Rank Based',
  },
  gymnasticsAwardGeneralInfo: [
    '1. In Medal-Based awards, participants receive medals based on the configured criteria percentage.',
    '2. In Rank-Based awards, participants are ranked based on their final marks in their respective genders.',
  ],
  gymnasticsTeamMarkingAwardInfo: [
    '1. In Medal-Based awards, teams receive medals based on the configured criteria percentage.',
    '2. In Rank-Based awards, teams are ranked based on the final marks.',
  ],
  scoreboardTrophyIconImage: 'Scoreboard-Trophy-Icon.png',
  scoreboardPageFooterMessage:
    'NOTE: The organizing body has the sole discretion to validate and finalize the results. All decisions made by the organizers are absolute and the scores and rankings are non-challengeable.',
  fetchGymnasticsChampionshipAPI: 'gymnastics-competition-championship-info',
  getRecommendedAIGeneratedInformationAPI:
    'getRecommendedAIGeneratedInformation',
  richTextEditorAutoFillDelay: 5,
  teamEnrolmentsFormLables: {
    markingType: { name: 'markingType', label: 'Marking Type' },
    markingParameter: { name: 'markingParameter', label: 'Team Final Score' },
    teamParticipantsLimit: {
      name: 'teamParticipantsLimit',
      label: 'Team Size',
    },
    teamsLimit: { name: 'teamsLimit', label: 'Number of Teams Allowed' },
  },
  markingTypes: {
    team: { label: 'Team Wise', value: 'Team' },
    individual: { label: 'Participant Wise', value: 'Individual' },
  },
  markingParameters: {
    sum_of_participant_marks: {
      value: 'Sum of all participants',
      key: 'sum_of_participant_marks',
    },
    average_of_participant_marks: {
      value: 'Average of all participants',
      key: 'average_of_participant_marks',
    },
  },
  team: '/team',
  teamMyLearningPath: '/team/past-enrolments',
  teamHomePath: '/team/home',
  initiateOrderForTeamEnrolmentAPI: 'initiateOrderForTeamEnrolment',
  enrolTeamInCompetitionAPI: 'enrolTeamInCompetition',
  getTeamCompetitionParticipantsAPI:
    'getTeamCompetitionParticipants?competitionUniqueId=',
  teamEnrolmentFormButtons: {
    enrol: 'Enrol',
    payOffline: 'Pay Offline',
  },
  whatsAppChatbotProps: {
    phoneNumber: '+919650106514',
    message: 'Hi, I want to know more!',
    position: 'right',
    companyName: 'Qrencia',
    subtitle: 'Connecting People with their Passion',
    brandLogo:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7XpU0EuIfgk582bm6tgw6S1GZqqMH_7p7ww&s',
    welcomeMessage: 'Welcome to Qrencia!',
    actionBtnText: 'Start Chat',
    themeColor: '#214a7e',
    buttonText: '',
  },
  downloadZipApiParams: {
    team: 'Team',
    participant: 'Participant',
  },
  generateCertificateZipForTeamsAPI: 'generateCertificateZipForTeams',
  getTeamParticipantsInCompetitionAPI: 'getTeamParticipantsInCompetition',
  fetchTeamParticipantsOfflinePaymentRequestAPI:
    'fetchTeamParticipantsOfflinePaymentRequest',
  raiseOfflineEnrolmentRequestForTeamAPI: 'raiseOfflineEnrolmentRequestForTeam',
  getLearnerDetailForQrenciaIdAPI: 'getLearnerDetailForQrenciaId',
  getSelectedTeamStudentsMarksAPI: 'getTeamStudentResultForCompetition',
  getDownloadStudentResultSummarySheet: 'downloadStudentResultSummarySheet',
  schoolText: 'School',
  checkTeamEnrolmentEligibilityAPI: 'checkTeamEnrolmentEligibility',
  gymnasticShowPercentageInfoMessage:
    "By selecting 'Yes', the percentage will be calculated and displayed; 'No' will prevent the calculation and display.",
  gymnasticOutOffScoreInfoMessage:
    "Please enter the 'Out of Score' for each criterion, which will be used to calculate the percentage.",
  downloadParticipantTeamCompetitionCertificateAPI:
    'downloadTeamCertificateForQrenciaUsers',
  eoiFirstNameMinLength: 3,
  eoiLastNameMinLength: 3,
  eoiAgencyFirstNameMinLength: 3,
  eoiAgencyLastNameMinLength: 3,
  eoiAgencyAcademyNameMinLength: 3,
  qrenciaPackageSubscriptionStatus: {
    active: 'Active',
    expired: 'Expired',
    notPurchased: 'Not Purchased',
  },
  paymentCallbackRoute: '/payment-callback',
  verifyRazorpayPaymentStatusEOIPage: ' verifyUserPaymentsEOISubscription',
  maximizeYourProfits: ' Maximize your profits',
  getStartedWithPassionPlus: ' Growth Partnership',
  watchHowQrenciaBenefitedExperts: 'Watch how Qrencia benefited experts',
  readyToJoin: ' Ready to join?',
  haveDoubtsAndNeedHelp: ' Have doubts and need help?',
  haveDoubtsAndNeedHelp: 'Have doubts and need help?',
  helpDescription:
    'Check out our Frequently Asked Questions for answers. If your doubts persist, we are a call away! ',
  pricingDescription: 'Join the skills marketplace that maximizes your profits',
  helpContact: '+91 9650106514',
  pricePlan1: '500/month',
  marketplaceMembership: 'Marketplace Membership',
  billedAnnualy: 'Billed annually with ',
  refundPolicy: '100% refund policy',
  pricePlan2: '15%',
  platformFee: 'Platform Fee',
  serviceProvide: 'On all services offered through Qrencia',
  watchHowQrenciaBenefitedExperts: 'Watch how Qrencia benefited experts',
  readyToJoin: 'Ready to join?',
  trustedByGlobalEducators: ' Trusted by global educators',
  qrenciaPassionSkillsPrivateLimited: ' Qrencia Passion Skills Private Limited',
  qrenciaAddress:
    ' 18th Floor, TOWER-B, Alphathum, Sector 90, Noida, Uttar Pradesh 201305',
  studentPass: ' Introducing Learner Pass',
  studentPassDescription:
    ' Elevate learning experience of your students with Learner Pass',
  studentPassPricing: 'Sell at',
  studentPassButton: 'Keep 100% of Earning',
  studentPassImage: 'Expression-of-Interest/Student-Pass-Image-2.png',
  studentPassImage2:
    'Expression-of-Interest/Learner-Pass-Section-Mobile-Banner.png',
  toYourStudents: 'per month to your students',
  popUpImage: 'Expression-of-Interest/Get-Started-Now2.png',
  // subscriptionModel: 'Expression-of-Interest/Subscription-Model.png',
  membershipFee: 'Expression-of-Interest/MembershipFee.png',
  learnerPassFee: 'Expression-of-Interest/Learner-Pass-Fee.png',
  wanttoGrowYourBusiness: 'Want to Grow Your Business?',
  popUpDescription: 'Maximize Your Profits with Qrencia',
  tiredOfPayingPlatformFee: ' Tired of Paying Platform Fee?',
  partnerOffer: ' Offer the',
  partnerLearnerPass: ' Learner Pass ',
  partnerOfferStudent: ' to 75% of your students',
  partnerToGet: ' and get your',
  partnerPlatformFee: ' platform fee waived-off',
  partnerBackgroundURL:
    'https://qrencia-b2c-application-bucket-dev.s3.ap-south-1.amazonaws.com/Expression-of-Interest/Partnership-Image.jpg',

  brandBuildingData: [
    {
      pageHeading: 'Build a Powerful Brand',
      sectionBackgroundColor: '#f7ead4',
      brandBuildingContent: [
        {
          brandId: 1,
          brandImg: 'Expression-of-Interest/Business-Profile.png',
          brandHeading: 'Business Profile',
          brandDesciption:
            'Build a dynamic profile that reflects your unique expertise and offerings',
          brandColor: '#efb04e',
        },
        {
          brandId: 2,
          brandImg: 'Expression-of-Interest/Reputation-Management.png',
          brandHeading: ' Reputation Management',
          brandDesciption:
            ' Collect and display ratings and reviews to boost your credibility',
          brandColor: '#efb04e',
        },
        {
          brandId: 3,
          brandImg: 'Expression-of-Interest/Social-Currency.png',
          brandHeading: ' Social Currency',
          brandDesciption:
            ' Aggregate all social links to strengthen your online presence and build trust',
          brandColor: '#efb04e',
        },
        {
          brandId: 4,
          brandImg: 'Expression-of-Interest/Marketplace-Discoverability.png',
          brandHeading: ' Marketplace Discoverability',
          brandDesciption:
            'Get discovered easily by learners searching for your skills on Qrencia',
          brandColor: '#efb04e',
        },
      ],
      brandImage: 'Expression-of-Interest/EOI-BuildBrand.png',
    },
    {
      pageHeading: 'Digitize Operations to Scale',
      sectionBackgroundColor: '#c0e5e7',
      brandBuildingContent: [
        {
          brandId: 1,
          brandImg: 'Expression-of-Interest/Fee-Management.png',
          brandHeading: 'Fee Management',
          brandDesciption:
            'Automate invoicing, track offline/online payments, collect fee globally',
          brandColor: '#34b5b3',
        },
        {
          brandId: 2,
          brandImg: 'Expression-of-Interest/Attendance-Management.png',
          brandHeading: 'Attendance Management',
          brandDesciption:
            'Advanced tools to monitor student attendance effortlessly',
          brandColor: '#34b5b3',
        },
        {
          brandId: 3,
          brandImg: 'Expression-of-Interest/Multichannel-Communication.png',
          brandHeading: 'Multichannel Communication',
          brandDesciption:
            'Share learning resources; Send updates, reminders via WhatsApp & Email',
          brandColor: '#34b5b3',
        },
        {
          brandId: 4,
          brandImg: 'Expression-of-Interest/Reporting-System.png',
          brandHeading: 'Reporting System',
          brandDesciption:
            'Analyze and download multiple business reports to ease record keeping',
          brandColor: '#34b5b3',
        },
      ],
      brandImage: 'Expression-of-Interest/EOI-SimplifyFinancialManagement.png',
    },
    {
      pageHeading: 'Amplify Reach with Smart Marketing',
      sectionBackgroundColor: '#efcdd2',
      brandBuildingContent: [
        {
          brandId: 1,
          brandImg: 'Expression-of-Interest/Sales-Page.png',
          brandHeading: 'Sales Page',
          brandDesciption:
            'Generate sales pages for all your offerings with AI-powered content',
          brandColor: '#e77a7b',
        },
        {
          brandId: 2,

          brandImg: 'Expression-of-Interest/SEO-Promotions.png',
          brandHeading: 'SEO & Promotions',
          brandDesciption:
            'On/Off-page SEO to enhance discoverability; Promote via Email & WhatsApp',
          brandColor: '#e77a7b',
        },
        {
          brandId: 3,

          brandImg: 'Expression-of-Interest/Discounts-Referrals.png',
          brandHeading: ' Discounts & Referrals',
          brandDesciption:
            'Create exclusive offers and referral benefits to attract and retain students',
          brandColor: '#e77a7b',
        },
        {
          brandId: 4,
          brandImg: 'Expression-of-Interest/Traffic-Analytics.png',
          brandHeading: 'Traffic Analytics',
          brandDesciption:
            'Analyze traffic and user behavior on sales pages; Address lead queries',
          brandColor: '#e77a7b',
        },
      ],
      brandImage: 'Expression-of-Interest/EOI-AutomateMarketing.png',
    },
    {
      pageHeading: 'Accelerate Sales to Drive Growth',
      sectionBackgroundColor: '#c0e5e7',
      brandBuildingContent: [
        {
          brandId: 1,
          brandImg: 'Expression-of-Interest/AI-Based-Targeting.png',
          brandHeading: 'AI-Based Targeting',
          brandDesciption:
            'Automatically identify ideal customers to show tailored ads with smart pricing',
          brandColor: '#40c6e6',
        },
        {
          brandId: 2,
          brandImg: 'Expression-of-Interest/Achievement-Alerts.png',
          brandHeading: 'Achievement Alerts',
          brandDesciption:
            'Word-of-mouth marketing of achievements using AI sales engine',
          brandColor: '#40c6e6',
        },
        {
          brandId: 3,
          brandImg: 'Expression-of-Interest/Diversify-Business.png',
          brandHeading: 'Diversify Business',
          brandDesciption:
            'Offer new types of activities including competitions, workshops, quizzes & more',
          brandColor: '#40c6e6',
        },
        {
          brandId: 4,
          brandImg: 'Expression-of-Interest/Engage-Leads.png',
          brandHeading: 'Engage Leads',
          brandDesciption:
            'Showcase your expertise to hot leads through Nudges and promote offerings',
          brandColor: '#40c6e6',
        },
      ],
      brandImage: 'Expression-of-Interest/EOI-StreamlineSalesProcesses.png',
    },
    {
      pageHeading: 'Provide Personalized Learning Experience',
      sectionBackgroundColor: '#dfd4f5',
      brandBuildingContent: [
        {
          brandId: 1,
          brandImg: 'Expression-of-Interest/Student-Profiles.png',
          brandHeading: 'Student Profiles',
          brandDesciption:
            'Platform for students to track and maintain a record of their learning',
          brandColor: '#7d6fea',
        },
        {
          brandId: 2,
          brandImg: 'Expression-of-Interest/Personalized-Feedback.png',
          brandHeading: 'Personalized Feedback',
          brandDesciption:
            'Allow students to seek feedback and reduce time to develop skills',
          brandColor: '#7d6fea',
        },
        {
          brandId: 3,

          brandImg: 'Expression-of-Interest/Gamification.png',
          brandHeading: 'Gamification',
          brandDesciption:
            'Boost engagement through loyalty points, quizzes, games, & daily challenges',
          brandColor: '#7d6fea',
        },
        {
          brandId: 4,

          brandImg: 'Expression-of-Interest/Digital-Resources.png',
          brandHeading: 'Digital Resources',
          brandDesciption:
            'Offer learning resources including video lessons, notes, and certificates',
          brandColor: '#7d6fea',
        },
      ],
      brandImage: 'Expression-of-Interest/EOI-Hyper-PersonalizeExperience.png',
    },
  ],

  // {
  //     pageHeading: "Automate Communication",
  //     sectionBackgroundColor: "#efcdd2",
  //     brandBuildingContent: [
  //         {
  //             brandId: 1,
  //             brandImg: <AiOutlineLaptop />,
  //             brandHeading: "MultiChannel Communication",
  //             brandDesciption: "Automate communication via email, WhatsApp, and SMS to save time",
  //             brandColor: "var(--qrenica-course-differentiator-color)"
  //         },
  //         {
  //             brandId: 2,
  //             brandImg: <TbMessageHeart />,
  //             brandHeading: " Student Broadcast",
  //             brandDesciption: " Send announcements and updates to your students",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //         {
  //             brandId: 3,
  //             brandImg: <BiLike />,
  //             brandHeading: " Fees Reminders",
  //             brandDesciption: " Send automated reminders to students for timely payments",
  //             brandColor: "var(--qrencia-workshop-differentiator-color)"

  //         },
  //         {
  //             brandId: 4,
  //             brandImg: <RiUserSearchFill />,
  //             brandHeading: " Digital Certificates",
  //             brandDesciption: "Generate and distribute branded professional certificates with ease",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //     ],
  //     brandImage: "Expression-of-Interest/EOI-AutomateCommunication.png"
  // },
  // {
  //     pageHeading: "Enhance Student Engagement",
  //     sectionBackgroundColor: "#f7ead4",
  //     brandBuildingContent: [
  //         {
  //             brandId: 1,
  //             brandImg: <AiOutlineLaptop />,
  //             brandHeading: "Discounts",
  //             brandDesciption: "Create exciting offers for your target audience",
  //             brandColor: "var(--qrenica-course-differentiator-color)"
  //         },
  //         {
  //             brandId: 2,
  //             brandImg: <TbMessageHeart />,
  //             brandHeading: " Feedback",
  //             brandDesciption: " Share individualized feedback to enhance student growth",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //         {
  //             brandId: 3,
  //             brandImg: <BiLike />,
  //             brandHeading: " Nudge",
  //             brandDesciption: " Provide students a channel to seek your feedback",
  //             brandColor: "var(--qrencia-workshop-differentiator-color)"

  //         },
  //         {
  //             brandId: 4,
  //             brandImg: <RiUserSearchFill />,
  //             brandHeading: "Gamification",
  //             brandDesciption: "Introduce quizes and games to boost engagement",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //     ],
  //     brandImage: "Expression-of-Interest/EOI-EnhanceStudentEngagement.png"
  // },
  // {
  //     pageHeading: "Hyper-Personalize Experience",
  //     sectionBackgroundColor: "#dfd4f5",
  //     brandBuildingContent: [
  //         {
  //             brandId: 1,
  //             brandImg: <AiOutlineLaptop />,
  //             brandHeading: "Student Profile",
  //             brandDesciption: "Platform for students to track and maintain a record of their learning",
  //             brandColor: "var(--qrenica-course-differentiator-color)"
  //         },
  //         {
  //             brandId: 2,
  //             brandImg: <TbMessageHeart />,
  //             brandHeading: " Interest-Based Recommendations",
  //             brandDesciption: " Automatically suggest offerings based on each student’s unique interests",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //         {
  //             brandId: 3,
  //             brandImg: <BiLike />,
  //             brandHeading: " Student-Teacher Communication",
  //             brandDesciption: " Communicate with students on a personal level through tailored messages",
  //             brandColor: "var(--qrencia-workshop-differentiator-color)"

  //         },
  //         {
  //             brandId: 4,
  //             brandImg: <RiUserSearchFill />,
  //             brandHeading: " Student Engagement Analytics",
  //             brandDesciption: "Track participation and performance to refine teaching strategies",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //     ],
  //     brandImage: "Expression-of-Interest/EOI-Hyper-PersonalizeExperience.png"
  // },
  // {
  //     pageHeading: "Boost Conversions",
  //     sectionBackgroundColor: "#f7ead4",
  //     brandBuildingContent: [
  //         {
  //             brandId: 1,
  //             brandImg: <AiOutlineLaptop />,
  //             brandHeading: "AI-Driven Discounts",
  //             brandDesciption: "Share Referral Benefits & Discounts with Hot Leads using AI",
  //             brandColor: "var(--qrenica-course-differentiator-color)"
  //         },
  //         {
  //             brandId: 2,
  //             brandImg: <TbMessageHeart />,
  //             brandHeading: " Smart Pricing Insights",
  //             brandDesciption: " Get demand driven recommendations on pricing and content",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //         {
  //             brandId: 3,
  //             brandImg: <BiLike />,
  //             brandHeading: " Achievement Alerts",
  //             brandDesciption: " Set up automated achievement notifications to learners",
  //             brandColor: "var(--qrencia-workshop-differentiator-color)"

  //         },
  //         {
  //             brandId: 4,
  //             brandImg: <RiUserSearchFill />,
  //             brandHeading: " Engage Leads",
  //             brandDesciption: "Showcase your expertise to hot leads through Nudges and promote offerings",
  //             brandColor: "var(--qrencia-blue-background)"
  //         },
  //     ],
  //     brandImage: "Expression-of-Interest/EOI-BoostConversions.png"
  // },

  AboutUs: [
    {
      question: ' What is Qrencia?',
      answer:
        ' Qrencia is an organized marketplace of 70+ skill communities, where creators like you can offer diverse learning activities to help people develop skills quickly. It empowers creators to enhance online visibility, digitize business operations, and personalize the learning experience with AI. Qrencia also enables you to introduce rewards and gamification, automate marketing and sales, and expand your offerings, making it a comprehensive platform for scaling your passion-driven business',
    },
    {
      question: ' Who can use Qrencia?',
      answer:
        ' Qrencia is for anyone passionate about sharing their skills. It is designed for creators such as skill experts, coaches, trainers, and academies, providing a growth-focused ecosystem to turn their passions into scalable, profitable, and sustainable businesses',
    },
    {
      question: ' How much does Qrencia charge?',
      answer:
        ' Qrencia is a no commission platform and charges a marketplace membership fee of INR 999/year (with a 100% refund policy) from experts to use all features. As partners, Qrencia expects them to recommend the learner pass to all their students.',
    },
    {
      question: ' How will I get paid?',
      answer:
        ' All payouts are processed as per the agreed subscription terms after deducting applicable taxes. As an expert, you retain full ownership of your content, and Qrencia acts only as a facilitator to collect funds on your behalf. Payments are then securely transferred directly to your account, ensuring a hassle-free and transparent process',
    },

    {
      question: ' Is there any hidden fee?',
      answer: ' No, there are no hidden charges',
    },
    {
      question: ' Is there a demo available?',
      answer:
        ' Yes, Qrencia provides a live demo session to showcase the platform and its features, helping you explore how it can meet your needs. ',
      LinkText: ' Click here',
      answerContinue: ' to schedule your personalized demo ',
    },
  ],

  profitCards: [
    {
      cardId: 1,
      eoiCardSectionImage: 'Expression-of-Interest/EOI-Build-Brand.png',
      cardHeading: 'Build Brand',
      cardDesc:
        ' Establish a business profile to effectively communicate your brand, offerings, and reputation to your ideal customers',
    },
    {
      cardId: 2,
      eoiCardSectionImage: 'Expression-of-Interest/EOI-Save-Time.png',
      cardHeading: 'Save Time',
      cardDesc:
        '  Scale your business by using AI-enabled tools to automate your day-to-day business operations',
    },
    {
      cardId: 3,
      eoiCardSectionImage: 'Expression-of-Interest/EOI-Earn-More.png',
      cardHeading: 'Earn More',
      cardDesc:
        ' Offer variety of learning activities to boost engagement & sales; retain students by personalizing learning',
    },
  ],

  logoImages: [
    {
      logoId: 1,
      logoImage: 'Expression-of-Interest/EOI-logo1.png',
    },
    {
      logoId: 2,
      logoImage: 'Expression-of-Interest/EOI-logo2.png',
    },
    {
      logoId: 3,
      logoImage: 'Expression-of-Interest/EOI-logo3.png',
    },
    {
      logoId: 4,
      logoImage: 'Expression-of-Interest/EOI-logo4.png',
    },
    {
      logoId: 5,
      logoImage: 'Expression-of-Interest/EOI-logo5.png',
    },
    {
      logoId: 6,
      logoImage: 'Expression-of-Interest/EOI-logo6.png',
    },
    {
      logoId: 7,
      logoImage: 'Expression-of-Interest/EOI-logo7.png',
    },
    {
      logoId: 8,
      logoImage: 'Expression-of-Interest/EOI-logo8.png',
    },
    {
      logoId: 9,
      logoImage: 'Expression-of-Interest/EOI-logo9.png',
    },
    {
      logoId: 10,
      logoImage: 'Expression-of-Interest/EOI-logo10.png',
    },
    {
      logoId: 11,
      logoImage: 'Expression-of-Interest/EOI-logo11.png',
    },
    {
      logoId: 12,
      logoImage: 'Expression-of-Interest/EOI-logo12.png',
    },
    {
      logoId: 13,
      logoImage: 'Expression-of-Interest/EOI-logo13.png',
    },
    {
      logoId: 14,
      logoImage: 'Expression-of-Interest/EOI-logo14.png',
    },
    {
      logoId: 15,
      logoImage: 'Expression-of-Interest/EOI-logo15.png',
    },
    {
      logoId: 16,
      logoImage: 'Expression-of-Interest/EOI-logo16.png',
    },
    {
      logoId: 17,
      logoImage: 'Expression-of-Interest/EOI-logo17.png',
    },
  ],

  externalLinks: [
    {
      id: 1,
      title: ' Resources',
      subTitle: ' About Us',
      description: ' Brochure',
    },
    {
      id: 2,
      title: 'What It Provides?',
      subTitle: ' Skills on Qrencia',
      description: ' Experts on Qrencia',
    },
    {
      id: 3,
      title: ' How Qrencia Works?',
      subTitle: null,
      description: null,
    },
    {
      id: 4,
      title: ' Contact',
      subTitle: '+91 9650106514',
      description: 'support@qrencia.com',
    },
  ],
  qrenciaPackageSubscriptionStatus: {
    active: 'Active',
    expired: 'Expired',
    notPurchased: 'Not Purchased',
  },
  paymentCallbackRoute: '/payment-callback',
  verifyRazorpayPaymentStatusEOIPage: 'verifyUserPaymentsEOISubscription',
  verifyRazorpayPaymentStatusEOIPage: 'verifyUserPaymentsEOISubscription',
  supportEmailAddress: 'support@qrencia.com',
  eoiFormBusinessType: [
    {
      value: 'Expert',
      display: 'I am an Individual Expert',
    },
    {
      value: 'Academy',
      display: 'I run an Academy or an Institute',
    },
  ],
  getCreatorMembershipAPI: 'getCreatorMemberships',
  sendPaymentLinkEmailAPI: 'sendPaymentLinkEmail',
  sendFirstMembershipPaymentReminderAPI: 'sendFirstMembershipPaymentReminder',
  sendIncreaseLearnerPassSubscriptionReminderEmailAPI:
    'sendLearnerPassReminder',
  sendRenewSubscriptionReminderEmailAPI: 'sendMembershipRenewalReminders',
  learnerPassPercentageThreshold: 75,

  leadPaymentStatus: {
    Pending: 'Pending',
    Success: 'Success',
    Failed: 'Failed',
  },
  subscriptionPlanFailsafePaymentLink:
    'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  getMembershipStatusAPI: 'getMembershipStatus',
  notScheduledText: 'Not Scheduled',
  aboutLearnerPassImage: 'banner-images/aboutLearnerPassImage.png',
  learnerRecommendationsPath: '/recommendations',
  getLearnerDetailsForQrenciaIdAPI:
    'getLearnerDetailForQrenciaId?userQrenciaId=',
  learner: 'learner',
  recommendLearnerAPI: 'recommendLearner',
  fetchRecommendationsForLearner:
    'getCreatorRecommendationsForLearner?userQrenciaId=',
  deleteRecommendationConfirmation:
    'Are you sure that you want to delete this recommendation?',
  learnerProfileRoute: 'learner/:learnerSlug',
  getLearnerProfileDetailsAPI: 'learnerDetails/',
  fetchRecommendationsForLearnerProfile:
    'creatorRecommendationsForLearnerProfilePage',
  ExpertsRecommendations: 'Experts’ Recommendations',
  learnerText: 'learner',
  learnerProfileDefaultBanner: 'learner-profile-default-banner.png',
  updateLearnerProfileApi: 'updateLearnerProfile',
  fetchLearnerAchievements: 'getLearnerActivities',
  LearnerProfileHeadingConstants: {
    intoductionHeading: 'Introduction',
    skillsProwessHeading: 'Skills Prowess',
    albumHeading: 'My Album',
    submissionHeading: 'My Submission',
  },
  goldMedalImagePath: 'gold.png',
  silverMedalImagePath: 'silver.png',
  bronzeMedalImagePath: 'bronze.png',
  nonRankMedalImagePath: 'nonRankMedal.png',
  mobileHomePageAdvertisementImage:
    'Expression-of-Interest/mobile-home-page-advertisement-image.jpg',
  advertismentPath: 'advertisment',
  advertismentLearnerProfilePath: 'achievementsAd.png',
  recentActivitiesSectionAdImagePath: 'recentActivitiesSectionAd.png',
  thumbnailSizeDimension:
    '<b>Desired dimension:</b><br> 308px wide and 216px high',
  desiredDimension: '<b>Desired dimension:</b><br> 460px wide and 400px high',
  image500MBSizeLimit: 500000000,
  allowedAudioFormats: ['audio/mpeg', 'audio/mp3', 'audio/wav'],
  allowedZipFormats: ['application/zip', 'application/x-zip-compressed'],
  myAlbumFileInputId: 'myAlbumFileInput',
  myAlbumImageFolder: '/myAlbumImages/',
  learnerDetailsFolder: 'learnerDetails',
  updateText: 'Update',
  createText: 'Create',
  createAssessmentFormLabels: {
    selectTemplate: { label: 'Select Template', name: 'selectedTemplate' },
    assessmentDate: { label: 'Assessment Date', name: 'assessmentDate' },
    skill: { label: 'Skill', name: 'skillId' },
    assessmentDescription: {
      label: 'Assessment Description',
      name: 'assessmentDescription',
    },
    assessmentType: { label: 'Assessment Type', name: 'assessmentType' },
    markingType: { label: 'Marking Type', name: 'markingType' },
  },
};

export const SUBSCRIPTIONS_PLAN = {
  originalPrice: 499,
  discountedPrice: 500,
  originalYearlyPrice: 5999,
  discountedYearlyPrice: 4999,
  featureList: [
    { text: 'Custom Business Profile' },
    { text: 'AI-Generated Content Creation ✨' },
    { text: 'Personalized Student Profile' },
    { text: 'AI-Powered Sales Engine ✨' },
    { text: 'Offline Fee Management' },
    { text: 'Attendance Management' },
  ],
};

export const SUBSCRIPTIONS_PLAN_PAYMENT = {
  learnerWelcomeScreenPopupIllustrationPath:
    'LearnerOnboarding/LearnerOnboardinglearnerWelcomeScreenPopupIllustration_1.png',
};

export const LEARNER_PASS_PLANS = {
  originalPrice: 499,
  discountedPrice: 399,
  featureList: [
    { text: 'Skill Portfolio' },
    { text: 'AI Recommendations ✨' },
    { text: 'Discount on all Learning Activities' },
    { text: 'Monthly Rewards' },
    { text: 'Fun Quizzes and Games' },
    { text: 'Activity Dashboard' },
  ],
};

export const LEAD_STATUS_OPTIONS = [
  { label: 'New', color: '#FFA726' },
  { label: 'Not Interested', color: '#FF7043' },
  { label: 'Demo Scheduled', color: '#42A5F5' },
  { label: 'Demo Completed (High)', color: '#66BB6A' },
  { label: 'Demo Completed (Low)', color: '#8D6E63' },
  { label: 'Payment Link Sent', color: '#AB47BC' },
  { label: 'Closed', color: '#26A69A' },
  { label: 'Subscribed', color: '#FFCA28' },
];

export const LEAD_STATUS = {
  New: 'New',
  'Not Interested (Call 1)': 'Not Interested (Call 1)',
  'Demo Scheduled': 'Demo Scheduled',
  'Demo Completed (High)': 'Demo Completed (High)',
  'Demo Completed (Low)': 'Demo Completed (Low)',
  'Payment Link Sent': 'Payment Link Sent',
  'Not Interested & Closed': 'Not Interested & Closed',
  Subscribed: 'Subscribed',
};

export const LEAD_STATUS_MAP = [
  { label: 'New', value: 'New' },
  { label: 'Not Interested (Call 1)', value: 'Not Interested (Call 1)' },
  { label: 'Demo Scheduled', value: 'Demo Scheduled' },
  { label: 'Demo Completed (High)', value: 'Demo Completed (High)' },
  { label: 'Demo Completed (Low)', value: 'Demo Completed (Low)' },
  { label: 'Payment Link Sent', value: 'Payment Link Sent' },
  { label: 'Not Interested & Closed', value: 'Not Interested & Closed' },
  { label: 'Subscribed', value: 'Subscribed' },
];

export const LEARNER_PASS_DASHBOARD = {
  subscriptionStatuses: {
    active: 'Active',
    inactive: 'Inactive',
    expired: 'Expired',
  },
  learnerPassNotificationThreshold: 3,
  learnerPassVerifyReferralCodeAPI: 'verifyReferalCode',
  learnerPassCreateOrderAPI: 'createOrderForLearnerPass',
  learnerPassVerifyPaymentSignatureAPI: 'verifyLearnerPassPayments',
  learnerPassSubscriptionPlansAPI: 'getLearnerMemberships',
  learnerPassIconPath: 'LearnerPass/learnerPassAlt2.png',
  learnerPassIndicatorIconPath: 'LearnerPass/learnerPassButtonIcon.png',
};

export const MEMBERSHIP_TYPES = {
  learner: 'Learner Pass',
  creator: 'Creator Membership',
};

export const LEARNER_ONBOARDING = {
  learnerWelcomeScreenPopupIllustrationPath:
    'LearnerOnboarding/LearnerOnboardinglearnerWelcomeScreenPopupIllustration_1.png',
  learnerWelcomeScreenPopupIllustration2Path:
    'LearnerOnboarding/LearnerOnboardinglearnerWelcomeScreenPopupIllustration_2.png',
};

export const LEARNER_PASS_POPUP_IMAGES = {
  learnerPassAdImage:
    'LearnerPass/LearnerPassPopups/LearnerPassAdImage_1.5x.png',
  learnerPassExpiredImage:
    'LearnerPass/LearnerPassPopups/LearnerPassExpired_1.5x.png',
  learnerPassWelcomeImage:
    'LearnerPass/LearnerPassPopups/LearnerPassWelcome1.5x.png',
  learnerPassRenewReminderImage:
    'LearnerPass/LearnerPassPopups/LearnerPassRenewReminder1.5x.png',
};

export const LEARNER_PROFILE_DATE = {
  qrenciaInceptionDate: '2023-01-01',
};

export const NOTIFICATION_UI = {
  newFeatureFlagImagePath: 'LearnerProfile/new.png',
};

export const LEARNER_PROFILE = {
  lpAdNoAchievementNoRecentActivityCTALink: '/signup',
  learnerProfileRootPath: 'learner-profile',
  addSelfDeclaredRecommendations: 'saveLearnerDeclaredInformation',
  updateActiveFlagForLearnerDeclaredActivities:
    'updateActiveFlagForLearnerDeclaredActivities',
  approvedSealForQrenciaHostedLearningActivities:
    'learnerProfile/staticAssets/approved.png',
};

export const EXPERT_STUDENT_DETAILS = {
  expertStudentFeeDetailsPath:
    '/expert/subscription-summary/student-fee-details',
  expertStudentPIIPath: '/expert/subscription-summary/student-pii',
  paginationPerPageResultSize: 100,
  subscriptionSummaryParentRoute: 'expert/subscription-summary',
};
export const AGENCY_STUDENT_DETAILS = {
  agencyStudentFeeDetailsPath:
    '/agency/subscription-summary/student-fee-details',
  agencyStudentPIIPath: '/agency/subscription-summary/student-pii',
  paginationPerPageResultSize: 100,
  subscriptionSummaryParentRoute: 'agency/subscription-summary',
};
export const subscriptionSummaryDataAPI = 'getSubscriptionSummaryFeesView';
export const postSubscriptionSummaryRemarksAPI =
  'postSubscriptionSummaryRemarks';
export const subscriptionSummaryPiiDataAPI = 'getSubscriptionSummaryPiiView';
export const AWARDS_CONSTANTS = {
  getAllStudentsToAwardApi: 'get-students-to-award',
  postAwardedChampionshipApi: 'post-awarded-championships',
  getChampionshipsAwardedByExpertsApi: 'get-championships-awarded-by-expert',
  agencyAwardsRoute: 'agency/awards/',
  expertAwardsRoute: 'expert/awards/',
  agencyCODRoute: '/champion-of-the-day',
  expertCODRoute: '/champion-of-the-day',
  searchStudentName: 'Search Student by Name',
  searchCourseName: 'Search Course by Name',
  awardType: 'COD',
  getAwardsOfLearnerByExpertApi: 'get-awards-of-learner-by-expert',
  getAwardsOfLearnerApi: 'get-awards-of-learner',
  noAwards: 'COD:0',
  getCourseByStatusAndAssignedApi: 'getCourseByStatusAndAssigned?status=',
};
export const SKILL_PROGRESS_ASSESSMENT_CONSTANTS = {
  skillProgressCreateRoute: '/expert/skill-progress/create-template',
  skillProgressAssessmentRoute: '/expert/skill-progress/create-assessment',
  agencySkillProgressCreateRoute: '/agency/skill-progress/create-template',
  agencySkillProgressAssessmentRoute:
    '/agency/skill-progress/create-assessment',
  skillProgressViewAssessmentRoute: 'skill-progress/view-assessment',
  getSkillProgressTemplateApi: 'get-all-skill-progress-template',
  enbaleDisableSkillProgressTemplateApi:
    'enable-disable-skill-progress-template?uniqueId=',
  postSkillProgressTemplateApi: 'post-skill-progress-template',
  skillProgressionNavItemLabel: 'Skill Progression',
  skillProgressionsCreateNavItemLabel: 'Template',
  skillProgressionAssessmentNavItemLabel: 'Assessment',
  skillProgressionMarkStudentNavItemLabel: 'Edit Assessment',
  skillAssessmentReportPathApi: 'learnerSkillAssessmentReportDetails',
  skillAssessmentReportPath:
    'learner-assessment/:studentAssessmentMappingUniqueId',
  skillAssessmentReport: 'Skill Assessment Report',
  reportPresentedBy: 'Report Presented By',
  poweredByQrencia: 'Powered by Qrencia',
  createAssessmentFormLabels: {
    selectTemplate: { label: 'Select Template', name: 'selectedTemplate' },
    assessmentDate: { label: 'Assessment Date', name: 'assessmentDate' },
    skill: { label: 'Skill', name: 'skill' },
    assessmentDescription: {
      label: 'Assessment Description',
      name: 'assessmentDescription',
    },
    assessmentType: { label: 'Assessment Type', name: 'assessmentType' },
    markingType: { label: 'Marking Type', name: 'markingType' },
  },
  fetchTemplatesAPI: 'get-all-skill-progress-template',
  assessmentTypes: ['Regular', 'Trial'],
  markingTypes: ['Individual Marking', 'Group Marking'],
  postSkillProgressAssessmentApi: 'post-skill-progress-assessment',
  updateSkillProgressAssessmentApi: 'update-skill-progress-assessment',
  getCreatorAssessments: 'getCreatorAssessments',
  getLearnerAssessments: 'getLearnerAssessments',
  getAssessmentDetailsForUniqueId:
    'getAssessmentDetailsForUniqueId?assessmentUniqueId=',
  updateAssessmentReportVisibilityFlagApi:
    'updateAssessmentReportVisibilityFlag?uniqueId=',
  AndVisibilityFlag: '&visibilityFlag=',
  updateSkillProgressAPI: 'update-skill-progress-assessment',
};
