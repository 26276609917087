import React from 'react';
import classes from './LearnerPassProgressBar.module.css';
import { AppConstants } from '../../../constants/AppConstants';

const LearnerPassProgressBar = ({ percentage }) => {
  const getProgressBarColor = (percentage) => {
    if (percentage < 25) {
      return classes.low;
    } else if (percentage < AppConstants.learnerPassPercentageThreshold) {
      return classes.medium;
    } else {
      return classes.complete;
    }
  };

  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.progressBar}>
        <div
          className={`${classes.fill} ${getProgressBarColor(percentage)}`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className={classes.percentageText}>{percentage}%</span>
    </div>
  );
};

export default LearnerPassProgressBar;
