import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import classes from './CreateChampionshipTabs.module.css'; // Import the CSS file
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AppConstants, AWARDS_CONSTANTS } from '../../constants/AppConstants';
import { StudentsToBeAwardedCards } from '../../component/cards/StudentsToBeAwardedCards';
import { ViewAndEditAwardsGrids } from '../../component/grids/ViewAndEditAwardsGrids';

export const CreateChampionshipTabs = () => {
  const [key, setKey] = useState('create');
  const [ongoingCourseList, setOngoingCourseList] = useState([]);
  const [pastCourseList, setPastCourseList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [awardsByExperts, setAwardsByExperts] = useState([]);
  //List of students enrolled in all courses
  useEffect(() => {
    async function getAllStudentsTobeAwarded() {
      const url = AWARDS_CONSTANTS.getAllStudentsToAwardApi;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response) {
        setStudentList(response);
      }
    }
    getAllStudentsTobeAwarded();
  }, []);

  //get list of awards given by expert
  useEffect(() => {
    async function getAllAwardsOfExpert() {
      const url = AWARDS_CONSTANTS.getChampionshipsAwardedByExpertsApi;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response) {
        setAwardsByExperts(response);
      }
    }
    getAllAwardsOfExpert();
  }, []);

  useEffect(() => {
    //fetch ongoing  And Assigned Courses
    async function getOngoingCoursesData() {
      const url = `${AWARDS_CONSTANTS.getCourseByStatusAndAssignedApi}${AppConstants.courseStatus.ongoing}`;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response !== undefined) {
        setOngoingCourseList(response);
      } else {
        setOngoingCourseList([]);
      }
    }
    getOngoingCoursesData();
  }, []);

  useEffect(() => {
    //fetch Past Courses
    async function getPastCoursesData() {
      const url = `${AppConstants.fetchCoursesByStatusAPI}${AppConstants.courseStatus.past}`;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response !== undefined) {
        setPastCourseList(response);
      } else {
        setPastCourseList([]);
      }
    }
    getPastCoursesData();
  }, []);

  const updateAwardsByExperts = (newAwards, selectedDate) => {
    setAwardsByExperts((prevAwards) => {
      if (newAwards.length === 0) {
        return prevAwards.filter((award) => award.awardedOn !== selectedDate);
      } else {
        return [
          ...prevAwards.filter((award) => award.awardedOn !== selectedDate), // Remove old entries for the same date
          ...newAwards, // Add new awards
        ];
      }
    });
  };
  return (
    <div className={classes.awardsTab}>
      <Tabs
        defaultActiveKey="create"
        id="create"
        // justify={true}
        className="mb-3"
        transition={true}
      >
        <Tab eventKey="create" title="Create" c>
          <div className={classes.tabsContainer}>
            <StudentsToBeAwardedCards
              studentList={studentList}
              ongoingCourseList={ongoingCourseList}
              pastCourseList={pastCourseList}
              awardsByExpertsHistory={awardsByExperts}
              updateAwardsByExperts={updateAwardsByExperts}
            ></StudentsToBeAwardedCards>
          </div>
        </Tab>
        <Tab eventKey="view-and-edit" title="History">
          <div className={classes.tabsContainer}>
            <ViewAndEditAwardsGrids awardsByExperts={awardsByExperts} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};
