import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { CgPin } from "react-icons/cg";
import { Row, Col, Card, Button } from "react-bootstrap";
import classes from './IndividualSkillsCards.module.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { BsArrowDownCircle } from 'react-icons/bs';
import { AppConstants } from "../../../constants/AppConstants";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { DateTime } from "luxon";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import Auth from "../../../utils/Auth";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import moment from 'moment';
import { LoginModal } from "../../modal/LoginModal";
import { BiWallet } from "react-icons/bi";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { TbShare3 } from "react-icons/tb";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { DataService } from "../../../services/DataService";
import { StringUtils } from "../../../utils/StringUtils";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { MathUtil } from "../../../utils/MathUtil";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { ViewSelectedWeekDays } from "../../calendar/ViewSelectedWeekDays";

export const IndividualSkillsCourseCards = (props) => {
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.allActivitiesForSkill);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paramData, setParamData] = useState({});
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [courseId, setCourseId] = useState(AppConstants.emptyString);
    const [courseTitle, setCourseTitle] = useState(AppConstants.emptyString);
    const [courseUniqueId, setCourseUniqueId] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [editIndex, setEditIndex] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.zeroIndex);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const navigate = useNavigate();

    useEffect(() => {
        setCardDetails(props.allActivitiesForSkill);
    }, [props.allActivitiesForSkill]);

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, []);
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(3);
            setViewMoreCardsCount(3);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(2);
            setViewMoreCardsCount(2);
        }
        if (viewportWidth <= 575) {
            setCardCount(1);
            setViewMoreCardsCount(1);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        const verifyPayment = async (data, course) => {
            try {
                if (course && course?.uniqueId) {
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setModalTypeError(AppConstants.falseText);
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setTransactionId(response.transactionId);
                            window.history.pushState(null, null, window.location.href.split("?")[0]);
                            enrolInCourse(course.courseId, course.courseTitle, course.uniqueId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(AppConstants.trueText);
                            setMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            window.history.pushState(null, null, window.location.href.split("?")[0]);
                            handleModalShow();
                        }
                    }).catch((error) => {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(null, null, window.location.href.split("?")[0]);
                        handleModalShow();
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        if (paymentParam && cardDetails.length > 0) {
            const data = StringUtils.decodeBase64EncodedString(paymentParam);
            if (data !== null && data.category === AppConstants.learningActivitiesTypes.course) {
                const course = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!course) {
                    setParamData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                data.learningActivityName = course.courseTitle;
                setParamData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setCourseTitle(course.courseTitle);
                setCourseUniqueId(course.uniqueId);
                setCourseId(course.courseId);

                const timer = setTimeout(() => {
                    verifyPayment(data, course);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        };
    }, [paymentParam, cardDetails]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag, creatorName, creatorRole });

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            if (startTime === AppConstants.nullText) {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInCourse(id, title, uniqueId);
                }
            } else {
                const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
                const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const currentDateTime = DateTime.local().setZone(timeZone);
                // check if the learning activity has started.
                if (currentDateTime > startDateTime) {
                    // show confirmation modal to the user.
                    setShowConfirmationModal(AppConstants.trueText);
                } else {
                    if (payOfflineFlag === AppConstants.yText) {
                        setShowOfflinePaymentModal(AppConstants.trueText);
                    } else {
                        enrolInCourse(id, title, uniqueId);
                    }
                }
            }

        } else {
            enrolInCourse(id, title, uniqueId);
        }

    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCourse = async (courseId, courseTitle, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.enrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }
        const course = cardDetails.filter(card => card.uniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount });

        let response = await enrolInLearningActivity({
            uniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.courseType === AppConstants.flexibleCourseType && course.courseEnrolledOnDate !== null ? course.trackingType : AppConstants.falseText
        }, status)


        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }

    };


    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    let verificationResponse = await PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                    if (verificationResponse.verificationStatus===AppConstants.paymentStatus.verified){
                        enrolInCourse(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    }
                    else
                    {
                        setModalTypeError(true);
                        setPaymentStatus(AppConstants.emptyString);
                        setMessage(ErrorMessages.paymentDeductedEnrolmentError);
                        setShowModal(true);
                        setDisplayNetworkError(false);
                        setShouldWarn(false);
                    }
  
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    const onClickRetry = async() => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        let verificationResponse = await PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
        if(verificationResponse.verificationStatus===AppConstants.paymentStatus.verified){
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        }
        
        
    };

    const getCourseStartTime = (startTime, endTime) => {
        const start = DateTime.fromFormat(startTime, AppConstants.courseCardsTimeDateFormat).toFormat(AppConstants.courseCardsDateFormat);
        return `${moment(start).format(AppConstants.ISPcourseDateFormat)}, ${startTime.split(AppConstants.comma)[0]}`;
    };

    const handleModalClose = () => {
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                setShowModal(AppConstants.falseText);
            }
        }
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const handleLoginModalClose = () => {
        setShowLoginModal(AppConstants.falseText);
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                navigate(AppConstants.studentCoursesPath);
            } else {
                window.location.reload();
            }
        }
        setConfirmationPopupActivityDetails({});
    };

    const handleLoginModalShow = () => {
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            navigate(AppConstants.studentCoursesPath);
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    };


    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${shareData.courseLink}`);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className={`${classes.courseActivityCard} mt-3`}>
                {cardDetails !== null && cardDetails?.slice(0, cardCount).map((courseCard, index) => (
                    <Col className={classes.courseActivityCardSection}>
                        <Card key={index} className={classes.courseActivityMainCard} onClick={() => handleCardClick(`${process.env.REACT_APP_FRONT_END_URL}${courseCard.courseLink}`)}>
                            <Card.Img variant="top" src={courseCard.courseThumbnailImagePath || defaultThumbnail} className={classes.courseActivityCardImage} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(courseCard) }} />
                            <Card.Body className={classes.courseActivityCardBody}>
                                <div className="subTitleContainer mb-1">
                                    <div className={`cardSubTitle courseDiffColor`}>{courseCard.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() && <span className="onlineIconCourse" data-tooltip-place="top" data-tooltip-id={courseCard.uniqueId}><a onClick={stopPropagation} href={courseCard.geoLocation} target={AppConstants.openInNewTab} className="cardLink"><CgPin className="geoLocationIcon onlineIconCourse" /></a></span>} {courseCard.modeOfDelivery.toUpperCase()}</div>
                                    <Tooltip className="tooltipMessage" id={courseCard.uniqueId}><span className="tooltipMessage">{courseCard.address}</span></Tooltip>
                                </div>
                                <Card.Text className={`${classes.cardTitle} titleFixedHeight`}>
                                    <Card.Title data-tooltip-id={`${courseCard.uniqueId}-title`} className={classes.courseActivityCardTitle}><Link className={classes.courseActivityCardTitleLink}>{courseCard.courseTitle}</Link></Card.Title>
                                    <Tooltip className="courseTitleTooltip" id={`${courseCard.uniqueId}-title`}><span>{courseCard.courseTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                {courseCard.userRole === "Expert" && <div onClick={stopPropagation} className={classes.courseActivityOrganizer}>By&nbsp;<Link to={`${AppConstants.forwardSlash}${AppConstants.expert}${AppConstants.forwardSlash}${courseCard.userSlug}`} className={classes.courseActivityOrganizerLink}>{courseCard.organizer}</Link></div>}
                                {courseCard.userRole === "Agency" && <div onClick={stopPropagation} className={classes.courseActivityOrganizer}>By&nbsp;<Link to={`${AppConstants.forwardSlash}${AppConstants.agency}${AppConstants.forwardSlash}${courseCard.userSlug}`} className={classes.courseActivityOrganizerLink}>{courseCard.organizer}</Link></div>}
                                <div className="cardTimeAndDate">
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat"><ViewSelectedWeekDays selectedValues={courseCard.weekDays} /></span>
                                    </div>
                                </div>
                                {courseCard.courseType === AppConstants.structuredCourseType && <div className={classes.courseActivityClasses}>
                                    <AiOutlineCalendar className={classes.courseActivityCardInfoIcon} /> <div>&nbsp;Starts {getCourseStartTime(courseCard.startTime, courseCard.endTime)} {courseCard.preferredTimezone === AppConstants.utcTimezone && `(${courseCard.preferredTimezone})`}</div>
                                </div>}
                                {courseCard.courseType === AppConstants.flexibleCourseType && <div className={classes.courseActivityClasses}>
                                    <AiOutlineClockCircle className={classes.courseActivityCardInfoIcon} /> <div>&nbsp;{DateTimeUtil.convertUtcTimeToLocalTime(courseCard.windowStartTime)} to {DateTimeUtil.convertUtcTimeToLocalTime(courseCard.windowEndTime)} {courseCard.preferredTimezone === AppConstants.utcTimezone && `(${courseCard.preferredTimezone})`} {courseCard.reviewCount >= minimumReviewCount && `| ${MathUtil.formatNumberToLocaleString(courseCard.reviewCount)} ${courseCard.reviewCount > 1 ? "Reviews" : "Review"}`}</div>
                                </div>}
                                {courseCard.trackingType !== AppConstants.durationBasedTrackingText && <div className={classes.courseActivityClasses}> <FaChalkboardTeacher className={classes.courseActivityCardInfoIcon} />&nbsp;
                                    {courseCard.numberOfClasses != null && <div data-tooltip-id={`${courseCard.uniqueId}-remainingClasses`}>{courseCard.numberOfClasses} {courseCard.numberOfClasses === 1 ? "Class" : "Classes"}</div>}
                                    {courseCard.duration != null && <div>&nbsp;| {courseCard.duration} {courseCard.duration === 0.5 || courseCard.duration === 1 ? "Hour" : "Hours"}</div>}
                                </div>}
                                {courseCard.trackingType === AppConstants.durationBasedTrackingText && <div className={classes.courseActivityClasses}> <FaChalkboardTeacher className={classes.courseActivityCardInfoIcon} />&nbsp;{courseCard.packageDuration} {courseCard.packageDuration > 1 ? "Days" : "Day"}</div>}
                                <div className={classes.courseActivityCardsEnrolSection}>
                                    <div className={classes.courseActivityCardMode}>
                                        <div className={classes.costInfo}>
                                            <BiWallet /> {PaymentsUtil.calculateCost({ cost: courseCard.cost, discount: courseCard.discount })}
                                        </div>
                                    </div>
                                    <div className={classes.courseActivityCardEnrol}>
                                        <Button onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: courseCard.courseId, title: courseCard.courseTitle, uniqueId: courseCard.uniqueId, startTime: courseCard.courseType === AppConstants.structuredCourseType ? `${courseCard.utcStartDate} ${courseCard.windowStartTime}` : AppConstants.nullText, payOfflineFlag: courseCard.payOfflineFlag, creatorName: courseCard.organizer, creatorRole: courseCard.userRole }) }} className={classes.courseActivityCardButton}>{courseCard.courseType === AppConstants.flexibleCourseType && courseCard.courseEnrolledOnDate !== null ? AppConstants.renewPlanText : "Enrol"}</Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {props.allActivitiesForSkill !== null && <div className={classes.loadMoreButton} >
                {cardCount < props.allActivitiesForSkill?.length && <BsArrowDownCircle className={classes.loadMoreIcon} onClick={() => setCardCount(cardCount + viewMoreCardsCount)} />}
            </div>}
            {(props.allActivitiesForSkill !== null && props.allActivitiesForSkill?.length >= AppConstants.ISPLearningActivityCardsLimit && ((Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) || !Auth.isLogin())) && <div className={classes.exploreMoreButton}>
                {(cardCount >= props.allActivitiesForSkill.length) && <Button onClick={handleLoginModalShow}>{Auth.isLogin() ? `Explore More` : `Login to Explore`}</Button>}
            </div>}
            {(props.allActivitiesForSkill == null || props.allActivitiesForSkill?.length === 0) && <p className='noDataAvailableError'>{`No courses for '${props.skill}' skill to display at the moment`}</p>}
            {(message !== AppConstants.emptyString || paramData.category === AppConstants.learningActivitiesTypes.course) && (showModal || paramData.category === AppConstants.learningActivitiesTypes.course) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} learningActivityId={courseId} learningActivityTitle={courseTitle} enrolInCourse={enrolInCourse} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} learningActivityUniqueId={courseUniqueId} userLoggedInAndEnroled={userLoggedInAndEnroled} learningActivityType={AppConstants.learningActivitiesTypes.course} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showLoginModal && <LoginModal onShow={handleLoginModalShow} onClose={handleLoginModalClose} />}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}