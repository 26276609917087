// SelectionContext.js
import React, { createContext, useState } from "react";

export const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [selectedActivity, setSelectedActivity] = useState("");
  const [expertName, setExpertName] = useState("");

  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState();

  return (
    <SelectionContext.Provider
      value={{
        selectedActivity,
        setSelectedActivity,
        expertName,
        setExpertName,
        subscriptionStatus,
        setSubscriptionStatus,
        subscriptionExpiryDate,
        setSubscriptionExpiryDate,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
