import React, { useEffect, useState } from 'react';
import classes from './LearnerPassPopup.module.css';
import { Modal, Button, Card, Form, InputGroup } from 'react-bootstrap';
import { BsCheck } from 'react-icons/bs';
import styles from './LearnerPassPopup.module.css';
import formClass from '../../../common/FormStyling.module.css';
import parse from 'html-react-parser';
import {
  LEARNER_PASS_PLANS,
  LEARNER_PASS_DASHBOARD,
  AppConstants,
  MEMBERSHIP_TYPES,
} from '../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { StringUtils } from '../../../utils/StringUtils';
import { PaymentsUtil } from '../../../utils/PaymentsUtil';
import { checkReferralCodeValidity } from './checkReferralCodeValidity';
import Auth from '../../../utils/Auth';
import { fetchSubscriptionPlans } from './fetchSubscriptionPlans';
import PaymentStatusModal from '../../modal/payment/PaymentStatusModal';
import {
  useLearnerPassPaymentPopupStore,
  useLearnerPassStore,
} from '../../../stores';
import { FetchMembershipStatus } from '../../../utils/SynchronizeMembershipStatus';
import { use } from 'react';
import { applyScopedClasses } from '../../../utils/applyScopedClasses';

const LearnerPassPopup = ({ show, onHide, handleButtonClick }) => {
  const {
    setLearnerPassSubscriptionStatus,
    setLearnerPassExpiryDate,
    learnerPassSubscriptionPlans,
  } = useLearnerPassStore();

  const {
    showLearnerPassPaymentPopup,
    setShowLearnerPassPaymentPopup,
    toggleLearnerPassPaymentPopup,
  } = useLearnerPassPaymentPopupStore();

  const [referralCode, setReferralCode] = useState(null);
  const [isValidCode, setIsValidCode] = useState(false);
  const [price, setPrice] = useState(0);

  const [planId, setPlanId] = useState(learnerPassSubscriptionPlans?.id);
  const [countryId, setCountryId] = useState(
    Auth.getLoggedInUserDetails().countryId
  );
  const [message, setMessage] = useState('');
  const [discount, setDiscount] = useState(
    learnerPassSubscriptionPlans?.netDiscount
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentId, setPaymentId] = useState('');
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(
    showLearnerPassPaymentPopup
  );
  const [paymentStatus, setPaymentStatus] = useState('idle');

  useEffect(() => {
    setPlanId(learnerPassSubscriptionPlans?.id);
    setCountryId(Auth.getLoggedInUserDetails().countryId);
  }, [planId]);

  const planDescription = learnerPassSubscriptionPlans?.planDescription;
  const processedHtml = applyScopedClasses(planDescription, styles);

  useEffect(() => {
    const cost = learnerPassSubscriptionPlans?.cost;
    const netDiscount = learnerPassSubscriptionPlans?.netDiscount;
    const actualCost = cost - netDiscount;
    setPrice(actualCost);
  }, [learnerPassSubscriptionPlans]);

  const handleReferralChange = (e) => {
    setReferralCode(e.target.value);
    setIsValidCode(null); // Reset the state when user starts typing
    setMessage('');
  };

  const handleVerifyReferral = async () => {
    // const planId = (await fetchSubscriptionPlans()) || 2;
    try {
      const referralCodeValidityResponse = await checkReferralCodeValidity({
        referralCode: referralCode,
        userEmail: Auth.getLoggedInUserDetails()?.username,
        planId: planId,
      });

      setIsValidCode(referralCodeValidityResponse.status);
      setDiscount(referralCodeValidityResponse.discount);
      if (referralCodeValidityResponse.status === 'valid') {
        setPrice(LEARNER_PASS_PLANS.discountedPrice);
      } else {
        setPrice(LEARNER_PASS_PLANS.originalPrice);
      }
      setMessage(referralCodeValidityResponse.message);
    } catch (error) {
      setIsValidCode(false);
      setMessage('Failed to verify referral code. Please try again later.');
    }
  };

  const initiatePayment = async () => {
    setLoading(true);
    setError(null);
    // const planId = await fetchSubscriptionPlans();

    let payload = {
      email: Auth.getLoggedInUserDetails()?.username,
      referalCode: isValidCode === 'valid' ? referralCode : null,
      planId: planId,
      // amount: 100,
      // discount: 20,
      // payableAmount: 80,
    };
    try {
      const response = await AuthorizedDataService.postWithResponse(
        LEARNER_PASS_DASHBOARD.learnerPassCreateOrderAPI,
        payload,
        '',
        ''
      );

      if (response.status === 201) {
        setPaymentStatus('processing');
        // setShowPaymentStatusModal(true);
        // setShowLearnerPassPaymentPopup(true);

        const data = await response.json();

        const createOrderAPIResponse = StringUtils.decodeBase64EncodedString(
          data.responseToken
        );

        // createOrderAPIResponse.lpCode = referralCode;

        const options = PaymentsUtil.getRazorpayPaymentOptions(
          createOrderAPIResponse
        );

        const rzp1 = new window.Razorpay({
          ...options,
          handler: async (response) => {
            setLoading(true);
            setError(null);
            setPaymentId(response.razorpay_payment_id);
            try {
              const paymentResult =
                await PaymentsUtil.verifyLearnerPassPaymentSignatureRazorpay({
                  paymentCallbackFromRazorpay: response,
                  verificationPayload: createOrderAPIResponse,
                  planId: planId,
                  countryId: countryId,
                });

              setPaymentId(paymentResult.transactionId);

              if (
                paymentResult.verificationStatus ===
                AppConstants.paymentStatus.verified
              ) {
                setLoading(false);
                setError(null);
                setPaymentStatus('success');
              } else {
                setLoading(false);
                setError(paymentResult.message);
                setPaymentStatus('failed');
              }
            } catch (error) {
              setLoading(false);
              setError(error.message);
              setPaymentStatus('failed');
            }
          },
        });

        rzp1.open();
      } else {
        setError('Failed to initiate payment');
      }
    } catch (error) {
      setError('Failed to initiate payment');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckoutProceed = () => {
    toggleLearnerPassPaymentPopup();
    handleVerifyReferral();
    // setShowLearnerPassPaymentPopup(false);
    setShowPaymentStatusModal(true);
    initiatePayment();
  };

  const handlePaymentStatusModalClose = () => {
    setShowPaymentStatusModal(false);
    window.location.reload();
  };

  return (
    <div>
      {paymentStatus === 'idle' ? (
        <div>
          <Modal show={show} onHide={onHide} centered>
            {/* <Modal.Header closeButton></Modal.Header> */}
            <Modal.Body className={`${styles.modalBody} `}>
              <button
                className={styles.closeButton}
                onClick={onHide}
                aria-label="Close"
              >
                &times;
              </button>

              <Card className={styles.pricingCard}>
                <div className={styles.popularTag}>EARLY BIRD OFFER</div>
                <Card.Body>
                  <h3 className={`text-left ${styles.planTitle}`}>
                    Learner Pass
                  </h3>
                  <h1 className={`text-left ${styles.planPrice}`}>
                    <span className={`${styles.originalPrice}`}>₹{price}</span>
                    <span className={`${styles.monthTitle}`}>
                      {' '}
                      for {learnerPassSubscriptionPlans?.durationInMonths} {learnerPassSubscriptionPlans?.durationInMonths == 1 ? 'month' : 'months'}
                    </span>
                  </h1>
                  <Button
                    variant="primary"
                    className={`${styles.checkoutButton} mt-3`}
                    onClick={handleCheckoutProceed}
                  >
                    Checkout
                  </Button>

                  {parse(processedHtml)}

                  {error && (
                    <div
                      className={`text-center ${isValidCode
                        ? classes.successMessage
                        : classes.errorMessage
                        }`}
                    >
                      {error}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div>
          <PaymentStatusModal
            show={showPaymentStatusModal}
            // show={showLearnerPassPaymentPopup}
            status={paymentStatus}
            onClose={handlePaymentStatusModalClose}
            paymentId={paymentId}
          />
        </div>
      )}
    </div>
  );
};

export default LearnerPassPopup;
