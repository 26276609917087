import React from 'react';
import classes from './StudentMyAssessments.module.css';
import {
  AppConstants,
  SKILL_PROGRESS_ASSESSMENT_CONSTANTS,
} from '../../constants/AppConstants';
import { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Col, Row } from 'react-bootstrap';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import moment from 'moment';

export const StudentMyAssessmentsPage = () => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const nonOnboardedRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 540);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onGridReady = (params) => {
    params.columnApi.applyColumnState({
      state: [
        {
          colId: 'assessmentStringId',
          sort: 'asc',
        },
      ],
    });
  };

  useEffect(() => {
    async function getStudentAssessments() {
      const url = SKILL_PROGRESS_ASSESSMENT_CONSTANTS.getLearnerAssessments;
      const response = await AuthorizedDataService.getRequest(url, '', '');
      if (response) {
        const filteredData = response.filter(
          (item) => item.assessmentEnabledFlag === 'Y'
        );
        setRowData(filteredData);
      }
    }
    getStudentAssessments();
  }, []);

  const handleClick = (event, assessmentUniqueId) => {
    event.stopPropagation();
    if (assessmentUniqueId) {
      window.open(`/learner-assessment/${assessmentUniqueId}`, '_blank');
    }
  };

  const myAssessmentsColumnDef = [
    {
      headerName: 'Assessment Id',
      field: 'assessmentStringId',
      sortable: true,
      filter: true,
      width: 100,
      comparator: (valueA, valueB) => {
        return valueA.localeCompare(valueB, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      },
      valueGetter: (params) => params.data.assessmentStringId,
      tooltipValueGetter: (params) =>
        params.data.assessmentStringId || AppConstants.notAvailableText,
    },
    {
      headerName: 'Assessed By',
      field: 'assessedBy',
      sortable: true,
      filter: true,
      headerClass: 'multi-line-header',
      width: 290,
      tooltipValueGetter: (params) =>
        params.data.assessedBy || AppConstants.notAvailableText,
    },
    {
      headerName: 'Assessment Date',
      field: 'assessmentDate',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 200,
      tooltipValueGetter: ({ data }) =>
        data.assessmentDate
          ? `${moment.utc(data.assessmentDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.assessmentDate
          ? `${moment.utc(data.assessmentDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
    },
    {
      headerName: 'Assessment Description',
      field: 'assessmentDescription',
      sortable: true,
      filter: true,
      headerClass: 'multi-line-header',
      width: 350,
      valueGetter: (params) =>
        params.data.assessmentDescription || AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.data.assessmentDescription || AppConstants.notAvailableText,
    },
    {
      headerName: 'Score',
      field: 'totalMarks',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 120,
      valueGetter: (params) => {
        const totalMarks =
          params.data.totalMarks ?? AppConstants.notAvailableText;
        const maxMarks = params.data.maximumMarks;
        return maxMarks ? `${totalMarks}/${maxMarks}` : `${totalMarks}`;
      },
      tooltipValueGetter: (params) => {
        const totalMarks =
          params.data.totalMarks ?? AppConstants.notAvailableText;
        const maxMarks = params.data.maximumMarks;
        return maxMarks ? `${totalMarks}/${maxMarks}` : `${totalMarks}`;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      headerClass: 'multi-line-header',
      sortable: false,
      width: 120,
      cellRendererFramework: (params) => {
        const { assessmentUniqueId, assessmentType } = params.data;

        return assessmentType === 'Regular' ? (
          <Button
            className="gridEditButton"
            onClick={(event) => handleClick(event, assessmentUniqueId)}
          >
            View Report
          </Button>
        ) : (
          <p style={{ margin: 0 }}>N/A</p>
        );
      },
    },
  ];

  return (
    <div className="studentContentSection">
      <h3 className={classes.heading}>My Assessments</h3>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div
            className="ag-theme-alpine"
            style={{ width: '100%', height: '100%' }}
          >
            <AgGridReact
              ref={nonOnboardedRef}
              onGridReady={onGridReady}
              suppressExcelExport={true}
              className="expertObrDetailsGrid"
              columnDefs={myAssessmentsColumnDef}
              pagination={true}
              paginationPageSize={AppConstants.adminDashboardPaginationSize}
              rowData={rowData}
              enableBrowserTooltips={true}
              domLayout="autoHeight"
              suppressRowHoverHighlight={true}
              defaultColDef={{
                filter: true,
                sortable: true,
                flex: 1,
                minWidth: isSmallScreen ? 160 : 100,
                resizable: true,
                suppressMenu: true,
                suppressHorizontalScroll: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                floatingFilter: true,
              }}
              enableFilter={true}
              enableSorting={true}
              enableSearching={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
