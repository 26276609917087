import { Row, Carousel } from 'react-bootstrap';
import brandStyle from './ExpressionOfInterestBrandBuildingSection.module.css';
import { AppConstants } from '../../../constants/AppConstants';

export const ExpressionOfInterestBrandBuildingSection = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;

  return (
    <>
      <div id="features" className={brandStyle.brandBuildingSection}>
        <Carousel interval={9000} slide={true} controls={true}>
          {AppConstants.brandBuildingData.map((data, dataIndex) => (
            <Carousel.Item
              className={brandStyle.featureCarousel}
              key={dataIndex}
              style={{
                backgroundColor: data.sectionBackgroundColor || 'transparent',
              }}
            >
              <Row>
                <h2 className={brandStyle.pageHeading}>{data.pageHeading}</h2>
                <div className="col-12 col-md-6">
                  <div className={brandStyle.brandBuilding}>
                    {data.brandBuildingContent.map((item) => (
                      <div key={item.brandId} className={brandStyle.brandContent}>
                        <div className={brandStyle.brandImg}>
                          <img
                            className={brandStyle.featureIconImage}
                            style={{
                              backgroundColor: item.brandColor || 'transparent',
                            }}
                            src={`${mediaContent}${item.brandImg}`}
                            alt="Brand"
                          />
                        </div>
                        <div className={brandStyle.brandDetails}>
                          <h3 className={brandStyle.brandHeading}>{item.brandHeading}</h3>
                          <p className={brandStyle.brandDesciption}>{item.brandDesciption}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* col-md-5: Placeholder for Image */}
                <div className={`${brandStyle.studentPassImageSection} col-12 col-md-6 `}>
                  <img className={brandStyle.buildBrandImage} src={`${mediaContent}${data.brandImage}`} alt="Brand" />
                </div>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};
