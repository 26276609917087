import { AppConstants } from '../constants/AppConstants';
import { DataService } from '../services/DataService';

export const FetchMembershipStatus = async ({ userEmail, membershipType }) => {
  const url = `${process.env.REACT_APP_API_URL}${AppConstants.getMembershipStatusAPI}?userEmail=${encodeURIComponent(userEmail)}&membershipType=${encodeURIComponent(membershipType)}`;

  try {
    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    const data = await response.json();
    return data
  } catch (error) {
    return null;
  }
};
