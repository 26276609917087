import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import parse from 'html-react-parser';
import { AppConstants } from "../../constants/AppConstants";
import classes from "./CourseFeedbackModal.module.css";
import Moment from "react-moment";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { BiLoaderAlt, BiPencil } from "react-icons/bi";
import { AiFillDelete, AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import modalClass from "../cards/CompetitionsCards.module.css";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { RecommendLearnerTextEditor } from "../recommendLearner/RecommendLearnerTextEditor";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const RecommendationsModal = ({ closeRecommendationsModal, learnerId, modalHeading, recommendationHistoryHeading, mode }) => {
    const [showSpinner, setShowSpinner] = useState(AppConstants.trueText);
    const [recommendationHistory, setRecommendationHistory] = useState([]);
    const [submitMessage, setSubmitMessage] = useState(AppConstants.emptyString);
    const [selectedRemark, setSelectedRemark] = useState(AppConstants.emptyString);
    const [updatingRemark, setUpdatingRemark] = useState(AppConstants.emptyString);
    const [recommendationUpdateMessage, setRecommendationUpdateMessage] = useState(AppConstants.emptyString);
    const [deleteSpinner, setDeleteSpinner] = useState(AppConstants.falseText);
    const [showModalSuccess, setShowModalSuccess] = useState(AppConstants.falseText);
    const [updateMode, setUpdateMode] = useState(AppConstants.emptyString);
    const [updateWarning, setUpdateWarning] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        let timer = setTimeout(() => {
            setSubmitMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => { clearTimeout(timer) };
    }, [submitMessage]);

    const fetchRecommendationHistory = useCallback(async () => {
        const url = `${AppConstants.fetchRecommendationsForLearner}${learnerId}`;
        let response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);
        if (response) {
            setRecommendationHistory(response);
            setShowSpinner(AppConstants.falseText);
        } else {
            setShowSpinner(AppConstants.falseText);
            setRecommendationHistory(response);
        }
    }, [])

    useEffect(() => {
        fetchRecommendationHistory();
    }, [fetchRecommendationHistory]);

    const refreshRecommendationHistory = async (mode) => {
        const url = `${AppConstants.fetchRecommendationsForLearner}${learnerId}`;
        let response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);
        if (response) {
            setRecommendationHistory(response);
            if (mode === AppConstants.actionModes.edit) {
                setSelectedRemark(AppConstants.emptyString)
            }
        }
    }

    const closeEditForm = () => {
        setSelectedRemark(AppConstants.emptyString);
        setUpdateMode(AppConstants.emptyString)
    };

    const deletedSelectedRecommendation = () => {
        setUpdateMode(AppConstants.actionModes.delete);
        setUpdateWarning(AppConstants.deleteRecommendationConfirmation);
    };

    const removeRecommendation = async () => {
        setDeleteSpinner(AppConstants.trueText);
        let url = AppConstants.recommendLearnerAPI;
        let data = {
            learnerQrenciaId: learnerId,
            recommendation: updatingRemark.recommendation,
            recommendationUniqueId: updatingRemark.recommendationUniqueId,
            deleteRecommendationFlag: AppConstants.yText
        };
        const response = await AuthorizedDataService.postRequest(url, data, AppConstants.emptyString, AppConstants.emptyString);
        if (response) {
            setDeleteSpinner(AppConstants.falseText);
            setRecommendationUpdateMessage(SuccessMessage.recommendationSuccessfullyDeleted);
            refreshRecommendationHistory(AppConstants.actionModes.edit);
            setShowModalSuccess(AppConstants.trueText);
        } else {
            setDeleteSpinner(AppConstants.falseText);
            setRecommendationUpdateMessage("");
        }
    };

    const closeDeleteRecommendationModal = () => {
        setRecommendationUpdateMessage(AppConstants.emptyString);
        setUpdatingRemark(AppConstants.emptyString);
        setShowModalSuccess(AppConstants.falseText);
        setUpdateWarning(AppConstants.emptyString);
    };

    const onClickEditRecommendation = (recommendation) => {
        setUpdateMode(AppConstants.actionModes.edit);
        setSelectedRemark(recommendation);
        setUpdateWarning(AppConstants.emptyString)
        setSubmitMessage(AppConstants.falseText);
    };


    const setEditRecommendationDetails = () => {
        setSelectedRemark(updatingRemark);
        setSubmitMessage(AppConstants.emptyString);
        setRecommendationUpdateMessage(AppConstants.falseText);
        setUpdatingRemark(AppConstants.emptyString);
        setUpdateWarning(AppConstants.emptyString)
        setShowModalSuccess(AppConstants.falseText);
    };

    return (
        <Modal size="xl" show={true} onHide={closeRecommendationsModal} backdrop="static" keyboard={false} centered>
            {modalHeading && !showSpinner && <Modal.Header closeButton>
                <h1 className={classes.heading}>{modalHeading}</h1>
            </Modal.Header>}
            {updateWarning === AppConstants.emptyString && !updatingRemark.recommendationUniqueId && <Modal.Body>
                {showSpinner ? <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p> : <div className={classes.modalBody}>
                    {recommendationHistory.length == 0 && <div className={classes.statusContainer}>
                        <p className="noDataAvailableError">{ErrorMessages.noRecommendationsMessage}</p>
                    </div>}
                    {recommendationHistory.length > 0 &&
                        <div>
                            <div className={classes.responseContainer}>
                                {recommendationHistory.map((recommendation, index) => (
                                    <div key={index}>
                                        {selectedRemark?.recommendationUniqueId && selectedRemark.recommendationUniqueId === recommendation.recommendationUniqueId ? <>
                                            <RecommendLearnerTextEditor learnerId={learnerId} placeholder="Type recommendation here" refreshRecommendationHistory={refreshRecommendationHistory} selectedResponse={selectedRemark} closeEditForm={closeEditForm} />
                                        </> :
                                            <div className={classes.responseCardContainer}>
                                                {mode !== AppConstants.actionModes.view && <div className={classes.editIcon}><BiPencil onClick={() => onClickEditRecommendation(recommendation)} /></div>}
                                                {mode !== AppConstants.actionModes.view && <div className={classes.deleteIcon}><AiFillDelete onClick={() => { setUpdatingRemark(recommendation); setSubmitMessage(AppConstants.falseText); deletedSelectedRecommendation(); }} /></div>}
                                                <div className={`${classes.responseCard} ql-editor`}>{parse(recommendation?.recommendation)}
                                                </div>
                                                {recommendation.recommendedOn && recommendation.recommendationUpdatedOn && (
                                                    <span className={classes.editedText}>Edited</span>
                                                )}
                                                <p className={classes.responseDateAndTime}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{recommendation.recommendedOn.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{recommendation.recommendedOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${recommendationHistory.preferredTimezone})`}</p>
                                            </div>}
                                    </div>))}
                            </div>
                        </div>
                    }
                </div>}
            </Modal.Body>}
            {updateWarning !== AppConstants.emptyString && updatingRemark.recommendationUniqueId && <>
                <Modal.Body>
                    <div className={classes.warningModalbody}>
                        {!showModalSuccess && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        {showModalSuccess && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            <p className={classes.deleteMessage}>{recommendationUpdateMessage ? recommendationUpdateMessage : updateWarning}</p>
                        </div>
                        {!recommendationUpdateMessage && <div className={`${classes.responseCard} ql-editor`}>{parse(updatingRemark?.recommendation)}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    {recommendationUpdateMessage && <Button className={modalClass.modalCloseButton} onClick={closeDeleteRecommendationModal}>Close</Button>}
                    {!recommendationUpdateMessage && <Button className={modalClass.modalNoButton} onClick={() => { setUpdatingRemark(AppConstants.emptyString); setUpdateWarning(AppConstants.emptyString) }}>No</Button>}
                    {!recommendationUpdateMessage && <Button className={modalClass.modalYesButton} onClick={() => updateMode === AppConstants.actionModes.delete ? removeRecommendation() : setEditRecommendationDetails()}>Yes {deleteSpinner && <BiLoaderAlt className="spinner" />}</Button>}
                </Modal.Footer>
            </>}
        </Modal>
    )
}