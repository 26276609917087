import priceStyle from './ExpressionOfInterestPricingCard.module.css';
import { Row } from 'react-bootstrap';
import { AppConstants } from '../../../constants/AppConstants';

const handleScroll = (event, id) => {
  event.preventDefault();
  const element = document.querySelector(id);
  if (element) {
    let offset = 0;

    if (window.innerWidth > 768) {
      offset = (window.innerHeight - element.offsetHeight) / 2;
    }

    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: elementPosition - offset,
      behavior: 'smooth',
    });
  }
};

export const ExpressionOfInterestPricingCard = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  return (
    <>
      <div id="pricingCard" className={priceStyle.pricingCard}>
        <Row>
          <div
            className={`${priceStyle.pricingDescription} col-md-4 col-sm-12`}
          >
            <div className={priceStyle.section}>
              <p className={priceStyle.sectionDesc}>
                {AppConstants.getStartedWithPassionPlus}
              </p>
            </div>
            <p className={priceStyle.pricingContent}>
              {AppConstants.pricingDescription}
            </p>
            <button
              className={priceStyle.signUpButton}
              onClick={(e) => handleScroll(e, '#formSection')}
            >
              Subscribe Now
            </button>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className={priceStyle.subscriptionCard}>
              <img
                className={priceStyle.subscriptionImage}
                src={`${mediaContent}${AppConstants.membershipFee}`}
                alt="Membership Fee"
              ></img>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className={priceStyle.subscriptionCard}>
              <img
                className={priceStyle.subscriptionImage}
                src={`${mediaContent}${AppConstants.learnerPassFee}`}
                alt="Learner Pass Fee"
              ></img>
            </div>
          </div>

          {/* <div className={`${priceStyle.mambershipCard} col-md-7 col-sm-12`}>
            <div className="row">
              <div className={`${priceStyle.priceCard} col-md-6 col-12`}>
                <p className={priceStyle.pricePlan1}>
                  <div>
                    <img
                      className={priceStyle.priceIcon}
                      src={`${mediaContent}${AppConstants.subscriptionModel}`}
                      alt="Price"
                    ></img>
                  </div>
                  <span className={priceStyle.price}>500</span>
                  <span className={priceStyle.priceSuffix}>/month</span>
                </p>
                <div className={priceStyle.membershipButton}>
                  {AppConstants.marketplaceMembership}
                </div>
                <p className={priceStyle.cardDesc}>
                  {AppConstants.billedAnnualy}
                  <span>{AppConstants.refundPolicy}</span>
                </p>
              </div>

              <div className={`${priceStyle.priceCard} col-md-6 col-12`}>
                <p className={priceStyle.pricePlan2}>
                  <div>
                    <img
                      className={priceStyle.priceIcon}
                      src={`${mediaContent}${AppConstants.subscriptionModel}`}
                      alt="Price"
                    ></img>
                  </div>
                  <span className={priceStyle.price}>10</span>
                  <span className={priceStyle.priceSuffix}>%</span>
                </p>
                <div className={priceStyle.membershipButton}>
                  {AppConstants.platformFee}
                </div>
                <p className={priceStyle.cardDesc}>
                  {AppConstants.serviceProvide}
                </p>
              </div>
            </div>
            <div className={priceStyle.plusCircle}>
              <p className={priceStyle.plus}>+</p>
            </div>
          </div> */}
        </Row>
      </div>
    </>
  );
};
