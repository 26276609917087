import { useLoaderData, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import Auth from '../../utils/Auth';
import { MdOpenInNew } from 'react-icons/md';
import classes from './RecommendLearner.module.css';
import { QuizAuthenticationForm } from '../quizEventComponent/QuizAuthenticationForm';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { BiLoaderAlt } from 'react-icons/bi';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { ErrorSuccessAlertMessage } from '../errorSuccessMessage/ErrorSuccessAlertMessage';
import formClass from '../../common/FormStyling.module.css';
import { RecommendLearnerTextEditor } from './RecommendLearnerTextEditor';
import { RecommendationsModal } from '../modal/RecommendationsModal';

export const RecommendLearner = () => {
  const [landingPageData] = useLoaderData();
  const [showPopupForLogin, setShowPopupForLogin] = useState(
    AppConstants.falseText
  );
  const navigate = useNavigate();
  const [learnerId, setLearnerId] = useState(AppConstants.emptyString);
  const [detailsSpinner, setDetailsSpinner] = useState(AppConstants.falseText);
  const [learnerDetails, setLearnerDetails] = useState({});
  const [learnerErrorMessage, setLearnerErrorMessage] = useState(
    AppConstants.emptyString
  );
  const [resetFlag, setResetFlag] = useState(AppConstants.trueText);
  const [viewRecommendationsModal, setViewRecommendationsModal] = useState(
    AppConstants.falseText
  );

  useEffect(() => {
    if (Auth.isLogin()) {
      if (
        !(
          Auth.getUserRole() === AppConstants.userRoleExpert ||
          (Auth.getUserRole() === AppConstants.userRoleAgency &&
            Auth.getAgencyType() === AppConstants.obrAgencyTypes[0])
        )
      ) {
        navigate(AppConstants.asterisk);
        setShowPopupForLogin(AppConstants.falseText);
      }
    } else {
      setShowPopupForLogin(AppConstants.trueText);
    }
  }, [navigate]);

  const closeAuthenticationForm = () => {
    window.location.reload();
  };

  const fetchLearnerDetails = async (e) => {
    try {
      e.preventDefault();
      setLearnerDetails({});
      setDetailsSpinner(AppConstants.trueText);

      const url = `${AppConstants.getLearnerDetailsForQrenciaIdAPI}${learnerId}`;
      const response = await AuthorizedDataService.getRequestWithResponseCode(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response.status === AppConstants.httpResponseCodes.responseCode409) {
        setLearnerErrorMessage(
          `${ErrorMessages.notLearnerIdError}${AppConstants.space}${learnerId}${AppConstants.space}${ErrorMessages.doesNotExistsText}`
        );
        setDetailsSpinner(AppConstants.falseText);
        let timer = setTimeout(() => {
          setLearnerErrorMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => {
          clearTimeout(timer);
        };
      } else if (
        response.status === AppConstants.httpResponseCodes.responseCode500
      ) {
        setLearnerErrorMessage(ErrorMessages.learnerShowDetailsError);
        setDetailsSpinner(AppConstants.falseText);
        let timer = setTimeout(() => {
          setLearnerErrorMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => {
          clearTimeout(timer);
        };
      } else if (response) {
        setLearnerDetails(response);
        setResetFlag(AppConstants.falseText);
        setLearnerErrorMessage(AppConstants.emptyString);
        setDetailsSpinner(AppConstants.falseText);
      }
    } catch (error) {
      setLearnerErrorMessage(ErrorMessages.learnerShowDetailsError);
      setDetailsSpinner(AppConstants.falseText);
      let timer = setTimeout(() => {
        setLearnerErrorMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
      return () => {
        clearTimeout(timer);
      };
    }
  };

  const handleKeyDown = (event) => {
    let excludedKeywords = AppConstants.excludedKeywordsNumberField;
    if (excludedKeywords.includes(event.key)) event.preventDefault();
  };
  const resetInput = () => {
    setResetFlag(AppConstants.trueText);
    setLearnerId(AppConstants.emptyString);
    setLearnerDetails({});
  };
  const viewPastRecommendations = () => {
    setViewRecommendationsModal(AppConstants.trueText);
  };
  const closeRecommendationsModal = () => {
    setViewRecommendationsModal(AppConstants.falseText);
  };
  return (
    <div>
      {Auth.isLogin() && (
        <LandingPageTopNavigation landingPageData={landingPageData} />
      )}
      {showPopupForLogin}
      {showPopupForLogin ? (
        <div className={classes.loginSection}>
          <QuizAuthenticationForm
            disableSignup={AppConstants.trueText}
            closeAuthenticationForm={closeAuthenticationForm}
            signupHeading="Signup"
            loginHeading="Login"
          />
        </div>
      ) : (
        <>
          <div className={classes.pageContentSection}>
            <div className={classes.pageContainer}>
              <Row className="mb-4 mt-2">
                <h1 className={classes.heading}>Recommendations</h1>
                <form
                  onSubmit={(e) => fetchLearnerDetails(e)}
                  id="getLearnerDetails"
                >
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      className={classes.idInputFieldSection}
                    >
                      <Form.Label className={formClass.formLabel}>
                        Learner Qrencia Id
                      </Form.Label>
                      <Form.Control
                        className={formClass.uploadFileInput}
                        type={AppConstants.inputFieldTypes.number}
                        onKeyDown={handleKeyDown}
                        required
                        disabled={!resetFlag}
                        value={learnerId}
                        onChange={(event) => setLearnerId(event.target.value)}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={2}
                      className={classes.buttonFieldSection}
                    >
                      {resetFlag && (
                        <Button
                          disabled={detailsSpinner}
                          type="submit"
                          className={classes.selectButton}
                        >
                          Show{' '}
                          {detailsSpinner && (
                            <BiLoaderAlt className="spinner" />
                          )}
                        </Button>
                      )}
                      {!resetFlag && (
                        <Button
                          disabled={detailsSpinner}
                          onClick={resetInput}
                          className={classes.selectButton}
                        >
                          Reset{' '}
                          {detailsSpinner && (
                            <BiLoaderAlt className="spinner" />
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </form>
                {learnerErrorMessage && (
                  <ErrorSuccessAlertMessage
                    message={learnerErrorMessage}
                    varient={AppConstants.alertVarient[1]}
                  />
                )}
              </Row>
              {learnerDetails && !resetFlag && (
                <Row className="mt-2">
                  <hr className={classes.detailsColumHrContainer} />
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.learnerDetailsSection}>
                      <div className={classes.columnContainer}>
                        <h5 className={classes.detailsHeading}>
                          Learner Details
                        </h5>
                      </div>
                      <div>
                        <Button
                          className={classes.viewRecommendationsButton}
                          onClick={viewPastRecommendations}
                        >
                          Past Recommendations
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>
                        Learner Name
                      </p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.name}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>Email</p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.email}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>
                        Phone Number
                      </p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.countryMobileNumberCode}-
                        {learnerDetails.mobileNumber}
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>Country</p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.country}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>State</p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.state
                          ? learnerDetails.state
                          : AppConstants.notAvailableText}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>City</p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.district
                          ? learnerDetails.district
                          : AppConstants.notAvailableText}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>Gender</p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.gender
                          ? learnerDetails.gender
                          : AppConstants.notAvailableText}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>Age</p>
                      <p className={classes.learnerDetailsText}>
                        {learnerDetails.age
                          ? learnerDetails.age
                          : AppConstants.notAvailableText}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className={classes.columnContainer}>
                      <p className={classes.learnerDetailsLabel}>
                        Learner Profile
                      </p>
                      <a
                        className={classes.learnerDetailsLinkLabel}
                        target={AppConstants.openInNewTab}
                        href={`${AppConstants.forwardSlash}${AppConstants.learner}${AppConstants.forwardSlash}${learnerDetails.userSlug}`}
                      >
                        Click Here <MdOpenInNew />
                      </a>
                    </div>
                  </Col>
                </Row>
              )}
              {!resetFlag && (
                <RecommendLearnerTextEditor
                  learnerId={learnerId}
                  placeholder="Type recommendation here"
                />
              )}
              {viewRecommendationsModal && (
                <RecommendationsModal
                  modalHeading="Past Recommendations"
                  feedbackHistoryHeading="Past Recommendations"
                  closeRecommendationsModal={closeRecommendationsModal}
                  learnerId={learnerId}
                />
              )}
            </div>
          </div>
          <LandingPageFooter landingPageData={landingPageData} />
        </>
      )}
    </div>
  );
};
