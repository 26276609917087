import dayjs from 'dayjs';

export const transformActivities = (activities) =>

  activities.map((activity) => {
    const {
      learningActivityId,
      learningActivityName,
      learningActivityType,
      learningActivityStartDate,
      learningActivityEndDate,
      learningActivityStatus,
      courseType,
      creatorName,
      creatorType,
      creatorSlug,
      recentActivityType
    } = activity;

    let activityVerb = '';

    let formattedLearningActivityStartDate = dayjs(
      learningActivityStartDate
    ).format('MMMM DD');

    switch (learningActivityStatus.toLowerCase()) {
      case 'past':
        if (learningActivityType.toLowerCase() === 'course') {
          activityVerb = `Completed `;
        } else if (learningActivityType.toLowerCase() === 'workshop') {
          activityVerb = `Attended `;
        } else if (learningActivityType.toLowerCase() === 'competition') {
          activityVerb = `Participated in `;
        }
        break;

      case 'ongoing':
        if (learningActivityType.toLowerCase() === 'course') {
          activityVerb = `Completing `;
        } else if (learningActivityType.toLowerCase() === 'workshop') {
          activityVerb = `Attending `;
        } else if (learningActivityType.toLowerCase() === 'competition') {
          activityVerb = `Participating in `;
        }
        break;

      case 'upcoming':
        if (learningActivityType.toLowerCase() === 'course') {
          activityVerb = `Enrolled in `;
        } else if (learningActivityType.toLowerCase() === 'workshop') {
          activityVerb = `Enrolled in `;
        } else if (learningActivityType.toLowerCase() === 'competition') {
          activityVerb = `Enrolled in `;
        }
        break;

      default:
        activityVerb = 'No activity status available';
    }

    return {
      learningActivityId,
      learningActivityName,
      learningActivityStartDate,
      learningActivityEndDate,
      learningActivityStatus,
      learningActivityType,
      enrolledOn: activity.enrolledOn,
      learningActivitySlug: activity.learningActivitySlug,
      courseType,
      activityVerb: activityVerb,
      organizer: creatorName,
      creatorType,
      organizerWebsiteLink: creatorSlug,
      recentActivityType
    };
  });
