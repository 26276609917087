import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from './LandingPageDedicatedNavbar.module.css';
import { AppConstants } from '../../constants/AppConstants';
import templateClasses from '../ExpertPageLayout/ExpertHeader.module.css';
import Auth from '../../utils/Auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from 'react-icons/fa';
import { BiLogOut, BiHome } from 'react-icons/bi';
import { Logout } from '../../common/Logout';
import { Link } from 'react-scroll';
import { DataService } from '../../services/DataService';
import { RiProfileLine } from 'react-icons/ri';

export const LandingPageDedicatedNavbar = (props) => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [accordionOpen, setAccordionOpen] = useState(false);
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
    const userBrandingPageLink = Auth.isLogin()
        ? `${process.env.REACT_APP_FRONT_END_URL}${Auth.getUserRole()?.toLowerCase()}/${Auth.getLoggedInUserDetails()?.userSlug}`
        : AppConstants.emptyString;
    const [showMenu, setShowMenu] = useState(false);
    const [quizOfTheDayData, setQuizOfTheDayData] = useState([]);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    //add active class on nav-item click
    function addActiveClass(e) {
        setShowMenu(!showMenu);
        const navigationItems = document.querySelectorAll(
            `.${classes.sectionNavItem}`
        );
        navigationItems.forEach((item, index) => {
            item.classList.remove(classes.sectionNavItemActive);
            if (index === navigationItems.length - 1) {
                e.target.classList.add(classes.sectionNavItemActive);
            }
        });
        if (
            navigationItems === null ||
            navigationItems === undefined ||
            navigationItems.length === 0
        ) {
            e.target.classList.add(classes.sectionNavItemActive);
        }
    }

    const accordionRef = useRef(null);

    const handleClickOutsideMobile = (event) => {
        if (accordionRef.current && !accordionRef.current.contains(event.target)) {
            setAccordionOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideMobile);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMobile);
        };
    }, []);

    function getUserHomePageUrl() {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleExpert) {
                window.open(
                    AppConstants.expertHomePath,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                window.open(
                    AppConstants.studentHomePath,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRoleAdmin) {
                window.open(
                    AppConstants.adminNumberTrackingPath,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRoleAgency) {
                window.open(
                    AppConstants.agencyHomePath,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRoleSales) {
                window.open(
                    AppConstants.salesNumberTrackingPath,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
                window.open(
                    AppConstants.marketingBulkSignupPath,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
                window.open(
                    AppConstants.partnerSuccessNumberTracking,
                    AppConstants.newPageTargetSelf
                );
            } else if (Auth.getUserRole() === AppConstants.userRoleTeam) {
                window.open(AppConstants.teamHomePath, AppConstants.newPageTargetSelf);
            } else {
                window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
            }
        } else {
            window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
        }
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    useEffect(() => {
        //fetch quiz of the day
        async function getQuizData() {
            const url = AppConstants.getQuizDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setQuizOfTheDayData(
                    response.filter(
                        (item) =>
                            item.quizOfTheDayFlag.toUpperCase() === AppConstants.trueFlag
                    )
                );
            } else {
                setQuizOfTheDayData([]);
            }
        }
        getQuizData();
    }, []);

    return (
        <>
            <header className={classes.header}>
                <div className={classes.hamburger} onClick={toggleMenu}>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                    <div className={classes.bar}></div>
                </div>
                <div className={classes.qrenciaLogoSection}>
                    <NavLink to="/">
                        <img
                            className={classes.qrenciaLogo}
                            src={qrenciaLogoUrl}
                            alt="Qrencia Logo"
                        />
                    </NavLink>
                </div>

                <div className={classes.navItemSection}>
                    <nav>
                        <ul className={classes.list}>
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
                            >
                                <NavLink
                                    onClick={() => window.location.replace(AppConstants.skills)}
                                    className={classes.sectionNavItem}
                                >
                                    Explore Skills
                                </NavLink>
                            </li>
                            {/* <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}>
                                <Link onClick={(e) => { addActiveClass(e) }} to="yourPassionSection" offset={-15} duration={100} className={classes.sectionNavItem}>
                                    Qrencia at Glance
                                </Link>
                            </li> */}
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
                            >
                                <NavLink
                                    to={AppConstants.expertPageRoute}
                                    className={classes.sectionNavItem}
                                >
                                    Find Experts
                                </NavLink>
                            </li>
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
                                ref={dropdownRef}
                            >
                                {props.learningActivities?.length > 0 && (
                                    <Dropdown show={dropdownOpen} className={classes.dropdown}>
                                        <Dropdown.Toggle
                                            as="div"
                                            className={classes.dropdownToggle}
                                            onClick={handleToggleDropdown}
                                        >
                                            Develop Skills
                                            <FaChevronDown />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {props.learningActivities.map((section, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    className={classes.dropdownItem}
                                                    onClick={() =>
                                                        window.location.replace(
                                                            section.subTitle.toLowerCase()
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={section.imageUrl}
                                                        className={classes.laIcons}
                                                        alt="LA Icon"
                                                    />
                                                    &nbsp;{section.title}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </li>
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
                            >
                                <NavLink
                                    onClick={() => window.location.replace(AppConstants.nudgesPageRoute)}
                                    className={classes.sectionNavItem}
                                >
                                    Get Feedback
                                </NavLink>
                            </li>
                            {quizOfTheDayData.length !== 0 && (
                                <li
                                    className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
                                >
                                    <NavLink
                                        to={AppConstants.quizLisitngPageRoute}
                                        className={classes.sectionNavItem}
                                    >
                                        Play Quiz
                                    </NavLink>
                                </li>
                            )}

                            {!Auth.isLogin() && (
                                <li className={classes.listItem}>
                                    <NavLink
                                        to={AppConstants.loginPageUrl}
                                        className={classes.loginButton}
                                    >
                                        Login
                                    </NavLink>
                                </li>
                            )}
                            {!Auth.isLogin() && (
                                <li className={classes.listItem}>
                                    <NavLink
                                        to={AppConstants.signup}
                                        className={classes.signUpButton}
                                    >
                                        Join Now
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
                {Auth.isLogin() && (
                    <div className={`${templateClasses.profileSection} ms-3`}>
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl !==
                            null && (
                                <div>
                                    <img
                                        src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl}
                                        className={templateClasses.profileImage}
                                        alt="Profile"
                                    />
                                </div>
                            )}
                        {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && (
                            <div>
                                <div className={templateClasses.userInitials}>
                                    {Auth.getLoggedInUserDetails().nameInitials}
                                </div>
                            </div>
                        )}
                        <Dropdown>
                            <Dropdown.Toggle className={templateClasses.profilePopoverButton}>
                                <p className={templateClasses.profileName}>
                                    {Auth.getLoggedInName().length < 16
                                        ? Auth.getLoggedInName()
                                        : Auth.getLoggedInName().substr(0, 16) + '..'}
                                </p>{' '}
                                <FaChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    className={templateClasses.profileDropdownItem}
                                    onClick={getUserHomePageUrl}
                                >
                                    <BiHome
                                        className={templateClasses.profileDropdownOptionIcon}
                                    />
                                    <p className={templateClasses.profileDropdownOption}>Home</p>
                                </Dropdown.Item>
                                {(Auth.getUserRole() === AppConstants.userRoleExpert ||
                                    Auth.getUserRole() === AppConstants.userRoleAgency) && (
                                        <Dropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    userBrandingPageLink,
                                                    AppConstants.openInNewTab
                                                )
                                            }
                                            className={templateClasses.profileDropdownItem}
                                        >
                                            <RiProfileLine
                                                className={templateClasses.profileDropdownOptionIcon}
                                            />
                                            <p className={templateClasses.profileDropdownOption}>
                                                Branding Page
                                            </p>
                                        </Dropdown.Item>
                                    )}
                                <Dropdown.Item
                                    className={templateClasses.profileDropdownItem}
                                    onClick={Logout.logout}
                                >
                                    <BiLogOut
                                        className={templateClasses.profileDropdownOptionIcon}
                                    />
                                    <p className={templateClasses.profileDropdownOption}>
                                        Logout
                                    </p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                {showMenu && (
                    <nav>
                        <ul
                            className={`${classes.listOnMobile} ${showMenu ? classes.showMenu : ''}`}
                        >
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                            >
                                <NavLink
                                    to={AppConstants.skills}
                                    className={classes.sectionNavItem}
                                >
                                    Explore Skills
                                </NavLink>
                            </li>
                            {/* <li className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}>
                            <Link onClick={(e) => { addActiveClass(e) }} to="yourPassionSection" offset={-15} duration={100} className={classes.sectionNavItem}>
                                Qrencia at Glance
                            </Link>
                        </li> */}
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                            >
                                <NavLink
                                    to={AppConstants.expertPageRoute}
                                    className={classes.sectionNavItem}
                                >
                                    Find Experts
                                </NavLink>
                            </li>
                            {props.learningActivities?.length > 0 && (
                                <li
                                    className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                                    ref={accordionRef}
                                >
                                    <div
                                        className={classes.accordionToggle}
                                        onClick={() => setAccordionOpen(!accordionOpen)}
                                    >
                                        Develop Skills <FaChevronDown />
                                    </div>
                                    <div
                                        className={`${classes.accordionMenu} ${accordionOpen ? classes.show : ''}`}
                                    >
                                        {props.learningActivities.map((section, index) => (
                                            <div
                                                className={classes.accordionItem}
                                                key={index}
                                                onClick={() =>
                                                    navigate(`${section.subTitle.toLowerCase()}`)
                                                }
                                            >
                                                <img
                                                    src={section.imageUrl}
                                                    className={classes.laIcons}
                                                    alt="LA Icon"
                                                />
                                                &nbsp;{section.title}
                                            </div>
                                        ))}
                                    </div>
                                </li>
                            )}
                            <li
                                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                            >
                                <NavLink
                                    to={AppConstants.nudgesPageRoute}
                                    className={classes.sectionNavItem}
                                >
                                    Get Feedback
                                </NavLink>
                            </li>
                            {quizOfTheDayData.length !== 0 && (
                                <li
                                    className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                                >
                                    <NavLink
                                        to={AppConstants.quizLisitngPageRoute}
                                        className={classes.sectionNavItem}
                                    >
                                        Play Quiz
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                )}
            </header>
        </>
    );
};
