import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './ExpressionOfInterestTopNavigation.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const ExpressionOfInterestTopNavigation = (props) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleScroll = (event, id) => {
    event.preventDefault();
    const element = document.querySelector(id);
    if (element) {
      let offset = 0;

      if (window.innerWidth > 768) {
        offset = (window.innerHeight - element.offsetHeight) / 2;
      }

      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <header className={classes.header}>
        <div className={classes.hamburger} onClick={toggleMenu}>
          <div className={classes.bar}></div>
          <div className={classes.bar}></div>
          <div className={classes.bar}></div>
        </div>
        <div className={classes.qrenciaLogoSection}>
          <NavLink to="/">
            <img
              className={classes.qrenciaLogo}
              src={qrenciaLogoUrl}
              alt="Qrencia Logo"
            />
          </NavLink>
        </div>

        <div className={classes.navItemSection}>
          <nav>
            <ul className={classes.list}>
              <li
                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
              >
                <NavLink
                  to="#"
                  className={classes.sectionNavItem}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      'https://www.qrencia.com/extra-curricular-skills',
                      `${AppConstants.openInNewTab}`,
                      'noopener,noreferrer'
                    );
                  }}
                >
                  Skills on Qrencia
                </NavLink>
              </li>
              <li
                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
              >
                <NavLink
                  to="#pricingCard"
                  className={classes.sectionNavItem}
                  onClick={(e) => handleScroll(e, '#pricingCard')}
                >
                  Pricing
                </NavLink>
              </li>
              <li
                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
              >
                <NavLink
                  to="#features"
                  className={classes.sectionNavItem}
                  onClick={(e) => handleScroll(e, '#features')}
                >
                  Features
                </NavLink>
              </li>

              <li className={classes.listItem}>
                <NavLink
                  to="#formSection"
                  className={classes.signUpButton}
                  onClick={(e) => handleScroll(e, '#formSection')}
                >
                  Get Started
                </NavLink>
              </li>

              <li className={classes.listItem}>
                <NavLink
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      'https://calendly.com/qrenciaforcreators/free-demo',
                      `${AppConstants.openInNewTab}`,
                      'noopener,noreferrer'
                    );
                  }}
                  className={classes.BookDemo}
                >
                  Book Demo
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        {showMenu && (
          <nav>
            <ul
              className={`${classes.listOnMobile} ${showMenu ? classes.showMenu : ''}`}
            >
              <li
                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
              >
                <NavLink
                  to="#"
                  className={classes.sectionNavItem}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      'https://www.qrencia.com/extra-curricular-skills';
                  }}
                >
                  Skills on Qrencia
                </NavLink>
              </li>
              <li
                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
              >
                <NavLink
                  to="#pricingCard"
                  className={classes.sectionNavItem}
                  onClick={(e) => handleScroll(e, '#pricingCard')}
                >
                  Pricing
                </NavLink>
              </li>
              <li
                className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
              >
                <NavLink
                  to="#features"
                  className={classes.sectionNavItem}
                  onClick={(e) => handleScroll(e, '#features')}
                >
                  Features
                </NavLink>
              </li>
            </ul>
          </nav>
        )}
      </header>
    </>
  );
};
