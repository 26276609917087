import React from 'react';
import classes from './MembershipStatusChip.module.css';

const MembershipStatusChip = ({ status }) => {
  const defaultStatusColor = {
    backgroundColor: '#f5f5f5',
    color: '#9e9e9e',
  };

  const statusColors = {
    active: {
      backgroundColor: '#e8ffe9',
      color: '#41ab45',
    },
    expired: {
      backgroundColor: '#fff6d6',
      color: '#bd9300',
    },
    inactive: {
      backgroundColor: '#f8e8e8',
      color: '#d17a7a',
    },
  };

  const chipBackgroundColor =
    statusColors[status.toLowerCase()]?.['backgroundColor'] ||
    defaultStatusColor['backgroundColor'];
  const chipTextColor =
    statusColors[status.toLowerCase()]?.['color'] ||
    defaultStatusColor['color'];
  return (
    <div
      className={classes.statusWrapper}
      style={{ backgroundColor: chipBackgroundColor, color: chipTextColor }}
    >
      <div className={classes.statusChipText}>{status}</div>
    </div>
  );
};

export default MembershipStatusChip;
