import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import classes from './SkillAssessmentReport.module.css';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SkillAssessmentCurrentAssessment = ({
  parameterScores = [],
  assessedBy,
  recommendation,
  assessmentDescription,
  reportPresentedBy,
}) => {
  const chartData = parameterScores.map((param) => ({
    name: param.parameterName,
    y: param.parameterScore,
    maxMarks: param.parameterMaximumMarks,
  }));

  const maxYAxis = Math.max(
    ...parameterScores.map((p) => p.parameterMaximumMarks)
  );

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'white',
      borderRadius: 12,
      height: 400,
      borderWidth: 0,
      style: {
        fontFamily: 'inherit',
      },
    },
    title: { text: '' },
    xAxis: {
      categories: parameterScores.map((p) => p.parameterName),
      labels: {
        style: {
          fontSize: '0.9em',
          fontWeight: 400,
          color: '#000',
          fontFamily: 'inherit',
        },
      },
      gridLineWidth: 0,
      lineWidth: 0,
    },

    tooltip: {
      formatter: function () {
        return `
          <b>${this.point.name}</b><br/>
          Marks Scored: <b>${this.point.y} / ${this.point.maxMarks}</b><br/>
        `;
      },
      style: {
        fontFamily: 'inherit',
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 457,
          },
          chartOptions: {
            xAxis: {
              labels: {
                style: {
                  fontSize: '0.8em',
                  fontWeight: 400,
                  color: '#000',
                  fontFamily: 'inherit',
                },
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            xAxis: {
              labels: {
                style: {
                  fontSize: '0.7em',
                  fontWeight: 400,
                  color: '#000',
                  fontFamily: 'inherit',
                },
              },
            },
          },
        },
      ],
    },
    yAxis: {
      min: 0,
      max: maxYAxis,
      title: { text: null },
      gridLineColor: '#000',
      gridLineWidth: 0.5,
      tickInterval: Math.ceil(maxYAxis / 10),
      labels: {
        style: {
          fontFamily: 'inherit',
        },
      },
    },
    legend: { enabled: false },
    colors: ['#34b5b3'],
    plotOptions: {
      column: {
        borderRadius: 15,
        borderWidth: 0.5,
        borderColor: '#34b5b3',
        dataLabels: {
          enabled: true,
          format: '{y}',
          color: '#000',
          style: {
            textOutline: 'none',
            fontFamily: 'inherit',
          },
        },
        groupPadding: 0.2,
      },
    },
    series: [
      {
        name: 'Marks Scored',
        data: chartData,
      },
    ],
    credits: { enabled: false },
  };

  const hasRemarks = parameterScores.some((param) => param.parameterRemarks);
  const hasRecommendation = !!recommendation;

  return (
    <>
      <div className={classes.currentAssessment}>
        <Row>
          <div className="col-md-12">
            <div className={classes.currentAssessmentTitle}>
              Current Assessment
            </div>
            <div className={classes.description}>{assessmentDescription}</div>

            <div
              style={{ minHeight: '400px' }}
              className={classes.chartContainer}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />
              <strong>
                <div className={classes.chartParam1}> Scores</div>
              </strong>
              <strong>
                <div className={classes.chartParam2}>Assessment Parameters</div>
              </strong>
            </div>

            {hasRemarks && (
              <div className={classes.skillTableContainer}>
                <table className={classes.assessmentTable}>
                  <thead>
                    <tr>
                      <th className={classes.skillTableHeader}>
                        Assessment Parameters
                      </th>
                      <th className={classes.skillTableHeader}>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parameterScores
                      .filter((param) => param.parameterRemarks?.trim())
                      .map((param, index) => (
                        <tr key={index} className={classes.tableRow}>
                          <td className={classes.tableCell}>
                            {param.parameterName}
                          </td>
                          <td className={classes.tableCell}>
                            {param.parameterRemarks}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            {hasRecommendation && (
              <div className={classes.recommendationSection}>
                <div className={classes.recommendationHeading}>
                  Recommendation
                </div>
                <div className={classes.recommendationText}>
                  {recommendation}
                </div>
              </div>
            )}

            <div className={classes.assessmentFooter}>
              <div className={classes.assessmentHead}>{assessedBy}</div>
              <div className={classes.assessmentSubHead}>
                Coach, {reportPresentedBy}
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default SkillAssessmentCurrentAssessment;
