import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import classes from '../cards/CompetitionsCards.module.css';
import { NavLink } from 'react-router-dom';
import signupClasses from '../formControls/SignupPageForm.module.css'
import loginFormClasses from '../formControls/LoginPageForm.module.css';
import '../../index.css';
import { Button, Form } from "react-bootstrap";
import { AppConstants } from '../../constants/AppConstants';
import Auth from '../../utils/Auth';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { RiErrorWarningLine } from 'react-icons/ri';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaInfo, FaEdit, FaCheckCircle } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import formClass from "../../common/FormStyling.module.css";
import { SuccessMessage } from '../../constants/SuccessMessage';
import { Tooltip } from 'react-tooltip';
import OTPInput, { ResendOTP } from "otp-input-react";
import { DataService } from '../../services/DataService';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { DateTime } from 'luxon';

export const LoginModal = (props) => {
    const [emailVerifyButtonDisable, setEmailVerifyButtonDisable] = useState(false);
    const { onShow, onClose } = props;
    const [passwordType, setPasswordType] = useState('password');
    const [userDetails, setUserDetails] = useState(Auth.getLoggedInName());
    const [showError, setShowError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const initialValues = { emailId: AppConstants.emptyString, password: AppConstants.emptyString };
    const [loginFormValues, setLoginFormValues] = useState(initialValues);
    const [showSignupForm, setShowSignupForm] = useState(AppConstants.falseText);
    const [capitalCity, setCapitalCity] = useState("Delhi");
    const [showSuccess, setShowSuccess] = useState([]);
    const [emailVerificationScreen, setEmailVerificationScreen] = useState(AppConstants.falseText);
    const [mobileVerificationScreen, setMobileVerificationScreen] = useState(AppConstants.falseText);
    const [countriesList, setCountriesList] = useState([]);
    const [callingCode, setCallingCode] = useState([]);
    const [showVerifyEmailText, setShowVerifyEmailText] = useState(AppConstants.falseText);
    const [showVerifyMobileText, setShowVerifyMobileText] = useState(AppConstants.falseText);
    const [emailOtp, setEmailOtp] = useState(AppConstants.emptyString);
    const [emailVerified, setEmailVerified] = useState(AppConstants.falseText);
    const [mobileOtp, setMobileOtp] = useState(AppConstants.emptyString);
    const [countryName, setCountryName] = useState("India");
    const [mobileVerified, setMobileVerified] = useState(AppConstants.trueText);
    const [signupFormError, setSignupFormError] = useState(AppConstants.emptyString);
    const initialSignupValues = { firstName: "", lastName: "", email: "", password: "", countryId: "1", mobileNumber: "", dob: "", capitalCity: "New Delhi", gender: AppConstants.emptyString };
    const [signupFormValues, setSingupFormValues] = useState(initialSignupValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [resetPasswordSpinner, setResetPasswordSpinner] = useState(false);
    const [resetPasswordMessage, setResetPasswordMessage] = useState(AppConstants.emptyString);
    const [selectedCountry, setSelectedCountry] = useState(AppConstants.emptyString);
    const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
    const index = 0;

    useEffect(() => {
        async function fetchCountries() {
            const url = AppConstants.fetchCountriesAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setCountriesList(response);
            setSelectedCountry(response.filter(country => country.countryCode === "IN")[0]);
            return null;
        };
        fetchCountries();

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinAgeKey).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, []);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === AppConstants.sessionKeyUserDetails && Auth.getLoggedInUserDetails() && !userDetails) {
                window.location.reload();
            }
        };

        // Add the event listener
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    const togglePassword = () => {
        if (passwordType === AppConstants.fieldTypePassword) {
            setPasswordType(AppConstants.fieldTypeText);
            return;
        }
        else {
            setPasswordType(AppConstants.fieldTypePassword);
            return;
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginFormValues({ ...loginFormValues, [name]: value });
    };

    //display calling on selecting country
    const onSelectingCountry = (e) => {
        setCallingCode(countriesList.filter((item) => item.id.toString() === e.target.value));
    }

    //display calling on selecting country
    const handleChangeMobileNumber = (e) => {
        setCallingCode(countriesList.filter((item) => item.id.toString() === signupFormValues.countryId));
    };

    const changeCountryCapital = (e) => {
        let country = countriesList.filter((item) => item.id.toString() === e.target.value);
        setCapitalCity(country[0].capitalCity.trim());
        setCountryName(country[0].country.trim());
    }

    const handleSignupFormChange = (e) => {
        const { name, value } = e.target;
        if (name === "dob") {
            setSingupFormValues({ ...signupFormValues, [name]: value?.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3') });
        } else {
            setSingupFormValues({ ...signupFormValues, [name]: value });
        }
    };

    const loginUser = (e) => {
        e.preventDefault();

        setShowSpinner(AppConstants.trueText);
        const apiURL = process.env.REACT_APP_API_URL;
        const authenticationUrl = `${apiURL}` + AppConstants.authenticationUri;
        const authData = {
            userName: loginFormValues.emailId,
            userPassword: loginFormValues.password
        }
        fetch(authenticationUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/JSON'
            },
            body: JSON.stringify(authData)
        })
            .then((response) => {
                if (response.ok && response.status === 200) {
                    props.onClose();
                    return response.json();
                }
                else {
                    setShowError(ErrorMessages.loginFailed);
                    setShowSpinner(false);
                    const timer = setTimeout(() => {
                        setShowError(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => clearTimeout(timer);
                }
            })
            .then((data) => {
                if (data !== null) {
                    const token = data.accessToken;
                    if (token !== null && token !== undefined) {
                        setLoginFormValues(initialValues);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
                        localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(data));
                        props.onClose();
                    }
                }
            })
    };

    const loginUserAfterSignup = () => {

        setShowSpinner(AppConstants.trueText);
        const apiURL = process.env.REACT_APP_API_URL;
        const authenticationUrl = `${apiURL}` + AppConstants.authenticationUri;
        const authData = {
            userName: signupFormValues.email,
            userPassword: signupFormValues.password
        }
        fetch(authenticationUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/JSON'
            },
            body: JSON.stringify(authData)
        })
            .then((response) => {
                if (response.ok && response.status === 200) {
                    props.onClose();
                    return response.json();
                }
                else {
                    setShowError(ErrorMessages.loginFailed);
                    setShowSpinner(false);
                    const timer = setTimeout(() => {
                        setShowError(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => clearTimeout(timer);
                }
            })
            .then((data) => {
                if (data !== null) {
                    const token = data.accessToken;
                    if (token !== null && token !== undefined) {
                        setLoginFormValues(initialValues);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
                        localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(data));
                        props.onClose();
                    }
                }
            })
    };

    //show verify email screen
    async function handleVerifyEmail() {
        setEmailVerifyButtonDisable(true);
        const url = AppConstants.generateEmailOtpAPI;
        const data = { "email": signupFormValues.email };
        const response = await DataService.post(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response?.status === AppConstants.httpResponseCodes.responseCode201) {
            setEmailVerifyButtonDisable(false);
            setMobileVerificationScreen(AppConstants.falseText);
            setEmailVerificationScreen(AppConstants.trueText);
            setFormErrors(formErrors.errors = { email: AppConstants.emptyString });
            setEmailOtp(AppConstants.emptyString);
        }
        if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
            setEmailVerifyButtonDisable(false);
            setEmailVerified(AppConstants.trueText);
            setFormErrors(formErrors.errors = { email: AppConstants.emptyString });
        }
        if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
            setEmailVerifyButtonDisable(false);
            setFormErrors(formErrors.errors = { email: ErrorMessages.signupDuplicateEmailError });
        }
        if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
            setEmailVerifyButtonDisable(false);
            setFormErrors(formErrors.errors = { email: ErrorMessages.signupGenerateMobileOtpError });
        }
    }

    //show verify mobile screen
    // async function handleVerifyMobile() {
    //     const url = AppConstants.signupPageGenerateMobileOtpAPI;
    //     const mobileWithCountryCode = callingCode[0]?.countryCallingCode + signupFormValues.mobileNumber;
    //     const data = { "mobileNumber": mobileWithCountryCode?.replace("+", "") };
    //     const response = await DataService.post(
    //         url,
    //         data,
    //         AppConstants.emptyString,
    //         AppConstants.emptyString
    //     );
    //     if (response?.status === AppConstants.httpResponseCodes.responseCode201) {
    //         setMobileVerificationScreen(AppConstants.trueText);
    //         setEmailVerificationScreen(AppConstants.falseText);
    //         setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
    //         setMobileOtp(AppConstants.emptyString);
    //     }
    //     if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
    //         setMobileVerified(AppConstants.trueText);
    //         setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
    //     }
    //     if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
    //         setFormErrors(formErrors.errors = { mobileNumber: ErrorMessages.signupGenerateMobileOtpError });
    //     }
    // }

    //show signup form
    function backToForm() {
        setMobileVerificationScreen(AppConstants.falseText);
        setEmailVerificationScreen(AppConstants.falseText);
    }

    //handle email change for verification
    function handleEmailChange(e) {
        const value = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (emailRegex.test(value)) {
            setShowVerifyEmailText(AppConstants.trueText);
            setEmailVerified(AppConstants.falseText);
        } else {
            setShowVerifyEmailText(AppConstants.falseText);
            setEmailVerified(AppConstants.falseText);
        }
    };

    const sendResetPasswordLink = async (e) => {
        e.preventDefault();
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
            if (emailRegex.test(loginFormValues.emailId)) {
                setResetPasswordSpinner(AppConstants.trueText);
                const url = `${AppConstants.sendResetPasswordLinkAPI}${loginFormValues.emailId}`;
                const response = await DataService.post(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                        setResetPasswordSpinner(AppConstants.falseText);
                        setShowSuccess(AppConstants.trueText);
                        setResetPasswordMessage(`${SuccessMessage.resetPasswordMailSendSuccessfully}${AppConstants.space}${loginFormValues.emailId}`);
                        const timer = setTimeout(() => {
                            setShowSuccess(AppConstants.falseText);
                            setResetPasswordMessage(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    } else if (response.status === 500) {
                        setShowSuccess(AppConstants.falseText);
                        setResetPasswordMessage(AppConstants.emptyString);
                        setShowError(ErrorMessages.emailVerificationError);
                        setResetPasswordSpinner(AppConstants.falseText);
                        const timer = setTimeout(() => {
                            setShowError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    }
                } else {
                    setShowSuccess(false);
                    setResetPasswordSpinner(false);
                    setResetPasswordMessage(AppConstants.emptyString);
                    setShowError(ErrorMessages.passwordLinkFailedError);
                    const timer = setTimeout(() => {
                        setShowError(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => clearTimeout(timer);
                }
            } else {
                setShowSuccess(false);
                setResetPasswordSpinner(false);
                setResetPasswordMessage(AppConstants.emptyString);
                setShowError(ErrorMessages.invalidEmailFormat);
                const timer = setTimeout(() => {
                    setShowError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => clearTimeout(timer);
            }
        } catch (error) {
            setShowSuccess(false);
            setResetPasswordSpinner(false);
            setResetPasswordMessage(AppConstants.emptyString);
            setShowError(ErrorMessages.passwordLinkFailedError);
            const timer = setTimeout(() => {
                setShowError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
        }
    };


    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            async function postUserDetails() {
                setShowSpinner(true);

                const trimmedFormValues = { ...signupFormValues, capitalCity: capitalCity, countryName: countryName };
                trimmedFormValues.firstName = trimmedFormValues.firstName.trim();
                trimmedFormValues.lastName = trimmedFormValues.lastName.trim();

                const url = AppConstants.postStudentDetailsAPI;
                const data = trimmedFormValues;
                const response = await DataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (!response.ok) {
                    if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                        setSignupFormError(ErrorMessages.signupDuplicateEmailError);
                        setShowSuccess(AppConstants.emptyString);
                        setShowSpinner(false);
                        const timer = setTimeout(() => {
                            setSignupFormError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    }
                    else if (response.status === AppConstants.httpResponseCodes.responseCode400) {
                        setSignupFormError(ErrorMessages.signupInvalidReferralError);
                        setShowSuccess(AppConstants.emptyString);
                        setShowSpinner(false);
                        const timer = setTimeout(() => {
                            setSignupFormError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    }
                    else if (response.status !== AppConstants.httpResponseCodes.responseCode400 && response.status !== AppConstants.httpResponseCodes.responseCode409) {
                        setSignupFormError(ErrorMessages.signupError);
                        setShowSuccess(AppConstants.emptyString);
                        const timer = setTimeout(() => {
                            setSignupFormError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    }
                }
                else if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
                    setShowSuccess(SuccessMessage.signupSuccess);
                    setSignupFormError(AppConstants.emptyString);
                    setShowSpinner(false);
                    setIsSubmit(false);
                    loginUserAfterSignup()
                    setCallingCode(countriesList.filter((item) => item.id === initialSignupValues.countryId));
                    const timer = setTimeout(() => {
                        setShowSuccess(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => clearTimeout(timer);
                }
                return null;
            };
            postUserDetails();
        }
    }, [formErrors]);

    //handle mobile change for verification
    function handleMobileChange(e) {
        const value = e.target.value;
        const indianMobileRegex = /^\d{10}$/;
        if (signupFormValues?.countryId === AppConstants.countryIdIndia) {
            if (indianMobileRegex.test(value)) {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.trueText);
                // setMobileVerified(AppConstants.falseText);
            } else {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.falseText);
                // setMobileVerified(AppConstants.falseText);
            }
        } else if (signupFormValues?.countryId !== AppConstants.countryIdIndia) {
            if (value?.length >= AppConstants.nonIndianMinMobileNumber && value?.length <= AppConstants.nonIndianMaxMobile) {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.trueText);
                // setMobileVerified(AppConstants.falseText);
            } else {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.falseText);
                // setMobileVerified(AppConstants.falseText);
            }
        }
    }

    //on country change handle
    useEffect(() => {
        function handleCountryChange() {
            if (signupFormValues?.mobileNumber) {
                const indianMobileRegex = /^\d{10}$/;
                if (signupFormValues?.countryId === AppConstants.countryIdIndia) {
                    if (indianMobileRegex.test(signupFormValues?.mobileNumber)) {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.trueText);
                        // setMobileVerified(AppConstants.falseText);
                    } else {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.falseText);
                        // setMobileVerified(AppConstants.falseText);
                    }
                } else if (signupFormValues?.countryId !== AppConstants.countryIdIndia) {
                    if (signupFormValues?.mobileNumber?.length >= AppConstants.nonIndianMinMobileNumber && signupFormValues?.mobileNumber?.length <= AppConstants.nonIndianMaxMobile) {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.trueText);
                        // setMobileVerified(AppConstants.falseText);
                    } else {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.falseText);
                        // setMobileVerified(AppConstants.falseText);
                    }
                }
            }
        }
        handleCountryChange();
    }, [signupFormValues?.countryId])

    //resend OTP button
    const renderButton = buttonProps => {
        return (
            <button className={signupClasses.resendOtpButton} {...buttonProps}>
                {buttonProps.remainingTime !== 0
                    ? `Resend code (in ${buttonProps.remainingTime} sec)`
                    : "Resend"}
            </button>
        )
    }
    const renderTime = () => React.Fragment;

    const validate = (values) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const nameRegex = /^[a-zA-Z ]*$/;
        const indianMobileRegex = /^\d{10}$/;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const dob = new Date(values.dob);
        dob.setHours(AppConstants.zeroIndex, AppConstants.zeroIndex, AppConstants.zeroIndex, AppConstants.zeroIndex);
        if (!values.firstName.trim()) {
            errors.firstName = ErrorMessages.invalidUserFirstName;
        }
        if (!values.lastName.trim()) {
            errors.lastName = ErrorMessages.invalidUserLastName;
        }
        if (!nameRegex.test(values.firstName)) {
            errors.firstName = ErrorMessages.invalidUserFirstNameWithRegex;
        }
        if (!nameRegex.test(values.lastName)) {
            errors.lastName = ErrorMessages.invalidUserLastNameWithRegex;
        }
        if (values.gender === AppConstants.emptyString) {
            errors.gender = ErrorMessages.invalidUserGender;
        }
        if (!emailRegex.test(values.email)) {
            errors.email = ErrorMessages.invalidEmailFormat;
        } else {
            if (!emailVerified) {
                errors.email = ErrorMessages.signupVerifyEmailError;
            }
        }
        if (!values.password.trim()) {
            errors.password = ErrorMessages.invalidPassword;
        }
        if (values.countryId === AppConstants.countryIdIndia) {
            if (!indianMobileRegex.test(values.mobileNumber)) {
                errors.mobileNumber = ErrorMessages.invalidMobileIndian;
            } else {
                if (!mobileVerified) {
                    errors.mobileNumber = ErrorMessages.signupVerifyMobileError;
                }
            }
        } else if (values.countryId !== AppConstants.countryIdIndia) {
            if (values.mobileNumber.length < AppConstants.nonIndianMinMobileNumber || values.mobileNumber.length > AppConstants.nonIndianMaxMobile) {
                errors.mobileNumber = ErrorMessages.invalidMobileNonIndian;
            } else {
                if (!mobileVerified) {
                    errors.mobileNumber = ErrorMessages.signupVerifyMobileError;
                }
            }
        }
        if (values.dob !== "" && values.dob !== null && values.dob !== undefined) {
            if (new Date(values.dob) >= currentDate) {
                errors.dob = ErrorMessages.signupDateOfBirthGreaterThanToday;
            }
            else if (DateTime.fromJSDate(new Date(currentDate)).diff(DateTime.fromJSDate(new Date(dob)), 'years').years < signupMinimumAge) {
                if (signupMinimumAge === 1) {
                    errors.dob = ErrorMessages.signupDateOfBirthLessThanAllowedYears + signupMinimumAge + " year" + ErrorMessages.signupDateOfBirthLessThanAllowedYearsPart2;
                } else {
                    errors.dob = ErrorMessages.signupDateOfBirthLessThanAllowedYears + signupMinimumAge + " years" + ErrorMessages.signupDateOfBirthLessThanAllowedYearsPart2;
                }
            }
        } else {
            errors.dob = ErrorMessages.signupInvalidDateOfBirth;
        }
        return errors;
    };

    const registerUser = (e) => {
        e.preventDefault();
        setFormErrors(validate(signupFormValues));
        setIsSubmit(true);
    };
    //verify email OTP
    useEffect(() => {
        async function handleVerifyEmailOtp() {
            if (emailOtp?.length === 6) {
                const url = AppConstants.verifyEmailOtpSignUpAPI;
                const data = { "email": signupFormValues.email, "otp": emailOtp };
                const response = await DataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
                    setEmailOtp(AppConstants.emptyString);
                    setMobileVerificationScreen(AppConstants.falseText);
                    setEmailVerificationScreen(AppConstants.falseText);
                    setEmailVerified(AppConstants.trueText);
                    setFormErrors(formErrors.errors = { emailOtpError: AppConstants.nullText });
                }
                if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
                    setMobileVerificationScreen(AppConstants.falseText);
                    setEmailVerificationScreen(AppConstants.trueText);
                    setEmailVerified(AppConstants.falseText);
                    setFormErrors(formErrors.errors = { emailOtpError: ErrorMessages.signupEmailOtpVerifyError });
                }
                if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
                    setMobileVerificationScreen(AppConstants.falseText);
                    setEmailVerificationScreen(AppConstants.trueText);
                    setEmailVerified(AppConstants.falseText);
                    setFormErrors(formErrors.errors = { emailOtpError: ErrorMessages.signupEmailOtpVerifyError });
                }
            }
        }
        handleVerifyEmailOtp();
    }, [emailOtp])


    const validateMobileNumber = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    //verify mobile OTP
    // useEffect(() => {
    //     async function handleVerifyMobileOtp() {
    //         if (mobileOtp?.length === 6) {
    //             const url = AppConstants.signupPageVerifyMobileAPI;
    //             const mobileWithCountryCode = callingCode[0]?.countryCallingCode + signupFormValues.mobileNumber;
    //             const data = { "mobileNumber": mobileWithCountryCode?.replace("+", ""), "otp": mobileOtp };
    //             const response = await DataService.post(
    //                 url,
    //                 data,
    //                 AppConstants.emptyString,
    //                 AppConstants.emptyString
    //             );
    //             if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
    //                 setMobileOtp(AppConstants.emptyString);
    //                 setMobileVerificationScreen(AppConstants.falseText);
    //                 setEmailVerificationScreen(AppConstants.falseText);
    //                 setMobileVerified(AppConstants.trueText);
    //                 setFormErrors(formErrors.errors = { mobileOtpError: AppConstants.nullText });
    //             }
    //             if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
    //                 setMobileVerificationScreen(AppConstants.trueText);
    //                 setEmailVerificationScreen(AppConstants.falseText);
    //                 setMobileVerified(AppConstants.falseText);
    //                 setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
    //             }
    //             if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
    //                 setMobileVerificationScreen(AppConstants.trueText);
    //                 setEmailVerificationScreen(AppConstants.falseText);
    //                 setMobileVerified(AppConstants.falseText);
    //                 setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
    //             }
    //         }
    //     }
    //     handleVerifyMobileOtp();
    // }, [mobileOtp])

    return (
        <>
            {
                !Auth.isLogin() && !showSignupForm &&
                <Modal show={onShow} onHide={onClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header closeButton className={loginFormClasses.loginModalHeader}>
                        <Modal.Title className={loginFormClasses.loginModalSubtitle}>{props.loginMessage ? props.loginMessage : "Login"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={classes.modalBody}>
                        <form onSubmit={loginUser}>
                            <div className={loginFormClasses.searchFormElementsSection}>
                                <div>
                                    {showError && <p className="errorMessage"><RiErrorWarningLine />{showError}</p>}
                                    {resetPasswordMessage && <p className="successMessage"><AiOutlineCheckCircle />{resetPasswordMessage}</p>}
                                </div>
                                <div>
                                    <Form.Label htmlFor="login-email" className={formClass.formLabel}>Email</Form.Label>
                                    <Form.Control id="login-email" value={loginFormValues.emailId} onChange={handleChange} name="emailId" className={formClass.formInput} type="email" required />
                                </div>
                                <div>
                                    <Form.Label htmlFor="login-password" className={formClass.formLabel}>Password</Form.Label>
                                    <Form.Control id="login-password" value={loginFormValues.password} onChange={handleChange} name="password" className={formClass.formInput} minLength={8} maxLength={16} type={passwordType} required />
                                    <div className={loginFormClasses.showHidePasswordIcon}>
                                        {passwordType === AppConstants.fieldTypePassword ? <FaEye onClick={togglePassword} /> : <FaEyeSlash onClick={togglePassword} />}
                                    </div>
                                </div>
                                <div className={classes.forgotPasswordContainer}>
                                    {resetPasswordSpinner ? <p className={classes.forgotPasswordText}>Sending Link <BiLoaderAlt className="spinner" /></p> : <p onClick={sendResetPasswordLink} className={classes.forgotPasswordText}>Forgot Password?</p>}
                                </div>
                                <div>
                                    {showSpinner && <Button type="button" className={loginFormClasses.loginButton}><BiLoaderAlt className={loginFormClasses.spinner} /></Button>}
                                    {!showSpinner && <Button type="submit" className={loginFormClasses.loginButton}>Login</Button>}
                                </div>
                                <div className={loginFormClasses.tncTextContainer}>
                                    <p className={`${loginFormClasses.tncText}  ${loginFormClasses.footerLoginText} mt-1`}>New to Qrencia? <a onClick={() => setShowSignupForm(AppConstants.trueText)} className={loginFormClasses.tncTextHyper}>Sign up</a></p>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            }
            {
                !Auth.isLogin() && showSignupForm &&
                <Modal show={onShow} onHide={onClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header closeButton className={loginFormClasses.loginModalHeader}>
                        <Modal.Title className={loginFormClasses.loginModalSubtitle}>{props.signupMessage ? props.signupMessage : AppConstants.signupHeadingText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={classes.modalBody}>
                        <form id="signUpForm" onSubmit={registerUser}>
                            <div className={signupClasses.searchFormElementsSection}>
                                <div className={signupClasses.nameSection}>
                                    <div className={signupClasses.nameField}>
                                        <div className={signupClasses.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterFirstNameLabelText}</Form.Label>
                                            <Form.Control className={`${formClass.formInput} ${signupClasses.formInputBox}`} name="firstName" value={signupFormValues.firstName} onChange={handleSignupFormChange} minLength={3} maxLength={40} type="text" required />
                                        </div>
                                        <p className={signupClasses.errorMessage}>{formErrors.firstName}</p>
                                    </div>
                                    <div className={signupClasses.nameField}>
                                        <div className={signupClasses.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterLastNameLabelText}</Form.Label>
                                            <Form.Control name="lastName" value={signupFormValues.lastName} onChange={handleSignupFormChange} minLength={3} maxLength={40} className={`${formClass.formInput} ${signupClasses.formInputBox}`} type="text" required />
                                        </div>
                                        <p className={signupClasses.errorMessage}>{formErrors.lastName}</p>
                                    </div>
                                </div>
                                <div className={signupClasses.nameSection}>
                                    <div className={signupClasses.nameField}>
                                        <div className={signupClasses.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterDobSignup}<span data-tooltip-place="top" data-tooltip-id="dobTooltip"><FaInfo className={signupClasses.iButton} /></span></Form.Label>
                                            <Form.Control name="dob" value={signupFormValues.dob} onChange={handleSignupFormChange} className={`${formClass.formInput} ${signupClasses.formInputBox}`} type="date" required />
                                            <Tooltip className={signupClasses.tooltipMessage} id="dobTooltip"><p>{AppConstants.iButtonMessageForDOB}</p></Tooltip>
                                        </div>
                                        <p className={signupClasses.errorMessage}>{formErrors.dob}</p>
                                    </div>
                                    <div className={signupClasses.nameField}>
                                        <div className={classes.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>Gender</Form.Label>
                                            <Form.Select className={formClass.selectInput} value={signupFormValues.gender} onChange={handleSignupFormChange} name="gender">
                                                <option value={AppConstants.emptyString}>Please select gender</option>
                                                {AppConstants.gender.map((gender) => (
                                                    <option value={gender} key={gender}>{gender}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        <p className={signupClasses.errorMessage}>{formErrors.gender}</p>
                                    </div>
                                </div>

                                {/* <div className={signupClasses.inputContainer}>
                                    <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterDobSignup} <span data-tooltip-place="top" data-tooltip-id="dobTooltip"><FaInfo className={signupClasses.iButton} /></span></Form.Label>
                                    <Form.Control name="dob" value={signupFormValues.dob} onChange={handleSignupFormChange} className={`${formClass.formInput} ${signupClasses.formInputBox}`} type="date" required />
                                    <Tooltip className={signupClasses.tooltipMessage} id="dobTooltip"><p>{AppConstants.iButtonMessageForDOB}</p></Tooltip>
                                </div>
                                <p className={signupClasses.errorMessage}>{formErrors.dob}</p> */}
                                {!emailVerificationScreen && <div className={`${signupClasses.inputContainer} ${signupClasses.positionRelative}`}>
                                    <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterEmailLabelText}
                                        <span data-tooltip-place="top" data-tooltip-id="emailToolTip"><FaInfo className={signupClasses.iButton} /></span></Form.Label>
                                    <Tooltip className={signupClasses.tooltipMessage} id="emailToolTip"><p>{AppConstants.iButtonMessageForEmail}</p></Tooltip>
                                    <Form.Control name="email" value={signupFormValues.email} onKeyUp={handleEmailChange} onChange={handleSignupFormChange} className={`${formClass.formInput} ${signupClasses.formInputBox}`} type="email" required disabled={emailVerified} />
                                    <div className={signupClasses.verifyOtpText}>{!emailVerified && showVerifyEmailText && <p onClick={!emailVerifyButtonDisable ? handleVerifyEmail : null} style={{
                                        cursor: emailVerifyButtonDisable ? "not-allowed" : "pointer"
                                    }}>Verify</p>} {emailVerified && <p><FaCheckCircle color="green" fontSize="1.2em" /></p>}</div>
                                </div>}
                                {!emailVerificationScreen && <p className={signupClasses.errorMessage}>{formErrors.email}</p>}

                                {emailVerificationScreen && <div>
                                    <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterEmailLabelText}</Form.Label>
                                    <div className={signupClasses.verifyEmailContainer}>
                                        <div className={signupClasses.emailContainer}>
                                            <p className={signupClasses.emailDisabledText}>{signupFormValues.email}</p>
                                            <div onClick={backToForm} className={signupClasses.editEmailText}><FaEdit /> Edit</div>
                                        </div>
                                        <div className={`${signupClasses.emailOtpInputContainer}`}>
                                            <OTPInput
                                                value={emailOtp}
                                                onChange={setEmailOtp}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                className={signupClasses.emailOtpInputBox}
                                            />
                                            <ResendOTP onResendClick={() => handleVerifyEmail()} renderButton={renderButton} renderTime={renderTime} />
                                        </div>
                                        {formErrors.emailOtpError ? <p className={signupClasses.errorMessage}>{formErrors.emailOtpError}</p> : <p className={signupClasses.otpInfoText}>Please enter the 6-digit code sent on your email</p>}
                                    </div>
                                </div>}

                                {!emailVerificationScreen && <div className={`${signupClasses.inputContainer} ${signupClasses.positionRelative}`}>
                                    <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterPasswordLabelText}</Form.Label>
                                    <Form.Control name="password" value={signupFormValues.password} onChange={handleSignupFormChange} className={`${formClass.formInput} ${signupClasses.formInputBox}`} minLength={8} maxLength={16} type={passwordType} required />
                                    <div className={signupClasses.showHidePasswordIcon}>{passwordType === AppConstants.fieldTypePassword ? <FaEye onClick={togglePassword} /> : <FaEyeSlash onClick={togglePassword} />}</div>
                                </div>}
                                {!emailVerificationScreen && <p className={signupClasses.errorMessage}>{formErrors.password}</p>}
                                {!emailVerificationScreen && <div className={signupClasses.selectCountry}>
                                    <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.selectCountryLabelText}</Form.Label>
                                    <Form.Select className={`${formClass.selectInput} ${signupClasses.formInputBox}`} value={signupFormValues.countryId} name="countryId" onChange={e => { handleSignupFormChange(e); onSelectingCountry(e); changeCountryCapital(e); }} required>
                                        <option value="" defaultValue></option>
                                        {countriesList.map((countries) => (
                                            <option value={countries.id} key={countries.id}>{countries.country}</option>
                                        ))}
                                    </Form.Select>
                                </div>}

                                {!emailVerificationScreen && !mobileVerificationScreen && <div className={`${signupClasses.inputContainer} ${signupClasses.positionRelative}`}>
                                    <label className={`${signupClasses.mobileLabel} ${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterMobileText}
                                        <span data-tooltip-place="top" data-tooltip-id="mobileToolTip"><FaInfo className={signupClasses.iButton} /></span></label>
                                    <Tooltip className={signupClasses.tooltipMessage} id="mobileToolTip"><p>{AppConstants.iButtonMessageForMobile}</p></Tooltip>
                                    <div className={`${signupClasses.unitInput}`} >
                                        <span className={`${signupClasses.unitInputPrepend} ${callingCode.length === 0 ? signupClasses.displayNone : signupClasses.unitInputPrepend}`}>{callingCode.length === 0 ? null : callingCode[0].countryCallingCode}</span>
                                        <input name="mobileNumber" value={signupFormValues.mobileNumber} onKeyUp={handleMobileChange} onChange={e => { handleSignupFormChange(e); handleChangeMobileNumber(e) }} min={0} step={1} className={`${signupClasses.formInputBox} ${signupClasses.unitInputBox}`} onKeyDown={validateMobileNumber} type="number" required />
                                    </div>
                                    {/* <div className={signupClasses.verifyOtpText}>{!mobileVerified && showVerifyMobileText && <p onClick={handleVerifyMobile}>Verify</p>} {mobileVerified && <p><FaCheckCircle color="green" fontSize="1.2em" /></p>}</div> */}
                                </div>}
                                {!emailVerificationScreen && !mobileVerificationScreen && <p className={signupClasses.errorMessage}>{formErrors.mobileNumber}</p>}

                                {/* {mobileVerificationScreen && <div>
                                    <Form.Label className={`${formClass.formLabel} ${signupClasses.inputLabel}`}>{AppConstants.enterMobileText}</Form.Label>
                                    <div className={signupClasses.verifyEmailContainer}>
                                        <div className={signupClasses.emailContainer}>
                                            <p className={signupClasses.emailDisabledText}>{callingCode[0]?.countryCallingCode + "-" + signupFormValues.mobileNumber}</p>
                                            <div onClick={backToForm} className={signupClasses.editEmailText}><FaEdit /> Edit</div>
                                        </div>
                                        <div className={`${signupClasses.emailOtpInputContainer}`}>
                                            <OTPInput
                                                value={mobileOtp}
                                                onChange={setMobileOtp}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                className={signupClasses.emailOtpInputBox}
                                            />
                                            <ResendOTP onResendClick={() => handleVerifyMobile()} renderButton={renderButton} renderTime={renderTime} />
                                        </div>
                                        {formErrors.mobileOtpError ? <p className={signupClasses.errorMessage}>{formErrors.mobileOtpError}</p> : <p className={signupClasses.otpInfoText}>Please enter the 6-digit code sent on your mobile number</p>}
                                    </div>
                                </div>} */}
                                {!emailVerificationScreen && !mobileVerificationScreen && <div>
                                    {showSpinner && <Button className={signupClasses.signupButton}><BiLoaderAlt className={signupClasses.spinner} /></Button>}
                                    {!showSpinner && <Button type="submit" value="Submit" className={signupClasses.signupButton}>Sign Up</Button>}
                                </div>}
                                <div>
                                    <p className={`${signupFormError.length === 0 ? signupClasses.displayNone : signupClasses.errorMessageText}`}>{signupFormError}</p>
                                </div>
                                {!emailVerificationScreen && !mobileVerificationScreen && <div className={loginFormClasses.tncTextContainer}>
                                    <p className={loginFormClasses.tncText}>By Signing Up, you agree to Qrencia <NavLink target="_blank" className={loginFormClasses.tncTextHyper} to={AppConstants.termsAndConditions}>Terms & Conditions</NavLink>
                                        &nbsp;and <NavLink target="_blank" className={loginFormClasses.tncTextHyper} to={AppConstants.privacyPolicy}>Privacy Policy</NavLink></p>
                                </div>}
                                <div className={loginFormClasses.tncTextContainer}>
                                    <p className={`${loginFormClasses.tncText} ${loginFormClasses.footerLoginText} mt-1`}>Already on Qrencia? <a onClick={() => setShowSignupForm(AppConstants.falseText)} className={loginFormClasses.tncTextHyper}>Log in</a></p>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

            }
        </>
    );
}