import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { CgPin } from "react-icons/cg";
import { Tooltip } from 'react-tooltip';
import classes from './IndividualSkillsCompetitionCards.module.css';
import { BiWallet } from 'react-icons/bi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import Auth from "../../../utils/Auth";
import { DateTime } from "luxon";
import Moment from 'react-moment';
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { BsArrowDownCircle } from 'react-icons/bs';
import { LoginModal } from "../../modal/LoginModal";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { TbShare3 } from "react-icons/tb";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";

export const IndividualSkillsWorkshopCards = (props) => {
    const [cardCount, setCardCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.allActivitiesForSkill);
    const [paramData, setParamData] = useState({});
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [paymentParam, setPaymentParam] = useState(searchParams.get("p"));
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const navigate = useNavigate();
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [editIndex, setEditIndex] = useState(null);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.individualSkillsCompetitionBannerPath}`;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultThumbnailImage}`;

    useEffect(() => {
        setCardDetails(props.allActivitiesForSkill);
    }, [props.allActivitiesForSkill]);


    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(AppConstants.cardCounts.skillPageWorkshopPageWebCardsCount);
            setViewMoreCardsCount(AppConstants.cardCounts.skillPageWorkshopPageWebCardsCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(AppConstants.cardCounts.skillPageWorkshopPageTabCardsCount);
            setViewMoreCardsCount(AppConstants.cardCounts.skillPageWorkshopPageTabCardsCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(AppConstants.cardCounts.skillPageWorkshopPageMobileCardsCount);
            setViewMoreCardsCount(AppConstants.cardCounts.skillPageWorkshopPageMobileCardsCount);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);


    const handleLoginModalClose = () => {
        setShowLoginModal(AppConstants.falseText);
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                navigate(AppConstants.studentWorkshopsPath);
            } else {
                window.location.reload();
            }
        }
    };

    const handleLoginModalShow = () => {
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            navigate(AppConstants.studentWorkshopsPath);
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    };

    useEffect(() => {
        const verifyPayment = async (data, workshop) => {
            try {
                PaymentsUtil.verifyPayment(data).then((response) => {
                    if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                        setPaymentStatus(AppConstants.paymentStatus.verified);
                        setTransactionId(response.transactionId);
                        setModalTypeError(AppConstants.falseText);
                        window.history.pushState(null, null, window.location.href.split("?")[0]);
                        enrolInWorkshop(workshop.workshopId, workshop.workshopTitle, workshop.uniqueId, AppConstants.paymentStatus.verified);
                    } else {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(response.message);
                        setTransactionId(response.transactionId);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(null, null, window.location.href.split("?")[0]);
                        handleModalShow();
                    }
                }).catch((error) => {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.verifyPaymentFailedError);
                    setPaymentStatus(AppConstants.emptyString);
                    window.history.pushState(null, null, window.location.href.split("?")[0]);
                    handleModalShow();
                });
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }
        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return AppConstants.nullText;
            }
        };

        if (paymentParam && cardDetails.length > 0) {
            const data = decodeBase64EncodedString(paymentParam);
            if (data !== AppConstants.nullText && data.category === AppConstants.learningActivitiesTypes.workshop) {
                const workshop = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!workshop) {
                    setParamData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                data.learningActivityName = workshop.workshopTitle;
                setParamData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, workshop);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }
    }, [paymentParam, cardDetails]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.workshop, creatorName, creatorRole, payOfflineFlag });
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInWorkshop(id, title, uniqueId);
                }
            }
        } else {
            enrolInWorkshop(id, title, uniqueId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);

            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInWorkshop = async (id, title, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.workshopEnrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }

        const workshop = cardDetails.filter(card => card.uniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: workshop.cost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status)
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    }

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setTransactionId(response.transactionId);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    enrolInWorkshop(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const handleModalClose = () => {
        if (paymentParam) {
            window.location.replace(window.location.href.split("?")[0]);
        } else if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                if (modalTypeError) {
                    setShowModal(AppConstants.falseText);
                    setConfirmationPopupActivityDetails({});
                } else {
                    window.location.reload();
                }
            }
        }
    }

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${shareData.workshopLink}`);
    };

    const navigateToGeolocation = (activityCard) => {
        if (activityCard.modeOfDelivery?.toLowerCase() === AppConstants.competitionTypeOffline.toLowerCase()) {
            window.open(activityCard.geoLocation, AppConstants.openInNewTab);
        }
    }

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={2} xl={2} className={`${classes.workshopActivityCard} mt-3`}>
                {cardDetails !== null && cardDetails?.slice(0, cardCount).map((activityCard, index) => (
                    <Col className={classes.workshopActivityCardSection}>
                        <Card key={activityCard.uniqueId} className={classes.workshopActivityMainCard} onClick={() => handleCardClick(`${process.env.REACT_APP_FRONT_END_URL}${activityCard.workshopLink}`)}>
                            <Card.Img variant="top" src={defaultWebBanner} className={`${classes.defaultBannerImage} ${classes.workshopBanner}`} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(activityCard) }} />
                            <Card.Body className={classes.workshopActivityCardBody}>
                                <Card.Img variant="top" src={activityCard.workshopThumbnailImagePath || defaultThumbnail} className={`${classes.competitionThumbnailImage} ${classes.workshopBanner}`} />
                                <div className={classes.workshopCardImageSection}>
                                    <div className={classes.workshopInnerCardImageSection}>
                                        <Card.Img className={classes.workshopImage} variant="top" src={activityCard.workshopThumbnailImagePath || defaultThumbnail} />
                                    </div>
                                </div>
                                <div className={classes.workshopCardContentSection}>
                                    <div className={classes.workshopTitleSection}>
                                        <Card.Text className={`${classes.cardTitle} titleFixedHeight`}>
                                            <Card.Title data-tooltip-id={`${activityCard.uniqueId}-title`} className={classes.activityCardTitle}><Link className={classes.activityCardTitleLink}>{activityCard.workshopTitle}</Link></Card.Title>
                                            <Tooltip className="courseTitleTooltip" id={`${activityCard.uniqueId}-title`}><span>{activityCard.workshopTitle}</span></Tooltip>
                                        </Card.Text>
                                        {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                            <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                        </div>}
                                    </div>
                                    {activityCard.userRole === "Expert" && <div onClick={stopPropagation} className={classes.workshopActivityOrganizer}>By&nbsp;<Link to={`${AppConstants.forwardSlash}${AppConstants.expert}${AppConstants.forwardSlash}${activityCard.userSlug}`} className={classes.workshopActivityOrganizerLink}>{activityCard.organizer}</Link></div>}
                                    {activityCard.userRole === "Agency" && <div onClick={stopPropagation} className={classes.workshopActivityOrganizer}>By&nbsp;<Link to={`${AppConstants.forwardSlash}${AppConstants.agency}${AppConstants.forwardSlash}${activityCard.userSlug}`} className={classes.workshopActivityOrganizerLink}>{activityCard.organizer}</Link></div>}
                                    <div className={classes.workshopDateContainer}>
                                        <p className={classes.workshopDateText}>
                                            <AiOutlineCalendar className={classes.cardIcon} /> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{activityCard.utcStartDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment>, <Moment format="Do MMM YYYY" tz={timeZone}>{activityCard.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> - <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{activityCard.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment>, <Moment format="Do MMM YYYY" tz={timeZone}>{activityCard.utcEndDate.replace(' ', 'T') + 'Z'}</Moment>{activityCard.preferredTimezone === AppConstants.utcTimezone && `(${activityCard.preferredTimezone})`}</p>
                                    </div>
                                    <div className="buttonSection">
                                        <div className={classes.workshopModeSection}>
                                            <div className={classes.workshopModeInfoBox} data-tooltip-id={activityCard.uniqueId}>
                                                {activityCard.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLowerCase() && <div className={classes.iconTextContainer} onClick={e => { stopPropagation(e); navigateToGeolocation(activityCard) }}><CgPin />&nbsp;<p className={classes.costIconText}>{activityCard.modeOfDelivery}</p></div>}
                                                {activityCard.modeOfDelivery.toLowerCase() === AppConstants.onlineText.toLowerCase() && <div className={classes.iconTextContainer}><CgPin />&nbsp;<p className={classes.costIconText}>{activityCard.modeOfDelivery}</p></div>}
                                            </div>&nbsp;&nbsp;
                                            <div className={classes.workshopModeInfoBox}>
                                                <BiWallet />&nbsp;<p className={classes.costIconText}>{PaymentsUtil.calculateCost({ cost: activityCard.cost, discount: 0 })}</p>
                                            </div>
                                            {activityCard.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLowerCase() && <Tooltip className="tooltipMessage" id={activityCard.uniqueId}><span className="tooltipMessage">{activityCard.address}</span></Tooltip>}
                                        </div>
                                        <Button type="button" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: activityCard.workshopId, title: activityCard.workshopTitle, uniqueId: activityCard.uniqueId, startTime: activityCard.utcStartDate, payOfflineFlag: activityCard.payOfflineFlag, creatorName: activityCard.organizer, creatorRole: activityCard.userRole }) }} className={classes.workshopCardButton}>Enrol</Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {props.allActivitiesForSkill !== null && <div className={classes.loadMoreButton} >
                {cardCount < props.allActivitiesForSkill?.length && <BsArrowDownCircle className={classes.loadMoreIcon} onClick={() => setCardCount(cardCount + viewMoreCardsCount)} />}
            </div>}
            {(props.allActivitiesForSkill !== null && props.allActivitiesForSkill?.length >= AppConstants.ISPLearningActivityCardsLimit && ((Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) || !Auth.isLogin())) && <div className={classes.exploreMoreButton}>
                {(cardCount >= props.allActivitiesForSkill.length) && <Button onClick={handleLoginModalShow}>{Auth.isLogin() ? `Explore More` : `Login to Explore`}</Button>}
            </div>}
            {(props.allActivitiesForSkill == null || props.allActivitiesForSkill?.length === 0) && <p className='noDataAvailableError'>{`No workshops for '${props.skill}' skill to display at the moment`}</p>}
            {(message !== AppConstants.emptyString || paramData.category === AppConstants.learningActivitiesTypes.workshop) && (showModal || paramData.category === AppConstants.learningActivitiesTypes.workshop) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} enrolInWorkshop={enrolInWorkshop} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showLoginModal && <LoginModal onShow={handleLoginModalShow} onClose={handleLoginModalClose} />}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}