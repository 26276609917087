import { useEffect, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { MdOpenInNew } from 'react-icons/md';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import classes from './LearningActivityDetailsModal.module.css';
import obrClasses from '../formControls/adminForms/createObrForms/AdminGetObrForEmailForm.module.css';
import { AppConstants } from '../../constants/AppConstants';
import moment from 'moment';
import modalClass from '../cards/CompetitionsCards.module.css';
import { GenerateLeadForm } from '../formControls/adminForms/generateLeadForms/GenerateLeadForm';
import Moment from 'react-moment';
import LeadStatus from '../leadsComponents/LeadStatus/LeadStatus';

export const LeadDetailsModal = ({
  selectedLead,
  closeLeadDetailsModal,
  countryList,
  stateList,
  onboardedFlag,
  skillDetails,
  refreshNonOnboardedLeads,
  refreshOnboardedLeads,
}) => {
  const [expertStatusFontColorClass, setExpertStatusFontColorClass] = useState(
    'expertObrStatusField'
  );
  const [showEditForm, setShowEditForm] = useState(AppConstants.falseText);
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

  useEffect(() => {
    function getCssClassForExpertObrStatus() {
      const defaultClassName = `${obrClasses.expertObrStatusField}`;
      let additionalClassName = AppConstants.emptyString;
      if (
        selectedLead.obrState === AppConstants.expertObrStatus.statusApproved
      ) {
        additionalClassName = `${obrClasses.expertObrStatusFieldGreen}`;
      } else if (
        selectedLead.obrState === AppConstants.expertObrStatus.statusRejected
      ) {
        additionalClassName = `${obrClasses.expertObrStatusFieldRed}`;
      } else if (
        selectedLead.obrState === AppConstants.expertObrStatus.statusDraft
      ) {
        additionalClassName = `${obrClasses.expertObrStatusFieldAmber}`;
      } else if (
        selectedLead.obrState === AppConstants.expertObrStatus.statusInReview
      ) {
        additionalClassName = `${obrClasses.expertObrStatusFieldBlue}`;
      }
      setExpertStatusFontColorClass(
        defaultClassName + AppConstants.space + additionalClassName
      );
    }
    getCssClassForExpertObrStatus();
  }, [selectedLead]);

  const closeEditModal = () => {
    closeLeadDetailsModal();
  };

  return (
    <div>
      <Modal
        size="xl"
        show={AppConstants.trueText}
        onHide={closeLeadDetailsModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className={modalClass.modalBody}>
          <Row className="mb-2">
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <div className={obrClasses.obrDetailsColumContainer}>
                <p className={obrClasses.expertDetailsLabelObrId}>
                  {onboardedFlag ? 'Qrencia Id' : 'Lead Id'}
                </p>
                <p className={obrClasses.expertDetailsText}>
                  {onboardedFlag
                    ? selectedLead.leadType === AppConstants.leadTypes.expert
                      ? selectedLead.expertQrenciaId
                      : selectedLead.agencyQrenciaId
                    : selectedLead.leadId}
                </p>
              </div>
            </Col>
            {/* {!onboardedFlag && selectedLead.obrState && <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                            <div className={obrClasses.obrDetailsColumContainer}>
                                <p className={obrClasses.expertDetailsLabelObrId}>OBR Status</p>
                                <p className={`${expertStatusFontColorClass}`}>{selectedLead.obrState}</p>
                            </div>
                        </Col>} */}
            {!onboardedFlag && (
              <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  {/* <p className={obrClasses.expertDetailsLabelObrId}>Lead Status</p> */}
                  {/* <p className={`${expertStatusFontColorClass}`}>{selectedLead.obrState}</p> */}
                  {/* {selectedLead.leadStatus !== null ? selectedLead.leadStatus : "Not Available"} */}

                  <LeadStatus status={selectedLead.leadStatus} />
                </div>
              </Col>
            )}
          </Row>
          <hr className={classes.obrDetailsColumHrContainer} />
          {!showEditForm && (
            <Row className={obrClasses.expertObrRowLeftBorder}>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>
                    {selectedLead.leadType === AppConstants.userRoleExpert
                      ? 'Expert Name'
                      : 'Agency Name'}
                  </p>
                  {
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.leadType === AppConstants.userRoleExpert
                        ? `${selectedLead.firstName} ${selectedLead.lastName}`
                        : selectedLead.instituteName}
                    </p>
                  }{' '}
                </div>
              </Col>
              {selectedLead.leadType !== AppConstants.leadTypes.expert && (
                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>SPOC Name</p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.firstName} {selectedLead.lastName}
                    </p>
                  </div>
                </Col>
              )}
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>Email</p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.email !== null
                      ? selectedLead.email
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              {selectedLead.selectedSkills !== AppConstants.nullText &&
                selectedLead.selectedSkills?.length > 0 && (
                  <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                    <div className={obrClasses.obrDetailsColumContainer}>
                      <p className={obrClasses.expertDetailsLabel}>Skills</p>
                      {selectedLead.selectedSkills !==
                        AppConstants.nullText && (
                        <ul className={obrClasses.skillsList}>
                          {selectedLead.selectedSkills.map((item, index) => (
                            <li
                              className={obrClasses.expertDetailsListElement}
                              key={index}
                            >
                              {item.skillName}
                              {index !==
                                selectedLead.selectedSkills.length - 1 && ', '}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </Col>
                )}
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>Phone Number</p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.phoneNumber !== null
                      ? `${selectedLead.countryMobileNumberCode}${selectedLead.phoneNumber}`
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              {selectedLead.leadType === AppConstants.leadTypes.expert && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>DOB</p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.dob !== null ? (
                        <Moment
                          format={AppConstants.competitionDatesNewDateFormat}
                        >
                          {selectedLead.dob}
                        </Moment>
                      ) : (
                        AppConstants.notAvailableText
                      )}
                    </p>
                  </div>
                </Col>
              )}
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>Lead Type</p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.leadType !== null
                      ? selectedLead.leadType
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>Source</p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.source !== null
                      ? selectedLead.source
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>
                    {selectedLead.leadType === AppConstants.leadTypes.expert
                      ? 'Country'
                      : 'SPOC Country'}
                  </p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.country !== null
                      ? selectedLead.country
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>
                    {selectedLead.leadType === AppConstants.leadTypes.expert
                      ? 'State'
                      : 'SPOC State'}
                  </p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.state !== null
                      ? selectedLead.state
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>
                    {selectedLead.leadType === AppConstants.leadTypes.expert
                      ? 'City'
                      : 'SPOC City'}
                  </p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.city !== null
                      ? selectedLead.city
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>
                    {selectedLead.leadType === AppConstants.leadTypes.expert
                      ? 'Top Expert'
                      : 'Top Agency'}
                  </p>
                  <p className={obrClasses.expertDetailsText}>
                    {selectedLead.topCreatorFlag !== null
                      ? selectedLead.topCreatorFlag
                      : AppConstants.notAvailableText}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>Rating</p>
                  {!onboardedFlag && (
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.rating !== null
                        ? selectedLead.rating
                        : AppConstants.notAvailableText}
                    </p>
                  )}
                  {onboardedFlag && (
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.leadType === AppConstants.leadTypes.expert
                        ? selectedLead.expertRating
                        : selectedLead.agencyRating}
                    </p>
                  )}
                </div>
              </Col>
              {/* {!onboardedFlag && <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                            <div className={obrClasses.obrDetailsColumContainer}>
                                <p className={obrClasses.expertDetailsLabel}>Current Status</p>
                                <p className={obrClasses.expertDetailsText}>{selectedLead.currentStatus !== null ? selectedLead.currentStatus : AppConstants.notAvailableText}</p>
                            </div>
                        </Col>} */}
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>Is OBR Sent</p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.isObrSentFlag === AppConstants.yText
                        ? selectedLead.isObrSentFlag
                        : AppConstants.nText}
                    </p>
                  </div>
                </Col>
              )}
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      OBR Link Status
                    </p>
                    {selectedLead.leadType ===
                      AppConstants.leadTypes.expert && (
                      <p className={obrClasses.expertDetailsText}>
                        {selectedLead.expertObrLinkExpireFlag !==
                        AppConstants.yText
                          ? 'Active'
                          : 'Expired'}
                      </p>
                    )}
                    {selectedLead.leadType !==
                      AppConstants.leadTypes.expert && (
                      <p className={obrClasses.expertDetailsText}>
                        {selectedLead.agencyObrLinkExpireFlag !==
                        AppConstants.yText
                          ? 'Active'
                          : 'Expired'}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className={obrClasses.obrDetailsColumContainer}>
                  <p className={obrClasses.expertDetailsLabel}>Created On</p>
                  {selectedLead.createdOn ? (
                    <p className={obrClasses.expertDetailsText}>
                      {moment
                        .utc(selectedLead.createdOn)
                        .tz(timeZone)
                        .format('D MMM YYYY, hh:mm A')}{' '}
                      {timeZone === AppConstants.utcTimezone && `(UTC)`}
                    </p>
                  ) : (
                    <p className={obrClasses.expertDetailsText}>
                      {AppConstants.notAvailableText}
                    </p>
                  )}
                </div>
              </Col>
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>Demo Date</p>
                    {selectedLead.demoDate ? (
                      <p className={obrClasses.expertDetailsText}>
                        {moment
                          .utc(selectedLead.demoDate)
                          .tz(timeZone)
                          .format('D MMM YYYY')}{' '}
                        {timeZone === AppConstants.utcTimezone && `(UTC)`}
                      </p>
                    ) : (
                      <p className={obrClasses.expertDetailsText}>
                        {AppConstants.notAvailableText}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      OBR Reminder Sent On
                    </p>
                    {selectedLead.reminderObrEmailSentOn ? (
                      <p className={obrClasses.expertDetailsText}>
                        {moment
                          .utc(selectedLead.reminderObrEmailSentOn)
                          .tz(timeZone)
                          .format('D MMM YYYY, hh:mm A')}{' '}
                        {timeZone === AppConstants.utcTimezone && `(UTC)`}
                      </p>
                    ) : (
                      <p className={obrClasses.expertDetailsText}>
                        {AppConstants.notAvailableText}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Payment Link Sent On
                    </p>
                    {selectedLead.paymentLinkMailSentOn ? (
                      <p className={obrClasses.expertDetailsText}>
                        {moment
                          .utc(selectedLead.paymentLinkMailSentOn)
                          .tz(timeZone)
                          .format('D MMM YYYY, hh:mm A')}{' '}
                        {timeZone === AppConstants.utcTimezone && `(UTC)`}
                      </p>
                    ) : (
                      <p className={obrClasses.expertDetailsText}>
                        {AppConstants.notAvailableText}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              {onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Onboarded On
                    </p>
                    {selectedLead.onboardedDate ? (
                      <p className={obrClasses.expertDetailsText}>
                        {moment
                          .utc(selectedLead.onboardedDate)
                          .tz(timeZone)
                          .format('D MMM YYYY, hh:mm A')}{' '}
                        {timeZone === AppConstants.utcTimezone && `(UTC)`}
                      </p>
                    ) : (
                      <p className={obrClasses.expertDetailsText}>
                        {AppConstants.notAvailableText}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Listing Enabled
                    </p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.listingEnabled !== null
                        ? selectedLead.listingEnabled
                        : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}
              {onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Offering Listed
                    </p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.offeringListedFlag !== null
                        ? selectedLead.offeringListedFlag
                        : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}
              {onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Physical Branding Installed
                    </p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.physicalBranding !== null
                        ? selectedLead.physicalBranding
                        : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}
              {onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Offering Reminder Sent On
                    </p>
                    {selectedLead.offeringsListedReminderEmailSentOn ? (
                      <p className={obrClasses.expertDetailsText}>
                        {moment
                          .utc(selectedLead.offeringsListedReminderEmailSentOn)
                          .tz(timeZone)
                          .format('D MMM YYYY, hh:mm A')}{' '}
                        {timeZone === AppConstants.utcTimezone && `(UTC)`}
                      </p>
                    ) : (
                      <p className={obrClasses.expertDetailsText}>
                        {AppConstants.notAvailableText}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              {!onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>OBR Id</p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.leadType === AppConstants.leadTypes.expert
                        ? selectedLead.expertObrId !== null
                          ? selectedLead.expertObrId
                          : AppConstants.notAvailableText
                        : selectedLead.agencyObrId
                          ? selectedLead.agencyObrId
                          : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}
              {onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Existing Business On Qrencia
                    </p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.isExistingBussinessOnQ !== null
                        ? selectedLead.isExistingBussinessOnQ
                        : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}
              {selectedLead.leadType === AppConstants.userRoleExpert && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Institute Name
                    </p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.instituteName
                        ? selectedLead.instituteName
                        : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}

              {onboardedFlag && (
                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>
                      Branding Page
                    </p>
                    {
                      <a
                        className={obrClasses.expertDetailsLinkLabel}
                        target={AppConstants.openInNewTab}
                        href={`${process.env.REACT_APP_FRONT_END_URL}${selectedLead.leadType === AppConstants.leadTypes.expert ? AppConstants.expert : AppConstants.agency}/${selectedLead.userSlug}`}
                      >
                        Click Here <MdOpenInNew />
                      </a>
                    }
                  </div>
                </Col>
              )}
              {!onboardedFlag &&
                selectedLead.listingEnabled === AppConstants.yText && (
                  <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={obrClasses.obrDetailsColumContainer}>
                      <p className={obrClasses.expertDetailsLabel}>
                        Listing Page
                      </p>
                      {
                        <a
                          className={obrClasses.expertDetailsLinkLabel}
                          target={AppConstants.openInNewTab}
                          href={`${process.env.REACT_APP_FRONT_END_URL}${selectedLead.leadType === AppConstants.leadTypes.expert ? AppConstants.expert : AppConstants.agency}/${selectedLead.userSlug}`}
                        >
                          Click Here <MdOpenInNew />
                        </a>
                      }
                    </div>
                  </Col>
                )}
              {selectedLead.websiteLink !== null && (
                <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>Website</p>
                    {selectedLead.websiteLink && (
                      <a
                        className={obrClasses.expertDetailsLinkLabel}
                        target={AppConstants.openInNewTab}
                        href={selectedLead.websiteLink}
                      >
                        Click Here <MdOpenInNew />
                      </a>
                    )}
                    {!selectedLead.websiteLink && (
                      <p className={obrClasses.expertDetailsText}>
                        {AppConstants.notAvailableText}
                      </p>
                    )}
                  </div>
                </Col>
              )}
              {!onboardedFlag && (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className={obrClasses.obrDetailsColumContainer}>
                    <p className={obrClasses.expertDetailsLabel}>Remarks</p>
                    <p className={obrClasses.expertDetailsText}>
                      {selectedLead.remarks !== null
                        ? selectedLead.remarks
                        : AppConstants.notAvailableText}
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          )}
          {showEditForm && (
            <Row>
              <GenerateLeadForm
                refreshNonOnboardedLeads={refreshNonOnboardedLeads}
                refreshOnboardedLeads={refreshOnboardedLeads}
                skillDetails={skillDetails}
                updateFlag={AppConstants.trueText}
                selectedLead={selectedLead}
                countryList={countryList}
                stateList={stateList}
                closeEditModal={closeEditModal}
                isOnboarded={onboardedFlag}
              />
            </Row>
          )}
          <div className={classes.buttonRow}>
            {!showEditForm &&
              ((!onboardedFlag &&
                selectedLead.obrState !==
                  AppConstants.expertObrStatus.statusAccepted &&
                selectedLead.obrState !==
                  AppConstants.expertObrStatus.statusRejected) ||
                onboardedFlag) && (
                <Button
                  className={modalClass.modalCloseButton}
                  onClick={() => setShowEditForm(AppConstants.trueText)}
                >
                  Edit
                </Button>
              )}
            {!showEditForm && (
              <Button
                className={modalClass.modalCloseButton}
                onClick={closeLeadDetailsModal}
              >
                Close
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
