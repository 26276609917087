import { EoiPageFooter } from '../../layout/LandingPageLayout/EoiPageFooter';
import { useLoaderData } from 'react-router-dom';
import { EoiLoginPageTopNavigation } from '../../layout/LoginPageLayout/EoiLoginPageTopNavigation';
import classes from './ExpressionOfInterestPage.module.css';
import { ExpressionOfInterestForm } from '../../component/formControls/ExpressionOfInterestForm';
import { ExpressionOfInterestCardsSection } from '../../component/eoiPageComponents/expressionOfInterestCardsSection/ExpressionOfInterestCardsSection';
import { ExpressionOfInterestAboutUsSection } from '../../component/eoiPageComponents/expressionOfInterestAboutUsSection/ExpressionOfInterestAboutUsSection';
import { ExpressionOfInterestPricingCard } from '../../component/eoiPageComponents/expressionOfInterestPricingCard/ExpressionOfInterestPricingCard';
import { ExpressionOfInterestStudentPass } from '../../component/eoiPageComponents/expressionOfInterestStudentPass/ExpressionOfInterestStudentPass';
import { ExpressionOfInterestBrandBuildingSection } from '../../component/eoiPageComponents/expressionOfInterestBrandBuildingSection/ExpressionOfInterestBrandBuildingSection';
import { EoiPopUpSection } from '../../component/eoiPageComponents/popUpSection/EoiPopUpSection';
import { ExpressionOfInterestLogoCarousel } from '../../component/eoiPageComponents/expressionOfInterestLogoCarousel/ExpressionOfInterestLogoCarousel';
import { ExpressionOfInterestTopNavigation } from '../../layout/expressionOfInterestTopNavigation/ExpressionOfInterestTopNavigation';
import { ExpressionOfInterestVideoSection } from '../../component/eoiPageComponents/expressionOfInterestDetailsVideoSection/ExpressionOfInterestVideoSection';
import { ExpressionOfInterestPageBanner } from '../../component/eoiPageComponents/expressionOfInterestPageBanner/ExpressionOfInterestPageBanner';
import { ExpresionOfInterestCarouselSection } from '../../component/eoiPageComponents/expressionOfInterestMobileCarousel/ExpressionOfInterestCarouselSection';
import { ExpertWelcomePageVideo } from '../../component/expertWelcomePage/ExpertWelcomePageVideo';
import { AppConstants } from '../../constants/AppConstants';
import welcomeExpertClasses from '../../component/expertWelcomePage/expertWelcomePage.module.css';
import { Col, Row } from 'react-bootstrap';
import { ExpressionOfInterestPartnership } from '../../component/eoiPageComponents/expressionOfInterestPartnershipSection/ExpressionOfInterestPartnership';
import { useSubscriptionModalStore } from '../../stores';
import { useEffect } from 'react';

export const ExpressionOfIntrestPage = () => {
  const [landingPageData, eoiImageData, eoiCarouselData] = useLoaderData();
  const { initializeSubscriptionModalStore, isInitialized } =
    useSubscriptionModalStore();

  useEffect(() => {
    if (!isInitialized) {
      initializeSubscriptionModalStore();
    }
  }, [isInitialized, initializeSubscriptionModalStore]);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <>
      <EoiPopUpSection />

      <ExpressionOfInterestTopNavigation landingPageData={landingPageData} />
      <div className={`row ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestPageBanner
            eoiImageData={eoiImageData}
            eoiCarouselData={eoiCarouselData}
          />
        </div>
      </div>

      <div className={`row ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestLogoCarousel />
        </div>
      </div>

      <div className={`row mb-3 ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestCardsSection />
        </div>
      </div>

      <div className={`row ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestBrandBuildingSection />
        </div>
      </div>
      {landingPageData?.welcomeExpertsSuccessfulExperts?.active && (
        <div className={`row mt-3 mb-3 ${classes.section}`}>
          <Row>
            <div className={welcomeExpertClasses.successfulExpertsSectionEOI}>
              <div className={welcomeExpertClasses.section}>
                <p>{AppConstants.watchHowQrenciaBenefitedExperts}</p>
              </div>

              <Row
                className={welcomeExpertClasses.videoContainer}
                xs={1}
                sm={2}
                md={2}
                lg={4}
                xl={4}
              >
                {landingPageData.welcomeExpertsSuccessfulExperts.cards
                  ?.filter((video) => video.active) // Filter cards where activeFlag is true
                  .map((video, index) => (
                    <Col
                      className={welcomeExpertClasses.videoSection}
                      key={index}
                    >
                      <ExpertWelcomePageVideo video={video} />
                    </Col>
                  ))}
              </Row>
            </div>
          </Row>
        </div>
      )}
      <div className={`row ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestStudentPass />
        </div>
      </div>

      <div className={`row ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestPricingCard />
        </div>
      </div>

      {isInitialized ? (
        <div className={`row mt-3 mb-3 ${classes.section}`}>
          <ExpressionOfInterestForm />
        </div>
      ) : null}

      <div className={`row ${classes.section}`}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ExpressionOfInterestAboutUsSection />
        </div>
      </div>
      <EoiPageFooter landingPageData={landingPageData} />
    </>
  );
};
