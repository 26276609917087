import { create } from 'zustand';
import { useLearnerPassPaymentPopupStore } from '../stores';

const useLearnerPassPopupStore = create((set, get) => ({
  isFirstTimeUser: false,
  showLearnerPassAd: false,
  showLearnerPassRenewalReminder: false,
  showLearnerPassReminder: false,
  hasPurchasedLearnerPass: false,
  showSubscriptionModal: false,
  hasLearnerPassExpired: false,
  showUpdateProfileDialogBox: false,

  setIsFirstTimeUser: (isFirstTimeUser) =>
    set({ isFirstTimeUser: isFirstTimeUser }),
  setShowLearnerPassAd: (showLearnerPassAd) =>
    set({ showLearnerPassAd: showLearnerPassAd }),
  setShowLearnerPassRenewalReminder: (showLearnerPassRenewalReminder) =>
    set({ showLearnerPassRenewalReminder: showLearnerPassRenewalReminder }),
  setShowLearnerPassReminder: (showLearnerPassReminder) =>
    set({ showLearnerPassReminder: showLearnerPassReminder }),
  setHasPurchasedLearnerPass: (hasPurchasedLearnerPass) =>
    set({ hasPurchasedLearnerPass: hasPurchasedLearnerPass }),
  setShowSubscriptionModal: (showSubscriptionModal) =>
    set({ showSubscriptionModal: showSubscriptionModal }),
  setHasLearnerPassExpired: (hasLearnerPassExpired) =>
    set({ hasLearnerPassExpired: hasLearnerPassExpired }),
  setShowUpdateProfileDialogBox: (updateProfileDialogBox) => {
    const {
      showLearnerPassAd,
      showLearnerPassReminder,
      showSubscriptionModal,
      showUpdateProfileDialogBox,
      showLearnerPassRenewalReminder,
    } = get();

    const showLearnerPassPaymentPopupValue =
      useLearnerPassPaymentPopupStore.getState().showLearnerPassPaymentPopup;
    const showLearnerPassWelcomeScreenValue =
      useLearnerPassPaymentPopupStore.getState().showLearnerPassWelcomeScreen;

    if (
      !showLearnerPassAd &&
      !showUpdateProfileDialogBox &&
      !showLearnerPassPaymentPopupValue &&
      !showLearnerPassWelcomeScreenValue &&
      !showLearnerPassRenewalReminder
    ) {
      set({ showUpdateProfileDialogBox: true });
    } else {
      set({ showUpdateProfileDialogBox: updateProfileDialogBox });
    }
  },
  // set({ updateProfileDialogBox: updateProfileDialogBox }),
}));

export default useLearnerPassPopupStore;
