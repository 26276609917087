import { useCallback, useEffect, useRef, useState } from "react";
import { AppConstants } from "../../../constants/AppConstants";
import { DateFilterComponent } from "../../adminDashboardComponents/dateFilterComponent/DateFilterComponent";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { AgGridReact } from "ag-grid-react";
import classes from "../AdminNumberTrackingComponents.module.css";
import dashboardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { Button, Card, Col, Row } from "react-bootstrap";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { FaFilter } from "react-icons/fa6";
import moment from 'moment';

import { CreatorLearningActivityDetails } from "./CreatorLearningActivityDetails";
import { BsEye } from "react-icons/bs";
import { RiProfileLine } from "react-icons/ri";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { MathUtil } from "../../../utils/MathUtil";

export const CreatorOnQrenciaStats = () => {
    const [showDateFilterModal, setShowFilterDateModal] = useState(AppConstants.falseText);
    const [dateDisplayValue, setDateDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [creatorDetails, setCreatorDetails] = useState([]);
    const [qrenciaCreatorStats, setQrenciaCreatorStats] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [creatorLearningActivityDetails, setCreatorLearningActivityDetails] = useState({});
    const [showDetailsComponent, setShowDetailsComponent] = useState(AppConstants.falseText);
    const [creatorName, setCreatorName] = useState(AppConstants.emptyString);
    const [fromDate, setFromDate] = useState(AppConstants.emptyString);
    const [toDate, setToDate] = useState(AppConstants.emptyString);
    const creatroDetailsRef = useRef(null);

    const constructURL = (url, fromDateString, toDateString) => {
        if (fromDateString) {
            url = `${url}?${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDateString)}`
        }
        if (toDateString && !fromDateString) {
            url = `${url}?${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        } else if (toDateString && fromDateString) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        }
        if (fromDateString || toDateString) {
            url = `${url}&locale=${DateTimeUtil.getPreferredTimeZoneForUser()}`
        }

        return url;
    };

    const fetchQrenciaCreatorDetails = useCallback(async (fromDateString, toDateString) => {

        let url;
        if (fromDateString || toDateString) {
            url = constructURL(AppConstants.getCreatorsInfoForSelectedDateRangeAPI, fromDateString, toDateString);
        } else {
            url = AppConstants.getCreatorsInfoForAdminAPI;
        }

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setCreatorDetails(response);
        } else {
            setCreatorDetails([]);
        }
    }, []);

    const fetchQrenciaCreatorStats = useCallback(async (fromDateString, toDateString) => {
        let url;
        if (fromDateString || toDateString) {
            url = constructURL(AppConstants.getQrenciaStatsForSelectedDateRangeAPI, fromDateString, toDateString);
        } else {
            url = AppConstants.getQrenciaStatsForAdminAPI;
        }

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setQrenciaCreatorStats(response);
        } else {
            setQrenciaCreatorStats({});
        }
    }, []);

    useEffect(() => {
        fetchQrenciaCreatorDetails();
        fetchQrenciaCreatorStats();
    }, [fetchQrenciaCreatorDetails, fetchQrenciaCreatorStats]);


    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setShowFilterDateModal(AppConstants.falseText);
        setDateDisplayValue(displayValue);
        setToDate(toDateString);
        setFromDate(fromDateString);
        fetchQrenciaCreatorStats(fromDateString, toDateString);
        fetchQrenciaCreatorDetails(fromDateString, toDateString);
    }

    const handleDateSelectionModalClose = () => {
        setShowFilterDateModal(AppConstants.falseText);
    };

    const handleDetailsComponent = async (data) => {
        let url = `${AppConstants.getCreatorWiseLearningActivityDetailsAPI}${data.creatorQrenciaId}`;
        if (fromDate) {
            url = `${url}&${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDate)}`
        }
        if (toDate) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDate)}`
        }
        if (fromDate || toDate) {
            url = `${url}&locale=${DateTimeUtil.getPreferredTimeZoneForUser()}`
        }

        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setCreatorLearningActivityDetails(response);
            setCreatorName(data.creatorName);
            setShowDetailsComponent(AppConstants.trueText);
        } else {
            setCreatorName(AppConstants.emptyString);
            setCreatorLearningActivityDetails({});
            setShowDetailsComponent(AppConstants.falseText);
        }
    }
    const hideDetailsSection = () => {
        setShowDetailsComponent(AppConstants.falseText);
        setCreatorName(AppConstants.emptyString);
        setCreatorLearningActivityDetails({});
    }

    const creatorListColumnDefs = [
        {
            headerName: `Name`,
            field: "creatorName",
            width: 400,
            tooltipValueGetter: ({ data }) => `${data.creatorName}`,
            valueGetter: (params) => {
                return `${params.data.creatorName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Skill",
            field: "Skill",
            width: 400,
            tooltipValueGetter: ({ data }) => data.creatorType === AppConstants.userRoleExpert ? data.expertSkills ?? AppConstants.notAvailableText : data.agencySkills ?? AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return data.creatorType === AppConstants.userRoleExpert ? data.expertSkills ?? AppConstants.notAvailableText : data.agencySkills ?? AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Type`,
            field: "creatorType",
            width: 400,
            tooltipValueGetter: ({ data }) => `${data.creatorType}`,
            valueGetter: (params) => {
                return `${params.data.creatorType}`;
            },
            filter: 'agTextColumnFilter'
        },
            {
                headerName: `Subscription Renewed On`,
                field: "lastMembershipRenewedOn",
                maxWidth: 400,
                tooltipValueGetter: ({ data }) =>
                    data.lastMembershipRenewedOn
                        ? `${moment.utc(data.lastMembershipRenewedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
                        : AppConstants.notAvailableText,
                valueGetter: ({ data }) => {
                    return data.lastMembershipRenewedOn
                        ? `${moment.utc(data.lastMembershipRenewedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
                        : AppConstants.notAvailableText;
                },
                filter: 'agTextColumnFilter',
                 headerClass: 'multi-line-header'
            },
            {
                headerName: `Subscription Expiry On`,
                field: "lastMembershipExpiryOn",
                maxWidth: 400,
                tooltipValueGetter: ({ data }) =>
                    data.lastMembershipExpiryOn
                        ? `${moment.utc(data.lastMembershipExpiryOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
                        : AppConstants.notAvailableText,
                valueGetter: ({ data }) => {
                    return data.lastMembershipExpiryOn
                        ? `${moment.utc(data.lastMembershipExpiryOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
                        : AppConstants.notAvailableText;
                },
                filter: 'agTextColumnFilter',
                 headerClass: 'multi-line-header'
            },
        {
            headerName: `Onboarded On`,
            field: "creatorOnboardingDate",
            maxWidth: 100,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.creatorOnboardingDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.creatorOnboardingDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: 'Ambassador Challenge',
            field: "isAmbassadorChallengeAccepted",
            width: 400,
            tooltipValueGetter: ({ data }) => (data.creatorType === AppConstants.userRoleExpert || data.creatorType === AppConstants.agencyTypes.academy) ? data.isAmbassadorChallengeAccepted === AppConstants.yText ? "Yes" : "No" : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.creatorType === AppConstants.userRoleExpert || data.creatorType === AppConstants.agencyTypes.academy) ? data.isAmbassadorChallengeAccepted === AppConstants.yText ? "Yes" : "No" : AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Challenge Accepted On",
            field: "ambassadorChallengeAcceptedOn",
            maxWidth: 100,
            tooltipValueGetter: ({ data }) => ((data.creatorType === AppConstants.userRoleExpert || data.creatorType === AppConstants.agencyTypes.academy) && data.isAmbassadorChallengeAccepted === AppConstants.yText && data.ambassadorChallengeAcceptedOn !== AppConstants.nullText) ? `${moment.utc(data.ambassadorChallengeAcceptedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return ((data.creatorType === AppConstants.userRoleExpert || data.creatorType === AppConstants.agencyTypes.academy) && data.isAmbassadorChallengeAccepted === AppConstants.yText && data.ambassadorChallengeAcceptedOn !== AppConstants.nullText) ? `${moment.utc(data.ambassadorChallengeAcceptedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : AppConstants.notAvailableText;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: `Competitions`,
            field: "totalCompetitions",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalCompetitions),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.totalCompetitions);
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Courses`,
            field: "totalCourses",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalCourses),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.totalCourses);
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Workshops`,
            field: "totalWorkshops",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalWorkshops),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.totalWorkshops);
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Competition Enrolments`,
            field: "totalCompetitionEnrolments",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalCompetitionEnrolments),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.totalCompetitionEnrolments);
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: `Course Enrolments`,
            field: "totalCourseEnrolments",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalCourseEnrolments),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.totalCourseEnrolments);
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: `Workshop Enrolments`,
            field: "totalWorkshopEnrolments",
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.totalWorkshopEnrolments),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.totalWorkshopEnrolments);
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header'
        },
        {
            headerName: "Action",
            field: "action",
            cellRendererFramework: ({ data }) => <div className={classes.obrGridActionBox}>
                <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Details" className={`gridButton ${classes.gridIcon}`} onClick={() => { handleDetailsComponent(data) }}><BsEye /></Button>
                <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Branding Page" className={`gridButton ${classes.gridIcon}`} onClick={() => { window.open(`${process.env.REACT_APP_FRONT_END_URL}${data.creatorBrandingPageLink}`, AppConstants.openInNewTab) }}><RiProfileLine /></Button>
            </div>,
            width: 100,
        }
    ];

    const getTotalCreatorsCount = () => {
        return (qrenciaCreatorStats.activeSkillOrganizations ?? 0) + (qrenciaCreatorStats.activeAcademies ?? 0) + (qrenciaCreatorStats.activeExperts ?? 0) + (qrenciaCreatorStats.activeSchools ?? 0) + (qrenciaCreatorStats.activeHousingSocieties ?? 0);
    };

    const onBtnExport = useCallback(() => {
        const selectedColumns = ['creatorName', 'Skill', 'creatorType', 'creatorOnboardingDate', 'totalCompetitions', 'totalCourses', 'totalWorkshops', 'totalCompetitionEnrolments', 'totalCourseEnrolments', 'totalWorkshopEnrolments'];

        const csvData = creatroDetailsRef.current?.api.getDataAsCsv({
            columnKeys: selectedColumns,
            columnSeparator: AppConstants.agGridColumnSeparator,
            suppressQuotes: AppConstants.trueText
        });

        FileOperationsUtil.downloadCsvDataAsExcel(csvData, "Creator-Details.xlsx");
    }, []);

    return (
        <div>
            {!showDetailsComponent && <>
                <div className={`${classes.dateFilterText}`} onClick={() => setShowFilterDateModal(AppConstants.trueText)}>{dateDisplayValue}<FaFilter /></div>
                {showDateFilterModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateDisplayValue} />}
                <Row className="mb-4">
                    <div className={`${dashboardClass.dashboardCards} mb-2`}>
                        <Card className={`${dashboardClass.countCards} ${dashboardClass.expertCard}`}>
                            <Card.Body>
                                <Card.Title className={dashboardClass.cardTitle}>Experts</Card.Title>
                                <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaCreatorStats.activeExperts)}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={`${dashboardClass.countCards} ${dashboardClass.academyCard}`}>
                            <Card.Body>
                                <Card.Title className={dashboardClass.cardTitle}>Academies</Card.Title>
                                <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaCreatorStats.activeAcademies)}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={`${dashboardClass.countCards} ${dashboardClass.costCard}`}>
                            <Card.Body>
                                <Card.Title className={dashboardClass.cardTitle}>Schools</Card.Title>
                                <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaCreatorStats.activeSchools)}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={`${dashboardClass.countCards} ${dashboardClass.courseCard}`}>
                            <Card.Body>
                                <Card.Title className={dashboardClass.cardTitle}>Housing Societies</Card.Title>
                                <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaCreatorStats.activeHousingSocieties)}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={`${dashboardClass.countCards} ${dashboardClass.workshopCard}`}>
                            <Card.Body>
                                <Card.Title className={dashboardClass.cardTitle}>Skill Organizations</Card.Title>
                                <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(qrenciaCreatorStats.activeSkillOrganizations)}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={`${dashboardClass.countCards} ${dashboardClass.feeCollectedCard}`}>
                            <Card.Body>
                                <Card.Title className={dashboardClass.cardTitle}>Total</Card.Title>
                                <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(getTotalCreatorsCount(qrenciaCreatorStats))}</Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                ref={creatroDetailsRef}
                                className="expertObrDetailsGrid"
                                columnDefs={creatorListColumnDefs}
                                pagination={true}
                                paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                rowData={creatorDetails}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
            </>}
            {showDetailsComponent && <div>
                <CreatorLearningActivityDetails creatorLearningActivityDetails={creatorLearningActivityDetails} hideDetailsSection={hideDetailsSection} creatorName={creatorName} />
            </div>}
            {!showDetailsComponent && creatorDetails && creatorDetails.length > 0 && <Row className="mt-4">
                <div>
                    <Button className={classes.exportButton} onClick={onBtnExport}>Download</Button>
                </div>
            </Row>}
        </div>
    )
}