import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classes from '../../layout/LandingPageLayout/LandingPageDedicatedNavbar.module.css';
import { AppConstants } from '../../constants/AppConstants';
import templateClasses from '../ExpertPageLayout/ExpertHeader.module.css';
import Auth from '../../utils/Auth';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from 'react-icons/fa';
import { BiLogOut, BiHome } from 'react-icons/bi';
import { Logout } from '../../common/Logout';
import { DataService } from '../../services/DataService';
import { RiProfileLine } from 'react-icons/ri';

export const LandingPageTopNavigationForListingPages = (props) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
  const userBrandingPageLink = Auth.isLogin()
    ? `${process.env.REACT_APP_FRONT_END_URL}${Auth.getUserRole()?.toLowerCase()}/${Auth.getLoggedInUserDetails()?.userSlug}`
    : AppConstants.emptyString;
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/+/, '').toLowerCase();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  function getUserHomePageUrl() {
    if (Auth.isLogin()) {
      if (Auth.getUserRole() === AppConstants.userRoleExpert) {
        window.open(
          AppConstants.expertHomePath,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRoleStudent) {
        window.open(
          AppConstants.studentHomePath,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRoleAdmin) {
        window.open(
          AppConstants.adminNumberTrackingPath,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRoleAgency) {
        window.open(
          AppConstants.agencyHomePath,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRoleSales) {
        window.open(
          AppConstants.salesNumberTrackingPath,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRoleMarketing) {
        window.open(
          AppConstants.marketingBulkSignupPath,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRolePartnerSuccess) {
        window.open(
          AppConstants.partnerSuccessNumberTracking,
          AppConstants.newPageTargetSelf
        );
      } else if (Auth.getUserRole() === AppConstants.userRoleTeam) {
        window.open(AppConstants.teamHomePath, AppConstants.newPageTargetSelf);
      } else {
        window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
      }
    } else {
      window.open(AppConstants.forwardSlash, AppConstants.newPageTargetSelf);
    }
  }

  return (
    <>
      <header className={classes.header}>
        <div className={classes.hamburger} onClick={toggleMenu}>
          <div className={classes.bar}></div>
          <div className={classes.bar}></div>
          <div className={classes.bar}></div>
        </div>
        <div className={classes.qrenciaLogoSection}>
          <NavLink to="/">
            <img
              className={classes.qrenciaLogo}
              src={qrenciaLogoUrl}
              alt="Qrencia Logo"
            />
          </NavLink>
        </div>

        <div className={classes.navItemSection}>
          <nav>
            <ul className={classes.list}>
              {props.learningActivities?.length > 0 &&
                props.learningActivities.map((section, index) => {
                  // Normalize section.subTitle by removing leading '/' and converting to lowercase
                  const sectionPath = section.subTitle
                    .replace(/^\/+/, '')
                    .toLowerCase();

                  // Skip rendering if the section path matches the current path
                  if (currentPath === sectionPath) {
                    return null; // Skip rendering this NavLink
                  }

                  return (
                    <li
                      className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnWeb}`}
                    >
                      <NavLink
                        key={index}
                        to={section.subTitle.toLowerCase()}
                        className={classes.sectionNavItem}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent React Router navigation
                          window.location.replace(
                            section.subTitle.toLowerCase()
                          ); // Reload & navigate
                        }}
                      >
                        {section.title}
                      </NavLink>
                    </li>
                  );
                })}

              {!Auth.isLogin() && (
                <li className={classes.listItem}>
                  <NavLink
                    to={AppConstants.loginPageUrl}
                    className={classes.loginButton}
                  >
                    Login
                  </NavLink>
                </li>
              )}
              {!Auth.isLogin() && (
                <li className={classes.listItem}>
                  <NavLink
                    to={AppConstants.signup}
                    className={classes.signUpButton}
                  >
                    Join Now
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
        {Auth.isLogin() && (
          <div className={`${templateClasses.profileSection} ms-3`}>
            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl !==
              null && (
              <div>
                <img
                  src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl}
                  className={templateClasses.profileImage}
                  alt="Profile"
                />
              </div>
            )}
            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && (
              <div>
                <div className={templateClasses.userInitials}>
                  {Auth.getLoggedInUserDetails().nameInitials}
                </div>
              </div>
            )}
            <Dropdown>
              <Dropdown.Toggle className={templateClasses.profilePopoverButton}>
                <p className={templateClasses.profileName}>
                  {Auth.getLoggedInName().length < 16
                    ? Auth.getLoggedInName()
                    : Auth.getLoggedInName().substr(0, 16) + '..'}
                </p>{' '}
                <FaChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className={templateClasses.profileDropdownItem}
                  onClick={getUserHomePageUrl}
                >
                  <BiHome
                    className={templateClasses.profileDropdownOptionIcon}
                  />
                  <p className={templateClasses.profileDropdownOption}>Home</p>
                </Dropdown.Item>
                {(Auth.getUserRole() === AppConstants.userRoleExpert ||
                  Auth.getUserRole() === AppConstants.userRoleAgency) && (
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        userBrandingPageLink,
                        AppConstants.openInNewTab
                      )
                    }
                    className={templateClasses.profileDropdownItem}
                  >
                    <RiProfileLine
                      className={templateClasses.profileDropdownOptionIcon}
                    />
                    <p className={templateClasses.profileDropdownOption}>
                      Branding Page
                    </p>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className={templateClasses.profileDropdownItem}
                  onClick={Logout.logout}
                >
                  <BiLogOut
                    className={templateClasses.profileDropdownOptionIcon}
                  />
                  <p className={templateClasses.profileDropdownOption}>
                    Logout
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {showMenu && (
          <nav>
            <ul
              className={`${classes.listOnMobile} ${showMenu ? classes.showMenu : ''}`}
            >
              {props.learningActivities?.length > 0 &&
                props.learningActivities.map((section, index) => {
                  // Normalize section.subTitle by removing leading '/' and converting to lowercase
                  const sectionPath = section.subTitle
                    .replace(/^\/+/, '')
                    .toLowerCase();

                  // Skip rendering if the section path matches the current path
                  if (currentPath === sectionPath) {
                    return null; // Skip rendering this NavLink
                  }

                  return (
                    <li
                      className={`${classes.listItem} ${classes.widthNotSet} ${classes.displayOnMobile}`}
                    >
                      <NavLink
                        key={index}
                        to={section.subTitle.toLowerCase()}
                        className={classes.sectionNavItem}
                      >
                        {section.title}
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </nav>
        )}
      </header>
    </>
  );
};
