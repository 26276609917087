import React from 'react';
import classes from './ExpertObrPageBanner.module.css';
import { Container, Row } from 'react-bootstrap';
export const ExpertObrPageBanner = (props) => {
  const { desktopBannerContent, tabletBannerContent, mobileBannerContent } = props;

  const desktopImagePath = desktopBannerContent.bannerImage.imagePath;
  const tabletImagePath = tabletBannerContent.bannerImage.imagePath;
  const mobileImagePath = mobileBannerContent.bannerImage.imagePath;

  return (
    <>
      <Row>
        <div className="col-12">
          <div className={classes.mobileImgContainer}>
            <img src={mobileImagePath} alt="banner" className={classes.bannerImage} />
          </div>
          <div className={classes.tabletImgContainer}>
            <img src={tabletImagePath} alt="banner" className={classes.bannerImage} />
          </div>
          <div className={classes.desktopImgContainer}>
            <img src={desktopImagePath} alt="banner" className={classes.bannerImage} />
          </div>
        </div>
      </Row>
    </>
  );
};
