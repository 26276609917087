import React from 'react';
import classes from './SkillGarden.module.css';

export const SkillGarden = (props) => {
    return (
        <div className={classes.skillGardenSection}>
            <h2 className={classes.skillGardenHeading}>Skill Garden</h2>
            <img src={props.skillGardenPath} className={classes.skillGardenImage} alt="Skill Garden" />
        </div>
    );
}