import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LEARNER_PASS_POPUP_IMAGES } from '../../../constants/AppConstants';
// import styles from './LearnerPassAd.module.css';
import commonStyles from './LearnerPopupsCommonStyles.module.css';
import Auth from '../../../utils/Auth';
import { useLearnerPassStore } from '../../../stores';

const LearnerPassAd = ({ show, onClose, onProceed }) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  // const illustrationURL = `${mediaContent}${LEARNER_ONBOARDING.learnerWelcomeScreenPopupIllustration2Path}`;
  const illustrationURL = `${mediaContent}${LEARNER_PASS_POPUP_IMAGES.learnerPassAdImage}`;

  const username = Auth.getLoggedInUserDetails().firstName;

  const { setShowSubscriptionModal, setShowLearnerPassAd } =
    useLearnerPassStore();

  const buyLater = () => {
    onClose(); // Close the modal
  };

  return (
    <div className={commonStyles.modalContainer}>
      <Modal show={show} centered>
        <Modal.Body className={commonStyles.modalBody}>
          <div className={commonStyles.container}>
            <div className={commonStyles.illustrationContainer}>
              <img
                className={commonStyles.illustration}
                src={illustrationURL}
                alt="Illustration"
              />
            </div>
            <div className={commonStyles.contentContainer}>
              <div className={commonStyles.textContainer}>
                <h4 className={commonStyles.title}>Get your Learner Pass</h4>

                <p className={commonStyles.description}>
                  Over 50,000 learners have their personal skill profile. Do you
                  have yours? Get your learner profile, discounts on courses,
                  access to games and much more with{' '}
                  <span className="textHighlight">Learner Pass</span>.
                </p>
              </div>
              <div className="buttonContainer">
                <div className={commonStyles.buttonContainer}>
                  <div
                    className={commonStyles.laterButton}
                    variant="primary"
                    onClick={buyLater}
                  >
                    Later
                  </div>
                  <Button
                    className={commonStyles.button}
                    variant="primary"
                    onClick={onProceed}
                  >
                    Buy Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LearnerPassAd;
