import { useState } from 'react';
import styles from './LearnerProfileIntroduction.module.css';
import { FaEdit } from 'react-icons/fa';
import { FaFloppyDisk } from 'react-icons/fa6';
import { AppConstants } from '../../../constants/AppConstants';
import { VscLocation } from 'react-icons/vsc';
import { ProgressBar, Button } from 'react-bootstrap';
import parse from 'html-react-parser';

export const LearnerProfileIntroduction = ({
  learnerDetails,
  onSaveLearnerIntroduction,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [learnerDetailsDto, setLearnerDetailsDto] = useState(
    learnerDetails.aboutLearner || ''
  );
  const [error, setError] = useState(AppConstants.falseText);

  // Handle form input changes
  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditable(!isEditable);
  };
  const handleTickClick = (e) => {
    e.preventDefault();
    if (learnerDetailsDto.trim() === '') {
      setError(AppConstants.trueText);
      return;
    }
    setError(AppConstants.falseText);
    setIsEditable(!isEditable);
    const formattedText =
      learnerDetailsDto.trim() === ''
        ? null
        : learnerDetailsDto.replace(/\n/g, '<br />');
    onSaveLearnerIntroduction(formattedText);
    setLearnerDetailsDto(formattedText); // Update local state as well
  };
  // Handles changes to the input field
  const handleInputChange = (e) => {
    e.preventDefault();
    setLearnerDetailsDto(e.target.value);
  };
  return (
    <>
      {(learnerDetails?.interestedSkills?.length > 0 ||
        learnerDetails.editAllowed) && (
          <div className={styles.learnerIntroductionContainer}>
            <div className={styles.learnerIntroductionBox}>
              <div className={styles.headingSection}>
                <div className={styles.heading}>
                  <h2>
                    {
                      AppConstants.LearnerProfileHeadingConstants
                        .intoductionHeading
                    }
                  </h2>
                </div>
                <div className={styles.location}>
                  <span className={styles.learnerProfileLocation}>
                    <VscLocation className={styles.locationIcon} />
                    {[
                      learnerDetails.learnerCity,
                      learnerDetails.learnerState,
                      learnerDetails.learnerCountry,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </span>
                </div>
              </div>
              <div className={styles.editIconSection}>
                {!isEditable && learnerDetails?.editAllowed && (<Button
                  onClick={handleEditClick}
                  className={` ${styles.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                >
                  <FaEdit className={styles.learnerProfileEditIcon} />{' '}Edit
                </Button>)}
                {isEditable && learnerDetails?.editAllowed && (<Button
                  onClick={handleTickClick}
                  className={` ${styles.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                >
                  Save
                </Button>)}
              </div>
              {isEditable ? (
                <div className={styles.introductionTextArea}>
                  <textarea
                    value={learnerDetailsDto?.replace(/<br\s*\/?>/g, '\n') || ''}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder='Start with your introduction and let your story unfold.'
                  />
                </div>
              ) : (
                <div className={styles.introductionTextArea}>
                  <p className={styles.introductionText}>
                    {parse(learnerDetailsDto.replace(/\n/g, '<br />'))}
                  </p>
                </div>
              )}
              {error && <div className={styles.errorMessage}>Please enter a valid introduction</div>}
            </div>
            <div className={styles.learnerSkillsProwessSection}>
              <div className={styles.headingSection}>
                <h2>
                  {
                    AppConstants.LearnerProfileHeadingConstants
                      .skillsProwessHeading
                  }
                </h2>
              </div>
              {learnerDetails?.interestedSkills?.length > 0 ? (
                <div className={styles.skillsListContainer}>
                  {learnerDetails.interestedSkills.map((skill) => (
                    <div className={styles.learnerSkillSection}>
                      <p className={styles.learnerSkillProwess}>
                        {skill.skillName}
                      </p>
                      <ProgressBar
                        className={styles.learnerProgressBar}
                        variant="success"
                        now={100}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="noDataAvailableError">
                  Add your skills of interest from the advanced profile section to
                  receive personalized, skill-based recommendations.
                </p>
              )}
            </div>
          </div>
        )}
      {learnerDetails?.interestedSkills?.length == 0 &&
        !learnerDetails.editAllowed && (
          <div className={styles.learnerIntroductionContainer}>
            <div className={styles.learnerIntroductionBox}>
              <div className={styles.headingSection}>
                <div className={styles.heading}>
                  <h2>
                    {
                      AppConstants.LearnerProfileHeadingConstants
                        .intoductionHeading
                    }
                  </h2>
                </div>
                <div className={styles.location}>
                  <span className={styles.learnerProfileLocation}>
                    <VscLocation className={styles.locationIcon} />
                    {[
                      learnerDetails.learnerCity,
                      learnerDetails.learnerState,
                      learnerDetails.learnerCountry,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </span>
                </div>
              </div>
              <div className={styles.editIconSection}>
                {!isEditable && learnerDetails?.editAllowed && (<Button
                  onClick={handleEditClick}
                  className={` ${styles.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                >
                  <FaEdit className={styles.learnerProfileEditIcon} />{' '}Edit
                </Button>)}
                {isEditable && learnerDetails?.editAllowed && (<Button
                  onClick={handleTickClick}
                  className={` ${styles.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                >
                  Save
                </Button>)}
              </div>
              {error && <div className={styles.errorMessage}>Please enter a valid introduction</div>}
              {learnerDetailsDto && learnerDetailsDto.trim() !== '' ? (
                isEditable ? (
                  <div className={styles.introductionTextArea}>
                    <textarea
                      value={
                        learnerDetailsDto?.replace(/<br\s*\/?>/g, '\n') || ''
                      }
                      placeholder='Start with your introduction and let your story unfold.'
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                ) : (
                  <div className={styles.introductionTextArea}>
                    <p className={styles.introductionText}>
                      {parse(learnerDetailsDto.replace(/\n/g, '<br />'))}
                    </p>
                  </div>
                )
              ) : null}
            </div>
          </div>
        )}
    </>
  );
};
