import { useState } from 'react';
import classes from './StudentAdvertisement.module.css';
export const StudentAdvertisement = (props) => {
  const [advertisementDetails, setAdvertisementDetails] = useState(
    props.advertisementData
  );
  return (
    <>
      {advertisementDetails.length != 0 && (
        <div className={classes.advertisementImageSection}>
          {advertisementDetails.map((advertisement) => (
            <div key={advertisement.id}>
              <a href={advertisement.link} target="_blank">
                <img
                  src={advertisement.bannerImageWeb}
                  alt="advertisement"
                  className={classes.advertisementImageWeb}
                />
              </a>
              <a href={advertisement.link} target="_blank">
                <img
                  src={advertisement.bannerImageTablet}
                  alt="advertisement"
                  className={classes.advertisementImageTab}
                />
              </a>
              <a href={advertisement.link} target="_blank">
                <img
                  src={advertisement.bannerImageMobile}
                  alt="advertisement"
                  className={classes.advertisementImageMobile}
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
