import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  AppConstants,
  AWARDS_CONSTANTS,
  SKILL_PROGRESS_ASSESSMENT_CONSTANTS,
} from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AgGridReact } from 'ag-grid-react';
import { Button, Row, Col } from 'react-bootstrap';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import moment from 'moment';
import toast from 'react-hot-toast';
import classes from './ViewAndEditAwardsGrids.module.css';
import EditAssessment from '../skillProgressAssessmentComponents/EditAssessment';
import { FaEye } from 'react-icons/fa';
import { IoArrowBackCircle } from 'react-icons/io5';
import { TiArrowLeft } from 'react-icons/ti';
import { TbExternalLink } from 'react-icons/tb';
import { FaRegEdit } from 'react-icons/fa';
import {
  BsFileEarmarkSpreadsheet,
  BsToggleOn,
  BsToggleOff,
} from 'react-icons/bs';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';

export const ViewAndEditAssessmentGrid = () => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const [rowData, setRowData] = useState([]);
  const [viewLearnersData, setViewLearnersData] = useState([]);
  const gridRef = useRef(null); // Reference for AG Grid
  const [editingAssessmentId, setEditingAssessmentId] = useState(null);
  const [viewAssessmentId, setViewAssessmentId] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);
  const [ongoingCourseList, setOngoingCourseList] = useState([]);
  const [assessmentData, setAssessmentData] = useState({});
  const [viewMarksForLearnerId, setViewMarksForLearnerId] = useState(null);
  const [selectedLearnerData, setSelectedLearnerData] = useState(null);
  const [togglingId, setTogglingId] = useState(null);
  const [downloadSpinner, setDownloadSpinner] = useState(
    AppConstants.falseText
  );

  const getAllAssessments = async () => {
    const url = SKILL_PROGRESS_ASSESSMENT_CONSTANTS.getCreatorAssessments;
    const response = await AuthorizedDataService.getRequest(url, '', '');
    if (response) {
      setRowData(response);
    }
  };

  const uniqueParameters = (data) => {
    const parametersSet = new Map();
    data.forEach((learner) => {
      learner.parameterScores.forEach((param) => {
        if (!parametersSet.has(param.parameterId)) {
          parametersSet.set(param.parameterId, param);
        }
      });
    });
    return Array.from(parametersSet.values());
  };

  useEffect(() => {
    getAllAssessments();
  }, []);

  const handleEditComplete = () => {
    setEditingAssessmentId(null);
    setAssessmentData({});
    setTemplatesList([]);
    setOngoingCourseList([]);
    getAllAssessments();
  };

  const viewBackClick = () => {
    setViewAssessmentId(null);
  };

  const viewAssessmentScreen = () => {
    setViewMarksForLearnerId(null);
  };

  // Function to handle edit button click
  const handleEditClick = async (assessmentUniqueId) => {
    await loadAssessmentData(assessmentUniqueId);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const updateVisibilityFlag = async (uniqueId, visibilityFlag) => {
    try {
      const url = `${SKILL_PROGRESS_ASSESSMENT_CONSTANTS.updateAssessmentReportVisibilityFlagApi}${uniqueId}${SKILL_PROGRESS_ASSESSMENT_CONSTANTS.AndVisibilityFlag}${visibilityFlag}`;
      const response = await AuthorizedDataService.putRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response) {
        toast.success('Visibility updated successfully');
        setViewLearnersData((prevData) =>
          prevData.map((learner) =>
            learner.learnerAssociationUniqueId === uniqueId
              ? { ...learner, isLearnerAssociationEnabled: visibilityFlag }
              : learner
          )
        );
      } else {
        toast.error('Failed to update visibility');
      }
    } catch (error) {
      console.error('Error updating visibility:', error);
      toast.error('Failed to update visibility');
    }
  };

  const loadAssessmentData = async (assessmentUniqueId) => {
    try {
      setAssessmentData({});
      // Fetch templates
      const templatesResponse = await AuthorizedDataService.getRequest(
        SKILL_PROGRESS_ASSESSMENT_CONSTANTS.fetchTemplatesAPI,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setTemplatesList(templatesResponse || []);

      async function getCoursesData() {
        try {
          // URLs for different course statuses
          const ongoingUrl = `${AWARDS_CONSTANTS.getCourseByStatusAndAssignedApi}${AppConstants.courseStatus.ongoing}`;
          const upcomingUrl = `${AWARDS_CONSTANTS.getCourseByStatusAndAssignedApi}${AppConstants.courseStatus.upcoming}`;
          const pastUrl = `${AWARDS_CONSTANTS.getCourseByStatusAndAssignedApi}${AppConstants.courseStatus.past}`;

          // Fetching all courses concurrently
          const [ongoingResponse, upcomingResponse, pastResponse] =
            await Promise.all([
              AuthorizedDataService.getRequest(
                ongoingUrl,
                AppConstants.emptyString,
                AppConstants.emptyString
              ),
              AuthorizedDataService.getRequest(
                upcomingUrl,
                AppConstants.emptyString,
                AppConstants.emptyString
              ),
              AuthorizedDataService.getRequest(
                pastUrl,
                AppConstants.emptyString,
                AppConstants.emptyString
              ),
            ]);

          // Combining all the courses
          const allCourses = [
            ...(ongoingResponse || []),
            ...(upcomingResponse || []),
            ...(pastResponse || []),
          ];

          // Setting the combined list of courses
          setOngoingCourseList(allCourses);
        } catch (error) {
          console.error('Error fetching courses data:', error);
          setOngoingCourseList([]); // Optionally, handle the error by setting an empty list
        }
      }

      // Call the function to fetch and update courses data
      getCoursesData();

      // Fetch assessment data
      const assessmentResponse = await AuthorizedDataService.getRequest(
        `${SKILL_PROGRESS_ASSESSMENT_CONSTANTS.getAssessmentDetailsForUniqueId}${assessmentUniqueId}`,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (assessmentResponse) {
        setAssessmentData(assessmentResponse);
        setViewLearnersData(assessmentResponse.learnerAssessment);
      } else {
        toast.error('Failed to load assessment data');
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast.error('Failed to load assessment data');
    }
  };

  const skillProgressAssessmentColumnDefs = [
    {
      headerName: 'Id',
      field: 'assessmentStringId',
      sortable: true,
      filter: true,
      width: 120,
      valueGetter: (params) => params.data.assessmentStringId,
      tooltipValueGetter: (params) =>
        params.data.assessmentStringId || AppConstants.notAvailableText,
    },
    {
      headerName: 'Assessment Type',
      field: 'assessmentType',
      sortable: true,
      filter: true,
      headerClass: 'multi-line-header',
      sort: 'asc',
      width: 200,
    },
    {
      headerName: 'Assessment Description',
      field: 'assessmentDescription',
      sortable: true,
      filter: true,
      headerClass: 'multi-line-header',
      sort: 'asc',
      minWidth: 400,
      suppressSizeToFit: true,
      valueGetter: (params) =>
        params.data.assessmentDescription || AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.data.assessmentDescription || AppConstants.notAvailableText,
    },
    {
      headerName: 'Assessment Date',
      field: 'createdOn',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 200,
      tooltipValueGetter: ({ data }) =>
        data.createdOn
          ? `${moment.utc(data.createdOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.createdOn
          ? `${moment.utc(data.createdOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
    },
    {
      headerName: 'Template Name',
      field: 'templateName',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 250,
      suppressSizeToFit: true,
      tooltipValueGetter: (params) =>
        params.data.templateName || AppConstants.notAvailableText,
    },
    {
      headerName: 'Learners Marked',
      field: 'learnersMapped',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 120,
      tooltipValueGetter: (params) =>
        params.data.learnersMapped || AppConstants.notAvailableText,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      headerClass: 'multi-line-header',
      sortable: false,
      width: 120,
      cellRendererFramework: (params) => {
        return (
          <div className={classes.buttonColumn}>
            <span data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <FaEye
                className={classes.editButton}
                onClick={async () => {
                  setViewAssessmentId(params.data.assessmentUniqueId);
                  handleEditClick(params.data.assessmentUniqueId);
                }}
              />
            </span>

            {/* Edit Icon */}
            <span data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
              <FaRegEdit
                className={classes.editButton}
                onClick={async () => {
                  setEditingAssessmentId(params.data.assessmentUniqueId);
                  handleEditClick(params.data.assessmentUniqueId);
                }}
              />
            </span>
          </div>
        );
      },
    },
  ];

  const viewLearnerMarks = (learnerQrenciaId) => {
    const selectedData = viewLearnersData.find(
      (learner) => learner.learnerQrenciaId === learnerQrenciaId
    );
    setSelectedLearnerData(selectedData || null);
    setViewMarksForLearnerId(learnerQrenciaId);
  };

  const processHeader = (headerName) => {
    if (!headerName) return { headerName: '', headerTooltip: undefined };

    const words = headerName.split(/\s+/);
    const shouldTruncate = words.length > 5;

    return {
      headerName: shouldTruncate
        ? `${words.slice(0, 5).join(' ')}...`
        : headerName,
      headerTooltip: shouldTruncate ? headerName : undefined,
      headerClass: shouldTruncate ? 'ag-header-cell-tooltip' : '',
    };
  };

  const mappedLearnersGrid = (data) => {
    const parameters = uniqueParameters(data);

    return [
      {
        headerName: 'Qrencia Id',
        field: 'learnerQrenciaId',
        sortable: true,
        filter: true,
        width: 120,
        hide: true,
        valueGetter: (params) => params.data.learnerQrenciaId,
        tooltipValueGetter: (params) =>
          params.data.learnerQrenciaId || AppConstants.notAvailableText,
      },
      {
        headerName: 'Learner Name',
        field: 'learnerName',
        headerClass: 'multi-line-header',
        sortable: true,
        filter: true,
        width: 200,
        tooltipValueGetter: (params) =>
          `${params.data.learnerName} (Qrencia Id : ${params.data.learnerQrenciaId})` ||
          AppConstants.notAvailableText,
      },
      ...parameters.map((param) => ({
        ...processHeader(param.parameterName),
        field: `param_${param.parameterId}`,
        headerClass: 'multi-line-header',
        width: 120,
        valueGetter: (params) => {
          const paramScore = params.data.parameterScores.find(
            (p) => p.parameterId === param.parameterId
          );
          return paramScore
            ? `${paramScore.parameterScore}/${paramScore.parameterMaximumMarks}`
            : AppConstants.notAvailableText;
        },
        tooltipValueGetter: (params) => {
          const paramScore = params.data.parameterScores.find(
            (p) => p.parameterId === param.parameterId
          );
          return paramScore
            ? `${paramScore.parameterScore}/${paramScore.parameterMaximumMarks}`
            : AppConstants.notAvailableText;
        },
      })),
      {
        headerName: 'Total Marks',
        field: 'totalScore',
        width: 120,
        valueGetter: (params) =>
          `${params.data.totalScore ?? AppConstants.notAvailableText}/${params.data.totalMaxMarks || ''}`,
        tooltipValueGetter: (params) =>
          `${params.data.totalScore ?? AppConstants.notAvailableText}/${params.data.totalMaxMarks || ''}`,
      },
      {
        headerName: 'Recommendation',
        field: 'recommendations',
        headerClass: 'multi-line-header',
        sortable: true,
        filter: true,
        // width: 200,
        minWidth: 250,
        flex: 2,
        tooltipValueGetter: (params) =>
          params.data.recommendations || AppConstants.notAvailableText,
        valueGetter: (params) =>
          params.data.recommendations?.trim()
            ? params.data.recommendations
            : 'N/A',
      },
      {
        headerName: 'Actions',
        field: 'actions',
        width: 160, // Increased width to accommodate the toggle button
        cellRendererFramework: (params) => {
          return (
            <>
              {/* Toggle Button */}
              <Button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  params.data.isLearnerAssociationEnabled === 'Y'
                    ? 'Restrict'
                    : 'Enable'
                }
                className={`gridIconButton ${classes.gridIcon} ${classes.toggleBtn} ${classes.gridIconMargin}`}
                variant={
                  params.data.isLearnerAssociationEnabled === 'Y'
                    ? 'success'
                    : 'danger'
                } // Green for active, Red for inactive
                disabled={togglingId === params.data.learnerAssociationUniqueId}
                onClick={async () => {
                  const currentVisibility =
                    params.data.isLearnerAssociationEnabled;
                  const newVisibility = currentVisibility === 'Y' ? 'N' : 'Y';
                  const uniqueId = params.data.learnerAssociationUniqueId;

                  try {
                    setTogglingId(uniqueId);
                    await updateVisibilityFlag(uniqueId, newVisibility);
                  } finally {
                    setTogglingId(null);
                  }
                }}
              >
                {params.data.isLearnerAssociationEnabled === 'Y' ? (
                  <BsToggleOn size={20} style={{ color: '#28a745' }} />
                ) : (
                  <BsToggleOff size={20} style={{ color: '#dc3545' }} />
                )}
              </Button>

              <Button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Assessment Report"
                className={`gridIconButton ${classes.gridIcon} ${classes.reportIcon} ${classes.gridIconMargin}`}
                disabled={
                  params.data.isLearnerAssociationEnabled ===
                  AppConstants.falseFlag
                }
                onClick={() => {
                  if (params.data.learnerAssociationUniqueId) {
                    window.open(
                      `/learner-assessment/${params.data.learnerAssociationUniqueId}`,
                      '_blank'
                    );
                  }
                }}
              >
                <TbExternalLink />
              </Button>

              <Button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Marks"
                className={`gridIconButton ${classes.gridIcon} ${classes.marksIcon}`}
                onClick={() => viewLearnerMarks(params.data.learnerQrenciaId)}
              >
                <BsFileEarmarkSpreadsheet />
              </Button>
            </>
          );
        },
      },
    ];
  };

  const selectedLearnerMarksGrid = [
    {
      ...processHeader('Parameter Name'),
      field: 'parameterName',
      sortable: true,
      filter: true,
      width: 120,
      valueGetter: (params) =>
        params.data.parameterName || AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.data.parameterName || AppConstants.notAvailableText,
    },
    {
      headerName: 'Marks Alloted',
      field: 'parameterScore',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 120,
      valueGetter: (params) => {
        const totalScore =
          params.data.parameterScore ?? AppConstants.notAvailableText;
        const maxMarks = params.data.parameterMaximumMarks;
        return maxMarks ? `${totalScore}/${maxMarks}` : `${totalScore}`;
      },
      tooltipValueGetter: (params) => {
        const totalScore =
          params.data.parameterScore ?? AppConstants.notAvailableText;
        const maxMarks = params.data.parameterMaximumMarks;
        return maxMarks ? `${totalScore}/${maxMarks}` : `${totalScore}`;
      },
    },
    {
      headerName: 'Remarks',
      field: 'parameterRemarks',
      sortable: true,
      filter: true,
      width: 110,
      valueGetter: (params) =>
        params.data.parameterRemarks || AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.data.parameterRemarks || AppConstants.notAvailableText,
    },
  ];

  const exportToExcel = useCallback(() => {
    let columnDefs = mappedLearnersGrid(viewLearnersData).filter(
      (col) => col.field !== 'actions'
    );

    const csvString = gridRef.current?.api.getDataAsCsv({
      columnKeys: columnDefs.map((col) => col.field),
      columnSeparator: AppConstants.agGridColumnSeparator,
      suppressQuotes: true,
      suppressHeader: true, // Should prevent headers, but let's handle it manually too
    });

    if (!csvString) {
      console.error('CSV data is empty.');
      return;
    }

    // Convert CSV string to array of arrays (rows)
    let csvData = csvString
      .trim()
      .split('\n')
      .map((row) => row.split(AppConstants.agGridColumnSeparator));

    // Ensure headers are removed if still present
    if (csvData.length > 0 && csvData[0][0] === 'Qrencia Id') {
      csvData.shift(); // Remove the first row (headers)
    }

    // Apply text formatting to prevent automatic conversion (like 3/12 → date)
    csvData = csvData.map(
      (row) => row.map((value) => `\t${value}`) // Add a tab to force text format
    );

    // Define assessment details (first few rows)
    const assessmentDetails = [
      ['Assessment Id', assessmentData.assessmentStringId],
      [
        'Description',
        assessmentData.assessmentDescription &&
          assessmentData.assessmentDescription.trim() !== ''
          ? assessmentData.assessmentDescription
          : 'N/A',
      ],
      [
        'Date',
        assessmentData.createdOn &&
          assessmentData.createdOn !== AppConstants.nullText
          ? `${moment.utc(assessmentData.createdOn).tz(timeZone).format('DD MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : 'N/A',
      ],
    ];

    // Send assessment details and cleaned CSV data separately
    FileOperationsUtil.downloadAssessmentCsvDataAsExcelWithStyling(
      assessmentDetails,
      csvData, // Now only contains values, formatted with text protection
      `LearnerAssessmentMarkingDetails_${assessmentData.assessmentStringId}.xlsx`,
      columnDefs
    );
  }, [viewLearnersData]);

  return (
    <div>
      {editingAssessmentId &&
        Object.keys(assessmentData).length > 0 &&
        templatesList.length > 0 &&
        ongoingCourseList.length > 0 ? (
        <div className={classes.editSection}>
          <IoArrowBackCircle
            onClick={handleEditComplete}
            className={classes.backBtn}
          />
          <EditAssessment
            assessmentId={editingAssessmentId}
            onSuccess={handleEditComplete}
            assessmentData={assessmentData}
            templatesList={templatesList}
            ongoingCourseList={ongoingCourseList}
            setOngoingCourseList={setOngoingCourseList}
            setAssessmentData={setAssessmentData}
          />
        </div>
      ) : viewAssessmentId &&
        !viewMarksForLearnerId &&
        Object.keys(assessmentData).length > 0 ? (
        <div className={classes.viewConatiner}>
          <TiArrowLeft onClick={viewBackClick} className={classes.backButton} />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={classes.columnContainer}>
                <h5 className={classes.sectionHeading}>Assessment Details</h5>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} xl={4}>
              <div className={classes.columnContainer}>
                <p className={classes.detailsLabel}>Id</p>
                <p className={classes.detailsText}>
                  {assessmentData.assessmentStringId !== AppConstants.nullText
                    ? assessmentData.assessmentStringId
                    : AppConstants.notAvailableText}
                </p>
              </div>
            </Col>
            <Col
              className={classes.emailColumn}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
            >
              <div className={classes.columnContainer}>
                <p className={classes.detailsLabel}>Date</p>
                <p className={classes.expertEmailText}>
                  {assessmentData.createdOn !== AppConstants.nullText
                    ? `${moment.utc(assessmentData.createdOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
                    : AppConstants.notAvailableText}
                </p>
              </div>
            </Col>
            <Col
              className={classes.emailColumn}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
            >
              <div className={classes.columnContainer}>
                <p className={classes.detailsLabel}>Template Name</p>
                <p className={classes.expertEmailText}>
                  {assessmentData.templateName !== AppConstants.nullText
                    ? assessmentData.templateName
                    : AppConstants.notAvailableText}
                </p>
              </div>
            </Col>

            <Col xs={12} sm={6} md={4} lg={4} xl={4}>
              <div className={classes.columnContainer}>
                <p className={classes.detailsLabel}>Template Skill</p>
                <p className={classes.detailsText}>
                  {assessmentData.templateSkill !== AppConstants.nullText
                    ? assessmentData.templateSkill
                    : AppConstants.notAvailableText}
                </p>
              </div>
            </Col>

            <Col xs={12} sm={6} md={4} lg={4} xl={4}>
              <div className={classes.columnContainer}>
                <p className={classes.detailsLabel}>Learners Marked</p>
                <p className={classes.detailsText}>
                  {assessmentData.learnersMapped !== AppConstants.nullText
                    ? assessmentData.learnersMapped
                    : AppConstants.notAvailableText}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={classes.columnContainer}>
                <p className={classes.detailsLabel}>Description</p>
                <p className={classes.descriptionText}>
                  {assessmentData.assessmentDescription !==
                    AppConstants.nullText
                    ? assessmentData.assessmentDescription
                    : AppConstants.notAvailableText}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3 mt-2">
              <div className={classes.columnContainer}>
                <h5 className={classes.sectionHeading}>
                  {viewMarksForLearnerId ? 'Learner Marks' : 'Learners Details'}
                </h5>
              </div>
            </Col>
            <Col>
              <div
                className="ag-theme-alpine"
                style={{ width: '100%', height: '100%' }}
              >
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  columnDefs={mappedLearnersGrid(viewLearnersData)}
                  rowData={viewLearnersData}
                  pagination={true}
                  paginationPageSize={10}
                  domLayout="autoHeight"
                  defaultColDef={{
                    filter: true,
                    sortable: true,
                    flex: 1,
                    minWidth: 160,
                    resizable: true,
                    suppressMenu: true,
                    suppressHorizontalScroll: true,
                    floatingFilterComponentParams: {
                      suppressFilterButton: true,
                    },
                    floatingFilter: true,
                    // wrapHeaderText: true, // Enables text wrapping
                    // autoHeight: true, // Adjusts height based on content
                    getHeaderHeight: (params) => {
                      return params.columnApi
                        .getAllDisplayedColumns()
                        .some((col) => col.colDef.headerName.includes('<br>'))
                        ? 80
                        : 50;
                    },
                  }}
                  suppressRowHoverHighlight={true}
                  enableCellTextSelection={true}
                  ensureDomOrder={true}
                />
              </div>
            </Col>
            {viewLearnersData?.length > 0 && (
              <div className="mt-2">
                <Button
                  className={classes.exportButton}
                  onClick={exportToExcel}
                >
                  Download Report
                </Button>
              </div>
            )}
          </Row>
        </div>
      ) : viewMarksForLearnerId ? (
        <div className={classes.viewConatiner}>
          <div key={assessmentData.learnerAssessment.learnerId}>
            <TiArrowLeft
              onClick={viewAssessmentScreen}
              className={classes.backButton}
            />
            <div className="row py-2">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <p className={classes.detailsLabel}>Qrencia Id</p>
                <p className={classes.detailsText}>
                  {selectedLearnerData.learnerQrenciaId !==
                    AppConstants.nullText
                    ? selectedLearnerData.learnerQrenciaId
                    : AppConstants.notAvailableText}
                </p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <p className={classes.detailsLabel}>Name</p>
                <p className={classes.detailsText}>
                  {selectedLearnerData.learnerName !== AppConstants.nullText
                    ? selectedLearnerData.learnerName
                    : AppConstants.notAvailableText}
                </p>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <p className={classes.detailsLabel}>Marks</p>
                <p className={classes.detailsText}>
                  {(() => {
                    const learner = viewLearnersData.find(
                      (learner) =>
                        learner.learnerQrenciaId ===
                        selectedLearnerData.learnerQrenciaId
                    );

                    if (learner) {
                      return learner.totalMaxMarks
                        ? `${learner.totalScore ?? AppConstants.notAvailableText}/${learner.totalMaxMarks}`
                        : `${learner.totalScore ?? AppConstants.notAvailableText}`;
                    } else {
                      return AppConstants.notAvailableText;
                    }
                  })()}
                </p>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <p className={classes.detailsLabel}>Assessment Report Link</p>
                {(() => {
                  const learner = viewLearnersData.find(
                    (learner) =>
                      learner.learnerQrenciaId ===
                      selectedLearnerData.learnerQrenciaId
                  );

                  return learner && learner.learnerAssociationUniqueId ? (
                    <TbExternalLink
                      onClick={() =>
                        window.open(
                          `/learner-assessment/${learner.learnerAssociationUniqueId}`,
                          '_blank'
                        )
                      }
                    />
                  ) : (
                    <p className={classes.descriptionText}>
                      {AppConstants.notAvailableText}
                    </p>
                  );
                })()}
              </div>

              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-8 col-xl-8 mb-3">
                <p className={classes.detailsLabel}>Recommendation</p>
                <p className={classes.descriptionText}>
                  {(() => {
                    const learner = viewLearnersData.find(
                      (learner) =>
                        learner.learnerQrenciaId ===
                        selectedLearnerData.learnerQrenciaId
                    );

                    if (learner) {
                      return (
                        learner.recommendations || AppConstants.notAvailableText
                      );
                    } else {
                      return AppConstants.notAvailableText;
                    }
                  })()}
                </p>
              </div>
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ width: '100%', height: '100%' }}
          >
            <AgGridReact
              ref={gridRef}
              onGridReady={onGridReady}
              columnDefs={selectedLearnerMarksGrid}
              rowData={selectedLearnerData.parameterScores}
              pagination={true}
              paginationPageSize={10}
              domLayout="autoHeight"
              defaultColDef={{
                filter: true,
                sortable: true,
                flex: 1,
                minWidth: 160,
                resizable: true,
                suppressMenu: true,
                suppressHorizontalScroll: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                floatingFilter: true,
              }}
              suppressRowHoverHighlight={true}
              enableCellTextSelection={true}
              ensureDomOrder={true}
            />
          </div>
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ width: '100%', height: '100%' }}
        >
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            columnDefs={skillProgressAssessmentColumnDefs}
            rowData={rowData}
            pagination={true}
            paginationPageSize={10}
            domLayout="autoHeight"
            defaultColDef={{
              filter: true,
              sortable: true,
              flex: 1,
              minWidth: 160,
              resizable: true,
              suppressMenu: true,
              suppressHorizontalScroll: true,
              floatingFilterComponentParams: { suppressFilterButton: true },
              floatingFilter: true,
            }}
            suppressRowHoverHighlight={true}
            enableCellTextSelection={true}
            ensureDomOrder={true}
          />
        </div>
      )}
    </div>
  );
};
