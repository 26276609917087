import { useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import logoStyle from "./ExpressionOfInterestLogoCarousel.module.css";
import { AppConstants } from "../../../constants/AppConstants";

export const ExpressionOfInterestLogoCarousel = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const logos = AppConstants.logoImages.map((logo) => ({
        ...logo,
        fullPath: `${mediaContent}${logo.logoImage}`,
    }));

    const logoBarRef = useRef(null);

    useEffect(() => {
        const logoBar = logoBarRef.current;
        let start = 0;
        let animationFrameId;

        const animate = () => {
            start -= 1; // Adjust speed by changing this value
            if (Math.abs(start) >= logoBar.scrollWidth / 2) {
                start = 0; // Reset position to create a seamless loop
            }
            logoBar.style.transform = `translateX(${start}px)`;
            animationFrameId = requestAnimationFrame(animate);
        };

        animationFrameId = requestAnimationFrame(animate);

        return () => cancelAnimationFrame(animationFrameId); // Cleanup on unmount
    }, []);

    return (
        <Row>
            <div className={logoStyle.logoSection}>
                <div className={logoStyle.logoDescription}>
                    <p>
                        <span>|</span>
                        {AppConstants.trustedByGlobalEducators}
                    </p>
                </div>

                <div className={logoStyle.logoContainer}>
                    <div className={logoStyle.logoBar} ref={logoBarRef}>
                        {logos.map((logo, index) => (
                            <div key={index} className={logoStyle.carouselItem}>
                                <img
                                    className={logoStyle.logoImage}
                                    src={logo.fullPath}
                                    alt={`Company Logo ${logo.logoId}`}
                                />
                            </div>
                        ))}
                        {/* Duplicate logos seamlessly */}
                        {logos.map((logo, index) => (
                            <div key={`duplicate-${index}`} className={logoStyle.carouselItem}>
                                <img
                                    className={logoStyle.logoImage}
                                    src={logo.fullPath}
                                    alt={`Company Logo ${logo.logoId}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Row>
    );
};
