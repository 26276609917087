import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import popUpstyle from './signUpPopUp.module.css';
import { AppConstants } from '../../constants/AppConstants';

const SignUpPopUp = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const popupImageUrl = `${mediaContent}${AppConstants.aboutLearnerPassImage}`;

  const closePopup = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <div className={popUpstyle.popupOverlay}>
          <div className={popUpstyle.popupContainer}>
            <FaTimes
              className={popUpstyle.closeIcon}
              onClick={() => {
                onClose();
                closePopup();
              }}
              title="Close"
            />
            <img
              className={popUpstyle.popUpImageOnly}
              src={popupImageUrl}
              alt="About Learner Pass"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpPopUp;
