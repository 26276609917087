import React from 'react';
import { useState, useEffect, useRef } from 'react';
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import 'cal-heatmap/cal-heatmap.css';
import styles from './Heatmap.module.css';
import { useLearnerProfileHeatmapStore } from '../../../stores';
import dayjs, { locale } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';
import {
  MdOutlineArrowCircleLeft,
  MdOutlineArrowCircleRight,
} from 'react-icons/md';
import { min } from 'moment';
import { LEARNER_PROFILE_DATE } from '../../../constants/AppConstants';

dayjs.extend(timezone);
dayjs.extend(utc);

const Heatmap = () => {
  const heatmapRef = useRef();
  const heatmapContainerRef = useRef(null);
  let isPainted = false;

  const cal = useRef(null);

  const { learnerProfileHeatmapData } = useLearnerProfileHeatmapStore();
  const [userTimezone, setUserTimezone] = useState(
    DateTimeUtil.getPreferredTimeZoneForUser()
  );

  useEffect(() => {
    if (!isPainted) {
      isPainted = true;
      cal.current = new CalHeatmap();

      let dataInUTC = [...learnerProfileHeatmapData];

      const activityCountPerDay = {};

      dataInUTC.forEach((item) => {
        let date = item.nudgeId
          ? item.nudgeRaisedOn?.split(' ')[0]
          : item.enrolledOn?.split(' ')[0];

        if (date && userTimezone) {
          date = dayjs.utc(date).tz(userTimezone).format('YYYY-MM-DD');
        }

        if (!activityCountPerDay[date]) {
          activityCountPerDay[date] = {
            date,
            value: 0, // Total count of activities
            stats: {
              course: 0,
              competition: 0,
              workshop: 0,
              nudge: 0,
            },
          };
        }

        if (item.learningActivityType === 'Competition') {
          activityCountPerDay[date].stats.competition += 1;
        } else if (item.learningActivityType === 'Course') {
          activityCountPerDay[date].stats.course += 1;
        } else if (item.learningActivityType === 'Workshop') {
          activityCountPerDay[date].stats.workshop += 1;
        } else if (item.nudgeId) {
          activityCountPerDay[date].stats.nudge += item.nudgeRaisedOn ? 1 : 0;
        }

        activityCountPerDay[date].value += 1;
      });

      const transformedDataForHeatmap = Object.values(activityCountPerDay);

      try {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const twelveMonthsAgo = dayjs()
          .subtract(6, 'months')
          .format('DD MMM YYYY');

        const heatmapMinDate = dayjs(
          LEARNER_PROFILE_DATE.qrenciaInceptionDate
        ).format('YYYY-MM-DD');
        const heatmapMaxDate = dayjs().add(2, 'months').format('YYYY-MM-DD');
        cal.current.paint(
          {
            itemSelector: heatmapRef.current,
            theme: 'light',
            date: {
              start: new Date(twelveMonthsAgo),
              highlight: new Date(currentDate),
              min: new Date(heatmapMinDate),
              max: new Date(heatmapMaxDate),
            },
            data: {
              source: transformedDataForHeatmap,
              type: 'json',
              x: 'date',
              y: 'value',
              subDomainTitle: (date, value) => {
                return value;
              },
            },
            range: 7,
            domain: {
              type: 'month',
              gutter: 20,
              label: {
                text: (timestamp) => {
                  const formattedMonthYear =
                    dayjs(timestamp).format('MMMM YYYY');
                  return formattedMonthYear;
                },
                height: 50,
              },
            },
            subDomain: {
              type: 'day',
              width: 31,
              height: 31,
              gutter: 4,
              radius: 4,
              label: 'DD',
            },
            scale: {
              color: {
                range: ['#ffffff', '#224b7f'],
                interpolate: 'hsl',
                type: 'linear',
                domain: [0, 10],
              },
            },
          },
          [
            [
              Tooltip,
              {
                text: function (date, value, dayjsDate) {
                  const clickedDate = new Date(date)
                    .toISOString()
                    .split('T')[0];

                  const formattedDate = dayjs(date).format('DD MMM YYYY');

                  const matchingEntry = transformedDataForHeatmap.find(
                    (item) => item.date === clickedDate
                  );

                  if (matchingEntry) {
                    const logForTheDay = `
                    <div>Course: ${matchingEntry.stats.course} </div>
                    <div>Competition: ${matchingEntry.stats.competition}</div>
                    <div>Workshop: ${matchingEntry.stats.workshop}</div> 
                    <div>Nudges Raised: ${matchingEntry.stats.nudge}</div>
                    `;
                    return logForTheDay;
                  } else {
                    return `No activity on ${formattedDate}`;
                  }
                },
                allowHTML: true,
              },
            ],
          ]
        );

        return () => {
          cal.current.destroy();
        };
      } catch (error) {
        console.error('Error rendering the component:', error);
      }
    }
  }, []);

  const handlePrevious = () => {
    if (heatmapContainerRef.current) {
      const container = heatmapContainerRef.current;
      container.scrollBy({
        left: -container.clientWidth * 0.8,
        behavior: 'smooth',
      });
    }
  };

  const handleNext = () => {
    if (heatmapContainerRef.current) {
      const container = heatmapContainerRef.current;
      container.scrollBy({
        left: container.clientWidth * 0.8,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.heatmapSection}>
      <h2 className={styles.heatmapHeading}>Learning Journey</h2>
      <div className={styles.heatmapContainer} ref={heatmapContainerRef}>
        <div
          id="cal-heatmap"
          className={styles.calHeatmap}
          ref={heatmapRef}
        ></div>
      </div>
      <div className="chevronIconContainer">
        <div className={styles.chevronIcon}>
          <MdOutlineArrowCircleLeft
            onClick={handlePrevious}
            className={styles.iconLeft}
          />
          <MdOutlineArrowCircleRight
            onClick={handleNext}
            className={styles.iconRight}
          />
        </div>
      </div>
    </div>
  );
};

export default Heatmap;
