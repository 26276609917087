import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { DateTime } from "luxon";
import classes from '../CompetitionsCards.module.css';
import Moment from 'react-moment';
import 'moment-timezone';
import '../CompetitionCards.css';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { CgPin } from "react-icons/cg";
import { AppConstants } from "../../../constants/AppConstants";
import { TbShare3 } from "react-icons/tb";
import Auth from "../../../utils/Auth";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";

export const MicrositeWorkshopCards = (props) => {
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.workshopsData);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [editIndex, setEditIndex] = useState(AppConstants.nullText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [payloadData, setPayloadData] = useState({});
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultThumbnailImage}`;
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    //set cardCount to 4 on tablet screen and 2 on mobile screen
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    //Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.workshopSlug}`);
    }

    useEffect(() => {
        const verifyPayment = async (data, workshop) => {
            try {
                PaymentsUtil.verifyPayment(data).then((response) => {
                    if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                        setPaymentStatus(AppConstants.paymentStatus.verified);
                        setTransactionId(response.transactionId);
                        setModalTypeError(AppConstants.falseText);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        enrolHere(workshop.workshopId, workshop.workshopTitle, workshop.uniqueId, AppConstants.paymentStatus.verified);
                    } else {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(response.message);
                        setTransactionId(response.transactionId);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        handleModalShow();
                    }
                }).catch((error) => {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.verifyPaymentFailedError);
                    setPaymentStatus(AppConstants.emptyString);
                    window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                    handleModalShow();
                });
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return AppConstants.nullText;
            }
        };

        if (paymentParam && cardDetails.length > 0) {
            const data = decodeBase64EncodedString(paymentParam);
            if (data !== AppConstants.nullText && data.category === props.category) {
                const workshop = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!workshop) {
                    setPayloadData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                data.learningActivityName = workshop.workshopTitle;
                setPayloadData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, workshop);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }
    }, [paymentParam, cardDetails]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, creatorName, payOfflineFlag, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.workshop, payOfflineFlag, creatorName, creatorRole });
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolHere(id, title, uniqueId);
                }
            }
        } else {
            enrolHere(id, title, uniqueId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolHere = async (id, title, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.workshopEnrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }

        const workshop = cardDetails.filter(card => card.uniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status)
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    }

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setTransactionId(response.razorpay_payment_id);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    enrolHere(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };
    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }

    const handleModalClose = () => {
        if (paymentParam) {
            window.location.replace(window.location.href.split("?")[0]);
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else if (transactionId) {
                window.location.reload();
            } else {
                if (modalTypeError) {
                    setShowModal(AppConstants.falseText);
                    setConfirmationPopupActivityDetails({});
                } else {
                    window.location.reload();
                }
            }
        }
    }

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {cardDetails.slice(0, cardCount).map((cardData, index) => (
                    <Col key={cardData.uniqueId}>
                        <Card className="cardDiv" onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${cardData.skillCategorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.workshopSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImageAbsolutePath ? cardData.thumbnailImageAbsolutePath : defaultWorkshopThumbnail} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(cardData) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div className="cardSubTitle workshopDiffColor">{cardData.workshopModeOfDelivery.toUpperCase() === AppConstants.offlineText ? <span className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.uniqueId}><a onClick={stopPropagation} href={cardData.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconWorkshop"><CgPin className="geoLocationIcon onlineIconWorkshop" /> {cardData.workshopModeOfDelivery.toUpperCase()}</a></span> : <span className="onlineIconWorkshop modeSubtitle">{cardData.workshopModeOfDelivery.toUpperCase()}</span>}</div>
                                    <Tooltip className="tooltipMessage" id={cardData.uniqueId}><span className="tooltipMessage">{cardData.address}</span></Tooltip>
                                </div>
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${cardData.uniqueId}-title`}>{cardData.workshopTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${cardData.uniqueId}-title`}><span>{cardData.workshopTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                {props.userRoleForMicrosite === AppConstants.userRoleAgency && <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.userRole === AppConstants.userRoleAgency ? cardData.agencyName : cardData.organizerName}</Card.Text>}
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> {cardData.perferredTimeZone === AppConstants.utcTimezone && `(${cardData.perferredTimeZone})`}</span>
                                    </div>
                                </div>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> {cardData.perferredTimeZone === AppConstants.utcTimezone && `(${cardData.perferredTimeZone})`}</span>
                                    </div>
                                </div>
                                <div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.workshopCost, discount: 0 })}</div>
                                    {((cardData.isOfflinePaymentRequested === AppConstants.nText || !cardData.isOfflinePaymentRequested) && (cardData.isLoggedInStudentEnrolled === AppConstants.falseText || cardData.isLoggedInStudentEnrolled === null)) && <Button type="button" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.workshopId, title: cardData.workshopTitle, uniqueId: cardData.uniqueId, startTime: cardData.utcStartDate, payOfflineFlag: cardData.payOfflineFlag, creatorName: cardData.userRole === AppConstants.userRoleAgency ? cardData.agencyName : cardData.organizerName, creatorRole: cardData.userRole }) }} className="cardButton">Enrol Now</Button>}
                                    {(cardData.isLoggedInStudentEnrolled === AppConstants.trueText || cardData.isOfflinePaymentRequested === AppConstants.yText) && cardData && <Button onClick={stopPropagation} type="button" className="cardButton" disabled>{cardData.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrolled"}</Button>}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails.length && <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</Button>}
                </Col>
            </Row>
            {(message !== AppConstants.emptyString || props.category === payloadData.category) && (showModal || props.category === payloadData.category) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} enrolInWorkshop={enrolHere} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} paymentStatus={paymentStatus} confirmBeforeEnrolment={confirmBeforeEnrolment} transactionId={transactionId} learningActivityStartTime={confirmationPopupActivityDetails.startTime} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    )
}

