import { useState, useEffect } from 'react';
import classes from './CreateAssessments.module.css';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as Yup from 'yup';
import Table from 'react-bootstrap/Table';
import formClass from '../../common/FormStyling.module.css';
import { ErrorMessages } from '../../constants/ErrorMessages';
import toast from 'react-hot-toast';
import {
  SKILL_PROGRESS_ASSESSMENT_CONSTANTS,
  AWARDS_CONSTANTS,
  AppConstants,
} from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { MdClear } from 'react-icons/md';
import {
  PiNumberSquareOne,
  PiNumberSquareTwo,
  PiNumberSquareThree,
} from 'react-icons/pi';

const EditAssessment = ({
  assessmentId,
  onSuccess,
  templatesList,
  ongoingCourseList,
  assessmentData,
}) => {
  const formLabels =
    SKILL_PROGRESS_ASSESSMENT_CONSTANTS.createAssessmentFormLabels;

  const assessmentTypes = SKILL_PROGRESS_ASSESSMENT_CONSTANTS.assessmentTypes;
  const markingTypes = SKILL_PROGRESS_ASSESSMENT_CONSTANTS.markingTypes;
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(
    assessmentData.assessmentType || assessmentTypes[0]
  );
  const [markingType, setMarkingType] = useState(markingTypes[1]);

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  useEffect(() => {
    initializeFormWithAssessmentData(assessmentData, ongoingCourseList);
  }, []);

  const initializeFormWithAssessmentData = (assessmentData, courses) => {
    // Ensure learnerAssessment exists
    const assessmentParticipants = (
      assessmentData?.learnerAssessment || []
    ).map((learner) => ({
      studentId: learner.learnerId,
      courseId: learner.courseId,
      courseIntId: learner.courseId,
      studentName: learner.learnerName,
      userQrenciaId: learner.learnerQrenciaId,
      parameters: learner.parameterScores || {},
      recommendations: learner.recommendations || '',
      totalScore: learner.totalScore || null,
      parameterRemarks: learner.parameterRemarks || {},
      learnerAssociationUniqueId: learner.learnerAssociationUniqueId,
      isLearnerAssociationEnabled: learner.isLearnerAssociationEnabled,
    }));

    // Identify courses present in assessment data
    const assessmentCourses = courses.filter(
      (course) => assessmentParticipants.some((p) => p.courseId === course.id) // Fixed Matching
    );

    // Merge assessment participants with course participants
    let mergedParticipants = [...assessmentParticipants];

    assessmentCourses.forEach((course) => {
      if (!course.participantsDetails) {
        console.warn(`No participants found for course: ${course.courseId}`);
        return;
      }

      const enhancedParticipants = (course.participantsDetails || []).map(
        (participant) => ({
          studentId: participant.studentId,
          courseId: course.id, // Ensure consistency with course object
          courseIntId: course.id, // Use course ID from course object
          courseTitle: course.courseTitle, // Fetch course title from course object
          studentName: participant.studentName,
          userQrenciaId: participant.userQrenciaId,
          parameters: {}, // Empty object for new participants
          recommendations: '', // Empty string for new participants
          totalScore: null, // Null for new participants
          parameterRemarks: {}, // Empty object for new participants
          learnerAssociationUniqueId: '',
          isLearnerAssociationEnabled: 'Y',
        })
      );

      // Prevent duplicates
      const existingParticipantKeys = new Set(
        mergedParticipants.map((p) => `${p.studentId}_${p.courseIntId}`)
      );

      const newParticipants = enhancedParticipants.filter(
        (ep) =>
          !existingParticipantKeys.has(`${ep.studentId}_${ep.courseIntId}`)
      );

      mergedParticipants = [...mergedParticipants, ...newParticipants];
    });

    setAllParticipants(mergedParticipants); // Ensure state updates properly
    setSelectedCourses(assessmentCourses);

    // Initialize form values safely
    const initialValues = {
      selectedTemplate:
        templatesList.find((t) => t.id === assessmentData?.templateId) || null,
      assessmentDate: assessmentData?.createdOn || '',
      assessmentType: assessmentData?.assessmentType || '',
      assessmentDescription: assessmentData?.assessmentDescription || '',
      markingType: assessmentData?.assessmentType || '',
      marks: mapScoresToForm(assessmentData?.learnerAssessment || []),
      remarks: mapRemarksToForm(assessmentData?.learnerAssessment || []),
      recommendations: mapRecommendationsToForm(
        assessmentData?.learnerAssessment || []
      ),
    };

    Object.keys(initialValues).forEach((key) => {
      setFieldValue(key, initialValues[key]);
    });

    setInitialDataLoaded(true);
  };

  const selectCourses = async (course) => {
    const alreadyExists = selectedCourses.some(
      (item) => item.courseId === course.courseId
    );

    if (alreadyExists) {
      setSelectedCourses((prev) =>
        prev.filter((item) => item.courseId !== course.courseId)
      );

      // Remove all participants of the deselected course
      setAllParticipants((prev) =>
        prev.filter((p) => p.courseIntId !== course.id)
      );
    } else {
      try {
        const enhancedParticipants = course.participantsDetails.map(
          (participant) => ({
            ...participant,
            courseId: course.id,
            courseIntId: course.id,
            courseTitle: course.courseTitle,
          })
        );

        setAllParticipants((prev) => {
          const existingParticipantKeys = new Set(
            prev.map((p) => `${p.studentId}_${p.courseIntId}`)
          );
          const newParticipants = enhancedParticipants.filter(
            (ep) =>
              !existingParticipantKeys.has(`${ep.studentId}_${ep.courseIntId}`)
          );
          return [...prev, ...newParticipants];
        });

        setSelectedCourses((prev) => [...prev, course]);
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    }
  };

  const mapScoresToForm = (learners) => {
    return learners.reduce((acc, learner) => {
      const compositeKey = `${learner.learnerId}_${learner.courseId}`;

      if (!acc[compositeKey]) acc[compositeKey] = {}; // Ensure structure for each learner

      // Iterate through learner's parameter scores
      (learner.parameterScores || []).forEach((param) => {
        acc[compositeKey][param.parameterId] =
          param.parameterScore !== undefined ? param.parameterScore : '';
      });

      return acc;
    }, {});
  };

  const mapRemarksToForm = (learners) => {
    return learners.reduce((acc, learner) => {
      const compositeKey = `${learner.learnerId}_${learner.courseId}`;
      learner.parameterScores.forEach((param) => {
        acc[compositeKey] = {
          ...acc[compositeKey],
          [param.parameterId]: param.parameterRemarks, // Changed to parameterId to match marks
        };
      });
      return acc;
    }, {});
  };

  const mapRecommendationsToForm = (learners) => {
    return learners.reduce((acc, learner) => {
      const compositeKey = `${learner.learnerId}_${learner.courseId}`;
      acc[compositeKey] = learner.recommendations;
      return acc;
    }, {});
  };

  const handleScoreChange = (studentId, courseId, parameterId, value) => {
    const compositeKey = `${studentId}_${courseId}`;
    const newValue =
      value === '' || value === null || value === undefined
        ? ''
        : Number(value); // Handle empty properly
    setFieldValue(`marks.${compositeKey}.${parameterId}`, newValue);
  };

  const handleRemarksChange = (studentId, courseId, parameterId, value) => {
    const compositeKey = `${studentId}_${courseId}`;
    setFieldValue(`remarks.${compositeKey}.${parameterId}`, value);
  };

  const handleRecommendationChange = (studentId, courseId, value) => {
    const compositeKey = `${studentId}_${courseId}`;
    setFieldValue(`recommendations.${compositeKey}`, value);
  };

  const validationSchema = Yup.object().shape({
    selectedTemplate: Yup.object().required('Please select a template'),
    assessmentDate: Yup.date().required('Please enter assessment date'),
    assessmentType: Yup.string().required('Please select assessment type'),
    assessmentDescription: Yup.string()
      .test(
        'valid-description',
        'Please enter a valid description',
        (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }
      )
      .test('min-characters', 'Please add more than 3 characters', (value) => {
        if (!value) return true;
        return value.trim().length >= 3;
      })
      .max(200, 'Description must be at most 200 characters'),
    markingType: Yup.string().required('Please select marking type'),
    marks: Yup.object()
      .test(
        'complete-marks',
        'Please enter marks for this parameters',
        (marks) => {
          if (allParticipants.length === 0) return true;

          return allParticipants.every((participant) => {
            const compositeKey = `${participant.studentId}_${participant.courseId}`;
            const studentMarks = marks?.[compositeKey] || {};

            const hasAnyMarks = values.selectedTemplate?.parameters?.some(
              (param) =>
                studentMarks[param.id] !== undefined &&
                studentMarks[param.id] !== ''
            );

            if (!hasAnyMarks) return true;

            return values.selectedTemplate?.parameters?.every(
              (param) =>
                studentMarks[param.id] !== undefined &&
                studentMarks[param.id] !== ''
            );
          });
        }
      )
      .test('max-marks', 'Marks cannot be more than maximum marks', (marks) => {
        if (!values.selectedTemplate?.parameters) return true;

        return allParticipants.every((participant) => {
          const compositeKey = `${participant.studentId}_${participant.courseId}`;
          const studentMarks = marks?.[compositeKey] || {};

          return values.selectedTemplate.parameters.every((param) => {
            const markValue = studentMarks[param.id];
            if (!markValue) return true;
            return Number(markValue) <= param.maximumMarks;
          });
        });
      }),
  });

  const {
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    validateForm,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      selectedTemplate: null,
      assessmentDate: '',
      assessmentType: assessmentTypes[0],
      assessmentDescription: '',
      markingType: markingTypes[1],
      marks: {},
      remarks: {},
      recommendations: {},
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setIsSubmitting(true);
      allParticipants.forEach((participant) => {
        const compositeKey = `${participant.studentId}_${participant.courseId}`;
        values.selectedTemplate?.parameters?.forEach((param) => {
          setFieldTouched(`marks.${compositeKey}.${param.uniqueId}`, true);
        });
      });

      const errors = await validateForm(values);
      if (Object.keys(errors).length > 0) {
        setSubmitting(false);
        return;
      }
      try {
        const payload = {
          assessmentUniqueId: assessmentData.assessmentUniqueId,
          assessmentId: assessmentData.assessmentId,
          templateId: values.selectedTemplate.id,
          assessedBy: assessmentData.assessedBy,
          assessmentDescription: values.assessmentDescription || null,
          assessmentType: values.assessmentType,
          markingType: values.markingType,
          createdOn: values.assessmentDate,
          learnerAssessment: allParticipants
            .filter((participant) => {
              const formCompositeKey = `${participant.studentId}_${participant.courseId}`;
              return values.selectedTemplate?.parameters?.some(
                (param) =>
                  values.marks[formCompositeKey]?.[param.id] !== undefined &&
                  values.marks[formCompositeKey]?.[param.id] !== ''
              );
            })
            .map((participant) => {
              const formCompositeKey = `${participant.studentId}_${participant.courseId}`;

              const markedParameters =
                values.selectedTemplate?.parameters
                  ?.filter(
                    (param) =>
                      values.marks[formCompositeKey]?.[param.id] !==
                      undefined ||
                      values.marks[formCompositeKey]?.[param.id] !== ''
                  )
                  ?.map((param) => ({
                    parameterId: param.id,
                    parameterScore:
                      Number(values.marks[formCompositeKey]?.[param.id]) || 0,
                    parameterRemarks:
                      values.remarks[formCompositeKey]?.[param.id] || null,
                    parameterMaximumMarks: param.maximumMarks,
                    parameterName: param.parameterName,
                  })) || [];

              const totalScore = markedParameters.reduce(
                (sum, param) => sum + (param.parameterScore || 0),
                0
              );

              return {
                learnerId: participant.studentId,
                courseId: participant.courseIntId,
                totalScore: totalScore,
                recommendations:
                  values.recommendations[formCompositeKey] || null,
                isLearnerAssociationEnabled:
                  participant.isLearnerAssociationEnabled || "Y",
                parameterScores:
                  markedParameters.length > 0 ? markedParameters : null,
                learnerAssociationUniqueId:
                  participant.learnerAssociationUniqueId,
              };
            }),
        };

        const response = await AuthorizedDataService.postRequest(
          SKILL_PROGRESS_ASSESSMENT_CONSTANTS.updateSkillProgressAPI,
          payload
        );
        if (response.ok) {
          toast.success('Assessment updated successfully', {
            style: { width: 'auto', minWidth: '200px', maxWidth: '400px' },
          });
          onSuccess();
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Update failed');
        }
      } catch (error) {
        console.error('Update error:', error);
        toast.error(`Update failed: ${error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (initialDataLoaded && templatesList.length > 0) {
      const template = templatesList.find(
        (t) => t.id === values.selectedTemplate?.id
      );
      setFieldValue('selectedTemplate', template);
    }
  }, [templatesList, initialDataLoaded]);

  return (
    <>
      <div className={classes.formContainer}>
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <div className={classes.warningInfoContainer}>
              <div className={classes.warningMessage}>
                <p>
                  <PiNumberSquareOne className={classes.headingIcons} />
                  Edit Assessment
                </p>
              </div>
            </div>
            {/* Select Template Field */}
            <Form.Group className="mb-2" as={Col} md={12} lg={6}>
              <Form.Label className={formClass.formLabel}>
                {formLabels.selectTemplate.label}
                <span className="redAstrisk">*</span>{' '}
              </Form.Label>
              <Form.Select
                className={formClass.selectInput}
                name={formLabels.selectTemplate.name}
                value={values.selectedTemplate?.templateName || ''}
                onBlur={handleBlur}
                onChange={(event) => {
                  const selectedTemplate = templatesList.find(
                    (template) => template.templateName === event.target.value
                  );
                  setFieldValue(
                    formLabels.selectTemplate.name,
                    selectedTemplate
                  );
                }}
                isInvalid={touched.selectedTemplate && errors.selectedTemplate}
                disabled
              >
                <option value={AppConstants.emptyString} defaultValue>
                  Select Template
                </option>
                {templatesList?.map((template, i) => (
                  <option key={i} value={template.templateName}>
                    {template.templateName}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback className="errorText" type="invalid">
                {errors.selectedTemplate}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Select Assessment Type Field */}

            <Form.Group className="mb-2" as={Col} md={12} lg={6}>
              <Form.Label className={formClass.formLabel}>
                {formLabels.assessmentType.label}
                <span className="redAstrisk">*</span>{' '}
              </Form.Label>
              <Form.Select
                className={formClass.selectInput}
                name={formLabels.assessmentType.name}
                value={values.assessmentType}
                onBlur={handleBlur}
                onChange={(event) => {
                  setSelectedAssessmentType(event.target.value);
                  setFieldValue('assessmentType', event.target.value);
                }}
                isInvalid={touched.assessmentType && errors.assessmentType}
                disabled
              >
                <option value={AppConstants.emptyString} defaultValue disabled>
                  Select Assessment Type
                </option>
                {assessmentTypes?.map((type, i) => (
                  <option key={i} value={type} disabled={type === 'Trial'}>
                    {type}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback className="errorText" type="invalid">
                {errors.assessmentType}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Enter Assessement Date Field */}

            <Form.Group
              className={
                values.assessmentDate === AppConstants.emptyString
                  ? `mb-2 ${formClass.dateTimeEmptyInput}`
                  : `mb-2 ${formClass.dateTimeInput}`
              }
              as={Col}
              md={12}
              lg={6}
            >
              <Form.Label className={formClass.formLabel}>
                {formLabels.assessmentDate.label}
                <span className="redAstrisk">*</span>
              </Form.Label>
              <Form.Control
                className={formClass.formInput}
                name={formLabels.assessmentDate.name}
                type="date"
                value={values.assessmentDate || ''}
                onBlur={handleBlur}
                min={new Date().toISOString().split('T')[0]}
                onChange={handleChange}
                isInvalid={touched.assessmentDate && errors.assessmentDate}
              />
              <Form.Control.Feedback className="errorText" type="invalid">
                {errors.assessmentDate}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Select Marking Type Field */}
            <Form.Group className="mb-2" as={Col} md={12} lg={6}>
              <Form.Label className={formClass.formLabel}>
                {formLabels.markingType.label}
                <span className="redAstrisk">*</span>{' '}
              </Form.Label>
              <Form.Select
                className={formClass.selectInput}
                name={formLabels.markingType.name}
                value={values.markingType}
                onBlur={handleBlur}
                onChange={(event) => {
                  setMarkingType(event.target.value);
                  setFieldValue('markingType', event.target.value);
                }}
                isInvalid={touched.markingType && errors.markingType}
                disabled
              >
                <option value={AppConstants.emptyString} defaultValue disabled>
                  Select Marking Type
                </option>
                {markingTypes?.map((type, i) => (
                  <option
                    key={i}
                    value={type}
                    disabled={
                      type === markingTypes[0] &&
                      values.assessmentType === 'Regular'
                    }
                  >
                    {type}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback className="errorText" type="invalid">
                {errors.markingType}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Enter Assessement Description Field */}
            <Form.Group className="mb-2" as={Col} md={12} lg={12}>
              <Form.Label className={formClass.formLabel}>
                {formLabels.assessmentDescription.label}
              </Form.Label>
              <Form.Control
                className={formClass.formTextAreaInput}
                as="textarea"
                placeholder="Enter assessment description"
                name={formLabels.assessmentDescription.name}
                value={values.assessmentDescription}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={
                  touched.assessmentDescription && errors.assessmentDescription
                }
                maxLength={200}
              />
              <Form.Control.Feedback className="errorText" type="invalid">
                {errors.assessmentDescription}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <div className={classes.warningInfoContainer}>
              <div className={classes.warningMessage}>
                <p>
                  <PiNumberSquareTwo className={classes.headingIcons} />
                  Select{' '}
                  {markingType === markingTypes[1] ? 'Learners' : 'Learner'}
                </p>
              </div>
            </div>
            {/* Search Learner */}
            {markingType === markingTypes[0] && (
              <Form.Group>
                <div className="SuggestiveCompetitionSearch">
                  <label className="suggestedSearchLabel">
                    Search Learner<span className="redAstrisk">*</span>{' '}
                  </label>
                  <div className="suggestiveSearchTypeaheadContainer">
                    <Typeahead
                      // ref={typeaheadRef}
                      className="SuggestedSearchInputBox"
                      placeholder="Search Learner"
                      name="suggestedCompetitionSearch"
                      id="suggestedCompetitionSearch"
                      labelKey="key"
                      filterBy={['title', 'uniqueId']}
                      onChange={handleChange}
                    // options={competitionsList}
                    />
                    <MdClear className="suggestedSearchClearIcon" />
                  </div>
                </div>
              </Form.Group>
            )}
            {/* Select Courses */}
            {markingType === markingTypes[1] && (
              <Form.Group>
                {ongoingCourseList?.length ? (
                  <div className="SuggestiveCompetitionSearch">
                    <label className="suggestedSearchLabel">
                      Select Course<span className="redAstrisk">*</span>{' '}
                    </label>
                    <Card
                      className={`${classes.cardContainer} ${classes.interestCard}`}
                    >
                      <div className={classes.skillContainerBox}>
                        <div className={classes.skillContainer}>
                          {ongoingCourseList.slice()
                            .sort((a, b) => b.id - a.id).map((item, idx) => (
                              <div
                                className={`${classes.interestitem} ${selectedCourses.some(
                                  (course) => course.courseId === item.courseId
                                )
                                  ? classes.selected
                                  : ''
                                  }`}
                                onClick={() => selectCourses(item)}
                                key={idx}
                              >
                                {item.courseId} | {item.courseTitle}
                              </div>
                            ))}
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : (
                  <div className="w-100">
                    <p className="noDataAvailableError">
                      {ErrorMessages.noCoursesAvailable}
                    </p>
                  </div>
                )}
              </Form.Group>
            )}
          </Row>
          <Row>
            <div className={classes.warningInfoContainer}>
              <div className={classes.warningMessage}>
                <p>
                  <PiNumberSquareThree className={classes.headingIcons} /> Mark{' '}
                  {markingType === markingTypes[1] ? 'Learners' : 'Learner'}
                </p>
              </div>
            </div>
          </Row>

          <Row>
            <div className={classes.tableContainer}>
              {allParticipants.length > 0 && values.selectedTemplate ? (
                <>
                  <Table bordered className={classes.markingTable}>
                    <thead>
                      <tr className={classes.tableHeadings}>
                        <th className={classes.tableSubHeadings}>Name</th>
                        {values.selectedTemplate?.parameters?.map(
                          (parameter) => (
                            <th
                              className={classes.tableSubHeadings}
                              key={parameter.uniqueId}
                            >
                              {parameter.parameterName}
                            </th>
                          )
                        )}
                        <th className={classes.tableSubHeadings}>
                          Recommendation
                        </th>
                        <th className={classes.tableSubHeadings}>
                          Total Marks
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allParticipants.map((participant) => {
                        const studentId = participant.studentId;
                        const courseId = participant.courseId;
                        const compositeKey = `${studentId}_${courseId}`;

                        const totalScore =
                          values.selectedTemplate?.parameters?.reduce(
                            (sum, param) =>
                              sum +
                              (values.marks?.[compositeKey]?.[param.id] || 0),
                            0
                          ) || 0;

                        return (
                          <tr key={compositeKey}>
                            <td>
                              <div className={classes.tableDesc}>
                                {participant.studentName}
                              </div>
                              <div className={classes.tableCourseDesc}>
                                {participant.userQrenciaId}
                              </div>
                            </td>
                            {values.selectedTemplate?.parameters?.map(
                              (parameter) => {
                                const currentMark =
                                  values.marks?.[compositeKey]?.[
                                  parameter.id
                                  ] ?? '';
                                const exceedsMax =
                                  currentMark > parameter.maximumMarks;
                                const hasAnyMarks =
                                  values.selectedTemplate?.parameters?.some(
                                    (p) => {
                                      const markValue =
                                        values.marks?.[compositeKey]?.[p.id];
                                      return (
                                        markValue !== undefined &&
                                        markValue !== '' &&
                                        markValue !== null
                                      );
                                    }
                                  );

                                const isEmpty =
                                  currentMark === '' ||
                                  currentMark === undefined ||
                                  currentMark === null;

                                const isTouched =
                                  touched.marks?.[compositeKey]?.[
                                  parameter.id
                                  ] ||
                                  (errors.marks &&
                                    Object.keys(touched).length > 0);

                                return (
                                  <td key={parameter.id}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Form.Control
                                        type="number"
                                        step="0.1"
                                        placeholder="Marks"
                                        max={parameter.maximumMarks}
                                        value={
                                          values.marks?.[compositeKey]?.[
                                          parameter.id
                                          ] ?? ''
                                        }
                                        className={classes.marksInputFeild}
                                        onChange={(e) => {
                                          handleScoreChange(
                                            studentId,
                                            courseId,
                                            parameter.id,
                                            e.target.value
                                          );
                                        }}
                                        onBlur={() => {
                                          setFieldTouched(
                                            `marks.${compositeKey}.${parameter.id}`,
                                            true
                                          );
                                        }}
                                        isInvalid={
                                          (hasAnyMarks &&
                                            isEmpty &&
                                            isTouched) ||
                                          currentMark > parameter.maximumMarks
                                        }
                                      />
                                    </div>
                                    {currentMark > parameter.maximumMarks && (
                                      <div
                                        className={classes.errorText}
                                        type="invalid"
                                      >
                                        Marks cannot be more than{' '}
                                        {parameter.maximumMarks}
                                      </div>
                                    )}
                                    {hasAnyMarks && isEmpty && isTouched && (
                                      <div
                                        className={classes.errorText}
                                        type="invalid"
                                      >
                                        Please enter marks for this parameters
                                      </div>
                                    )}
                                    <Form.Control.Feedback
                                      className="errorText"
                                      type="invalid"
                                    >
                                      Please enter marks for this parameters
                                    </Form.Control.Feedback>
                                    <Form.Control
                                      as="textarea"
                                      placeholder={`Remarks`}
                                      value={
                                        values.remarks[compositeKey]?.[
                                        parameter.id
                                        ] || ''
                                      }
                                      className={classes.remarksInput}
                                      onChange={(e) =>
                                        handleRemarksChange(
                                          studentId,
                                          courseId,
                                          parameter.id,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                );
                              }
                            )}
                            <td>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter recommendations"
                                value={
                                  values.recommendations[compositeKey] || ''
                                }
                                className={classes.recommendationInput}
                                onChange={(e) =>
                                  handleRecommendationChange(
                                    studentId,
                                    courseId,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                readOnly
                                value={totalScore}
                                className={classes.TotalMarks}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className="w-100">
                  <p className="noDataAvailableError">
                    {allParticipants.length > 0
                      ? 'Please select a template to view participants'
                      : ErrorMessages.selectCoursesFirst}
                  </p>
                </div>
              )}
            </div>
          </Row>

          <div className={classes.saveButton}>
            <Button variant="secondary" onClick={onSuccess} className="me-2">
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Update Assessment'}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditAssessment;
