import React from 'react';
import classes from './ExpressionOfInterestPageBanner.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';

export const ExpressionOfInterestPageBanner = ({ eoiCarouselData }) => {
  const desktopBannerImages = eoiCarouselData.slides?.filter((image) =>
    image.imagePath.toLowerCase().includes(AppConstants.desktopText)
  );
  const tabletBannerImages = eoiCarouselData.slides?.filter((image) =>
    image.imagePath.toLowerCase().includes(AppConstants.tabletText)
  );
  const mobileBannerImages = eoiCarouselData.slides?.filter((image) =>
    image.imagePath.toLowerCase().includes(AppConstants.mobileText)
  );

  const responsiveSlider = {
    desktop: {
      breakpoint: { max: 3000, min: 991 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 990, min: 481 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className={classes.eoiPageBannerSection}>
        <div className={classes.mobileImgContainer}>
          <Carousel
            responsive={responsiveSlider}
            autoPlay={AppConstants.trueText}
            autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
            customTransition={AppConstants.qrenciaNewsCustomTransition}
            rewindWithAnimation={AppConstants.trueText}
            transitionDuration={AppConstants.eoiMobileCarouselDuration}
            infinite={
              mobileBannerImages?.filter(
                (data) => data.active === AppConstants.trueText
              )?.length > 1
                ? AppConstants.trueText
                : AppConstants.falseText
            }
            showDots={
              mobileBannerImages?.filter(
                (data) => data.active === AppConstants.trueText
              )?.length > 1
                ? AppConstants.trueText
                : AppConstants.falseText
            }
            className="eoiBannerCarouselContainerImage"
          >
            {mobileBannerImages
              ?.filter((data) => data.active === AppConstants.trueText)
              .map((slide) => (
                <div key={slide.id}>
                  <img
                    alt="mobile_banner"
                    className={classes.bannerImage}
                    src={slide.imagePath}
                  />
                  <h2 className={classes.mobileBannerTitle}>
                    {parse(slide.title)}
                  </h2>
                  <p className={classes.mobileBannerSubtitle}>
                    {slide.subTitle}
                  </p>
                </div>
              ))}
          </Carousel>
        </div>
        <div className={classes.tabletImgContainer}>
          <Carousel
            responsive={responsiveSlider}
            autoPlay={AppConstants.trueText}
            autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
            customTransition={AppConstants.qrenciaNewsCustomTransition}
            rewindWithAnimation={AppConstants.trueText}
            transitionDuration={AppConstants.eoiMobileCarouselDuration}
            infinite={
              tabletBannerImages?.filter(
                (data) => data.active === AppConstants.trueText
              )?.length > 1
                ? AppConstants.trueText
                : AppConstants.falseText
            }
            showDots={
              tabletBannerImages?.filter(
                (data) => data.active === AppConstants.trueText
              )?.length > 1
                ? AppConstants.trueText
                : AppConstants.falseText
            }
            className="eoiBannerCarouselContainerImage"
          >
            {tabletBannerImages
              ?.filter((data) => data.active === AppConstants.trueText)
              .map((slide) => (
                <div key={slide.id} className="">
                  <img
                    alt="tablet_banner"
                    className={classes.bannerImage}
                    src={slide.imagePath}
                  />
                  <h2 className={classes.tabletBannerTitle}>
                    {parse(slide.title)}
                  </h2>
                  <p className={classes.tabletBannerSubtitle}>
                    {slide.subTitle}
                  </p>
                </div>
              ))}
          </Carousel>
        </div>
        <div className={classes.desktopImgContainer}>
          <Carousel
            responsive={responsiveSlider}
            autoPlay={AppConstants.trueText}
            autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
            customTransition={AppConstants.qrenciaNewsCustomTransition}
            rewindWithAnimation={AppConstants.trueText}
            transitionDuration={AppConstants.eoiMobileCarouselDuration}
            infinite={
              desktopBannerImages?.filter(
                (data) => data.active === AppConstants.trueText
              )?.length > 1
                ? AppConstants.trueText
                : AppConstants.falseText
            }
            showDots={
              desktopBannerImages?.filter(
                (data) => data.active === AppConstants.trueText
              )?.length > 1
                ? AppConstants.trueText
                : AppConstants.falseText
            }
            className="eoiBannerCarouselContainerImage"
          >
            {desktopBannerImages
              ?.filter((data) => data.active === AppConstants.trueText)
              .map((slide) => (
                <div key={slide.id} className={classes.bannerSlide}>
                  <img
                    alt="desktop_banner"
                    className={classes.bannerImageWeb}
                    src={slide.imagePath}
                  />
                  <h2 className={classes.bannerTitle}>{parse(slide.title)}</h2>
                  <p className={classes.bannerSubtitle}>{slide.subTitle}</p>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};
