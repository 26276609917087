import React from 'react';
import SkillAssessmentReport from './SkillAssessmentReport';
import classes from './SkillAssessmentReport.module.css';
import SkillAssessmentReportFooter from './SkillAssessmentReportFooter';

const SkillProgressionAssessmentReportPage = () => {
  return (
    <>
      <div className={classes.pageContainer}>
        <SkillAssessmentReport />
      </div>
      <SkillAssessmentReportFooter />
    </>
  );
};

export default SkillProgressionAssessmentReportPage;
