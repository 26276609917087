import { useState } from 'react';
import { AuthorizedDataService } from '../services/AuthorizedDataService';
import { LEARNER_PASS_DASHBOARD } from '../constants/AppConstants';
import { PaymentsUtil } from '../utils/PaymentsUtil';
import { StringUtils } from '../utils/StringUtils';

const useLearnerPassPayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const initiatePayment = async ({ createOrderPayload }) => {
    setLoading(true);
    setError(null);


    // let payload = {
    //     email: 'alkeshsrivastava11+dev@gmail.com',
    //     //   referalCode: 98393,
    //     planId: 1,
    //     amount: 100,
    //     discount: 20,
    //     payableAmount: 80,
    // }
    try {
      const response = await AuthorizedDataService.postWithResponse(
        'createOrderForLearnerPass',
        createOrderPayload,
        '',
        ''
      );

      if (response.status === 201) {

        const data = await response.json();

        const createOrderAPIResponse = StringUtils.decodeBase64EncodedString(
          data.responseToken
        );

        const options = PaymentsUtil.getRazorpayPaymentOptions(createOrderAPIResponse);

        const rzp1 = new window.Razorpay({
          ...options,
          handler: async (response) => {
            setLoading(true);
            setError(null);
            try {
              const paymentResult =
                await PaymentsUtil.verifyRazorpayPaymentStatus(
                  response,
                  createOrderAPIResponse
                );

              if (paymentResult.verificationStatus === 'verified') {
                setLoading(false);
                setError(null);
              } else {
                setLoading(false);
                setError(paymentResult.message);
              }
            } catch (error) {
              setLoading(false);
              setError(error.message);
            }
          },
        });

        rzp1.open();
      } else {
        setError('Failed to initiate payment');
      }
    } catch (error) {
      setError('Failed to initiate payment');
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, initiatePayment };
};

export default useLearnerPassPayment;
