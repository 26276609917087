import React from 'react';
import classes from './AccountStatusIndicator.module.css';

const AccountStatusIndicator = ({ status }) => {
  const isActive = status.toLowerCase() === 'active';

  return (
    <div className={classes.statusWrapper}>
      <span
        className={classes.statusDot}
        style={{ backgroundColor: isActive ? '#00e109' : '#ff1616' }}
      ></span>
      <span className={classes.statusText}>{status}</span>
    </div>
  );
};

export default AccountStatusIndicator;
