import { NavLink } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { FaChevronDown, FaRegBell } from 'react-icons/fa';
import { BiLogOut, BiHome } from 'react-icons/bi';
import { RiUserSettingsLine, RiFileUserLine } from 'react-icons/ri';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import {
  AppConstants,
  LEARNER_PASS_DASHBOARD,
  NOTIFICATION_UI,
} from '../../constants/AppConstants';
import Dropdown from 'react-bootstrap/Dropdown';
import Auth from '../../utils/Auth';
import '../StudentPageLayout/StudentHeader.css';
import { Logout } from '../../common/Logout';
import classes from './StudentHeader.module.css';
import {
  useLearnerPassPaymentPopupStore,
  useLearnerPassStore,
} from '../../stores';
import {
  calculateNumberOfRemainingDaysLearnerPass,
  getNumberOfDaysUntilLearnerPassExpiry,
} from '../../utils/calculateNumberOfRemainingDays';
import LearnerPassButton from '../../component/subscription/LearnerPass/LearnerPassButton';
import useLearnerPassPayment from '../../hooks/useLearnerPassPayment';
import LearnerPassStatus from '../../component/subscription/LearnerPass/LearnerPassStatus';
import { useEffect, useState } from 'react';
import { FetchMembershipStatus } from '../../utils/SynchronizeMembershipStatus';
import LearnerPassPopup from '../../component/subscription/LearnerPass/LearnerPassPopup';
import LearnerWelcomeScreen from '../../pages/student/learnerOnboarding/LearnerWelcomeScreen';

export const StudentHeader = (props) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;
  const newFeatureFlagImagePath = `${mediaContent}${NOTIFICATION_UI.newFeatureFlagImagePath}`;

  const {
    learnerPassSubscriptionStatus,
    setLearnerPassSubscriptionStatus,
    wantsToBuyLearnerPass,
    setWantsToBuyLearnerPass,
    numberOfDaysRemaining,
    setNumberOfDaysRemaining,
    learnerPassSubscriptionPlans,
    learnerMembershipStatus,
  } = useLearnerPassStore();
  const { learnerPassExpiryDate, setLearnerPassExpiryDate } =
    useLearnerPassStore();

  const {
    showLearnerPassPaymentPopup,
    toggleLearnerPassPaymentPopup,
    handleButtonClick,
    setShowLearnerPassPaymentPopup,
    setShowLearnerPassWelcomeScreen,
    showLearnerPassWelcomeScreen,
  } = useLearnerPassPaymentPopupStore();

  const [
    numberOfDaysUntilLearnerPassExpiry,
    setNumberOfDaysUntilLearnerPassExpiry,
  ] = useState(null);

  const renderLearnerPassSection = () => {
    if (!learnerPassSubscriptionPlans) {
      return null;
    } else {
      if (learnerPassSubscriptionStatus === 'Not Purchased') {
        return <LearnerPassButton onPaymentTrigger={initiatePayment} />;
      } else if (learnerPassSubscriptionStatus === 'Active') {
        if (
          numberOfDaysRemaining >
          LEARNER_PASS_DASHBOARD.learnerPassNotificationThreshold
        ) {
          return <LearnerPassStatus />;
        } else {
          return (
            <>
              <LearnerPassButton
                onPaymentTrigger={initiatePayment}
                buttonTitle="Renew Learner Pass"
                numberOfDaysRemaining={numberOfDaysRemaining}
                showNumberOfDaysRemaining={true}
              />
              <LearnerPassStatus />
            </>
          );
        }
      } else if (learnerPassSubscriptionStatus === 'Expired') {
        return <LearnerPassButton onPaymentTrigger={initiatePayment} />;
      }
    }

    return <LearnerPassButton onPaymentTrigger={initiatePayment} />;
  };

  useEffect(() => {
    const userEmail = Auth.getLoggedInUserDetails().userEmail;
    const userRole = Auth.getLoggedInUserDetails().userRole;

    setNumberOfDaysRemaining(
      getNumberOfDaysUntilLearnerPassExpiry(learnerPassExpiryDate)
    );

    if (wantsToBuyLearnerPass) {
      // setShowLearnerPassPaymentPopup(true);
      setShowLearnerPassWelcomeScreen(true);
      // toggleLearnerPassPaymentPopup();
    }
  }, [learnerPassExpiryDate]);

  const premiumHeaderBorder =
    learnerPassSubscriptionStatus ===
      LEARNER_PASS_DASHBOARD.subscriptionStatuses.active
      ? `${classes.header} ${classes.subscribedBorder}`
      : classes.header;

  const { initiatePayment } = useLearnerPassPayment();
  function getUserHomePageUrl() {
    if (Auth.isLogin()) {
      if (Auth.getUserRole() === AppConstants.userRoleStudent) {
        return AppConstants.studentHomePath;
      } else {
        return AppConstants.forwardSlash;
      }
    } else {
      return AppConstants.forwardSlash;
    }
  }

  return (
    <>
      <header
        className={`${props.hasShadow
          ? `${classes.header} ${classes.profilePageHeader}`
          : classes.header
          } ${premiumHeaderBorder}`}
      >
        <div
          className={`${props.hasShadow ? `${classes.logoSection}` : classes.logoSection
            }`}
        >
          <NavLink to={AppConstants.forwardSlash}>
            <img
              src={qrenciaLogoUrl}
              className={classes.logo}
              alt="Qrencia Logo"
            />
          </NavLink>
        </div>

        {props.page !== 'profileUpdate' && (
          <div className={classes.listSection}>
            <nav>
              <ul className={classes.list}>
                <li className={classes.hideListOnMobile}>
                  <NavLink
                    to={AppConstants.studentMyLearningPath}
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    <HiOutlineBookOpen className={classes.linkIcons} /> My
                    Learning
                  </NavLink>
                </li>
                <li className={classes.hideListOnMobile}>
                  <NavLink
                    to={AppConstants.studentMyAssessementPath}
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    <TbReportAnalytics className={classes.linkIcons} /> My
                    Assessments
                  </NavLink>
                </li>
                <li
                  className={`${classes.hideListOnMobile} ${classes.myProfileContainer}`}
                >
                  {learnerPassSubscriptionStatus === 'Active' ? (
                    <>
                      <div
                        className={classes.statusWrapper}
                        style={{ backgroundColor: '#E77A7B', color: '#ffffff' }}
                      >
                        <div className={classes.statusChipText}>NEW</div>
                      </div>
                      <NavLink
                        to={`/learner/${Auth.getLoggedInUserDetails().userSlug}`}
                        className={({ isActive }) =>
                          isActive ? classes.active : classes.newLabelButton
                        }
                      >
                        My Profile
                      </NavLink>
                    </>
                  ) : null}
                </li>
                <li
                  className={`${classes.hideListOnMobile} ${classes.myProfileContainer}`}
                >
                  {learnerPassSubscriptionStatus === 'Active' ? (
                    <>
                      <div
                        className={classes.statusWrapper}
                        style={{ backgroundColor: '#E77A7B', color: '#ffffff' }}
                      >
                        <div className={classes.statusChipText}>NEW</div>
                      </div>
                      <NavLink
                        to={`https://calendly.com/qrenciaforlearners/passioncompanion`} target="_blank"
                        className={({ isActive }) =>
                          isActive ? classes.active : classes.newLabelButton
                        }
                      >
                        My Companion
                      </NavLink>
                    </>
                  ) : null}
                </li>
                {/* {learnerPassExpiryDate === undefined && null}
                {numberOfDaysUntilLearnerPassExpiry === undefined && null} */}

                {/* {learnerPassSubscriptionPlans ? (
                  numberOfDaysRemaining >
                  LEARNER_PASS_DASHBOARD.learnerPassNotificationThreshold ? (
                    <LearnerPassStatus />
                  ) : (
                    <LearnerPassButton onPaymentTrigger={initiatePayment} />
                  )
                ) : null} */}
                {renderLearnerPassSection()}
              </ul>
            </nav>
          </div>
        )}
        <div
          className={
            props.page && props.page === 'profileUpdate'
              ? `${classes.buttonSection} ${classes.alignRight}`
              : classes.buttonSection
          }
        >
          {/* <Button className={classes.referButton}>REFER A FRIEND</Button> */}
        </div>
        {props.page !== 'profileUpdate' && (
          <div className={classes.notificationSection}>
            <FaRegBell className={classes.notificationImage} />
            <Badge bg="danger" className={classes.notificationBadge}>
              0
            </Badge>
          </div>
        )}
        <div className={classes.profileSection}>
          {Auth.getLoggedInUserDetails()?.profileImagePreSignedUrl != null && (
            <div>
              <img
                src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl}
                className={classes.profileImage}
                alt="Profile"
              />
            </div>
          )}
          {Auth.getLoggedInUserDetails()?.profileImagePreSignedUrl == null && (
            <div>
              <div className={classes.userInitials}>
                {Auth.getLoggedInUserDetails()?.nameInitials?.toUpperCase()}
              </div>
            </div>
          )}
          <Dropdown>
            <Dropdown.Toggle className={classes.profilePopoverButton}>
              <p className={classes.profileName}>
                {Auth.getLoggedInName().length < 16
                  ? Auth.getLoggedInName()
                  : Auth.getLoggedInName().substr(0, 16) + '..'}
              </p>{' '}
              <FaChevronDown />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Auth.isLogin() && learnerPassSubscriptionStatus === 'Active' ? (
                <Dropdown.Item className={classes.profileDropdownItem}>
                  <RiFileUserLine
                    className={classes.profileDropdownOptionIcon}
                  />
                  <NavLink
                    to={`/learner/${Auth.getLoggedInUserDetails().userSlug}`}
                    className={({ isActive }) =>
                      isActive
                        ? `${classes.active} ${classes.profileDropdownOption}`
                        : classes.profileDropdownOption
                    }
                  >
                    My Profile
                  </NavLink>
                </Dropdown.Item>
              ) : null}

              {props.page === 'profileUpdate' ? (
                <Dropdown.Item className={classes.profileDropdownItem}>
                  <BiHome className={classes.profileDropdownOptionIcon} />
                  <NavLink
                    to={getUserHomePageUrl()}
                    className={classes.profileDropdownOption}
                  >
                    Home
                  </NavLink>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item className={classes.profileDropdownItem}>
                  <RiUserSettingsLine
                    className={classes.profileDropdownOptionIcon}
                  />
                  <NavLink
                    to={AppConstants.studentUpdateProfilePagePath}
                    className={classes.profileDropdownOption}
                  >
                    Update Profile
                  </NavLink>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className={classes.profileDropdownItem}
                onClick={Logout.logout}
              >
                <BiLogOut className={classes.profileDropdownOptionIcon} />
                <p className={classes.profileDropdownOption}>Logout</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <LearnerPassPopup
        show={showLearnerPassPaymentPopup}
        onHide={toggleLearnerPassPaymentPopup}
        handleButtonClick={handleButtonClick}
      />

      {/* <LearnerWelcomeScreen
        show={showLearnerPassWelcomeScreen}
        onClose={() => {
          setShowLearnerPassWelcomeScreen(false);
          setWantsToBuyLearnerPass(false);
        }}
        onProceed={() => {
          setShowLearnerPassWelcomeScreen(false);
          setShowLearnerPassPaymentPopup(true);
          setWantsToBuyLearnerPass(false);
        }}
      /> */}
      <LearnerWelcomeScreen />
    </>
  );
};
