import React, { useEffect, useState, useRef } from 'react';
import {
  SKILL_PROGRESS_ASSESSMENT_CONSTANTS,
  AppConstants,
} from '../../../constants/AppConstants';
import classes from './SkillAssessmentReport.module.css';
import { Col, Row } from 'react-bootstrap';
import { FcApproval } from 'react-icons/fc';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SkillAssessmentReportFooter from './SkillAssessmentReportFooter';
import SkillAssessmentCurrentAssessment from './SkillAssessmentCurrentAssessment';
import { IoMdDownload } from 'react-icons/io';
import { FaShare } from 'react-icons/fa';
import { DataService } from '../../../services/DataService';
import { useParams, useNavigate } from 'react-router-dom';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
import { GiStairsGoal } from 'react-icons/gi';
import { PiCertificate } from 'react-icons/pi';
import { SlBadge } from 'react-icons/sl';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BiLoaderAlt } from 'react-icons/bi';
import { ShareLinkToSocialMediaUtil } from '../../../../src/utils/ShareLinkToSocialMediaUtil';
import { FiLink } from 'react-icons/fi';
import { SlCalender } from 'react-icons/sl';
import { GiLightBulb } from 'react-icons/gi';
import { TfiBarChartAlt } from 'react-icons/tfi';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';

const SkillAssessmentReport = () => {
  const [learnerDetails, setLearnerDetails] = useState([]);
  const { studentAssessmentMappingUniqueId } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const reportRef = useRef();
  const [isDownloading, setIsDownloading] = useState(false);

  const [editIndex, setEditIndex] = useState(-1);
  const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(false);
  const [linkToShare, setLinkToShare] = useState('');
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.qrenciaGlobeImage}`;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  function copyToClipBoard(event) {
    event.preventDefault();
    navigator.clipboard.writeText(linkToShare);
    setShowLinkCopied(true);
    var timer = setTimeout(() => {
      setShowLinkCopied(false);
    }, 3000);
  }

  const handleShareClick = (e) => {
    stopPropagation(e);
    setShowSocialMediaPannel(!showSocialMediaPannel);

    const generatedLink = `${window.location.href}`;
    setLinkToShare(generatedLink);
  };

  const formatNumber = (num) => {
    if (!num && num !== 0) return 'N/A'; // Handle undefined/null cases
    const number = parseFloat(num);
    return number % 1 === 0 ? number.toString() : number.toFixed(2);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const getOrdinal = (n) => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    return `${day}${getOrdinal(day)} ${month} ${year}`;
  };

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const url = `${SKILL_PROGRESS_ASSESSMENT_CONSTANTS.skillAssessmentReportPathApi}/${studentAssessmentMappingUniqueId}`;

        const response = await DataService.get(
          url,
          AppConstants.emptyString,
          AppConstants.emptyString
        );

        if (response !== undefined) {
          setLearnerDetails(response);
        } else {
          setLearnerDetails([]);
          navigate(errorPageUrl);
        }
      } catch (err) {
        setError(err.message);
        navigate(AppConstants.errorPageUrl);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [studentAssessmentMappingUniqueId, navigate]);

  useEffect(() => { }, [learnerDetails]);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setIsLoaderVisible(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  return (
    <>
      {isLoaderVisible ? (
        <div
          className={`${classes.preLoaderContainer} ${!loading ? classes.exit : ''}`}
        >
          <div className={classes.preLoaderDiv}>
            <div className={classes.loader}></div>
            <img
              className={classes.qrenciaGlobe}
              src={qrenciaGlobeImage}
              alt="Qrencia globe"
            />
            <p className={classes.loaderText}>
              {AppConstants.reportPageLoaderText}
            </p>
          </div>
        </div>
      ) : (
        <div ref={reportRef} className={classes.SkillProgressionReport}>
          <div className={classes.skillProgressionHeader}>
            <Row>
              <div
                className={`${classes.skillProgressionSection}  col-md-8 col-sm-8 col-8`}
              >
                <div className={classes.skillAssessmentReportTitle}>
                  {SKILL_PROGRESS_ASSESSMENT_CONSTANTS.skillAssessmentReport}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="verified-tooltip">Qrencia Verified</Tooltip>
                    }
                  >
                    <span>
                      <FcApproval className={classes.approvedSeal} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
              <div
                className={`${classes.skillProgressionSection}  col-md-4 col-sm-4 col-4`}
              >
                <div className={classes.skillAssessmentSection}>
                  <p className={classes.skillAssessmentReportSubTitle}>
                    {SKILL_PROGRESS_ASSESSMENT_CONSTANTS.reportPresentedBy}
                  </p>
                  <p
                    className={classes.reportPresentedBy}
                    onClick={() => {
                      if (
                        learnerDetails.reportPresentedByUserRole &&
                        learnerDetails.reportPresentedByLink
                      ) {
                        window.open(
                          `/${learnerDetails.reportPresentedByUserRole.toLowerCase()}/${learnerDetails.reportPresentedByLink}`,
                          '_blank'
                        );
                      } else {
                        console.error('User role or link is undefined');
                      }
                    }}
                  >
                    {learnerDetails.reportPresentedBy}
                    <FiLink />
                  </p>
                  {learnerDetails.reportPresentedByLogo && (
                    <div className={classes.logoImage}>
                      <img
                        className={classes.logoProfileImage}
                        src={learnerDetails.reportPresentedByLogo}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </div>
          <div>
            <Row className={classes.studentProfileSection}>
              <div className="col-md-8">
                <div className={classes.studentProfileIdentity}>
                  <div className={classes.studentProfile}>
                    <div className={classes.profileImageSection}>
                      {learnerDetails.learnerImage ? (
                        <img
                          src={learnerDetails.learnerImage}
                          className={classes.profileImage}
                          alt="Profile"
                        />
                      ) : (
                        <div className={classes.userInitials}>
                          {learnerDetails.learnerNameInitials}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.studentAboutUs}>
                    <p className={classes.studentName}>
                      {learnerDetails.learnerName}
                    </p>
                    <p className={classes.studentAge}>
                      {learnerDetails.learnerAge} Years
                    </p>
                  </div>
                </div>
                <div className={classes.studentDescriptionSection}>
                  <div className={classes.studentDescription}>
                    This skill assessment report provides an in-depth evaluation
                    of {learnerDetails.learnerName}'s current{' '}
                    {learnerDetails.skillName} abilities. The assessment was
                    carried out on a set of predefined parameters designed to
                    measure the skill level holistically.
                  </div>
                  <div className={classes.studentDescription}>
                    Each parameter is individually reviewed, offering specific
                    remarks/recommendations on strengths and areas for
                    improvement.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className={classes.skillReportsBtn}>
                  <div className={classes.shareBtn} onClick={handleShareClick}>
                    <FaShare />
                    Share
                  </div>

                  {showSocialMediaPannel && (
                    <div
                      onClick={stopPropagation}
                      className={classes.shareIconPannelContainer}
                    >
                      <ShareLinkToSocialMediaUtil
                        linkToShare={linkToShare}
                        className={classes}
                        useIcons={AppConstants.trueText}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.learnerAwardSection}>
                  <div className={classes.awardSection}>
                    <div>
                      <SlCalender className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {formatDate(learnerDetails.assessmentDate)}
                      </p>
                      <p className={classes.awardSubTitle}>Assessment Date</p>
                    </div>
                  </div>

                  <div className={classes.awardSection}>
                    <div>
                      <GiLightBulb className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {learnerDetails.courseTitle}
                      </p>
                      <p className={classes.awardSubTitle}>
                        {learnerDetails.courseStatus}
                      </p>
                    </div>
                  </div>

                  <div className={classes.awardSection}>
                    <div>
                      <SlBadge className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {formatNumber(learnerDetails.assessmentScore)}/
                        {learnerDetails.assessmentMaxScore}
                      </p>
                      <p className={classes.awardSubTitle}>
                        Your Assessment Score
                      </p>
                    </div>
                  </div>

                  <div className={classes.awardSection}>
                    <div>
                      <TfiBarChartAlt className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {formatNumber(learnerDetails.assessmentAverageScore)}/
                        {learnerDetails.assessmentAverageMaxScore}
                      </p>
                      <p className={classes.awardSubTitle}>
                        Average Assessment Score
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
          <SkillAssessmentCurrentAssessment
            parameterScores={learnerDetails.parameterScores}
            recommendation={learnerDetails.recommendation}
            assessmentDescription={learnerDetails.assessmentDescription}
            assessedBy={learnerDetails.assessmentAssessedBy}
            reportPresentedBy={learnerDetails.reportPresentedBy}
          />
        </div>
      )}
    </>
  );
};

export default SkillAssessmentReport;
