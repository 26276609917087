import dayjs from 'dayjs';
// import { utc } from 'moment';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useLearnerPassStore } from '../stores';

export const calculateNumberOfRemainingDays = (
  expiryDate,
  currentDate = dayjs()
) => {
  const expiryDateDayjs = dayjs(expiryDate);
  const currentDateDayjs = dayjs(currentDate);
  const days = expiryDateDayjs.diff(currentDateDayjs, 'days');
  return days;
};

export const calculateNumberOfRemainingDaysLearnerPass = (
  expiryDate,
  currentDate = dayjs()
) => {
  const expiryDateDayjs = dayjs(expiryDate);
  const currentDateDayjs = dayjs(currentDate);
  const days = expiryDateDayjs.diff(currentDateDayjs, 'days');
  return days;
};

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

export const getNumberOfDaysUntilLearnerPassExpiry = (
  expiryDate,
  currentDate = dayjs.utc()
) => {
  if (!expiryDate || !dayjs.utc(expiryDate).isValid()) {
    return null;
  }

  if (!dayjs.utc(currentDate).isValid()) {
    return null;
  }

  // Convert inputs to UTC
  const expiryDateDayjs = dayjs.utc(expiryDate);
  const currentDateDayjs = dayjs.utc(currentDate);

  if (expiryDateDayjs.isSameOrBefore(currentDateDayjs, 'day')) {
    return 0; // Already expired or expires today
  }

  const days = expiryDateDayjs.diff(currentDateDayjs, 'days') + 1;
  return days;
};
