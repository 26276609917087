import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AiOutlineDownload,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import { Form, Button, Modal, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DataService } from '../../services/DataService';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { AppConstants } from '../../constants/AppConstants';
import classes from './ExpertObrStatusForm.module.css';
import parse from 'html-react-parser';
import { BiLoaderAlt } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import modalClass from '../cards/CompetitionsCards.module.css';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { FormValidationUtils } from '../../utils/FormValidationUtils';
import { FileUploadInput } from '../fileUploadInput/FileUploadInput';
import { FaRegEye } from 'react-icons/fa';
import { ImageGalleryModal } from '../modal/ImageGalleryModal';

export const AgencyObrStatusForm = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const { obrId } = useParams();
  const [obrLinkValid, setObrLinkValid] = useState(AppConstants.falseText);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(
    AppConstants.falseText
  );
  const [obrStatus, setObrStatus] = useState(AppConstants.emptyString);

  const [tncCheck, setTncCheck] = useState(AppConstants.falseText);
  const [expertObrDetails, setExpertObrDetails] = useState({});
  const [addressProof, setAddressProof] = useState(AppConstants.emptyString);
  const [webBannerImage, setWebBannerImage] = useState(
    AppConstants.emptyString
  );
  const [videoMessageThumbnail, setVideoMessageThumbnail] = useState(
    AppConstants.emptyString
  );
  const [tabletBannerImage, setTabletBannerImage] = useState(
    AppConstants.emptyString
  );
  const [mobileBannerImage, setMobileBannerImage] = useState(
    AppConstants.emptyString
  );
  const [videoMessage, setVideoMessage] = useState(AppConstants.emptyString);
  const [profileImage, setProfileImage] = useState(AppConstants.emptyString);
  const [webBannerImageSpinner, setWebBannerImageSpinner] = useState(
    AppConstants.falseText
  );
  const [videoMessageThumbnailSpinner, setVideoMessageThumbnailSpinner] =
    useState(AppConstants.falseText);
  const [tabletBannerImageSpinner, setTabletBannerImageSpinner] = useState(
    AppConstants.falseText
  );
  const [mobileBannerImageSpinner, setMobileBannerImageSpinner] = useState(
    AppConstants.falseText
  );
  const [addressProofSpinner, setAddressProofSpinner] = useState(
    AppConstants.falseText
  );
  const [videoMessageSpinner, setVideoMessageSpinner] = useState(
    AppConstants.falseText
  );
  const [profileImageSpinner, setProfileImageSpinner] = useState(
    AppConstants.falseText
  );
  const [saveButtonSpinner, setSaveButtonSpinner] = useState(
    AppConstants.falseText
  );
  const [reviewButtonSpinner, setReviewButtonSpinner] = useState(
    AppConstants.falseText
  );
  const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
  const [showModal, setShowModal] = useState(AppConstants.falseText);
  const [detailsSaved, setDetailsSaved] = useState(AppConstants.falseText);
  const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
  const [resetPasswordLink, setResetPasswordLink] = useState(
    AppConstants.emptyString
  );
  const [showPageLoader, setShowPageLoader] = useState(AppConstants.trueText);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [organizationCityList, setOrganizationCityList] = useState([]);
  const [selectedCountryOrg, setSelectedCountryOrg] = useState([]);
  const [selectedStateOrg, setSelectedStateOrg] = useState([]);
  const [selectedCityOrg, setSelectedCityOrg] = useState([]);
  const [academySkillValue, setAcademySkillValue] = useState(
    AppConstants.emptyString
  );
  const [skillLength, setSkillLength] = useState(0);
  const [capturedLeadWebsiteLink, setCapturedLeadWebsiteLink] = useState(
    AppConstants.emptyString
  );
  const [isCentrePresent, setIsCentrePresent] = useState(
    AppConstants.falseText
  );

  const [videoMessageThumbnailError, setVideoMessageThumbnailError] = useState(
    AppConstants.nullText
  );
  const [profileImageError, setProfileImageError] = useState(
    AppConstants.nullText
  );
  const [webBannerImageError, setWebBannerImageError] = useState(
    AppConstants.nullText
  );
  const [tabletBannerImageError, setTabletBannerImageError] = useState(
    AppConstants.nullText
  );
  const [mobileBannerImageError, setMobileBannerImageError] = useState(
    AppConstants.nullText
  );
  const [videoMessageError, setVideoMessageError] = useState(
    AppConstants.nullText
  );

  const [addressProofPath, setAddressProofPath] = useState(
    AppConstants.nullText
  );
  const [webBannerImagePath, setWebBannerImagePath] = useState(
    AppConstants.nullText
  );
  const [videoMessageThumbnailPath, setVideoMessageThumbnailPath] = useState(
    AppConstants.nullText
  );
  const [tabletBannerImagePath, setTabletBannerImagePath] = useState(
    AppConstants.nullText
  );
  const [mobileBannerImagePath, setMobileBannerImagePath] = useState(
    AppConstants.nullText
  );
  const [videoMessagePath, setVideoMessagePath] = useState(
    AppConstants.nullText
  );
  const [userImagePath, setUserImagePath] = useState(AppConstants.nullText);
  const [selectedMediaGallery, setSelectedMediaGallery] = useState([]);
  const [showCreatorGallery, setShowCreatorGallery] = useState(
    AppConstants.falseText
  );
  const validMediaTypes = [
    ...AppConstants.promotionalVideoAllowedFormats,
    ...AppConstants.supportedImageFormats,
  ];

  const newCentreFormObj = {
    uid: AppConstants.emptyString,
    centreName: AppConstants.emptyString,
    centreSpocEmail: AppConstants.emptyString,
    centreSpocPhoneNumber: AppConstants.emptyString,
    centreCountryName: AppConstants.emptyString,
    centreCountry: [],
    centreState: [],
    centreStateName: AppConstants.emptyString,
    centreCity: [],
    centreCityName: AppConstants.emptyString,
    centreAddress: AppConstants.emptyString,
    centreGeoLocation: AppConstants.emptyString,
    centreCountryId: AppConstants.emptyString,
    cityList: [],
  };

  const numberInputs = document.querySelectorAll('input[type=number]');
  numberInputs.forEach((input) => {
    input.addEventListener('wheel', (e) => e.preventDefault());
  });

  const [orgCentreForm, setOrgCentreForm] = useState([
    { ...newCentreFormObj, uid: DateTimeUtil.fetchCurrentTimestamp() },
  ]);
  const status = AppConstants.expertObrStatus;
  const zeroValue = 0;

  const closeModal = () => setShowModal(false);

  const getRemainingLengthText = (limit, textField) => {
    if (!textField || textField === AppConstants.emptyString) {
      return AppConstants.emptyString;
    }
    let length = textField.length;
    const remaining = limit - length;

    return `${remaining} out of ${limit} characters remaining`;
  };

  const extractFileName = (name) => {
    let url = name.substr(AppConstants.zeroIndex, name.lastIndexOf('\/'));
    let arr = name.split('/');
    const regex = /[0-9]*-/;
    return arr[3]?.replace(regex, '');
  };

  const getDataTransfer = (name) => {
    const dataTransfer = new DataTransfer();
    const file = new File([''], name, { type: 'text/plain' });
    dataTransfer.items.add(file);
    return dataTransfer;
  };

  useEffect(() => {
    const checkObrLinkValidity = async () => {
      try {
        let statusCode = AppConstants.emptyString;
        const url = `${process.env.REACT_APP_API_URL}${AppConstants.checkAgencyObrLinkValidityAPI}${obrId}`;
        const response = await fetch(url);
        const body = await response.json();
        if (
          response.status === AppConstants.httpResponseCodes.responseCode202
        ) {
          setObrStatus(status.statusAccepted);
          if (
            response.status === AppConstants.httpResponseCodes.responseCode202
          ) {
            statusCode = response.status;
          }
          if (body !== null) {
            if (
              statusCode === AppConstants.httpResponseCodes.responseCode202 &&
              body.resetPasswordLink === AppConstants.nullText
            ) {
              return window.location.assign(
                `${process.env.REACT_APP_FRONT_END_URL}login`
              );
            } else {
              setResetPasswordLink(body.resetPasswordLink);
              setObrLinkValid(AppConstants.trueText);
            }
          }
        } else if (
          response.status === AppConstants.httpResponseCodes.responseCode200
        ) {
          setObrLinkValid(AppConstants.trueText);
        } else {
          setObrLinkValid(AppConstants.falseText);
          setShowPageLoader(AppConstants.falseText);
        }
      } catch (error) {
        setObrLinkValid(AppConstants.falseText);
        setShowPageLoader(AppConstants.falseText);
      }
    };

    checkObrLinkValidity();
  }, [obrId]);

  useEffect(() => {
    async function fetchStates() {
      var config = {
        headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates },
      };
      const response = await fetch(AppConstants.getStatesApi, config);
      const responseData = await response.json();
      setStateList(responseData);
      return null;
    }

    async function fetchCountries() {
      const url = AppConstants.fetchCountriesAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setCountryList(response);
      return null;
    }
    fetchStates();
    fetchCountries();
  }, []);

  async function fetchOrganizationCities(
    state,
    country,
    cityName = AppConstants.emptyString
  ) {
    if (
      (selectedCountryOrg[zeroValue]?.country == AppConstants.countryIndia ||
        country[zeroValue].country === AppConstants.countryIndia) &&
      state[zeroValue] &&
      state[zeroValue].name !== AppConstants.emptyString
    ) {
      var config = {
        headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates },
      };
      const response = await fetch(
        `${AppConstants.getStatesApi}${AppConstants.forwardSlash}${state[zeroValue].iso2}${AppConstants.getCityUri}`,
        config
      );
      const responseData = await response.json();
      if (responseData.length !== zeroValue) {
        setOrganizationCityList(responseData);
        if (cityName) {
          setSelectedCityOrg(
            responseData.filter((city) => city.name === cityName)
          );
        } else {
          setSelectedCityOrg(
            responseData.filter(
              (city) => city.name === expertObrDetails.agencyCity
            )
          );
        }
      }
    } else {
      setOrganizationCityList([]);
    }

    return null;
  }

  useEffect(() => {
    const fetchAgencyDetails = async () => {
      const url = `${AppConstants.getSpecificAgencyObrDetailAPI}${obrId}`;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response) {
        const creatorGalleryRelativePaths = response.galleryMediaPaths
          ? response.galleryMediaPaths.split(',')
          : [];
        setSelectedMediaGallery(
          response.galleryMediaAbsolutePaths
            ? response.galleryMediaAbsolutePaths
              .split(',')
              .map((url, index) => ({
                file: AppConstants.nullText,
                url,
                relativePath: creatorGalleryRelativePaths[index],
                type: AppConstants.supportedImageFormats.includes(
                  AppConstants.contentTypeMap[
                  url.split('.').pop().toLowerCase()
                  ]
                )
                  ? 'Image'
                  : 'Video',
                id: Math.floor(Math.random() * 100000 + 1).toString(),
              }))
            : []
        );
        setIsCentrePresent(
          response.agencyCentresDetails?.length > 0
            ? AppConstants.trueText
            : AppConstants.falseText
        );
        setCapturedLeadWebsiteLink(response.agencyWebsiteLink);
        setExpertObrDetails(response);
        setAddressProofPath(response.addressProofRelativePath);
        setUserImagePath(response.userImageRelativePath);
        setVideoMessagePath(response.videoMessageRelativePath);
        setWebBannerImagePath(response.webBannerImageRelativePath);
        setVideoMessageThumbnailPath(
          response.videoMessageThumbnailRelativePath
        );
        setTabletBannerImagePath(response.tabletBannerImageRelativePath);
        setMobileBannerImagePath(response.mobileBannerImageRelativePath);
        setObrStatus(response.obrStatus);
        setInitialValuesLoaded(AppConstants.trueText);
        if (response.agencyType === AppConstants.agencyTypes.academy) {
          createAcademySkillValue(response.selectedSkills);
        }
        if (response.obrStatus !== status.statusCreated) {
          setDetailsSaved(AppConstants.trueText);
        }
        setShowPageLoader(AppConstants.falseText);
      } else {
        setObrLinkValid(AppConstants.falseText);
        setShowPageLoader(AppConstants.falseText);
      }
    };

    if (obrLinkValid) {
      fetchAgencyDetails();
    }
  }, [obrLinkValid]);

  useEffect(() => {
    const fetchAgencyDetails = async () => {
      const url = `${AppConstants.getSpecificAgencyObrDetailAPI}${obrId}`;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response) {
        const creatorGalleryRelativePaths = response.galleryMediaPaths
          ? response.galleryMediaPaths.split(',')
          : [];
        setSelectedMediaGallery(
          response.galleryMediaAbsolutePaths
            ? response.galleryMediaAbsolutePaths
              .split(',')
              .map((url, index) => ({
                file: AppConstants.nullText,
                url,
                relativePath: creatorGalleryRelativePaths[index],
                type: AppConstants.supportedImageFormats.includes(
                  AppConstants.contentTypeMap[
                  url.split('.').pop().toLowerCase()
                  ]
                )
                  ? 'Image'
                  : 'Video',
                id: Math.floor(Math.random() * 100000 + 1).toString(),
              }))
            : []
        );
        setIsCentrePresent(
          response.agencyCentresDetails?.length > 0
            ? AppConstants.trueText
            : AppConstants.falseText
        );
        setCapturedLeadWebsiteLink(response.agencyWebsiteLink);
        setExpertObrDetails(response);
        setAddressProofPath(response.addressProofRelativePath);
        setUserImagePath(response.userImageRelativePath);
        setVideoMessagePath(response.videoMessageRelativePath);
        setWebBannerImagePath(response.webBannerImageRelativePath);
        setVideoMessageThumbnailPath(
          response.videoMessageThumbnailRelativePath
        );
        setTabletBannerImagePath(response.tabletBannerImageRelativePath);
        setMobileBannerImagePath(response.mobileBannerImageRelativePath);
        setObrStatus(response.obrStatus);
        setInitialValuesLoaded(AppConstants.trueText);
      } else {
        setObrLinkValid(AppConstants.falseText);
      }
    };

    if (detailsSaved) {
      fetchAgencyDetails();
    }
  }, [detailsSaved]);

  useEffect(() => {
    let orgCountry = [];
    async function fetchCountries(organizationDetails) {
      let countryName = organizationDetails.agencyCountryName;
      if (countryName !== null && countryName !== undefined) {
        orgCountry = countryList.filter(
          (country) => country.country === countryName
        );
        setSelectedCountryOrg(orgCountry);
      }
      return null;
    }

    async function fetchStates(organizationDetails) {
      if (organizationDetails === null || organizationDetails === undefined)
        return null;
      let stateName = organizationDetails.agencyState;
      let countryName = organizationDetails.agencyCountryName;
      let cityName = organizationDetails.agencyCity;
      if (stateName !== null && stateName !== undefined) {
        if (countryName === AppConstants.countryIndia) {
          let state = stateList.filter((state) => state.name === stateName);
          setSelectedStateOrg(state);
          fetchOrganizationCities(state, orgCountry, cityName);
        } else {
          setSelectedStateOrg(stateName);
          setSelectedCityOrg(cityName);
        }
      }
      return null;
    }
    const getCityForState = async (state) => {
      var config = {
        headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates },
      };
      const response = await fetch(
        `${AppConstants.getStatesApi}${AppConstants.forwardSlash}${state[zeroValue].iso2}${AppConstants.getCityUri}`,
        config
      );
      const responseData = await response.json();
      if (responseData) return responseData;
      else return [];
    };

    const populateCentreDetails = async (centreDetails) => {
      if (centreDetails === null || centreDetails === undefined) {
        return null;
      }
      let details = [];
      let timeStamp = DateTimeUtil.fetchCurrentTimestamp();
      for (let i = AppConstants.zeroIndex; i < centreDetails.length; i++) {
        let centre = centreDetails[i];
        let data = { ...newCentreFormObj };
        data.centreName = centre.centreName?.trim();
        data.centreSpocEmail = centre.centreSpocEmail;
        data.centreSpocPhoneNumber = centre.centreSpocPhoneNumber;
        data.centreCountryName = centre.centreCountryName;
        data.centreCityName = centre.centreCity;
        data.centreStateName = centre.centreState;
        data.centreAddress = centre.centreAddress?.trim();
        data.centreGeoLocation = centre.centreGeoLocation;
        data.centreCountryId = centre.centreCountryId;
        data.uid = centre.id;

        data.centreCountry = countryList.filter(
          (country) => country.country === centre.centreCountryName
        );
        if (
          centre.centreCountryName === AppConstants.countryIndia &&
          centre.centreState !== null
        ) {
          data.centreState = stateList.filter(
            (state) => state.name === centre.centreState
          );
          data.cityList = await getCityForState(data.centreState);
          data.centreCity = data.cityList?.filter(
            (city) => city.name === centre.centreCity
          );
        } else {
          data.centreState = [];
          data.centreCity = [];
          data.cityList = [];
        }
        details.push(data);
      }

      if (details.length === AppConstants.zeroIndex) {
        setOrgCentreForm([{ ...newCentreFormObj, uid: timeStamp }]);
      } else {
        setOrgCentreForm(details);
      }
      return null;
    };

    const updateObrStatusToDraft = async () => {
      let data = {
        agencyObrUniqueId: obrId,
        comment: AppConstants.emptyString,
        obrStatus: status.statusDraft,
      };
      const url = AppConstants.updateAgencyObrStatusAPI;
      const response = await DataService.put(
        url,
        data,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response !== 1) {
        setObrStatus(status.statusCreated);
      }
    };
    if (
      initialValuesLoaded &&
      expertObrDetails.obrStatus !== status.statusCreated &&
      stateList.length > AppConstants.zeroIndex &&
      countryList.length > AppConstants.zeroIndex
    ) {
      fetchCountries(expertObrDetails);
      fetchStates(expertObrDetails);
      populateCentreDetails(expertObrDetails.agencyCentresDetails);
    } else if (
      initialValuesLoaded &&
      expertObrDetails.obrStatus === status.statusCreated
    ) {
      updateObrStatusToDraft();
    }
  }, [initialValuesLoaded, stateList, countryList]);

  const changeTncCheck = (e) => {
    setTncCheck(e.target.checked);
  };

  const getFileNameWithExt = (name) => {
    let ext = AppConstants.emptyString;
    if (name === AppConstants.addressProofText) {
      ext = expertObrDetails.addressProofRelativePath.split('.').pop();
    } else if (name === AppConstants.profilePicture) {
      ext = expertObrDetails?.userImageRelativePath.split('.').pop();
    } else if (name === AppConstants.videoMessage) {
      ext = expertObrDetails?.videoMessageRelativePath.split('.').pop();
    } else if (name === AppConstants.mobileBanner) {
      ext = expertObrDetails?.mobileBannerImageRelativePath.split('.').pop();
    } else if (name === AppConstants.tabletBanner) {
      ext = expertObrDetails?.tabletBannerImageRelativePath.split('.').pop();
    } else if (name === AppConstants.webBanner) {
      ext = expertObrDetails?.webBannerImageRelativePath.split('.').pop();
    } else if (name === AppConstants.obrVideoMessageThumbnail) {
      ext = expertObrDetails?.videoMessageThumbnailRelativePath
        .split('.')
        .pop();
    }

    return `${name}${AppConstants.dot}${ext}`;
  };

  const createAcademySkillValue = (selectedSkills) => {
    let value = AppConstants.emptyString;
    selectedSkills.forEach((skill, i) => {
      if (i > 0) {
        setSkillLength(i + 1);
        value = `${value}, ${skill.skillName}`;
      } else {
        setSkillLength(1);
        value = skill.skillName;
      }
    });
    setAcademySkillValue(value);
  };

  const downloadFile = async (fileUrl, name) => {
    let fileName = getFileNameWithExt(name);
    let response = await FileOperationsUtil.downloadDocument(
      fileUrl,
      AppConstants.agencyObrUploadsFolder,
      fileName
    );
    if (!response || response instanceof Error) {
      if (name === AppConstants.addressProofText) {
        setAddressProofSpinner(AppConstants.falseText);
      } else if (name === AppConstants.profilePicture) {
        setProfileImageSpinner(AppConstants.falseText);
      } else if (name === AppConstants.videoMessage) {
        setVideoMessageSpinner(AppConstants.falseText);
      } else if (name === AppConstants.mobileBanner) {
        setMobileBannerImageSpinner(AppConstants.falseText);
      } else if (name === AppConstants.tabletBanner) {
        setTabletBannerImageSpinner(AppConstants.falseText);
      } else if (name === AppConstants.webBanner) {
        setWebBannerImageSpinner(AppConstants.falseText);
      } else if (name === AppConstants.obrVideoMessageThumbnail) {
        setVideoMessageThumbnailSpinner(AppConstants.falseText);
      }
    }
  };

  const removeFile = (id) => {
    const input = document.getElementById(id);
    input.value = AppConstants.emptyString;
    input.type = AppConstants.emptyString;
    input.type = 'file';
    setDetailsSaved(AppConstants.falseText);
    if (id === AppConstants.addressProofText) {
      setAddressProof(AppConstants.emptyString);
    } else if (id === AppConstants.profilePicture) {
      setProfileImage(AppConstants.emptyString);
    } else if (id === AppConstants.webBanner) {
      setWebBannerImage(AppConstants.emptyString);
    } else if (id === AppConstants.tabletBanner) {
      setTabletBannerImage(AppConstants.emptyString);
    } else if (id === AppConstants.mobileBanner) {
      setMobileBannerImage(AppConstants.emptyString);
    } else if (id === AppConstants.videoMessage) {
      setVideoMessage(AppConstants.emptyString);
    } else if (id === AppConstants.obrVideoMessageThumbnail) {
      setVideoMessageThumbnail(AppConstants.emptyString);
    }
  };

  const uploadFiles = async () => {
    try {
      let filePaths = {
        addressProofPath: AppConstants.emptyString,
        webBannerImagePath: AppConstants.emptyString,
        tabletBannerImagePath: AppConstants.emptyString,
        mobileBannerImagePath: AppConstants.emptyString,
        userImagePath: AppConstants.emptyString,
        videoMessagePath: AppConstants.emptyString,
        videoMessageThumbnailPath: AppConstants.emptyString,
        creatorMediaGalleryPath: AppConstants.emptyString,
      };
      let creatorMedia = [];
      let params = [];
      let forwardSlash = AppConstants.forwardSlash;

      if (addressProof?.size > AppConstants.zeroIndex)
        params.push({
          file: addressProof,
          previousPath: addressProofPath,
          id: AppConstants.addressProofText,
        });
      if (webBannerImage?.size > AppConstants.zeroIndex)
        params.push({
          file: webBannerImage,
          previousPath: webBannerImagePath,
          id: AppConstants.expertObrMicrositeWebBannerImageText,
        });
      if (tabletBannerImage?.size > AppConstants.zeroIndex)
        params.push({
          file: tabletBannerImage,
          previousPath: tabletBannerImagePath,
          id: AppConstants.expertObrMicrositeTabletBannerImageText,
        });
      if (mobileBannerImage?.size > AppConstants.zeroIndex)
        params.push({
          file: mobileBannerImage,
          previousPath: mobileBannerImagePath,
          id: AppConstants.expertObrMicrositeMobileBannerImageText,
        });
      if (profileImage?.size > AppConstants.zeroIndex)
        params.push({
          file: profileImage,
          previousPath: userImagePath,
          id: AppConstants.expertObrMicrositeUserImageText,
        });
      if (videoMessage?.size > AppConstants.zeroIndex)
        params.push({
          file: videoMessage,
          previousPath: videoMessagePath,
          id: AppConstants.expertObrMicrositeUserVideoMessageText,
        });
      if (videoMessageThumbnail?.size > AppConstants.zeroIndex)
        params.push({
          file: videoMessageThumbnail,
          previousPath: videoMessageThumbnailPath,
          id: AppConstants.obrVideoMessageThumbnail,
        });
      selectedMediaGallery.forEach((media) => {
        if (media.file?.name) {
          params.push({
            file: media.file,
            previousPath: AppConstants.nullText,
            id: 'creatorMediaGallery',
          });
        }
      });

      for (let i = AppConstants.zeroIndex; i < params.length; i++) {
        let { file, previousPath, id } = params[i];
        let path = AppConstants.emptyString;
        // replace white spaces with emptystring
        let fileName = file.name.replace(/\s+/g, AppConstants.emptyString);
        if (previousPath) {
          previousPath = previousPath.replace(
            mediaContent,
            AppConstants.emptyString
          );
          let pathArray = previousPath.split(AppConstants.forwardSlash);
          path = `${AppConstants.agencyObrDetailsUploadFolder}${forwardSlash}${pathArray[1]}${forwardSlash}${id}${forwardSlash}${fileName}`;
        } else {
          path = `${AppConstants.agencyObrDetailsUploadFolder}${forwardSlash}${expertObrDetails.agencyObrUniqueId}${forwardSlash}${id}${forwardSlash}${fileName}`;
        }
        let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(
          file,
          path,
          AppConstants.s3filePermissionPublic,
          AppConstants.s3FileContentDispositionInline
        );
        if (response instanceof Error) {
          return null;
        }

        if (id === AppConstants.addressProofText)
          filePaths.addressProofPath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeWebBannerImageText)
          filePaths.webBannerImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeTabletBannerImageText)
          filePaths.tabletBannerImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeMobileBannerImageText)
          filePaths.mobileBannerImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeUserImageText)
          filePaths.userImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeUserVideoMessageText)
          filePaths.videoMessagePath = response.objectKey;
        if (id === AppConstants.obrVideoMessageThumbnail)
          filePaths.videoMessageThumbnailPath = response.objectKey;
        if (id === 'creatorMediaGallery') creatorMedia.push(response.objectKey);
      }
      creatorMedia.push(
        selectedMediaGallery
          .filter((media) => !media.file?.name)
          .map((value) => value.relativePath)
      );
      filePaths.creatorMediaGalleryPath = creatorMedia.join(',');

      return filePaths;
    } catch (error) {
      return null;
    }
  };

  const sendObrDetailsForReview = async () => {
    try {
      let errorMessage = validateFormSubmit();
      if (detailsSaved && errorMessage.length === AppConstants.zeroIndex) {
        setReviewButtonSpinner(AppConstants.trueText);
        let data = {
          agencyObrUniqueId: obrId,
          comment: null,
          obrStatus: status.statusInReview,
          tncCheck: tncCheck ? AppConstants.yText : AppConstants.nText,
        };

        const url = AppConstants.updateAgencyObrStatusAPI;
        const response = await DataService.put(
          url,
          data,
          AppConstants.emptyString,
          AppConstants.emptyString
        );

        if (response === 1) {
          setReviewButtonSpinner(AppConstants.falseText);
          setShowModal(AppConstants.trueText);
          setModalTypeError(AppConstants.falseText);
          setModalMessage(SuccessMessage.obrDetailsSentForReview);
          setObrStatus(status.statusInReview);
        } else {
          setReviewButtonSpinner(AppConstants.falseText);
          setShowModal(AppConstants.trueText);
          setModalTypeError(AppConstants.trueText);
          setModalMessage(ErrorMessages.expertObrDetailsNotSentForReview);
          setObrStatus(status.statusInReview);
        }
      } else {
        setShowModal(AppConstants.trueText);
        setModalTypeError(AppConstants.trueText);
        if (errorMessage) {
          setModalMessage(errorMessage);
        } else {
          setModalMessage(ErrorMessages.unsavedDetailsError);
        }
      }
    } catch (error) {
      setReviewButtonSpinner(AppConstants.falseText);
      setShowModal(AppConstants.trueText);
      setModalTypeError(AppConstants.trueText);
      setModalMessage(ErrorMessages.expertObrDetailsNotSentForReview);
    }
  };
  const validateFormSubmit = () => {
    let message = AppConstants.emptyString;
    let organizationAdded = AppConstants.falseText;
    let urlPattern = /^((https?|smtp):\/\/)?(www\.)?[\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\/\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%_\+.~#?&//=]*)$/u;

    if (
      userImagePath === AppConstants.emptyString ||
      userImagePath === AppConstants.nullText
    ) {
      if (
        profileImage === null ||
        profileImage === undefined ||
        profileImage === AppConstants.emptyString ||
        !profileImage?.size
      ) {
        message = ErrorMessages.expertObrProfilePictureError;
        return message;
      }
    }

    if (expertObrDetails.yearsOfExperience < AppConstants.zeroIndex) {
      message = ErrorMessages.expertObrInvalidExperienceError;
      return message;
    }
    // validating organization details
    if (
      expertObrDetails?.agencyName?.trim() ||
      expertObrDetails?.agencyEmail?.trim() ||
      expertObrDetails?.agencyContactNumber?.trim() ||
      expertObrDetails?.agencyDescription?.trim() ||
      expertObrDetails?.agencyInstagramLink?.trim() ||
      expertObrDetails?.agencyFacebookLink?.trim() ||
      expertObrDetails?.agencyLinkedinLink?.trim() ||
      expertObrDetails?.agencyWebsiteLink?.trim() ||
      expertObrDetails?.agencyYoutubeLink?.trim() ||
      selectedCountryOrg.length > 0 ||
      selectedStateOrg.length > 0 ||
      selectedCityOrg.length > 0
    ) {
      organizationAdded = true;
      if (!expertObrDetails?.agencyName?.trim()) {
        message = ErrorMessages.expertObrOrganizationNameError;
        return message;
      } else if (
        !FormValidationUtils.isValidEmail(expertObrDetails.agencyEmail)
      ) {
        message = ErrorMessages.expertObrWorkEmailError;
        return message;
      } else if (
        !expertObrDetails?.agencyContactNumber?.trim() ||
        !FormValidationUtils.isValidInternationalMobileNumber(
          expertObrDetails.agencyContactNumber
        )
      ) {
        message = ErrorMessages.expertObrOrgMobileError;
        return message;
      } else if (
        selectedCountryOrg === null ||
        selectedCountryOrg === undefined ||
        selectedCountryOrg.length === 0
      ) {
        message = ErrorMessages.expertObrOrgCountryError;
        return message;
      } else if (
        selectedCountryOrg[AppConstants.zeroIndex].country ===
        AppConstants.countryIndia &&
        (selectedStateOrg === null ||
          selectedStateOrg === undefined ||
          selectedStateOrg.length === 0)
      ) {
        message = ErrorMessages.expertObrOrgStateError;
        return message;
      } else if (
        selectedCountryOrg[AppConstants.zeroIndex].country !==
        AppConstants.countryIndia &&
        (selectedCityOrg === null ||
          selectedCityOrg === undefined ||
          selectedCityOrg.length === 0)
      ) {
        message = ErrorMessages.expertObrOrgCityError;
        return message;
      } else if (
        selectedCountryOrg[AppConstants.zeroIndex].country ===
        AppConstants.countryIndia &&
        (selectedCityOrg === null ||
          selectedCityOrg === undefined ||
          selectedCityOrg.length === 0)
      ) {
        message = ErrorMessages.expertObrOrgCityError;
        return message;
      } else if (!expertObrDetails?.agencyAddress?.trim()) {
        message = ErrorMessages.expertObrOrgAddressError;
        return message;
      } else if (!urlPattern.test(expertObrDetails.agencyGeoLocation)) {
        message = ErrorMessages.expertObrOrgGeolocationError;
        return message;
      } else if (
        expertObrDetails?.agencyWebsiteLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyWebsiteLink)
      ) {
        message = ErrorMessages.expertObrOrgWebsiteLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyLinkedinLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyLinkedinLink)
      ) {
        message = ErrorMessages.expertObrOrgLinkedinLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyFacebookLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyFacebookLink)
      ) {
        message = ErrorMessages.expertObrOrgFacebookLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyInstagramLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyInstagramLink)
      ) {
        message = ErrorMessages.expertObrOrgInstagramLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyYoutubeLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyYoutubeLink)
      ) {
        message = ErrorMessages.expertObrOrgYoutubeLinkError;
        return message;
      } else if (!expertObrDetails?.agencyDescription?.trim()) {
        message = ErrorMessages.expertObrOrgDescriptionError;
        return message;
      }
    }

    let isCentreAdded = false;
    // check if centre details are added if no organization details are added
    if (isCentrePresent) {
      orgCentreForm.forEach((centreDetails, idx) => {
        if (
          centreDetails.centreName?.trim() ||
          centreDetails.centreSpocEmail?.trim() ||
          centreDetails.centreCountryName?.trim() ||
          centreDetails.centreStateName?.trim() ||
          centreDetails.centreCityName?.trim() ||
          centreDetails.centreSpocPhoneNumber ||
          centreDetails.centreAddress?.trim() ||
          centreDetails.centreGeoLocation?.trim()
        ) {
          isCentreAdded = true;
        }
      });
    }

    if (isCentreAdded && !organizationAdded) {
      message = ErrorMessages.expertObrOrgCentreError;
      return message;
    } else if (isCentreAdded && organizationAdded) {
      for (let idx = 0; idx < orgCentreForm.length; idx++) {
        let centreDetails = orgCentreForm[idx];
        if (
          !centreDetails.centreName ||
          centreDetails.centreName?.trim() === AppConstants.emptyString
        ) {
          message = `Please enter the name of centre ${idx + 1}`;
          return message;
        } else if (
          !FormValidationUtils.isValidEmail(centreDetails.centreSpocEmail)
        ) {
          message = `Please enter a valid email of SPOC for centre ${idx + 1} `;
          return message;
        } else if (
          centreDetails.centreCountryName === AppConstants.countryIndia &&
          !FormValidationUtils.isValidIndiaMobileNumber(
            centreDetails.centreSpocPhoneNumber
          )
        ) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (
          centreDetails.centreCountryName !== AppConstants.countryIndia &&
          !FormValidationUtils.isValidInternationalMobileNumber(
            centreDetails.centreSpocPhoneNumber
          )
        ) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (
          centreDetails.centreCountryName === null ||
          centreDetails.centreCountryName === undefined ||
          centreDetails.centreCountryName === AppConstants.emptyString
        ) {
          message = `Please enter centre ${idx + 1} country`;
          return message;
        } else if (
          centreDetails.centreCountryName === AppConstants.countryIndia &&
          (centreDetails.centreStateName === null ||
            centreDetails.centreStateName === undefined ||
            centreDetails.centreStateName === AppConstants.emptyString)
        ) {
          message = `Please enter centre ${idx + 1} state`;
          return message;
        } else if (
          centreDetails.centreCountryName !== AppConstants.countryIndia &&
          (centreDetails.centreCityName === null ||
            centreDetails.centreCityName === undefined ||
            centreDetails.centreCityName === AppConstants.emptyString)
        ) {
          message = `Please enter centre ${idx + 1} city`;
          return message;
        } else if (
          centreDetails.centreCountryName === AppConstants.countryIndia &&
          (centreDetails.centreCityName === undefined ||
            centreDetails.centreCityName === null ||
            centreDetails.centreCityName === AppConstants.emptyString)
        ) {
          message = `Please enter centre ${idx + 1} city`;
          return message;
        } else if (
          !centreDetails.centreAddress ||
          centreDetails.centreAddress?.trim() === AppConstants.emptyString
        ) {
          message = `Please enter centre ${idx + 1} address`;
          return message;
        } else if (!urlPattern.test(centreDetails.centreGeoLocation)) {
          message = `Please enter a valid google maps location URL for centre ${idx + 1}`;
          return message;
        }
      }
    } else if (!isCentreAdded && !organizationAdded) {
      message = 'Please enter skill institute information';
      return message;
    } else if (!isCentreAdded && organizationAdded) {
      message = AppConstants.emptyString;
    }

    if (!addressProof?.size > 0 && !addressProofPath) {
      message = ErrorMessages.expertObrAddressProofError;
    }

    return message;
  };

  const validateFormSave = () => {
    let message = AppConstants.emptyString;
    let organizationAdded = false;
    let isCentreAdded = false;
    let urlPattern = /^((https?|smtp):\/\/)?(www\.)?[\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\/\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%_\+.~#?&//=]*)$/u;
    // let urlPattern = /^((https?|smtp):\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)[\wÀ-ž]*$/;
    if (expertObrDetails.yearsOfExperience < 0) {
      message = ErrorMessages.expertObrInvalidExperienceError;
      return message;
    }

    // validating organization details
    if (
      expertObrDetails.agencyName?.trim() ||
      expertObrDetails?.agencyEmail?.trim() ||
      expertObrDetails?.agencyContactNumber?.trim() ||
      expertObrDetails?.agencyDescription?.trim() ||
      expertObrDetails?.agencyInstagramLink?.trim() ||
      expertObrDetails?.agencyFacebookLink?.trim() ||
      expertObrDetails?.agencyLinkedinLink?.trim() ||
      expertObrDetails?.agencyWebsiteLink?.trim() ||
      expertObrDetails?.agencyYoutubeLink?.trim() ||
      selectedCountryOrg.length > 0 ||
      selectedStateOrg.length > 0 ||
      selectedCityOrg.length > 0
    ) {
      organizationAdded = true;
      if (
        expertObrDetails?.agencyEmail?.trim() &&
        !FormValidationUtils.isValidEmail(expertObrDetails.agencyEmail)
      ) {
        message = ErrorMessages.expertObrWorkEmailError;
        return message;
      } else if (
        expertObrDetails?.agencyContactNumber?.trim() &&
        !FormValidationUtils.isValidInternationalMobileNumber(
          expertObrDetails.agencyContactNumber
        )
      ) {
        message = ErrorMessages.expertObrOrgMobileError;
        return message;
      } else if (
        expertObrDetails?.agencyContactNumber?.trim() &&
        selectedCountryOrg[AppConstants.zeroIndex]?.country ===
        AppConstants.countryIndia &&
        !FormValidationUtils.isValidIndiaMobileNumber(
          expertObrDetails.agencyContactNumber
        )
      ) {
        message = ErrorMessages.expertObrOrgMobileError;
        return message;
      } else if (
        expertObrDetails?.agencyGeoLocation?.trim() &&
        !urlPattern.test(expertObrDetails.agencyGeoLocation)
      ) {
        message = ErrorMessages.expertObrOrgGeolocationError;
        return message;
      } else if (
        expertObrDetails?.agencyWebsiteLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyWebsiteLink)
      ) {
        message = ErrorMessages.expertObrOrgWebsiteLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyLinkedinLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyLinkedinLink)
      ) {
        message = ErrorMessages.expertObrOrgLinkedinLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyFacebookLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyFacebookLink)
      ) {
        message = ErrorMessages.expertObrOrgFacebookLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyInstagramLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyInstagramLink)
      ) {
        message = ErrorMessages.expertObrOrgInstagramLinkError;
        return message;
      } else if (
        expertObrDetails?.agencyYoutubeLink?.trim() &&
        !urlPattern.test(expertObrDetails.agencyYoutubeLink)
      ) {
        message = ErrorMessages.expertObrOrgYoutubeLinkError;
        return message;
      }
    }

    if (isCentrePresent) {
      orgCentreForm.forEach((centreDetails, idx) => {
        if (
          centreDetails.centreName?.trim() ||
          centreDetails.centreSpocEmail?.trim() ||
          centreDetails.centreCountryName?.trim() ||
          centreDetails.centreStateName?.trim() ||
          centreDetails.centreCityName?.trim() ||
          centreDetails.centreSpocPhoneNumber ||
          centreDetails.centreAddress?.trim() ||
          centreDetails.centreGeoLocation?.trim()
        ) {
          isCentreAdded = true;
        }
      });
    }

    if (isCentreAdded && organizationAdded) {
      orgCentreForm.map((centreDetails, idx) => {
        if (
          centreDetails.centreSpocEmail?.trim() &&
          !FormValidationUtils.isValidEmail(centreDetails.centreSpocEmail)
        ) {
          message = `Please enter a valid email of SPOC for centre ${idx + 1} `;
          return message;
        } else if (
          centreDetails.centreSpocPhoneNumber &&
          centreDetails.centreCountryName === AppConstants.countryIndia &&
          !FormValidationUtils.isValidIndiaMobileNumber(
            centreDetails.centreSpocPhoneNumber
          )
        ) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (
          centreDetails.centreSpocPhoneNumber &&
          centreDetails.centreCountryName !== AppConstants.countryIndia &&
          !FormValidationUtils.isValidInternationalMobileNumber(
            centreDetails.centreSpocPhoneNumber
          )
        ) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (
          centreDetails.centreGeoLocation?.trim() &&
          !urlPattern.test(centreDetails.centreGeoLocation)
        ) {
          message = `Please enter a valid google maps location URL for centre ${idx + 1}`;
          return message;
        }
      });
    } else if (isCentreAdded && !organizationAdded) {
      message = ErrorMessages.expertObrOrgCentreError;
      return message;
    } else {
      message = AppConstants.emptyString;
    }

    return message;
  };

  const getAbsoluteUrlPath = (url) => {
    try {
      if (
        url === null ||
        url === undefined ||
        url === AppConstants.emptyString ||
        url.trim() === AppConstants.emptyString
      ) {
        return null;
      }

      if (
        !url.startsWith(`${AppConstants.internetProtocols.http}://`) &&
        !url.startsWith(`${AppConstants.internetProtocols.https}://`)
      ) {
        return `${AppConstants.internetProtocols.https}://${url}`;
      }
      return url;
    } catch (e) {
      return null;
    }
  };

  const getOrganizationCentreProps = () => {
    let isCentreNull = true;

    orgCentreForm.forEach((centreData) => {
      if (
        centreData.centreName?.trim() ||
        centreData.centreSpocEmail?.trim() ||
        centreData.centreSpocPhoneNumber?.trim() ||
        centreData.centreCountryId ||
        centreData.centreStateName?.trim() ||
        centreData.centreCityName?.trim() ||
        centreData.centreAddress?.trim() ||
        centreData.centreGeoLocation?.trim()
      ) {
        isCentreNull = false;
      }
    });

    if (isCentreNull) {
      return null;
    }

    let centreDetails = orgCentreForm.map((centreData) => {
      return {
        centreName: centreData.centreName?.trim() || null,
        centreSpocEmail: centreData.centreSpocEmail || null,
        centreSpocPhoneNumber: centreData.centreSpocPhoneNumber || null,
        centreCountryId: centreData.centreCountryId || null,
        centreState: centreData.centreStateName || null,
        centreCity: centreData.centreCityName || null,
        centreAddress: centreData.centreAddress?.trim() || null,
        centreGeoLocation:
          getAbsoluteUrlPath(centreData.centreGeoLocation) || null,
      };
    });

    return centreDetails;
  };

  // Method used to remove e/E from the input tag for the mobile number
  const validateMobileNumber = (event) => {
    let excludedKeywords = ['-', 'e', '+', 'E', '.', AppConstants.emptyString];
    if (excludedKeywords.includes(event.key)) event.preventDefault();
  };

  const saveObrDetails = async () => {
    let formErrorMessage = validateFormSave();
    if (formErrorMessage.length === 0) {
      try {
        setSaveButtonSpinner(true);
        const filePaths = await uploadFiles();
        if (filePaths !== null) {
          let youtubeLink = getAbsoluteUrlPath(
            expertObrDetails.agencyYoutubeLink
          );
          let facebookLink = getAbsoluteUrlPath(
            expertObrDetails.agencyFacebookLink
          );
          let instagramLink = getAbsoluteUrlPath(
            expertObrDetails.agencyInstagramLink
          );
          let linkedinLink = getAbsoluteUrlPath(
            expertObrDetails.agencyLinkedinLink
          );
          let websiteLink = getAbsoluteUrlPath(
            expertObrDetails.agencyWebsiteLink
          );
          let agencyState, agencyCity;

          if (
            selectedCountryOrg[AppConstants.zeroIndex]?.country ==
            AppConstants.countryIndia
          ) {
            agencyState = selectedStateOrg[AppConstants.zeroIndex]?.name;
            agencyCity = selectedCityOrg[AppConstants.zeroIndex]?.name;
          } else {
            agencyState = selectedStateOrg.length > 0 ? selectedStateOrg : null;
            agencyCity = selectedCityOrg.length > 0 ? selectedCityOrg : null;
          }

          let data = {
            addressProofRelativePath: filePaths.addressProofPath
              ? filePaths.addressProofPath
              : addressProofPath
                ? expertObrDetails.addressProofRelativePath
                : null,
            tncCheck: tncCheck ? AppConstants.yText : AppConstants.nText,
            agencyObrUniqueId: obrId,
            webBannerImageRelativePath: filePaths.webBannerImagePath
              ? filePaths.webBannerImagePath
              : webBannerImagePath
                ? expertObrDetails.webBannerImageRelativePath
                : null,
            videoMessageThumbnailRelativePath:
              filePaths.videoMessageThumbnailPath
                ? filePaths.videoMessageThumbnailPath
                : videoMessageThumbnailPath
                  ? expertObrDetails.videoMessageThumbnailRelativePath
                  : null,
            tabletBannerImageRelativePath: filePaths.tabletBannerImagePath
              ? filePaths.tabletBannerImagePath
              : tabletBannerImagePath
                ? expertObrDetails.tabletBannerImageRelativePath
                : null,
            mobileBannerImageRelativePath: filePaths.mobileBannerImagePath
              ? filePaths.mobileBannerImagePath
              : mobileBannerImagePath
                ? expertObrDetails.mobileBannerImageRelativePath
                : null,
            userImageRelativePath: filePaths.userImagePath
              ? filePaths.userImagePath
              : userImagePath
                ? expertObrDetails.userImageRelativePath
                : null,
            yearsOfExperience: expertObrDetails.yearsOfExperience?.trim()
              ? expertObrDetails.yearsOfExperience
              : null,
            agencyFacebookLink: facebookLink || null,
            agencyInstagramLink: instagramLink || null,
            agencyLinkedinLink: linkedinLink || null,
            agencyWebsiteLink: websiteLink || null,
            agencyYoutubeLink: youtubeLink || null,
            videoMessageRelativePath: filePaths.videoMessagePath
              ? filePaths.videoMessagePath
              : videoMessagePath
                ? expertObrDetails.videoMessageRelativePath
                : null,
            agencyDescription:
              expertObrDetails.agencyDescription?.trim() || null,
            agencyState: agencyState ? agencyState : null,
            agencyCity: agencyCity ? agencyCity : null,
            agencyAddress: expertObrDetails.agencyAddress || null,
            agencyContactNumber: expertObrDetails.agencyContactNumber || null,
            agencyEmail: expertObrDetails.agencyEmail || null,
            agencyCountryId:
              selectedCountryOrg[AppConstants.zeroIndex]?.id || null,
            agencyGeoLocation: expertObrDetails.agencyGeoLocation,
            agencyCentresDetails: isCentrePresent
              ? getOrganizationCentreProps()
              : null,
            isWebsiteLinkUpdated:
              capturedLeadWebsiteLink !== expertObrDetails.agencyWebsiteLink
                ? AppConstants.yText
                : AppConstants.nText,
            galleryMediaPaths:
              filePaths.creatorMediaGalleryPath || AppConstants.nullText,
          };

          const url = AppConstants.postAgencyDetailsAPI;
          const response = await DataService.post(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
          );

          if (
            response &&
            response.status === AppConstants.httpResponseCodes.responseCode200
          ) {
            setDetailsSaved(AppConstants.trueText);
            setSaveButtonSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
            setModalTypeError(AppConstants.falseText);
            setModalMessage(SuccessMessage.obrDetailsSavedSuccessFully);
          } else {
            setSaveButtonSpinner(AppConstants.falseText);
            setReviewButtonSpinner(AppConstants.falseText);
            setDetailsSaved(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
            setModalTypeError(AppConstants.trueText);
            setModalMessage(ErrorMessages.expertObrDetailsNotSavedError);
          }
        } else {
          setSaveButtonSpinner(AppConstants.falseText);
          setReviewButtonSpinner(AppConstants.falseText);
          setDetailsSaved(AppConstants.falseText);
          setModalTypeError(AppConstants.falseText);
          setModalMessage(ErrorMessages.expertObrDetailsNotSavedError);
        }
      } catch (error) {
        setSaveButtonSpinner(AppConstants.falseText);
        setShowModal(AppConstants.trueText);
        setDetailsSaved(AppConstants.falseText);
        setModalTypeError(AppConstants.trueText);
        setModalMessage(ErrorMessages.expertObrDetailsNotSavedError);
      }
    } else {
      setShowModal(AppConstants.trueText);
      setDetailsSaved(AppConstants.falseText);
      setModalTypeError(AppConstants.trueText);
      setModalMessage(formErrorMessage);
    }
  };

  //handle organization country change
  function handleCountryChange(value) {
    if (value.length !== zeroValue) {
      setSelectedCountryOrg(value);
      setSelectedStateOrg([]);
      setSelectedCityOrg([]);
      fetchOrganizationCities([]);
    } else {
      setSelectedCountryOrg([]);
      setSelectedStateOrg([]);
      setSelectedCityOrg([]);
      fetchOrganizationCities([]);
    }
    setDetailsSaved(AppConstants.falseText);
  }

  //handle organization state change
  function handleStateChange(value) {
    if (value.length !== zeroValue) {
      setSelectedStateOrg([value[zeroValue]]);
      fetchOrganizationCities([value[zeroValue]]);
    } else {
      setSelectedStateOrg([]);
      setSelectedCityOrg([]);
      fetchOrganizationCities([]);
    }
    setDetailsSaved(AppConstants.falseText);
  }

  function handleCityChange(value) {
    if (value.length !== zeroValue) {
      setSelectedCityOrg([value[zeroValue]]);
    } else {
      setSelectedCityOrg([]);
    }
    setDetailsSaved(AppConstants.falseText);
  }

  //handle image file change
  const handleImageUpload = (e, fileType) => {
    if (e.target.files[AppConstants.zeroIndex] !== undefined) {
      const type = e.target.files[AppConstants.zeroIndex].type;
      setDetailsSaved(false);
      const validImgaeInput = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validImgaeInput.includes(type)) {
        if (fileType === AppConstants.expertObrMicrositeUserImageText) {
          setProfileImageError(ErrorMessages.validImageFormatError);
          setProfileImage(AppConstants.emptyString);
          document.getElementById(AppConstants.profilePicture).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeWebBannerImageText) {
          setWebBannerImageError(ErrorMessages.validImageFormatError);
          setWebBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.webBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeTabletBannerImageText) {
          setTabletBannerImageError(ErrorMessages.validImageFormatError);
          setTabletBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.tabletBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeMobileBannerImageText) {
          setMobileBannerImageError(ErrorMessages.validImageFormatError);
          setMobileBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.mobileBanner).value = null;
        }
        if (fileType === AppConstants.obrVideoMessageThumbnail) {
          setVideoMessageThumbnailError(ErrorMessages.validImageFormatError);
          setVideoMessageThumbnail(AppConstants.emptyString);
          document.getElementById(AppConstants.obrVideoMessageThumbnail).value =
            null;
        }
      } else if (e.target.files[0]?.size > AppConstants.image5MBSizeLimit) {
        if (fileType === AppConstants.expertObrMicrositeUserImageText) {
          setProfileImageError(ErrorMessages.imageError5MbSize);
          setProfileImage(AppConstants.emptyString);
          document.getElementById(AppConstants.profilePicture).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeWebBannerImageText) {
          setWebBannerImageError(ErrorMessages.imageError5MbSize);
          setWebBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.webBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeTabletBannerImageText) {
          setTabletBannerImageError(ErrorMessages.imageError5MbSize);
          setTabletBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.tabletBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeMobileBannerImageText) {
          setMobileBannerImageError(ErrorMessages.imageError5MbSize);
          setMobileBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.mobileBanner).value = null;
        }
        if (fileType === AppConstants.obrVideoMessageThumbnail) {
          setVideoMessageThumbnailError(ErrorMessages.imageError5MbSize);
          setVideoMessageThumbnail(AppConstants.emptyString);
          document.getElementById(AppConstants.obrVideoMessageThumbnail).value =
            null;
        }
      } else if (fileType === AppConstants.expertObrMicrositeUserImageText) {
        setProfileImage(e.target.files[AppConstants.zeroIndex]);
        setUserImagePath(AppConstants.emptyString);
        setProfileImageError(null);
      } else if (
        fileType === AppConstants.expertObrMicrositeWebBannerImageText
      ) {
        setWebBannerImage(e.target.files[AppConstants.zeroIndex]);
        setWebBannerImageError(null);
        setWebBannerImagePath(AppConstants.emptyString);
      } else if (
        fileType === AppConstants.expertObrMicrositeTabletBannerImageText
      ) {
        setTabletBannerImage(e.target.files[AppConstants.zeroIndex]);
        setTabletBannerImagePath(AppConstants.emptyString);
        setTabletBannerImageError(null);
      } else if (
        fileType === AppConstants.expertObrMicrositeMobileBannerImageText
      ) {
        setMobileBannerImage(e.target.files[AppConstants.zeroIndex]);
        setMobileBannerImageError(null);
        setMobileBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.obrVideoMessageThumbnail) {
        setVideoMessageThumbnail(e.target.files[AppConstants.zeroIndex]);
        setVideoMessageThumbnailError(null);
        setVideoMessageThumbnailPath(AppConstants.emptyString);
      }
    } else {
      if (fileType === AppConstants.expertObrMicrositeUserImageText) {
        setProfileImageError(null);
        setUserImagePath(AppConstants.emptyString);
      } else if (
        fileType === AppConstants.expertObrMicrositeWebBannerImageText
      ) {
        setWebBannerImageError(null);
        setWebBannerImagePath(AppConstants.emptyString);
      } else if (
        fileType === AppConstants.expertObrMicrositeTabletBannerImageText
      ) {
        setTabletBannerImageError(null);
        setTabletBannerImagePath(AppConstants.emptyString);
      } else if (
        fileType === AppConstants.expertObrMicrositeMobileBannerImageText
      ) {
        setMobileBannerImageError(null);
        setMobileBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.obrVideoMessageThumbnail) {
        setVideoMessageThumbnailError(null);
        setVideoMessageThumbnailPath(AppConstants.emptyString);
      }
    }
  };

  //handle video file change
  const handleVideoFileChange = (e) => {
    var fileTypeArray = [
      'video/mp4',
      'video/mkv',
      'video/x-m4v',
      'video/quicktime',
      'video/ai',
      'video/ogg',
      'video/webm',
      'video/x-msvideo',
    ];
    if (e.target.files[AppConstants.zeroIndex] !== undefined) {
      if (
        !fileTypeArray.includes(e.target.files[AppConstants.zeroIndex].type)
      ) {
        document.getElementById(AppConstants.videoMessage).value = null;
        setVideoMessageError(ErrorMessages.expertObrMicrositeVideoFormatError);
      } else {
        setVideoMessage(e.target.files[AppConstants.zeroIndex]);
        setVideoMessageError(null);
      }
    } else {
      setVideoMessageError(null);
    }
    setDetailsSaved(false);
  };

  //handle centre form changes
  const handleCentreFormChange = (idx, event) => {
    let data = [...orgCentreForm];
    data[idx][event.target.name] = event.target.value;
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  async function fetchCentreCities(selectedState, index) {
    let data = [...orgCentreForm];
    if (
      selectedState === null ||
      selectedState === undefined ||
      selectedState?.length === zeroValue
    ) {
      data[index].cityList = [];
      setOrgCentreForm(data);
    }
    var config = {
      headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates },
    };
    const response = await fetch(
      `${AppConstants.getStatesApi}${AppConstants.forwardSlash}${selectedState[zeroValue].iso2}${AppConstants.getCityUri}`,
      config
    );
    const responseData = await response.json();
    if (responseData.length !== zeroValue) {
      data[index].cityList = responseData;
      setOrgCentreForm(data);
    }
    return null;
  }

  // handle centre country change
  const handleCentreCountryChange = (idx, value) => {
    let data = [...orgCentreForm];

    if (value.length !== zeroValue) {
      data[idx].centreCountryName = value[zeroValue].country;
      data[idx].centreCountryId = value[zeroValue].id;
      data[idx].centreCountry = value;
      data[idx].centreState = [];
      data[idx].centreStateName = AppConstants.emptyString;
      data[idx].centreCityName = AppConstants.emptyString;
      data[idx].centreCity = [];
      data[idx].cityList = [];
    } else {
      data[idx].centreCountry = [];
      data[idx].centreCountryName = AppConstants.emptyString;
      data[idx].centreCountryId = AppConstants.emptyString;
      data[idx].centreState = [];
      data[idx].centreStateName = AppConstants.emptyString;
      data[idx].centreCityName = AppConstants.emptyString;
      data[idx].centreCity = [];
      data[idx].cityList = [];
    }
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  // handle centre state change (for suggestive search)
  const handleCentreStateChange = async (idx, value) => {
    let data = [...orgCentreForm];
    if (value.length !== zeroValue) {
      data[idx].centreState = value;
      data[idx].centreStateName = value[zeroValue].name;
      fetchCentreCities(value, idx);
    } else {
      data[idx].centreStateName = AppConstants.emptyString;
      data[idx].centreCityName = AppConstants.emptyString;
      data[idx].centreCity = [];
      data[idx].cityList = [];
      data[idx].centreState = [];
    }
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  // handle centre city change (for suggestive search)
  const handleCentreCityChange = (idx, value) => {
    let data = [...orgCentreForm];
    if (value.length !== zeroValue) {
      data[idx].centreCityName = value[zeroValue].name;
      data[idx].centreCity = value;
    } else {
      data[idx].centreCity = [];
      data[idx].centreCityName = AppConstants.emptyString;
    }
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  // add centre form
  const addFields = () => {
    let addCentreForm = {
      uid: DateTimeUtil.fetchCurrentTimestamp(),
      centreName: AppConstants.emptyString,
      centreSpocEmail: AppConstants.emptyString,
      centreSpocPhoneNumber: AppConstants.emptyString,
      centreCountryName: AppConstants.emptyString,
      centreCountry: [],
      centreState: [],
      centreStateName: AppConstants.emptyString,
      centreCity: [],
      centreCityName: AppConstants.emptyString,
      centreAddress: AppConstants.emptyString,
      centreGeoLocation: AppConstants.emptyString,
      centreCountryId: AppConstants.emptyString,
      cityList: [],
    };
    setOrgCentreForm([...orgCentreForm, addCentreForm]);
    setDetailsSaved(false);
  };

  //delete centre form
  const removeFields = (idx) => {
    let data = [...orgCentreForm];
    data.splice(idx, 1);
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  const checkValidYearOfExperience = (event) => {
    let excludedKeywords = ['-', 'e', 'E', AppConstants.emptyString];
    if (excludedKeywords.includes(event.key)) event.preventDefault();
  };

  const removeSelectedMedia = (media) => {
    let newMediaArray = selectedMediaGallery.filter(
      (value) => value.id !== media.id
    );
    setSelectedMediaGallery(newMediaArray);
    if (newMediaArray.length === 0) {
      setShowCreatorGallery(AppConstants.falseText);
    }
  };

  const handleGalleryMediaChange = (e) => {
    let selectedFiles = e.target.files;
    let filesArray = [];
    if (selectedFiles && selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (
          selectedFiles[i] &&
          validMediaTypes.includes(selectedFiles[i].type)
        ) {
          filesArray.push({
            file: selectedFiles[i],
            url: URL.createObjectURL(selectedFiles[i]),
            type: AppConstants.supportedImageFormats.includes(
              selectedFiles[i].type
            )
              ? 'Image'
              : 'Video',
            id: Math.floor(Math.random() * 100000 + 1).toString(),
          });
        }
      }

      setSelectedMediaGallery([...selectedMediaGallery, ...filesArray]);
    }
  };

  const hanldeGalleryClose = () => {
    setShowCreatorGallery(AppConstants.falseText);
  };

  const viewGallery = () => {
    setShowCreatorGallery(AppConstants.trueText);
  };

  const toggleCentreDetails = () => {
    setDetailsSaved(false);
    setIsCentrePresent(!isCentrePresent);
  };

  return (
    <>
      {obrLinkValid &&
        (obrStatus === status.statusDraft ||
          obrStatus === status.statusCreated) && (
          <div className={classes.container}>
            <div className={classes.headingBar}>
              {obrStatus === status.statusCreated && (
                <h5 className="mb-2">
                  Welcome{' '}
                  <p className={classes.userName}>
                    {expertObrDetails?.agencyHeadFirstName}
                    {AppConstants.space}
                    {expertObrDetails?.agencyHeadLastName}
                  </p>
                  ,
                </h5>
              )}
              {obrStatus !== status.statusCreated && (
                <h5 className="mb-2">
                  Welcome Back{' '}
                  <p className={classes.userName}>
                    {expertObrDetails?.agencyHeadFirstName}
                    {AppConstants.space}
                    {expertObrDetails?.agencyHeadLastName}
                  </p>
                  ,
                </h5>
              )}
              {obrStatus === status.statusRejected && (
                <div className={classes.rejectedInfo}>OBR Rejected</div>
              )}
            </div>
            <div className="row">
              <h1 className={classes.formSectionHeading}>
                {expertObrDetails.agencyType === AppConstants.schoolText
                  ? 'School Head Information'
                  : 'Agency Head Information'}
              </h1>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label
                  className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}
                >
                  First Name
                </Form.Label>
                <Form.Control
                  name="agencyHeadFirstName"
                  value={expertObrDetails.agencyHeadFirstName}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabelDisabled}>
                  Last Name
                </Form.Label>
                <Form.Control
                  name="agencyHeadLastName"
                  value={expertObrDetails.agencyHeadLastName}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              <div className=" mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label
                  className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}
                >
                  Email
                </Form.Label>
                <Form.Control
                  name="agencyHeadEmail"
                  value={expertObrDetails.agencyHeadEmail}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabelDisabled}>
                  Mobile Number
                </Form.Label>
                <Form.Control
                  name="agencyHeadPhoneNumber"
                  value={expertObrDetails.agencyHeadPhoneNumber}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              {expertObrDetails.selectedSkills !== null &&
                expertObrDetails.selectedSkills.length > 0 && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label
                      className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}
                    >
                      Skill
                    </Form.Label>
                    {skillLength <= 1 && (
                      <Form.Control
                        name="skill"
                        value={academySkillValue}
                        className={classes.formOBRInput}
                        type="text"
                        disabled
                      />
                    )}
                    {skillLength > 1 && (
                      <span
                        data-tooltip-place="top"
                        data-tooltip-id="academySkillValue"
                      >
                        <Form.Control
                          name="skill"
                          value={academySkillValue}
                          className={classes.formOBRInput}
                          type="text"
                          disabled
                        />
                      </span>
                    )}
                    {skillLength > 1 && (
                      <Tooltip
                        className={classes.tooltipMessageDownload}
                        id="academySkillValue"
                      >
                        <span className={classes.tooltipMessage}>
                          {academySkillValue}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                )}
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabelDisabled}>
                  Country
                </Form.Label>
                <Form.Control
                  name="agencyHeadCountry"
                  value={expertObrDetails.agencyHeadCountry}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabelDisabled}>
                  State
                </Form.Label>
                <Form.Control
                  name="agencyHeadState"
                  value={expertObrDetails.agencyHeadState}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabelDisabled}>
                  City
                </Form.Label>
                <Form.Control
                  name="agencyHeadCity"
                  value={expertObrDetails.agencyHeadCity}
                  className={classes.formOBRInput}
                  type="text"
                  disabled
                />
              </div>
              <h1 className={classes.formSectionHeading}>
                {expertObrDetails.agencyType === AppConstants.schoolText
                  ? "Let's build your school's branding webpage! Here's what you need to do."
                  : "Let's build your institute's branding webpage! Here's what you need to do."}
              </h1>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.profilePicture}
                    name={AppConstants.profilePicture}
                    accept="image/png,image/jpeg,image/jpg"
                    label="Profile Picture"
                    mandatory={true}
                    handleFileChange={(e) =>
                      handleImageUpload(
                        e,
                        AppConstants.expertObrMicrositeUserImageText
                      )
                    }
                    infoMessage={AppConstants.obrProfilePictureDimensions}
                    inputFile={profileImage}
                    obrClasses={true}
                    defaultFile={
                      userImagePath &&
                      getDataTransfer(
                        extractFileName(userImagePath) || 'Profile_Picture'
                      ).files[0]
                    }
                  />
                  {/* <Form.Label className={classes.formOBRLabel}>Profile Picture<span className="redAstrisk">{AppConstants.asterisk}</span></Form.Label>
                            <Form.Control id={AppConstants.profilePicture} className={classes.uploadOBRFileInput} name={AppConstants.profilePicture} type='file' accept="image/png,image/jpeg,image/jpg" onChange={(e) => handleImageUpload(e, AppConstants.expertObrMicrositeUserImageText)} /> */}
                  {(userImagePath || profileImage?.size > 0) && (
                    <p
                      onClick={() => {
                        setUserImagePath(AppConstants.emptyString);
                        removeFile(AppConstants.profilePicture);
                      }}
                      className="removeFileText"
                    >
                      Remove file
                    </p>
                  )}
                  {profileImageError && (
                    <p className={classes.errorMessage}>{profileImageError}</p>
                  )}
                </div>
                {expertObrDetails.userImageAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.userImageAbsolutePath,
                        AppConstants.profilePicture
                      );
                      setProfileImageSpinner(true);
                    }}
                    data-tooltip-id="downloadProfileImage"
                    className={classes.removeBtn}
                  >
                    {profileImageSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadProfileImage"
                >
                  <span className={classes.tooltipMessage}>
                    Download Profile Picture
                  </span>
                </Tooltip>
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabel}>
                  Years Of Experience
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  placeholder="Years of experience"
                  name="yearsOfExperience"
                  value={expertObrDetails.yearsOfExperience}
                  type="number"
                  min={0}
                  onKeyDown={checkValidYearOfExperience}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      yearsOfExperience: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.videoMessage}
                    name={AppConstants.videoMessage}
                    accept="video/mp4,video/mkv,video/x-m4v,video/quicktime,video/*"
                    label="Video Message"
                    handleFileChange={(e) => handleVideoFileChange(e)}
                    infoMessage={AppConstants.agencyObrFormVideoMessageIButton}
                    inputFile={videoMessage}
                    obrClasses={true}
                    defaultFile={
                      videoMessagePath &&
                      getDataTransfer(
                        extractFileName(videoMessagePath) || 'Video_Message'
                      ).files[0]
                    }
                  />
                  {(videoMessage?.size > zeroValue || videoMessagePath) && (
                    <p
                      onClick={() => {
                        removeFile(AppConstants.videoMessage);
                        setVideoMessagePath(AppConstants.emptyString);
                      }}
                      className="removeFileText"
                    >
                      Remove file
                    </p>
                  )}
                  {videoMessageError && (
                    <p className="errorText">{videoMessageError}</p>
                  )}
                </div>
                {expertObrDetails.videoMessageAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.videoMessageAbsolutePath,
                        AppConstants.videoMessage
                      );
                      setVideoMessageSpinner(true);
                    }}
                    data-tooltip-id="downloadVideoMessageInfo"
                    className={classes.removeBtn}
                  >
                    {videoMessageSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadVideoMessageInfo"
                >
                  <span className={classes.tooltipMessage}>
                    Download Video Message
                  </span>
                </Tooltip>
              </div>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.obrVideoMessageThumbnail}
                    name={AppConstants.obrVideoMessageThumbnail}
                    accept="image/png,image/jpeg,image/jpg"
                    label="Video Message Thumbnail Image"
                    handleFileChange={(e) =>
                      handleImageUpload(
                        e,
                        AppConstants.obrVideoMessageThumbnail
                      )
                    }
                    infoMessage={
                      AppConstants.expertObrVideoThumbnailMessageDimension
                    }
                    inputFile={videoMessageThumbnail}
                    obrClasses={true}
                    defaultFile={
                      videoMessageThumbnailPath &&
                      getDataTransfer(
                        extractFileName(videoMessageThumbnailPath) ||
                        'VideoMessageThumbnail'
                      ).files[0]
                    }
                  />
                  {(videoMessageThumbnail?.size > zeroValue ||
                    videoMessageThumbnailPath) && (
                      <p
                        onClick={() => {
                          removeFile(AppConstants.obrVideoMessageThumbnail);
                          setVideoMessageThumbnailPath(AppConstants.emptyString);
                        }}
                        className="removeFileText"
                      >
                        Remove file
                      </p>
                    )}
                  {videoMessageThumbnailError && (
                    <p className={classes.errorMessage}>
                      {videoMessageThumbnailError}
                    </p>
                  )}
                </div>
                {expertObrDetails.videoMessageThumbnailAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.videoMessageThumbnailAbsolutePath,
                        AppConstants.obrVideoMessageThumbnail
                      );
                      setVideoMessageThumbnailSpinner(true);
                    }}
                    data-tooltip-id="downloadVideoThumbnailInfo"
                    className={classes.removeBtn}
                  >
                    {videoMessageThumbnailSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadVideoThumbnailInfo"
                >
                  <span className={classes.tooltipMessage}>
                    Download Video Message Thumbnail Image
                  </span>
                </Tooltip>
              </div>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.webBanner}
                    name={AppConstants.webBanner}
                    accept="image/png,image/jpeg,image/jpg"
                    label="Web Banner Image"
                    handleFileChange={(e) =>
                      handleImageUpload(
                        e,
                        AppConstants.expertObrMicrositeWebBannerImageText
                      )
                    }
                    infoMessage={
                      AppConstants.expertObrFormMicrositeWebBannerMessage
                    }
                    inputFile={webBannerImage}
                    obrClasses={true}
                    defaultFile={
                      webBannerImagePath &&
                      getDataTransfer(
                        extractFileName(webBannerImagePath) || 'Web_Banner'
                      ).files[0]
                    }
                  />
                  {(webBannerImage?.size > zeroValue || webBannerImagePath) && (
                    <p
                      onClick={() => {
                        removeFile(AppConstants.webBanner);
                        setWebBannerImagePath(AppConstants.emptyString);
                      }}
                      className="removeFileText"
                    >
                      Remove file
                    </p>
                  )}
                  {webBannerImageError && (
                    <p className={classes.errorMessage}>
                      {webBannerImageError}
                    </p>
                  )}
                </div>
                {expertObrDetails.webBannerImageAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.webBannerImageAbsolutePath,
                        AppConstants.webBanner
                      );
                      setWebBannerImageSpinner(true);
                    }}
                    data-tooltip-id="downloadWebBannerInfo"
                    className={classes.removeBtn}
                  >
                    {webBannerImageSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadWebBannerInfo"
                >
                  <span className={classes.tooltipMessage}>
                    Download Web Banner Image
                  </span>
                </Tooltip>
              </div>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.tabletBanner}
                    name={AppConstants.tabletBanner}
                    accept="image/png,image/jpeg,image/jpg"
                    label="Tablet Banner Image"
                    handleFileChange={(e) =>
                      handleImageUpload(
                        e,
                        AppConstants.expertObrMicrositeTabletBannerImageText
                      )
                    }
                    infoMessage={
                      AppConstants.expertObrFormMicrositeTabletBannerMessage
                    }
                    inputFile={tabletBannerImage}
                    obrClasses={true}
                    defaultFile={
                      tabletBannerImagePath &&
                      getDataTransfer(
                        extractFileName(tabletBannerImagePath) ||
                        'Tablet_Banner'
                      ).files[0]
                    }
                  />

                  {(tabletBannerImage?.size > 0 || tabletBannerImagePath) && (
                    <p
                      onClick={() => {
                        removeFile(AppConstants.tabletBanner);
                        setTabletBannerImagePath(AppConstants.emptyString);
                      }}
                      className="removeFileText"
                    >
                      Remove file
                    </p>
                  )}
                  {tabletBannerImageError && (
                    <p className={classes.errorMessage}>
                      {tabletBannerImageError}
                    </p>
                  )}
                </div>
                {expertObrDetails.tabletBannerImageAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.tabletBannerImageAbsolutePath,
                        AppConstants.tabletBanner
                      );
                      setTabletBannerImageSpinner(true);
                    }}
                    data-tooltip-id="downloadTabletBannerInfo"
                    className={classes.removeBtn}
                  >
                    {tabletBannerImageSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadTabletBannerInfo"
                >
                  <span className={classes.tooltipMessage}>
                    Download Tablet Banner Image
                  </span>
                </Tooltip>
              </div>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.mobileBanner}
                    name={AppConstants.mobileBanner}
                    accept="image/png,image/jpeg,image/jpg"
                    label="Mobile Banner Image"
                    handleFileChange={(e) =>
                      handleImageUpload(
                        e,
                        AppConstants.expertObrMicrositeMobileBannerImageText
                      )
                    }
                    infoMessage={
                      AppConstants.expertObrFormMicrositeMobileBannerMessage
                    }
                    inputFile={mobileBannerImage}
                    obrClasses={true}
                    defaultFile={
                      mobileBannerImagePath &&
                      getDataTransfer(
                        extractFileName(mobileBannerImagePath) ||
                        'Mobile_Banner'
                      ).files[0]
                    }
                  />

                  {(mobileBannerImage?.size > zeroValue ||
                    mobileBannerImagePath) && (
                      <p
                        onClick={() => {
                          removeFile(AppConstants.mobileBanner);
                          setMobileBannerImagePath(AppConstants.emptyString);
                        }}
                        className="removeFileText"
                      >
                        Remove file
                      </p>
                    )}
                  {mobileBannerImageError && (
                    <p className={classes.errorMessage}>
                      {mobileBannerImageError}
                    </p>
                  )}
                </div>
                {expertObrDetails.mobileBannerImageAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.mobileBannerImageAbsolutePath,
                        AppConstants.mobileBanner
                      );
                      setMobileBannerImageSpinner(true);
                    }}
                    data-tooltip-id="downloadMobileBannerInfo"
                    className={classes.removeBtn}
                  >
                    {mobileBannerImageSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadMobileBannerInfo"
                >
                  <span className={classes.tooltipMessage}>
                    Download Mobile Banner Image
                  </span>
                </Tooltip>
              </div>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id="creatorMediaGallery"
                    name="creatorMediaGallery"
                    accept="image/png,image/jpeg,image/jpg,video/mp4,video/mkv,video/x-m4v,video/quicktime,video/*"
                    label="Your Spotlight"
                    handleFileChange={handleGalleryMediaChange}
                    infoMessage={AppConstants.obrGalleryInfoIconText}
                    inputFile={selectedMediaGallery}
                    obrClasses={true}
                    placeholder={'Select images/videos for your spotlight'}
                    multiple={true}
                  />
                  {selectedMediaGallery.length > 0 && (
                    <p
                      onClick={() => {
                        setSelectedMediaGallery([]);
                      }}
                      className="removeFileText"
                    >
                      Remove files
                    </p>
                  )}
                </div>
                {selectedMediaGallery.length > 0 && (
                  <button
                    type="button"
                    onClick={viewGallery}
                    data-tooltip-id="viewGallery"
                    className={classes.removeBtn}
                  >
                    <FaRegEye />
                  </button>
                )}
                <div className={classes.galleryTooltip}>
                  <Tooltip
                    className={classes.tooltipMessageDownload}
                    id="viewGallery"
                  >
                    <span className={classes.tooltipMessage}>View</span>
                  </Tooltip>
                </div>
              </div>

              <h1 className={classes.formSectionHeading}>
                {expertObrDetails.agencyType === AppConstants.schoolText
                  ? 'Tell us about your School, so we can better understand what you offer'
                  : 'Tell us about your Skill Institute, so we can better understand what you offer'}
              </h1>
              <Tooltip
                className={classes.tooltipMessageDownload}
                id="organizationInfoHeadingId"
              >
                <span className={classes.tooltipMessage}>
                  {AppConstants.expertObrFormMicrositeIbutton}
                </span>
              </Tooltip>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label
                  className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}
                >
                  Name
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Form.Control
                  name="agencyName"
                  placeholder="What is the name of your institute?"
                  value={expertObrDetails.agencyName}
                  className={classes.formOBRInput}
                  type="text"
                  minLength={3}
                  maxLength={65}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyName: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                  disabled
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Email
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Form.Control
                  name="expertOrganizationEmail"
                  placeholder="Enter your work email address"
                  value={expertObrDetails.agencyEmail}
                  className={classes.formOBRInput}
                  type="text"
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyEmail: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={classes.formOBRLabel}>
                  Mobile Number
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Form.Control
                  name="expertOrganizationContactNumber"
                  placeholder="Enter your work mobile number"
                  onKeyDown={validateMobileNumber}
                  value={expertObrDetails.agencyContactNumber}
                  className={classes.formOBRInput}
                  type="number"
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyContactNumber: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Country
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Typeahead
                  className={`${classes.obrInputontainer} adminCreateObrTypeahead`}
                  name="agencyCountry"
                  id="agencyCountry"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? 'In which country is this school located?'
                      : 'In which country is this institute located?'
                  }
                  labelKey="country"
                  onChange={(e) => handleCountryChange(e)}
                  options={countryList}
                  selected={selectedCountryOrg}
                />
              </div>
              {selectedCountryOrg !== AppConstants.nullText &&
                selectedCountryOrg !== AppConstants.emptyString &&
                selectedCountryOrg !== undefined &&
                selectedCountryOrg.length > 0 &&
                selectedCountryOrg[AppConstants.zeroIndex]?.country ===
                AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      State
                      <span className="redAstrisk">
                        {AppConstants.asterisk}
                      </span>
                    </Form.Label>
                    <Typeahead
                      className={`${classes.obrInputontainer} adminCreateObrTypeahead`}
                      name="agencyState"
                      id="agencyState"
                      placeholder={
                        expertObrDetails.agencyType === AppConstants.schoolText
                          ? 'In which state is this school located?'
                          : 'In which state is this institute located?'
                      }
                      labelKey="name"
                      onChange={(e) => handleStateChange(e)}
                      options={stateList}
                      selected={selectedStateOrg}
                    />
                  </div>
                )}
              {selectedCountryOrg !== AppConstants.nullText &&
                selectedCountryOrg !== AppConstants.emptyString &&
                selectedCountryOrg !== undefined &&
                selectedCountryOrg.length > 0 &&
                selectedCountryOrg[AppConstants.zeroIndex]?.country !==
                AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      State
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={classes.formOBRInput}
                      placeholder={
                        expertObrDetails.agencyType === AppConstants.schoolText
                          ? 'In which state is this school located?'
                          : 'In which state is this institute located?'
                      }
                      name="organizationNotIndiaState"
                      id="organizationNotIndiaState"
                      value={selectedStateOrg}
                      onChange={(e) => {
                        setSelectedStateOrg(e.target.value);
                      }}
                    />
                  </div>
                )}
              {selectedCountryOrg !== AppConstants.nullText &&
                selectedCountryOrg !== AppConstants.emptyString &&
                selectedCountryOrg.length > 0 &&
                selectedCountryOrg !== undefined &&
                selectedCountryOrg[AppConstants.zeroIndex]?.country ==
                AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      City
                      <span className="redAstrisk">
                        {AppConstants.asterisk}
                      </span>
                    </Form.Label>
                    <Typeahead
                      className={`${classes.obrInputontainer} adminCreateObrTypeahead`}
                      name="agencyCity"
                      id="agencyCity"
                      placeholder={
                        expertObrDetails.agencyType === AppConstants.schoolText
                          ? 'In which city is this school located?'
                          : 'In which city is this institute located?'
                      }
                      labelKey="name"
                      onChange={(e) => handleCityChange(e)}
                      options={organizationCityList}
                      selected={selectedCityOrg}
                    />
                  </div>
                )}
              {selectedCountryOrg !== AppConstants.nullText &&
                selectedCountryOrg !== AppConstants.emptyString &&
                selectedCountryOrg.length > 0 &&
                selectedCountryOrg !== undefined &&
                selectedCountryOrg[AppConstants.zeroIndex]?.country !==
                AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      City
                      <span className="redAstrisk">
                        {AppConstants.asterisk}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={classes.formOBRInput}
                      placeholder={
                        expertObrDetails.agencyType === AppConstants.schoolText
                          ? 'In which city is this school located?'
                          : 'In which city is this institute located?'
                      }
                      name="organizationNotIndiaCity"
                      id="organizationNotIndiaCity"
                      value={selectedCityOrg}
                      onChange={(e) => {
                        setSelectedCityOrg(e.target.value);
                      }}
                    />
                  </div>
                )}
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Address
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="text"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? 'Enter the complete address of the school'
                      : 'Enter the complete address of the institute'
                  }
                  name="organizationAddress"
                  value={expertObrDetails.agencyAddress}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyAddress: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Geo Location
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="url"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? "Enter your school's Google Maps location URL"
                      : "Enter your institute's Google Maps location URL"
                  }
                  name="agencyGeoLocation"
                  value={expertObrDetails.agencyGeoLocation}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyGeoLocation: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Website
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="url"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? "Enter your school's Website URL"
                      : "Enter your institute's Website URL"
                  }
                  name="websiteLink"
                  value={expertObrDetails.agencyWebsiteLink}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyWebsiteLink: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  LinkedIn
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="url"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? "Enter your school's LinkedIn page URL"
                      : "Enter your institute's LinkedIn page URL"
                  }
                  name="linkedinLink"
                  value={expertObrDetails.agencyLinkedinLink}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyLinkedinLink: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Facebook
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="url"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? "Enter your school's Facebook page URL"
                      : "Enter your institute's Facebook page URL"
                  }
                  name="facebookLink"
                  value={expertObrDetails.agencyFacebookLink}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyFacebookLink: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Instagram
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="url"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? "Enter your school's Instagram handle URL"
                      : "Enter your institute's Instagram handle URL"
                  }
                  name="instagramLink"
                  value={expertObrDetails.agencyInstagramLink}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyInstagramLink: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
              </div>
              <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Youtube
                </Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  type="url"
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? "Enter your school's YouTube channel URL"
                      : "Enter your institute's YouTube channel URL"
                  }
                  name="youtubeLink"
                  value={expertObrDetails.agencyYoutubeLink}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyYoutubeLink: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-2 col-lg-12  col-sm-12 col-xs-12">
                <Form.Label className={`${classes.formOBRLabel}`}>
                  Description
                  <span className="redAstrisk">{AppConstants.asterisk}</span>
                </Form.Label>
                <Form.Control
                  className={classes.formOBRTextAreaInput}
                  name="agencyDescription"
                  as="textarea"
                  maxLength={2000}
                  placeholder={
                    expertObrDetails.agencyType === AppConstants.schoolText
                      ? 'Briefly describe about your school and its offerings'
                      : 'Briefly describe about your institute and its offerings'
                  }
                  value={expertObrDetails.agencyDescription}
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({
                      ...prevState,
                      agencyDescription: e.target.value,
                    }));
                    setDetailsSaved(false);
                  }}
                />
                {expertObrDetails?.agencyDescription && (
                  <span className={classes.remainingChars}>
                    {getRemainingLengthText(
                      2000,
                      expertObrDetails?.agencyDescription
                    )}
                  </span>
                )}
              </div>
            </div>
            <Row>
              <div className={classes.checkboxContainer}>
                <div className={classes.checkboxText}>
                  {expertObrDetails.agencyType === AppConstants.schoolText
                    ? 'Do you have any School Branches?'
                    : 'Do you have any Skill Institute Centres?'}{' '}
                  <span>
                    <input
                      type="checkbox"
                      checked={isCentrePresent}
                      onChange={toggleCentreDetails}
                    />
                  </span>
                </div>
              </div>
            </Row>
            {isCentrePresent && (
              <div>
                <div className="row">
                  <h1 className={classes.formSectionHeading}>
                    {expertObrDetails.agencyType === AppConstants.schoolText
                      ? 'Please provide school branch details below.'
                      : 'Please provide skill institute centre details below.'}
                  </h1>
                </div>
                {orgCentreForm.map((input, index) => {
                  return (
                    <div key={input.uid} className="row">
                      {index !== zeroValue && <hr />}
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <Form.Label className={`${classes.formOBRLabel}`}>
                          Name
                          <span className="redAstrisk">
                            {AppConstants.asterisk}
                          </span>
                        </Form.Label>
                        <Form.Control
                          className={classes.formOBRInput}
                          type="text"
                          placeholder={
                            expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'Enter name of the branch within your school'
                              : 'Enter name of the centre within your institute'
                          }
                          id="centreName"
                          name={`centreName`}
                          value={input.centreName}
                          onChange={(event) =>
                            handleCentreFormChange(index, event)
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <Form.Label className={`${classes.formOBRLabel}`}>
                          SPOC Email
                          <span className="redAstrisk">
                            {AppConstants.asterisk}
                          </span>
                        </Form.Label>
                        <Form.Control
                          className={classes.formOBRInput}
                          type="email"
                          placeholder={
                            expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'Enter the SPOC email for this branch'
                              : 'Enter the SPOC email for this center'
                          }
                          name="centreSpocEmail"
                          value={input.centreSpocEmail}
                          onChange={(event) =>
                            handleCentreFormChange(index, event)
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <Form.Label className={`${classes.formOBRLabel}`}>
                          SPOC Mobile
                          <span className="redAstrisk">
                            {AppConstants.asterisk}
                          </span>
                        </Form.Label>
                        <Form.Control
                          className={classes.formOBRInput}
                          type="number"
                          placeholder={
                            expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'Enter the SPOC mobile number for this branch'
                              : 'Enter the SPOC mobile number for this centre'
                          }
                          name="centreSpocPhoneNumber"
                          value={input.centreSpocPhoneNumber}
                          onKeyDown={validateMobileNumber}
                          onChange={(event) =>
                            handleCentreFormChange(index, event)
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <Form.Label className={`${classes.formOBRLabel}`}>
                          Country
                          <span className="redAstrisk">
                            {AppConstants.asterisk}
                          </span>
                        </Form.Label>
                        <Typeahead
                          className={`${classes.obrInputontainer} adminCreateObrTypeahead`}
                          name="centreCountryName"
                          id="centreCountryName"
                          placeholder={
                            expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'In which country is this branch located?'
                              : 'In which country is this centre located?'
                          }
                          labelKey="country"
                          onChange={(value) =>
                            handleCentreCountryChange(index, value)
                          }
                          options={countryList}
                          selected={input.centreCountry}
                        />
                      </div>
                      {input.centreCountryName !== null &&
                        input.centreCountryName !== AppConstants.emptyString &&
                        input.centreCountryName !== undefined && (
                          <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                            {input.centreCountryName ===
                              AppConstants.countryIndia && (
                                <Form.Label className={`${classes.formOBRLabel}`}>
                                  Centre State
                                  <span className="redAstrisk">
                                    {AppConstants.asterisk}
                                  </span>
                                </Form.Label>
                              )}
                            {input.centreCountryName ===
                              AppConstants.countryIndia && (
                                <Typeahead
                                  className={`${classes.obrInputontainer} adminCreateObrTypeahead`}
                                  name="centreState"
                                  id="centreState"
                                  placeholder={
                                    expertObrDetails.agencyType ===
                                      AppConstants.schoolText
                                      ? 'In which state is this branch located'
                                      : 'In which state is this centre located'
                                  }
                                  labelKey="name"
                                  onChange={(value) =>
                                    handleCentreStateChange(index, value)
                                  }
                                  options={stateList}
                                  selected={input.centreState}
                                />
                              )}
                            {input.centreCountryName !==
                              AppConstants.countryIndia && (
                                <Form.Label className={`${classes.formOBRLabel}`}>
                                  Centre State
                                </Form.Label>
                              )}
                            {input.centreCountryName !==
                              AppConstants.countryIndia && (
                                <Form.Control
                                  className={classes.formOBRInput}
                                  type="text"
                                  placeholder={
                                    expertObrDetails.agencyType ===
                                      AppConstants.schoolText
                                      ? 'In which state is this branch located'
                                      : 'In which state is this centre located'
                                  }
                                  name="centreStateName"
                                  value={input.centreStateName}
                                  onChange={(event) =>
                                    handleCentreFormChange(index, event)
                                  }
                                />
                              )}
                          </div>
                        )}
                      {input.centreCountryName !== null &&
                        input.centreCountryName !== AppConstants.emptyString &&
                        input.centreCountryName !== undefined && (
                          <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                            {input.centreCountryName ===
                              AppConstants.countryIndia && (
                                <Form.Label className={`${classes.formOBRLabel}`}>
                                  Centre City
                                  <span className="redAstrisk">
                                    {AppConstants.asterisk}
                                  </span>
                                </Form.Label>
                              )}
                            {input.centreCountryName ===
                              AppConstants.countryIndia && (
                                <Typeahead
                                  className={`${classes.obrInputontainer} adminCreateObrTypeahead`}
                                  name="centreCity"
                                  id="centreCity"
                                  placeholder={
                                    expertObrDetails.agencyType ===
                                      AppConstants.schoolText
                                      ? 'In which city is this branch located?'
                                      : 'In which city is this centre located?'
                                  }
                                  labelKey="name"
                                  onChange={(value) =>
                                    handleCentreCityChange(index, value)
                                  }
                                  options={input.cityList}
                                  selected={input.centreCity}
                                />
                              )}
                            {input.centreCountryName !==
                              AppConstants.countryIndia && (
                                <Form.Label className={`${classes.formOBRLabel}`}>
                                  Centre City
                                  <span className="redAstrisk">
                                    {AppConstants.asterisk}
                                  </span>
                                </Form.Label>
                              )}
                            {input.centreCountryName !==
                              AppConstants.countryIndia && (
                                <Form.Control
                                  className={classes.formOBRInput}
                                  type="text"
                                  placeholder={
                                    expertObrDetails.agencyType ===
                                      AppConstants.schoolText
                                      ? 'In which city is this branch located?'
                                      : 'In which city is this centre located?'
                                  }
                                  name="centreCityName"
                                  value={input.centreCityName}
                                  onChange={(event) =>
                                    handleCentreFormChange(index, event)
                                  }
                                />
                              )}
                          </div>
                        )}
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <Form.Label className={`${classes.formOBRLabel}`}>
                          Address
                          <span className="redAstrisk">
                            {AppConstants.asterisk}
                          </span>
                        </Form.Label>
                        <Form.Control
                          className={classes.formOBRInput}
                          type="text"
                          placeholder={
                            expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'Enter the complete address of this branch'
                              : 'Enter the complete address of this centre'
                          }
                          name="centreAddress"
                          value={input.centreAddress}
                          onChange={(event) =>
                            handleCentreFormChange(index, event)
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <Form.Label className={`${classes.formOBRLabel}`}>
                          Geolocation
                          <span className="redAstrisk">
                            {AppConstants.asterisk}
                          </span>
                        </Form.Label>
                        <Form.Control
                          className={classes.formOBRInput}
                          type="url"
                          placeholder={
                            expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'Enter this branch Google Maps location URL'
                              : 'Enter this centre Google Maps location URL'
                          }
                          name="centreGeoLocation"
                          value={input.centreGeoLocation}
                          onChange={(event) =>
                            handleCentreFormChange(index, event)
                          }
                        />
                      </div>
                      <div>
                        {orgCentreForm.length > 1 && (
                          <Button
                            className={classes.addCentreFormButton}
                            onClick={() => removeFields(index)}
                          >
                            Remove
                          </Button>
                        )}
                        {index === orgCentreForm.length - 1 && (
                          <Button
                            className={classes.addCentreFormButton}
                            onClick={addFields}
                          >
                            {expertObrDetails.agencyType ===
                              AppConstants.schoolText
                              ? 'Add School'
                              : 'Add Center'}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="row">
              <h1 className={classes.formSectionHeading}>
                {expertObrDetails.agencyType === AppConstants.schoolText
                  ? "To verify you as an school on Qrencia, we require your school's information. Rest assured, your data will be handled with utmost confidentiality."
                  : "To verify you as an agency on Qrencia, we require your institute's information. Rest assured, your data will be handled with utmost confidentiality."}
              </h1>
              <div
                className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}
              >
                <div
                  className={`${classes.fileOBRUpload}  ${classes.fileOBRUploadInput}`}
                >
                  <FileUploadInput
                    id={AppConstants.addressProofText}
                    name={AppConstants.addressProofText}
                    mandatory={true}
                    label="Address Proof"
                    handleFileChange={(e) => {
                      setAddressProof(e.target.files[AppConstants.zeroIndex]);
                      setDetailsSaved(false);
                      setAddressProofPath(AppConstants.emptyString);
                    }}
                    infoMessage={AppConstants.agencyAddressProofIcon}
                    inputFile={addressProof}
                    obrClasses={true}
                    defaultFile={
                      addressProofPath &&
                      getDataTransfer(
                        extractFileName(addressProofPath) || 'Address_Proof'
                      ).files[0]
                    }
                  />
                  {(addressProof?.size > zeroValue || addressProofPath) && (
                    <p
                      onClick={() => {
                        removeFile(AppConstants.addressProofText);
                        setAddressProofPath(AppConstants.emptyString);
                      }}
                      className="removeFileText"
                    >
                      Remove file
                    </p>
                  )}
                </div>
                {expertObrDetails.addressProofAbsolutePath && (
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile(
                        expertObrDetails.addressProofAbsolutePath,
                        AppConstants.addressProofText
                      );
                      setAddressProofSpinner(true);
                    }}
                    data-tooltip-id="downloadAddressProof"
                    className={classes.removeBtn}
                  >
                    {addressProofSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      <AiOutlineDownload />
                    )}
                  </button>
                )}
                <Tooltip
                  className={classes.tooltipMessageDownload}
                  id="downloadAddressProof"
                >
                  <span className={classes.tooltipMessage}>
                    Download Address Proof
                  </span>
                </Tooltip>
              </div>
              {expertObrDetails.comment && (
                <div className={classes.feedbackComponent}>
                  <div className={classes.adminFeedbackHeading}>
                    Admin Feedback:
                  </div>
                  <div className={classes.feedbackComment}>
                    {parse(expertObrDetails.comment)}
                  </div>
                </div>
              )}
              {obrStatus !== status.statusRejected && (
                <div className={classes.switchContainer}>
                  <div className={classes.switchText}>
                    <input
                      type="checkbox"
                      onChange={(e) => changeTncCheck(e)}
                    />
                    <p className={classes.tncText}>
                      By selecting this, you agree to Qrencia{' '}
                      <NavLink
                        target="_blank"
                        className={classes.tncTextHyper}
                        to={AppConstants.termsAndConditions}
                      >
                        Terms & Conditions
                      </NavLink>
                      &nbsp; and &nbsp;
                      <NavLink
                        target="_blank"
                        className={classes.tncTextHyper}
                        to={AppConstants.privacyPolicy}
                      >
                        Privacy Policy
                      </NavLink>
                    </p>
                  </div>
                </div>
              )}
              <div className={classes.buttonSection}>
                <Button
                  className={classes.saveButton}
                  disabled={
                    saveButtonSpinner ||
                    reviewButtonSpinner ||
                    obrStatus === status.statusRejected
                  }
                  onClick={saveObrDetails}
                >
                  {saveButtonSpinner ? (
                    <BiLoaderAlt className="spinner" />
                  ) : (
                    'Save as draft'
                  )}
                </Button>
                {
                  <Button
                    className={classes.reviewButton}
                    disabled={
                      !tncCheck ||
                      saveButtonSpinner ||
                      obrStatus === status.statusRejected ||
                      reviewButtonSpinner
                    }
                    onClick={sendObrDetailsForReview}
                  >
                    {reviewButtonSpinner ? (
                      <BiLoaderAlt className="spinner" />
                    ) : (
                      'Submit for review'
                    )}
                  </Button>
                }
              </div>
            </div>
          </div>
        )}
      {obrLinkValid && obrStatus === status.statusInReview && (
        <div className={classes.statusContainer}>
          <p className="noDataAvailableError">
            {ErrorMessages.obrRequestInReviewMessage}
          </p>
        </div>
      )}
      {obrLinkValid && obrStatus === status.statusAccepted && (
        <div className={classes.statusContainer}>
          <p className="noDataAvailableError">
            Your Onboarding is approved, kindly{' '}
            <NavLink
              target="_self"
              className={classes.tncTextHyper}
              to={resetPasswordLink}
            >
              reset your password
            </NavLink>{' '}
            to login to Qrencia
          </p>
        </div>
      )}
      {obrLinkValid && obrStatus === status.statusRejected && (
        <div className={classes.statusContainer}>
          {expertObrDetails.comment === null && (
            <p className="noDataAvailableError">
              Onboarding Application Rejected
            </p>
          )}
          {expertObrDetails.comment && (
            <p className="obrRejectedError">
              <div className={classes.boldText}>
                Onboarding Application Rejected
              </div>
              <br></br>
              Dear {expertObrDetails.agencyHeadFirstName},<br></br>
              <br></br>
              We regret to inform you that your onboarding application as an
              agency has been rejected by our admin team.<br></br>
              <br></br>
              <strong className={classes.boldText}>Reason:</strong>
              <div className={classes.feedbackText}>
                {parse(expertObrDetails.comment)}
              </div>{' '}
              <br></br>
              If you have any queries or need further clarification, please
              write to us at{' '}
              <a href="mailto:support@qrencia.com">support@qrencia.com</a>, and
              we will respond within 2 hours.<br></br>
              <br></br>
              Thank you for expressing your interest.
            </p>
          )}
        </div>
      )}
      {showPageLoader ? (
        <div className={classes.statusContainer}>
          <p className="noDataAvailableError">
            <BiLoaderAlt className={`spinner ${classes.pageLoader}`} />
          </p>
        </div>
      ) : (
        (!obrLinkValid ||
          obrStatus === status.statusDeclined ||
          obrStatus === AppConstants.emptyString) &&
        !showPageLoader && (
          <div className={classes.statusContainer}>
            <p className="noDataAvailableError">
              {ErrorMessages.obrLinkExpiredMessage}
            </p>
          </div>
        )
      )}
      {showCreatorGallery && (
        <ImageGalleryModal
          creatorGalleryContent={selectedMediaGallery}
          showDeleteIcon={true}
          showDownloadIcon={true}
          hanldeGalleryClose={hanldeGalleryClose}
          removeSelectedMedia={removeSelectedMedia}
        />
      )}
      <Modal
        show={showModal}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className={modalClass.modalBody}>
          {modalTypeError === AppConstants.falseText && (
            <div className={modalClass.modalSuccessIcon}>
              <AiOutlineCheckCircle />
            </div>
          )}
          {modalTypeError === AppConstants.trueText && (
            <div className={modalClass.modalErrorIcon}>
              <AiOutlineExclamationCircle />
            </div>
          )}
          <div className={modalClass.modalAlertText}>{modalMessage}</div>
        </Modal.Body>
        <Modal.Footer className={modalClass.modalFooter}>
          <Button className={modalClass.modalCloseButton} onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
