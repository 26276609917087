import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import classes from './LearnerPassButton.module.css';
import LearnerPassPopup from './LearnerPassPopup';
import { FaCrown } from 'react-icons/fa6';
import { useLearnerPassPaymentPopupStore } from '../../../stores';

const LearnerPassButton = ({
  numberOfDaysRemaining = null,
  onPaymentTrigger,
  buttonTitle = 'Buy Learner Pass',
  showNumberOfDaysRemaining = false,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const { showLearnerPassPaymentPopup, toggleLearnerPassPaymentPopup } =
    useLearnerPassPaymentPopupStore();
  // const title = buttonTitle || 'Buy Learner Pass';

  const handlePopupOpen = () => {
    // setShowPopup(true);
    toggleLearnerPassPaymentPopup();
  };

  const handlePopupClose = () => {
    // setShowPopup(false);
    toggleLearnerPassPaymentPopup();
  };

  const renderDaysRemainingText = () => {
    if (numberOfDaysRemaining === 0) {
      return 'Expiring Today';
    } else if (numberOfDaysRemaining === 1) {
      return 'Expires Tomorrow';
    } else if (numberOfDaysRemaining > 1) {
      return `Expires in ${numberOfDaysRemaining} days`;
    } else {
      return 'Expired';
    }
  };

  return (
    <div className={classes.learnerPassContainer}>
      {showNumberOfDaysRemaining && <div
        className={classes.statusWrapper}
        style={{ backgroundColor: '#E77A7B', color: '#ffffff' }}
      >
        <div className={classes.statusChipText}>{renderDaysRemainingText()}</div>
      </div>}
      <Button className={classes.learnerPassButton} onClick={handlePopupOpen}>
        {buttonTitle}&nbsp;
        <FaCrown className={classes.buttonIcon} />
      </Button>

      {/* {showPopup && (
        <LearnerPassPopup
          show={showPopup}
          onHide={handlePopupClose}
          handleButtonClick={onPaymentTrigger}
        />
      )} */}

      {showLearnerPassPaymentPopup && (
        <LearnerPassPopup
          show={showLearnerPassPaymentPopup}
          onHide={handlePopupClose}
          handleButtonClick={onPaymentTrigger}
        />
      )}
    </div>
  );
};

export default LearnerPassButton;
