import React from 'react';
import { LEARNER_PASS_DASHBOARD } from '../../../constants/AppConstants';
import classes from '../LearnerPass/LearnerPassStatus.module.css';


const LearnerPassStatus = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  return (
    <img
      src={`${mediaContent}${LEARNER_PASS_DASHBOARD.learnerPassIndicatorIconPath}`}
      alt="icon"
      className={classes.buttonIcon}
    />
  );
};

export default LearnerPassStatus;
