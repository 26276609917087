import { React, useEffect, useState } from 'react';
import classes from './AchievementsCards.module.css';
import { GoTrophy } from 'react-icons/go';
import moment from 'moment-timezone';
import { AppConstants, LEARNER_PROFILE } from '../../../constants/AppConstants';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';
import { PiCertificate } from 'react-icons/pi';
import { FileOperationsUtil } from '../../../utils/FileOperationsUtil';
import { BiLoaderAlt } from 'react-icons/bi';
import { Button, Form } from 'react-bootstrap';
import { FaTrash, FaEye, FaEyeSlash, FaEdit } from 'react-icons/fa';
import useCardStore from '../../../stores/achievementCardStore';
import { FcApproval } from 'react-icons/fc';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const AchievementCards = (props) => {
  const [timeZone, setTimeZone] = useState(
    DateTimeUtil.getPreferredTimeZoneForUser()
  );
  const [certificateSpinner, setCertificateSpinner] = useState(
    new Array(props.achievements?.length).fill(false)
  );

  const [viewMode, setViewMode] = useState({});

  const visibilityData = useCardStore((state) => state.visibilityData);
  const setVisibilityData = useCardStore((state) => state.setVisibilityData);

  const [isEditing, setIsEditing] = useState(false);
  const [visibilityMap, setVisibilityMap] = useState({});
  const [changedVisibility, setChangedVisibility] = useState({});

  const setPostPayload = useCardStore((state) => state.setPostPayload);

  useEffect(() => {
    if (props.achievements.length > 0) {
      const visibilityMap = {};
      props.achievements.forEach((achievement) => {
        if (achievement.achievementType === 'Self Declared') {
          visibilityMap[achievement.learningActivityId] =
            achievement.isActiveFlag === 'Y';
        }
      });
      setVisibilityMap(visibilityMap);
    }
  }, [props.updateAchievements, props.achievements]);

  const toggleView = (id) => {
    setVisibilityMap((prev) => {
      const newVisibility = !prev[id];
      setChangedVisibility((prevChanges) => {
        const updatedChanges = { ...prevChanges, [id]: newVisibility };

        // 🔥 Ensure updated data is immediately passed
        props.onPostPayloadUpdate(updatedChanges);

        return updatedChanges;
      });

      return { ...prev, [id]: newVisibility };
    });
  };

  const handleVisibilityChange = async () => {
    const updatedVisibilityArray = Object.keys(changedVisibility).map((id) => ({
      id: id,
      activeFlag: changedVisibility[id] ? 'Y' : 'N', // Convert boolean to 'Y' or 'N'
    }));

    if (updatedVisibilityArray.length === 0) {
      setIsEditing(false);
      return; // No need to call API if nothing changed
    }

    const achievementTogglePostPayload = {
      activityCategory: 'Achievements',
      activityList: updatedVisibilityArray,
    };
  };

  useEffect(() => {
    handleVisibilityChange();
  }, [props.updateAchievements]);

  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const rankOne = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.goldMedalImagePath}`;
  const rankTwo = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.silverMedalImagePath}`;
  const rankThree = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.bronzeMedalImagePath}`;
  const otherRank = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.landingPageS3Path}/${AppConstants.nonRankMedalImagePath}`;

  const approvedImagePath = `${mediaContent}${LEARNER_PROFILE.approvedSealForQrenciaHostedLearningActivities}`;

  const hexagonSectionBackground = {
    competition: classes.competitionBgHexagon,
    course: classes.courseBgHexagon,
    workshop: classes.workshopBgHexagon,
  };

  async function downloadSelfDeclaredCertificate(achievement, index) {
    setCertificateSpinner((certificateSpinner) =>
      certificateSpinner.map((state, idx) => (idx === index ? true : state))
    );

    try {
      const fileUrl = achievement.selfDeclaredAchievementCertificatePath;

      if (!fileUrl) {
        throw new Error('Certificate URL is missing.');
      }

      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Failed to download certificate.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger download
      const fileName = fileUrl.split('/').pop().split('?')[0]; // Handles query params in URL

      // Create a link and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Uses original filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading self declared certificate:', error);
    } finally {
      setCertificateSpinner((certificateSpinner) =>
        certificateSpinner.map((state, idx) => (idx === index ? false : state))
      );
    }
  }

  async function downloadCertificate(achievement, index) {
    setCertificateSpinner((certificateSpinner) =>
      certificateSpinner.map((state, idx) => (idx === index ? true : state))
    );
    let url;
    let response;
    const name = `${achievement.learningActivityName}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
    const fileName = name.replace(/\s+/g, AppConstants.hyphen);
    if (achievement.learningActivityType === 'Course') {
      const url = `${AppConstants.downloadCourseCertificateForStudentAPI}${achievement.learningActivityUniqueId}${AppConstants.studentQrenciaIdParam}${props.learnerQrenciaId}`;
      const name = `${achievement.learningActivityName}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
      const fileName = name.replace(/\s+/g, AppConstants.hyphen);
      response = await FileOperationsUtil.downloadFileUnauthorized(
        url,
        fileName,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
    } else if (achievement.learningActivityType === 'Competition') {
      if (achievement.teamEnrolmentEnabledFlag === AppConstants.yText) {
        url = AppConstants.downloadParticipantTeamCompetitionCertificateAPI;
        let data = {
          uniqueId: achievement.learningActivityUniqueId,
          skillName: achievement.skillName,
          markingType: achievement.markingType,
          entityType: 'Participant',
          creatorEmail: achievement.creatorEmail,
          participantQrenciaId: props.learnerQrenciaId,
        };
        response =
          await FileOperationsUtil.downloadFileUnauthorizedUsingPostRequest(
            url,
            fileName,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
          );
      } else {
        url = `${AppConstants.downloadCertificateAPI}${achievement.learningActivityUniqueId}&studentQrenciaId=${props.learnerQrenciaId}&skillName=${achievement.skillName}`;
        response = await FileOperationsUtil.downloadFileUnauthorized(
          url,
          fileName,
          AppConstants.emptyString,
          AppConstants.emptyString
        );
      }
    } else if (achievement.learningActivityType === 'Workshop') {
      const url = `${AppConstants.downloadWorkshopCertificateForStudentAPI}${achievement.learningActivityUniqueId}${AppConstants.studentQrenciaIdParam}${props.learnerQrenciaId}`;
      const name = `${achievement.learningActivityName}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
      const fileName = name.replace(/\s+/g, AppConstants.hyphen);
      response = await FileOperationsUtil.downloadFileUnauthorized(
        url,
        fileName,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
    }
    if (response !== undefined) {
      if (response.ok && response.status === 200) {
        setCertificateSpinner((certificateSpinner) =>
          certificateSpinner.map((state, idx) =>
            idx === index ? false : state
          )
        );
      } else {
        setCertificateSpinner((certificateSpinner) =>
          certificateSpinner.map((state, idx) =>
            idx === index ? false : state
          )
        );
      }
    } else {
      setCertificateSpinner((certificateSpinner) =>
        certificateSpinner.map((state, idx) => (idx === index ? false : state))
      );
    }
  }
  const getOrdinalSuffix = (rank) => {
    if (rank % 10 === 1 && rank % 100 !== 11) return 'st';
    if (rank % 10 === 2 && rank % 100 !== 12) return 'nd';
    if (rank % 10 === 3 && rank % 100 !== 13) return 'rd';
    return 'th';
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div className={classes.cardsSection}>
        {props.achievements
          .filter(
            (achievement) =>
              props.editAllowed ||
              achievement.isActiveFlag === 'Y' ||
              achievement.achievementType === null
          )
          .map((achievement, index) => (
            <div key={index} className={classes.cardContainer}>
              {achievement.learnerMedal == null &&
                achievement.learnerRank == null && (
                  <div className={classes.hexagonSection}>
                    <div
                      className={` ${classes.hexagonContainer} ${hexagonSectionBackground[achievement.learningActivityType.toLowerCase()]}`}
                    >
                      <div className={classes.hexagonContent}>
                        <GoTrophy />
                      </div>
                    </div>
                  </div>
                )}

              {(achievement.learnerMedal != null ||
                achievement.learnerRank != null) &&
                achievement.learningActivityType == 'Competition' && (
                  <div className={classes.hexagonSection}>
                    <div className={` ${classes.circleContainer}`}>
                      <div className={classes.hexagonContent}>
                        <img
                          className={classes.rankIcons}
                          src={
                            achievement.learnerRank === 1 ||
                              achievement.learnerMedal === 'Gold'
                              ? rankOne
                              : achievement.learnerRank === 2 ||
                                achievement.learnerMedal === 'Silver'
                                ? rankTwo
                                : achievement.learnerRank === 3 ||
                                  achievement.learnerMedal === 'Bronze'
                                  ? rankThree
                                  : otherRank
                          }
                          alt="rank medals"
                        ></img>
                      </div>
                    </div>
                  </div>
                )}

              <div className={classes.contentSection}>
                <div className={classes.verifiedSection}>
                  {achievement.learnerMedal == null &&
                    achievement.learnerRank == null && (
                      <p className={classes.cardHeading}>
                        {achievement.learningActivityType} Completion
                      </p>
                    )}

                  {(achievement.learnerMedal != null ||
                    achievement.learnerRank != null) &&
                    achievement.learningActivityType === 'Competition' && (
                      <p className={classes.cardHeading}>
                        {achievement.learnerRank === 1 ||
                          achievement.learnerMedal === 'Gold'
                          ? `${achievement.learnerRank ? `Secured ${achievement.learnerRank}${getOrdinalSuffix(achievement.learnerRank)} Rank` : 'Won Gold Medal'}`
                          : achievement.learnerRank === 2 ||
                            achievement.learnerMedal === 'Silver'
                            ? `${achievement.learnerRank ? `Secured ${achievement.learnerRank}${getOrdinalSuffix(achievement.learnerRank)} Rank` : 'Won Silver Medal'}`
                            : achievement.learnerRank === 3 ||
                              achievement.learnerMedal === 'Bronze'
                              ? `${achievement.learnerRank ? `Secured ${achievement.learnerRank}${getOrdinalSuffix(achievement.learnerRank)} Rank` : 'Won Bronze Medal'}`
                              : `Participated in ${achievement.learningActivityType}`}
                      </p>
                    )}
                  {achievement.achievementType !== 'Self Declared' && (
                    <div className={classes.selfDeclaredContainer}>
                      {/* <img
                        className={classes.approvedSeal}
                        src={approvedImagePath}
                        alt="Seal of Approval from Qrencia"
                        title="This achievement has been approved by Qrencia"
                      ></img> */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="verified-tooltip">
                            Qrencia Verified
                          </Tooltip>
                        }
                      >
                        <span>
                          <FcApproval className={classes.approvedSeal} />
                        </span>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
                <p className={classes.cardSubHeading}>
                  {achievement.learningActivityName}
                </p>
                <div className={classes.shareContainer}>
                  <div className={classes.cardDate}>
                    {achievement.courseType === 'Flexible'
                      ? ' '
                      : (() => {
                        const startDateStr =
                          achievement.learningActivityStartDate?.replace(
                            ' ',
                            'T'
                          ) + 'Z';
                        const endDateStr =
                          achievement.learningActivityEndDate?.replace(
                            ' ',
                            'T'
                          ) + 'Z';
                        const startDate = moment(startDateStr).tz(timeZone);
                        const endDate = moment(endDateStr).tz(timeZone);
                        const isSameDate = startDate.isSame(endDate, 'day');

                        return isSameDate ? (
                          <Moment
                            format={
                              AppConstants.competitionCardsDateMonthFormat
                            }
                            tz={timeZone}
                          >
                            {startDateStr}
                          </Moment>
                        ) : (
                          <>
                            <Moment
                              format={
                                AppConstants.competitionCardsDateMonthFormat
                              }
                              tz={timeZone}
                            >
                              {startDateStr}
                            </Moment>{' '}
                            -{' '}
                            <Moment
                              format={
                                AppConstants.competitionCardsDateMonthFormat
                              }
                              tz={timeZone}
                            >
                              {endDateStr}
                            </Moment>
                          </>
                        );
                      })()}
                  </div>

                  <div className={classes.certificateSection}>
                    {achievement.templateId != 0 &&
                      (certificateSpinner[index] ? (
                        <BiLoaderAlt className="spinner" />
                      ) : (achievement.achievementType === 'Self Declared' &&
                        achievement.selfDeclaredAchievementCertificatePath) ||
                        achievement.achievementType === null ? (
                        <PiCertificate
                          onClick={(e) => {
                            stopPropagation(e);
                            if (
                              achievement.achievementType === 'Self Declared'
                            ) {
                              downloadSelfDeclaredCertificate(
                                achievement,
                                index
                              );
                            } else {
                              downloadCertificate(achievement, index);
                            }
                          }}
                        />
                      ) : null)}
                  </div>
                  <div>
                    {props.isEditing &&
                      achievement.achievementType === 'Self Declared' && (
                        <>
                          <div className={classes.achievementEdit}>
                            <FaEdit
                              className="cursor-pointer text-gray-500 hover:text-gray-700"
                              size={20}
                              onClick={() => {
                                props.showModal(achievement);
                              }}
                            />
                            <Form.Check
                              key={achievement.learningActivityId}
                              type="switch"
                              id={`visibility-switch-${index}`}
                              label={
                                <span className={classes.editLabel}>
                                  Show on profile?
                                </span>
                              }
                              checked={
                                visibilityMap[achievement.learningActivityId] ||
                                false
                              }
                              className={classes.editSwitch}
                              onChange={() => {
                                toggleView(achievement.learningActivityId);
                              }}
                            />
                            {/* <Button
                          variant="outline-secondary"
                          onClick={() => props.showModal(achievement)}
                        >
                          Edit
                        </Button> */}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
