import React, { useEffect, useState } from 'react';
import classes from './LearnerAchievements.module.css';
import { AchievementCards } from './AchievementsCards';
import RecentActivity from '../RecentActivity/RecentActivity';
import { AppConstants, LEARNER_PROFILE } from '../../../constants/AppConstants';
import { Button } from 'react-bootstrap';
import AddAchievementModal from './AddAchievementModal';
import useCardStore from '../../../stores/achievementCardStore';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { DataService } from '../../../services/DataService';
import { FaEdit, FaSave } from 'react-icons/fa';
import { SuccessMessage } from '../../../constants/SuccessMessage';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { ErrorSuccessAlertMessage } from '../../errorSuccessMessage/ErrorSuccessAlertMessage';
import AddRecentActivityModal from '../RecentActivity/AddRecentActivityModal';

export const LearnerAchievements = (props) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const achievementAd = `${mediaContent}${AppConstants.advertismentPath}/${AppConstants.advertismentLearnerProfilePath}`;
  const recentActivityaAd = `${mediaContent}${AppConstants.advertismentPath}/${AppConstants.recentActivitiesSectionAdImagePath}`;
  const [achievements, setAchievements] = useState(props.learnerAchievements?.achievements, []);
  const [recentActivities, setRecentActivities] = useState(props.learnerActivityCards?.recentActivities, []);
  const [editAllowed, setEditAllowed] = useState(props.editAllowed);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRecentActivityModal, setShowRecentActivityModal] = useState(false);
  const [isEditingRecentActivity, setIsEditingRecentActivity] = useState(false);
  const [achievementSaveStatus, setAchievementSaveStatus] = useState(null);
  const [recentActivitySaveStatus, setRecentActivitySaveStatus] = useState(null);
  const [alertVariant, setAlertVariant] = useState('');
  const [showAlertMessage, setShowAlertMessage] = useState('');
  const [showActivityAlertMessage, setShowActivityAlertMessage] = useState('');

  const [postPayload, setPostPayload] = useState({});

  useEffect(() => {
    setAchievements(props.learnerAchievements?.achievements);
    setRecentActivities(props.learnerActivityCards?.recentActivities);
    setEditAllowed(props.editAllowed);
  }, [props.learnerAchievements.achievements, props.learnerActivityCards.recentActivities, props.editAllowed]);

  const setLearnerProfileAchievementCardsData = useCardStore(
    (state) => state.setLearnerProfileAchievementCardsData
  );

  const setLearnerProfileRecentActivityCardsData = useCardStore(
    (state) => state.setLearnerProfileRecentActivityCardsData
  );

  const handlePostPayloadUpdate = (updatedChanges) => {
    setPostPayload(updatedChanges);
  };

  const handleAchievementSuccess = (isUpdate) => {
    setAchievementSaveStatus('success');
  };

  const handleRecentActivitySuccess = (isUpdate) => {
    setRecentActivitySaveStatus('success');
  };

  useEffect(() => {
    if (achievementSaveStatus === 'success') {
      setAlertVariant(AppConstants.alertVarient[0]);
      setShowAlertMessage(SuccessMessage.updateAchievementSuccess);
      setTimeout(() => {
        setShowAlertMessage(AppConstants.emptyString);
        setAchievementSaveStatus(null);
      }, AppConstants.messageDisappearTime);
    } else if (achievementSaveStatus === 'error') {
      setAlertVariant(AppConstants.alertVarient[1]);
      setShowAlertMessage(ErrorMessages.saveAchievementError);
      setTimeout(() => {
        setShowAlertMessage(AppConstants.emptyString);
        setAchievementSaveStatus(null);
      }, AppConstants.messageDisappearTime);
    }
  }, [achievementSaveStatus]);

  useEffect(() => {
    if (recentActivitySaveStatus === 'success') {
      setAlertVariant(AppConstants.alertVarient[0]);
      setShowActivityAlertMessage(SuccessMessage.updateRecentActivitySuccess);
      setTimeout(() => {
        setShowActivityAlertMessage(AppConstants.emptyString);
        setRecentActivitySaveStatus(null);
      }, AppConstants.messageDisappearTime);
    } else if (recentActivitySaveStatus === 'error') {
      setAlertVariant(AppConstants.alertVarient[1]);
      setShowActivityAlertMessage(ErrorMessages.saveRecentActivityError);
      setTimeout(() => {
        setShowActivityAlertMessage(AppConstants.emptyString);
        setRecentActivitySaveStatus(null);
      }, AppConstants.messageDisappearTime);
    }
  }, [recentActivitySaveStatus]);

  const saveChanges = async () => {

    const updatedVisibilityArray = Object.keys(postPayload).map((id) => ({
      id: id,
      activeFlag: postPayload[id] ? 'Y' : 'N', // Convert boolean to 'Y' or 'N'
    }));

    const postAchievementTogglePayload = {
      activityCategory: 'Achievements',
      activityList: updatedVisibilityArray,
    };

    try {
      const response = await AuthorizedDataService.post(
        LEARNER_PROFILE.updateActiveFlagForLearnerDeclaredActivities,
        postAchievementTogglePayload,
        '',
        '',
        ''
      );

      if (response) {
        // triggerRefresh();
        let url = `${AppConstants.fetchLearnerAchievements}/${props.learnerSlug}`;
        const freshData = await DataService.get(url, '', '');

        if (freshData !== undefined) {
          props.updateAchievements(freshData);
          setLearnerProfileAchievementCardsData(freshData);
        }
      }
    } catch (error) {
      console.error('Error saving achievements', error);
    }
  };

  const saveRecentActivityChanges = async () => {
    const updatedActivityVisibilityArray = Object.keys(postPayload).map((id) => ({
      id: id,
      activeFlag: postPayload[id] ? 'Y' : 'N', // Convert boolean to 'Y' or 'N'
    }));

    const postActivityTogglePayload = {
      activityCategory: 'Recent Activities',
      activityList: updatedActivityVisibilityArray,
    };

    try {
      const response = await AuthorizedDataService.post(
        LEARNER_PROFILE.updateActiveFlagForLearnerDeclaredActivities,
        postActivityTogglePayload,
        '',
        '',
        ''
      );

      if (response) {
        let url = `${AppConstants.fetchLearnerAchievements}/${props.learnerSlug}`;
        const freshData = await DataService.get(url, '', '');

        if (freshData !== undefined) {
          props.updateAchievements(freshData);
          setLearnerProfileRecentActivityCardsData(freshData);
        }
      }
    } catch (error) {
      console.error('Error saving recent activities', error);
    }
  };

  return (
    <>
      {editAllowed && <div className={classes.achievementsSection}>
        {recentActivities?.length > 0 ? (
          <div className={classes.recentActivitiesContainer}>
            <div className="d-flex justify-content-between align-items-center m-2">
              <h2 className={classes.containerHeading}>Recent Activities</h2>
              {editAllowed && (
                <div className="d-flex gap-2 p-2">
                  <Button
                    className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                    onClick={() => {
                      setShowRecentActivityModal(true);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className={` ${classes.achievementBtn} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                    onClick={() => {
                      if (isEditingRecentActivity) {
                        saveRecentActivityChanges();
                      }
                      setIsEditingRecentActivity(!isEditingRecentActivity);
                    }}
                  >
                    {isEditingRecentActivity ? <FaSave /> : <FaEdit />}
                    {isEditingRecentActivity ? 'Save' : 'Edit'}
                  </Button>
                </div>
              )}
            </div>
            {showActivityAlertMessage && (
              <ErrorSuccessAlertMessage
                message={showActivityAlertMessage}
                varient={alertVariant}
              />
            )}
            <RecentActivity
              recentActivities={recentActivities}
              learnerQrenciaId={props.learnerQrenciaId}
              isEditingRecentActivity={isEditingRecentActivity}
              onPostPayloadUpdate={handlePostPayloadUpdate}
              editAllowed={editAllowed}
              showRecentActivityModal={(activity) =>
                setShowRecentActivityModal({ show: true, activity })
              }
            />

            <AddRecentActivityModal
              show={showRecentActivityModal}
              onHide={() => setShowRecentActivityModal(false)}
              prepopulatedActivities={showRecentActivityModal.activity}
              onSuccess={handleRecentActivitySuccess}
              onError={() => setRecentActivitySaveStatus('error')}
            />
          </div>
        ) : (
          <div className={classes.recentActivitiesContainer} >
            <div className="d-flex justify-content-between align-items-center m-2">
              <h2 className={classes.containerHeading}>Recent Activities</h2>
              {editAllowed && (
                <div className="d-flex gap-2 p-2">
                  <Button
                    className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                    onClick={() => {
                      setShowRecentActivityModal(true);
                    }}
                  >
                    Add
                  </Button>
                </div>)}
            </div>
            <AddRecentActivityModal
              show={showRecentActivityModal}
              onHide={() => setShowRecentActivityModal(false)}
              prepopulatedActivities={showRecentActivityModal.activity}
              onSuccess={handleRecentActivitySuccess}
              onError={() => setRecentActivitySaveStatus('error')}
            />
            <p className="noDataAvailableError">
              Some exciting learning activities are being offered by leading
              experts in your favourite skill. Enrol now!
            </p>
          </div>
        )}

        {achievements?.length > 0 ? (
          <div className={classes.achievementsContainer}>
            <div className="d-flex justify-content-between align-items-center m-2">
              <h2 className={classes.containerHeading}>Achievements</h2>
              {editAllowed && (
                <div className="d-flex gap-2 p-2">
                  <Button
                    className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    className={` ${classes.achievementBtn} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                    onClick={() => {
                      if (isEditing) {
                        saveChanges();
                      }
                      setIsEditing(!isEditing);
                    }}
                  >
                    {isEditing ? <FaSave /> : <FaEdit />}
                    {isEditing ? 'Save' : 'Edit'}
                  </Button>
                </div>
              )}
            </div>

            {showAlertMessage && (
              <ErrorSuccessAlertMessage
                message={showAlertMessage}
                varient={alertVariant}
              />
            )}

            <AchievementCards
              achievements={achievements}
              learnerQrenciaId={props.learnerQrenciaId}
              isEditing={isEditing}
              onPostPayloadUpdate={handlePostPayloadUpdate}
              editAllowed={editAllowed}
              showModal={(achievement) =>
                setShowModal({ show: true, achievement })
              }
            />
            <AddAchievementModal
              show={showModal}
              onHide={() => setShowModal(false)}
              prepopulatedAchievements={showModal.achievement}
              onSuccess={handleAchievementSuccess}
              onError={() => setAchievementSaveStatus('error')}
            />
          </div>
        ) : (
          <div className={classes.achievementsContainer}>
            <div className="d-flex justify-content-between align-items-center m-2">
              <h2 className={classes.containerHeading}>Achievements</h2>
              {editAllowed && (
                <div className="d-flex gap-2 p-2">
                  {/* Add Button */}
                  <Button
                    className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Add
                  </Button>
                </div>
              )}
            </div>
            <AddAchievementModal
              show={showModal}
              onHide={() => setShowModal(false)}
              prepopulatedAchievements={showModal.achievement}
            />
            <p className="noDataAvailableError">
              {' '}
              Everyone recognizes the immense talent you have. It's time you
              participate in more activities and take the first step towards
              unlocking achievements.
            </p>
          </div>
        )}
      </div >}
      {!editAllowed && (achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')?.length != 0 ||
        recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')?.length != 0) && <div className={classes.achievementsSection}>
          {recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')?.length > 0 && achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')?.length > 0 && (
            <>
              <div className={classes.recentActivitiesContainer}>
                <div className="d-flex justify-content-between align-items-center m-2">
                  <h2 className={classes.containerHeading}>Recent Activities</h2>
                  {editAllowed && (
                    <div className="d-flex gap-2 p-2">
                      <Button
                        className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                        onClick={() => {
                          setShowRecentActivityModal(true);
                        }}
                      >
                        Add
                      </Button>
                      <Button
                        className={` ${classes.achievementBtn} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                        onClick={() => {
                          if (isEditingRecentActivity) {
                            saveRecentActivityChanges();
                          }
                          setIsEditingRecentActivity(!isEditingRecentActivity);
                        }}
                      >
                        {isEditingRecentActivity ? <FaSave /> : <FaEdit />}
                        {isEditingRecentActivity ? 'Save' : 'Edit'}
                      </Button>
                    </div>
                  )}
                </div>
                {showActivityAlertMessage && (
                  <ErrorSuccessAlertMessage
                    message={showActivityAlertMessage}
                    varient={alertVariant}
                  />
                )}
                <RecentActivity
                  recentActivities={recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')}
                  learnerQrenciaId={props.learnerQrenciaId}
                  isEditingRecentActivity={isEditingRecentActivity}
                  onPostPayloadUpdate={handlePostPayloadUpdate}
                  editAllowed={editAllowed}
                  showRecentActivityModal={(activity) =>
                    setShowRecentActivityModal({ show: true, activity })
                  }
                />

                <AddRecentActivityModal
                  show={showRecentActivityModal}
                  onHide={() => setShowRecentActivityModal(false)}
                  prepopulatedActivities={showRecentActivityModal.activity}
                  onSuccess={handleRecentActivitySuccess}
                  onError={() => setRecentActivitySaveStatus('error')}
                />
              </div>
              <div className={classes.achievementsContainer}>
                <div className="d-flex justify-content-between align-items-center m-2">
                  <h2 className={classes.containerHeading}>Achievements</h2>
                  {editAllowed && (
                    <div className="d-flex gap-2 p-2">
                      <Button
                        className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Add
                      </Button>
                      <Button
                        className={` ${classes.achievementBtn} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                        onClick={() => {
                          if (isEditing) {
                            saveChanges();
                          }
                          setIsEditing(!isEditing);
                        }}
                      >
                        {isEditing ? <FaSave /> : <FaEdit />}
                        {isEditing ? 'Save' : 'Edit'}
                      </Button>
                    </div>
                  )}
                </div>

                {showAlertMessage && (
                  <ErrorSuccessAlertMessage
                    message={showAlertMessage}
                    varient={alertVariant}
                  />
                )}

                <AchievementCards
                  achievements={achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')}
                  learnerQrenciaId={props.learnerQrenciaId}
                  isEditing={isEditing}
                  onPostPayloadUpdate={handlePostPayloadUpdate}
                  editAllowed={editAllowed}
                  showModal={(achievement) =>
                    setShowModal({ show: true, achievement })
                  }
                />
                <AddAchievementModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  prepopulatedAchievements={showModal.achievement}
                  onSuccess={handleAchievementSuccess}
                  onError={() => setAchievementSaveStatus('error')}
                />
              </div>
            </>
          )}
          {
            achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')?.length == 0 &&
            recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')?.length > 0 && (
              <>
                <div className={classes.recentActivitiesContainer}>
                  <div className="d-flex justify-content-between align-items-center m-2">
                    <h2 className={classes.containerHeading}>Recent Activities</h2>
                    {editAllowed && (
                      <div className="d-flex gap-2 p-2">
                        <Button
                          className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                          onClick={() => {
                            setShowRecentActivityModal(true);
                          }}
                        >
                          Add
                        </Button>
                        <Button
                          className={` ${classes.achievementBtn} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                          onClick={() => {
                            if (isEditingRecentActivity) {
                              saveRecentActivityChanges();
                            }
                            setIsEditingRecentActivity(!isEditingRecentActivity);
                          }}
                        >
                          {isEditingRecentActivity ? <FaSave /> : <FaEdit />}
                          {isEditingRecentActivity ? 'Save' : 'Edit'}
                        </Button>
                      </div>
                    )}
                  </div>
                  {showActivityAlertMessage && (
                    <ErrorSuccessAlertMessage
                      message={showActivityAlertMessage}
                      varient={alertVariant}
                    />
                  )}
                  <RecentActivity
                    recentActivities={recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')}
                    learnerQrenciaId={props.learnerQrenciaId}
                    isEditingRecentActivity={isEditingRecentActivity}
                    onPostPayloadUpdate={handlePostPayloadUpdate}
                    editAllowed={editAllowed}
                    showRecentActivityModal={(activity) =>
                      setShowRecentActivityModal({ show: true, activity })
                    }
                  />

                  <AddRecentActivityModal
                    show={showRecentActivityModal}
                    onHide={() => setShowRecentActivityModal(false)}
                    prepopulatedActivities={showRecentActivityModal.activity}
                    onSuccess={handleRecentActivitySuccess}
                    onError={() => setRecentActivitySaveStatus('error')}
                  />
                </div>
                <div className={classes.adSection}>
                  <a
                    href={
                      LEARNER_PROFILE.lpAdNoAchievementNoRecentActivityCTALink
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={achievementAd}
                      alt="Learner Pass Ad"
                      className={classes.achievementAd}
                    ></img>{' '}
                  </a>
                </div>
              </>

            )
          }
          {
            achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')?.length > 0 &&
            recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')?.length == 0 && (
              <>
                <div className={classes.adSection}>
                  <a
                    href={
                      LEARNER_PROFILE.lpAdNoAchievementNoRecentActivityCTALink
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={achievementAd}
                      alt="Learner Pass Ad"
                      className={classes.achievementAd}
                    ></img>{' '}
                  </a>
                </div>
                <div className={classes.achievementsContainer}>
                  <div className="d-flex justify-content-between align-items-center m-2">
                    <h2 className={classes.containerHeading}>Achievements</h2>
                    {editAllowed && (
                      <div className="d-flex gap-2 p-2">
                        <Button
                          className={` ${classes.achievementBtn}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Add
                        </Button>
                        <Button
                          className={` ${classes.achievementBtn} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                          onClick={() => {
                            if (isEditing) {
                              saveChanges();
                            }
                            setIsEditing(!isEditing);
                          }}
                        >
                          {isEditing ? <FaSave /> : <FaEdit />}
                          {isEditing ? 'Save' : 'Edit'}
                        </Button>
                      </div>
                    )}
                  </div>

                  {showAlertMessage && (
                    <ErrorSuccessAlertMessage
                      message={showAlertMessage}
                      varient={alertVariant}
                    />
                  )}

                  <AchievementCards
                    achievements={achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')}
                    learnerQrenciaId={props.learnerQrenciaId}
                    isEditing={isEditing}
                    onPostPayloadUpdate={handlePostPayloadUpdate}
                    editAllowed={editAllowed}
                    showModal={(achievement) =>
                      setShowModal({ show: true, achievement })
                    }
                  />
                  <AddAchievementModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    prepopulatedAchievements={showModal.achievement}
                    onSuccess={handleAchievementSuccess}
                    onError={() => setAchievementSaveStatus('error')}
                  />
                </div>
              </>
            )
          }
        </div >}
      {!editAllowed &&
        < div >
          {achievements.filter(a => a.achievementType !== "Self Declared" || a.isActiveFlag === 'Y')?.length == 0 &&
            recentActivities.filter(b => b.recentActivityType !== "Self Declared" || b.isActiveFlag === 'Y')?.length == 0 && (
              <div className={classes.fullAdSection}>
                <div className={classes.recentActivitiesAd}>
                  <a
                    href={LEARNER_PROFILE.lpAdNoAchievementNoRecentActivityCTALink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={recentActivityaAd}
                      alt="Learner Pass Ad"
                      className={classes.achievementAd}
                    ></img>
                  </a>
                </div>
              </div>
            )}
        </div>
      }

    </>
  );
};
