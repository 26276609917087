import { useState } from 'react';
import classes from './FooterSectionExternalLinks.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const FooterSectionEoiExternalLinks = (props) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}`;
  const [footerExternalLinkDetails, setFooterExternalLinkDetails] = useState(
    props.externalLinkDetails
  );

  return (
    <>
      <div>
        <h1 className={classes.externalLinkTitle}>
          {footerExternalLinkDetails[0].title}
        </h1>
        {footerExternalLinkDetails
          .filter((data) => data.active === AppConstants.trueText)
          .map((list) => (
            <div key={list.id}>
              {list.videoPath && list.subTitle && (
                <li
                  className={`${classes.externalLinkListElements} ${list.subTitle === 'Skills on Qrencia' ? classes.skillsOnQrencia : ''}`}
                >
                  <a
                    target={AppConstants.openInNewTab}
                    className={classes.eoiLink}
                    href={
                      list.subTitle === 'Skills on Qrencia'
                        ? list.videoPath
                        : `${qrenciaLogoUrl}${list.videoPath}`
                    }
                  >
                    {list.subTitle}
                  </a>
                </li>
              )}
              {list.link && list.description && (
                <li className={classes.externalLinkListElements}>
                  <a
                    target={AppConstants.openInNewTab}
                    className={classes.eoiLink}
                    href={
                      list.description === 'Experts on Qrencia'
                        ? list.link
                        : `${qrenciaLogoUrl}${list.link}`
                    }
                  >
                    {list.description}
                  </a>
                </li>
              )}
            </div>
          ))}
      </div>
    </>
  );
};
