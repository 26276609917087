import React, { useState } from 'react';
import classes from '../individualCoursePage/individualCoursePageBanner/IndividualCoursePageBanner.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const ExpertWelcomePageBanner = (props) => {
  const [bannerImageMobile, setBannerImageMobile] = useState(() => {
    const mobileBanner = props.bannerImage.find((section) => section.imageUrl && section.imageUrl.toLowerCase().includes(AppConstants.mobileText));
    return mobileBanner || null;
  });

  const [bannerImageTablet, setBannerImageTablet] = useState(() => {
    const tabletBanner = props.bannerImage.find((section) => section.imageUrl && section.imageUrl.toLowerCase().includes(AppConstants.tabletText));
    return tabletBanner || null;
  });

  const [bannerImageWeb, setBannerImageWeb] = useState(() => {
    const webBanner = props.bannerImage.find((section) => section.imageUrl && section.imageUrl.toLowerCase().includes(AppConstants.webText));
    return webBanner || null;
  });

  return (
    <>
      {bannerImageMobile && (
        <div className={classes.courseMobileImgContainer}>
          <img src={bannerImageMobile.imageUrl} alt={AppConstants.welcomeExpertPageBannerText} className={classes.courseBannerImage} />
        </div>
      )}
      {bannerImageTablet && (
        <div className={classes.courseTabletImgContainer}>
          <img src={bannerImageTablet.imageUrl} alt={AppConstants.welcomeExpertPageBannerText} className={classes.courseBannerImage} />
        </div>
      )}
      {bannerImageWeb && (
        <div className={classes.courseBannerImgContainer}>
          <img src={bannerImageWeb.imageUrl} alt={AppConstants.welcomeExpertPageBannerText} className={classes.courseBannerImage} />
        </div>
      )}
    </>
  );
};
