import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LEARNER_PASS_POPUP_IMAGES } from '../../../constants/AppConstants';
import Auth from '../../../utils/Auth';
import commonStyles from './LearnerPopupsCommonStyles.module.css';

const LearnerPassExpired = ({ show, onClose, onProceed }) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const illustrationURL = `${mediaContent}${LEARNER_PASS_POPUP_IMAGES.learnerPassExpiredImage}`;
  const username = Auth.getLoggedInUserDetails().firstName;

  const buyLater = () => {
    onClose(); // Close the modal
  };
  return (
    <div>
      <Modal show={show} centered>
        <Modal.Body className={commonStyles.modalBody}>
          <div className={commonStyles.container}>
            <div className={commonStyles.illustrationContainer}>
              <img
                className={commonStyles.illustration}
                src={illustrationURL}
                alt="Illustration"
              />
            </div>
            <div className={commonStyles.contentContainer}>
              <div className={commonStyles.textContainer}>
                <h4 className={commonStyles.title}>Learner Pass Expired</h4>

                <p className={commonStyles.description}>
                  Hi {username}, your Learner Pass has expired! But you can get all the benefits back in no time if you renew it now.{' '}
                  {/* <span className="textHighlight">Learner Pass</span>. */}
                </p>
              </div>
              <div className="buttonContainer">
                <div className={commonStyles.buttonContainer}>
                  <div
                    className={commonStyles.laterButton}
                    variant="primary"
                    onClick={buyLater}
                  >
                    Later
                  </div>
                  <Button
                    className={commonStyles.button}
                    variant="primary"
                    onClick={onProceed}
                  >
                    Renew Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LearnerPassExpired;
