import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AiOutlineDownload, AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { Form, Button, Modal, Row } from 'react-bootstrap';
import { FaInfo, FaRegEye } from 'react-icons/fa';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DataService } from '../../services/DataService';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { AppConstants } from '../../constants/AppConstants';
import classes from './ExpertObrStatusForm.module.css';
import parse from 'html-react-parser';
import { BiLoaderAlt } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import modalClass from '../cards/CompetitionsCards.module.css';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { FormValidationUtils } from '../../utils/FormValidationUtils';
import { FileUploadInput } from '../fileUploadInput/FileUploadInput';
import formClass from '../../common/FormStyling.module.css';
import { ImageGalleryModal } from '../modal/ImageGalleryModal';

export const ExpertObrStatusForm = () => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const { obrId } = useParams();
  const [obrLinkValid, setObrLinkValid] = useState(false);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
  const [obrStatus, setObrStatus] = useState(AppConstants.emptyString);

  const [tncCheck, setTncCheck] = useState(false);
  const [expertObrDetails, setExpertObrDetails] = useState({});
  const [skillDocument, setSkillDocument] = useState(AppConstants.emptyString);
  const [idProof, setIdProof] = useState(AppConstants.emptyString);
  const [addressProof, setAddressProof] = useState(AppConstants.emptyString);
  const [webBannerImage, setWebBannerImage] = useState(AppConstants.emptyString);
  const [videoMessageThumbnail, setVideoMessageThumbnail] = useState(AppConstants.emptyString);
  const [tabletBannerImage, setTabletBannerImage] = useState(AppConstants.emptyString);
  const [mobileBannerImage, setMobileBannerImage] = useState(AppConstants.emptyString);
  const [videoMessage, setVideoMessage] = useState(AppConstants.emptyString);
  const [profileImage, setProfileImage] = useState(AppConstants.emptyString);
  const [skillDocumentSpinner, setSkillDocumentSpinner] = useState(false);
  const [idProofSpinner, setIdProofSpinner] = useState(false);
  const [webBannerImageSpinner, setWebBannerImageSpinner] = useState(false);
  const [videoMessageThumbnailSpinner, setVideoMessageThumbnailSpinner] = useState(false);
  const [tabletBannerImageSpinner, setTabletBannerImageSpinner] = useState(false);
  const [mobileBannerImageSpinner, setMobileBannerImageSpinner] = useState(false);
  const [addressProofSpinner, setAddressProofSpinner] = useState(false);
  const [videoMessageSpinner, setVideoMessageSpinner] = useState(false);
  const [profileImageSpinner, setProfileImageSpinner] = useState(false);
  const [saveButtonSpinner, setSaveButtonSpinner] = useState(false);
  const [reviewButtonSpinner, setReviewButtonSpinner] = useState(false);
  const [modalTypeError, setModalTypeError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [detailsSaved, setDetailsSaved] = useState(false);
  const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
  const [expertSkillValue, setExpertSkillValue] = useState(AppConstants.emptyString);
  const [resetPasswordLink, setResetPasswordLink] = useState(AppConstants.emptyString);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [skillLength, setSkillLength] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [organizationCityList, setOrganizationCityList] = useState([]);
  const [selectedCountryOrg, setSelectedCountryOrg] = useState([]);
  const [selectedStateOrg, setSelectedStateOrg] = useState([]);
  const [selectedCityOrg, setSelectedCityOrg] = useState([]);
  const [capturedLeadWebsiteLink, setCapturedLeadWebsiteLink] = useState(AppConstants.emptyString);
  const [capturedLeadInstituteName, setCapturedLeadInstituteName] = useState(AppConstants.emptyString);
  const [isSkillInstitutePresent, setIsSkillInstitutePresent] = useState(AppConstants.falseText);
  const [isCentrePresent, setIsCentrePresent] = useState(AppConstants.falseText);

  const [expertProfileImageError, setExpertProfileImageError] = useState(null);
  const [expertWebBannerImageError, setExpertWebBannerImageError] = useState(null);
  const [videoMessageThumbnailError, setVideoMessageThumbnailError] = useState(null);
  const [expertTabletBannerImageError, setExpertTabletBannerImageError] = useState(null);
  const [expertMobileBannerImageError, setExpertMobileBannerImageError] = useState(null);
  const [expertVideoMessageError, setExpertVideoMessageError] = useState(null);

  const [skillDocumentPath, setSkillDocumentPath] = useState(null);
  const [idProofPath, setIdProofPath] = useState(null);
  const [addressProofPath, setAddressProofPath] = useState(null);
  const [webBannerImagePath, setWebBannerImagePath] = useState(null);
  const [videoMessageThumbnailPath, setVideoMessageThumbnailPath] = useState(null);
  const [tabletBannerImagePath, setTabletBannerImagePath] = useState(null);
  const [mobileBannerImagePath, setMobileBannerImagePath] = useState(null);
  const [videoMessagePath, setVideoMessagePath] = useState(null);
  const [userImagePath, setUserImagePath] = useState(null);
  const [selectedMediaGallery, setSelectedMediaGallery] = useState([]);
  const [showCreatorGallery, setShowCreatorGallery] = useState(AppConstants.falseText);
  const validMediaTypes = [...AppConstants.promotionalVideoAllowedFormats, ...AppConstants.supportedImageFormats];

  const newCentreFormObj = {
    uid: AppConstants.emptyString,
    centreName: AppConstants.emptyString,
    centreSpocEmail: AppConstants.emptyString,
    centreSpocPhoneNumber: AppConstants.emptyString,
    centreCountryName: AppConstants.emptyString,
    centreCountry: [],
    centreState: [],
    centreStateName: AppConstants.emptyString,
    centreCity: [],
    centreCityName: AppConstants.emptyString,
    centreAddress: AppConstants.emptyString,
    centreGeoLocation: AppConstants.emptyString,
    centreCountryId: AppConstants.emptyString,
    cityList: [],
  };

  const [orgCentreForm, setOrgCentreForm] = useState([{ ...newCentreFormObj, uid: DateTimeUtil.fetchCurrentTimestamp() }]);
  const status = AppConstants.expertObrStatus;
  const zeroValue = 0;

  const closeModal = () => setShowModal(false);

  const createExpertSkillValue = (selectedSkills) => {
    let value = AppConstants.emptyString;
    selectedSkills.forEach((skill, i) => {
      if (i > 0) {
        setSkillLength(i + 1);
        value = `${value}, ${skill.skillName}`;
      } else {
        setSkillLength(1);
        value = skill.skillName;
      }
    });
    setExpertSkillValue(value);
  };

  const numberInputs = document.querySelectorAll('input[type=number]');
  numberInputs.forEach((input) => {
    input.addEventListener('wheel', (e) => e.preventDefault());
  });

  const getRemainingLengthText = (limit, textField) => {
    if (!textField || textField === AppConstants.emptyString) {
      return AppConstants.emptyString;
    }
    let length = textField.length;
    const remaining = limit - length;

    return `${remaining} out of ${limit} characters remaining`;
  };

  const extractFileName = (name) => {
    let url = name.substr(0, name.lastIndexOf('\/'));
    let arr = name.split('/');
    const regex = /[0-9]*-/;
    return arr[3]?.replace(regex, '');
  };

  const getDataTransfer = (name) => {
    const dataTransfer = new DataTransfer();
    const file = new File([''], name, { type: 'text/plain' });
    dataTransfer.items.add(file);
    return dataTransfer;
  };

  useEffect(() => {
    const checkObrLinkValidity = async () => {
      try {
        let statusCode = AppConstants.emptyString;
        const url = `${process.env.REACT_APP_API_URL}${AppConstants.checkObrLinkValidityAPI}${obrId}`;
        const response = await fetch(url);
        const body = await response.json();
        if (response.status === 202) {
          setObrStatus(status.statusAccepted);
          if (response.status === 202) {
            statusCode = response.status;
          }
          if (body !== null) {
            if (statusCode === 202 && body.resetPasswordLink === null) {
              return window.location.assign(`${process.env.REACT_APP_FRONT_END_URL}login`);
            } else {
              setResetPasswordLink(body.resetPasswordLink);
              setObrLinkValid(true);
            }
          }
        } else if (response.status === 200) {
          setObrLinkValid(true);
        } else {
          setObrLinkValid(false);
          setShowPageLoader(false);
        }
      } catch (error) {
        setObrLinkValid(false);
        setShowPageLoader(false);
      }
    };

    checkObrLinkValidity();
  }, [obrId]);

  useEffect(() => {
    async function fetchStates() {
      var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
      const response = await fetch(AppConstants.getStatesApi, config);
      const responseData = await response.json();
      setStateList(responseData);
      return null;
    }

    async function fetchCountries() {
      const url = AppConstants.fetchCountriesAPI;
      const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
      setCountryList(response);
      return null;
    }
    fetchStates();
    fetchCountries();
  }, []);

  async function fetchOrganizationCities(state, country, cityName = AppConstants.emptyString) {
    if ((selectedCountryOrg[zeroValue]?.country == AppConstants.countryIndia || country[zeroValue].country === AppConstants.countryIndia) && state[zeroValue] && state[zeroValue].name !== AppConstants.emptyString) {
      var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
      const response = await fetch(`${AppConstants.getStatesApi}${AppConstants.forwardSlash}${state[zeroValue].iso2}${AppConstants.getCityUri}`, config);
      const responseData = await response.json();
      if (responseData.length !== zeroValue) {
        setOrganizationCityList(responseData);
        if (cityName) {
          setSelectedCityOrg(responseData.filter((city) => city.name === cityName));
        } else {
          setSelectedCityOrg(responseData.filter((city) => city.name === expertObrDetails.expertOrganizationDetails?.organizationCity));
        }
      }
    } else {
      setOrganizationCityList([]);
    }

    return null;
  }

  useEffect(() => {
    const fetchExpertDetails = async () => {
      const url = `${AppConstants.getExpertObrDetailsAPI}${obrId}`;
      const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);

      if (response) {
        const creatorGalleryRelativePaths = response.galleryMediaPaths ? response.galleryMediaPaths.split(',') : [];
        setSelectedMediaGallery(
          response.galleryMediaAbsolutePaths
            ? response.galleryMediaAbsolutePaths.split(',').map((url, index) => ({
                file: AppConstants.nullText,
                url,
                relativePath: creatorGalleryRelativePaths[index],
                type: AppConstants.supportedImageFormats.includes(AppConstants.contentTypeMap[url.split('.').pop().toLowerCase()]) ? 'Image' : 'Video',
                id: Math.floor(Math.random() * 100000 + 1).toString(),
              }))
            : []
        );
        setCapturedLeadWebsiteLink(response.websiteLink);
        setCapturedLeadInstituteName(response.expertOrganizationDetails?.organizationName || AppConstants.nullText);
        setIsSkillInstitutePresent(response.expertOrganizationDetails?.organizationName ? AppConstants.trueText : AppConstants.falseText);
        setIsCentrePresent(response.expertOrganizationDetails?.organizationCentreDetails?.length > 0 ? AppConstants.trueText : AppConstants.falseText);
        setExpertObrDetails(response);
        setSkillDocumentPath(response.skillDocumentRelativePath);
        setAddressProofPath(response.addressProofRelativePath);
        setIdProofPath(response.identityProofRelativePath);
        setUserImagePath(response.userImageRelativePath);
        setVideoMessagePath(response.videoMessageRelativePath);
        setWebBannerImagePath(response.webBannerImageRelativePath);
        setVideoMessageThumbnailPath(response.videoMessageThumbnailRelativePath);
        setTabletBannerImagePath(response.tabletBannerImageRelativePath);
        setMobileBannerImagePath(response.mobileBannerImageRelativePath);
        setObrStatus(response.obrStatus);
        setInitialValuesLoaded(true);
        if (response.obrStatus !== status.statusCreated) {
          setDetailsSaved(true);
        }
        createExpertSkillValue(response.selectedSkills);
        setShowPageLoader(false);
      } else {
        setObrLinkValid(false);
        setShowPageLoader(false);
      }
    };

    if (obrLinkValid) {
      fetchExpertDetails();
    }
  }, [obrLinkValid]);

  useEffect(() => {
    const fetchExpertDetails = async () => {
      const url = `${AppConstants.getExpertObrDetailsAPI}${obrId}`;
      const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);

      if (response) {
        const creatorGalleryRelativePaths = response.galleryMediaPaths ? response.galleryMediaPaths.split(',') : [];
        setSelectedMediaGallery(
          response.galleryMediaAbsolutePaths
            ? response.galleryMediaAbsolutePaths.split(',').map((url, index) => ({
                file: AppConstants.nullText,
                url,
                relativePath: creatorGalleryRelativePaths[index],
                type: AppConstants.supportedImageFormats.includes(AppConstants.contentTypeMap[url.split('.').pop().toLowerCase()]) ? 'Image' : 'Video',
                id: Math.floor(Math.random() * 100000 + 1).toString(),
              }))
            : []
        );
        setCapturedLeadWebsiteLink(response.websiteLink);
        setCapturedLeadInstituteName(response.expertOrganizationDetails?.organizationName || AppConstants.nullText);
        setIsSkillInstitutePresent(response.expertOrganizationDetails?.organizationName ? AppConstants.trueText : AppConstants.falseText);
        setIsCentrePresent(response.expertOrganizationDetails?.organizationCentreDetails?.length > 0 ? AppConstants.trueText : AppConstants.falseText);
        setExpertObrDetails(response);
        setSkillDocumentPath(response.skillDocumentRelativePath);
        setAddressProofPath(response.addressProofRelativePath);
        setIdProofPath(response.identityProofRelativePath);
        setUserImagePath(response.userImageRelativePath);
        setVideoMessagePath(response.videoMessageRelativePath);
        setWebBannerImagePath(response.webBannerImageRelativePath);
        setVideoMessageThumbnailPath(response.videoMessageThumbnailRelativePath);
        setTabletBannerImagePath(response.tabletBannerImageRelativePath);
        setMobileBannerImagePath(response.mobileBannerImageRelativePath);
        setObrStatus(response.obrStatus);
        setInitialValuesLoaded(true);
        createExpertSkillValue(response.selectedSkills);
      } else {
        setObrLinkValid(false);
      }
    };

    if (detailsSaved) {
      fetchExpertDetails();
    }
  }, [detailsSaved]);

  useEffect(() => {
    let orgCountry = [];
    async function fetchCountries(organizationDetails) {
      if (organizationDetails === null) return null;
      let countryName = organizationDetails.organizationCountryName;
      if (countryName !== null && countryName !== undefined) {
        orgCountry = countryList.filter((country) => country.country === countryName);
        setSelectedCountryOrg(orgCountry);
      }
      return null;
    }

    async function fetchStates(organizationDetails) {
      if (organizationDetails === null || organizationDetails === undefined) return null;
      let stateName = organizationDetails.organizationState;
      let countryName = organizationDetails.organizationCountryName;
      let cityName = organizationDetails.organizationCity;
      if (stateName !== null && stateName !== undefined) {
        if (countryName === AppConstants.countryIndia) {
          let state = stateList.filter((state) => state.name === stateName);
          setSelectedStateOrg(state);
          fetchOrganizationCities(state, orgCountry, cityName);
        } else {
          setSelectedStateOrg(stateName);
          setSelectedCityOrg(cityName);
        }
      }
      return null;
    }
    const getCityForState = async (state) => {
      var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
      const response = await fetch(`${AppConstants.getStatesApi}${AppConstants.forwardSlash}${state[zeroValue].iso2}${AppConstants.getCityUri}`, config);
      const responseData = await response.json();
      if (responseData) return responseData;
      else return [];
    };

    const populateCentreDetails = async (organizationDetails) => {
      if (organizationDetails === null || organizationDetails === undefined) {
        return null;
      }

      if (organizationDetails?.organizationCentreDetails === null || organizationDetails?.organizationCentreDetails === undefined) {
        return null;
      }

      let centreDetails = organizationDetails?.organizationCentreDetails;
      let details = [];
      let timeStamp = DateTimeUtil.fetchCurrentTimestamp();
      for (let i = 0; i < centreDetails.length; i++) {
        let centre = centreDetails[i];
        let data = { ...newCentreFormObj };
        data.centreName = centre.centreName;
        data.centreSpocEmail = centre.centreSpocEmail;
        data.centreSpocPhoneNumber = centre.centreSpocPhoneNumber;
        data.centreCountryName = centre.centreCountryName;
        data.centreCityName = centre.centreCity;
        data.centreStateName = centre.centreState;
        data.centreAddress = centre.centreAddress;
        data.centreGeoLocation = centre.centreGeoLocation;
        data.centreCountryId = centre.centreCountryId;
        data.uid = centre.id;

        data.centreCountry = countryList.filter((country) => country.country === centre.centreCountryName);
        if (centre.centreCountryName === AppConstants.countryIndia && centre.centreState !== null) {
          data.centreState = stateList.filter((state) => state.name === centre.centreState);
          data.cityList = await getCityForState(data.centreState);
          data.centreCity = data.cityList?.filter((city) => city.name === centre.centreCity);
        } else {
          data.centreState = [];
          data.centreCity = [];
          data.cityList = [];
        }
        details.push(data);
      }

      if (details.length === 0) {
        setOrgCentreForm([{ ...newCentreFormObj, uid: timeStamp }]);
      } else {
        setOrgCentreForm(details);
      }
      return null;
    };

    const updateObrStatusToDraft = async () => {
      let data = {
        expertObrUniqueId: obrId,
        comment: AppConstants.emptyString,
        obrStatus: status.statusDraft,
      };
      const url = AppConstants.updateObrStatusAPI;
      const response = await DataService.put(url, data, AppConstants.emptyString, AppConstants.emptyString);
      if (response !== 1) {
        setObrStatus(status.statusCreated);
      }
    };
    if (initialValuesLoaded && expertObrDetails.obrStatus !== status.statusCreated && stateList.length > 0 && countryList.length > 0) {
      fetchCountries(expertObrDetails.expertOrganizationDetails);
      fetchStates(expertObrDetails.expertOrganizationDetails);
      populateCentreDetails(expertObrDetails.expertOrganizationDetails);
    } else if (initialValuesLoaded && expertObrDetails.obrStatus === status.statusCreated) {
      updateObrStatusToDraft();
    }
  }, [initialValuesLoaded, stateList, countryList]);

  const changeTncCheck = (e) => {
    setTncCheck(e.target.checked);
  };

  const getFileNameWithExt = (name) => {
    let ext = AppConstants.emptyString;
    if (name === AppConstants.skillDocumentText) {
      ext = expertObrDetails?.skillDocumentRelativePath.split('.').pop();
    } else if (name === AppConstants.idProofText) {
      ext = expertObrDetails?.identityProofRelativePath.split('.').pop();
    } else if (name === AppConstants.addressProofText) {
      ext = expertObrDetails.addressProofRelativePath.split('.').pop();
    } else if (name === AppConstants.profilePicture) {
      ext = expertObrDetails?.userImageRelativePath.split('.').pop();
    } else if (name === AppConstants.videoMessage) {
      ext = expertObrDetails?.videoMessageRelativePath.split('.').pop();
    } else if (name === AppConstants.mobileBanner) {
      ext = expertObrDetails?.mobileBannerImageRelativePath.split('.').pop();
    } else if (name === AppConstants.tabletBanner) {
      ext = expertObrDetails?.tabletBannerImageRelativePath.split('.').pop();
    } else if (name === AppConstants.webBanner) {
      ext = expertObrDetails?.webBannerImageRelativePath.split('.').pop();
    } else if (name === AppConstants.obrVideoMessageThumbnail) {
      ext = expertObrDetails?.videoMessageThumbnailRelativePath.split('.').pop();
    }

    return `${name}${AppConstants.dot}${ext}`;
  };

  const downloadFile = async (fileUrl, name) => {
    let fileName = getFileNameWithExt(name);
    let response = await FileOperationsUtil.downloadDocument(fileUrl, AppConstants.obrDetailsUploadFolder, fileName);
    if (!response || response instanceof Error) {
      if (name === AppConstants.skillDocumentText) {
        setSkillDocumentSpinner(false);
      } else if (name === AppConstants.idProofText) {
        setIdProofSpinner(false);
      } else if (name === AppConstants.addressProofText) {
        setAddressProofSpinner(false);
      } else if (name === AppConstants.profilePicture) {
        setProfileImageSpinner(false);
      } else if (name === AppConstants.videoMessage) {
        setVideoMessageSpinner(false);
      } else if (name === AppConstants.mobileBanner) {
        setMobileBannerImageSpinner(false);
      } else if (name === AppConstants.tabletBanner) {
        setTabletBannerImageSpinner(false);
      } else if (name === AppConstants.webBanner) {
        setWebBannerImageSpinner(false);
      } else if (name === AppConstants.obrVideoMessageThumbnail) {
        setVideoMessageThumbnailSpinner(false);
      }
    }
  };

  const removeFile = (id) => {
    const input = document.getElementById(id);
    input.value = AppConstants.emptyString;
    input.type = AppConstants.emptyString;
    input.type = 'file';
    setDetailsSaved(false);
    if (id === AppConstants.idProofText) {
      setIdProof(AppConstants.emptyString);
    } else if (id === AppConstants.addressProofText) {
      setAddressProof(AppConstants.emptyString);
    } else if (id === AppConstants.skillDocumentText) {
      setSkillDocument(AppConstants.emptyString);
    } else if (id === AppConstants.profilePicture) {
      setProfileImage(AppConstants.emptyString);
    } else if (id === AppConstants.webBanner) {
      setWebBannerImage(AppConstants.emptyString);
    } else if (id === AppConstants.tabletBanner) {
      setTabletBannerImage(AppConstants.emptyString);
    } else if (id === AppConstants.mobileBanner) {
      setMobileBannerImage(AppConstants.emptyString);
    } else if (id === AppConstants.videoMessage) {
      setVideoMessage(AppConstants.emptyString);
    } else if (id === AppConstants.obrVideoMessageThumbnail) {
      setVideoMessageThumbnail(AppConstants.emptyString);
    }
  };

  const uploadFiles = async () => {
    try {
      let filePaths = {
        skillDocumentPath: AppConstants.emptyString,
        idProofPath: AppConstants.emptyString,
        addressProofPath: AppConstants.emptyString,
        webBannerImagePath: AppConstants.emptyString,
        tabletBannerImagePath: AppConstants.emptyString,
        mobileBannerImagePath: AppConstants.emptyString,
        userImagePath: AppConstants.emptyString,
        videoMessagePath: AppConstants.emptyString,
        videoMessageThumbnailPath: AppConstants.emptyString,
        creatorMediaGalleryPath: AppConstants.emptyString,
      };
      let creatorMedia = [];
      let params = [];
      let forwardSlash = AppConstants.forwardSlash;

      if (skillDocument?.size > 0) params.push({ file: skillDocument, previousPath: skillDocumentPath, id: AppConstants.skillDocumentText });
      if (idProof?.size > 0) params.push({ file: idProof, previousPath: idProofPath, id: AppConstants.idProofText });
      if (addressProof?.size > 0) params.push({ file: addressProof, previousPath: addressProofPath, id: AppConstants.addressProofText });
      if (webBannerImage?.size > 0) params.push({ file: webBannerImage, previousPath: webBannerImagePath, id: AppConstants.expertObrMicrositeWebBannerImageText });
      if (tabletBannerImage?.size > 0) params.push({ file: tabletBannerImage, previousPath: tabletBannerImagePath, id: AppConstants.expertObrMicrositeTabletBannerImageText });
      if (mobileBannerImage?.size > 0) params.push({ file: mobileBannerImage, previousPath: mobileBannerImagePath, id: AppConstants.expertObrMicrositeMobileBannerImageText });
      if (profileImage?.size > 0) params.push({ file: profileImage, previousPath: userImagePath, id: AppConstants.expertObrMicrositeUserImageText });
      if (videoMessage?.size > 0) params.push({ file: videoMessage, previousPath: videoMessagePath, id: AppConstants.expertObrMicrositeUserVideoMessageText });
      if (videoMessageThumbnail?.size > 0) params.push({ file: videoMessageThumbnail, previousPath: videoMessageThumbnailPath, id: AppConstants.obrVideoMessageThumbnail });
      selectedMediaGallery.forEach((media) => {
        if (media.file?.name) {
          params.push({ file: media.file, previousPath: AppConstants.nullText, id: 'creatorMediaGallery' });
        }
      });

      for (let i = 0; i < params.length; i++) {
        let { file, previousPath, id } = params[i];
        let path = AppConstants.emptyString;
        // replace white spaces with emptystring
        let fileName = file.name.replace(/\s+/g, AppConstants.emptyString);
        if (previousPath) {
          previousPath = previousPath.replace(mediaContent, AppConstants.emptyString);
          let pathArray = previousPath.split('/');
          path = `${AppConstants.obrDetailsUploadFolder}${forwardSlash}${pathArray[1]}${forwardSlash}${id}${forwardSlash}${fileName}`;
        } else {
          path = `${AppConstants.obrDetailsUploadFolder}${forwardSlash}${expertObrDetails.expertObrUniqueId}${forwardSlash}${id}${forwardSlash}${fileName}`;
        }
        let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline);
        if (response instanceof Error) {
          return null;
        }
        if (id === AppConstants.skillDocumentText) filePaths.skillDocumentPath = response.objectKey;
        if (id === AppConstants.idProofText) filePaths.idProofPath = response.objectKey;
        if (id === AppConstants.addressProofText) filePaths.addressProofPath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeWebBannerImageText) filePaths.webBannerImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeTabletBannerImageText) filePaths.tabletBannerImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeMobileBannerImageText) filePaths.mobileBannerImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeUserImageText) filePaths.userImagePath = response.objectKey;
        if (id === AppConstants.expertObrMicrositeUserVideoMessageText) filePaths.videoMessagePath = response.objectKey;
        if (id === AppConstants.obrVideoMessageThumbnail) filePaths.videoMessageThumbnailPath = response.objectKey;
        if (id === 'creatorMediaGallery') creatorMedia.push(response.objectKey);
      }

      creatorMedia.push(selectedMediaGallery.filter((media) => !media.file?.name).map((value) => value.relativePath));
      filePaths.creatorMediaGalleryPath = creatorMedia.join(',');
      return filePaths;
    } catch (error) {
      return null;
    }
  };

  const sendObrDetailsForReview = async () => {
    try {
      let errorMessage = validateFormSubmit();
      if (detailsSaved && errorMessage.length === 0) {
        setReviewButtonSpinner(true);
        let data = {
          expertObrUniqueId: obrId,
          comment: null,
          obrStatus: status.statusInReview,
          tncCheck: tncCheck ? AppConstants.yText : AppConstants.nText,
        };

        const url = AppConstants.updateObrStatusAPI;
        const response = await DataService.put(url, data, AppConstants.emptyString, AppConstants.emptyString);

        if (response === 1) {
          setReviewButtonSpinner(false);
          setShowModal(true);
          setModalTypeError(false);
          setModalMessage(SuccessMessage.obrDetailsSentForReview);
          setObrStatus(status.statusInReview);
        } else {
          setReviewButtonSpinner(false);
          setShowModal(true);
          setModalTypeError(true);
          setModalMessage(ErrorMessages.expertObrDetailsNotSentForReview);
          setObrStatus(status.statusInReview);
        }
      } else {
        setShowModal(true);
        setModalTypeError(true);
        if (errorMessage) {
          setModalMessage(errorMessage);
        } else {
          setModalMessage(ErrorMessages.unsavedDetailsError);
        }
      }
    } catch (error) {
      setReviewButtonSpinner(false);
      setShowModal(true);
      setModalTypeError(true);
      setModalMessage(ErrorMessages.expertObrDetailsNotSentForReview);
    }
  };
  const validateFormSubmit = () => {
    let message = AppConstants.emptyString;
    let organizationAdded = false;
    let isCentreAdded = false;
    let orgDetails = expertObrDetails.expertOrganizationDetails;
    let urlPattern = /^((https?|smtp):\/\/)?(www\.)?[\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\/\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%_\+.~#?&//=]*)$/u;

    if (expertObrDetails.expertDob !== AppConstants.emptyString && expertObrDetails.expertDob !== null && expertObrDetails.expertDob !== undefined && !FormValidationUtils.isValidDateOfBirth(expertObrDetails.expertDob)) {
      message = ErrorMessages.expertObrIncorrectDob;
      return message;
    }

    if (userImagePath === AppConstants.emptyString || userImagePath === AppConstants.nullText) {
      if (profileImage === null || profileImage === undefined || profileImage === AppConstants.emptyString || !profileImage?.size) {
        message = ErrorMessages.expertObrProfilePictureError;
        return message;
      }
    }

    if (expertObrDetails.yearsOfExperience > 99 || expertObrDetails.yearsOfExperience < 0) {
      message = ErrorMessages.expertObrInvalidExperienceError;
      return message;
    }

    // validating organization details if skill institute present
    if (isSkillInstitutePresent) {
      if (orgDetails?.organizationName?.trim() || orgDetails?.organizationDesignation?.trim() || orgDetails?.expertOrganizationEmail?.trim() || orgDetails?.expertOrganizationContactNumber?.trim() || orgDetails?.organizationDescription?.trim() || orgDetails?.organizationInstagramLink?.trim() || orgDetails?.organizationFacebookLink?.trim() || orgDetails?.organizationLinkedinLink?.trim() || orgDetails?.organizationWebsiteLink?.trim() || orgDetails?.organizationYoutubeLink?.trim() || selectedCountryOrg.length > 0 || selectedStateOrg.length > 0 || selectedCityOrg.length > 0) {
        organizationAdded = true;
        if (!orgDetails?.organizationName?.trim()) {
          message = ErrorMessages.expertObrOrganizationNameError;
          return message;
        } else if (orgDetails?.organizationName?.includes(`"`)) {
          message = ErrorMessages.expertObrOrganizationNameQuotesError;
          return message;
        } else if (!orgDetails?.organizationDesignation?.trim()) {
          message = ErrorMessages.expertObrDesignationError;
          return message;
        } else if (!FormValidationUtils.isValidEmail(orgDetails.expertOrganizationEmail)) {
          message = ErrorMessages.expertObrWorkEmailError;
          return message;
        } else if (!orgDetails?.expertOrganizationContactNumber?.trim() || !FormValidationUtils.isValidInternationalMobileNumber(orgDetails.expertOrganizationContactNumber)) {
          message = ErrorMessages.expertObrOrgMobileError;
          return message;
        } else if (selectedCountryOrg === null || selectedCountryOrg === undefined || selectedCountryOrg.length === 0) {
          message = ErrorMessages.expertObrOrgCountryError;
          return message;
        } else if (selectedCountryOrg[0].country === AppConstants.countryIndia && (selectedStateOrg === null || selectedStateOrg === undefined || selectedStateOrg.length === 0)) {
          message = ErrorMessages.expertObrOrgStateError;
          return message;
        } else if (selectedCountryOrg[0].country === AppConstants.countryIndia && (selectedCityOrg === null || selectedCityOrg === undefined || selectedCityOrg.length === 0)) {
          message = ErrorMessages.expertObrOrgCityError;
          return message;
        } else if (selectedCountryOrg[0].country !== AppConstants.countryIndia && (selectedCityOrg === null || selectedCityOrg === undefined || selectedCityOrg.length === 0)) {
          message = ErrorMessages.expertObrOrgCityError;
          return message;
        } else if (!orgDetails?.organizationAddress?.trim()) {
          message = ErrorMessages.expertObrOrgAddressError;
          return message;
        } else if (!urlPattern.test(orgDetails.organizationGeoLocation)) {
          message = ErrorMessages.expertObrOrgGeolocationError;
          return message;
        } else if (orgDetails?.organizationWebsiteLink?.trim() && !urlPattern.test(orgDetails.organizationWebsiteLink)) {
          message = ErrorMessages.expertObrOrgWebsiteLinkError;
          return message;
        } else if (orgDetails?.organizationLinkedinLink?.trim() && !urlPattern.test(orgDetails.organizationLinkedinLink)) {
          message = ErrorMessages.expertObrOrgLinkedinLinkError;
          return message;
        } else if (orgDetails?.organizationFacebookLink?.trim() && !urlPattern.test(orgDetails.organizationFacebookLink)) {
          message = ErrorMessages.expertObrOrgFacebookLinkError;
          return message;
        } else if (orgDetails?.organizationInstagramLink?.trim() && !urlPattern.test(orgDetails.organizationInstagramLink)) {
          message = ErrorMessages.expertObrOrgInstagramLinkError;
          return message;
        } else if (orgDetails?.organizationYoutubeLink?.trim() && !urlPattern.test(orgDetails.organizationYoutubeLink)) {
          message = ErrorMessages.expertObrOrgYoutubeLinkError;
          return message;
        } else if (!orgDetails?.organizationDescription?.trim()) {
          message = ErrorMessages.expertObrOrgDescriptionError;
          return message;
        }
      }
    }

    if (isCentrePresent && isSkillInstitutePresent) {
      orgCentreForm.forEach((centreDetails, idx) => {
        if (centreDetails.centreName?.trim() || centreDetails.centreSpocEmail?.trim() || centreDetails.centreCountryName?.trim() || centreDetails.centreStateName?.trim() || centreDetails.centreCityName?.trim() || centreDetails.centreSpocPhoneNumber || centreDetails.centreAddress?.trim() || centreDetails.centreGeoLocation?.trim()) {
          isCentreAdded = true;
        }
      });
    }

    if (isCentreAdded && !organizationAdded) {
      message = ErrorMessages.expertObrOrgCentreError;
    } else if (isCentreAdded && organizationAdded) {
      for (let idx = 0; idx < orgCentreForm.length; idx++) {
        let centreDetails = orgCentreForm[idx];
        if (!centreDetails.centreName || centreDetails.centreName?.trim() === AppConstants.emptyString) {
          message = `Please enter the name of centre ${idx + 1}`;
          return message;
        } else if (!FormValidationUtils.isValidEmail(centreDetails.centreSpocEmail)) {
          message = `Please enter a valid email of SPOC for centre ${idx + 1} `;
          return message;
        } else if (centreDetails.centreCountryName === AppConstants.countryIndia && !FormValidationUtils.isValidIndiaMobileNumber(centreDetails.centreSpocPhoneNumber)) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (centreDetails.centreCountryName !== AppConstants.countryIndia && !FormValidationUtils.isValidInternationalMobileNumber(centreDetails.centreSpocPhoneNumber)) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (centreDetails.centreCountryName === null || centreDetails.centreCountryName === undefined || centreDetails.centreCountryName === AppConstants.emptyString) {
          message = `Please enter centre ${idx + 1} country`;
          return message;
        } else if (centreDetails.centreCountryName === AppConstants.countryIndia && (centreDetails.centreStateName === null || centreDetails.centreStateName === undefined || centreDetails.centreStateName === AppConstants.emptyString)) {
          message = `Please enter centre ${idx + 1} state`;
          return message;
        } else if (centreDetails.centreCountryName === AppConstants.countryIndia && (centreDetails.centreCityName === undefined || centreDetails.centreCityName === null || centreDetails.centreCityName === AppConstants.emptyString)) {
          message = `Please enter centre ${idx + 1} city`;
          return message;
        } else if (centreDetails.centreCountryName !== AppConstants.countryIndia && (centreDetails.centreCityName === null || centreDetails.centreCityName === undefined || centreDetails.centreCityName === AppConstants.emptyString)) {
          message = `Please enter centre ${idx + 1} city`;
          return message;
        } else if (!centreDetails.centreAddress || centreDetails.centreAddress?.trim() === AppConstants.emptyString) {
          message = `Please enter centre ${idx + 1} address`;
          return message;
        } else if (!urlPattern.test(centreDetails.centreGeoLocation)) {
          message = `Please enter a valid google maps location URL for centre ${idx + 1}`;
          return message;
        }
      }
    } else if (!isCentreAdded && organizationAdded) {
      message = AppConstants.emptyString;
    } else if (!isCentreAdded && !organizationAdded) {
      message = AppConstants.emptyString;
    }

    if (!skillDocumentPath && !skillDocument?.size > 0) {
      message = ErrorMessages.highestQualificationFileError;
    } else if (!idProof?.size > 0 && !idProofPath) {
      message = ErrorMessages.expertObrIdProofError;
    } else if (!addressProof?.size > 0 && !addressProofPath) {
      message = ErrorMessages.expertObrAddressProofError;
    }

    return message;
  };

  const validateFormSave = () => {
    let message = AppConstants.emptyString;
    let organizationAdded = false;
    let isCentreAdded = false;
    let orgDetails = isSkillInstitutePresent ? expertObrDetails.expertOrganizationDetails || {} : {};
    // let urlPattern = /^((https?|smtp):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    let urlPattern = /^((https?|smtp):\/\/)?(www\.)?[\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\/\p{L}\p{N}\p{S}\p{P}\p{M}\-@:%_\+.~#?&//=]*)$/u;

    if (expertObrDetails.expertDob !== AppConstants.emptyString && expertObrDetails.expertDob !== null && expertObrDetails.expertDob != undefined && !FormValidationUtils.isValidDateOfBirth(expertObrDetails.expertDob)) {
      message = ErrorMessages.expertObrIncorrectDob;
      return message;
    }

    if (expertObrDetails.yearsOfExperience > 99 || expertObrDetails.yearsOfExperience < 0) {
      message = ErrorMessages.expertObrInvalidExperienceError;
      return message;
    }

    if (expertObrDetails.websiteLink?.trim() && !urlPattern.test(expertObrDetails.websiteLink)) {
      message = ErrorMessages.expertObrMicrositeWebsiteError;
      return message;
    }

    if (expertObrDetails.linkedinLink?.trim() && !urlPattern.test(expertObrDetails.linkedinLink)) {
      message = ErrorMessages.expertObrMicrositeLinkedinError;
      return message;
    }

    if (expertObrDetails.facebookLink?.trim() && !urlPattern.test(expertObrDetails.facebookLink)) {
      message = ErrorMessages.expertObrMicrositeFacebookError;
      return message;
    }

    if (expertObrDetails.instagramLink?.trim() && !urlPattern.test(expertObrDetails.instagramLink)) {
      message = ErrorMessages.expertObrMicrositeInstagramError;
      return message;
    }

    if (expertObrDetails.youtubeLink?.trim() && !urlPattern.test(expertObrDetails.youtubeLink)) {
      message = ErrorMessages.expertObrMicrositeYoutubeError;
      return message;
    }

    // validating organization details if skill Institute is present
    if (isSkillInstitutePresent) {
      if (orgDetails?.organizationName?.trim() || orgDetails?.organizationDesignation?.trim() || orgDetails?.expertOrganizationEmail?.trim() || orgDetails?.expertOrganizationContactNumber?.trim() || orgDetails?.organizationDescription?.trim() || orgDetails?.organizationInstagramLink?.trim() || orgDetails?.organizationFacebookLink?.trim() || orgDetails?.organizationLinkedinLink?.trim() || orgDetails?.organizationWebsiteLink?.trim() || orgDetails?.organizationYoutubeLink?.trim() || selectedCountryOrg.length > 0 || selectedStateOrg.length > 0 || selectedCityOrg.length > 0) {
        organizationAdded = true;
        if (orgDetails?.organizationName?.includes(`"`)) {
          message = ErrorMessages.expertObrOrganizationNameQuotesError;
          return message;
        } else if (orgDetails?.expertOrganizationEmail?.trim() && !FormValidationUtils.isValidEmail(orgDetails.expertOrganizationEmail)) {
          message = ErrorMessages.expertObrWorkEmailError;
          return message;
        } else if (orgDetails?.expertOrganizationContactNumber?.trim() && !FormValidationUtils.isValidInternationalMobileNumber(orgDetails.expertOrganizationContactNumber)) {
          message = ErrorMessages.expertObrOrgMobileError;
          return message;
        } else if (orgDetails?.expertOrganizationContactNumber?.trim() && selectedCountryOrg[0]?.country === AppConstants.countryIndia && !FormValidationUtils.isValidIndiaMobileNumber(orgDetails.expertOrganizationContactNumber)) {
          message = ErrorMessages.expertObrOrgMobileError;
          return message;
        } else if (orgDetails?.organizationGeoLocation?.trim() && !urlPattern.test(orgDetails.organizationGeoLocation)) {
          message = ErrorMessages.expertObrOrgGeolocationError;
          return message;
        } else if (orgDetails?.organizationWebsiteLink?.trim() && !urlPattern.test(orgDetails.organizationWebsiteLink)) {
          message = ErrorMessages.expertObrOrgWebsiteLinkError;
          return message;
        } else if (orgDetails?.organizationLinkedinLink?.trim() && !urlPattern.test(orgDetails.organizationLinkedinLink)) {
          message = ErrorMessages.expertObrOrgLinkedinLinkError;
          return message;
        } else if (orgDetails?.organizationFacebookLink?.trim() && !urlPattern.test(orgDetails.organizationFacebookLink)) {
          message = ErrorMessages.expertObrOrgFacebookLinkError;
          return message;
        } else if (orgDetails?.organizationInstagramLink?.trim() && !urlPattern.test(orgDetails.organizationInstagramLink)) {
          message = ErrorMessages.expertObrOrgInstagramLinkError;
          return message;
        } else if (orgDetails?.organizationYoutubeLink?.trim() && !urlPattern.test(orgDetails.organizationYoutubeLink)) {
          message = ErrorMessages.expertObrOrgYoutubeLinkError;
          return message;
        }
      }
    }

    if (isSkillInstitutePresent && isCentrePresent) {
      orgCentreForm.forEach((centreDetails) => {
        if (centreDetails.centreName?.trim() || centreDetails.centreSpocEmail?.trim() || centreDetails.centreCountryName?.trim() || centreDetails.centreStateName?.trim() || centreDetails.centreCityName?.trim() || centreDetails.centreSpocPhoneNumber || centreDetails.centreAddress?.trim() || centreDetails.centreGeoLocation?.trim()) {
          isCentreAdded = true;
        }
      });
    }

    if (isCentreAdded && !organizationAdded) {
      message = ErrorMessages.expertObrOrgCentreError;
    } else if (isCentreAdded && organizationAdded) {
      orgCentreForm.map((centreDetails, idx) => {
        if (centreDetails.centreSpocEmail?.trim() && !FormValidationUtils.isValidEmail(centreDetails.centreSpocEmail)) {
          message = `Please enter a valid email of SPOC for centre ${idx + 1} `;
          return message;
        } else if (centreDetails.centreSpocPhoneNumber && centreDetails.centreCountryName === AppConstants.countryIndia && !FormValidationUtils.isValidIndiaMobileNumber(centreDetails.centreSpocPhoneNumber)) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (centreDetails.centreSpocPhoneNumber && centreDetails.centreCountryName !== AppConstants.countryIndia && !FormValidationUtils.isValidInternationalMobileNumber(centreDetails.centreSpocPhoneNumber)) {
          message = `Please enter a valid mobile number of SPOC for centre ${idx + 1} `;
          return message;
        } else if (centreDetails.centreGeoLocation?.trim() && !urlPattern.test(centreDetails.centreGeoLocation)) {
          message = `Please enter a valid google maps location URL for centre ${idx + 1}`;
          return message;
        }
      });
    } else {
      message = AppConstants.emptyString;
    }

    return message;
  };

  const getAbsoluteUrlPath = (url) => {
    try {
      if (url === null || url === undefined || url === AppConstants.emptyString || url.trim() === AppConstants.emptyString) {
        return null;
      }

      if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
        return `${AppConstants.internetProtocols.https}://${url}`;
      }
      return url;
    } catch (e) {
      return null;
    }
  };

  const getOrganizationCentreProps = () => {
    let centreAdded = false;
    orgCentreForm.forEach((centreDetails, idx) => {
      if (centreDetails.centreName?.trim() || centreDetails.centreSpocEmail?.trim() || centreDetails.centreCountryName?.trim() || centreDetails.centreStateName?.trim() || centreDetails.centreCityName?.trim() || centreDetails.centreSpocPhoneNumber || centreDetails.centreAddress?.trim() || centreDetails.centreGeoLocation?.trim()) {
        centreAdded = true;
      }
    });

    if (!centreAdded) {
      return null;
    }
    let centreDetails = orgCentreForm.map((centreData) => {
      return {
        centreName: centreData.centreName?.trim() || null,
        centreSpocEmail: centreData.centreSpocEmail || null,
        centreSpocPhoneNumber: centreData.centreSpocPhoneNumber || null,
        centreCountryId: centreData.centreCountryId || null,
        centreState: centreData.centreStateName || null,
        centreCity: centreData.centreCityName || null,
        centreAddress: centreData.centreAddress?.trim() || null,
        centreGeoLocation: getAbsoluteUrlPath(centreData.centreGeoLocation) || null,
      };
    });
    return centreDetails;
  };

  const getExpertOrganizationDetails = () => {
    let isCentreNull = true;
    let isOrgNull = true;
    let orgDetails = expertObrDetails.expertOrganizationDetails;
    let organizationState, organizationCity;

    if (selectedCountryOrg[0]?.country == AppConstants.countryIndia) {
      organizationState = selectedStateOrg[0]?.name;
      organizationCity = selectedCityOrg[0]?.name;
    } else {
      organizationState = selectedStateOrg.length > 0 ? selectedStateOrg : null;
      organizationCity = selectedCityOrg.length > 0 ? selectedCityOrg : null;
    }
    orgCentreForm.forEach((centreData) => {
      if (centreData.centreName?.trim() || centreData.centreSpocEmail?.trim() || centreData.centreSpocPhoneNumber?.trim() || centreData.centreCountryId || centreData.centreStateName?.trim() || centreData.centreCityName?.trim() || centreData.centreAddress?.trim() || centreData.centreGeoLocation?.trim()) {
        isCentreNull = false;
      }
    });
    if (orgDetails !== null || orgDetails !== undefined) {
      if (orgDetails?.organizationName?.trim() || orgDetails?.organizationDesignation?.trim() || orgDetails?.expertOrganizationEmail?.trim() || orgDetails?.expertOrganizationContactNumber?.trim() || orgDetails?.organizationDescription?.trim() || orgDetails?.organizationInstagramLink?.trim() || orgDetails?.organizationFacebookLink?.trim() || orgDetails?.organizationLinkedinLink?.trim() || orgDetails?.organizationWebsiteLink?.trim() || orgDetails?.organizationYoutubeLink?.trim() || selectedCountryOrg.length > 0 || selectedStateOrg.length > 0 || selectedCityOrg.length > 0) {
        isOrgNull = false;
      }
    }

    if (isCentreNull && isOrgNull) {
      return null;
    }

    let orgFacebookLink = getAbsoluteUrlPath(expertObrDetails.expertOrganizationDetails?.organizationFacebookLink);
    let orgLinkedinLink = getAbsoluteUrlPath(expertObrDetails.expertOrganizationDetails?.organizationLinkedinLink);
    let orgYoutubeLink = getAbsoluteUrlPath(expertObrDetails.expertOrganizationDetails?.organizationYoutubeLink);
    let orgWebsiteLink = getAbsoluteUrlPath(expertObrDetails.expertOrganizationDetails?.organizationWebsiteLink);
    let orgInstagramLink = getAbsoluteUrlPath(expertObrDetails.expertOrganizationDetails?.organizationInstagramLink);
    let geoLocationLink = getAbsoluteUrlPath(expertObrDetails.expertOrganizationDetails?.organizationGeoLocation);

    let data = {
      organizationName: expertObrDetails.expertOrganizationDetails?.organizationName?.trim() || null,
      organizationDesignation: expertObrDetails.expertOrganizationDetails?.organizationDesignation?.trim() || null,
      organizationDescription: expertObrDetails.expertOrganizationDetails?.organizationDescription?.trim() || null,
      organizationState: organizationState ? organizationState?.trim() : null,
      organizationCity: organizationCity ? organizationCity?.trim() : null,
      organizationAddress: expertObrDetails.expertOrganizationDetails?.organizationAddress?.trim() || null,
      organizationFacebookLink: orgFacebookLink || null,
      organizationInstagramLink: orgInstagramLink || null,
      organizationLinkedinLink: orgLinkedinLink || null,
      organizationYoutubeLink: orgYoutubeLink || null,
      organizationWebsiteLink: orgWebsiteLink || null,
      organizationGeoLocation: geoLocationLink || null,
      expertOrganizationContactNumber: expertObrDetails.expertOrganizationDetails?.expertOrganizationContactNumber || null,
      expertOrganizationEmail: expertObrDetails.expertOrganizationDetails?.expertOrganizationEmail || null,
      organizationCountryId: selectedCountryOrg[0]?.id || null,
      organizationCentreDetails: isCentrePresent ? getOrganizationCentreProps() : null,
    };

    return data;
  };

  const saveObrDetails = async () => {
    let formErrorMessage = validateFormSave();
    if (formErrorMessage.length === 0) {
      try {
        setSaveButtonSpinner(true);
        const filePaths = await uploadFiles();
        if (filePaths !== null) {
          let youtubeLink = getAbsoluteUrlPath(expertObrDetails.youtubeLink);
          let facebookLink = getAbsoluteUrlPath(expertObrDetails.facebookLink);
          let instagramLink = getAbsoluteUrlPath(expertObrDetails.instagramLink);
          let linkedinLink = getAbsoluteUrlPath(expertObrDetails.linkedinLink);
          let websiteLink = getAbsoluteUrlPath(expertObrDetails.websiteLink);

          let data = {
            skillDocumentRelativePath: filePaths.skillDocumentPath ? filePaths.skillDocumentPath : skillDocumentPath ? expertObrDetails.skillDocumentRelativePath : null,
            identityProofRelativePath: filePaths.idProofPath ? filePaths.idProofPath : idProofPath ? expertObrDetails.identityProofRelativePath : null,
            addressProofRelativePath: filePaths.addressProofPath ? filePaths.addressProofPath : addressProofPath ? expertObrDetails.addressProofRelativePath : null,
            tncCheck: tncCheck ? AppConstants.yText : AppConstants.nText,
            expertObrUniqueId: obrId,
            webBannerImageRelativePath: filePaths.webBannerImagePath ? filePaths.webBannerImagePath : webBannerImagePath ? expertObrDetails.webBannerImageRelativePath : null,
            videoMessageThumbnailRelativePath: filePaths.videoMessageThumbnailPath ? filePaths.videoMessageThumbnailPath : videoMessageThumbnailPath ? expertObrDetails.videoMessageThumbnailRelativePath : null,
            tabletBannerImageRelativePath: filePaths.tabletBannerImagePath ? filePaths.tabletBannerImagePath : tabletBannerImagePath ? expertObrDetails.tabletBannerImageRelativePath : null,
            mobileBannerImageRelativePath: filePaths.mobileBannerImagePath ? filePaths.mobileBannerImagePath : mobileBannerImagePath ? expertObrDetails.mobileBannerImageRelativePath : null,
            userImageRelativePath: filePaths.userImagePath ? filePaths.userImagePath : userImagePath ? expertObrDetails.userImageRelativePath : null,
            yearsOfExperience: expertObrDetails.yearsOfExperience?.trim() ? expertObrDetails.yearsOfExperience : null,
            aboutExpert: expertObrDetails.aboutExpert?.trim().length > 0 ? expertObrDetails.aboutExpert?.trim() : null,
            facebookLink: facebookLink || null,
            instagramLink: instagramLink || null,
            linkedinLink: linkedinLink || null,
            websiteLink: websiteLink || null,
            youtubeLink: youtubeLink || null,
            videoMessageRelativePath: filePaths.videoMessagePath ? filePaths.videoMessagePath : videoMessagePath ? expertObrDetails.videoMessageRelativePath : null,
            expertDob: expertObrDetails.expertDob || null,
            expertOrganizationDetails: isSkillInstitutePresent ? getExpertOrganizationDetails() : null,
            isInstituteNameUpdated: capturedLeadInstituteName !== expertObrDetails?.expertOrganizationDetails?.organizationName ? AppConstants.yText : AppConstants.nText,
            isWebsiteLinkUpdated: expertObrDetails.websiteLink !== capturedLeadWebsiteLink ? AppConstants.yText : AppConstants.nText,
            galleryMediaPaths: filePaths.creatorMediaGalleryPath || AppConstants.nullText,
          };

          const url = AppConstants.updateObrFormAPI;
          const response = await DataService.post(url, data, AppConstants.emptyString, AppConstants.emptyString);

          if (response && response.status === 200) {
            setDetailsSaved(true);
            setSaveButtonSpinner(false);
            setShowModal(true);
            setModalTypeError(false);
            setModalMessage(SuccessMessage.obrDetailsSavedSuccessFully);
          } else {
            setSaveButtonSpinner(false);
            setReviewButtonSpinner(false);
            setDetailsSaved(false);
            setShowModal(true);
            setModalTypeError(true);
            setModalMessage(ErrorMessages.expertObrDetailsNotSavedError);
          }
        } else {
          setSaveButtonSpinner(false);
          setReviewButtonSpinner(false);
          setDetailsSaved(false);
          setModalTypeError(false);
          setModalMessage(ErrorMessages.expertObrDetailsNotSavedError);
        }
      } catch (error) {
        setSaveButtonSpinner(false);
        setShowModal(true);
        setDetailsSaved(false);
        setModalTypeError(true);
        setModalMessage(ErrorMessages.expertObrDetailsNotSavedError);
      }
    } else {
      setShowModal(true);
      setDetailsSaved(false);
      setModalTypeError(true);
      setModalMessage(formErrorMessage);
    }
  };

  //handle organization country change
  function handleCountryChange(value) {
    if (value.length !== zeroValue) {
      setSelectedCountryOrg(value);
      setSelectedStateOrg([]);
      setSelectedCityOrg([]);
      fetchOrganizationCities([]);
    } else {
      setSelectedCountryOrg([]);
      setSelectedStateOrg([]);
      setSelectedCityOrg([]);
      fetchOrganizationCities([]);
    }
    setDetailsSaved(false);
  }

  //handle organization state change
  function handleStateChange(value) {
    if (value.length !== zeroValue) {
      setSelectedStateOrg([value[zeroValue]]);
      fetchOrganizationCities([value[zeroValue]]);
    } else {
      setSelectedStateOrg([]);
      setSelectedCityOrg([]);
      fetchOrganizationCities([]);
    }
    setDetailsSaved(false);
  }

  function handleCityChange(value) {
    if (value.length !== zeroValue) {
      setSelectedCityOrg([value[zeroValue]]);
    } else {
      setSelectedCityOrg([]);
    }
    setDetailsSaved(false);
  }

  //handle image file change
  const handleImageUpload = (e, fileType) => {
    if (e.target.files[0] !== undefined) {
      const type = e.target.files[0].type;
      setDetailsSaved(false);
      const validImgaeInput = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validImgaeInput.includes(type)) {
        if (fileType === AppConstants.expertObrMicrositeUserImageText) {
          setExpertProfileImageError(ErrorMessages.validImageFormatError);
          setProfileImage(AppConstants.emptyString);
          document.getElementById(AppConstants.profilePicture).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeWebBannerImageText) {
          setExpertWebBannerImageError(ErrorMessages.validImageFormatError);
          setWebBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.webBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeTabletBannerImageText) {
          setExpertTabletBannerImageError(ErrorMessages.validImageFormatError);
          setTabletBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.tabletBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeMobileBannerImageText) {
          setExpertMobileBannerImageError(ErrorMessages.validImageFormatError);
          setMobileBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.mobileBanner).value = null;
        }
        if (fileType === AppConstants.obrVideoMessageThumbnail) {
          setVideoMessageThumbnailError(ErrorMessages.validImageFormatError);
          setVideoMessageThumbnail(AppConstants.emptyString);
          document.getElementById(AppConstants.obrVideoMessageThumbnail).value = null;
        }
      } else if (e.target.files[0]?.size > AppConstants.image5MBSizeLimit) {
        if (fileType === AppConstants.expertObrMicrositeUserImageText) {
          setExpertProfileImageError(ErrorMessages.imageError5MbSize);
          setProfileImage(AppConstants.emptyString);
          document.getElementById(AppConstants.profilePicture).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeWebBannerImageText) {
          setExpertWebBannerImageError(ErrorMessages.imageError5MbSize);
          setWebBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.webBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeTabletBannerImageText) {
          setExpertTabletBannerImageError(ErrorMessages.imageError5MbSize);
          setTabletBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.tabletBanner).value = null;
        }
        if (fileType === AppConstants.expertObrMicrositeMobileBannerImageText) {
          setExpertMobileBannerImageError(ErrorMessages.imageError5MbSize);
          setMobileBannerImage(AppConstants.emptyString);
          document.getElementById(AppConstants.mobileBanner).value = null;
        }
        if (fileType === AppConstants.obrVideoMessageThumbnail) {
          setVideoMessageThumbnailError(ErrorMessages.imageError5MbSize);
          setVideoMessageThumbnail(AppConstants.emptyString);
          document.getElementById(AppConstants.obrVideoMessageThumbnail).value = null;
        }
      } else if (fileType === AppConstants.expertObrMicrositeUserImageText) {
        setProfileImage(e.target.files[0]);
        setUserImagePath(AppConstants.emptyString);
        setExpertProfileImageError(null);
      } else if (fileType === AppConstants.expertObrMicrositeWebBannerImageText) {
        setWebBannerImage(e.target.files[0]);
        setExpertWebBannerImageError(null);
        setWebBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.expertObrMicrositeTabletBannerImageText) {
        setTabletBannerImage(e.target.files[0]);
        setTabletBannerImagePath(AppConstants.emptyString);
        setExpertTabletBannerImageError(null);
      } else if (fileType === AppConstants.expertObrMicrositeMobileBannerImageText) {
        setMobileBannerImage(e.target.files[0]);
        setExpertMobileBannerImageError(null);
        setMobileBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.obrVideoMessageThumbnail) {
        setVideoMessageThumbnail(e.target.files[0]);
        setVideoMessageThumbnailError(null);
        setVideoMessageThumbnailPath(AppConstants.emptyString);
      }
    } else {
      if (fileType === AppConstants.expertObrMicrositeUserImageText) {
        setExpertProfileImageError(null);
        setUserImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.expertObrMicrositeWebBannerImageText) {
        setExpertWebBannerImageError(null);
        setWebBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.expertObrMicrositeTabletBannerImageText) {
        setExpertTabletBannerImageError(null);
        setTabletBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.expertObrMicrositeMobileBannerImageText) {
        setExpertMobileBannerImageError(null);
        setMobileBannerImagePath(AppConstants.emptyString);
      } else if (fileType === AppConstants.obrVideoMessageThumbnail) {
        setVideoMessageThumbnailError(null);
        setVideoMessageThumbnailPath(AppConstants.emptyString);
      }
    }
  };

  //handle video file change
  const handleVideoFileChange = (e) => {
    var fileTypeArray = ['video/mp4', 'video/mkv', 'video/x-m4v', 'video/quicktime', 'video/ai', 'video/ogg', 'video/webm', 'video/x-msvideo'];
    if (e.target.files[0] !== undefined) {
      if (!fileTypeArray.includes(e.target.files[0].type)) {
        document.getElementById(AppConstants.videoMessage).value = null;
        setExpertVideoMessageError(ErrorMessages.expertObrMicrositeVideoFormatError);
      } else {
        setVideoMessage(e.target.files[0]);
        setExpertVideoMessageError(null);
      }
    } else {
      setExpertVideoMessageError(null);
    }
    setDetailsSaved(false);
  };

  const removeSelectedMedia = (media) => {
    let newMediaArray = selectedMediaGallery.filter((value) => value.id !== media.id);
    setSelectedMediaGallery(newMediaArray);
    if (newMediaArray.length === 0) {
      setShowCreatorGallery(AppConstants.falseText);
    }
  };

  const handleGalleryMediaChange = (e) => {
    let selectedFiles = e.target.files;
    let filesArray = [];
    if (selectedFiles && selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i] && validMediaTypes.includes(selectedFiles[i].type)) {
          filesArray.push({
            file: selectedFiles[i],
            url: URL.createObjectURL(selectedFiles[i]),
            type: AppConstants.supportedImageFormats.includes(selectedFiles[i].type) ? 'Image' : 'Video',
            id: Math.floor(Math.random() * 100000 + 1).toString(),
          });
        }
      }

      setSelectedMediaGallery([...selectedMediaGallery, ...filesArray]);
    }
  };

  //handle centre form changes
  const handleCentreFormChange = (idx, event) => {
    let data = [...orgCentreForm];
    data[idx][event.target.name] = event.target.value;
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  async function fetchCentreCities(selectedState, index) {
    let data = [...orgCentreForm];
    if (selectedState === null || selectedState === undefined || selectedState?.length === zeroValue) {
      data[index].cityList = [];
      setOrgCentreForm(data);
    }
    var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
    const response = await fetch(`${AppConstants.getStatesApi}${AppConstants.forwardSlash}${selectedState[zeroValue].iso2}${AppConstants.getCityUri}`, config);
    const responseData = await response.json();
    if (responseData.length !== zeroValue) {
      data[index].cityList = responseData;
      setOrgCentreForm(data);
    }
    return null;
  }

  // handle centre country change
  const handleCentreCountryChange = (idx, value) => {
    let data = [...orgCentreForm];

    if (value.length !== zeroValue) {
      data[idx].centreCountryName = value[zeroValue].country;
      data[idx].centreCountryId = value[zeroValue].id;
      data[idx].centreCountry = value;
      data[idx].centreState = [];
      data[idx].centreStateName = AppConstants.emptyString;
      data[idx].centreCityName = AppConstants.emptyString;
      data[idx].centreCity = [];
      data[idx].cityList = [];
    } else {
      data[idx].centreCountry = [];
      data[idx].centreCountryName = AppConstants.emptyString;
      data[idx].centreCountryId = AppConstants.emptyString;
      data[idx].centreState = [];
      data[idx].centreStateName = AppConstants.emptyString;
      data[idx].centreCityName = AppConstants.emptyString;
      data[idx].centreCity = [];
      data[idx].cityList = [];
    }
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  // handle centre state change (for suggestive search)
  const handleCentreStateChange = async (idx, value) => {
    let data = [...orgCentreForm];
    if (value.length !== zeroValue) {
      data[idx].centreState = value;
      data[idx].centreStateName = value[zeroValue].name;
      fetchCentreCities(value, idx);
    } else {
      data[idx].centreStateName = AppConstants.emptyString;
      data[idx].centreCityName = AppConstants.emptyString;
      data[idx].centreCity = [];
      data[idx].cityList = [];
      data[idx].centreState = [];
    }
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  // handle centre city change (for suggestive search)
  const handleCentreCityChange = (idx, value) => {
    let data = [...orgCentreForm];
    if (value.length !== zeroValue) {
      data[idx].centreCityName = value[zeroValue].name;
      data[idx].centreCity = value;
    } else {
      data[idx].centreCity = [];
      data[idx].centreCityName = AppConstants.emptyString;
    }
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  // add centre form
  const addFields = () => {
    let addCentreForm = {
      uid: DateTimeUtil.fetchCurrentTimestamp(),
      centreName: AppConstants.emptyString,
      centreSpocEmail: AppConstants.emptyString,
      centreSpocPhoneNumber: AppConstants.emptyString,
      centreCountryName: AppConstants.emptyString,
      centreCountry: [],
      centreState: [],
      centreStateName: AppConstants.emptyString,
      centreCity: [],
      centreCityName: AppConstants.emptyString,
      centreAddress: AppConstants.emptyString,
      centreGeoLocation: AppConstants.emptyString,
      centreCountryId: AppConstants.emptyString,
      cityList: [],
    };
    setOrgCentreForm([...orgCentreForm, addCentreForm]);
    setDetailsSaved(false);
  };

  // Method used to remove e/E from the input tag for the mobile number
  const validateMobileNumber = (event) => {
    let excludedKeywords = ['-', 'e', '+', 'E', '.', AppConstants.emptyString];
    if (excludedKeywords.includes(event.key)) event.preventDefault();
  };

  //delete centre form
  const removeFields = (idx) => {
    let data = [...orgCentreForm];
    data.splice(idx, 1);
    setOrgCentreForm(data);
    setDetailsSaved(false);
  };

  const checkValidYearOfExperience = (event) => {
    let excludedKeywords = ['-', 'e', 'E', AppConstants.emptyString];
    if (excludedKeywords.includes(event.key)) event.preventDefault();
  };

  const hanldeGalleryClose = () => {
    setShowCreatorGallery(AppConstants.falseText);
  };

  const viewGallery = () => {
    setShowCreatorGallery(AppConstants.trueText);
  };

  const toggleSkillInstitute = () => {
    setDetailsSaved(false);
    setIsSkillInstitutePresent(!isSkillInstitutePresent);
  };

  const toggleCentreDetails = () => {
    setDetailsSaved(false);
    setIsCentrePresent(!isCentrePresent);
  };

  return (
    <>
      {obrLinkValid && (obrStatus === status.statusDraft || obrStatus === status.statusCreated) && (
        <div className={classes.container}>
          <div className={classes.headingBar}>
            {obrStatus === status.statusCreated && (
              <h5 className="mb-2">
                Welcome{' '}
                <p className={classes.userName}>
                  {expertObrDetails?.expertFirstName}
                  {AppConstants.space}
                  {expertObrDetails?.expertLastName}
                </p>
                ,
              </h5>
            )}
            {obrStatus !== status.statusCreated && (
              <h5 className="mb-2">
                Welcome Back{' '}
                <p className={classes.userName}>
                  {expertObrDetails?.expertFirstName}
                  {AppConstants.space}
                  {expertObrDetails?.expertLastName}
                </p>
                ,
              </h5>
            )}
            {obrStatus === status.statusRejected && <div className={classes.rejectedInfo}>OBR Rejected</div>}
          </div>
          <div className="row">
            <h1 className={classes.formSectionHeading}>Personal Information</h1>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}>First Name</Form.Label>
              <Form.Control name="expertFirstName" value={expertObrDetails.expertFirstName} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={classes.formOBRLabelDisabled}>Last Name</Form.Label>
              <Form.Control name="expertLastName" value={expertObrDetails.expertLastName} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className=" mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}>Email</Form.Label>
              <Form.Control name="expertEmail" value={expertObrDetails.expertEmail} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={classes.formOBRLabelDisabled}>Mobile Number</Form.Label>
              <Form.Control name="mobileNumber" value={expertObrDetails.phoneNumber} onKeyDown={validateMobileNumber} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel} ${classes.formOBRLabelDisabled}`}>Skill</Form.Label>
              {skillLength <= 1 && <Form.Control name="skill" value={expertSkillValue} className={classes.formOBRInput} type="text" disabled />}
              {skillLength > 1 && (
                <span data-tooltip-place="top" data-tooltip-id="expertSkillValues">
                  <Form.Control name="skill" value={expertSkillValue} className={classes.formOBRInput} type="text" disabled />
                </span>
              )}
              {skillLength > 1 && (
                <Tooltip className={classes.tooltipMessageAboutYourself} id="expertSkillValues">
                  <span className={classes.tooltipMessage}>{expertSkillValue}</span>
                </Tooltip>
              )}
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={classes.formOBRLabelDisabled}>Country</Form.Label>
              <Form.Control name="country" value={expertObrDetails.expertCountry} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={classes.formOBRLabelDisabled}>State</Form.Label>
              <Form.Control name="state" value={expertObrDetails.expertState} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={classes.formOBRLabelDisabled}>City</Form.Label>
              <Form.Control name="mobileNumber" value={expertObrDetails.expertCity} className={classes.formOBRInput} type="text" disabled />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Group className={formClass.dateTimeInput}>
                <Form.Label className={classes.formOBRLabelDisabled}>Date Of Birth</Form.Label>
                <Form.Control
                  className={classes.formOBRInput}
                  value={expertObrDetails.expertDob}
                  name="expertDob"
                  type="date"
                  onChange={(e) => {
                    setExpertObrDetails((prevState) => ({ ...prevState, expertDob: e.target.value }));
                  }}
                />
              </Form.Group>
            </div>
            <h1 className={classes.formSectionHeading}>Let's build your personal branding webpage! Here's what you need to do.</h1>
            <div className="mb-2 col-lg-12  col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel}`}>
                About Yourself{' '}
                <span data-tooltip-id="micrositeAboutYourself">
                  <FaInfo className={classes.iButton} />
                </span>
              </Form.Label>
              <Tooltip className={classes.tooltipMessageAboutYourself} id="micrositeAboutYourself">
                <span className={classes.tooltipMessage}>
                  This introduction will be visible to learners on your microsite. Few points to help you craft your introduction: <br></br>• Share your background and experience <br></br>• Give us a brief overview of your skills and interests <br></br>• Tell us about your journey to becoming an expert in your field
                </span>
              </Tooltip>
              <Form.Control
                className={classes.formOBRTextAreaInput}
                name="aboutExpert"
                as="textarea"
                maxLength={2000}
                placeholder="Tell us a little bit about yourself"
                value={expertObrDetails.aboutExpert}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, aboutExpert: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
              {expertObrDetails.aboutExpert?.length > 0 && <span className={classes.remainingChars}>{getRemainingLengthText(2000, expertObrDetails.aboutExpert)}</span>}
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id={AppConstants.profilePicture} name={AppConstants.profilePicture} accept="image/png,image/jpeg,image/jpg" label="Profile Picture" mandatory={true} handleFileChange={(e) => handleImageUpload(e, AppConstants.expertObrMicrositeUserImageText)} infoMessage={AppConstants.obrProfilePictureDimensions} inputFile={profileImage} obrClasses={true} defaultFile={userImagePath && getDataTransfer(extractFileName(userImagePath) || 'Profile_Picture').files[0]} />
                {(userImagePath || profileImage?.size > 0) && (
                  <p
                    onClick={() => {
                      setUserImagePath(AppConstants.emptyString);
                      removeFile(AppConstants.profilePicture);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
                {expertProfileImageError && <p className={classes.errorMessage}>{expertProfileImageError}</p>}
              </div>
              {expertObrDetails.userImageAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.userImageAbsolutePath, AppConstants.profilePicture);
                    setProfileImageSpinner(true);
                  }}
                  data-tooltip-id="downloadProfileImage"
                  className={classes.removeBtn}
                >
                  {profileImageSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadProfileImage">
                <span className={classes.tooltipMessage}>Download Profile Picture</span>
              </Tooltip>
            </div>
            <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={classes.formOBRLabel}>Years Of Experience</Form.Label>
              <Form.Control
                className={classes.formOBRInput}
                placeholder="How many years of experience do you have?"
                name="yearsOfExperience"
                value={expertObrDetails.yearsOfExperience}
                type="number"
                min={0}
                onKeyDown={checkValidYearOfExperience}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, yearsOfExperience: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id={AppConstants.videoMessage} name={AppConstants.videoMessage} accept="video/mp4,video/mkv,video/x-m4v,video/quicktime,video/*" label="Video Message" handleFileChange={(e) => handleVideoFileChange(e)} infoMessage={AppConstants.expertObrFormVideoMessageIButton} inputFile={videoMessage} obrClasses={true} defaultFile={videoMessagePath && getDataTransfer(extractFileName(videoMessagePath) || 'Video_Message').files[0]} />
                {(videoMessage?.size > 0 || videoMessagePath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.videoMessage);
                      setVideoMessagePath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
                {expertVideoMessageError && <p className="errorText">{expertVideoMessageError}</p>}
              </div>
              {expertObrDetails.videoMessageAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.videoMessageAbsolutePath, AppConstants.videoMessage);
                    setVideoMessageSpinner(true);
                  }}
                  data-tooltip-id="downloadVideoMessageInfo"
                  className={classes.removeBtn}
                >
                  {videoMessageSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadVideoMessageInfo">
                <span className={classes.tooltipMessage}>Download Video Message</span>
              </Tooltip>
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id={AppConstants.obrVideoMessageThumbnail} name={AppConstants.obrVideoMessageThumbnail} accept="image/png,image/jpeg,image/jpg" label="Video Message Thumbnail Image" handleFileChange={(e) => handleImageUpload(e, AppConstants.obrVideoMessageThumbnail)} infoMessage={AppConstants.expertObrVideoThumbnailMessageDimension} inputFile={videoMessageThumbnail} obrClasses={true} defaultFile={videoMessageThumbnailPath && getDataTransfer(extractFileName(videoMessageThumbnailPath) || 'VideoMessageThumbnail').files[0]} />
                {(videoMessageThumbnail?.size > 0 || videoMessageThumbnailPath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.obrVideoMessageThumbnail);
                      setVideoMessageThumbnailPath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
                {videoMessageThumbnailError && <p className={classes.errorMessage}>{videoMessageThumbnailError}</p>}
              </div>
              {expertObrDetails.videoMessageThumbnailAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.videoMessageThumbnailAbsolutePath, AppConstants.obrVideoMessageThumbnail);
                    setVideoMessageThumbnailSpinner(true);
                  }}
                  data-tooltip-id="downloadVideoThumbnailInfo"
                  className={classes.removeBtn}
                >
                  {videoMessageThumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadVideoThumbnailInfo">
                <span className={classes.tooltipMessage}>Download Video Message Thumbnail Image</span>
              </Tooltip>
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id={AppConstants.webBanner} name={AppConstants.webBanner} accept="image/png,image/jpeg,image/jpg" label="Web Banner Image" handleFileChange={(e) => handleImageUpload(e, AppConstants.expertObrMicrositeWebBannerImageText)} infoMessage={AppConstants.expertObrFormMicrositeWebBannerMessage} inputFile={webBannerImage} obrClasses={true} defaultFile={webBannerImagePath && getDataTransfer(extractFileName(webBannerImagePath) || 'Web_Banner').files[0]} />

                {(webBannerImage?.size > 0 || webBannerImagePath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.webBanner);
                      setWebBannerImagePath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
                {expertWebBannerImageError && <p className={classes.errorMessage}>{expertWebBannerImageError}</p>}
              </div>
              {expertObrDetails.webBannerImageAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.webBannerImageAbsolutePath, AppConstants.webBanner);
                    setWebBannerImageSpinner(true);
                  }}
                  data-tooltip-id="downloadWebBannerInfo"
                  className={classes.removeBtn}
                >
                  {webBannerImageSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadWebBannerInfo">
                <span className={classes.tooltipMessage}>Download Web Banner Image</span>
              </Tooltip>
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id={AppConstants.tabletBanner} name={AppConstants.tabletBanner} accept="image/png,image/jpeg,image/jpg" label="Tablet Banner Image" handleFileChange={(e) => handleImageUpload(e, AppConstants.expertObrMicrositeTabletBannerImageText)} infoMessage={AppConstants.expertObrFormMicrositeTabletBannerMessage} inputFile={tabletBannerImage} obrClasses={true} defaultFile={tabletBannerImagePath && getDataTransfer(extractFileName(tabletBannerImagePath) || 'Tablet_Banner').files[0]} />
                {(tabletBannerImage?.size > 0 || tabletBannerImagePath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.tabletBanner);
                      setTabletBannerImagePath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
                {expertTabletBannerImageError && <p className={classes.errorMessage}>{expertTabletBannerImageError}</p>}
              </div>
              {expertObrDetails.tabletBannerImageAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.tabletBannerImageAbsolutePath, AppConstants.tabletBanner);
                    setTabletBannerImageSpinner(true);
                  }}
                  data-tooltip-id="downloadTabletBanner"
                  className={classes.removeBtn}
                >
                  {tabletBannerImageSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadTabletBanner">
                <span className={classes.tooltipMessage}>Download Tablet Banner Image</span>
              </Tooltip>
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id={AppConstants.mobileBanner} name={AppConstants.mobileBanner} accept="image/png,image/jpeg,image/jpg" label="Mobile Banner Image" handleFileChange={(e) => handleImageUpload(e, AppConstants.expertObrMicrositeMobileBannerImageText)} infoMessage={AppConstants.expertObrFormMicrositeMobileBannerMessage} inputFile={mobileBannerImage} obrClasses={true} defaultFile={mobileBannerImagePath && getDataTransfer(extractFileName(mobileBannerImagePath) || 'Mobile_Banner').files[0]} />

                {(mobileBannerImage?.size > 0 || mobileBannerImagePath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.mobileBanner);
                      setMobileBannerImagePath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
                {expertMobileBannerImageError && <p className={classes.errorMessage}>{expertMobileBannerImageError}</p>}
              </div>
              {expertObrDetails.mobileBannerImageAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.mobileBannerImageAbsolutePath, AppConstants.mobileBanner);
                    setMobileBannerImageSpinner(true);
                  }}
                  data-tooltip-id="downloadMobileBanner"
                  className={classes.removeBtn}
                >
                  {mobileBannerImageSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadMobileBanner">
                <span className={classes.tooltipMessage}>Download Mobile Banner Image</span>
              </Tooltip>
            </div>

            <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel}`}>Website</Form.Label>
              <Form.Control
                className={classes.formOBRInput}
                type="url"
                placeholder="If you have a personal website, enter its URL"
                name="websiteLink"
                value={expertObrDetails.websiteLink}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, websiteLink: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
            </div>
            <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel}`}>LinkedIn</Form.Label>
              <Form.Control
                className={classes.formOBRInput}
                type="url"
                placeholder="Enter your LinkedIn profile URL"
                name="linkedinLink"
                value={expertObrDetails.linkedinLink}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, linkedinLink: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
            </div>
            <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel}`}>Facebook</Form.Label>
              <Form.Control
                className={classes.formOBRInput}
                type="url"
                placeholder="Enter your Facebook profile URL"
                name="facebookLink"
                value={expertObrDetails.facebookLink}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, facebookLink: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
            </div>

            <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel}`}>Instagram</Form.Label>
              <Form.Control
                className={classes.formOBRInput}
                type="url"
                placeholder="Enter your Instagram handle URL"
                name="instagramLink"
                value={expertObrDetails.instagramLink}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, instagramLink: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
            </div>
            <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
              <Form.Label className={`${classes.formOBRLabel}`}>Youtube</Form.Label>
              <Form.Control
                className={classes.formOBRInput}
                type="url"
                placeholder="Enter your YouTube channel URL"
                name="youtubeLink"
                value={expertObrDetails.youtubeLink}
                onChange={(e) => {
                  setExpertObrDetails((prevState) => ({ ...prevState, youtubeLink: e.target.value }));
                  setDetailsSaved(false);
                }}
              />
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput id="creatorMediaGallery" name="creatorMediaGallery" accept="image/png,image/jpeg,image/jpg,video/mp4,video/mkv,video/x-m4v,video/quicktime,video/*" label="Your Spotlight" handleFileChange={handleGalleryMediaChange} infoMessage={AppConstants.obrGalleryInfoIconText} inputFile={selectedMediaGallery} obrClasses={true} placeholder={'Select images/videos for your spotlight'} multiple={true} />
                {selectedMediaGallery.length > 0 && (
                  <p
                    onClick={() => {
                      setSelectedMediaGallery([]);
                    }}
                    className="removeFileText"
                  >
                    Remove files
                  </p>
                )}
              </div>
              {selectedMediaGallery.length > 0 && (
                <button type="button" onClick={viewGallery} data-tooltip-id="viewGallery" className={classes.removeBtn}>
                  <FaRegEye />
                </button>
              )}
              <div className={classes.galleryTooltip}>
                <Tooltip className={classes.tooltipMessageDownload} id="viewGallery">
                  <span className={classes.tooltipMessage}>View</span>
                </Tooltip>
              </div>
            </div>
            <div className={classes.checkboxContainer}>
              <div className={classes.checkboxText}>
                Do you have a Skill Institute?{' '}
                <span>
                  <input type="checkbox" checked={isSkillInstitutePresent} onChange={toggleSkillInstitute} />
                </span>
              </div>
            </div>
            {isSkillInstitutePresent && (
              <>
                <h1 className={classes.formSectionHeading}>Tell us about your Skill Institute, so we can better understand what you offer</h1>
                <Tooltip className={classes.tooltipMessageDownload} id="organizationInfoHeadingId">
                  <span className={classes.tooltipMessage}>{AppConstants.expertObrFormMicrositeIbutton}</span>
                </Tooltip>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Name<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    name="organizationName"
                    placeholder="What is the name of your institute?"
                    value={expertObrDetails.expertOrganizationDetails?.organizationName}
                    className={classes.formOBRInput}
                    type="text"
                    minLength={3}
                    maxLength={65}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationName: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Designation<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    name="organizationDesignation"
                    placeholder="What is your role/designation at the institute?"
                    value={expertObrDetails.expertOrganizationDetails?.organizationDesignation}
                    className={classes.formOBRInput}
                    type="text"
                    minLength={3}
                    maxLength={40}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationDesignation: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Email<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    name="expertOrganizationEmail"
                    placeholder="Enter your work email address"
                    value={expertObrDetails.expertOrganizationDetails?.expertOrganizationEmail}
                    className={classes.formOBRInput}
                    type="text"
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, expertOrganizationEmail: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={classes.formOBRLabel}>
                    Mobile Number<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    onKeyDown={validateMobileNumber}
                    name="expertOrganizationContactNumber"
                    placeholder="Enter your work mobile number"
                    value={expertObrDetails.expertOrganizationDetails?.expertOrganizationContactNumber}
                    className={classes.formOBRInput}
                    type="number"
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, expertOrganizationContactNumber: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Country<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Typeahead className={`${classes.obrInputontainer} adminCreateObrTypeahead`} name="organizationCountry" id="organizationCountry" placeholder="In which country is this institute located?" labelKey="country" onChange={(e) => handleCountryChange(e)} options={countryList} selected={selectedCountryOrg} />
                </div>
                {selectedCountryOrg !== AppConstants.nullText && selectedCountryOrg !== AppConstants.emptyString && selectedCountryOrg !== undefined && selectedCountryOrg.length > 0 && selectedCountryOrg[0]?.country === AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      State<span className="redAstrisk">{AppConstants.asterisk}</span>
                    </Form.Label>
                    <Typeahead className={`${classes.obrInputontainer} adminCreateObrTypeahead`} name="organizationState" id="organizationState" placeholder="In which state is this institute located?" labelKey="name" onChange={(e) => handleStateChange(e)} options={stateList} selected={selectedStateOrg} />
                  </div>
                )}
                {selectedCountryOrg !== AppConstants.nullText && selectedCountryOrg !== AppConstants.emptyString && selectedCountryOrg !== undefined && selectedCountryOrg.length > 0 && selectedCountryOrg[0]?.country !== AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>State</Form.Label>
                    <Form.Control
                      type="text"
                      className={classes.formOBRInput}
                      placeholder="In which state is this institute located?"
                      name="organizationNotIndiaState"
                      id="organizationNotIndiaState"
                      value={selectedStateOrg}
                      onChange={(e) => {
                        setSelectedStateOrg(e.target.value);
                      }}
                    />
                  </div>
                )}
                {selectedCountryOrg !== AppConstants.nullText && selectedCountryOrg !== AppConstants.emptyString && selectedCountryOrg.length > 0 && selectedCountryOrg !== undefined && selectedCountryOrg[0]?.country == AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      City<span className="redAstrisk">{AppConstants.asterisk}</span>
                    </Form.Label>
                    <Typeahead className={`${classes.obrInputontainer} adminCreateObrTypeahead`} name="organizationCity" id="organizationCity" placeholder="In which city is this institute located?" labelKey="name" onChange={(e) => handleCityChange(e)} options={organizationCityList} selected={selectedCityOrg} />
                  </div>
                )}
                {selectedCountryOrg !== AppConstants.nullText && selectedCountryOrg !== AppConstants.emptyString && selectedCountryOrg.length > 0 && selectedCountryOrg !== undefined && selectedCountryOrg[0]?.country !== AppConstants.countryIndia && (
                  <div className="mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Form.Label className={`${classes.formOBRLabel}`}>
                      City<span className="redAstrisk">{AppConstants.asterisk}</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={classes.formOBRInput}
                      placeholder="In which city is this institute located?"
                      name="organizationNotIndiaCity"
                      id="organizationNotIndiaCity"
                      value={selectedCityOrg}
                      onChange={(e) => {
                        setSelectedCityOrg(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Address<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="text"
                    placeholder="Enter the complete address of the institute"
                    name="organizationAddress"
                    value={expertObrDetails.expertOrganizationDetails?.organizationAddress}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationAddress: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Institute Geo Location<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="url"
                    placeholder="Enter your institute's Google Maps location URL"
                    name="organizationGeoLocation"
                    value={expertObrDetails.expertOrganizationDetails?.organizationGeoLocation}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationGeoLocation: e.target.value } }));
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>Website</Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="url"
                    placeholder="Enter your institute's Website URL"
                    name="websiteLink"
                    value={expertObrDetails.expertOrganizationDetails?.organizationWebsiteLink}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationWebsiteLink: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>LinkedIn</Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="url"
                    placeholder="Enter your institute's LinkedIn page URL"
                    name="linkedinLink"
                    value={expertObrDetails.expertOrganizationDetails?.organizationLinkedinLink}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationLinkedinLink: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>Facebook</Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="url"
                    placeholder="Enter your institute's Facebook page URL"
                    name="facebookLink"
                    value={expertObrDetails.expertOrganizationDetails?.organizationFacebookLink}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationFacebookLink: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>Instagram</Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="url"
                    placeholder="Enter your institute's Instagram handle URL"
                    name="instagramLink"
                    value={expertObrDetails.expertOrganizationDetails?.organizationInstagramLink}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationInstagramLink: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>Youtube</Form.Label>
                  <Form.Control
                    className={classes.formOBRInput}
                    type="url"
                    placeholder="Enter your institute's YouTube channel URL"
                    name="youtubeLink"
                    value={expertObrDetails.expertOrganizationDetails?.organizationYoutubeLink}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationYoutubeLink: e.target.value } }));
                    }}
                  />
                </div>
                <div className="mb-2 col-lg-12  col-sm-12 col-xs-12">
                  <Form.Label className={`${classes.formOBRLabel}`}>
                    Institute Description<span className="redAstrisk">{AppConstants.asterisk}</span>
                  </Form.Label>
                  <Form.Control
                    className={classes.formOBRTextAreaInput}
                    name="organizationDescription"
                    as="textarea"
                    maxLength={2000}
                    placeholder="Briefly describe about your institute and its offerings"
                    value={expertObrDetails.expertOrganizationDetails?.organizationDescription}
                    onChange={(e) => {
                      setExpertObrDetails((prevState) => ({ ...prevState, expertOrganizationDetails: { ...prevState.expertOrganizationDetails, organizationDescription: e.target.value } }));
                      setDetailsSaved(false);
                    }}
                  />
                  {expertObrDetails?.expertOrganizationDetails?.organizationDescription && <span className={classes.remainingChars}>{getRemainingLengthText(2000, expertObrDetails?.expertOrganizationDetails?.organizationDescription)}</span>}
                </div>
              </>
            )}
          </div>
          <Row>
            {isSkillInstitutePresent && (
              <div className={classes.checkboxContainer}>
                <div className={classes.checkboxText}>
                  Do you have any Skill Institute Centres?{' '}
                  <span>
                    <input type="checkbox" checked={isCentrePresent} onChange={toggleCentreDetails} />
                  </span>
                </div>
              </div>
            )}
          </Row>

          {isSkillInstitutePresent && isCentrePresent && (
            <div>
              <div className="row">
                <h1 className={classes.formSectionHeading}>Please provide skill institute centre details below.</h1>
              </div>
              {orgCentreForm.map((input, index) => {
                return (
                  <div key={input.uid} className="row">
                    {index !== 0 && <hr />}
                    <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                      <Form.Label className={`${classes.formOBRLabel}`}>
                        Name<span className="redAstrisk">{AppConstants.asterisk}</span>
                      </Form.Label>
                      <Form.Control className={classes.formOBRInput} type="text" placeholder="Enter name of the centre within your institute" id="centreName" name={`centreName`} value={input.centreName} onChange={(event) => handleCentreFormChange(index, event)} />
                    </div>
                    <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                      <Form.Label className={`${classes.formOBRLabel}`}>
                        SPOC Email
                        <span data-tooltip-id="spocEmailAddress">
                          <span className="redAstrisk">{AppConstants.asterisk}</span>
                          <FaInfo className={classes.iButton} />
                        </span>
                      </Form.Label>
                      <Tooltip className={classes.tooltipMessageDownload} id="spocEmailAddress">
                        <span className={classes.tooltipMessage}>{AppConstants.spocEmailInformation}</span>
                      </Tooltip>
                      <Form.Control className={classes.formOBRInput} type="email" placeholder="Enter the SPOC email for this center" name="centreSpocEmail" value={input.centreSpocEmail} onChange={(event) => handleCentreFormChange(index, event)} />
                    </div>
                    <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                      <Form.Label className={`${classes.formOBRLabel}`}>
                        SPOC Mobile Number<span className="redAstrisk">{AppConstants.asterisk}</span>
                      </Form.Label>
                      <Form.Control className={classes.formOBRInput} type="number" onKeyDown={validateMobileNumber} placeholder="Enter the SPOC mobile number for this centre" name="centreSpocPhoneNumber" value={input.centreSpocPhoneNumber} onChange={(event) => handleCentreFormChange(index, event)} />
                    </div>
                    <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                      <Form.Label className={`${classes.formOBRLabel}`}>
                        Country<span className="redAstrisk">{AppConstants.asterisk}</span>
                      </Form.Label>
                      <Typeahead className={`${classes.obrInputontainer} adminCreateObrTypeahead`} name="centreCountryName" id="centreCountryName" placeholder="In which country is this centre located?" labelKey="country" onChange={(value) => handleCentreCountryChange(index, value)} options={countryList} selected={input.centreCountry} />
                    </div>
                    {input.centreCountryName !== null && input.centreCountryName !== AppConstants.emptyString && input.centreCountryName !== undefined && (
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        {input.centreCountryName === AppConstants.countryIndia && (
                          <Form.Label className={`${classes.formOBRLabel}`}>
                            Centre State<span className="redAstrisk">{AppConstants.asterisk}</span>
                          </Form.Label>
                        )}
                        {input.centreCountryName === AppConstants.countryIndia && <Typeahead className={`${classes.obrInputontainer} adminCreateObrTypeahead`} name="centreState" id="centreState" placeholder="In which state is this centre located" labelKey="name" onChange={(value) => handleCentreStateChange(index, value)} options={stateList} selected={input.centreState} />}
                        {input.centreCountryName !== AppConstants.countryIndia && <Form.Label className={`${classes.formOBRLabel}`}>Centre State</Form.Label>}
                        {input.centreCountryName !== AppConstants.countryIndia && <Form.Control className={classes.formOBRInput} type="text" placeholder="In which state is this centre located" name="centreStateName" value={input.centreStateName} onChange={(event) => handleCentreFormChange(index, event)} />}
                      </div>
                    )}
                    {input.centreCountryName !== null && input.centreCountryName !== AppConstants.emptyString && input.centreCountryName !== undefined && (
                      <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        {input.centreCountryName === AppConstants.countryIndia && (
                          <Form.Label className={`${classes.formOBRLabel}`}>
                            Centre City<span className="redAstrisk">{AppConstants.asterisk}</span>
                          </Form.Label>
                        )}
                        {input.centreCountryName === AppConstants.countryIndia && <Typeahead className={`${classes.obrInputontainer} adminCreateObrTypeahead`} name="centreCity" id="centreCity" placeholder="In which city is this centre located?" labelKey="name" onChange={(value) => handleCentreCityChange(index, value)} options={input.cityList} selected={input.centreCity} />}
                        {input.centreCountryName !== AppConstants.countryIndia && (
                          <Form.Label className={`${classes.formOBRLabel}`}>
                            Centre City<span className="redAstrisk">{AppConstants.asterisk}</span>
                          </Form.Label>
                        )}
                        {input.centreCountryName !== AppConstants.countryIndia && <Form.Control className={classes.formOBRInput} type="text" placeholder="In which city is this centre located?" name="centreCityName" value={input.centreCityName} onChange={(event) => handleCentreFormChange(index, event)} />}
                      </div>
                    )}
                    <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                      <Form.Label className={`${classes.formOBRLabel}`}>
                        Centre Address<span className="redAstrisk">{AppConstants.asterisk}</span>
                      </Form.Label>
                      <Form.Control className={classes.formOBRInput} type="text" placeholder="Enter the complete address of this centre" name="centreAddress" value={input.centreAddress} onChange={(event) => handleCentreFormChange(index, event)} />
                    </div>
                    <div className="mb-2 col-lg-4  col-md-6 col-sm-12 col-xs-12">
                      <Form.Label className={`${classes.formOBRLabel}`}>
                        Centre Geolocation<span className="redAstrisk">{AppConstants.asterisk}</span>
                      </Form.Label>
                      <Form.Control className={classes.formOBRInput} type="url" placeholder="Enter this centre Google Maps location URL" name="centreGeoLocation" value={input.centreGeoLocation} onChange={(event) => handleCentreFormChange(index, event)} />
                    </div>
                    <div>
                      {orgCentreForm.length > 1 && (
                        <Button className={classes.addCentreFormButton} onClick={() => removeFields(index)}>
                          Remove
                        </Button>
                      )}
                      {index === orgCentreForm.length - 1 && (
                        <Button className={classes.addCentreFormButton} onClick={addFields}>
                          Add Centre
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="row">
            <h1 className={classes.formSectionHeading}>To verify you as an expert on Qrencia, we require your personal information. Rest assured, your data will be handled with utmost confidentiality.</h1>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload} ${classes.fileOBRUploadInput}`}>
                <FileUploadInput
                  id={AppConstants.skillDocumentText}
                  name={AppConstants.skillDocumentText}
                  label="Qualification"
                  mandatory={true}
                  handleFileChange={(e) => {
                    setSkillDocument(e.target.files[0]);
                    setDetailsSaved(false);
                    setSkillDocumentPath(AppConstants.emptyString);
                  }}
                  infoMessage={`${AppConstants.skillDocumentInfoIcon} ${expertSkillValue}${AppConstants.multipleFilesMessage}`}
                  inputFile={skillDocument}
                  obrClasses={true}
                  defaultFile={skillDocumentPath && getDataTransfer(extractFileName(skillDocumentPath) || 'Skill_Document').files[0]}
                />

                {(skillDocument?.size > 0 || skillDocumentPath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.skillDocumentText);
                      setSkillDocumentPath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
              </div>
              {expertObrDetails.skillDocumentAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.skillDocumentAbsolutePath, AppConstants.skillDocumentText);
                    setSkillDocumentSpinner(true);
                  }}
                  data-tooltip-id="downloadSkillDocument"
                  className={classes.removeBtn}
                >
                  {skillDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadSkillDocument">
                <span className={classes.tooltipMessage}>Download Skill Document</span>
              </Tooltip>
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload}  ${classes.fileOBRUploadInput}`}>
                <FileUploadInput
                  id={AppConstants.idProofText}
                  name={AppConstants.idProofText}
                  label="Id Proof"
                  mandatory={true}
                  handleFileChange={(e) => {
                    setIdProof(e.target.files[0]);
                    setDetailsSaved(false);
                    setIdProofPath(AppConstants.emptyString);
                  }}
                  infoMessage={AppConstants.idProofInfoIcon}
                  inputFile={idProof}
                  obrClasses={true}
                  defaultFile={idProofPath && getDataTransfer(extractFileName(idProofPath) || 'Id_Proof').files[0]}
                />

                {(idProof?.size > 0 || idProofPath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.idProofText);
                      setIdProofPath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
              </div>
              {expertObrDetails.identityProofAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.identityProofAbsolutePath, AppConstants.idProofText);
                    setIdProofSpinner(true);
                  }}
                  data-tooltip-id="downloadIdProof"
                  className={classes.removeBtn}
                >
                  {idProofSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadIdProof">
                <span className={classes.tooltipMessage}>Download Id Proof</span>
              </Tooltip>
            </div>
            <div className={`${classes.fileOBRUploadSection} mb-2 col-lg-4 col-md-6 col-sm-12 col-xs-12`}>
              <div className={`${classes.fileOBRUpload}  ${classes.fileOBRUploadInput}`}>
                <FileUploadInput
                  id={AppConstants.addressProofText}
                  name={AppConstants.addressProofText}
                  label="Address Proof"
                  mandatory={true}
                  handleFileChange={(e) => {
                    setAddressProof(e.target.files[0]);
                    setDetailsSaved(false);
                    setAddressProofPath(AppConstants.emptyString);
                  }}
                  infoMessage={AppConstants.addressProofInfoIcon}
                  inputFile={addressProof}
                  obrClasses={true}
                  defaultFile={addressProofPath && getDataTransfer(extractFileName(addressProofPath) || 'Address_Proof').files[0]}
                />
                {(addressProof?.size > 0 || addressProofPath) && (
                  <p
                    onClick={() => {
                      removeFile(AppConstants.addressProofText);
                      setAddressProofPath(AppConstants.emptyString);
                    }}
                    className="removeFileText"
                  >
                    Remove file
                  </p>
                )}
              </div>
              {expertObrDetails.addressProofAbsolutePath && (
                <button
                  type="button"
                  onClick={() => {
                    downloadFile(expertObrDetails.addressProofAbsolutePath, AppConstants.addressProofText);
                    setAddressProofSpinner(true);
                  }}
                  data-tooltip-id="downloadAddressProof"
                  className={classes.removeBtn}
                >
                  {addressProofSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}
                </button>
              )}
              <Tooltip className={classes.tooltipMessageDownload} id="downloadAddressProof">
                <span className={classes.tooltipMessage}>Download Address Proof</span>
              </Tooltip>
            </div>
            {expertObrDetails.comment && (
              <div className={classes.feedbackComponent}>
                <div className={classes.adminFeedbackHeading}>Admin Feedback:</div>
                <div className={classes.feedbackComment}>{parse(expertObrDetails.comment)}</div>
              </div>
            )}
            {obrStatus !== status.statusRejected && (
              <div className={classes.switchContainer}>
                <div className={classes.switchText}>
                  <input type="checkbox" onChange={(e) => changeTncCheck(e)} />
                  <p className={classes.tncText}>
                    By selecting this, you agree to Qrencia{' '}
                    <NavLink target="_blank" className={classes.tncTextHyper} to={AppConstants.termsAndConditions}>
                      Terms & Conditions
                    </NavLink>
                    &nbsp; and &nbsp;
                    <NavLink target="_blank" className={classes.tncTextHyper} to={AppConstants.privacyPolicy}>
                      Privacy Policy
                    </NavLink>
                  </p>
                </div>
              </div>
            )}
            <div className={classes.buttonSection}>
              <Button className={classes.saveButton} disabled={saveButtonSpinner || reviewButtonSpinner || obrStatus === status.statusRejected} onClick={saveObrDetails}>
                {saveButtonSpinner ? <BiLoaderAlt className="spinner" /> : 'Save as draft'}
              </Button>
              {
                <Button className={classes.reviewButton} disabled={!tncCheck || saveButtonSpinner || obrStatus === status.statusRejected || reviewButtonSpinner} onClick={sendObrDetailsForReview}>
                  {reviewButtonSpinner ? <BiLoaderAlt className="spinner" /> : 'Submit for review'}
                </Button>
              }
            </div>
          </div>
        </div>
      )}
      {obrLinkValid && obrStatus === status.statusInReview && (
        <div className={classes.statusContainer}>
          <p className="noDataAvailableError">{ErrorMessages.obrRequestInReviewMessage}</p>
        </div>
      )}
      {obrLinkValid && obrStatus === status.statusAccepted && (
        <div className={classes.statusContainer}>
          <p className="noDataAvailableError">
            Your Onboarding is approved, kindly{' '}
            <NavLink target="_self" className={classes.tncTextHyper} to={resetPasswordLink}>
              reset your password
            </NavLink>{' '}
            to login to Qrencia
          </p>
        </div>
      )}
      {obrLinkValid && obrStatus === status.statusRejected && (
        <div className={classes.statusContainer}>
          {expertObrDetails.comment === null && <p className="noDataAvailableError">Onboarding Application Rejected</p>}
          {expertObrDetails.comment && (
            <p className="obrRejectedError">
              <div className={classes.boldText}>Onboarding Application Rejected</div>
              <br></br>
              Dear {expertObrDetails.expertFirstName},<br></br>
              <br></br>
              We regret to inform you that your onboarding application as an expert has been rejected by our admin team.<br></br>
              <br></br>
              <strong className={classes.boldText}>Reason:</strong>
              <div className={classes.feedbackText}>{parse(expertObrDetails.comment)}</div> <br></br>
              If you have any queries or need further clarification, please write to us at <a href="mailto:support@qrencia.com">support@qrencia.com</a>, and we will respond within 2 hours.<br></br>
              <br></br>
              Thank you for expressing your interest.
            </p>
          )}
        </div>
      )}
      {showPageLoader ? (
        <div className={classes.statusContainer}>
          <p className="noDataAvailableError">
            <BiLoaderAlt className={`spinner ${classes.pageLoader}`} />
          </p>
        </div>
      ) : (
        (!obrLinkValid || obrStatus === status.statusDeclined || obrStatus === AppConstants.emptyString) &&
        !showPageLoader && (
          <div className={classes.statusContainer}>
            <p className="noDataAvailableError">{ErrorMessages.obrLinkExpiredMessage}</p>
          </div>
        )
      )}
      {showCreatorGallery && <ImageGalleryModal creatorGalleryContent={selectedMediaGallery} showDeleteIcon={true} showDownloadIcon={true} hanldeGalleryClose={hanldeGalleryClose} removeSelectedMedia={removeSelectedMedia} />}
      <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
        <Modal.Body className={modalClass.modalBody}>
          {modalTypeError === AppConstants.falseText && (
            <div className={modalClass.modalSuccessIcon}>
              <AiOutlineCheckCircle />
            </div>
          )}
          {modalTypeError === AppConstants.trueText && (
            <div className={modalClass.modalErrorIcon}>
              <AiOutlineExclamationCircle />
            </div>
          )}
          <div className={modalClass.modalAlertText}>{modalMessage}</div>
        </Modal.Body>
        <Modal.Footer className={modalClass.modalFooter}>
          <Button className={modalClass.modalCloseButton} onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
