import React from "react";
import { SkillProgressCreateTemplateTabs } from "../../component/skillProgressAssessmentComponents/SkillProgressCreateTemplateTabs";

export const SkillProgressReportsPage = () => {
  return (
    <div className="expertContentSection">
      <div className="containerHeadings">Manage Templates</div>
      <SkillProgressCreateTemplateTabs />
    </div>
  );
};