import { useEffect, useState } from 'react';
import { AppConstants, AWARDS_CONSTANTS } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from './StudentHomePage.module.css';
import { StudentHomeRecentlyPublishedLearningActivities } from '../../component/studentPageComponents/StudentHomeRecentlyPublishedLearningActivities';
import { StudentHomeFeaturedLearningActivities } from '../../component/studentPageComponents/StudentHomeFeaturedLearningActivities';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { StudentHomeTodaysActivities } from '../../component/studentPageComponents/StudentHomeTodaysActivities';
import { DataService } from '../../services/DataService';
import Auth from '../../utils/Auth';
import { Button, Modal } from 'react-bootstrap';
import useLearnerPassPopupStore from '../../stores/learnerPassPopupStore';
import {
  useLearnerPassPaymentPopupStore,
  useLearnerPassStore,
} from '../../stores';
import LearnerPassAd from '../../component/subscription/LearnerPass/LearnerPassAd';
import {
  calculateNumberOfRemainingDays,
  getNumberOfDaysUntilLearnerPassExpiry,
} from '../../utils/calculateNumberOfRemainingDays';
import LearnerPassReminder from '../../component/subscription/LearnerPass/LearnerPassExpired';
import LearnerPassExpired from '../../component/subscription/LearnerPass/LearnerPassExpired';
import LearnerPassRenewReminder from '../../component/subscription/LearnerPass/LearnerPassRenewReminder';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

export const StudentHomePage = () => {
  const {
    showLearnerPassAd,
    setShowLearnerPassAd,
    showLearnerPassRenewalReminder,
    showLearnerPassReminder,
    setShowLearnerPassReminder,
    setHasLearnerPassExpired,
    showUpdateProfileDialogBox,
    setShowUpdateProfileDialogBox,
    setShowLearnerPassRenewalReminder,
    setIsFirstTimeUser,
    hasPurchasedLearnerPass,
    showSubscriptionModal,
    isFirstTimeUser,
    hasLearnerPassExpired,
  } = useLearnerPassPopupStore();
  const [featuredLearningActivitiesData, setFeaturedLearningActivitiesData] =
    useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [
    recentlyPublishedLearningActivitiesData,
    setRecentlyPublishedLearningActivitiesData,
  ] = useState([]);
  const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const [minimumReviewCount, setMinimumReviewCount] = useState(
    AppConstants.emptyString
  );
  const [nudgeAdImage, setNudgeAdImage] = useState({});
  const [skipsAvailable, setSkipsAvailable] = useState();
  const [profileCompletion, setProfileCompletion] = useState(
    AppConstants.falseFlag
  );
  const [minimumAgeLimit, setMinimumAgeLimit] = useState(
    AppConstants.emptyString
  );
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const defaultProfilePicture = `${mediaContent}${AppConstants.profileImageS3Path}`;
  // const [updateProfileDialogBox, setUpdateProfileDialog] = useState(
  //   AppConstants.trueText
  // );
  const [updateProfileDialogBox, setUpdateProfileDialog] = useState(false);
  const backdropElementMobile = `${mediaContent}${AppConstants.studentProfileBackgroundMobile}`;
  const mobileImage = `${mediaContent}${AppConstants.mobileHomePageAdvertisementImage}`;
  const [championshipData, setChampionshipData] = useState([]);
  const [championshipLoading, setChampionshipLoading] = useState(true);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [learnerSlug, setLearnerSlug] = useState('');

  const {
    learnerPassExpiryDate,
    setLearnerPassExpiryDate,
    wantsToBuyLearnerPass,
    learnerPassSubscriptionStatus,
    setWantsToBuyLearnerPass,
  } = useLearnerPassStore();

  const { setShowLearnerPassPaymentPopup } = useLearnerPassPaymentPopupStore();

  useEffect(() => {
    setShowUpdateProfileDialogBox();
  }, [showLearnerPassAd, setShowUpdateProfileDialogBox]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imagePath =
    screenWidth < 525 ? mobileImage : nudgeAdImage.bannerImage?.imagePath;

  useEffect(() => {
    async function fetchFeaturedLearningActivitiesData() {
      const url = `${AppConstants.studentFeaturedLearningActivitiesAPI}${preferredTimeZone}`;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setFeaturedLearningActivitiesData(response);
      return null;
    }
    fetchFeaturedLearningActivitiesData();

    if (!wantsToBuyLearnerPass) {
      // openUpdateProfileDialog();
    }
    // openUpdateProfileDialog();

    async function fetchRecentlyPublishedLearningActivitiesData() {
      const url = `${AppConstants.studentRecentlyPublishedLearningActivitiesAPI}${preferredTimeZone}`;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setRecentlyPublishedLearningActivitiesData(response);
      return null;
    }
    fetchRecentlyPublishedLearningActivitiesData();

    async function fetchNudgeAdvertisementData() {
      const url = `${AppConstants.pageSpecificContentAPI}${AppConstants.studentHomePageName}`;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setNudgeAdImage(response);
    }
    fetchNudgeAdvertisementData();

    const fetchConfigurableKeys = async () => {
      const url = AppConstants.fetchConfigurableKeysAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response) {
        setMinimumReviewCount(
          parseInt(
            response.find(
              (item) =>
                item.keyName === AppConstants.minimumCourseReviewsCardDisplay
            ).keyValue
          )
        );
        setMinimumAgeLimit(
          parseInt(
            response.find(
              (item) => item.keyName === AppConstants.signupMinimumAgeKey
            ).keyValue
          )
        );
      }
    };

    fetchConfigurableKeys();

    async function fetchUserInfo() {
      const url =
        AppConstants.fetchLoggedInStudentInfoAPI +
        Auth.getLoggedInUserDetails().userId;
      const response = await AuthorizedDataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      const loggedUserInfo = response;
      setSkipsAvailable(loggedUserInfo.profileCompletionSkips);
      response.profileCompletionFlag === AppConstants.yText
        ? setProfileCompletion(true)
        : setProfileCompletion(false);
      setLearnerSlug(response.learnerSlug);
    }
    fetchUserInfo();
  }, []);

  useEffect(() => {
    const numberOfDaysRemaining = getNumberOfDaysUntilLearnerPassExpiry(
      learnerPassExpiryDate
    );

    if (numberOfDaysRemaining === 0) {
      setShowLearnerPassReminder(true);
      setHasLearnerPassExpired(true);
      setShowLearnerPassAd(false);
    }
  }, []);

  useEffect(() => {
    // if (isFirstTimeUser) {
    //   setShowLearnerPassAd(true);
    // } else {
    //   if (!hasPurchasedLearnerPass) {
    //     // return null;
    //     setShowLearnerPassAd(true);
    //   } else {
    //     if (hasLearnerPassExpired) {
    //       setShowLearnerPassReminder(true);
    //     } else {
    //       if (
    //         getNumberOfDaysUntilLearnerPassExpiry(learnerPassExpiryDate) < 3
    //       ) {
    //         setShowLearnerPassAd(true);
    //       } else {

    //       }
    //     }
    //   }
    // }
    if (learnerPassExpiryDate === null && !wantsToBuyLearnerPass) {
      setShowLearnerPassAd(true);
    } else if (learnerPassExpiryDate !== null) {
      let numberOfDaysRemaining = getNumberOfDaysUntilLearnerPassExpiry(
        learnerPassExpiryDate
      );
      if (numberOfDaysRemaining === 0) {
        setShowLearnerPassReminder(true);
        setHasLearnerPassExpired(true);
        setShowLearnerPassAd(false);
      } else if (numberOfDaysRemaining < 3 && numberOfDaysRemaining > 0) {
        setShowLearnerPassRenewalReminder(true);
      } else {
        setShowLearnerPassReminder(false);
      }
    }
  }, []);

  const renderLearnerPassAd = () => {
    if (showLearnerPassAd) {
      return (
        <LearnerPassAd
          show={showLearnerPassAd}
          onClose={() => {
            setShowLearnerPassAd(false);
          }}
          onProceed={() => {
            // setShowLearnerPassWelcomeScreen(false);
            setShowLearnerPassPaymentPopup(true);
            setWantsToBuyLearnerPass(false);
            setShowLearnerPassAd(false);
          }}
        />
      );
    } else if (showLearnerPassReminder) {
      return (
        <LearnerPassExpired
          show={showLearnerPassReminder}
          onClose={() => setShowLearnerPassReminder(false)}
          onProceed={() => {
            setShowLearnerPassReminder(false);
            setShowLearnerPassPaymentPopup(true);
          }}
        />
      );
    } else if (showLearnerPassRenewalReminder) {
      return (
        <LearnerPassRenewReminder
          show={showLearnerPassRenewalReminder}
          onClose={() => setShowLearnerPassRenewalReminder(false)}
          onProceed={() => {
            setShowLearnerPassRenewalReminder(false);
            setShowLearnerPassPaymentPopup(true);
          }}
        />
      );
    }
    return null;
  };

  const openUpdateProfileDialog = () => {
    setUpdateProfileDialog(AppConstants.trueText);
  };

  const closeUpdateProfileDialog = () => {
    setUpdateProfileDialog(AppConstants.falseText);
    setShowUpdateProfileDialogBox(false);
  };

  const skipProfileCompletion = async () => {
    const userQrenciaId = Auth.getLoggedInUserDetails().userId;
    const url = `${AppConstants.skipCompleteStudentProfile}?userQrenciaId=${userQrenciaId}`;
    const response = await AuthorizedDataService.putRequest(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    closeUpdateProfileDialog();
  };

  const updateProfile = () => {
    window.open(
      `${AppConstants.StudentCompleteProfilePagePath}`,
      AppConstants.openInNewTab
    );
    closeUpdateProfileDialog();
  };

  useEffect(() => {
    const fetchChampionshipData = async () => {
      try {
        const url = `${AWARDS_CONSTANTS.getAwardsOfLearnerApi}?userSlug=${Auth.getLoggedInUserDetails().userSlug}`;

        // Use either authorized or normal data service based on login status
        const response = Auth.isLogin()
          ? await AuthorizedDataService.get(url)
          : await DataService.get(url, '', '');

        if (response) {
          setChampionshipData(response);
        }
      } catch (error) {
        console.error('Error fetching championship data:', error);
      } finally {
        setChampionshipLoading(false);
      }
    };

    fetchChampionshipData();
  }, [learnerSlug, toggleUpdate]);

  const isChampionOfTheDay = () => {
    const today = moment().format('YYYY-MMM-DD');
    return championshipData.some(
      (champ) => moment(champ.awardedOn).format('YYYY-MMM-DD') === today
    );
  };

  const getTodaysChampionship = () => {
    const today = moment().format('YYYY-MMM-DD');
    return championshipData.filter(
      (champ) => moment(champ.awardedOn).format('YYYY-MMM-DD') === today
    );
  };

  return (
    <div className="studentContentSection">
      {!championshipLoading && isChampionOfTheDay() && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="champion-tooltip">
              <strong>Awarded for excellence in:</strong>
              <br />
              {getTodaysChampionship().length > 0
                ? getTodaysChampionship()
                    .map((course) => course.courseTitle)
                    .join(', ')
                : ''}
            </Tooltip>
          }
        >
          <span className={`${classes.championBadge} ${classes.statusWrapper}`}>
            🏆 Champion of the Day
          </span>
        </OverlayTrigger>
      )}
      <div>
        <StudentHomeTodaysActivities minimumReviewCount={minimumReviewCount} />

        {learnerPassSubscriptionStatus !== 'Active'
          ? nudgeAdImage?.bannerImage?.active && (
              <div className={classes.adImageSection}>
                <a
                  target={AppConstants.newPageTargetSelf}
                  href={nudgeAdImage.bannerImage?.link}
                >
                  <img
                    alt="advertisement"
                    src={imagePath}
                    className="templateHomeBannerImage"
                  />
                </a>
              </div>
            )
          : null}

        {featuredLearningActivitiesData !== null &&
          featuredLearningActivitiesData !== undefined &&
          featuredLearningActivitiesData.length !== 0 && (
            <StudentHomeFeaturedLearningActivities
              minimumAgeLimit={minimumAgeLimit}
              minimumReviewCount={minimumReviewCount}
              featuredLearningActivitiesData={featuredLearningActivitiesData}
            />
          )}
      </div>
      <div className={classes.sectionDiv}>
        {recentlyPublishedLearningActivitiesData != null &&
          recentlyPublishedLearningActivitiesData !== undefined &&
          recentlyPublishedLearningActivitiesData.length !== 0 && (
            <StudentHomeRecentlyPublishedLearningActivities
              minimumAgeLimit={minimumAgeLimit}
              minimumReviewCount={minimumReviewCount}
              recentlyPublishedActivitiesDetails={
                recentlyPublishedLearningActivitiesData
              }
            />
          )}
      </div>

      <Modal
        centered
        // show={updateProfileDialogBox && !profileCompletion}
        show={showUpdateProfileDialogBox && !profileCompletion}
        onRequestClose={closeUpdateProfileDialog}
        contentLabel="Update Profile Popup"
        className={classes.updateProfilePopup}
        overlayClassName={classes.overlay}
      >
        <div className={classes.updateProfileDialogContainer}>
          <div className={classes.greetingsContainer}>
            <div className={classes.dialogProfileGifBackground}>
              <img
                src={backdropElementMobile}
                alt="backdrop"
                className={classes.backdropImage}
              />
              <div className={classes.avatarImage}>
                <img
                  src={defaultProfilePicture}
                  className={classes.dialogProfileGif}
                  alt="Profile"
                />
              </div>
            </div>
            <div className={classes.dialogContent}>
              <p className={classes.welcomeText}>Welcome Onboard!</p>
              <div className={classes.dialogContentText}>
                <p>{`Hi ${JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails))?.firstName}`}</p>
                <p>Enhance your profile for a better experience</p>
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            {skipsAvailable > 0 && (
              <div
                className={classes.dialogButton}
                onClick={skipProfileCompletion}
              >
                <span className={classes.skipButton}>Skip for now</span>
              </div>
            )}
            <Button className={classes.dialogButton} onClick={updateProfile}>
              Update profile
            </Button>
          </div>
        </div>
      </Modal>
      {renderLearnerPassAd()}
    </div>
  );
};
