import { React, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { EXPERT_STUDENT_DETAILS } from '../../constants/AppConstants';
import classes from './ViewAndEditAwardsGrids.module.css';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup, Form } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AppConstants } from '../../constants/AppConstants';
export const ViewAndEditAwardsGrids = ({ awardsByExperts }) => {
  const contentRef = useRef();
  //const [awardsByExperts,setAwardsByExperts] = useState([]);

  const [awardsGroupedByDate, setAwardsGroupedByDate] = useState([]);
  const [awardsGroupedByLearnerNames, setAwardsGroupedByLearnerNames] =
    useState([]);
  const [showStudentView, setShowStudentView] = useState(true);
  const [checked, setChecked] = useState(false);

  // Generate PDF from HTML
  const generatePDF = () => {
    const content = contentRef.current;

    html2canvas(content, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('download.pdf');
    });
  };

  //Transform awardsByExperts to grid format
  useEffect(() => {
    // Group by awardedOn and format names
    const groupedByDate = Object.values(
      awardsByExperts.reduce((acc, student) => {
        const date = student.awardedOn;
        if (!acc[date]) {
          acc[date] = { awardedOn: date, student: [] };
        }
        acc[date].student.push(student);
        return acc;
      }, {})
    );
    setAwardsGroupedByDate(groupedByDate);
    // Group by learnerNames and format Date
    const groupedByLearnerNames = Object.values(
      awardsByExperts.reduce((acc, student) => {
        const learnerName = student.learnerName;
        if (!acc[learnerName]) {
          acc[learnerName] = { learnerName: learnerName, student: [] };
        }
        acc[learnerName].student.push(student);
        return acc;
      }, {})
    );
    // setAwardsGroupedByDate(groupedByDate);
    setAwardsGroupedByLearnerNames(groupedByLearnerNames);
  }, [awardsByExperts]);

  const gridRef = useRef(null); // Reference for AG Grid

  const awardsByExpertsColumnDefs = [
    {
      headerName: 'Awarded Date',
      field: 'awardedOn',
      sort: 'asc',
      filter: true,
      sortable: true,
      width: 200,
      rowGroup: true,
      hide: false,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('DD-MMM-YYYY') : '',
      tooltipField: 'awardedOn',
      valueGetter: (params) => {
        // Format the date for filtering
        return params.data.awardedOn
          ? moment(params.data.awardedOn).format('DD-MMM-YYYY')
          : '';
      },
      filterParams: {
        // Custom text matcher to search in the formatted date string
        textMatcher: ({ value, filterText }) => {
          return value.toLowerCase().includes(filterText.toLowerCase());
        },
      },
    },
    {
      headerName: 'Learner Name',
      field: 'student',
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => {
        if (!params.value || params.value.length === 0) return '';
        return params.value;
      },
      valueGetter: (params) => {
        if (!params.data.student || !Array.isArray(params.data.student))
          return '';
        return params.data.student
          .map((student) => student.learnerName)
          .join(', ');
      },
      width: 500,
      filterParams: {
        // Custom text matcher to search in the flattened string of names
        textMatcher: ({ value, filterText }) => {
          return value.toLowerCase().includes(filterText.toLowerCase());
        },
      },
      tooltipValueGetter: ({ data }) => {
        if (!data.student || data.student.length === 0) return '';
        return data.student.map((student) => student.learnerName).join(', ');
      },
    },
    {
      headerName: 'Courses',
      field: 'student',
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        if (!params.data.student || !Array.isArray(params.data.student))
          return '';
        return params.data.student
          .map((student) => student.courseTitle)
          .join(', ');
      },
      cellRenderer: (params) => {
        if (!params.value || params.value.length === 0) return '';
        return params.value;
      },
      filterParams: {
        // Custom text matcher to search in the flattened string of course titles
        textMatcher: ({ value, filterText }) => {
          return value.toLowerCase().includes(filterText.toLowerCase());
        },
      },
      // cellRenderer: (params) => {
      //   if (!params.value || params.value.length === 0) return '';
      //   return params.value
      //     .map((courseList) => courseList.courseTitle)
      //     .join(', ');
      // },
      width: 700,
      tooltipValueGetter: ({ data }) => {
        if (!data.student || data.student.length === 0) return '';
        return data.student.map((student) => student.courseTitle).join(', ');
      },
    },
  ];
  const awardsOfLearnerColumnDefs = [
    {
      headerName: 'Learner Name',
      field: 'learnerName',
      sortable: true,
      filter: true,
      width: 200,
      tooltipValueGetter: ({ data }) => data.learnerName,
    },
    {
      headerName: 'Awarded On',
      field: 'student',
      filter: true,
      sort: 'desc',
      sortable: true,
      width: 400,
      comparator: (valueA, valueB) => {
        // Helper function to extract the latest date from the joined string
        const getLatestTimestamp = (value) => {
          if (!value) return 0; // If no value, return 0
          const dateStrings = value.split(', '); // Split into individual dates
          const dates = dateStrings.map((d) =>
            moment(d, 'DD-MMM-YYYY').toDate()
          ); // Convert to Date objects
          return dates.length ? Math.max(...dates.map((d) => d.getTime())) : 0; // Return the latest timestamp
        };

        const latestA = getLatestTimestamp(valueA);
        const latestB = getLatestTimestamp(valueB);
        return latestB - latestA; // Sort in descending order (newest first)
      },
      valueGetter: (params) => {
        if (!params.data.student || !Array.isArray(params.data.student))
          return '';
        return params.data.student
          .map((student) => moment(student.awardedOn).format('DD-MMM-YYYY'))
          .join(', ');
      },
      cellRenderer: (params) => {
        if (!params.value || params.value.length === 0) return '';
        return params.value;
      },
      filterParams: {
        // Custom text matcher to search in the flattened string of dates
        textMatcher: ({ value, filterText }) => {
          return value.toLowerCase().includes(filterText.toLowerCase());
        },
      },
      tooltipValueGetter: ({ data }) => {
        if (!data.student || data.student.length === 0) return '';
        return data.student
          .map((student) => {
            if (!student.awardedOn) return '';
            const date = new Date(student.awardedOn);
            return date.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            });
          })
          .join(', ');
      },
    },
    {
      headerName: 'Courses',
      field: 'student',
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        if (!params.data.student || !Array.isArray(params.data.student))
          return '';
        return params.data.student
          .map((student) => student.courseTitle)
          .join(', ');
      },
      cellRenderer: (params) => {
        if (!params.value || params.value.length === 0) return '';
        return params.value;
      },
      filterParams: {
        // Custom text matcher to search in the flattened string of course titles
        textMatcher: ({ value, filterText }) => {
          return value.toLowerCase().includes(filterText.toLowerCase());
        },
      },
      width: 800,
      tooltipValueGetter: ({ data }) => {
        if (!data.student || data.student.length === 0) return '';
        return data.student.map((student) => student.courseTitle).join(', ');
      },
    },
  ];
  return (
    <div>
      <div className="d-flex align-items-center">
        {/*{showStudentView &&(<h3 className={classes.heading}>Student Wise</h3>)}*/}
        {/*{!showStudentView &&(<h3 className={classes.heading}>Date Wise</h3>)}*/}
        {showStudentView && <span className="toggle-label">Date Wise</span>}
        {!showStudentView && <span className="toggle-label">Name wise</span>}
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={showStudentView}
          onChange={() => setShowStudentView(!showStudentView)}
          className="toggle-switch"
        />
      </div>
      {!showStudentView && (
        <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={awardsOfLearnerColumnDefs}
            rowData={awardsGroupedByLearnerNames}
            groupDisplayType={'groupRows'}
            enableCharts={true}
            pagination={true}
            paginationPageSize={
              EXPERT_STUDENT_DETAILS.paginationPerPageResultSize
            }
            suppressRowHoverHighlight={true}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            defaultColDef={{
              resizable: true,
              filter: true,
            }}
          />
        </div>
      )}
      {showStudentView && (
        <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={awardsByExpertsColumnDefs}
            rowData={awardsGroupedByDate}
            pagination={true}
            groupDisplayType={'groupRows'}
            paginationPageSize={
              EXPERT_STUDENT_DETAILS.paginationPerPageResultSize
            }
            suppressRowHoverHighlight={true}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            defaultColDef={{
              resizable: true,
              filter: true,
            }}
          />
        </div>
      )}
    </div>
  );
};
