import React, { useState, useEffect } from 'react';
import { AppConstants, LEARNER_PROFILE } from '../../../constants/AppConstants';
import expertStyle from './LearnerExpertRecommendation.module.css';
import { RiDoubleQuotesR } from 'react-icons/ri';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import 'moment-timezone';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';
import AddRecommendationModal from './AddRecommendationModal';
import { FaSave } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { Button, Form } from 'react-bootstrap';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { DataService } from '../../../services/DataService';
import { SuccessMessage } from '../../../constants/SuccessMessage';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { ErrorSuccessAlertMessage } from '../../errorSuccessMessage/ErrorSuccessAlertMessage';

export const LearnerExpertRecommendation = (props) => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [showActivityAlertMessage, setShowActivityAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [showAlertMessage, setShowAlertMessage] = useState('');

  const [initialRecommendationDetails, setInitialRecommendationDetails] =
    useState(null);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [recommendedOn, setRecommendedOn] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [visibilityMap, setVisibilityMap] = useState({});
  const [changedVisibility, setChangedVisibility] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const onSuccess = (data) => {
    setAlertVariant(AppConstants.alertVarient[0]);
    setShowActivityAlertMessage(data != null && data != '' ? "Recommendation updated Successfully" : "Recommendation added successfully");
    setTimeout(() => {
      setShowActivityAlertMessage(AppConstants.emptyString);
    }, AppConstants.messageDisappearTime);
  }
  const onError = (data) => {
    setAlertVariant(AppConstants.alertVarient[0]);
    setShowActivityAlertMessage(data != null && data != '' ? "Recommendation could not be updated. Try again later" : "Recommendation could not be added. Try again later");
    setTimeout(() => {
      setShowActivityAlertMessage(AppConstants.emptyString);
    }, AppConstants.messageDisappearTime);
  }

  useEffect(() => {
    if (props.recommendationDetails.length > 0) {
      const visibilityMap = {};
      props.recommendationDetails.forEach((recommendation) => {
        if (recommendation.recommendationType === 'Self Declared') {
          visibilityMap[recommendation.id] =
            recommendation.isActiveFlag === 'Y';
        }
      });
      setVisibilityMap(visibilityMap);
    }
  }, [props.updateRecommendations]);

  const handleToggleVisibility = (id) => {
    setVisibilityMap((prev) => {
      const updatedMap = { ...prev, [id]: !prev[id] };
      setChangedVisibility((prevChanges) => ({
        ...prevChanges,
        [id]: updatedMap[id], // Sync changes correctly
      }));
      return updatedMap;
    });
  };

  const handleSaveVisibilityChanges = async () => {
    const updatedVisibilityArray = Object.keys(changedVisibility).map((id) => ({
      id: id,
      activeFlag: changedVisibility[id] ? 'Y' : 'N', // Convert boolean to 'Y' or 'N'
    }));

    if (updatedVisibilityArray.length === 0) {
      setIsEditing(false);
      return; // No changes, no API call needed
    }

    const postPayload = {
      activityCategory: 'Recommendations',
      activityList: updatedVisibilityArray,
    };

    try {
      const response = await AuthorizedDataService.post(
        LEARNER_PROFILE.updateActiveFlagForLearnerDeclaredActivities,
        postPayload,
        '',
        '',
        ''
      );

      if (response) {
        let url = `${AppConstants.fetchRecommendationsForLearnerProfile}/${props.learnerSlug}?timestamp=${Date.now()}`;
        const freshData = await DataService.get(url, '', '');

        if (freshData) {
          props.updateRecommendations(freshData);
          setChangedVisibility({}); // Reset changes after successful update
        }
      }
    } catch (error) {
      console.error('Error saving recommendation', error);
    }
  };


  useEffect(() => {
    if (props.recommendationDetails.length > 0) {
      setSelectedIndex(0);
      if (props.editAllowed) {
        setSelectedRecommendation(props.recommendationDetails[0].recommendation);
        setRecommendedOn(props.recommendationDetails[0].recommendedOn);
      }
      else {
        setSelectedRecommendation(props.recommendationDetails.filter((a) => a.isActiveFlag === 'Y')[0].recommendation);
        setRecommendedOn(props.recommendationDetails.filter((a) => a.isActiveFlag === 'Y')[0].recommendedOn);
      }

    }
  }, [props.recommendationDetails, props.editAllowed]);

  const handleExpertClick = (recommendation, index, recommendedOn) => {
    setRecommendedOn(recommendedOn);
    setSelectedIndex(index);
    setSelectedRecommendation(recommendation || '');
  };

  const handleTitleClick = (e, link) => {
    e.stopPropagation(); // Prevents triggering the card's onClick

    if (!link) return;
    if (link.startsWith("/expert/") || link.startsWith("/agency/")) {
      window.open(`${link}`, "_blank");
    } else {
      // Ensure external links have proper protocol
      const formattedLink = link.startsWith("http") ? link : `https://${link}`;
      window.open(formattedLink, "_blank");
    }
  };
  return (
    <div className={expertStyle.expertsRecommendationsSection}>
      <div className="d-flex justify-content-between align-items-center m-2">
        <h2 className={expertStyle.expertsRecommendations}>
          {AppConstants.ExpertsRecommendations}
        </h2>

        {props.editAllowed && (
          <div className="d-flex gap-2 p-2">
            <Button
              className={` ${expertStyle.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
              onClick={() => {
                setShowModal(true);
                setIsUpdating(false);
                setInitialRecommendationDetails(null);
              }}
            >
              Add
            </Button>
            <Button
              className={` ${expertStyle.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
              onClick={() => {
                if (isEditing) {
                  handleSaveVisibilityChanges();
                }

                setIsEditing(!isEditing);
              }}
            >
              {isEditing ? <FaSave /> : <FaEdit />}
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          </div>
        )}

        <AddRecommendationModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          triggerRefresh={props.triggerRefresh}
          recommendationDetails={selectedRecommendation}
          updateRecommendations={props.updateRecommendations}
          learnerSlug={props.learnerSlug}
          initialRecommendationDetails={initialRecommendationDetails}
          isUpdating={isUpdating}
          onSuccess={onSuccess}
          onError={onError}
        />
      </div>
      {showActivityAlertMessage && (
        <ErrorSuccessAlertMessage
          message={showActivityAlertMessage}
          varient={alertVariant}
        />
      )}
      {props.recommendationDetails.length > 0 && (
        <div className={expertStyle.recommendationContainer}>
          <div className={`${expertStyle.experts}`}>
            <ul className={expertStyle.expertsList}>
              {props.recommendationDetails
                .filter(
                  (expert) => props.editAllowed || expert.isActiveFlag === 'Y'
                )
                .map((expert, index) => (
                  <li
                    key={`${expert.learnerQrenciaId}-${index}`}
                    className={`${expertStyle.expertName} ${selectedIndex === index ? expertStyle.activeExpert : ''}`}
                    onClick={() =>
                      handleExpertClick(
                        expert.recommendation,
                        index,
                        expert.recommendedOn
                      )
                    }
                  >
                    {expert.recommendedByImagePath ? (
                      <img
                        src={expert.recommendedByImagePath}
                        className={expertStyle.profileImage}
                        alt="Expert"
                      />
                    ) : (
                      <div>
                        <div className={expertStyle.userInitials}>
                          {expert.recommendedByNameInitials}
                        </div>
                      </div>
                    )}
                    <div className={expertStyle.expertContent}>
                      <h2 onClick={(e) => handleTitleClick(e, expert.brandingLink)} className={expertStyle.recommendedByName}>
                        {expert.agencyName !== null &&
                          expert.agencyName !== undefined
                          ? expert.agencyName
                          : expert.recommendedByName}
                      </h2>
                      <p className={expertStyle.expertDesignation}>
                        {expert.designation}
                        {expert.designation && expert.organizationName
                          ? ', '
                          : ''}{' '}
                        {expert.organizationName}
                      </p>
                    </div>

                    <div className={expertStyle.editRecommendationWrapper}>
                      {isEditing &&
                        expert.recommendationType === 'Self Declared' && (
                          <>
                            <Form.Check
                              // key=index
                              type="switch"
                              id={`visibility-switch-${index}`}
                              label={
                                <span className={expertStyle.editLabel}>
                                  Show on profile?
                                </span>
                              }
                              checked={visibilityMap[expert.id] || false}
                              className={expertStyle.editSwitch}
                              onChange={() => {
                                handleToggleVisibility(expert.id);
                              }}
                            />
                            <FaEdit
                              className="cursor-pointer text-gray-500 hover:text-gray-700"
                              size={20}
                              onClick={() => {
                                setInitialRecommendationDetails(
                                  props.recommendationDetails[index]
                                );
                                setIsUpdating(true);
                                setShowModal(true);
                              }}
                            />
                          </>
                        )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className={`${expertStyle.recommendations}`}>
            <div className={expertStyle.expertWrapper}>
              <p className={expertStyle.recommendationDate}>
                <Moment
                  format={AppConstants.competitionCardsDateMonthFormat}
                  tz={timeZone}
                >
                  {recommendedOn?.replace(' ', 'T') + 'Z'}
                </Moment>{' '}
              </p>
              <p className={expertStyle.firstQuote}>
                <RiDoubleQuotesR />
              </p>
              <p className={expertStyle.recommendationDesc}>
                {parse(selectedRecommendation || '')}
              </p>
              <p className={expertStyle.lastQuote}>
                <RiDoubleQuotesR />
              </p>
            </div>
          </div>
        </div>
      )
      }
      {
        props.recommendationDetails.length == 0 && (
          <p className="noDataAvailableError">
            Your talent must be recognized! Reach out to your teacher to get your
            first recommendation.
          </p>
        )
      }
    </div >
  );
};
