import { useEffect, useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AppConstants, LEARNER_PROFILE } from '../../../constants/AppConstants';
import Auth from '../../../utils/Auth';
import { FileOperationsUtil } from '../../../utils/FileOperationsUtil';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import useCardStore from '../../../stores/achievementCardStore';
import dayjs from 'dayjs';
import formClass from '../../../common/FormStyling.module.css';
import classes from './AddAchievementModal.module.css';
import { BiLoaderAlt } from 'react-icons/bi';

const AddAchievementModal = ({
  show,
  onHide,
  prepopulatedAchievements = null,
  onSuccess,
  onError,
}) => {
  const fileInputRef = useRef(null);
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const addCards = useCardStore((state) => state.addCards);
  const [achievementType, setAchievementType] = useState('');
  const [selectedFile, setSelectedFile] = useState(() =>
    prepopulatedAchievements?.selfDeclaredAchievementCertificatePath
      ? prepopulatedAchievements.selfDeclaredAchievementCertificatePath.replace(
          mediaContent,
          ''
        )
      : null
  );
  const [fileError, setFileError] = useState('');
  const initializeLearnerProfileAchievementCardsData = useCardStore(
    (state) => state.initializeLearnerProfileAchievementCardsData
  );
  const learnerSlug = useCardStore((state) => state.learnerSlug);

  useEffect(() => {
    setAchievementType(prepopulatedAchievements?.learningActivityType || '');
  }, [prepopulatedAchievements]);

  // 🛠️ Validation Schema with Yup
  const validationSchema = Yup.object().shape({
    learningActivityType: Yup.string()
      .trim()
      .required('Activity type is required'),
    learningActivityName: Yup.string()
      .trim()
      .required('Activity name is required')
      .max(100, 'Activity name must be less than 100 characters'),
    organizer: Yup.string()
      .trim()
      .required('Organizer is required')
      .max(100, 'Organizer name must be less than 100 characters'),
    learningActivityStartDate: Yup.date()
      .required('Start date is required')
      .max(new Date(), 'Start date cannot be in the future'),
    learningActivityEndDate: Yup.date()
      .required('End date is required')
      .min(
        Yup.ref('learningActivityStartDate'),
        'End date must be after start date'
      )
      .max(new Date(), 'End date must be in the past'),
    learnerRank: Yup.string().nullable(),
    learnerMedal: Yup.string().nullable(),
    website: Yup.string()
      .test('is-valid-url', 'Enter a valid URL', (value) => {
        if (!value) return true;

        const urlPattern =
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

        if (!urlPattern.test(value)) {
          return false;
        }

        const domainPart = value.replace(/^(https?:\/\/)?(www\.)?/, '');
        if (domainPart.split('.').length !== 2) {
          return false;
        }

        return true;
      })
      .nullable(),
  });

  const uploadFileToS3 = async (file) => {
    try {
      const fileExtension = file.name.split('.').pop();
      const timestamp = new Date().getTime();
      const fileName = `certificate_${timestamp}.${fileExtension}`;
      const contentDisposition = `attachment; filename="${fileName}"`;
      const certificatePath = `${LEARNER_PROFILE.learnerProfileRootPath}/${Auth.getLoggedInUserDetails().userId}/selfDeclaredAchievements/${achievementType}/${fileName}`;

      const uploadToS3Result =
        await FileOperationsUtil.uploadFileThroughPreSignedURL(
          file,
          certificatePath,
          AppConstants.s3filePermissionPublic,
          contentDisposition
        );

      return uploadToS3Result.objectKey;
    } catch (error) {
      return null;
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (
      achievementType === 'Competition' &&
      !values.learnerRank &&
      !values.learnerMedal
    ) {
      values.completionType = 'Competition Completion';
    }

    if (
      selectedFile != '' &&
      selectedFile != null &&
      prepopulatedAchievements?.selfDeclaredAchievementCertificatePath == null
    ) {
      const uploadedFilePath = await uploadFileToS3(selectedFile);
      values.certificate = uploadedFilePath;
      values.learningActivityType = achievementType;
    } else if (selectedFile == null) {
      values.certificate = null;
    }

    let insertNewAchievementPayload = {
      activityCategory: 'Achievements',
      activityType: achievementType,
      activityName: values.learningActivityName,
      activityStartDate: values.learningActivityStartDate,
      activityEndDate: values.learningActivityEndDate,
      organizedByExpertName: values.organizer,
      organizedByExpertWebsiteLink: values.website,
      rankAwarded:
        achievementType === 'Competition' ? values.learnerRank : null,
      medalAwarded:
        achievementType === 'Competition' ? values.learnerMedal : null,
      certificatePath: values.certificate,
    };

    try {
      let url = 'saveLearnerDeclaredInformation';
      if (prepopulatedAchievements) {
        url = 'saveLearnerDeclaredInformation';
        insertNewAchievementPayload.id =
          prepopulatedAchievements.learningActivityId;
      }

      const response = AuthorizedDataService.post(
        url,
        insertNewAchievementPayload,
        '',
        ''
      );

      if (response) {
        initializeLearnerProfileAchievementCardsData({ learnerSlug });
        setSelectedFile(null);
        onSuccess(!!prepopulatedAchievements);
      }
      await addCards({ newCard: values });
      onHide();
    } catch (error) {
      onError();
    } finally {
      setSubmitting(false);
    }

    try {
      await addCards({ newCard: values });
    } catch (error) {
      onError();
    }
    setAchievementType(AppConstants.emptyString);
    onHide();
  };

  useEffect(() => {
    if (prepopulatedAchievements?.selfDeclaredAchievementCertificatePath) {
      setSelectedFile(
        prepopulatedAchievements?.selfDeclaredAchievementCertificatePath
          ? prepopulatedAchievements.selfDeclaredAchievementCertificatePath.replace(
              mediaContent,
              ''
            )
          : null
      ); // Mock file object
    }
  }, [prepopulatedAchievements]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
      ]; // Added PDF
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        setFileError('');
      } else {
        setSelectedFile(null);
        setFileError('Only JPG, PNG, JPEG, and PDF files are allowed.');
      }
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    }
  };

  return (
    <Modal
      show={show}
      centered
      className={classes.achievementHead}
      enableReinitialize // Important for resetting
      onHide={() => {
        setAchievementType('');
        setSelectedFile('');
        onHide();
      }}
    >
      <Modal.Header closeButton className={classes.achievmentHeader}>
        <Modal.Title className={classes.achievmentTitle}>
          Add Achievement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            learningActivityName:
              prepopulatedAchievements?.learningActivityName || '',
            organizer: prepopulatedAchievements?.organizer || '',
            website: prepopulatedAchievements?.organizerWebsiteLink || null,
            learningActivityStartDate:
              prepopulatedAchievements?.learningActivityStartDate
                ? dayjs(
                    prepopulatedAchievements?.learningActivityStartDate
                  ).format('YYYY-MM-DD')
                : '',
            learningActivityEndDate:
              prepopulatedAchievements?.learningActivityEndDate
                ? dayjs(
                    prepopulatedAchievements?.learningActivityEndDate
                  ).format('YYYY-MM-DD')
                : '',
            certificate:
              prepopulatedAchievements?.selfDeclaredAchievementCertificatePath
                ? prepopulatedAchievements.selfDeclaredAchievementCertificatePath.replace(
                    mediaContent,
                    ''
                  )
                : null,
            learnerRank: prepopulatedAchievements?.learnerRank || '',
            learnerMedal: prepopulatedAchievements?.learnerMedal || '',
            learningActivityType:
              prepopulatedAchievements?.learningActivityType || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, isSubmitting, values, resetForm }) => {
            return (
              <FormikForm>
                {/* Achievement Type */}
                <Form.Group>
                  <Form.Label className={formClass.formLabel}>
                    Activity Type
                  </Form.Label>
                  <Form.Select
                    className={formClass.selectInput}
                    value={achievementType}
                    onChange={(e) => {
                      setAchievementType(e.target.value);
                      setFieldValue('learningActivityType', e.target.value);
                    }}
                  >
                    <option value={AppConstants.emptyString} defaultValue>
                      Select Activity Type
                    </option>
                    <option value="Course">Course</option>
                    <option value="Competition">Competition</option>
                    <option value="Workshop">Workshop</option>
                  </Form.Select>
                  <ErrorMessage
                    name="learningActivityType"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                {/* Name of Activity */}
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Name of Activity<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="text"
                    name="learningActivityName"
                  />
                  <ErrorMessage
                    name="learningActivityName"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                {/* Organized by */}
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Organized by<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="text"
                    name="organizer"
                  />
                  <ErrorMessage
                    name="organizer"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                {/* Organization Website */}
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Organization Website (optional)
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    // type="url"
                    name="website"
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                {/* Start Date */}
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Activity Start Date<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="date"
                    name="learningActivityStartDate"
                  />
                  <ErrorMessage
                    name="learningActivityStartDate"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                {/* End Date */}
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Activity End Date<span className="redAstrisk">*</span>
                  </Form.Label>
                  <Field
                    className={`form-control ${formClass.formInput}`}
                    type="date"
                    name="learningActivityEndDate"
                  />
                  <ErrorMessage
                    name="learningActivityEndDate"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                {/* Rank/Medal for Competitions */}
                {achievementType === 'Competition' && (
                  <>
                    <Form.Group className="mt-2">
                      <Form.Label className={formClass.formLabel}>
                        Rank
                      </Form.Label>
                      <Field
                        as="select"
                        className={`form-control ${formClass.formInput}`}
                        name="learnerRank"
                        onChange={(e) => {
                          const rankValue = e.target.value;
                          setFieldValue('learnerRank', rankValue);
                          if (rankValue) {
                            setFieldValue('learnerMedal', '');
                          }
                        }}
                        disabled={!!values.learnerMedal}
                      >
                        <option value="">Select Rank</option>
                        <option value="1">1st</option>
                        <option value="2">2nd</option>
                        <option value="3">3rd</option>
                      </Field>
                    </Form.Group>

                    <Form.Group className="mt-2">
                      <Form.Label className={formClass.formLabel}>
                        Medal
                      </Form.Label>
                      <Field
                        as="select"
                        className={`form-control ${formClass.formInput}`}
                        name="learnerMedal"
                        onChange={(e) => {
                          const medalValue = e.target.value;
                          setFieldValue('learnerMedal', medalValue);
                          if (medalValue) {
                            setFieldValue('learnerRank', '');
                          }
                        }}
                        disabled={!!values.learnerRank}
                      >
                        <option value="">Select Medal</option>
                        <option value="Gold">Gold</option>
                        <option value="Silver">Silver</option>
                        <option value="Bronze">Bronze</option>
                      </Field>
                    </Form.Group>
                  </>
                )}

                {/* Certificate Upload */}
                <Form.Group className="mt-2">
                  <Form.Label className={formClass.formLabel}>
                    Upload Certificate (PDF/JPG, optional)
                  </Form.Label>
                  <Form.Control
                    className={formClass.formInput}
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept=".pdf,.jpg,.jpeg,.png"
                  />
                  {selectedFile && (
                    <p onClick={handleRemoveFile} className="removeFileText">
                      Remove File
                    </p>
                  )}
                  {fileError && <div style={{ color: 'red' }}>{fileError}</div>}
                  <ErrorMessage
                    name="certificate"
                    component="div"
                    className={classes.achievementErrorText}
                  />
                </Form.Group>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className={classes.achievementBtn}
                    onClick={() => {
                      // resetForm();
                      setAchievementType('');
                      setSelectedFile('');
                      onHide();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.achievementBtn}
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        {prepopulatedAchievements ? 'Updating ' : 'Saving '}
                        <BiLoaderAlt className={`spinner ${classes.spinner}`} />
                      </>
                    ) : prepopulatedAchievements ? (
                      'Update'
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Modal.Footer>
              </FormikForm>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddAchievementModal;
