import { create } from 'zustand';
import { AppConstants } from '../constants/AppConstants';
import { FetchLearnerAchievementCards } from '../utils/FetchLearnerProfileAchievementCardsData';
import { AuthorizedDataService } from '../services/AuthorizedDataService';

const useCardStore = create((set, get) => ({
  learnerProfileAchievementCards: { achievements: [] },
  loading: false,
  error: null,
  visibilityData: [],
  learnerSlug: null,
  postPayload: {},

  setPostPayload: (data) => {
    set((state) => ({
      postPayload: data,
    }));
  },

  getPostPayload: () => get().postPayload,

  setLearnerSlug: (slug) => {
    set((state) => ({
      learnerSlug: slug,
    }));
  },

  setVisibilityData: (data) => {
    set((state) => {
      const updatedState = {
        // visibilityData: [...(state.visibilityData || []), ...data],
        visibilityData: data,
      };
      return updatedState;
    });
  },

  setLearnerProfileAchievementCardsData: (data) => {
    set((state) => ({
      learnerProfileAchievementCards: {
        ...state.learnerProfileAchievementCards,
        ...data,
      },
    }));
  },

  getLearnerProfileAchievementCardsData: () => get().learnerProfileAchievementCards,

  initializeLearnerProfileAchievementCardsData: async ({ learnerSlug }) => {
    try {
      const data = await FetchLearnerAchievementCards({
        userSlug: learnerSlug,
      });

      set((state) => ({
        learnerProfileAchievementCards: {
          ...state.learnerProfileAchievementCards,
          ...data,
        },
      }));
    } catch (error) {
      set(() => ({
        learnerProfileAchievementCards: {},
        error: 'An error occurred while fetching cards',
      }));
    }
  },

  addCards: async ({ newCard }) => {

    try {
      set((state) => {
        const updatedState = {
          learnerProfileAchievementCards: {
            ...state.learnerProfileAchievementCards,
            achievements: [
              ...(state.learnerProfileAchievementCards.achievements || []),
              newCard,
            ],
          },
        };
        return updatedState;
      });
    } catch (error) {
      set({ error: 'An error occurred while adding card' });
    }
  },
}));

export default useCardStore;
