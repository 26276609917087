import React, { useEffect, useState, useRef } from 'react';
import {
  AppConstants,
  EXPERT_STUDENT_DETAILS,
  SKILL_PROGRESS_ASSESSMENT_CONSTANTS,
} from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AgGridReact } from 'ag-grid-react';
import { Button, Table, Modal, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Auth from '../../utils/Auth';
import { FaDotCircle } from 'react-icons/fa';
import classes from './../competitionJudgingComponents/GymnasticsMarkParticipantScreen.module.css';
import { GoDotFill } from "react-icons/go";

export const ViewAndEditSkillProgressTemplateGrid = ({ refresh }) => {
  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Store selected row for the modal
  const [showModal, setShowModal] = useState(false); // State for controlling modal visibility
  const gridRef = useRef(null); // Reference for AG Grid

  useEffect(() => {
    async function getAllSkillProgressTemplates() {
      const url =
        SKILL_PROGRESS_ASSESSMENT_CONSTANTS.getSkillProgressTemplateApi;
      const response = await AuthorizedDataService.getRequest(url, '', '');
      if (response) {
        const loggedInUserId = Auth.getLoggedInUserDetails()?.id;
        const filteredData = response.filter(
          (row) => row.createdBy === loggedInUserId
        );
        setRowData(filteredData);
      }
    }
    getAllSkillProgressTemplates();
  }, [refresh]);

  // Function to handle toggle action
  const toggleStatus = async (id, uniqueId, isActive) => {
    try {
      const updatedStatus = isActive === 'Y' ? 'N' : 'Y';
      const url = `${SKILL_PROGRESS_ASSESSMENT_CONSTANTS.enbaleDisableSkillProgressTemplateApi}${uniqueId}&isActive=${updatedStatus}`;
      const payload = { isActive: updatedStatus };

      const response = await AuthorizedDataService.post(url, payload, '', '');

      if (response) {
        // Update the rowData state to trigger a re-render
        setRowData((prevData) =>
          prevData.map((row) =>
            row.id === id ? { ...row, isActive: updatedStatus } : row
          )
        );

        // Refresh AG Grid to apply styles
        gridRef.current.api.refreshCells();

        toast.success('Template status updated successfully');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Error updating status');
    }
  };

  // Function to open the modal with row data
  const handleView = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const skillProgressTemplateColumnDefs = [
    {
      headerName: 'Id',
      field: 'templateId',
      sortable: true,
      filter: true,
      width: 90,
      cellRendererFramework: (params) => {
        const isActive = params.data.isActive === 'Y';
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
            <GoDotFill style={{ alignSelf: 'center' }} color={isActive ? '#28a745' : '#dc3545'} />
            <p style={{ color: '#000', fontWeight: 400 }}>{params.value}</p>
          </div>
        );
      },
      valueGetter: (params) => params.data.templateId,
      tooltipValueGetter: (params) =>
        params.data.templateId || AppConstants.notAvailableText,
    },
    {
      headerName: 'Name',
      field: 'templateName',
      sortable: true,
      filter: true,
      sort: 'asc',
      width: 200,
      tooltipValueGetter: (params) =>
        params.data.templateName || AppConstants.notAvailableText,
    },
    {
      headerName: 'Description',
      field: 'templateDescription',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 250,
      valueGetter: (params) =>
        params.data.templateDescription || AppConstants.notAvailableText,
      tooltipValueGetter: (params) =>
        params.data.templateDescription || AppConstants.notAvailableText,
    },
    // {
    //   headerName: 'Skill',
    //   field: 'skillName',
    //   sortable: true,
    //   filter: true,
    //   sort: 'asc',
    //   width: 200,
    //   valueGetter: (params) => params.data?.skillName || AppConstants.notAvailableText,
    //   tooltipValueGetter: (params) => params.data?.skillName || AppConstants.notAvailableText,
    // },
    // {
    //   headerName: 'Status',
    //   field: 'isActive',
    //   sortable: true,
    //   filter: 'agTextColumnFilter', // Ensures text-based filtering
    //   width: 110,
    //   valueGetter: (params) =>
    //     params.data.isActive === 'Y' ? 'Active' : 'Inactive', // Converts values for filtering
    //   tooltipValueGetter: (params) => params.value, // Uses transformed value for tooltip
    //   cellRendererFramework: (params) => (
    //     <span
    //       style={{
    //         color: params.value === 'Active' ? '#008800' : '#d63500',
    //         fontWeight: 'normal',
    //       }}
    //     >
    //       {params.value}
    //     </span>
    //   ),
    // },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      headerClass: 'multi-line-header',
      sortable: false,
      width: 220,
      cellRendererFramework: (params) => {
        const { id, uniqueId, isActive } = params.data;
        return (
          <>
            <Button
              className={isActive === 'Y' ? 'disableButton' : 'enableButton'}
              size="sm"
              onClick={() => toggleStatus(id, uniqueId, isActive)}
            >
              {isActive === 'Y' ? 'Disable' : 'Enable'}
            </Button>
            <Button
              size="sm"
              className="ms-2"
              onClick={() => handleView(params.data)}
            >
              View
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{ width: '100%', height: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={skillProgressTemplateColumnDefs}
          rowData={rowData}
          pagination={true}
          paginationPageSize={10}
          domLayout="autoHeight"
          suppressRowHoverHighlight={true}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          defaultColDef={{
            filter: true,
            sortable: true,
            flex: 1,
            minWidth: 160,
            resizable: true,
            suppressMenu: true,
            suppressHorizontalScroll: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            floatingFilter: true,
          }}
        />
      </div>

      {/* Modal for Viewing Details */}
      {selectedRow && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Template Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {/* Template Details */}
              <Row className="mb-3">
                <Col className="table-data">
                  <strong>Template Id:</strong>{' '}
                  {selectedRow.templateId || 'N/A'}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="table-data">
                  <strong>Template Name:</strong>{' '}
                  {selectedRow.templateName || 'N/A'}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="table-data">
                  <strong>Skill Name:</strong> {selectedRow.skillName || 'N/A'}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="table-data">
                  <strong>Template Description:</strong>{' '}
                  {selectedRow.templateDescription || 'N/A'}
                </Col>
              </Row>

              {/* Status */}
              <Row className="mb-3">
                <Col className="table-data">
                  <strong>Status:</strong>{' '}
                  <span
                    className={
                      selectedRow.isActive === 'Y'
                        ? 'activeText'
                        : 'inactiveText'
                    }
                  >
                    {selectedRow.isActive === 'Y' ? ' Active' : ' Inactive'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="table-data">
                  <strong>Parameters:</strong>
                </Col>
                <Col md={12}>
                  <Table bordered className="custom-table">
                    <thead>
                      <tr className="table-headings">
                        <th
                          className={`${classes.tableData} ${classes.disabledHeader}`}
                        >
                          Name
                        </th>
                        <th
                          className={`${classes.tableData} ${classes.disabledHeader}`}
                        >
                          Max Marks
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedRow.parameters.map((param) => (
                        <tr key={param.id}>
                          <td className={classes.tableData}>
                            {param.parameterName}
                          </td>
                          <td className={classes.tableData}>
                            {param.maximumMarks}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
