import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import classes from './LearnerProfileLayout.module.css';
import toast from 'react-hot-toast';
import { AppConstants, AWARDS_CONSTANTS } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { BiLoaderAlt } from 'react-icons/bi';
import { ProgressBar, Row, Col } from 'react-bootstrap';
import { GiMeepleCircle } from 'react-icons/gi';
import moment from 'moment';
import { PiTrophyFill } from 'react-icons/pi';
import { IoIosSettings } from 'react-icons/io';
import { PiMedalBold } from 'react-icons/pi';
import { GiOpenBook } from 'react-icons/gi';
import { LearnerExpertRecommendation } from '../../component/learnerProfile/learnerExpertRecommendation/LearnerExpertRecommendation';
import { ShareLinkToSocialMediaUtil } from '../../utils/ShareLinkToSocialMediaUtil';
import { SkillGarden } from './skillGarden/SkillGarden';
import { LearnerProfileIntroduction } from './introduction/LearnerProfileIntroduction';
import Heatmap from './Heatmap/Heatmap';
import Auth from '../../utils/Auth';
import { useLearnerProfileHeatmapStore } from '../../stores';
import { LearnerAchievements } from './achievements/LearnerAchievements';
import { LearnerProfileMySubmission } from './mySubmission/LearnerProfileMySubmission';
import EmptyHeatmap from './Heatmap/EmptyHeatmap';
import { LearnerProfileMyAlbum } from './myAlbum/LearnerProfileMyAlbum';
import Banner from './Banner/Banner';
import useCardStore from '../../stores/achievementCardStore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useRecentActivityCardStore from '../../stores/recentActivitiesCardStore';

export const LearnerProfileLayout = () => {
  const { learnerSlug } = useParams();
  const [learnerDetails, setLearnerDetails] = useState([]);
  const [learnerDetailsLoader, setLearnerDetailsLoader] = useState(
    AppConstants.trueText
  );
  const [recommendationDetails, setRecommendationDetails] = useState([]);
  const [learnerAchievements, setLearnerAchievements] = useState([]);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [learnerIntroduction, setLearnerIntroduction] = useState(
    learnerDetails.aboutLearner
  );
  const [myAlbumImageArray, setMyAlbumImageArray] = useState([]);
  const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
  const navigate = useNavigate();
  const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.learnerText}${AppConstants.forwardSlash}${learnerSlug}`;
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const defaultBannerImage = `${mediaContent}${AppConstants.bannerImagesS3Path}/${AppConstants.learnerProfileDefaultBanner}`;
  const [isInitialized, setIsInitialized] = useState(false);
  const [taglineEdit, setTaglineEdit] = useState(false);
  const [albumSaved, setAlbumSaved] = useState(AppConstants.falseText);
  const [albumSavedAlert, setAlbumSavedAlert] = useState(
    AppConstants.emptyString
  );
  const [refreshRecommendations, setRefreshRecommendations] = useState(false);

  const initialValues = {
    shortDescription: AppConstants.emptyString,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const updateRecommendations = (newData) => {
    setRecommendationDetails(newData);
  };

  const updateAchievements = (newData) => {
    setLearnerAchievements(newData);
  };

  const initializeLearnerProfileHeatmapData = useLearnerProfileHeatmapStore(
    (state) => state.initializeLearnerProfileHeatmapData
  );
  const learnerProfileHeatmapData = useLearnerProfileHeatmapStore(
    (state) => state.learnerProfileHeatmapData
  );

  const initializeLearnerProfileAchievementCardsData = useCardStore(
    (state) => state.initializeLearnerProfileAchievementCardsData
  );

  const initializeLearnerProfileRecentActivityCardsData =
    useRecentActivityCardStore(
      (state) => state.initializeLearnerProfileRecentActivityCardsData
    );
  const [learnerShortDescription, setLearnerShortDescription] = useState(
    learnerDetails.shortDescription
  );

  useEffect(() => {
    formValues.shortDescription = learnerDetails.shortDescription;
    setLearnerShortDescription(learnerDetails.shortDescription);
  }, [learnerDetails]);

  const learnerProfileAchievementCards = useCardStore((state) =>
    state.getLearnerProfileAchievementCardsData()
  );

  const learnerProfileActivityCards = useRecentActivityCardStore((state) =>
    state.getLearnerProfileRecentActivityCardsData()
  );
  const [taglineError, setTaglineError] = useState(AppConstants.falseText);

  const setLearnerSlug = useCardStore((state) => state.setLearnerSlug);
  const setRaLearnerSlug = useRecentActivityCardStore(
    (state) => state.setLearnerSlug
  );

  const [championshipData, setChampionshipData] = useState([]);
  const [championshipLoading, setChampionshipLoading] = useState(true);

  useEffect(() => {
    setLearnerSlug(learnerSlug);
    initializeLearnerProfileAchievementCardsData({ learnerSlug });
  }, [learnerSlug]);

  // useEffect(() => {
  //   setRaLearnerSlug(learnerSlug);
  //   initializeLearnerProfileRecentActivityCardsData({ learnerSlug });
  // }, [learnerSlug, learnerProfileActivityCards?.recentActivities]);

  useEffect(() => {
    setRaLearnerSlug(learnerSlug);
    initializeLearnerProfileRecentActivityCardsData({ learnerSlug });
  }, [learnerSlug]);

  useEffect(() => {
    initializeLearnerProfileHeatmapData(learnerSlug).finally(() => {
      setIsInitialized(true);
    });
  }, [learnerSlug, initializeLearnerProfileHeatmapData]);

  const triggerRefresh = async () => {
    setRefreshRecommendations((prevState) => !prevState);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    let url = `${AppConstants.fetchRecommendationsForLearnerProfile}/${learnerSlug}?timestamp=${Date.now()}`;
    const response = await DataService.get(url, '', '');

    if (response !== undefined) {
      setRecommendationDetails([...response]); // Force update
    } else {
    }
  };

  useEffect(() => {
    async function getLearnerDetails() {
      setLearnerDetailsLoader(AppConstants.trueText);
      let url = `${AppConstants.getLearnerProfileDetailsAPI}${learnerSlug}`;
      const response = await AuthorizedDataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response !== undefined) {
        setLearnerDetails(response);
        setMyAlbumImageArray(response.galleryMediaPaths);
        setLearnerDetailsLoader(AppConstants.falseText);
      } else {
        setLearnerDetails([]);
        setLearnerDetailsLoader(AppConstants.falseText);
        navigate(errorPageUrl);
      }
    }
    async function getUnauthorisedLearnerDetails() {
      setLearnerDetailsLoader(AppConstants.trueText);
      let url = `${AppConstants.getLearnerProfileDetailsAPI}${learnerSlug}`;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response !== undefined) {
        setLearnerDetails(response);
        setMyAlbumImageArray(response.galleryMediaPaths);
        setLearnerDetailsLoader(AppConstants.falseText);
      } else {
        setLearnerDetails([]);
        setLearnerDetailsLoader(AppConstants.falseText);
        navigate(errorPageUrl);
      }
    }
    async function getLearnerAchievements() {
      let url = `${AppConstants.fetchLearnerAchievements}/${learnerSlug}`;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response !== undefined) {
        setLearnerAchievements(response);
      } else {
        setLearnerAchievements([]);
        navigate(errorPageUrl);
      }
    }
    async function getExpertRecommendation() {
      let url = `${AppConstants.fetchRecommendationsForLearnerProfile}/${learnerSlug}`;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response !== undefined) {
        setRecommendationDetails(response);
      } else {
        setRecommendationDetails([]);
        navigate(errorPageUrl);
      }
    }
    Auth.isLogin() ? getLearnerDetails() : getUnauthorisedLearnerDetails();
    getExpertRecommendation();
    getLearnerAchievements();
  }, [toggleUpdate]);

  useEffect(() => {
    const fetchChampionshipData = async () => {
      try {
        const url = `${AWARDS_CONSTANTS.getAwardsOfLearnerApi}?userSlug=${learnerSlug}`;

        // Use either authorized or normal data service based on login status
        const response = Auth.isLogin()
          ? await AuthorizedDataService.get(url)
          : await DataService.get(url, '', '');

        if (response) {
          setChampionshipData(response);
        }
      } catch (error) {
        console.error('Error fetching championship data:', error);
      } finally {
        setChampionshipLoading(false);
      }
    };

    fetchChampionshipData();
  }, [learnerSlug, toggleUpdate]);

  const isChampionOfTheDay = () => {
    const today = moment().format('YYYY-MMM-DD');
    return championshipData.some(
      (champ) => moment(champ.awardedOn).format('YYYY-MMM-DD') === today
    );
  };

  const getTodaysChampionship = () => {
    const today = moment().format('YYYY-MMM-DD');
    return championshipData.filter(
      (champ) => moment(champ.awardedOn).format('YYYY-MMM-DD') === today
    );
  };

  //Handle Introduction Update
  const saveLearnerIntroduction = async (data) => {
    let url = `${AppConstants.updateLearnerProfileApi}`;
    const learnerDetailsDto = {
      aboutLearner: data,
      updateSection: 'About Learner',
    };
    const response = AuthorizedDataService.post(
      url,
      learnerDetailsDto,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    if (response !== undefined) {
      if (response.ok && response.data === 1) {
        setToggleUpdate(!toggleUpdate);
      }
      setLearnerIntroduction(data);
    } else {
    }
  };

  //Handle Album Update
  const saveMyAlbumMedia = async (data, action) => {
    let url = `${AppConstants.updateLearnerProfileApi}`;
    const learnerDetailsDto = {
      updateSection: 'My Album',
      galleryMediaPaths: data,
    };
    const response = AuthorizedDataService.post(
      url,
      learnerDetailsDto,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    if (response !== undefined) {
      if (response.ok && response.data === 1) {
        setToggleUpdate(!toggleUpdate);
      }
      if (action === AppConstants.createText) {
        setAlbumSaved(AppConstants.trueText);
      } else {
        setAlbumSavedAlert(AppConstants.trueText);
      }
      setMyAlbumImageArray(data);
    } else {
      setAlbumSavedAlert(AppConstants.falseText);
    }
  };

  const saveLearnerShortDescription = async () => {
    if (formValues.shortDescription.trim() === '') {
      setTaglineError(AppConstants.trueText);
      return;
    }

    let url = `${AppConstants.updateLearnerProfileApi}`;
    const learnerDetailsDto = {
      updateSection: 'Short Description',
      shortDescription: formValues.shortDescription.trim(),
    };

    try {
      const responsePromise = AuthorizedDataService.post(
        url,
        learnerDetailsDto,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      const response = await responsePromise;
      if (response.ok) {
        setTaglineEdit(AppConstants.falseText);
        setTaglineError(AppConstants.falseText);
        setLearnerShortDescription(formValues.shortDescription);
        toast.success('Short description updated successfully', {
          style: { width: 'auto', minWidth: '200px', maxWidth: '400px' },
        });
      } else {
        toast.dismiss();
        toast.error('Short description update failed', {
          style: { width: 'auto', minWidth: '200px', maxWidth: '400px' },
        });
      }
    } catch (error) {
      toast.dismiss();
      toast.error('Short description update failed', {
        style: { width: 'auto', minWidth: '200px', maxWidth: '400px' },
      });
      console.error('Error updating short description:', error);
    }
  };

  const updateSaveAlbumFlag = (activeFlag) => {
    setAlbumSaved(activeFlag);
  };
  const updateAlertFlag = () => {
    setAlbumSavedAlert(AppConstants.emptyString);
  };

  //handle form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleTaglineEdit = () => {
    setTaglineEdit(AppConstants.trueText);
  };

  return (
    <>
      {learnerDetailsLoader && (
        <div className={classes.errorContainer}>
          <p className="loaderIcon">
            <BiLoaderAlt className="spinner" />
          </p>
        </div>
      )}
      {!learnerDetailsLoader && (
        <div className={classes.shareIconPannelContainer}>
          <ShareLinkToSocialMediaUtil
            className={classes}
            linkToShare={linkToShare}
          />
        </div>
      )}
      {!learnerDetailsLoader && (
        <div className={classes.learnerProfileContainer}>
          <div className={classes.learnerInformationSection}>
            <Banner learnerDetails={learnerDetails} />

            <div className={classes.learnerDetailSection}>
              <div className={classes.learnerDetailsInnerSection}>
                {learnerDetails.learnerUserImagePath !== null && (
                  <div className={classes.profileImageSection}>
                    <img
                      src={learnerDetails.learnerUserImagePath}
                      className={classes.profileImage}
                      alt="Profile"
                    />
                  </div>
                )}
                {learnerDetails.learnerUserImagePath == null && (
                  <div className={classes.profileImageSection}>
                    <div className={classes.userInititals}>
                      {learnerDetails.learnerNameInitials}
                    </div>
                  </div>
                )}
                <div className={classes.aboutLearnerSection}>
                  <h1 className={classes.learnerDetailsHeading}>
                    {learnerDetails.learnerName}
                    {!championshipLoading && isChampionOfTheDay() && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="champion-tooltip">
                            <strong>Awarded for excellence in:</strong>
                            <br />
                            {getTodaysChampionship().length > 0
                              ? getTodaysChampionship()
                                .map((course) => course.courseTitle)
                                .join(', ')
                              : ''}
                          </Tooltip>
                        }
                      >
                        <span
                          className={`${classes.championBadge} ${classes.statusWrapper}`}
                        >
                          🏆 Champion of the Day
                        </span>
                      </OverlayTrigger>
                    )}
                  </h1>
                  {!taglineEdit && (
                    <div className={classes.taglineSection}>
                      {learnerDetails.editAllowed && <p className={classes.learnerDetailsSubHeading}>
                        {learnerShortDescription != null ? learnerShortDescription : "Add a tagline that represents you"}
                      </p>}

                      {!learnerDetails.editAllowed && <p className={classes.learnerDetailsSubHeading}>
                        {learnerShortDescription}
                      </p>}
                      {learnerDetails.editAllowed && (
                        <Button
                          className={` ${classes.editButton} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                          onClick={handleTaglineEdit}
                        >
                          <FaEdit /> Edit
                        </Button>
                      )}
                    </div>
                  )}

                  {learnerDetails.editAllowed && taglineEdit && (
                    <div className={` ${classes.editTaglineInputSection}`}>
                      <div className={` ${classes.editTaglineInputSection}`}>
                        <div className={classes.editTaglineInput}>
                          <Form.Control
                            maxLength={100}
                            placeholder="Tell us about yourself"
                            name="shortDescription"
                            value={formValues.shortDescription}
                            type="text"
                            onChange={handleChange}
                          />
                        </div>
                        <Button
                          className={` ${classes.updateButton} btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                          onClick={saveLearnerShortDescription}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  )}
                  {taglineError && (
                    <div className={classes.errorMessage}>
                      Please enter a valid tagline
                    </div>
                  )}
                  <div className={classes.awardSectionDesktopView}>
                    <ProgressBar
                      className={classes.learnerProgressBar}
                      variant="warning"
                      now={100}
                    />
                    <Row className={classes.learnerAwardsSection}>
                      <Col
                        className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                      >
                        <div>
                          <GiMeepleCircle className={classes.awardIcon} />
                        </div>
                        <div className={classes.awardContainers}>
                          <p className={classes.awardTitle}>
                            {learnerDetails.berylsEarned
                              ? learnerDetails.berylsEarned
                              : 0}
                          </p>
                          <p className={classes.awardSubTitle}>Beryls</p>
                        </div>
                      </Col>
                      <Col
                        className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                      >
                        <div>
                          <PiMedalBold className={classes.awardIcon} />
                        </div>
                        <div className={classes.awardContainers}>
                          <p className={classes.awardTitle}>
                            {learnerDetails.meritCount
                              ? learnerDetails.meritCount
                              : 0}
                          </p>
                          <p className={classes.awardSubTitle}>
                            Merit Posititons
                          </p>
                        </div>
                      </Col>
                      <Col
                        className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                      >
                        <div>
                          <GiOpenBook className={classes.awardIcon} />
                        </div>
                        <div className={classes.awardContainers}>
                          <p className={classes.awardTitle}>
                            {learnerDetails.coursesEnrolled
                              ? learnerDetails.coursesEnrolled
                              : 0}
                          </p>
                          <p className={classes.awardSubTitle}>Courses</p>
                        </div>
                      </Col>
                      <Col
                        className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                      >
                        <div>
                          <PiTrophyFill className={classes.awardIcon} />
                        </div>
                        <div className={classes.awardContainers}>
                          <p className={classes.awardTitle}>
                            {learnerDetails.competitionsEnrolled
                              ? learnerDetails.competitionsEnrolled
                              : 0}
                          </p>
                          <p className={classes.awardSubTitle}>Competitions</p>
                        </div>
                      </Col>
                      <Col
                        className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                      >
                        <div>
                          <IoIosSettings className={classes.awardIcon} />
                        </div>
                        <div className={classes.awardContainers}>
                          <p className={classes.awardTitle}>
                            {learnerDetails.workshopsEnrolled
                              ? learnerDetails.workshopsEnrolled
                              : 0}
                          </p>
                          <p className={classes.awardSubTitle}>Workshops</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className={classes.awardSectionMobileView}>
                <ProgressBar
                  className={classes.learnerProgressBar}
                  variant="warning"
                  now={100}
                />
                <Row className={classes.learnerAwardsSection}>
                  <Col
                    className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                  >
                    <div>
                      <GiMeepleCircle className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {learnerDetails.berylsEarned
                          ? learnerDetails.berylsEarned
                          : 0}
                      </p>
                      <p className={classes.awardSubTitle}>Beryls</p>
                    </div>
                  </Col>
                  <Col
                    className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                  >
                    <div>
                      <PiMedalBold className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {learnerDetails.meritCount
                          ? learnerDetails.meritCount
                          : 0}
                      </p>
                      <p className={classes.awardSubTitle}>Merit Posititons</p>
                    </div>
                  </Col>
                  <Col
                    className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                  >
                    <div>
                      <GiOpenBook className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {learnerDetails.coursesEnrolled
                          ? learnerDetails.coursesEnrolled
                          : 0}
                      </p>
                      <p className={classes.awardSubTitle}>Courses</p>
                    </div>
                  </Col>
                  <Col
                    className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                  >
                    <div>
                      <PiTrophyFill className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {learnerDetails.competitionsEnrolled
                          ? learnerDetails.competitionsEnrolled
                          : 0}
                      </p>
                      <p className={classes.awardSubTitle}>Competitions</p>
                    </div>
                  </Col>
                  <Col
                    className={`${classes.awardSection} col-xs-4 col-sm-4 col-md-3 col-lg-2`}
                  >
                    <div>
                      <IoIosSettings className={classes.awardIcon} />
                    </div>
                    <div className={classes.awardContainers}>
                      <p className={classes.awardTitle}>
                        {learnerDetails.workshopsEnrolled
                          ? learnerDetails.workshopsEnrolled
                          : 0}
                      </p>
                      <p className={classes.awardSubTitle}>Workshops</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <LearnerProfileIntroduction
            learnerDetails={learnerDetails}
            onSaveLearnerIntroduction={saveLearnerIntroduction}
          ></LearnerProfileIntroduction>{' '}
          <LearnerAchievements
            learnerAchievements={learnerProfileAchievementCards}
            learnerActivityCards={learnerProfileActivityCards}
            editAllowed={learnerDetails.editAllowed}
            learnerQrenciaId={learnerDetails.learnerQrenciaId}
            updateAchievements={updateAchievements}
            learnerSlug={learnerSlug}
          />
          {(learnerDetails.editAllowed ||
            recommendationDetails.filter((a) => a.isActiveFlag === 'Y').length >
            0) && (
              <LearnerExpertRecommendation
                recommendationDetails={recommendationDetails}
                triggerRefresh={triggerRefresh}
                updateRecommendations={updateRecommendations}
                learnerSlug={learnerSlug}
                editAllowed={learnerDetails.editAllowed}
              />
            )}
          {(learnerDetails.editAllowed || myAlbumImageArray?.length > 0) && (
            <LearnerProfileMyAlbum
              learnerDetails={learnerDetails}
              myAlbumImageArray={myAlbumImageArray}
              onSaveLearnerMyAlbum={saveMyAlbumMedia}
              albumSaved={albumSaved}
              albumSavedAlert={albumSavedAlert}
              updateSaveAlbumFlag={updateSaveAlbumFlag}
              updateAlertFlag={updateAlertFlag}
            />
          )}
          <div>
            {learnerProfileHeatmapData?.length > 0 ? (
              <Heatmap />
            ) : (
              <EmptyHeatmap />
            )}
          </div>
          {learnerDetails.skillGardenImagePath && (
            <SkillGarden
              skillGardenPath={learnerDetails.skillGardenImagePath}
            />
          )}
        </div>
      )}
    </>
  );
};
