import React, { useEffect, useState } from 'react';
import classes from '../LearnerProfileLayout.module.css';
// import styles from '.././LearnerProfileLayout.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { Button, Modal, Form } from 'react-bootstrap';
import Auth from '../../../utils/Auth';
import { FileOperationsUtil } from '../../../utils/FileOperationsUtil';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import toast from 'react-hot-toast';
import { FiEdit } from 'react-icons/fi';
import { LuPencil } from 'react-icons/lu';
import formClass from '../../../common/FormStyling.module.css';
import { RiInformationFill } from 'react-icons/ri';
import { Tooltip } from 'react-tooltip';
import parse from 'html-react-parser';

const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
const defaultBannerImage = `${mediaContent}${AppConstants.bannerImagesS3Path}/${AppConstants.learnerProfileDefaultBanner}`;

const Banner = ({ learnerDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageVersion, setImageVersion] = useState(Date.now());
  const [fileError, setFileError] = useState('');
  const [bannerImagePath, setBannerImagePath] = useState(
    learnerDetails.bannerImagePath || defaultBannerImage
  );

  // Open Modal
  const handleShow = () => setShowModal(true);

  // Close Modal
  const handleClose = () => {
    setSelectedFile(null);
    setFileError('');
    setShowModal(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ['png', 'jpg', 'jpeg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setSelectedFile(file);
        setFileError('');
      } else {
        setSelectedFile(null);
        setFileError('Only PNG, JPG and JPEG files are allowed.');
      }
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
      let fileName = 'cover-image';
      const fileExtension = selectedFile.name.split('.').pop();
      const coverImagePath = `learner-profile/${Auth.getLoggedInUserDetails().userId}/${fileName}.${fileExtension}`;

      const result = FileOperationsUtil.uploadFileThroughPreSignedURL(
        selectedFile,
        coverImagePath,
        AppConstants.s3filePermissionPublic,
        `attachment;filename="${fileName}"`
      );

      toast.promise(
        result.then(async () => {
          const response = await AuthorizedDataService.post(
            AppConstants.updateLearnerProfileApi,
            { updateSection: 'Banner Image', bannerImagePath: coverImagePath }
          );

          if (response) {
            setBannerImagePath(`${mediaContent}${coverImagePath}`);
            setImageVersion(Date.now()); // Force UI refresh
          } else {
            console.error(`The cover image could not be updated`);
          }
        }),
        {
          loading: 'Updating your banner image...',
          success: 'Cover image updated successfully!',
          error: 'Cover image update failed!',
        }
      );

      handleClose();
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  return (
    <div className={classes.learnerBannerSection}>
      <img
        src={`${bannerImagePath}?v=${imageVersion}`}
        alt="Learner Profile Banner"
        className={classes.learnerProfileBannerImage}
      />

      <div className={classes.learnerBannerTitleSection}>
        {!learnerDetails.bannerImagePath && (
          <h1 className={classes.bannerTitle}>{learnerDetails.learnerName} </h1>
        )}
      </div>
      {learnerDetails.editAllowed && (
        <Button
          variant="primary"
          onClick={handleShow}
          className={classes.editImageButton}
        >
          <LuPencil className={classes.editBanner} />
        </Button>
      )}

      {/* Upload Image Modal */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton className={classes.achievmentHeader}>
          <Modal.Title className={classes.achievmentTitle}>
            Upload your cover image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className={`${classes.uploadFormLabel} `}>
            Upload a cover image to be showcased on your profile.
          </Form.Label>
          <RiInformationFill
            className={classes.infoButton}
            data-tooltip-id="bannerTypeInfo"
          />
          <Tooltip className={classes.tooltipMessage} id="bannerTypeInfo">
            <span className={classes.tooltipMessage}>
              {parse(AppConstants.bannerTypeDropdownInfo)}
            </span>
          </Tooltip>
          {/* Upload File Area */}
          <Form.Group>
            <Form.Control
              className={classes.uploadForm}
              type="file"
              onChange={handleFileChange}
            />
          </Form.Group>
          {fileError && <div className="text-danger">{fileError}</div>}
          <div className={classes.uploadInfo}>
            <small className={classes.leftText}>
              Max size: 5MB, Supported formats: JPG, PNG and JPEG
            </small>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selectedFile && (
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          )}
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={!selectedFile || !!fileError}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Banner;
