import { Nav, Navbar, Badge } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { TbReportAnalytics } from 'react-icons/tb';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { RiFileUserLine } from 'react-icons/ri';
import {
  FaRegBell,
  FaChalkboardTeacher,
  FaRegUserCircle,
  FaRegCommentDots,
} from 'react-icons/fa';
import classes from '../StudentPageLayout/StudentLeftNav.module.css';
import { AppConstants } from '../../constants/AppConstants';
import Auth from '../../utils/Auth';
import '../../common/TemplateStyle.css';
import { useLearnerPassStore } from '../../stores';

export const StudentLeftNav = (props) => {
  const { learnerPassSubscriptionStatus } = useLearnerPassStore();
  const [expanded, setExpanded] = useState(false);
  const icons = AppConstants.qrenciaApplicationIcons;

  const getIconPath = (icon) => {
    return `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.iconImagesS3Path}${AppConstants.forwardSlash}${icon}`;
  };

  return (
    <>
      <Navbar expanded={expanded} expand="lg" className="sideNavigation">
        <Navbar.Toggle
          className="sidebarToggleButton"
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />
        <Navbar.Collapse className="sideNavigationContent">
          <Nav className="sideNavigationListSection">
            <div className="profileSection">
              <div className="profileImageDiv">
                {Auth.getLoggedInUserDetails().profileImagePreSignedUrl !=
                  null && (
                    <div>
                      <img
                        src={
                          Auth.getLoggedInUserDetails().profileImagePreSignedUrl
                        }
                        className="profileImage"
                        alt="Profile"
                      />
                    </div>
                  )}
                {Auth.getLoggedInUserDetails().profileImagePreSignedUrl ==
                  null && (
                    <div>
                      <div className="userInitials">
                        {Auth.getLoggedInUserDetails().nameInitials.toUpperCase()}
                      </div>
                    </div>
                  )}
              </div>
              <div className="userNameDiv">
                <p className="userName">{Auth.getLoggedInName()}</p>
                <p className="qrenciaIdText">
                  QID: {Auth.getLoggedInUserDetails().userId}
                </p>
              </div>
            </div>
            {/* <div className={classes.competitionAndBerylSection}>
                            <div className={classes.competitionSection}>
                                <p className={classes.competitionCount}>0</p>
                                <p className={classes.competitionText}>Competitions</p>
                            </div>
                            <div className={classes.berylSeprationBorder}></div>
                            <div className={classes.competitionSection}>
                                <p className={classes.competitionCount}>0</p>
                                <p className={classes.competitionText}>Beryls</p>
                            </div>
                        </div> */}
            <div className="referButtonSection">
              {/* <div className="referButtonInnerSection">
                                <Button className="referButton">REFER A FRIEND</Button>
                            </div> */}
              <div className="notificationSidebarSection">
                <FaRegBell className="notificationSidebarImage" />
                <Badge bg="danger" className="notificationSidebarBadge">
                  0
                </Badge>
              </div>
            </div>

            <Nav.Item
              className="sideNavigationItem"
              onClick={() => setExpanded(false)}
            >
              <NavLink
                to={AppConstants.studentHomePath}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                <FaRegUserCircle className="linkIcons" />
                <p className="navigationLinks">Home</p>
              </NavLink>
            </Nav.Item>
            <div className="sideNavigationItemMobileView">
              <Nav.Item
                className="sideNavigationItem"
                onClick={() => setExpanded(false)}
              >
                <NavLink
                  to={AppConstants.studentMyLearningPath}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  <HiOutlineBookOpen className="linkIcons" />
                  <p className="navigationLinks">My Learning</p>
                </NavLink>
              </Nav.Item>
            </div>
            <div className="sideNavigationItemMobileView">
              <Nav.Item
                className="sideNavigationItem"
                onClick={() => setExpanded(false)}
              >
                <NavLink
                  to={AppConstants.studentMyAssessementPath}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  <TbReportAnalytics className="linkIcons" />
                  <p className="navigationLinks">My Assessments</p>
                </NavLink>
              </Nav.Item>
            </div>
            {/* <div className="sideNavigationItemMobileView disabledSideNavigationComponent">
                            <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                <NavLink
                                    to={AppConstants.studentMyAnalysisPath}
                                    className={({ isActive }) =>
                                        isActive ? "active" : undefined
                                    }
                                ><TbReportAnalytics className="linkIcons" />
                                    <p className="navigationLinks">My Analysis</p>
                                </NavLink>
                            </Nav.Item>
                        </div> */}
            <div className="sideNavigationItemMobileView">
              {learnerPassSubscriptionStatus === 'Active' ? (
                <>
                  <Nav.Item
                    className="sideNavigationItem"
                    onClick={() => setExpanded(false)}
                  >
                    <NavLink
                      to={`/learner/${Auth.getLoggedInUserDetails().userSlug}`}
                      className={({ isActive }) =>
                        isActive ? 'active' : undefined
                      }
                    >
                      <RiFileUserLine className="linkIcons" />
                      <p className="navigationLinks">My Profile </p>
                      <div
                        className={classes.statusWrapper}
                        style={{ backgroundColor: '#E77A7B', color: '#ffffff' }}
                      >
                        <div className={classes.statusChipText}>NEW</div>
                      </div>
                    </NavLink>
                  </Nav.Item>
                </>
              ) : null}
              {learnerPassSubscriptionStatus === 'Active' ? (
                <>
                  <Nav.Item
                    className="sideNavigationItem"
                    onClick={() => setExpanded(false)}
                  >
                    <NavLink
                      to={`https://calendly.com/qrenciaforlearners/passioncompanion`} target="_blank"
                      className={({ isActive }) =>
                        isActive ? 'active' : undefined
                      }
                    >
                      <RiFileUserLine className="linkIcons" />
                      <p className="navigationLinks">My Companion </p>
                      <div
                        className={classes.statusWrapper}
                        style={{ backgroundColor: '#E77A7B', color: '#ffffff' }}
                      >
                        <div className={classes.statusChipText}>NEW</div>
                      </div>
                    </NavLink>
                  </Nav.Item>
                </>
              ) : null}
            </div>
            <Nav.Item
              className="sideNavigationItem"
              onClick={() => setExpanded(false)}
            >
              <NavLink
                to={AppConstants.studentCompetitionsPath}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                {({ isActive }) => (
                  <>
                    <img
                      src={getIconPath(
                        isActive
                          ? icons.competitionIconWhite
                          : icons.competitionIcon
                      )}
                      alt="icon"
                      className={`linkIcons ${classes.logoImages}`}
                    />
                    <p className="navigationLinks">Competitions</p>
                  </>
                )}
              </NavLink>
            </Nav.Item>
            <Nav.Item
              className="sideNavigationItem"
              onClick={() => setExpanded(false)}
            >
              <NavLink
                to={AppConstants.studentCoursesPath}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                {({ isActive }) => (
                  <>
                    <img
                      src={getIconPath(
                        isActive ? icons.courseIconWhite : icons.courseIcon
                      )}
                      alt="icon"
                      className={`linkIcons ${classes.logoImages}`}
                    />
                    <p className="navigationLinks">Courses</p>
                  </>
                )}
              </NavLink>
            </Nav.Item>
            <Nav.Item
              className="sideNavigationItem"
              onClick={() => setExpanded(false)}
            >
              <NavLink
                to={AppConstants.studentNudgesPath}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                {({ isActive }) => (
                  <>
                    <img
                      src={getIconPath(
                        isActive ? icons.nudgeIconWhite : icons.nudgeIcon
                      )}
                      alt="icon"
                      className={`linkIcons ${classes.logoImages}`}
                    />
                    <p className="navigationLinks">Nudges</p>
                  </>
                )}
              </NavLink>
            </Nav.Item>
            <Nav.Item
              className="sideNavigationItem"
              onClick={() => setExpanded(false)}
            >
              <NavLink
                to={AppConstants.studentWorkshopsPath}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                {({ isActive }) => (
                  <>
                    <img
                      src={getIconPath(
                        isActive ? icons.workshopIconWhite : icons.workshopIcon
                      )}
                      alt="icon"
                      className={`linkIcons ${classes.logoImages}`}
                    />
                    <p className="navigationLinks">Workshops</p>
                  </>
                )}
              </NavLink>
            </Nav.Item>
            <Nav.Item
              className="sideNavigationItem disabledSideNavigationComponent"
              onClick={() => setExpanded(false)}
            >
              <NavLink
                to={AppConstants.studentQtalksPath}
                className={({ isActive }) => (isActive ? 'active' : undefined)}
              >
                <FaRegCommentDots className="linkIcons" />
                <p className="navigationLinks">Qtalks</p>
              </NavLink>
            </Nav.Item>
            {props?.associationStatus === AppConstants.trueText && (
              <Nav.Item
                className="sideNavigationItem"
                onClick={() => setExpanded(false)}
              >
                <NavLink
                  to={AppConstants.studentMySchoolPath}
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  <FaChalkboardTeacher className="linkIcons" />
                  <p className="navigationLinks">My School</p>
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
