import { Row } from 'react-bootstrap'
import { AppConstants } from '../../../constants/AppConstants';
import cardStyles from "./ExpressionOfInterestCardsSection.module.css";
export const ExpressionOfInterestCardsSection = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    return (
        <>
            <div className={cardStyles.profitCard}>
                <div className={cardStyles.section}>
                    <p className={cardStyles.sectionDesc}>{AppConstants.maximizeYourProfits}</p>
                </div>
                <Row>
                    {AppConstants.profitCards.map((card, index) => (
                        <div key={index} className={`${cardStyles.cardStatus} col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12`}>
                            <img className={cardStyles.cardImage} src={`${mediaContent}${card.eoiCardSectionImage}`} alt={`Card : ${card.cardId}`}></img>
                            <h3 className={cardStyles.cardHeading}>
                                <strong>{card.cardHeading.split(" ")[0]}</strong>{" "}
                                {card.cardHeading.split(" ").slice(1).join(" ")}
                            </h3>
                            <p className={cardStyles.cardDesc}>{card.cardDesc}</p>
                        </div>
                    ))}

                </Row>
            </div>
        </>
    )
}
