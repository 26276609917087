import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const paymentStore = create(
  persist(
    (set) => ({
      paymentId: '',
      setPaymentId: (id) => set({ paymentId: id }),
    }),
    { name: 'paymentStorage' }
  )
);

export default paymentStore;