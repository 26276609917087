import React, { useState, useEffect } from 'react';
import myAlbumStyle from './LearnerProfileMyAlbum.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { ImageGalleryModal } from '../../modal/ImageGalleryModal';
import { FileUploadInput } from '../../fileUploadInput/FileUploadInput';
import { FileOperationsUtil } from '../../../utils/FileOperationsUtil';
import { FaRegEye } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import { FaEdit } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { Button } from 'react-bootstrap';
import { ErrorSuccessAlertMessage } from './../../errorSuccessMessage/ErrorSuccessAlertMessage';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { SuccessMessage } from '../../../constants/SuccessMessage';

export const LearnerProfileMyAlbum = ({
  learnerDetails,
  updateAlertFlag,
  myAlbumImageArray,
  onSaveLearnerMyAlbum,
  albumSavedAlert,
  albumSaved,
  updateSaveAlbumFlag,
}) => {
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
  const galleryMediaArray =
    myAlbumImageArray && myAlbumImageArray.trim() !== ''
      ? myAlbumImageArray.split(',').map((item) => item.trim())
      : [];
  const [selectedMediaGallery, setSelectedMediaGallery] = useState([]);
  const [showMediaGallery, setShowMediaGallery] = useState(
    AppConstants.falseText
  );
  const [showSelectedMediaGallery, setShowSelectedMediaGallery] = useState(
    AppConstants.falseText
  );
  const [selectedMedia, setSelectedMedia] = useState(AppConstants.emptyString);
  const [showEditMediaGallery, setShowEditMediaGallery] = useState(
    AppConstants.falseText
  );
  const [myAlbumImageUploadError, setMyAlbumImageUploadError] = useState(null);
  const [allAlbumFiles, setAllAlbumFiles] = useState(galleryMediaArray);
  const validMediaTypes = [
    ...AppConstants.promotionalVideoAllowedFormats,
    ...AppConstants.supportedImageFormats,
  ];
  const validMediaTypesString = validMediaTypes.join(',');
  const [disableUpload, setDisableUpload] = useState(AppConstants.trueText);
  const [editGalleryMediaPaths, setEditGalleryMediaPaths] = useState(
    AppConstants.emptyString
  );
  const [cleanGalleryMediaPaths, setCleanGalleryMediaPaths] = useState(
    AppConstants.emptyString
  );
  const [showEditButton, setShowEditButton] = useState(AppConstants.falseText);
  const [albumSavingLoader, setAlbumSavingLoader] = useState(
    AppConstants.falseText
  );
  const [showAlertMessage, setShowAlertMessage] = useState(
    AppConstants.emptyString
  );
  const [alertVariant, setAlertVariant] = useState(AppConstants.emptyString);

  useEffect(() => {
    setAllAlbumFiles(
      myAlbumImageArray && myAlbumImageArray.trim() !== ''
        ? myAlbumImageArray.split(',').map((item) => item.trim())
        : []
    );
    setShowEditButton(
      myAlbumImageArray && myAlbumImageArray.trim() !== ''
        ? AppConstants.trueText
        : AppConstants.falseText
    );
  }, [myAlbumImageArray]);

  useEffect(() => {
    setDisableUpload(selectedMediaGallery.length === 0);
  }, [selectedMediaGallery]);

  useEffect(() => {
    if (albumSaved) {
      setSelectedMediaGallery([]);
    }
    const timer = setTimeout(() => {
      updateSaveAlbumFlag(AppConstants.falseText);
    }, 1000);
    return () => clearTimeout(timer);
  }, [albumSaved]);

  const uploadFiles = async () => {
    setAlbumSavingLoader(AppConstants.trueText);
    try {
      let myAlbumMediaPaths = AppConstants.emptyString;
      let myAlbumMedia = [];
      let params = [];
      selectedMediaGallery.forEach((media) => {
        if (media.file?.name) {
          params.push({
            file: media.file,
            previousPath: AppConstants.nullText,
          });
        }
      });
      for (let i = AppConstants.zeroIndex; i < params.length; i++) {
        let { file } = params[i];
        let path = AppConstants.emptyString;
        // replace white spaces with emptystring
        let fileName = file.name.replace(/\s+/g, AppConstants.emptyString);
        path = getFileNameWithExt(fileName);
        let response = await FileOperationsUtil.uploadFileThroughPreSignedURL(
          file,
          path,
          AppConstants.s3filePermissionPublic,
          AppConstants.s3FileContentDispositionInline
        );
        if (response instanceof Error) {
          setAlertVariant(AppConstants.alertVarient[1]);
          setShowAlertMessage(ErrorMessages.uploadAlbumError);
          let timer = setTimeout(() => {
            setShowAlertMessage(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);

          return () => clearTimeout(timer);
        }
        myAlbumMedia.push(response.objectKey);
      }
      myAlbumMedia.push(
        ...selectedMediaGallery
          .filter((media) => !media.file?.name)
          .map((value) => value.relativePath)
      );
      myAlbumMediaPaths = myAlbumMedia
        .slice()
        .reverse()
        .concat(allAlbumFiles)
        .join(',');
      onSaveLearnerMyAlbum(myAlbumMediaPaths, AppConstants.createText);
      setSelectedMedia([]);
      setAlbumSavingLoader(AppConstants.falseText);
      setShowAlertMessage(AppConstants.trueText);
      setAlertVariant(AppConstants.alertVarient[0]);
      setShowAlertMessage(SuccessMessage.uploadMyAlbumSuccess);
      setTimeout(() => {
        setShowAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
      return myAlbumMediaPaths;
    } catch (error) {
      setAlertVariant(AppConstants.alertVarient[1]);
      setShowAlertMessage(ErrorMessages.uploadAlbumError);
      let timer = setTimeout(() => {
        setShowAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);

      return () => clearTimeout(timer);
    }
  };

  const getFileNameWithExt = name => {
    const ext = name.trim().split('.').pop();
    const baseName = name
      .trim()
      .split('.')
      .slice(0, -1)
      .join('.')
      .replace(/[^a-zA-Z0-9]/g, ''); // Remove spaces and special characters

    const newName =
      AppConstants.learnerDetailsFolder +
      AppConstants.myAlbumImageFolder +
      learnerDetails.learnerQrenciaId +
      '/' +
      baseName +
      AppConstants.dot +
      ext;

    return newName;
  };

  const handleMediaGalleryShow = (value) => {
    setShowMediaGallery(AppConstants.trueText);
    setSelectedMedia(value);
  };
  const hanldeGalleryClose = () => {
    setEditGalleryMediaPaths(
      myAlbumImageArray
        ? myAlbumImageArray
          ?.replace(/[()]/g, '')
          .split(',')
          .map((item) => {
            const trimmedItem = item.trim();
            return {
              url: `${mediaContent}` + trimmedItem,
              type: determineMediaType(trimmedItem),
              id: Math.floor(Math.random() * 100000 + 1).toString(),
              file: trimmedItem,
            };
          })
        : []
    );
    setShowEditButton(
      myAlbumImageArray && myAlbumImageArray.trim() !== ''
        ? AppConstants.trueText
        : AppConstants.falseText
    );
    setSelectedMedia(AppConstants.emptyString);
    setShowMediaGallery(AppConstants.falseText);
    setShowSelectedMediaGallery(AppConstants.falseText);
    setShowEditMediaGallery(AppConstants.falseText);
    updateAlertFlag();
  };
  // Function to get file extension and determine type
  const determineMediaType = (filePath) => {
    const extension = filePath.split('.').pop().toLowerCase(); // Extract extension
    const mimeType = {
      mp4: 'video/mp4',
      mkv: 'video/mkv',
      m4v: 'video/x-m4v',
      mov: 'video/quicktime',
      ai: 'video/ai',
      ogg: 'video/ogg',
      webm: 'video/webm',
      avi: 'video/x-msvideo',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
    }[extension];

    return AppConstants.promotionalVideoAllowedFormats.includes(mimeType)
      ? AppConstants.mediaTypes.video
      : AppConstants.mediaTypes.image;
  };

  useEffect(() => {
    setCleanGalleryMediaPaths(
      myAlbumImageArray
        ? myAlbumImageArray
          ?.replace(/[()]/g, '')
          .split(',')
          .map((item) => {
            const trimmedItem = item.trim();
            return {
              url: `${mediaContent}` + trimmedItem,
              type: determineMediaType(trimmedItem),
              id: Math.floor(Math.random() * 100000 + 1).toString(),
              file: trimmedItem,
            };
          })
        : []
    );
    setEditGalleryMediaPaths(
      myAlbumImageArray
        ? myAlbumImageArray
          ?.replace(/[()]/g, '')
          .split(',')
          .map((item) => {
            const trimmedItem = item.trim();
            return {
              url: `${mediaContent}` + trimmedItem,
              type: determineMediaType(trimmedItem),
              id: Math.floor(Math.random() * 100000 + 1).toString(),
              file: trimmedItem,
            };
          })
        : []
    );
  }, [myAlbumImageArray]);

  useEffect(() => {
    setDisableUpload(selectedMediaGallery.length === 0 ? true : false);
  }, [selectedMediaGallery]);

  const handleImageUpload = (e) => {
    let selectedFiles = e.target.files;
    let filesArray = [];
    if (selectedFiles && selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (
          selectedFiles[i] &&
          validMediaTypes.includes(selectedFiles[i].type)
        ) {
          filesArray.push({
            file: selectedFiles[i],
            path: getFileNameWithExt(selectedFiles[i].name),
            url: URL.createObjectURL(selectedFiles[i]),
            type: AppConstants.supportedImageFormats.includes(
              selectedFiles[i].type
            )
              ? 'Image'
              : 'Video',
            id: Math.floor(Math.random() * 100000 + 1).toString(),
          });
        }
      }

      setSelectedMediaGallery((prevGallery) => [...prevGallery, ...filesArray]);
    }
  };

  const viewGallery = () => {
    setShowSelectedMediaGallery(AppConstants.trueText);
  };

  const removeSelectedMedia = (media) => {
    let newMediaArray = selectedMediaGallery.filter(
      (value) => value.id !== media.id
    );
    setSelectedMediaGallery(newMediaArray);
    if (newMediaArray.length === 0) {
      setShowSelectedMediaGallery(AppConstants.falseText);
    }
  };

  const removeSelectedMediaByEditing = (media) => {
    let newMediaArray = editGalleryMediaPaths.filter(
      (value) => value.id !== media.id
    );
    setEditGalleryMediaPaths(newMediaArray);
  };

  const viewEditMediaGallery = () => {
    setShowEditMediaGallery(AppConstants.trueText);
  };

  const saveEditMyAlbumChanges = (data) => {
    const editPaths = data.map((item) => item.file).join(',');
    onSaveLearnerMyAlbum(editPaths, AppConstants.updateText);
  };

  return (
    <>
      <div className={myAlbumStyle.myAlbumContainer}>
        <h2>{AppConstants.LearnerProfileHeadingConstants.albumHeading}</h2>
        {learnerDetails.editAllowed && (
          <div
            className={`${myAlbumStyle.fileOBRUploadSection} mb-2 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
          >
            <div
              className={`${myAlbumStyle.fileOBRUpload} ${myAlbumStyle.fileOBRUploadInput} `}
            >
              <FileUploadInput
                id={AppConstants.myAlbumFileInputId}
                name="myalbumUploadedFile"
                accept="image/png,image/jpeg,image/jpg,video/mp4,video/mkv,video/x-m4v,video/quicktime,video/*"
                label="Choose File(s)"
                mandatory={true}
                handleFileChange={(e) => handleImageUpload(e)}
                // infoMessage={AppConstants.desiredDimension}
                inputFile={selectedMediaGallery}
                obrClasses={true}
                multiple={true}
                placeholder="Select images/videos for your album"
              />
              {selectedMediaGallery?.length > 0 && (
                <p
                  onClick={() => {
                    setSelectedMediaGallery([]);
                    const fileInput = document.getElementById(
                      AppConstants.myAlbumFileInputId
                    );
                    if (fileInput) {
                      fileInput.value = '';
                    }
                  }}
                  className="removeFileText"
                >
                  Remove files
                </p>
              )}
            </div>
            {selectedMediaGallery?.length > 0 && (
              <Button
                onClick={viewGallery}
                // data-tooltip-id="viewGallery"
                className={`${myAlbumStyle.viewButton}`}
              >
                View
              </Button>
            )}
            <div className={myAlbumStyle.galleryTooltip}>
              <Tooltip
                className={myAlbumStyle.tooltipMessageDownload}
                id="viewGallery"
              >
                <span className={myAlbumStyle.tooltipMessage}>View</span>
              </Tooltip>
            </div>
            <div className={`mb-2 col-lg-7 col-md-4 col-sm-4 col-xs-12`}>
              <Button
                onClick={uploadFiles}
                className={`${myAlbumStyle.uploadButton}`}
                disabled={disableUpload || albumSavingLoader}
              >
                Upload{' '}
                {albumSavingLoader && <BiLoaderAlt className="spinner" />}
              </Button>
              {showEditButton && learnerDetails.editAllowed && (
                <div className={myAlbumStyle.editButtonSection}>
                  <Button
                    onClick={viewEditMediaGallery}
                    className={` ${myAlbumStyle.editButton}  btn btn-primary d-flex align-items-center gap-2 py-2 px-3`}
                  >
                    <FaEdit className={myAlbumStyle.learnerProfileEditIcon} />{' '}Edit
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {!(myAlbumImageArray === null) &&
          cleanGalleryMediaPaths !== AppConstants.emptyString && (
            <div className={myAlbumStyle.mediaSection}>
              <div className={myAlbumStyle.mediaContentContainer}>
                {cleanGalleryMediaPaths?.slice(0, 4).map((value, index) =>
                  value.type === AppConstants.mediaTypes.image ? (
                    <div className={myAlbumStyle.imageBox} key={index}>
                      <img
                        className={`${myAlbumStyle.gallaryImage} ${cleanGalleryMediaPaths?.length >
                          AppConstants.micrositeGalleryImagesCount &&
                          index === AppConstants.micrositeGalleryImagesCount - 1
                          }`}
                        onClick={() => handleMediaGalleryShow(value)}
                        src={value.url}
                        alt="Gallery"
                      />
                      {cleanGalleryMediaPaths?.length >
                        AppConstants.micrositeGalleryImagesCount &&
                        index ===
                        AppConstants.micrositeGalleryImagesCount - 1 && (
                          <div
                            onClick={() => handleMediaGalleryShow(value)}
                            className={myAlbumStyle.overLay}
                          >
                            +{' '}
                            {cleanGalleryMediaPaths?.length -
                              AppConstants.micrositeGalleryImagesCount}
                          </div>
                        )}
                      {cleanGalleryMediaPaths?.length >
                        AppConstants.micrositeGalleryImagesCount &&
                        index ===
                        AppConstants.micrositeGalleryImagesCount - 1 && (
                          <div
                            onClick={() => handleMediaGalleryShow(value)}
                            className={myAlbumStyle.fadeImageBackground}
                          ></div>
                        )}
                    </div>
                  ) : (
                    <div className={myAlbumStyle.imageBox} key={value.url}>
                      <video
                        key={value.url}
                        controls={false}
                        autoPlay={false}
                        className={`${myAlbumStyle.gallaryImage} ${cleanGalleryMediaPaths?.length >
                          AppConstants.micrositeGalleryImagesCount &&
                          index === AppConstants.micrositeGalleryImagesCount - 1
                          }`}
                        onClick={() => handleMediaGalleryShow(value)}
                      >
                        <source src={`${value.url}?${new Date().getTime()}`} />
                      </video>
                      <img
                        onClick={() => handleMediaGalleryShow(value)}
                        className={myAlbumStyle.gallaryVideoPlayIcon}
                        src={videoPlayIconUrl}
                        alt="Video Play Button"
                      />
                      {cleanGalleryMediaPaths?.length >
                        AppConstants.micrositeGalleryImagesCount &&
                        index ===
                        AppConstants.micrositeGalleryImagesCount - 1 && (
                          <div
                            onClick={() => handleMediaGalleryShow(value)}
                            className={myAlbumStyle.overLay}
                          >
                            +{' '}
                            {cleanGalleryMediaPaths?.length -
                              AppConstants.micrositeGalleryImagesCount}
                          </div>
                        )}
                      {cleanGalleryMediaPaths?.length >
                        AppConstants.micrositeGalleryImagesCount &&
                        index ===
                        AppConstants.micrositeGalleryImagesCount - 1 && (
                          <div
                            onClick={() => handleMediaGalleryShow(value)}
                            className={myAlbumStyle.fadeImageBackground}
                          ></div>
                        )}
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        {showAlertMessage && (
          <ErrorSuccessAlertMessage
            message={showAlertMessage}
            varient={alertVariant}
          />
        )}
        {(myAlbumImageArray === null || myAlbumImageArray?.length == 0) && (
          <p className="noDataAvailableError">
            No uploads yet? Start filling your album with your proudest moments!
          </p>
        )}

        {showMediaGallery && (
          <ImageGalleryModal
            creatorGalleryContent={cleanGalleryMediaPaths}
            selectedMediaContent={selectedMedia}
            hanldeGalleryClose={hanldeGalleryClose}
            headerName={
              AppConstants.LearnerProfileHeadingConstants.albumHeading
            }
          />
        )}
        {showSelectedMediaGallery && (
          <ImageGalleryModal
            creatorGalleryContent={selectedMediaGallery}
            selectedMediaContent={selectedMedia}
            showDeleteIcon={true}
            showDownloadIcon={true}
            hanldeGalleryClose={hanldeGalleryClose}
            removeSelectedMedia={removeSelectedMedia}
            headerName={AppConstants.LearnerProfileHeadingConstants.albumHeading}
          />
        )}
        {showEditMediaGallery && (
          <ImageGalleryModal
            saveButton="Update"
            saveEditMyAlbum={saveEditMyAlbumChanges}
            creatorGalleryContent={editGalleryMediaPaths}
            hanldeGalleryClose={hanldeGalleryClose}
            showDeleteIcon={true}
            selectedMediaContent={selectedMedia}
            showDownloadIcon={true}
            headerName={
              AppConstants.LearnerProfileHeadingConstants.albumHeading
            }
            removeSelectedMedia={removeSelectedMediaByEditing}
            errorMessage="No more images in your album! Feel free to come back anytime to add new ones!"
            albumSavedAlert={albumSavedAlert}
          />
        )}
      </div>
    </>
  );
};
