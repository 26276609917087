import { useLocation } from 'react-router-dom';
import { MarkAttendanceRightWidget } from '../../component/widgets/MarkAttendanceRightWidget';
import classes from './ExpertRightWidgetSpace.module.css';
export const ExpertRightWidgetSpace = () => {
    const location = useLocation();

    if(location.pathname === '/expert/student-fee-details' || location.pathname === '/expert/student-pii/') {
        return null;
    }

    return (
        <>
            <div className={classes.widgetSection}>
                <MarkAttendanceRightWidget />
            </div>
        </>
    )
}