import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  Button,
} from 'react-bootstrap';
import classes from './StudentsToBeAwardedCards.module.css';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { BiLoaderAlt } from 'react-icons/bi';
import { AppConstants, AWARDS_CONSTANTS } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { successMessageChampionship } from '../../constants/SuccessMessage';
import { ErrorSuccessAlertMessage } from '../errorSuccessMessage/ErrorSuccessAlertMessage';
import { SearchCourseAndStudent } from '../../component/formControls/SearchCourseAndStudent';
import { RxCross2 } from 'react-icons/rx';
import moment from 'moment';
import { GiCancel } from 'react-icons/gi';

export const StudentsToBeAwardedCards = (props) => {
  const [errorSuccessAlertVisiblity, setErrorSuccessAlertVisiblity] = useState(
    AppConstants.falseText
  );
  const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState(
    AppConstants.emptyString
  );
  const [errorSuccessAlertMessage, setErrorSuccessAlertMessage] = useState(
    AppConstants.emptyString
  );
  const [detailsUpdated, setDetailsUpdated] = useState(AppConstants.falseText);
  const [studentArray, setStudentArray] = useState(props.studentList);
  const [cardCount, setCardCount] = useState(16);
  const today = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [studentsOfCourse, setStudentsOfCourse] = useState(props.studentList);
  const [courseList, setCourseList] = useState(
    props.ongoingCourseList.concat(props.pastCourseList)
  );
  const [searchedStudent, setSearchedStudent] = useState('');
  const [searchedCourse, setSearchedCourse] = useState(0);
  const [pastAwardsByExpert, setPastAwardsByExpert] = useState(
    props.awardsByExpertsHistory
  );

  const contentRef = useRef();
  const isMobile = window.innerWidth <= 768;
  //Hide error/success messages after 7 seconds
  function hideErrorMessage() {
    let timer = setTimeout(() => setErrorSuccessAlertVisiblity(false), 7000);
    return () => {
      clearTimeout(timer);
    };
  }

  const isFutureDate = (date) => {
    const today = new Date().toISOString().split('T')[0];
    return date > today;
  };

  const validatedDate = isFutureDate(selectedDate) ? today : selectedDate;

  const [cardDisplayedCount, setCardDisplayedCount] = useState(16);
  const { lastElementRef, loading } = useInfiniteScroll(() => {
    setCardCount((prevCount) => prevCount + cardDisplayedCount);
  }, [props.studentList]);

  //get initials for student
  const getInitials = (name) => {
    if (!name) return '?';
    const nameParts = name.trim().split(' ');
    return nameParts
      .map((part) => part.charAt(0).toUpperCase())
      .join('')
      .substring(0, 2); // Get first two initials
  };
  //Initialise student array , student list filtered on course and course List  array and awards by experts
  useEffect(() => {
    setCardDisplayedCount(16);

    setCourseList(props.ongoingCourseList.concat(props.pastCourseList));
    setSearchedCourse(0);
    // setSelectedDate(today);
    setPastAwardsByExpert(props.awardsByExpertsHistory);

    // const filteredAwardedStudent = props.awardsByExpertsHistory
    //   .filter((student) => student.awardedOn === selectedDate)
    //   .map((student) => ({
    //     qid: student.qid, // Convert userId to qid
    //     name: student.learnerName, // Convert userName to name
    //     userId: student.awardedTo,
    //     courseIntId: student.learningActivityId, // Convert learningActivityId
    //     courseTitle: student.courseTitle,
    //     isAwarded: true,
    //   }));

    const filteredAwardedStudent = props.awardsByExpertsHistory
      .filter((award) => award.awardedOn === selectedDate)
      .map((award) => {
        // Find the corresponding student in props.studentList
        const foundStudent = props.studentList.find(
          (student) =>
            student.userId === award.awardedTo &&
            student.courseIntId === Number(award.learningActivityId)
        );
        if (foundStudent) {
          return { ...foundStudent, isAwarded: true };
        }
        return null;
      })
      .filter(Boolean);
    const updatedStudentList = props.studentList.map((student) => {
      const isAwarded = filteredAwardedStudent.some(
        (awarded) =>
          awarded.userId === student.userId &&
          awarded.courseIntId === student.courseIntId
      );
      return { ...student, isAwarded };
    });
    setSelectedStudents(filteredAwardedStudent);
    setStudentArray(updatedStudentList);
    setStudentsOfCourse(updatedStudentList); // Initialize with updated students
  }, [
    props.studentList,
    props.pastCourseList,
    today,
    props.awardsByExpertsHistory,
    props.ongoingCourseList,
    selectedDate,
  ]);

  //Handle Student Name search
  const handleStudentNameSearch = (value) => {
    setSearchedStudent(value);
    //Filter Array basis on course
    const filteredStudents = studentArray.filter((student) =>
      searchedCourse != 0
        ? student?.name.toLowerCase().includes(value.toLowerCase()) &&
          student?.courseIntId == searchedCourse
        : student?.name.toLowerCase().includes(value.toLowerCase())
    );
    setStudentsOfCourse(filteredStudents); // Set the filtered list
  };
  // Handle course Selection
  const handleCourseSelection = (selectedCourseId) => {
    setSearchedCourse((prevCourseId) => selectedCourseId);
    //Filter Array basis on course
    const filteredStudents =
      selectedCourseId == 0
        ? studentArray
        : studentArray.filter(
            (student) => student.courseIntId == selectedCourseId
          );
    setStudentsOfCourse(filteredStudents); // Set the filtered list
  };
  //Handle Date selection
  const handleDateSelection = (date) => {
    const today = new Date().toISOString().split('T')[0];
    if (date > today) {
      return;
    }
    setSelectedDate(date);
    //Already awarded students
    const filteredAwardedStudent = pastAwardsByExpert
      .filter((student) => student.awardedOn === date)
      .map((student) => ({
        qid: student.qid, // Convert userId to qid
        name: student.learnerName, // Convert userName to name
        userId: student.awardedTo,
        courseIntId: student.learningActivityId, // Convert learningActivityId
        courseTitle: student.courseTitle,
        isAwarded: true,
      }));
    const updatedStudentList = studentArray.map((student) => {
      const isAwarded = filteredAwardedStudent.some(
        (awarded) =>
          awarded.userId === student.userId &&
          awarded.courseIntId === student.courseIntId
      );
      return { ...student, isAwarded };
    });

    setStudentArray(updatedStudentList);
    setStudentsOfCourse(updatedStudentList);
    setSelectedStudents(filteredAwardedStudent);

    // setSelectedStudents(filteredAwardedStudent);
  };

  //Handle Update
  const handleUpdate = async () => {
    const url = AWARDS_CONSTANTS.postAwardedChampionshipApi;
    let data = getPayloadData(selectedStudents);

    if (selectedStudents.length === 0 && data.length === 0) {
      setErrorSuccessAlertMessage('No changes to update.');
      setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
      setErrorSuccessAlertVisiblity(AppConstants.trueText);
      hideErrorMessage();
      return;
    }

    const response = await AuthorizedDataService.post(
      url,
      data,
      AppConstants.emptyString,
      AppConstants.emptyString
    );

    if (response.status === 500) {
      setErrorSuccessAlertMessage(
        ErrorMessages.completeProfileInternalServerError
      );
      setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
      setErrorSuccessAlertVisiblity(AppConstants.trueText);
      setSelectedStudents([]);
      setTimeout(() => {}, AppConstants.searchSkillTextChangeDuration);
      hideErrorMessage();
    } else if (response.ok && response.status === 200) {
      if (selectedStudents.length === 0) {
        props.updateAwardsByExperts([], selectedDate);
        setErrorSuccessAlertMessage(
          `All students for ${moment(selectedDate).format('DD-MM-YYYY')} have been removed.`
        );
      } else {
        const newAwards = selectedStudents.map((student) => ({
          qid: student.qid,
          learnerName: student.name,
          awardedTo: student.userId,
          learningActivityId: student.courseIntId,
          courseTitle: student.courseTitle,
          awardedOn: selectedDate,
        }));

        props.updateAwardsByExperts(newAwards, selectedDate);

        // Show success message
        setErrorSuccessAlertMessage(
          successMessageChampionship.championshipPostSuccess +
            moment(selectedDate).format('DD-MM-YYYY')
        );
      }

      setErrorSuccessAlertVarient(AppConstants.alertVarient[0]);
      setErrorSuccessAlertVisiblity(AppConstants.trueText);
      hideErrorMessage();
    }
    return null;
  };
  const handleRemoveStudent = (student) => {
    setSelectedStudents((prevSelected) => {
      return prevSelected.filter(
        (s) => !(s.qid === student.qid && s.courseIntId === student.courseIntId)
      );
    });
    setStudentArray((prevStudents) =>
      prevStudents.map((s) =>
        s.qid === student.qid && s.courseIntId === student.courseIntId
          ? { ...s, isAwarded: false }
          : s
      )
    );

    setStudentsOfCourse((prevStudents) =>
      prevStudents.map((s) =>
        s.qid === student.qid && s.courseIntId === student.courseIntId
          ? { ...s, isAwarded: false }
          : s
      )
    );
  };
  const toggleIsAwarded = (student) => {
    setSelectedStudents((prevSelected) => {
      const exists = prevSelected.find(
        (s) => s.qid === student.qid && s.courseIntId === student.courseIntId
      );

      if (exists) {
        return prevSelected.filter(
          (s) =>
            !(s.qid === student.qid && s.courseIntId === student.courseIntId)
        );
      } else {
        return [...prevSelected, { ...student, isAwarded: true }];
      }
    });
    const updateIsAwarded = (prevStudents) =>
      prevStudents.map((s) =>
        s.qid === student.qid && s.courseIntId === student.courseIntId
          ? { ...s, isAwarded: !s.isAwarded }
          : s
      );

    setStudentArray(updateIsAwarded);
    setStudentsOfCourse(updateIsAwarded);
  };

  //function to get payload data
  const getPayloadData = (students) => {
    if (students.length === 0) {
      // Get all existing awards for the selected date from pastAwardsByExpert
      const awardsToDeactivate = pastAwardsByExpert
        .filter((award) => award.awardedOn === selectedDate)
        .map((award) => ({
          awardedTo: award.awardedTo,
          awardedBy: award.awardedBy,
          learningActivityId: award.learningActivityId,
          learningActivityType: award.learningActivityType,
          awardedOn: award.awardedOn,
          isActive: AppConstants.nText,
          awardType: award.awardType,
        }));

      return awardsToDeactivate.length > 0 ? awardsToDeactivate : [];
    }

    const uniqueStudents = students.filter(
      (student, index, self) =>
        self.findIndex(
          (s) =>
            s.userId === student.userId && s.courseIntId === student.courseIntId
        ) === index
    );

    return uniqueStudents.map((student) => ({
      awardedTo: student.userId,
      awardedBy: '',
      learningActivityId: student.courseIntId,
      learningActivityType: AppConstants.learningActivitiesTypes.course,
      awardedOn: selectedDate,
      isActive: student.isAwarded ? AppConstants.yText : AppConstants.nText,
      awardType: AWARDS_CONSTANTS.awardType,
    }));
  };

  return (
    <div ref={contentRef}>
      <Row>
        <Col xs={7} sm={7} md={8} lg={8} xl={8}>
          <SearchCourseAndStudent
            onStudentNameSearch={handleStudentNameSearch}
            courseList={courseList}
            onCourseNameSearch={handleCourseSelection}
            selectedStudentName={searchedStudent}
            selectedCourse={searchedCourse}
            onDateSelection={handleDateSelection}
            defaultDate={selectedDate}
          />
          <Row
            xs={1}
            sm={2}
            md={3}
            lg={3}
            xl={4}
            className={classes.studentsToBeAwardedCardsSection}
          >
            {studentsOfCourse
              .sort((a, b) => {
                if (a.courseId?.toLowerCase() < b.courseId?.toLowerCase()) {
                  return -1;
                }
              })
              .slice(0, cardCount)
              .map((student, index) => (
                <Col ref={index === cardCount - 1 ? lastElementRef : null}>
                  <Card
                    key={student.qid && student.courseId}
                    className={`${classes.studentCard} ${
                      student.isAwarded ? classes.selectedCard : ''
                    }`}
                    onClick={() => toggleIsAwarded(student)}
                  >
                    {student.userImagePath !== AppConstants.nullText && (
                      <div className={classes.userImageContainer}>
                        <Card.Img
                          variant="top"
                          src={student.userImagePath}
                          className={classes.userImage}
                        />
                      </div>
                    )}
                    {student.userImagePath === AppConstants.nullText && (
                      <div className={classes.userInitialsContainer}>
                        <div className={classes.userInitials}>
                          {getInitials(student.name)}
                        </div>
                      </div>
                    )}
                    <Card.Body className={classes.cardBody}>
                      <Card.Text title={student.qid}>
                        <div className={classes.awardsParent}>
                          <span className={classes.qid}>{student.qid}</span>

                          {/* Tooltip for Student Name */}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-name-${student.id}`}>
                                {student.awardsCount
                                  ? student.awardsCount
                                  : AWARDS_CONSTANTS.noAwards}
                              </Tooltip>
                            }
                          >
                            <span key={index} className={classes.awardsList}>
                              {student.awardsCount
                                ? student.awardsCount
                                : AWARDS_CONSTANTS.noAwards}
                            </span>
                          </OverlayTrigger>
                        </div>
                      </Card.Text>

                      {/* Tooltip for Student Name */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-name-${student.id}`}>
                            {student.name}
                          </Tooltip>
                        }
                      >
                        <Card.Text
                          className={classes.studentName}
                          title={student.name}
                        >
                          {student.name.length > 20
                            ? student.name.substring(0, 20) + '...'
                            : student.name}
                        </Card.Text>
                      </OverlayTrigger>

                      {/* Tooltip for Course Name */}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-name-${student.courseTitle}`}>
                            {student.courseTitle}
                          </Tooltip>
                        }
                      >
                        <Card.Text
                          className={classes.courseName}
                          title={student.courseTitle}
                        >
                          {student.courseTitle.length > 25
                            ? student.courseTitle.substring(0, 25) + '...'
                            : student.courseTitle}
                        </Card.Text>
                      </OverlayTrigger>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
        <Col xs={5} sm={5} md={4} lg={4} xl={4}>
          <Row>
            <Col>
              <div>
                <h3 className={classes.summaryHeading}>
                  Summary: {moment(validatedDate).format('DD-MMM-YYYY')}
                </h3>
                <ListGroup className={classes.summaryList}>
                  {selectedStudents?.length > 0 ? (
                    selectedStudents.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        className={classes.summaryListItem}
                      >
                        <div>
                          <div className={classes.name}>{item.name}</div>
                          <div className={classes.courseName}>
                            {item.courseTitle || AppConstants.notAvailableText}
                          </div>
                        </div>
                        <RxCross2
                          className={classes.crossCloseButton}
                          onClick={() => handleRemoveStudent(item)}
                        />
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item className={classes.summaryListItem}>
                      <div className={classes.noStudentsSelected}>
                        No students selected for the day.
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </div>
              <Button
                onClick={handleUpdate}
                className="btn btn-primary mb-1 mx-auto gridButton mt-2 float-end"
                // disabled={selectedStudents.length === 0}
              >
                Update
              </Button>
            </Col>
          </Row>
          <Row xs={10} sm={10} md={10} lg={10} xl={10}>
            <Col>
              {errorSuccessAlertVisiblity && (
                <ErrorSuccessAlertMessage
                  message={errorSuccessAlertMessage}
                  varient={errorSuccessAlertVarient}
                />
              )}{' '}
            </Col>
          </Row>
        </Col>
      </Row>
      {loading && cardCount < studentArray?.length && (
        <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
          <div className="pageLoader">
            <BiLoaderAlt className="spinner" />
          </div>
        </Row>
      )}
    </div>
  );
};
