import Auth from "../utils/Auth";
import { AppConstants } from '../constants/AppConstants';
import { DataService } from '../services/DataService';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
export const FileOperationsUtil = {
    //Method to download authorized file using GET method
    async downloadFile(uri, fileName, invalidResponse, noResponse, requestHeaders) {
        const apiURL = process.env.REACT_APP_API_URL;
        const url = `${apiURL}` + uri;
        const token = `Bearer ${Auth.getAuthToken()}`;

        try {
            let header = { 'Authorization': token };
            if (requestHeaders && Object.keys(requestHeaders)?.length > 0) {
                header = { ...header, ...requestHeaders }
            }

            const response = await fetch(url, { headers: header });
            if (response.ok) {
                const data = await response.arrayBuffer();
                const viewDocument = new Blob([data]);
                let downloadURL = URL.createObjectURL(viewDocument);
                let link = document.createElement('a');
                link.href = downloadURL;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                return await response;
            }
            else {
                throw new Error(invalidResponse);
            }
        } catch (error) {
            console.error(noResponse, error);
        }
    },

    async downloadFileUsingPostRequest(uri, fileName, data, invalidResponse, noResponse) {
        const apiURL = process.env.REACT_APP_API_URL;
        const url = `${apiURL}` + uri;
        const token = `Bearer ${Auth.getAuthToken()}`;
        try {
            const header = { 'Authorization': token, 'Content-Type': 'application/json' };
            const response = await fetch(url, {
                headers: header, method: 'POST',
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const data = await response.arrayBuffer();
                const viewDocument = new Blob([data]);
                let downloadURL = URL.createObjectURL(viewDocument);
                let link = document.createElement('a');
                link.href = downloadURL;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                return response;
            }
            else {
                throw new Error(invalidResponse);
            }
        } catch (error) {
            console.error(noResponse, error);
        }
    },

    async downloadFileUnauthorizedUsingPostRequest(uri, fileName, data, invalidResponse, noResponse) {
        const apiURL = process.env.REACT_APP_API_URL;
        const url = `${apiURL}` + uri;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const data = await response.arrayBuffer();
                const viewDocument = new Blob([data]);
                let downloadURL = URL.createObjectURL(viewDocument);
                let link = document.createElement('a');
                link.href = downloadURL;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                return response;
            }
            else {
                throw new Error(invalidResponse);
            }
        } catch (error) {
            console.error(noResponse, error);
        }
    },

    //Method to download files
    async downloadDocument(fileUrl, FileTitle, suffix) {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            let name = `${FileTitle}${AppConstants.hyphen}${suffix}`;
            let fileName = name.replace(/\s+/g, AppConstants.hyphen);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(url); // Clean up the temporary URL
        } catch (error) {
            return error;
        }
    },

    //Method to download un-authorized file using GET method
    async downloadFileUnauthorized(uri, fileName, invalidResponse, noResponse) {
        const apiURL = process.env.REACT_APP_API_URL;
        const url = `${apiURL}` + uri;
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.arrayBuffer();
                const viewDocument = new Blob([data]);
                let downloadURL = URL.createObjectURL(viewDocument);
                let link = document.createElement('a');
                link.href = downloadURL;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                return await response;
            }
            else {
                throw new Error(invalidResponse);
            }
        } catch (error) {
            console.error(noResponse, error);
        }
    },
    getFileType(file) {
        // Get the file extension
        const extension = file.name.split('.').pop();
        // Return the content type for the file extension
        return AppConstants.contentTypeMap[extension];
    },
    async downloadFileFromS3(presignedUrl, fileName, fileExtension) {
        try {
            const response = await fetch(presignedUrl);
            if (response.ok) {
                const blob = await response.blob();

                // Create a temporary anchor element to initiate the download
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(blob);
                anchor.download = `${fileName}.${fileExtension}`;
                anchor.click();
            }
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    },
    //to donload problem statement
    downloadProblemStatement(challengePath, competitionTitle) {
        let startIndex = challengePath.lastIndexOf(".");
        let endIndex = challengePath.indexOf("?");
        let fileExtension = challengePath.substring(startIndex, endIndex);
        fetch(challengePath)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${competitionTitle}-problem-statement${fileExtension}`;
                    a.click();
                });
            });
    },
    async downloadFileWithLoader(presignedUrl, fileName) {
        try {
            const response = await fetch(presignedUrl);
            if (response.ok) {
                const blob = await response.blob();

                // Create a temporary anchor element to initiate the download
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(blob);
                anchor.download = fileName;
                anchor.click();
                return true;
            } else {
                return true;
            }
        } catch (error) {
            return true;
        }
    },
    //to donload problem statement
    async downloadProblemStatementFile(challengePath, competitionTitle) {
        let startIndex = challengePath.lastIndexOf(".");
        let endIndex = challengePath.indexOf("?");
        let fileExtension = challengePath.substring(startIndex, endIndex);
        try {
            const response = await fetch(challengePath);
            if (response.ok) {
                const blob = await response.blob();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${competitionTitle}-problem-statement${fileExtension}`;
                a.click();
                return true;
            } else {
                return false;
            }
        }
        catch (error) {
            return false;
        }
    },
    async uploadFileThroughPreSignedURL(selectedFile, path, filePermission, fileContentDisposition) {
        const data = {
            objectKey: path,
            contentType: selectedFile.type,
            permissionType: filePermission,
            contentDisposition: fileContentDisposition
        };

        const fileFormData = new FormData();
        fileFormData.append("file", selectedFile);
        const config = {
            'x-amz-acl': filePermission,
            'Content-Disposition': fileContentDisposition,
            'Content-Type': selectedFile.type,
        }
        try {

            const presignedUrlResponse = await DataService.postWithReturnValue(
                AppConstants.getPreSignedURLForUploadAPI,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            )
            if (presignedUrlResponse !== undefined && presignedUrlResponse !== null) {
                const start = 1;
                const end = presignedUrlResponse.preSignedURL.length - 1;
                const uploadStatus = await DataService.putFileOnPreSigned(presignedUrlResponse.preSignedURL.substring(start, end),
                    selectedFile,
                    config
                );
                if (uploadStatus.ok) {
                    return presignedUrlResponse;
                }
                else {
                    throw new Error("Error while uploading file", uploadStatus);
                }
            }
        }
        catch (error) {
            console.error('Error while uploading file:', error);
            throw new Error(error);

        }
    },
    async fetchFileBinaryData(fileURL, fileName) {
        return fetch(fileURL)
            .then(response => {
                if (!response.ok) {
                    // cannot fetch file
                    return AppConstants.emptyString;
                }
                return response.arrayBuffer();
            }).then(fileData => {
                const dataTransfer = new DataTransfer();
                if (fileData === AppConstants.emptyString) {
                    return AppConstants.emptyString;
                }
                const [urlWithoutParams] = fileURL.split(AppConstants.questionMark);
                const urlFileName = urlWithoutParams.split(AppConstants.forwardSlash).pop();
                const [name, extension] = urlFileName.split(AppConstants.dot);
                const mimeType = extension ? AppConstants.contentTypeMap[extension.toLowerCase()] : AppConstants.emptyString;
                const file = new File([fileData], `${fileName}.${extension}` || urlFileName, { type: mimeType || AppConstants.s3ContentTypeOctetStream });
                dataTransfer.items.add(file);

                return { dataTransfer, fileURL, name };
            }).catch(error => {
                return AppConstants.emptyString;
            });
    },
    downloadFileBlob(blob, fileName) {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = fileName;
        anchor.click();
        URL.revokeObjectURL(anchor.href);
    },
    downloadCsvDataAsExcel(csvData, fileName) {
        const csvRows = csvData.split('\n').map(row => row.split(AppConstants.agGridColumnSeparator));
        const cleanedData = csvRows.map(row => row.map(cell => cell?.replace(/\r$/, AppConstants.emptyString)));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(cleanedData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);
    },
    async downloadCsvDataAsExcelWithStyling(csvData, fileName, columnDefs) {
        const csvRows = csvData.split('\n').map(row => row.split(AppConstants.agGridColumnSeparator));
        //const cleanedData = csvRows.map(row => row.map(cell => cell?.replace(/\r$/, AppConstants.emptyString)));
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet(fileName.split('.')[0]);

        //Add Header Row
        const headers = columnDefs.map(col => col.headerName);
        const headerRow = ws.addRow(headers);
        // Apply Header Styling Only to Header Cells
        headerRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 12 }; //font color
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '224b7f' } }; //Background color
            cell.alignment = { horizontal: 'center', vertical: 'middle' }; // vertical and horizontal alignment of text
        });

        // Add Data Rows
        for (let i = 1; i < csvRows.length; i++) {
            ws.addRow(csvRows[i]);
        }
        // Apply Borders to All Cells
        ws.eachRow((row) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { vertical: 'middle', horizontal: 'left' };
            });
        });
        // Set Column Widths Dynamically Based on `columnDefs`
        ws.columns = columnDefs.map(col => ({ header: col.headerName, width: col.headerName.length + 6 }));
        // Generate Excel File
        const buffer = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), fileName);
    },
    async downloadAssessmentCsvDataAsExcelWithStyling(assessmentDetails, csvData, fileName, columnDefs) {
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet(fileName.split('.')[0]);

        // Add Assessment Details Properly
        assessmentDetails.forEach(detail => {
            const row = ws.addRow([detail[0], detail[1] || "N/A"]); // Ensure no empty values

            row.eachCell((cell, colNumber) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.font = { bold: true, size: 12 };
                cell.alignment = { vertical: 'middle', horizontal: 'left' };

                // Apply background color only to the first column
                if (colNumber === 1) {
                    cell.font.color = { argb: 'FFFFFFFF' };
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '224b7f' } };
                }
            });
        });

        // Add a blank row after assessment details
        ws.addRow([]);

        // Add Headers (Styled)
        const headers = columnDefs.map(col => col.headerName);
        const headerRow = ws.addRow(headers);
        headerRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' }, size: 12 };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '224b7f' } };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        // Add Data Rows (csvData should not include headers)
        csvData.forEach(row => ws.addRow(row));

        // Apply Borders and Alignment to All Cells
        const startDataRow = assessmentDetails.length + 3; // +2 to account for blank row after details

        ws.eachRow((row, rowNumber) => {
            // Skip the assessment details and blank row
            if (rowNumber < startDataRow) return;

            row.eachCell((cell, colNumber) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };

                // If it's the last column (recommendations), left-align it
                if (colNumber === headers.length) {
                    if (cell.value !== 'N/A') {
                        cell.alignment = { vertical: 'middle', horizontal: 'left' };
                    } else {
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                } else {
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                }
            });
        });


        // Set Column Widths Dynamically
        ws.columns = columnDefs.map(col => ({ width: col.headerName.length + 12 }));

        // Generate Excel File
        const buffer = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), fileName);
    }

};


