import React, { useEffect, useState } from 'react';
import classes from './SearchCourseAndStudent.module.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  Button,
  Dropdown,
  DropdownButton,
  Row,
  Col,
  ButtonGroup,
} from 'react-bootstrap';
import { AWARDS_CONSTANTS } from '../../constants/AppConstants';
export const SearchCourseAndStudent = (props) => {
  const [selectedStudentName, setSelectedStudentName] = useState(
    props.selectedStudentName
  );
  const [selectedCourse, setSelectedCourse] = useState(props.selectedCourse);

  const [selectedDated, setSelectedDated] = useState(props.defaultDate);
  const [courseList, setCourseList] = useState(props.courseList);
  useEffect(() => {
    setCourseList(props.courseList);
    // setSelectedDated(props.defaultDate);
    const maxDate = new Date().toISOString().split('T')[0];
    const validatedDate =
      props.defaultDate > maxDate ? maxDate : props.defaultDate;
    setSelectedDated(validatedDate);
  }, [props.courseList, props.defaultDate]);

  //Value of Search Skill Name typeahed
  const handleSearchInputChange = (event) => {
    setSelectedStudentName(event.target.value);
    props.onStudentNameSearch(event.target.value);
  };

  const handleSearchCourseInputChange = (event) => {
    const value = event.target.value;
    if (value === 'all') {
      setSelectedCourse(0);
      props.onCourseNameSearch(0);
    } else {
      setSelectedCourse(value);
      props.onCourseNameSearch(value);
    }
  };

  //Select Date
  const chooseDate = (event) => {
    const selectedDate = event.target.value;
    const maxDate = new Date().toISOString().split('T')[0];
    const finalDate = selectedDate > maxDate ? maxDate : selectedDate;
    setSelectedDated(finalDate);
    props.onDateSelection(event.target.value);
  };

  return (
    <>
      <Row>
        <Col xs={8} sm={4} md={4} lg={4} xl={4}>
          <div className={classes.containerSearchItem}>
            <input
              type="date"
              value={selectedDated}
              onChange={chooseDate}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>
        </Col>
        <Col xs={8} sm={4} md={4} lg={4} xl={4}>
          <div className={classes.containerSearchItem}>
            <select
              name="selectedCourse"
              id="selectedCourse"
              value={selectedCourse || 'all'}
              labelKey="selectedCourse"
              onChange={handleSearchCourseInputChange}
            >
              <option value="all">All Courses</option>
              {courseList.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.courseTitle}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col xs={8} sm={4} md={4} lg={4} xl={4}>
          <div className={classes.containerSearchItem}>
            <input
              type="text"
              value={selectedStudentName}
              onChange={handleSearchInputChange}
              placeholder={AWARDS_CONSTANTS.searchStudentName}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
