import {
  BsLinkedin,
  BsFacebook,
  BsFillEnvelopeAtFill,
  BsFillCheckCircleFill,
  BsWhatsapp,
} from 'react-icons/bs';
import { AiOutlineCopy } from 'react-icons/ai';
import { AppConstants } from '../constants/AppConstants';
import { useState } from 'react';
import { RiTwitterXLine } from 'react-icons/ri';

export const ShareLinkToSocialMediaUtil = (props) => {
  const [showLinkCopied, setShowLinkCopied] = useState(AppConstants.falseText);
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const faceBookIconPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.facebookIconPath}`;
  const linkedInIconPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.linkedInIconPath}`;
  const twitterIconPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.twitterIconPath}`;
  const copyIconPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.copyIconPath}`;
  const mailIconPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.mailIconPath}`;
  const whatsAppIconPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.whatsAppIconPath}`;

  //function to copy link to clipboard
  function copyToClipBoard(event) {
    event.preventDefault();
    navigator.clipboard.writeText(props.linkToShare);
    setShowLinkCopied(AppConstants.trueText);
    var timer = setTimeout(() => {
      setShowLinkCopied(AppConstants.falseText);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }

  //function Send on Mail
  function sendOnMail() {
    window.open(
      `${AppConstants.mailToURI}` + `${props.linkToShare}`,
      AppConstants.openInNewTab
    );
  }

  function shareOnWhatsApp() {
    const encodedLink = encodeURIComponent(props.linkToShare);
    const userAgent = navigator.userAgent || window.opera;
    const deepLink = `${AppConstants.deepLinkWhatsapp}${encodedLink}`;
    const webLink = `${AppConstants.whatsAppShareURI}${encodedLink}`;

    let isMobile =
      /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent);

    if (isMobile) {
      window.location.href = deepLink;
      // Fallback to web interface after a slight delay;
      setTimeout(() => {
        window.open(webLink, AppConstants.openInNewTab);
      }, 1000);
    } else {
      window.open(webLink, AppConstants.openInNewTab);
    }
  }

  //Function to Share on facebook
  function shareOnFacebook() {
    window.open(
      `${AppConstants.facebookShareURI}` + `${props.linkToShare}`,
      AppConstants.openInNewTab
    );
  }
  //Function to share on Linkedin
  function shareOnLinkedIn() {
    window.open(
      `${AppConstants.linkedInShareURI}` +
        `${encodeURIComponent(props.linkToShare)}`,
      AppConstants.openInNewTab
    );
  }
  //function to share on twitter
  function shareOnTwitter() {
    window.open(
      `${AppConstants.twitterShareURI}` + `${props.linkToShare}`,
      AppConstants.openInNewTab
    );
  }

  return (
    <>
      {props.useIcons ? (
        <>
          <BsLinkedin
            className={props.className.shareIcons}
            onClick={shareOnLinkedIn}
          />
          <BsFacebook
            className={props.className.shareIcons}
            onClick={shareOnFacebook}
          />
          <BsWhatsapp
            className={props.className.shareIcons}
            onClick={shareOnWhatsApp}
          />
          <RiTwitterXLine
            className={props.className.shareIcons}
            onClick={shareOnTwitter}
          />
          <BsFillEnvelopeAtFill
            className={props.className.shareOnMailButton}
            onClick={sendOnMail}
          />
          {!showLinkCopied && (
            <AiOutlineCopy
              className={props.className.shareIcons}
              onClick={copyToClipBoard}
            />
          )}
          {showLinkCopied && (
            <BsFillCheckCircleFill
              className={props.className.showLinkCopiedIcon}
            />
          )}
        </>
      ) : (
        <>
          <img
            src={faceBookIconPath}
            className={props.className.shareIcons}
            onClick={shareOnFacebook}
            alt={AppConstants.emptyString}
          />
          <img
            src={linkedInIconPath}
            className={props.className.shareIcons}
            onClick={shareOnLinkedIn}
            alt={AppConstants.emptyString}
          />
          <img
            src={whatsAppIconPath}
            className={props.className.shareIcons}
            onClick={shareOnWhatsApp}
            alt={AppConstants.emptyString}
          />
          <img
            src={twitterIconPath}
            onClick={shareOnTwitter}
            className={props.className.shareIcons}
            alt={AppConstants.emptyString}
          />
          <img
            src={mailIconPath}
            onClick={sendOnMail}
            className={props.className.shareIcons}
            alt={AppConstants.emptyString}
          />

          {!showLinkCopied && (
            <img
              src={copyIconPath}
              className={props.className.shareIcons}
              onClick={copyToClipBoard}
              alt={AppConstants.emptyString}
            />
          )}
          {showLinkCopied && (
            <BsFillCheckCircleFill
              className={props.className.showLinkCopiedIcon}
            />
          )}
        </>
      )}
    </>
  );
};
