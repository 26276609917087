import React, { useEffect, useState } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import { BsCheck } from 'react-icons/bs';
import styles from './creatorSubscription.module.css';
import { useSubscriptionModalStore } from '../../../stores';
import { applyScopedClasses } from '../../../utils/applyScopedClasses';
import parse from 'html-react-parser';

const CreatorSubscriptionModal = ({ show, onHide, handleButtonClick }) => {
  const {
    setSubscriptionsPlanData,
    subscriptionsPlanData,
    initializeSubscriptionModalStore,
  } = useSubscriptionModalStore();

  const [price, setPrice] = useState(0);

  useEffect(() => {
    const cost = subscriptionsPlanData?.cost;
    const netDiscount = subscriptionsPlanData?.netDiscount;
    const actualCost = cost - netDiscount;
    setPrice(actualCost);
  }, [subscriptionsPlanData]);

  const planDescription = subscriptionsPlanData?.planDescription;
  const processedHtml = applyScopedClasses(planDescription, styles);

  return (
    <div>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body className={`${styles.modalBody} `}>
          <button
            className={styles.closeButton}
            onClick={onHide}
            aria-label="Close"
          >
            &times;
          </button>
          <Card className={styles.pricingCard}>
            <div className={styles.popularTag}>EARLY BIRD OFFER</div>
            <Card.Body>
              <h3 className={`text-left ${styles.planTitle}`}>Standard Plan</h3>
              <h1 className={`text-left ${styles.planPrice}`}>
                <span className={`${styles.discountedPrice}`}>₹{price}</span>
                <span className={`${styles.monthTitle}`}> billed annually</span>
              </h1>
              <h4 className={`text-left ${styles.billingInfo}`}></h4>
              <Button
                variant="primary"
                className={`${styles.checkoutButton} mt-3`}
                onClick={handleButtonClick}
              >
                Checkout
              </Button>
              <h5 className={`text-center ${styles.savingsInfo} mt-2`}>
                You are saving 17% with annual billing
              </h5>
              {parse(processedHtml)}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreatorSubscriptionModal;
