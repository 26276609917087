import { AppConstants } from '../constants/AppConstants';

const Auth = {
  getLoggedInUserDetails() {
    if (
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === null ||
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === undefined
    ) {
      return null;
    } else {
      const userDetails = JSON.parse(
        localStorage.getItem(AppConstants.sessionKeyUserDetails)
      );
      return userDetails;
    }
  },

  getAgencyType() {
    const userDetails = Auth.getLoggedInUserDetails();
    return userDetails ? userDetails.agencyType : null;
  },

  getLoggedInName() {
    if (
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === null ||
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === undefined
    ) {
      return null;
    } else {
      const name = JSON.parse(
        localStorage.getItem(AppConstants.sessionKeyUserDetails)
      ).name;
      return name;
    }
  },
  getAuthToken() {
    const token = localStorage.getItem(AppConstants.sessionKeyAccesToken);
    return token;
  },
  getUserRole() {
    if (
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === null ||
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === undefined
    ) {
      return null;
    } else {
      const role = JSON.parse(
        localStorage.getItem(AppConstants.sessionKeyUserDetails)
      ).userRole;
      return role;
    }
  },
  isLogin() {
    if (
      localStorage.getItem(AppConstants.sessionKeyAccesToken) === null ||
      localStorage.getItem(AppConstants.sessionKeyAccesToken) === undefined ||
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === null ||
      localStorage.getItem(AppConstants.sessionKeyUserDetails) === undefined
    ) {
      return false;
    } else {
      return true;
    }
  },
};

export default Auth;
