import React, { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from "../../constants/AppConstants";
import { SkillCategoryListSection } from "../../component/lists/SkillsCategoryListSection";
import { ExpertListingPageCards } from "../../component/expertListingPage/ExpertListingPageCards";
import classes from "../../component/expertListingPage/ExpertListingPage.module.css";
import { Button } from "react-bootstrap";
import { ErrorMessages } from "../../constants/ErrorMessages";
import parse from 'html-react-parser';
import { ExpertListingPageBanner } from "../../component/expertListingPage/ExpertListingPageBanner";

export const ExpertListingPage = () => {
    const { categorySlug } = useParams();
    const [landingPageData, skillsData, expertData, bannerSectionData] = useLoaderData();
    const exploreSkills = skillsData;
    const [filterText, setFilterText] = useState(AppConstants.emptyString);
    const linkToNavigate = `${AppConstants.forwardSlash}${AppConstants.expertPageRoute}`;
    const [expertsOfCategory, setExpertsOfCategory] = useState(categorySlug !== undefined ? expertData.filter(expert => expert.skillDetails?.some(skill => skill.skillCategorySlug === categorySlug)) : expertData);
    const [filteredList, setFilteredList] = useState(categorySlug !== undefined ? expertData.filter(expert => expert.skillDetails?.some(skill => skill.skillCategorySlug === categorySlug)) : expertData);
    const [selectedCategory, setSelectedCategory] = useState(categorySlug !== undefined ? exploreSkills.filter((item) => item.categorySlug.toLowerCase()?.includes(categorySlug.toLowerCase()))[0].category : AppConstants.AllText);

    // function to filter the experts based on selected category
    function categorySelectionHandler(option) {
        setSelectedCategory(option);

        if (option) {
            if (option === AppConstants.AllText) {
                setExpertsOfCategory(expertData);
                setFilteredList(expertData)
            } else {
                const expertArray = expertData.filter(expert =>
                    expert.skillDetails?.some(skill => skill.skillCategoryName === option)
                )
                setFilteredList(expertArray);
                setExpertsOfCategory(expertArray);
            }
        } else {
            setFilteredList(expertData)
            setExpertsOfCategory(expertData);
        }
    }

    const handleResetFields = () => {
        setFilterText(AppConstants.emptyString);
        if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
            setFilteredList(expertData.filter(expert =>
                expert.skillDetails?.some(skill => skill.skillCategoryName?.toLowerCase() === selectedCategory?.toLowerCase())
            ));
        }
        if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
            setFilteredList(expertData);
        }
    }

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value.replace(/\s{2,}/g, ' ');
        setFilterText(searchText);

        let filteredArray = searchText.toLowerCase() !== AppConstants.emptyString
            ? expertsOfCategory?.filter((item) => ((item.creatorName?.toLowerCase())?.includes(searchText.toLowerCase()) || (item.creatorCity?.toLowerCase())?.includes(searchText.toLowerCase()) || (item.creatorUserQrenciaId?.toLowerCase())?.includes(searchText.toLowerCase()) || item.skillDetails?.some(skill => skill.skillName?.toLowerCase()?.includes(searchText?.toLowerCase()))))
            : expertsOfCategory;

        setFilteredList(filteredArray);
    }

    const getParsedMessage = (message, obj) => {
        let replacedStr = message;
        for (const [key, value] of Object.entries(obj)) {
            replacedStr = replacedStr.replace(`{{${key}}}`, `${value}`);
        };

        return parse(replacedStr);
    }

    return (
        <div>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <ExpertListingPageBanner bannerImage={bannerSectionData} />
            <SkillCategoryListSection categoryList={skillsData} onSelection={categorySelectionHandler} linkToNavigate={linkToNavigate} />
            <div className={classes.containerSearchSkill}>
                <div className={classes.containerSearchItem}><input
                    type="text"
                    value={filterText}
                    onChange={handleSearchInputChange}
                    placeholder="Search by Name or Skill or City or Expert Id"
                />
                </div>
                <Button onClick={handleResetFields} className={classes.resetButton}>Reset</Button>
            </div>
            {filteredList?.length !== 0 ?
                <ExpertListingPageCards expertsOfCategory={filteredList} skillsData={skillsData} />
                : ((filterText.length > 0 && expertsOfCategory.length > 0) || selectedCategory === AppConstants.AllText) ? <div className={classes.noDataError}><p className={classes.errorText}>{ErrorMessages.noExpertsFoundMessage}</p></div> : <div className={classes.noDataError}><p className={classes.errorText}>{getParsedMessage(ErrorMessages.noExpertsForSelectedCategory, { selectedCategory })}</p></div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </div>

    );
}