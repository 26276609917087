import React, { useState, useEffect, useCallback } from 'react';
import classes from './GenerateLeadsComponent.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { DataService } from '../../../services/DataService';
import { GenerateLeadForm } from '../../formControls/adminForms/generateLeadForms/GenerateLeadForm';
import { MonitorNonOnboardedLeads } from '../monitorLeadsComponent/monitorNonOnboardedLeads/MonitorNonOnboardedLeads';
import { MonitorOnboardedLeads } from '../monitorLeadsComponent/monitorOnboardedLeads/MonitorOnboardedLeads';

export const GenerateLeadsComponent = () => {
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [onboardedLeads, setOnboardedLeads] = useState([]);
  const [nonOnboardedLeads, setNonOnboardedLeads] = useState([]);
  const [skillDetails, setSkillDetails] = useState([]);

  const getOnboardedLeads = useCallback(async () => {
    const url = `${AppConstants.getLeadsDetailsAPI}${AppConstants.onboardingStatus.onboarded}`;
    const response = await AuthorizedDataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    if (response) {
      setOnboardedLeads(response);
    } else {
      setOnboardedLeads([]);
    }
    return null;
  }, []);

  const getNonOnboardedLeads = useCallback(async () => {
    const url = `${AppConstants.getLeadsDetailsAPI}${AppConstants.onboardingStatus.notOnboarded}`;
    const response = await AuthorizedDataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );

    if (response) {
      setNonOnboardedLeads(response);
    } else {
      setNonOnboardedLeads([]);
    }
    return null;
  }, []);

  useEffect(() => {
    //fetch Country List
    async function fetchCountries() {
      const url = AppConstants.fetchCountriesAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      setCountryList(response);
      return null;
    }

    // Fetch states for country India
    async function fetchStates() {
      var config = {
        headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates },
      };
      const response = await fetch(AppConstants.getStatesApi, config);
      const responseData = await response.json();
      setStateList(responseData);
      return null;
    }

    async function fetchSkills() {
      const url = AppConstants.getSkillsDataAPI;
      const response = await DataService.get(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response) {
        setSkillDetails(response);
      } else {
        setSkillDetails([]);
      }
    }
    fetchSkills();
    fetchCountries();
    fetchStates();
    getOnboardedLeads();
    getNonOnboardedLeads();
  }, [getOnboardedLeads, getNonOnboardedLeads]);

  return (
    <div className={classes.expertOnBoardingContainer}>
      <h1 className={classes.expertOnBoardingHeading}>Generate Leads</h1>
      {
        <GenerateLeadForm
          refreshNonOnboardedLeads={getNonOnboardedLeads}
          skillDetails={skillDetails}
          countryList={countryList}
          stateList={stateList}
        />
      }
      {<h1 className={classes.expertOnBoardingHeading}>Non Onboarded</h1>}
      {
        <MonitorNonOnboardedLeads
          refreshNonOnboardedLeads={getNonOnboardedLeads}
          skillDetails={skillDetails}
          leadsList={nonOnboardedLeads}
          countryList={countryList}
          stateList={stateList}
        />
      }
      {<h1 className={classes.expertOnBoardingHeading}>Onboarded</h1>}
      {
        <MonitorOnboardedLeads
          refreshOnboardedLeads={getOnboardedLeads}
          skillDetails={skillDetails}
          leadsList={onboardedLeads}
          countryList={countryList}
          stateList={stateList}
        />
      }
    </div>
  );
};
