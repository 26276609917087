import React, { useEffect, useState } from 'react';
import { calculateNumberOfRemainingDays } from '../../utils/calculateNumberOfRemainingDays';
import { Button } from 'react-bootstrap';
import { useSubscriptionStore } from '../../stores';
import { DataService } from '../../services/DataService';
import { useNavigate } from 'react-router-dom';
import { PaymentsUtil } from '../../utils/PaymentsUtil';
import { AppConstants } from '../../constants/AppConstants';

export const RenewSubscription = () => {
  const {
    subscriptionStatus,
    setSubscriptionStatus,
    subscriptionExpiryDate,
    setSubscriptionExpiryDate,
    renewSubscriptionNotificationThreshold,
  } = useSubscriptionStore();

  const history = useNavigate();
  //   const [daysRemaining, setDaysRemaining] = useState(0);

  const remainingDays = calculateNumberOfRemainingDays(subscriptionExpiryDate);

  const makePayment = async () => {
    // POST request to initiatePayment API ot make payment
    const data = {
      userId: 'ar12lsa',
    };

    // let response = await DataService.postWithResponseCode(
    //   'initiatePayment',
    //   data,
    //   ''
    // );

    let response = {
      ok: true, // Simulates a successful response
      status: 200, // Simulates an HTTP 200 OK status code
      json: async () => ({ message: 'Success' }), // Mock the response body as JSON
      text: async () => 'Success', // Mock the response body as plain text
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    let options = {
      id: 'PUGOTA6LEbjPgm',
      checkout_id: 'PUGOQg0TOSXWhb',
      closed_at: null,
      close_reason: null,
      expire_at: 1733570222,
      invoice_id: null,
      order_id: 'order_PUGOO97WdNziFd',
      status: 'active',
      qr_code: {
        id: 'qr_PUGOTFAD5PxFWW',
        entity: 'qr_code',
        created_at: 1733569502,
        name: null,
        usage: 'single_use',
        type: 'upi_qr',
        image_url: null,
        payment_amount: 30000,
        status: 'active',
        description: '"Batik Dreamscape Showcase"',
        fixed_amount: true,
        payments_amount_received: 0,
        payments_count_received: 0,
        notes: [],
        customer_id: null,
        close_by: 1733570222,
        image_content:
          'upi://pay?ver=01&mode=22&pa=random@razorpay&pn=QrenciaPassionSkillsPrivateLimited&tr=RZPPUGOTFAD5PxFWWqrv2&cu=INR&mc=5817&qrMedium=04&tn=PaymenttoQrenciaPassionSkillsPrivateLimited&am=300.00',
        tax_invoice: [],
      },
      request: {
        method: 'GET',
        url: 'https://api.razorpay.com/v1/checkout/qr_code/qr_PUGOTFAD5PxFWW/payment/status?key_id=rzp_test_Hip5MTR9SJs5gF',
      },
    };

    if (response.ok && response.status === 200) {

      const rzp1 = new window.Razorpay({
        ...options,
        handler: async (response) => {
          try {
            const paymentResult =
              await PaymentsUtil.verifySubscriptionPaymentSignatureRazorpay(
                response
              );

            if (
              paymentResult.verificationStatus ===
              AppConstants.razorpayVerificationStatus.verified
            ) {
              // history(AppConstants.welcomeExpertPageRoute);
              // TODO : Display Success Message

              setSubscriptionStatus(AppConstants.subscriptionStatus.expired);
              setSubscriptionExpiryDate('2026-01-01T00:00:00Z');
            } else {
              // setMessage(paymentResult.message);
              // setAlertVarient(AppConstants.alertVarient[1]);
              // setSubmitButtonSpinner(AppConstants.falseText);
              // let timer = setTimeout(() => {
              //   setMessage(AppConstants.falseText);
              // }, AppConstants.messageDisappearTime);
              // return () => {
              //   clearTimeout(timer);
              // };

              // TODO : Display Error Message
            }
          } catch (error) {
            //   setMessage(error.message);
            //   setAlertVarient(AppConstants.alertVarient[1]);
            //   setSubmitButtonSpinner(AppConstants.falseText);
            //   let timer = setTimeout(() => {
            //     setMessage(AppConstants.falseText);
            //   }, AppConstants.messageDisappearTime);
            //   return () => {
            //     clearTimeout(timer);
            //   };
          }
        },
      });
      rzp1.open();
    }
  };
  //   setDaysRemaining(remainingDays);

  return (
    <>
      {subscriptionStatus === 'Active' &&
        remainingDays < renewSubscriptionNotificationThreshold && (
          <Button variant="outline-primary" type="button" onClick={makePayment}>
            Renew Subscription
          </Button>
        )}
      <p>{subscriptionStatus}</p>
    </>
  );
};
