import { useState, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import classes from '../formControls/LoginPageForm.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { DataService } from '../../services/DataService';
import '../../index.css';
import formClass from '../../common/FormStyling.module.css';
import { NavLink } from 'react-router-dom';
import { useSubscriptionStore } from '../../stores';

export const LoginPageForm = (props) => {
  //Intialize form parameters
  const [passwordType, setPasswordType] = useState('password');
  const [showError, setShowError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const initialValues = {
    emailId: AppConstants.emptyString,
    password: AppConstants.emptyString,
  };
  const [loginFormValues, setLoginFormValues] = useState(initialValues);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    AppConstants.emptyString
  );
  const [resetPasswordSpinner, setResetPasswordSpinner] = useState(false);
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaTaglineLogoFile}`;

  const { subscriptionStatus, setSubscriptionStatus } = useSubscriptionStore();
  const { subscriptionExpiryDate, setSubscriptionExpiryDate } =
    useSubscriptionStore();
  const {
    renewSubscriptionNotificationThreshold,
    setRenewSubscriptionNotificationThreshold,
  } = useSubscriptionStore();

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    } else {
      setPasswordType('password');
      return;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginFormValues({ ...loginFormValues, [name]: value });
  };

  const sendResetPasswordLink = async (e) => {
    e.preventDefault();
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (emailRegex.test(loginFormValues.emailId)) {
        setResetPasswordSpinner(true);
        const url = `${AppConstants.sendResetPasswordLinkAPI}${loginFormValues.emailId}`;
        const response = await DataService.post(
          url,
          AppConstants.emptyString,
          AppConstants.emptyString
        );

        if (response) {
          if (response.ok && response.status === 200) {
            setResetPasswordSpinner(false);
            setShowSuccess(true);
            setSuccessMessage(
              `${SuccessMessage.resetPasswordMailSendSuccessfully}${AppConstants.space}${loginFormValues.emailId}`
            );
            const timer = setTimeout(() => {
              setShowSuccess(false);
              setSuccessMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
          } else if (response.status === 500) {
            setShowSuccess(false);
            setSuccessMessage(AppConstants.emptyString);
            setShowError(ErrorMessages.emailVerificationError);
            setResetPasswordSpinner(false);
            const timer = setTimeout(() => {
              setShowError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => clearTimeout(timer);
          }
        } else {
          setShowSuccess(false);
          setResetPasswordSpinner(false);
          setSuccessMessage(AppConstants.emptyString);
          setShowError(ErrorMessages.passwordLinkFailedError);
          const timer = setTimeout(() => {
            setShowError(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);
          return () => clearTimeout(timer);
        }
      } else {
        setShowSuccess(false);
        setResetPasswordSpinner(false);
        setSuccessMessage(AppConstants.emptyString);
        setShowError(ErrorMessages.invalidEmailFormat);
        const timer = setTimeout(() => {
          setShowError(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      setShowSuccess(false);
      setResetPasswordSpinner(false);
      setSuccessMessage(AppConstants.emptyString);
      setShowError(ErrorMessages.passwordLinkFailedError);
      const timer = setTimeout(() => {
        setShowError(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
      return () => clearTimeout(timer);
    }
  };

  const loginUser = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    const apiURL = process.env.REACT_APP_API_URL;
    const authenticationUrl = `${apiURL}` + AppConstants.authenticationUri;
    const authData = {
      userName: loginFormValues.emailId,
      userPassword: loginFormValues.password,
    };
    fetch(authenticationUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/JSON',
      },
      body: JSON.stringify(authData),
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.json();
        } else {
          setShowError(ErrorMessages.loginFailed);
          setShowSpinner(false);
          const timer = setTimeout(() => {
            setShowError(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);
          return () => clearTimeout(timer);
        }
      })
      .then((data) => {
        if (data !== null) {
          const token = data.accessToken;
          if (token !== null && token !== undefined) {
            setShowError(AppConstants.emptyString);
            setShowSpinner(false);
            localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
            localStorage.setItem(
              AppConstants.sessionKeyUserDetails,
              JSON.stringify(data)
            );
            if (data.authorities[0].authority === AppConstants.userRoleExpert) {
              //   POST request to DB to get subscription details
              setSubscriptionStatus(AppConstants.subscriptionStatus.active);
              setSubscriptionExpiryDate('2025-01-01T00:00:00Z');
              setRenewSubscriptionNotificationThreshold(30);

              localStorage.setItem(
                AppConstants.sessionKeyUserDetails,
                JSON.stringify(data)
              );
              window.location.href = '../' + AppConstants.expertHomePath;
              window.location.reload();
            } else if (
              data.authorities[0].authority === AppConstants.userRoleAdmin
            ) {
              window.location.href =
                '../' + AppConstants.adminNumberTrackingPath;
              window.location.reload();
            } else if (
              data.authorities[0].authority === AppConstants.userRoleStudent
            ) {
              window.location.href = '../' + AppConstants.studentHomePath;
              window.location.reload();
            } else if (
              data.authorities[0].authority === AppConstants.userRoleAgency
            ) {
              window.location.href = '../' + AppConstants.agencyHomePath;
              window.location.reload();
            } else if (
              data.authorities[0].authority === AppConstants.userRoleSales
            ) {
              window.location.href = '../' + AppConstants.salesLeadsPath;
              window.location.reload();
            } else if (
              data.authorities[0].authority === AppConstants.userRoleMarketing
            ) {
              window.location.href =
                '../' + AppConstants.marketingBulkSignupPath;
              window.location.reload();
            } else if (
              data.authorities[0].authority ===
              AppConstants.userRolePartnerSuccess
            ) {
              window.location.href =
                '../' + AppConstants.partnerSuccessNumberTracking;
              window.location.reload();
            } else if (
              data.authorities[0].authority === AppConstants.userRoleTeam
            ) {
              window.location.href = '../' + AppConstants.teamHomePath;
              window.location.reload();
            }
          }
        }
      });
  };

  return (
    <div className={classes.loginFormSection}>
      <div className={classes.qrenciaLogoSection}>
        <NavLink to="/">
          <img
            className={classes.qrenciaLogo}
            src={qrenciaLogoUrl}
            alt="Qrencia Logo"
          />
        </NavLink>
      </div>
      <h1 className={classes.formHeading}>Welcome Back</h1>
      <form className={classes.formSection} onSubmit={loginUser}>
        {/* <div className={classes.searchFormElementsSection}> */}
        <div>
          {showError && (
            <p className="errorMessage">
              <RiErrorWarningLine />
              {showError}
            </p>
          )}
          {showSuccess && (
            <p className="successMessage">
              <AiOutlineCheckCircle />
              {successMessage}
            </p>
          )}
        </div>
        <div className="mb-2">
          <Form.Label
            htmlFor="login-email"
            className={`${formClass.formLabel}`}
          >
            Email
          </Form.Label>
          <Form.Control
            className={`${formClass.formInput} ${classes.formInputBox}`}
            id="login-email"
            value={loginFormValues.emailId}
            onChange={handleChange}
            name="emailId"
            type="email"
            required
          />
        </div>
        <div className="mb-2">
          <Form.Label
            htmlFor="login-password"
            className={`${formClass.formLabel} `}
          >
            Password
          </Form.Label>
          <Form.Control
            id="login-password"
            className={`${formClass.formInput} ${classes.formInputBox}`}
            onChange={handleChange}
            name="password"
            minLength={8}
            maxLength={16}
            type={passwordType}
            required
          />
          <div className={classes.showHidePasswordIcon}>
            {passwordType === 'password' ? (
              <FaEye onClick={togglePassword} />
            ) : (
              <FaEyeSlash onClick={togglePassword} />
            )}
          </div>
          <div className={classes.forgotPasswordContainer}>
            {resetPasswordSpinner ? (
              <p className={classes.forgotPasswordText}>
                Sending Link <BiLoaderAlt className="spinner" />
              </p>
            ) : (
              <p
                onClick={sendResetPasswordLink}
                className={classes.forgotPasswordText}
              >
                Forgot Password?
              </p>
            )}
          </div>
        </div>
        <div>
          {showSpinner && (
            <Button type="button" className={classes.loginButton}>
              <BiLoaderAlt className={classes.spinner} />
            </Button>
          )}
          {!showSpinner && (
            <Button type="submit" className={classes.loginButton}>
              {AppConstants.loginButtonText}
            </Button>
          )}
        </div>
        <div className={classes.tncTextContainer}>
          <p className={classes.tncText}>
            Don't have an account?{' '}
            <a className={classes.tncTextHyper} href={AppConstants.signup}>
              Sign up
            </a>
          </p>
        </div>
        {/* </div> */}
      </form>
    </div>
  );
};
