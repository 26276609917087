import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { TbShare3 } from 'react-icons/tb';
import { BiLoaderAlt, BiCalendarX, BiWallet } from 'react-icons/bi';
import { BiCalendarCheck } from 'react-icons/bi';
import { CgPin } from 'react-icons/cg';
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { AppConstants } from '../../constants/AppConstants';
import classes from './CompetitionsCards.module.css';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { PaymentsUtil } from '../../utils/PaymentsUtil';
import './CompetitionCards.css';
import { ShareLinkToSocialMediaUtil } from '../../utils/ShareLinkToSocialMediaUtil';
import { MathUtil } from '../../utils/MathUtil';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

export const CompetitionsCards = (props) => {
  const [fetchedCompetitionsData, setFetchedCompetitionsData] = useState([]);
  const [cardCount, setCardCount] = useState(0);
  const [cardDisplayedCount, setCardDisplayedCount] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalSpinner, setModalSpinner] = useState(false);
  const [modalAlertText, setModalAlertText] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(
    AppConstants.falseText
  );
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const [linkToShare, setLinkToShare] = useState('');
  const [timeZone, setTimeZone] = useState(
    DateTimeUtil.getPreferredTimeZoneForUser()
  );
  const [participantsImageList, setParticipantImageList] = useState([]);
  const learningActivitiesCardsCount = AppConstants.cardCounts;

  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const defaultCompetitionThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;

  useEffect(() => {
    setFetchedCompetitionsData(props.competitionsData);
  }, [props.competitionsData]);

  const handleResize = () => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth >= 992) {
      setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
      setCardDisplayedCount(
        learningActivitiesCardsCount.learningActivityDesktopCount
      );
    }
    if (viewportWidth >= 576 && viewportWidth <= 991) {
      setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
      setCardDisplayedCount(
        learningActivitiesCardsCount.learningActivityTabCount
      );
    }
    if (viewportWidth <= 575) {
      setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
      setCardDisplayedCount(
        learningActivitiesCardsCount.learningActivityMobileCount
      );
    }
  };

  //call handleResize() on screen width change
  useEffect(() => {
    handleResize();
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    const populateParticipantImageList = () => {
      let imageArray = [];
      for (let i = 0; i < fetchedCompetitionsData.length; i++) {
        let partcipantsData = fetchedCompetitionsData[i].participantsDetails;
        let imageList = [];
        partcipantsData?.map((data) => {
          if (data.studentImage) imageList.push(data.studentImage);
        });
        imageArray.push(imageList);
      }
      setParticipantImageList(imageArray);
    };
    populateParticipantImageList();
  }, [fetchedCompetitionsData]);

  //Share on social media
  function shareOnSocialMedia(shareData) {
    setShowSocialMediaPannel(!showSocialMediaPannel);
    setLinkToShare(
      `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitionsText}${AppConstants.forwardSlash}${shareData.categorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.competionSlug}`
    );
  }

  //view competition details
  const viewCompDetails = (compDetails) => {
    props.onViewCompDetails(compDetails);
  };

  //show/hide modal
  const handleModalClose = () => {
    setShowModal(false);
    setModalSpinner(false);
    setModalSuccess(false);
    setModalError(false);
  };
  const handleModalShow = () => {
    setShowModal(true);
    setModalSpinner(false);
    setModalSuccess(false);
    setModalError(false);
  };
  function setModalDataFunc(data) {
    setModalData(data);
    setModalSpinner(false);
    setModalSuccess(false);
    setModalError(false);
    setModalAlertText(
      `${AppConstants.expertCancelCompetitionAlertText}${data.title}${' ?'}`
    );
  }

  //Cancel selected competition
  async function cancelCompetition(compData) {
    setModalSpinner(true);
    const url = `${AppConstants.cancelCompetitionWithParameterAPI}${compData.uniqueId}`;
    const response = await AuthorizedDataService.put(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    if (response !== undefined && response.ok) {
      setModalSpinner(false);
      setModalSuccess(true);
      setModalError(false);
      setModalAlertText(
        `${compData.title}${SuccessMessage.expertCancelCompetitionSuccess}`
      );
      props.onCancelCompetition();
    } else {
      setModalSpinner(false);
      setModalSuccess(false);
      setModalError(true);
      setModalAlertText(
        `${ErrorMessages.expertCancelCompetitionError}${compData.title}${ErrorMessages.expertCancelCompetitionErrorPartTwo}`
      );
    }
  }

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleCardClick = (url, competition) => {
    if (competition.status !== AppConstants.competitionStatus.cancelled) {
      window.open(url, AppConstants.newPageTargetSelf);
    }
  };

  const navigateToGeolocation = (mode, location) => {
    if (mode?.toUpperCase() === 'OFFLINE') {
      window.open(location, AppConstants.openInNewTab);
    }
  };

  const fetchFormattedEnrolledCount = (
    enrolledParticipants,
    isTeamEnrolmentEnabled
  ) => {
    if (!enrolledParticipants) return AppConstants.emptyString;

    let enrolCount = MathUtil.formatNumberToLocaleString(
      parseInt(enrolledParticipants.split(AppConstants.space))
    );
    return `${enrolCount} ${isTeamEnrolmentEnabled === AppConstants.trueFlag ? (enrolCount == 1 ? 'Team Enrolled' : 'Teams Enrolled') : 'Enrolled'}`;
  };

  const { lastElementRef, loading } = useInfiniteScroll(() => {
    setCardCount((prevCount) => prevCount + cardDisplayedCount);
  }, [fetchedCompetitionsData]);

  return (
    <>
      <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
        {fetchedCompetitionsData !== undefined &&
          fetchedCompetitionsData
            .slice(0, cardCount)
            ?.map((competitionsCard, index) => (
              <Col
                key={competitionsCard.competionSlug}
                ref={index === cardCount - 1 ? lastElementRef : null}
              >
                <Card
                  className="cardDiv"
                  key={index}
                  onClick={() =>
                    handleCardClick(
                      `${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${competitionsCard.categorySlug}${AppConstants.forwardSlash}${competitionsCard.skillSlug}${AppConstants.forwardSlash}${competitionsCard.competionSlug}`,
                      competitionsCard
                    )
                  }
                >
                  <Card.Img
                    variant="top"
                    className="cardImage"
                    src={
                      competitionsCard.thumbnailImagePath
                        ? competitionsCard.thumbnailImagePath
                        : defaultCompetitionThumbnail
                    }
                  />
                  <div className={classes.uniqueIdText}>
                    {competitionsCard.competitionId}
                  </div>
                  {competitionsCard.status !==
                    AppConstants.competitionStatus.cancelled && (
                    <TbShare3
                      className={classes.shareIcon}
                      onClick={(e) => {
                        stopPropagation(e);
                        setEditIndex((editIndex) =>
                          editIndex === index ? index : index
                        );
                        shareOnSocialMedia(competitionsCard);
                      }}
                    />
                  )}
                  <Card.Body className="cardBody">
                    <div className="subTitleContainer">
                      <div className="cardSubTitle competitionDiffColor">
                        <span
                          onClick={(e) => {
                            stopPropagation(e);
                            navigateToGeolocation(
                              competitionsCard.type.toUpperCase(),
                              competitionsCard.geoLocation
                            );
                          }}
                          className="modeSubtitle competitionDiffColor"
                          data-tooltip-id={`${competitionsCard.uniqueId}-offline`}
                        >
                          {competitionsCard.type.toUpperCase()}
                        </span>{' '}
                        {competitionsCard.schoolId !== 0 && (
                          <span className="modeSubtitle competitionDiffColor">
                            | SCHOOL
                          </span>
                        )}
                      </div>
                      {competitionsCard.isTeamEnrolmentEnabled ===
                        AppConstants.nText && (
                        <div className="cardsCostInfoText">
                          <BiWallet />{' '}
                          {PaymentsUtil.calculateCost({
                            cost: competitionsCard.cost,
                            discount: 0,
                          })}
                        </div>
                      )}
                      {competitionsCard.isTeamEnrolmentEnabled ===
                        AppConstants.yText && (
                        <div className="cardsCostInfoText">
                          <BiWallet />{' '}
                          {PaymentsUtil.calculateCost({
                            cost: competitionsCard.cost,
                            discount: 0,
                            teamSize: competitionsCard.teamParticipantsLimit,
                            teamEnrolmentFlag: AppConstants.yText,
                          })}
                        </div>
                      )}
                    </div>
                    {competitionsCard.type.toUpperCase() === 'OFFLINE' && (
                      <Tooltip
                        className="tooltipMessage"
                        id={`${competitionsCard.uniqueId}-offline`}
                      >
                        <span className="tooltipMessage">
                          {competitionsCard.venueLocation}
                        </span>
                      </Tooltip>
                    )}
                    {competitionsCard.status !=
                      AppConstants.cancelledCompetitionsText && (
                      <Card.Text className="cardTitle titleFixedHeight">
                        <Link
                          className="cardLink"
                          data-tooltip-id={`${competitionsCard.competionSlug}-title`}
                        >
                          {competitionsCard.title}
                        </Link>
                        <Tooltip
                          className="courseTitleTooltip"
                          id={`${competitionsCard.competionSlug}-title`}
                        >
                          <span>{competitionsCard.title}</span>
                        </Tooltip>
                      </Card.Text>
                    )}
                    {competitionsCard.status ===
                      AppConstants.cancelledCompetitionsText && (
                      <Card.Text className="cardTitle">
                        <div
                          data-tooltip-id={`${competitionsCard.competionSlug}-title`}
                        >
                          {competitionsCard.title}
                        </div>
                        <Tooltip
                          className="courseTitleTooltip"
                          id={`${competitionsCard.competionSlug}-title`}
                        >
                          <span>{competitionsCard.title}</span>
                        </Tooltip>
                      </Card.Text>
                    )}
                    {editIndex === index && showSocialMediaPannel && (
                      <div
                        onClick={stopPropagation}
                        className={classes.shareIconPannelContainer}
                      >
                        <ShareLinkToSocialMediaUtil
                          linkToShare={linkToShare}
                          className={classes}
                          useIcons={AppConstants.trueText}
                        />
                      </div>
                    )}
                    <div className="cardTimeAndDate">
                      <div>
                        <BiCalendarCheck className="calendarIcon" />
                        &nbsp;
                      </div>
                      <div className="dateTimeText">
                        Start:&nbsp;
                        <span className="competitionDateTimeFormat">
                          <Moment
                            format={
                              AppConstants.competitionCardsDateMonthFormat
                            }
                            tz={timeZone}
                          >
                            {competitionsCard.startDateTimeUTC.replace(
                              ' ',
                              'T'
                            ) + 'Z'}
                          </Moment>{' '}
                          at{' '}
                          <Moment
                            format={AppConstants.twelveHoursTimeFormat}
                            tz={timeZone}
                          >
                            {competitionsCard.startDateTimeUTC.replace(
                              ' ',
                              'T'
                            ) + 'Z'}
                          </Moment>{' '}
                          {timeZone === AppConstants.utcTimezone &&
                            `(${competitionsCard.preferredTimezone})`}
                        </span>
                      </div>
                    </div>
                    <div className="cardTimeAndDate">
                      <div>
                        <BiCalendarX className="calendarIcon" />
                        &nbsp;
                      </div>
                      <div className="dateTimeText">
                        End:&nbsp;
                        <span className="competitionDateTimeFormat">
                          <Moment
                            format={
                              AppConstants.competitionCardsDateMonthFormat
                            }
                            tz={timeZone}
                          >
                            {competitionsCard.endDateTimeUTC.replace(' ', 'T') +
                              'Z'}
                          </Moment>{' '}
                          at{' '}
                          <Moment
                            format={AppConstants.twelveHoursTimeFormat}
                            tz={timeZone}
                          >
                            {competitionsCard.endDateTimeUTC.replace(' ', 'T') +
                              'Z'}
                          </Moment>{' '}
                          {timeZone === AppConstants.utcTimezone &&
                            `(${competitionsCard.preferredTimezone})`}
                        </span>
                      </div>
                    </div>
                    <div className="buttonSection">
                      <div>
                        <Button
                          type="button"
                          onClick={(e) => {
                            stopPropagation(e);
                            viewCompDetails(competitionsCard);
                          }}
                          className="cardButton me-2"
                        >
                          View
                        </Button>
                        {props.showCancelButton &&
                          competitionsCard.status !== 'Cancelled' &&
                          new Date(
                            DateTimeUtil.utcToPreferredTimezone(
                              competitionsCard.startDateTimeUTC
                            )
                          ) > new Date() && (
                            <Button
                              type="button"
                              onClick={(e) => {
                                stopPropagation(e);
                                handleModalShow();
                                setModalDataFunc(competitionsCard);
                              }}
                              className="cardCancelButton"
                            >
                              Cancel
                            </Button>
                          )}
                      </div>
                    </div>
                    <div className="cardUserProfileImageSectionExpert mt-1">
                      <div className="cardUserProfileImageContainerExpert">
                        {competitionsCard?.length !== 0 &&
                          competitionsCard.participantsDetails !== null &&
                          competitionsCard?.participantsDetails?.length !== 0 &&
                          participantsImageList?.length !== 0 &&
                          participantsImageList[index]?.length !== 0 && (
                            <div className="cardProfileImageSection">
                              {competitionsCard.length !== 0 &&
                                competitionsCard.participantsDetails !== null &&
                                participantsImageList[index]
                                  ?.slice(
                                    0,
                                    AppConstants.studentProfileImageLimit
                                  )
                                  ?.map((imgageSrc, i) => (
                                    <img
                                      src={imgageSrc}
                                      key={i}
                                      className="cardProfileImage"
                                      alt="student profile"
                                    />
                                  ))}
                            </div>
                          )}
                        <div className="cardEnrolledCountSection">
                          <div className="cardText">
                            {fetchFormattedEnrolledCount(
                              competitionsCard.enrolledParticipants,
                              competitionsCard.isTeamEnrolmentEnabled
                            )}
                          </div>
                        </div>
                      </div>
                      <div onClick={stopPropagation}>
                        {(competitionsCard.type.toUpperCase() ===
                          AppConstants.physicalText ||
                          competitionsCard.type.toUpperCase() ===
                            AppConstants.offlineText) && (
                          <span
                            className="onlineIcon"
                            data-tooltip-place="top"
                            data-tooltip-id={competitionsCard.uniqueId}
                          >
                            <a
                              href={competitionsCard.geoLocation}
                              target={AppConstants.openInNewTab}
                              className="cardLink"
                            >
                              <CgPin className="geoLocationIcon" />
                            </a>
                          </span>
                        )}
                      </div>
                      <Tooltip
                        className="tooltipMessage"
                        id={competitionsCard.uniqueId}
                      >
                        <span className="tooltipMessage">
                          {competitionsCard.venueLocation}
                        </span>
                      </Tooltip>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
      </Row>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className={classes.modalBody}>
          {!(modalSuccess || modalError) && (
            <div className={classes.modalAlertIcon}>
              <AiOutlineQuestionCircle />
            </div>
          )}
          {modalSuccess && (
            <div className={classes.modalSuccessIcon}>
              <AiOutlineCheckCircle />
            </div>
          )}
          {modalError && (
            <div className={classes.modalErrorIcon}>
              <AiOutlineExclamationCircle />
            </div>
          )}
          <div className={classes.modalAlertText}>{modalAlertText}</div>
        </Modal.Body>
        <Modal.Footer className={classes.modalFooter}>
          {!(modalSuccess || modalError) && (
            <Button
              className={classes.modalNoButton}
              onClick={handleModalClose}
            >
              No
            </Button>
          )}
          {!(modalSuccess || modalError) && (
            <Button
              className={classes.modalYesButton}
              onClick={() => cancelCompetition(modalData)}
            >
              Yes {modalSpinner && <BiLoaderAlt className={classes.spinner} />}
            </Button>
          )}
          {(modalSuccess || modalError) && (
            <Button
              className={classes.modalCloseButton}
              onClick={handleModalClose}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
        {loading && cardCount < fetchedCompetitionsData?.length && (
          <div className="templatePageLoader">
            <BiLoaderAlt className="spinner" />
          </div>
        )}
      </Row>
    </>
  );
};
