export const FetchLearnerProfileHeatmapData = async ({ userSlug, date }) => {
  const url = `${process.env.REACT_APP_API_URL}learningJourneyForLearner/${encodeURIComponent(userSlug)}/${encodeURIComponent(date)}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(
        `HTTP error! status: ${response.status} - ${response.statusText}`
      );
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};