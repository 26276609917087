import React, { useState } from 'react';
import { Modal, Spinner, Button, ButtonGroup } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';
import styles from './PaymentStatusModal.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { useLearnerPassStore } from '../../../stores';
import { FaWhatsapp } from 'react-icons/fa6';
import { CiPhone } from 'react-icons/ci';

const PaymentStatusModal = ({ show, status, onClose, paymentId }) => {
  const verifyGif = `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.greenVerificationGif}`;
  const phoneNumber = AppConstants.helpContact;
  const whatsAppPhoneNumber = AppConstants.whatsAppChatbotProps.phoneNumber;
  const whatsappPaymentFailureMessage = `Hi, my payment with the payment ID ${paymentId} failed. Can you help me figure this out?`;

  const { learnerPassExpiryDate } = useLearnerPassStore();

  // Determine the modal content based on payment status
  const getStatusContent = () => {
    switch (status) {
      case 'processing':
        return {
          title: 'Processing Payment',
          message: 'Please wait while we process your payment..',
          icon: <Spinner animation="border" role="status" />,
        };
      case 'success':
        return {
          title: 'Payment Successful',
          message:
            'Your Learner Pass has been activated! You can now enjoy premium benefits on Qrencia.',
          icon: <i className="bi bi-check-circle-fill text-success fs-3"></i>,
          // image: <img className={styles.verifyCheckImg} src={verifyGif} />,
          // expiryDate: learnerPassExpiryDate
        };
      case 'failed':
        return {
          title: <h2 className={styles.title}>Payment Issue</h2>,
          message: `Looks like there was some issue processing your purchase. \nDon't worry, your money is safe with us and we are aware of this issue. We are working to resolve it as soon as possible. \nYou can reach out to us at ${phoneNumber} directly for immediate assistance.`,
          icon: <i className="bi bi-x-circle-fill text-danger fs-3"></i>,
        };
      default:
        return {
          title: '',
          message: '',
          icon: null,
        };
    }
  };

  const { title, message, icon, image, expiryDate } = getStatusContent();
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyPaymentId = () => {
    navigator.clipboard.writeText(paymentId).then(
      () => setCopySuccess(true),
      () => alert('Failed to copy Payment ID')
    );

    setTimeout(() => setCopySuccess(false), 2000);
  };

  const handleEmailSupport = () => {
    const email = AppConstants.supportEmailAddress;
    const subject = 'PAYMENT ISSUE';
    const body = `Hi,\n\nI made a payment using the payment ID ${paymentId} but it did not go through. Can you help me figure this out?\n`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close"
        >
          &times;
        </button>
        <div className="mb-3">{icon}</div>
        <div>
          {status === 'success' && (
            <img className={styles.verifyCheckImg} src={verifyGif} />
          )}
        </div>
        <h3 className="title">{title}</h3>
        <p className="message">{message}</p>
        {expiryDate && (
          <p>Your Learner Pass expires on {learnerPassExpiryDate}</p>
        )}
        <br />
        <div className={styles.paymentIdContainer}>
          <span className={styles.paymentId}>Payment ID: </span>
          {paymentId}{' '}
          <FaCopy
            onClick={handleCopyPaymentId}
            className={styles.copyIcon}
            title="Copy Payment ID"
          />
        </div>

        {status === 'failed' && (
          <div className={styles.buttonContainer}>
            <Button
              href={`tel:${phoneNumber}`}
              variant="primary"
              aria-label="Call Us"
              className={styles.callUsButton}
            >
              <CiPhone /> Call Us
            </Button>

            <Button
              href={`https://wa.me/${whatsAppPhoneNumber}?text=${encodeURIComponent(whatsappPaymentFailureMessage)}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="success"
              aria-label="Chat on WhatsApp"
              className={styles.whatsappButton}
            >
              <FaWhatsapp /> Chat on WhatsApp
            </Button>
          </div>
        )}

        {copySuccess && (
          <p className={styles.copySuccess}>Payment ID copied to clipboard!</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentStatusModal;
