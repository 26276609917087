import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const useSubscriptionStore = create(
  persist(
    (set) => ({
      subscriptionStatus: 'Not Purchased',
      setSubscriptionStatus: (status) => set({ subscriptionStatus: status }),
      subscriptionExpiryDate: '2025-01-01T00:00:00Z',
      setSubscriptionExpiryDate: (date) =>
        set({ subscriptionExpiryDate: date }),
      renewSubscriptionNotificationThreshold: 30,
      setRenewSubscriptionNotificationThreshold: (threshold) =>
        set({ renewSubscriptionNotificationThreshold: threshold }),
    }),
    { name: 'subscriptionStorage' }
  )
);

export default useSubscriptionStore;
