import React, { useCallback, useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { AgGridReact } from 'ag-grid-react';
import { Button } from 'react-bootstrap';
import { FaRegClock } from 'react-icons/fa';
import styles from './ExpertStudentFeeDetails.module.css';
import { DateFilterComponent } from '../../component/adminDashboardComponents/dateFilterComponent/DateFilterComponent';
import {
  AppConstants,
  EXPERT_STUDENT_DETAILS,
} from '../../constants/AppConstants';
import { subscriptionSummaryDataAPI } from '../../constants/AppConstants';
import {
  ErrorMessages,
  subscriptionSummaryErrorMessages,
} from '../../constants/ErrorMessages';
import {
  SuccessMessage,
  successMessageSubscriptionSummaryRemarks,
} from '../../constants/SuccessMessage';
import { ErrorSuccessAlertMessage } from '../../component/errorSuccessMessage/ErrorSuccessAlertMessage';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import moment from 'moment';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import { postSubscriptionSummaryRemarksAPI } from '../../constants/AppConstants';

import { Row } from 'react-bootstrap';
import dayjs from 'dayjs';

const ExpertStudentFeeDetails = () => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const [rowData, setRowData] = useState([]);
  const [showDateFilterModal, setShowFilterDateModal] = useState(false);
  const [dateFilterDisplayValue, setDateFilterDisplayValue] = useState(
    AppConstants.dateFilterPresets.allTime.label
  );
  const [fromDate, setFromDate] = useState(AppConstants.emptyString);
  const [toDate, setToDate] = useState(AppConstants.emptyString);
  const [subscriptionReminderSpinner, setSubscriptionReminderSpinner] =
    useState([]);
  const [subscriptionAlertVarient, setSubscriptionAlertVarient] = useState(
    AppConstants.emptyString
  );
  const [
    subscriptionReminderEmailMessage,
    setSubscriptionReminderEmailMessage,
  ] = useState(AppConstants.emptyString);
  const gridRef = useRef(null); // Reference for AG Grid
  const notify = () => {
    toast.success('Successfully sent the fee reminder');
  };
  const fetchGridData = useCallback(async () => {
    const url = subscriptionSummaryDataAPI;
    const response = await AuthorizedDataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );
    setRowData(response);
    return null;
  }, []);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  // Function to handle remarks update
  const handleRemarksUpdate = async (rowData, newRemakrs, index) => {
    // Make a POST request to update remarks in the backend
    const remarksPayload = {
      qId: rowData.qid,
      courseIntId: rowData.courseIntId,
      remarks: newRemakrs,
    };
    try {
      //const url = postSubscriptionSummaryRemarksAPI;
      const updateRemarksResponse = await AuthorizedDataService.post(
        postSubscriptionSummaryRemarksAPI,
        remarksPayload,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (updateRemarksResponse !== undefined) {
        if (updateRemarksResponse.ok && updateRemarksResponse.status === 200) {
          setSubscriptionAlertVarient(AppConstants.alertVarient[0]);
          // setSubscriptionReminderEmailMessage(
          //   `${successMessageSubscriptionSummaryRemarks.subscriptionRemarksPostSuccess} ${rowData.studentEmail}`
          // );
          toast.success(`Remarks added successfully for ${rowData.name}`);
          setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
            subscriptionReminderSpinner.map((state, idx) =>
              idx === index ? false : state
            )
          );
          let timer = setTimeout(() => {
            setSubscriptionReminderEmailMessage(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);
          return () => {
            clearTimeout(timer);
          };
        } else {
          setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
            subscriptionReminderSpinner.map((state, idx) =>
              idx === index ? false : state
            )
          );
          setSubscriptionReminderEmailMessage(
            ErrorMessages.subscriptionReminderEmailError
          );
          setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
          let timer = setTimeout(() => {
            setSubscriptionReminderEmailMessage(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);
          return () => {
            clearTimeout(timer);
          };
        }
      } else {
        setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
          subscriptionReminderSpinner.map((state, idx) =>
            idx === index ? false : state
          )
        );
        setSubscriptionReminderEmailMessage(
          ErrorMessages.subscriptionReminderEmailError
        );
        setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
        let timer = setTimeout(() => {
          setSubscriptionReminderEmailMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => {
          clearTimeout(timer);
        };
      }
    } catch (error) {
      setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
        subscriptionReminderSpinner.map((state, idx) =>
          idx === index ? true : state
        )
      );
      setSubscriptionReminderEmailMessage(
        ErrorMessages.subscriptionReminderEmailError
      );
      setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
      let timer = setTimeout(() => {
        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
      return () => {
        clearTimeout(timer);
      };
    }
  };

  const studentFeeDetailsColumnDefs = [
    {
      headerName: 'QID',
      field: 'qid',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 80,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      sort: 'asc',
      width: 170,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Course Name',
      field: 'courseTitle',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 160,
      sortable: true,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Enroled On',
      field: 'enrolledOn',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 130,
      valueGetter: ({ data }) => {
        return data.enrolledOn
          ? `${moment.utc(data.enrolledOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      tooltipValueGetter: ({ data }) =>
        data.enrolledOn
          ? `${moment.utc(data.enrolledOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      comparator: (valueA, valueB) => {
        const dateA = moment.utc(valueA, 'D MMM YYYY');
        const dateB = moment.utc(valueB, 'D MMM YYYY');
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      headerName: 'Last Renewal',
      field: 'lastEnrolledOn',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 130,
      valueGetter: ({ data }) => {
        if (
          data.courseType === AppConstants.structuredCourseType ||
          !data.lastEnrolledOn
        ) {
          return AppConstants.notAvailableText;
        }
        return `${moment.utc(data.lastEnrolledOn).tz(timeZone).format('D MMM YYYY')} ${
          timeZone === AppConstants.utcTimezone ? '(UTC)' : ''
        }`;
      },
      tooltipValueGetter: ({ data }) => {
        if (
          data.courseType === AppConstants.structuredCourseType ||
          !data.lastEnrolledOn
        ) {
          return AppConstants.notAvailableText;
        }
        return `${moment.utc(data.lastEnrolledOn).tz(timeZone).format('D MMM YYYY')} ${
          timeZone === AppConstants.utcTimezone ? '(UTC)' : ''
        }`;
      },

      comparator: (valueA, valueB) => {
        const dateA = moment.utc(valueA, 'D MMM YYYY');
        const dateB = moment.utc(valueB, 'D MMM YYYY');
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      headerName: 'Mode',
      field: 'paymentMode', // This is just for fallback, actual value will be computed
      sortable: true,
      valueGetter: (params) => {
        const paymentMode = params.data?.paymentMode || '';
        const paymentModeRemarks =
          params.data?.paymentModeRemarks?.trim() || '';

        return paymentMode
          ? paymentModeRemarks
            ? `${paymentMode} (${paymentModeRemarks})`
            : paymentMode
          : AppConstants.notAvailableText;
      },
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 100,
      tooltipValueGetter: (params) => {
        const paymentMode = params.data?.paymentMode || '';
        const paymentModeRemarks = params.data?.paymentModeRemarks || '';

        return paymentMode
          ? paymentModeRemarks
            ? `${paymentMode} (${paymentModeRemarks})`
            : paymentMode
          : AppConstants.notAvailableText;
      },
    },
    {
      headerName: 'Amount',
      field: 'cost',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 97,
      valueGetter: (params) => {
        return params.data.cost
          ? `₹${params.data.cost}`
          : AppConstants.notAvailableText;
      },
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Subscription Valid Until',
      field: 'subscriptionValidTill',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 140,
      tooltipValueGetter: ({ data }) => {
        if (
          data.courseType === AppConstants.flexibleCourseType &&
          data.courseTrackingType === AppConstants.durationBasedTrackingText &&
          data.subscriptionValidTill
        ) {
          return `${moment.utc(data.subscriptionValidTill).tz(timeZone).format('D MMM YYYY')} ${
            timeZone === AppConstants.utcTimezone ? '(UTC)' : ''
          }`;
        }
        return AppConstants.notAvailableText;
      },
      valueGetter: ({ data }) => {
        if (
          data.courseType === AppConstants.flexibleCourseType &&
          data.courseTrackingType === AppConstants.durationBasedTrackingText &&
          data.subscriptionValidTill
        ) {
          return `${moment.utc(data.subscriptionValidTill).tz(timeZone).format('D MMM YYYY')} ${
            timeZone === AppConstants.utcTimezone ? '(UTC)' : ''
          }`;
        }
        return AppConstants.notAvailableText;
      },
      comparator: (valueA, valueB) => {
        const dateA = moment.utc(valueA, 'D MMM YYYY');
        const dateB = moment.utc(valueB, 'D MMM YYYY');
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      headerName: 'Classes',
      field: 'classes',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 105,
      valueGetter: ({ data }) =>
        data.courseTrackingType === AppConstants.classBasedTrackingText && data.classes
          ? data.classes
          : AppConstants.notAvailableText,
      tooltipValueGetter: ({ data }) =>
        data.courseTrackingType === AppConstants.classBasedTrackingText && data.classes
          ? data.classes
          : AppConstants.notAvailableText,
    },
    {
      headerName: 'Validity Until',
      field: 'classesValidity',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      sortable: true,
      width: 130,
      valueGetter: ({ data }) => {
        if (
          data.courseType === AppConstants.flexibleCourseType &&
          data.courseTrackingType === AppConstants.classBasedTrackingText &&
          data.classesValidity
        ) {
          return `${moment.utc(data.classesValidity).tz(timeZone).format('D MMM YYYY')} ${
            timeZone === AppConstants.utcTimezone ? '(UTC)' : ''
          }`;
        }
        return AppConstants.notAvailableText;
      },
      tooltipValueGetter: ({ data }) => {
        if (
          data.courseType === AppConstants.flexibleCourseType &&
          data.courseTrackingType === AppConstants.classBasedTrackingText &&
          data.classesValidity
        ) {
          return `${moment.utc(data.classesValidity).tz(timeZone).format('D MMM YYYY')} ${
            timeZone === AppConstants.utcTimezone ? '(UTC)' : ''
          }`;
        }
        return AppConstants.notAvailableText;
      },
      comparator: (valueA, valueB) => {
        const dateA = moment.utc(valueA, 'D MMM YYYY');
        const dateB = moment.utc(valueB, 'D MMM YYYY');
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      headerName: 'Last Remarks',
      field: 'subscriptionSummaryRemark',
      editable: true,
      width: 150,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true, // this is required to make the cell editor popup
      onCellValueChanged: (params) =>
        handleRemarksUpdate(params.data, params.newValue, params.node.rowIndex),
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Phone',
      field: 'mobileNumber',
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
      width: 110,
      tooltipValueGetter: (params) =>
        params.value ? params.value : AppConstants.notAvailableText,
    },
    {
      headerName: 'Actions',
      field: 'Actions',
      headerClass: 'multi-line-header',
      width: 95,
      cellRenderer: (params) => {
        const { courseType, isActiveSubscription } = params.data;
        if (
          courseType === AppConstants.flexibleCourseType &&
          isActiveSubscription === AppConstants.nText
        ) {
          return (
            <Button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Fee Reminder"
             
            >
              <FaRegClock
                onClick={() =>
                  sendSubscriptionReminder(params.data, params.node.rowIndex)
                }
                title="Send Fee Reminder"
                className={`gridIconButton ${styles.gridIcon}`}
              />
            </Button>
          );
        }

        return null;
      },
      cellClass: styles.actionCell,
    },
  ];
  //Send Payment fee reminder
  const sendSubscriptionReminder = async (rowData, index) => {
    try {
      setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
        subscriptionReminderSpinner.map((state, idx) =>
          idx === index ? true : state
        )
      );
      const url = `${AppConstants.sendReminderEmailToRenewFlexiCourseSubscriptionAPI}${rowData.courseUniqueId}&learnerEmail=${encodeURIComponent(rowData.studentEmail)}`;
      const response = await AuthorizedDataService.post(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response !== undefined) {
        if (response.ok && response.status === 200) {
          setSubscriptionAlertVarient(AppConstants.alertVarient[0]);
          setSubscriptionReminderEmailMessage(
            `${SuccessMessage.courseSubscriptionReminderSentSuccessfully} ${rowData.studentEmail}`
          );
          setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
            subscriptionReminderSpinner.map((state, idx) =>
              idx === index ? false : state
            )
          );
          let timer = setTimeout(() => {
            setSubscriptionReminderEmailMessage(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);
          return () => {
            clearTimeout(timer);
          };
        } else {
          setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
            subscriptionReminderSpinner.map((state, idx) =>
              idx === index ? false : state
            )
          );
          setSubscriptionReminderEmailMessage(subscriptionSummaryErrorMessages);
          setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
          let timer = setTimeout(() => {
            setSubscriptionReminderEmailMessage(AppConstants.emptyString);
          }, AppConstants.messageDisappearTime);
          return () => {
            clearTimeout(timer);
          };
        }
      } else {
        setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
          subscriptionReminderSpinner.map((state, idx) =>
            idx === index ? false : state
          )
        );
        setSubscriptionReminderEmailMessage(subscriptionSummaryErrorMessages);
        setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
        let timer = setTimeout(() => {
          setSubscriptionReminderEmailMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
        return () => {
          clearTimeout(timer);
        };
      }
    } catch (error) {
      setSubscriptionReminderSpinner((subscriptionReminderSpinner) =>
        subscriptionReminderSpinner.map((state, idx) =>
          idx === index ? true : state
        )
      );
      setSubscriptionReminderEmailMessage(subscriptionSummaryErrorMessages);
      setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
      let timer = setTimeout(() => {
        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
      return () => {
        clearTimeout(timer);
      };
    }
  };
  const handleDateSelection = ({
    fromDateString,
    toDateString,
    displayValue,
  }) => {
    setShowFilterDateModal(false);
    setFromDate(fromDateString);
    setToDate(toDateString);
    setDateFilterDisplayValue(displayValue);
  };
  //Export CSV data to Excel sheet
  const exportToExcel = useCallback(() => {
    const csvData = gridRef.current?.api.getDataAsCsv({
      allColumns: true,
      columnSeparator: AppConstants.agGridColumnSeparator,
      suppressQuotes: AppConstants.trueText,
    });
    const columnDefs = gridRef.current?.api.getColumnDefs(); // Access column definitions
    FileOperationsUtil.downloadCsvDataAsExcelWithStyling(
      csvData,
      'EnrollmentSummary.xlsx',
      columnDefs
    );
  }, []);
  return (
    <>
      {/* <div onClick={() => setShowFilterDateModal(true)}>
        <MdDateRange />
        {dateFilterDisplayValue}
      </div> */}

      <h3 className={styles.heading}>Student Fee Details</h3>

      {showDateFilterModal && (
        <DateFilterComponent
          onDateSelectionHandle={handleDateSelection}
          handleDateSelectionModalClose={() => setShowFilterDateModal(false)}
          dateFilterDisplayValue={dateFilterDisplayValue}
        />
      )}
      {subscriptionReminderEmailMessage && (
        <ErrorSuccessAlertMessage
          message={subscriptionReminderEmailMessage}
          varient={subscriptionAlertVarient}
        />
      )}

      <div className="ag-theme-alpine" style={{ height: 480, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={studentFeeDetailsColumnDefs}
          rowData={rowData}
          pagination={true}
          paginationPageSize={
            EXPERT_STUDENT_DETAILS.paginationPerPageResultSize
          }
          suppressRowHoverHighlight={true}
          enableCellTextSelection={true}
          ensureDomOrder={true}
          defaultColDef={{
            resizable: true,
            cellStyle: { fontSize: '10px' },
          }}
        />
      </div>
      {rowData?.length > 0 && (
        <Row className="mt-4">
          <div>
            <Button className={styles.exportButton} onClick={exportToExcel}>
              Download
            </Button>
          </div>
        </Row>
      )}
    </>
  );
};

export default ExpertStudentFeeDetails;
