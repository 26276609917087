import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import classes from './SkillProgressAssessStudentTabs.module.css';
import { CreateAssessment } from './CreateAssessments';
import { ViewAndEditAssessmentGrid } from '../grids/ViewAndEditAssessmentGrid';

export const SkillProgressAssessStudentTabs = () => {
  const [activeTab, setActiveTab] = useState('create');

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
        className="mb-3"
        transition
      >
        <Tab eventKey="create" title="Create">
          <div className={classes.tabContainer}>
            <CreateAssessment />
          </div>
        </Tab>
        <Tab eventKey="view" title="View">
          {activeTab === 'view' && <ViewAndEditAssessmentGrid />}
        </Tab>
      </Tabs>
    </>
  );
};
