import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Row, Col, Form } from 'react-bootstrap';
import formClass from '../../common/FormStyling.module.css';
import classes from './SkillProgressAssessmentTabs.module.css';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import * as Yup from 'yup';
import '../../index.css';
import { ViewAndEditSkillProgressTemplateGrid } from '../grids/ViewAndEditSkillProgressTemplateGrid';
import { SKILL_PROGRESS_ASSESSMENT_CONSTANTS } from '../../constants/AppConstants';
import { skillProgressTemplateCreation } from '../../constants/ErrorMessages';
import { successMessageSkillProgressTemplate } from '../../constants/SuccessMessage';
import { AppConstants } from '../../constants/AppConstants';
import { MdDelete } from 'react-icons/md';
import Auth from "../../utils/Auth";

export const SkillProgressCreateTemplateTabs = (props) => {
  const [refresh, setRefresh] = useState(false);
  const formLabels = AppConstants.createAssessmentFormLabels;
  const [totalMarks, setTotalMarks] = useState(0);
  const [parameters, setParameters] = useState([
    { parameterName: '', maximumMarks: '' },
  ]);
  const [skills, setSkills] = useState([]);

  const handleTabChange = (tabKey) => {
    if (tabKey === "view-and-edit") {
      setRefresh(prev => !prev); // Toggle state to trigger useEffect in the child component
    }
  };

  useEffect(() => {
    const fetchSkillsForExpert = async () => {
      const url = AppConstants.fetchExpertSkillsApi;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );
      if (response) {

        // Sort the skills alphabetically based on skillName
        const sortedSkills = response.sort((a, b) =>
          a.skillName.localeCompare(b.skillName)
        );

        // Set the sorted skills array in the state
        setSkills(sortedSkills);
      }

    };

    async function fetchSkillsForAgency() {
      const url = AppConstants.getSkillsofAgencyAPI;
      const response = await AuthorizedDataService.getRequest(
        url,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (response) {
        // Sort the skills alphabetically based on skillName
        setSkills(response.sort((a, b) =>
          a.skillName.localeCompare(b.skillName)
        ));
      }
    };

    const details = Auth.getLoggedInUserDetails();
    if (details)

      if (details.userRole === AppConstants.userRoleAgency) {
        fetchSkillsForAgency();
      } else {
        fetchSkillsForExpert();
      }
  }, []);

  const validationSchema = Yup.object().shape({
    templateName: Yup.string()
      .trim()
      .min(3, 'Minimum 3 characters required')
      .max(250, 'Maximum 250 characters allowed')
      .required('Template Name is required'),
    templateDescription: Yup.string()
      .trim()
      .min(3, 'Minimum 3 characters required')
      .max(250, 'Maximum 250 characters allowed')
      .notRequired(),
    skillId: Yup.string()
      .required('Skill is required')
      .oneOf(skills?.map((s) => s.skillId)),
    parameters: Yup.array()
      .of(
        Yup.object().shape({
          parameterName: Yup.string()
            .trim()
            .min(3, 'Minimum 3 characters required')
            .required('Parameter Name is required'),
          maximumMarks: Yup.number()
            .positive('Marks must be positive')
            .typeError('Maximum Marks must be a number')
            .required('Maximum Marks is required')
            .min(0.1, 'Maximum marks must be at least 0.1')
        })
      )
      .min(1, 'At least one parameter is required'),
  });

  useEffect(() => {
    if (skills?.length === 1) {
      setFieldValue(formLabels.skill.name, skills[0]?.skillId);
    }
  }, [skills]);

  const formik = useFormik({
    initialValues: {
      templateName: '',
      templateDescription: '',
      skillId: skills?.length === 1
        ? skills[0]?.skillId
        : AppConstants.emptyString,
      parameters: parameters,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let url = SKILL_PROGRESS_ASSESSMENT_CONSTANTS.postSkillProgressTemplateApi;
        let data = values;
        const response = await AuthorizedDataService.post(url, data, '', '');
        if (response) {
          toast.success(
            successMessageSkillProgressTemplate.skillProgressTemplateCreatedSuccess,
            {
              style: { width: 'auto', minWidth: '200px', maxWidth: '400px' },
            }
          );
          resetForm(); // Reset form after success
          if (skills?.length === 1) {
            setFieldValue(formLabels.skill.name, skills[0]?.skillId);
          }
          setTotalMarks(0);
          setParameters([{ parameterName: '', maximumMarks: '' }]); // Reset parameters
          setFieldValue('parameters', [{ parameterName: '', maximumMarks: '' }]); // Reset Formik field explicitly
        }
      } catch (error) {
        toast.error(
          skillProgressTemplateCreation.skillProgressTemplateCreationError,
          {
            style: { width: 'auto', minWidth: '200px', maxWidth: '400px' },
          }
        );
      }
    }
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  // Add new parameter
  const addParameter = () => {
    const newParam = { parameterName: '', maximumMarks: '' };
    setParameters([...parameters, newParam]); // Update local state
    setFieldValue('parameters', [...values.parameters, newParam]); // Update Formik state
  };

  // Handle parameter field changes
  const handleParameterChange = (index, field, value) => {
    const newParameters = [...parameters];
    newParameters[index][field] = value;

    // Convert values to numbers and sum up the total marks
    const newTotalMarks = newParameters.reduce((sum, param) => {
      return sum + (parseFloat(param.maximumMarks) || 0);
    }, 0);

    setParameters(newParameters); // Update local state
    setFieldValue(`parameters.${index}.${field}`, value); // Update Formik state
    setTotalMarks(newTotalMarks); // Update the total marks state

  };

  /* Function to remove parameter */
  const removeParameter = (index) => {
    const newParameters = parameters.filter((_, i) => i !== index);

    const newTotalMarks = newParameters.reduce((sum, param) => {
      return sum + (parseFloat(param.maximumMarks) || 0);
    }, 0);

    setParameters(newParameters);
    setFieldValue('parameters', newParameters);
    setTotalMarks(newTotalMarks); // Update the total marks state
  };
  return (
    <div className={classes.skillProgressTab}>
      <Tabs onSelect={handleTabChange} defaultActiveKey="create" id="create" className="mb-3" transition>
        <Tab eventKey="create" title="Create">
          <div className={classes.formContainer}>
            <div className={classes.createTab}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Template Name */}
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Group>
                      <Form.Label className={formClass.formLabel}>
                        Template Name<span className="redAstrisk">*</span>
                      </Form.Label>
                      <Form.Control
                        className={`${formClass.formInput} ${errors.templateName && touched.templateName
                          ? classes.inputBoxError
                          : ''
                          }`}
                        type="text"
                        name="templateName"
                        placeholder="Template name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.templateName}
                      />
                      {errors.templateName && touched.templateName && (
                        <div className={classes.skillProgressErrorText}>
                          {errors.templateName}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    {(
                      <Form.Group
                        className="mb-2"
                        as={Col}
                        controlId="basicDetailsSkill"
                      >
                        <Form.Label className={formClass.formLabel}>
                          {formLabels.skill.label}
                          <span className="redAstrisk">*</span>
                        </Form.Label>
                        <Form.Select
                          className={formClass.selectInput}
                          name={formLabels.skill.name}
                          value={values.skillId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.skillId && errors.skillId}
                        >
                          <option value={AppConstants.emptyString} defaultValue>
                            Select Skill
                          </option>
                          {skills?.map((option) => (
                            <option key={option.skillId} value={option.skillId}>
                              {option.skillName}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.skillId &&
                          touched.skillId && (
                            <div className={classes.skillProgressErrorText}>
                              {errors.skillId}
                            </div>
                          )}
                      </Form.Group>
                    )}
                  </Col>

                  {/* Template Description */}
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form.Group>
                      <Form.Label className={formClass.formLabel}>
                        Template Description
                      </Form.Label>
                      <Form.Control
                        className={`${formClass.formTextAreaInput} ${errors.templateDescription &&
                          touched.templateDescription
                          ? classes.inputBoxError
                          : ''
                          }`}
                        as="textarea"
                        name="templateDescription"
                        placeholder="Template Description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.templateDescription}
                      />
                      {errors.templateDescription &&
                        touched.templateDescription && (
                          <div className={classes.skillProgressErrorText}>
                            {errors.templateDescription}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>

                {/* Dynamic Parameters Fields */}
                {parameters.map((param, index) => (
                  <div key={index} className="mt-2">
                    <Row>
                      {/* Parameter Name */}
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group>
                          <Form.Label className={formClass.formLabel}>
                            Parameter {index + 1}<span className="redAstrisk">*</span>
                          </Form.Label>
                          <Form.Control
                            className={`${formClass.formInput} ${errors.parameters?.[index]?.parameterName &&
                              touched.parameters?.[index]?.parameterName
                              ? classes.inputBoxError
                              : ''
                              }`}
                            type="text"
                            placeholder="Parameter Name"
                            value={param.parameterName}
                            onChange={(e) =>
                              handleParameterChange(
                                index,
                                'parameterName',
                                e.target.value
                              )
                            }
                            onBlur={handleBlur}
                          />
                          {errors.parameters?.[index]?.parameterName &&
                            touched.parameters?.[index]?.parameterName && (
                              <div className={classes.skillProgressErrorText}>
                                {errors.parameters[index].parameterName}
                              </div>
                            )}
                        </Form.Group>
                      </Col>

                      {/* Maximum Marks */}
                      <Col xs={parameters.length > 1 ? 11 : 12}
                        sm={parameters.length > 1 ? 11 : 12}
                        md={parameters.length > 1 ? 5 : 6}
                        lg={parameters.length > 1 ? 5 : 6}
                        xl={parameters.length > 1 ? 5 : 6}>
                        <Form.Group>
                          <Form.Label className={formClass.formLabel}>
                            Maximum Marks<span className="redAstrisk">*</span>
                          </Form.Label>
                          <Form.Control
                            className={`${formClass.formInput} ${errors.parameters?.[index]?.maximumMarks &&
                              touched.parameters?.[index]?.maximumMarks
                              ? classes.inputBoxError
                              : ''
                              }`}
                            type="number"
                            placeholder="Maximum Marks"
                            value={param.maximumMarks}
                            onChange={(e) =>
                              handleParameterChange(
                                index,
                                'maximumMarks',
                                e.target.value
                              )
                            }
                            onBlur={handleBlur}
                          />
                          {errors.parameters?.[index]?.maximumMarks &&
                            touched.parameters?.[index]?.maximumMarks && (
                              <div className={classes.skillProgressErrorText}>
                                {errors.parameters[index].maximumMarks}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      {parameters.length > 1 && (<Col xs={1} sm={1} md={1} lg={1} xl={1} className={classes.deleteButton}>
                        <Button
                          onClick={() => removeParameter(index)}
                          className={classes.deleteParamButton}
                        >
                          <MdDelete />
                        </Button>
                      </Col>)}
                    </Row>

                  </div>
                ))}

                {/* Add Parameter Button */}
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Button
                      type="button"
                      onClick={addParameter}
                      className={`mt-2 ${classes.addParametersBtn}`}
                    >
                      Add Parameter
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <p className={classes.totalMarksHeading}>Total Marks: {totalMarks}</p>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Row>
                  <Col className={classes.buttonSection}>
                    <Button
                      type="submit"
                      className={`btn ${classes.saveTemplateBtn}`}
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Tab>

        {/* View and Edit Tab */}
        <Tab eventKey="view-and-edit" title="View">
          <ViewAndEditSkillProgressTemplateGrid refresh={refresh} />
        </Tab>
      </Tabs>
    </div>
  );
};
