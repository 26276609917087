import React, { useState, useEffect } from 'react';
import popUpstyle from './EoiPopUpSection.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';

export const EoiPopUpSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [popupDisabled, setPopupDisabled] = useState(false);
  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaLogoFilePath}`;

  const closePopup = () => setIsOpen(false);

  useEffect(() => {
    const preloadImages = () => {
      const images = [
        qrenciaLogoUrl,
        `${mediaContent}${AppConstants.popUpImage}`,
      ];
      const promises = images.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      Promise.all(promises)
        .then(() => setImagesLoaded(true))
        .catch(() => console.error('Error loading images'));
    };

    preloadImages();
  }, [qrenciaLogoUrl, mediaContent]);

  useEffect(() => {
    if (!imagesLoaded) return;

    const inactivityTimeout = setTimeout(() => {
      if (!popupDisabled) {
        setIsOpen(true); // Open popup only if not disabled
      }
    }, 20000);

    return () => clearTimeout(inactivityTimeout);
  }, [imagesLoaded, popupDisabled]);

  useEffect(() => {
    // Monitor scrolling to check if user reaches #formSection
    const handleScroll = () => {
      const formSection = document.querySelector('#formSection');
      if (formSection) {
        const rect = formSection.getBoundingClientRect();
        const isInView =
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);

        if (isInView) {
          setPopupDisabled(true); // Disable popup if form is in view
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (event, id) => {
    event.preventDefault();
    const element = document.querySelector(id);
    if (element) {
      let offset = 0;

      if (window.innerWidth > 768) {
        offset = (window.innerHeight - element.offsetHeight) / 2;
      }

      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
    window.history.pushState(null, '', id);
  };

  return (
    <>
      {isOpen && (
        <div className={popUpstyle.popupOverlay}>
          <div className={popUpstyle.popupBox}>
            <span className={popUpstyle.closeButton} onClick={closePopup}>
              <RxCross2 />
            </span>
            <h2 className={popUpstyle.popupTitle}>
              <img
                className={popUpstyle.qrenciaLogo}
                src={qrenciaLogoUrl}
                alt="Qrencia Logo"
              ></img>
            </h2>
            <div className={popUpstyle.popUpImageSection}>
              <img
                className={popUpstyle.popUpImage}
                src={`${mediaContent}${AppConstants.popUpImage}`}
                alt="popUpImage"
              ></img>
              <p className={popUpstyle.businessGrow}>
                Want to Grow Your Business?
              </p>
              <p className={popUpstyle.popUpDescription}>
                {AppConstants.popUpDescription}
              </p>
            </div>

            <NavLink
              to="#formSection"
              className={popUpstyle.sectionNavItem}
              onClick={(e) => {
                handleScroll(e, '#formSection');
                closePopup();
              }}
            >
              Subscribe
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};
