import React, { useEffect, useState } from 'react';
import styles from './RecentActivity.module.css';
import dayjs from 'dayjs';
import { transformActivities } from './transformRecentActivityData';
import { DateTimeUtil } from '../../../utils/DateTimeUtil';
import timezone from 'dayjs/plugin/timezone';
import Moment from 'react-moment';
import { AppConstants } from '../../../constants/AppConstants';
import { FcApproval } from 'react-icons/fc';
import { FaRegCircleDot } from 'react-icons/fa6';
import useRecentActivityCardStore from '../../../stores/recentActivitiesCardStore';
import { Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
dayjs.extend(timezone);

const RecentActivity = (props) => {
  const [recentActivities, setRecentActivities] = useState([]);
  const [userTimezone, setUserTimezone] = useState(
    DateTimeUtil.getPreferredTimeZoneForUser()
  );
  const [isEditingRecentActivity, setIsEditingRecentActivity] = useState(false);
  const [visibilityMap, setVisibilityMap] = useState({});
  const [changedVisibility, setChangedVisibility] = useState({});

  useEffect(() => {
    setRecentActivities(transformActivities(props.recentActivities));
  }, [props.recentActivities]);

  useEffect(() => {
    if (props.recentActivities.length > 0) {
      const visibilityMap = {};
      props.recentActivities.forEach((activity) => {
        if (activity.recentActivityType === 'Self Declared') {
          visibilityMap[activity.learningActivityId] =
            activity.isActiveFlag === 'Y';
        }
      });
      setVisibilityMap(visibilityMap);
    }
  }, [props.updateAchievements, props.recentActivities]);

  const toggleView = (id) => {
    setVisibilityMap((prev) => {
      const newVisibility = !prev[id];
      setChangedVisibility((prevChanges) => {
        const updatedChanges = { ...prevChanges, [id]: newVisibility };

        // 🔥 Ensure updated data is immediately passed
        props.onPostPayloadUpdate(updatedChanges);

        return updatedChanges;
      });

      return { ...prev, [id]: newVisibility };
    });
  };

  const handleVisibilityChange = async () => {
    const updatedVisibilityArray = Object.keys(changedVisibility).map((id) => ({
      id: id,
      activeFlag: changedVisibility[id] ? 'Y' : 'N',
    }));

    if (updatedVisibilityArray.length === 0) {
      setIsEditingRecentActivity(false);
      return;
    }
  };

  useEffect(() => {
    handleVisibilityChange();
  }, [props.updateAchievements]);

  const isValidUrl = (url) => {
    try {
      if (url.startsWith('www.')) {
        url = `https://${url}`;
      }

      new URL(url);

      const domainPart = url.replace(/^(https?:\/\/)?(www\.)?/, '');
      return domainPart.split('.').length >= 2;
    } catch {
      return false;
    }
  };

  return recentActivities?.length > 0 ? (
    <>
      <div className={styles.recentActivitiesContainer}>
        {recentActivities.map((activity, index) => {
          return (
            <div>
              <div key={index} className={`${styles.activityCard}`}>
                <div
                  className={`${styles.iconTextContainer
                    } ${activity.learningActivityType.toLowerCase()}`}
                  style={{
                    color:
                      activity.learningActivityType.toLowerCase() === 'course'
                        ? '#34b4b3'
                        : activity.learningActivityType.toLowerCase() ===
                          'competition'
                          ? '#efb04e'
                          : activity.learningActivityType.toLowerCase() ===
                            'workshop'
                            ? '#e77a7b'
                            : '#131523', // Default color
                  }}
                >
                  <FaRegCircleDot />
                </div>
                <div className={styles.titleCreatorContainer}>
                  <p className={styles.activityTextContainer}>
                    {activity.activityVerb}
                    <span className={styles.learningActivityName}>
                      {activity.learningActivityName}
                      {activity.recentActivityType !== 'Self Declared' && (
                        <div className={styles.selfDeclaredContainer}>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="verified-tooltip">
                                Qrencia Verified
                              </Tooltip>
                            }
                          >
                            <span>
                              <FcApproval className={styles.approvedSeal} />
                            </span>
                          </OverlayTrigger>
                        </div>
                      )}
                    </span>
                  </p>

                  <div className={styles.creatorText}>
                    <span className={styles.creatorText}>
                      {activity?.learningActivityType.toLowerCase() === 'course'
                        ? 'Coached by '
                        : 'Organized by '}
                      {activity.recentActivityType === 'Self Declared' ? (
                        activity.organizerWebsiteLink && isValidUrl(activity.organizerWebsiteLink) ? (
                          <a
                            className={styles.creatorName}
                            href={
                              activity.organizerWebsiteLink.startsWith('http://') ||
                                activity.organizerWebsiteLink.startsWith('https://')
                                ? activity.organizerWebsiteLink
                                : `https://${activity.organizerWebsiteLink}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {activity.organizer}
                          </a>
                        ) : (
                          <span className={styles.creatorName}>{activity.organizer}</span>
                        )
                      ) : (
                        <a
                          className={styles.creatorName}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/${activity.creatorType === 'Expert' ? 'expert' : 'agency'}/${activity.organizerWebsiteLink}`}
                        >
                          {activity.organizer}
                        </a>
                      )}
                    </span>

                    <div className={styles.dateContainer}>
                      {activity?.courseType === 'Flexible' ? (
                        <p></p>
                      ) : activity?.learningActivityStatus.toLowerCase() ===
                        'past' ? (
                        <p className={styles.activityDate}>
                          <>
                            <Moment
                              format={
                                AppConstants.competitionCardsDateMonthFormat
                              }
                              tz={userTimezone}
                            >
                              {activity.learningActivityEndDate?.replace(
                                ' ',
                                'T'
                              ) + 'Z'}
                            </Moment>{' '}
                          </>
                        </p>
                      ) : (
                        <p className={styles.activityDate}>
                          <>
                            <Moment
                              format={
                                AppConstants.competitionCardsDateMonthFormat
                              }
                              tz={userTimezone}
                            >
                              {activity.enrolledOn?.replace(' ', 'T') + 'Z'}
                            </Moment>{' '}
                          </>
                        </p>
                      )}
                    </div>
                  </div>
                  {props.isEditingRecentActivity &&
                    activity.recentActivityType === 'Self Declared' && (
                      <div className={styles.activityEdit}>
                        <FaEdit
                          className="cursor-pointer text-gray-500 hover:text-gray-700"
                          size={20}
                          onClick={() => {
                            props.showRecentActivityModal(activity);
                          }}
                        />
                        <Form.Check
                          key={activity.learningActivityId}
                          type="switch"
                          id={`visibility-switch-${index}`}
                          label={
                            <span className={styles.editLabel}>
                              Show on profile?
                            </span>
                          }
                          checked={
                            visibilityMap[activity.learningActivityId] || false
                          }
                          className={styles.editSwitch}
                          onChange={() => {
                            toggleView(activity.learningActivityId);
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  ) : (
    <p>No recent activities found</p>
  );
};

export default RecentActivity;
