import React from 'react';
import { DailyAwardsPage } from '../../pages/expert/DailyAwardsPage';
import { StudentsToBeAwardedCards } from './StudentsToBeAwardedCards';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { QuizAuthenticationForm } from '../../component/quizEventComponent/QuizAuthenticationForm';
import classes from './ChampionOfTheDay.module.css';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { useCallback, useEffect, useState } from 'react';
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { Logout } from '../../common/Logout';
import Auth from '../../utils/Auth';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from '../../constants/AppConstants';

const ChampionOfTheDay = () => {
  const [showPopupForLogin, setShowPopupForLogin] = useState(
    AppConstants.falseText
  );
  const [pageLoaderSpinner, setPageLoaderSpinner] = useState(
    AppConstants.trueText
  );
  const [logoutMessage, setLogoutMessage] = useState(AppConstants.falseText);
  const [landingPageData] = useLoaderData();
  const navigate = useNavigate();
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

  const checkAuthorization = useCallback(() => {
    if (Auth.isLogin()) {
      if (
        Auth.getUserRole() !== AppConstants.userRoleExpert &&
        Auth.getUserRole() !== AppConstants.userRoleAgency
      ) {
        navigate(AppConstants.asterisk);
      }
      setPageLoaderSpinner(AppConstants.falseText);
    } else {
      setShowPopupForLogin(AppConstants.trueText);
      setPageLoaderSpinner(AppConstants.falseText);
    }
  }, [navigate]);

  useEffect(() => {
    checkAuthorization();
  }, [checkAuthorization, navigate, timeZone]);

  const closeAuthenticationForm = () => {
    window.location.reload();
    setShowPopupForLogin(AppConstants.trueText);
  };

  return (
    <>
      {showPopupForLogin ? (
        <div className={classes.loginSection}>
          <QuizAuthenticationForm
            disableSignup={AppConstants.trueText}
            closeAuthenticationForm={closeAuthenticationForm}
            signupHeading="Signup"
            loginHeading="Login"
          />
        </div>
      ) : pageLoaderSpinner || logoutMessage ? (
        <>
          <LandingPageTopNavigation landingPageData={landingPageData} />
          <div className={classes.loaderContainer}>
            {pageLoaderSpinner && (
              <p className={classes.loaderIcon}>
                <BiLoaderAlt className="spinner" />
              </p>
            )}
            {logoutMessage && (
              <p className={classes.sessionExpiredText}>
                Your session has been expired. Please{' '}
                <span onClick={Logout.logout}>login</span> to continue.
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <LandingPageTopNavigation landingPageData={landingPageData} />
          <div className={classes.pageContainer}>
            <DailyAwardsPage />
          </div>
          <LandingPageFooter landingPageData={landingPageData} />
        </>
      )}
    </>
  );
};

export default ChampionOfTheDay;
