import { useEffect, useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { DateTimeUtil } from '../../../../utils/DateTimeUtil';
import { BiLoaderAlt } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { FaRegClock } from 'react-icons/fa';
import { FaAmazonPay } from 'react-icons/fa6';
import { LeadDetailsModal } from '../../../modal/LeadDetailsModal';
import { AppConstants } from '../../../../constants/AppConstants';
import classes from './MonitorNonOnboardedLeads.module.css';
import { FaRegCopy } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import { RiShareForwardFill } from 'react-icons/ri';
import { AuthorizedDataService } from '../../../../services/AuthorizedDataService';
import { SuccessMessage } from '../../../../constants/SuccessMessage';
import { ErrorMessages } from '../../../../constants/ErrorMessages';
import { ErrorSuccessAlertMessage } from '../../../errorSuccessMessage/ErrorSuccessAlertMessage';
import { FileOperationsUtil } from '../../../../utils/FileOperationsUtil';
import LeadStatus from '../../LeadStatus/LeadStatus';
import { DataService } from '../../../../services/DataService';
import PaymentStatusChip from '../../PaymentStatusChip/PaymentStatusChip';

export const MonitorNonOnboardedLeads = (props) => {
  const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
  const nonOnboardedRef = useRef(null);
  const [showLeadDetailsModal, setShowLeadDetailsModal] = useState(
    AppConstants.falseText
  );
  const [selectedLead, setSelectedLead] = useState({});
  const [submitObrMessage, setSubmitObrMessage] = useState(
    AppConstants.emptyString
  );
  const [submitObrSpinner, setSubmitObrSpinner] = useState(
    new Array(props.leadsList?.length).fill(AppConstants.falseText)
  );
  const [copyObrLinkSuccess, setCopyObrLinkSuccess] = useState(
    new Array(props.leadsList?.length).fill(AppConstants.falseText)
  );
  const [sendReminderSpinner, setSendReminderSpinner] = useState(
    new Array(props.leadsList?.length).fill(AppConstants.falseText)
  );
  const [alertVarient, setAlertVarient] = useState(0);
  const [reminderAlertVarient, setReminderAlertVarient] = useState(0);
  const [reminderAlertMessage, setReminderAlertMessage] = useState(
    AppConstants.emptyString
  );
  const [subscriptionResponse, setSubscriptionResponse] = useState('');
  const icons = AppConstants.qrenciaApplicationIcons;

  const getIconPath = (icon) => {
    return `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.iconImagesS3Path}${AppConstants.forwardSlash}${icon}`;
  };

  useEffect(() => {
    async function fetchMembershipPlans() {
      //fetch subscription details
      let url = AppConstants.getCreatorMembershipAPI;
      let subscriptionDetailsResponse = await DataService.get(url, '', '');
      if (subscriptionDetailsResponse) {
        setSubscriptionResponse(subscriptionDetailsResponse);
      }
    }

    fetchMembershipPlans();
  }, []);

  useEffect(() => {
    if (props.leadsList !== null && props.leadsList !== undefined) {
      setSubmitObrSpinner(
        new Array(props.leadsList?.length).fill(AppConstants.falseText)
      );
      setSendReminderSpinner(
        new Array(props.leadsList?.length).fill(AppConstants.falseText)
      );
      setCopyObrLinkSuccess(
        new Array(props.leadsList?.length).fill(AppConstants.falseText)
      );
    }
  }, [props.leadsList]);

  const toggleViewComponent = (data) => {
    let skillIntegerArrays = data.skills?.split(',').map(Number);
    let selectedSkills = props.skillDetails
      .filter((item) => {
        return skillIntegerArrays?.some(
          (skillId) => skillId === item.skillUniqueId
        );
      })
      ?.map((item) => ({
        skillId: item.skillId,
        skillName: item.skill,
        skillIntegerId: item.skillUniqueId,
      }));

    setSelectedLead({ ...data, selectedSkills });
    setShowLeadDetailsModal(AppConstants.trueText);
  };

  const closeLeadDetailsModal = () => {
    setShowLeadDetailsModal(AppConstants.falseText);
    setSelectedLead({});
  };

  const getSelectedSkills = (data) => {
    let skillIntegerArrays = data.skills?.split(',').map(Number);
    let selectedSkills = props.skillDetails
      .filter((item) => {
        return skillIntegerArrays?.some(
          (skillId) => skillId === item.skillUniqueId
        );
      })
      ?.map((item) => ({
        skillId: item.skillId,
        skillName: item.skill,
        skillIntegerId: item.skillUniqueId,
      }));

    return selectedSkills;
  };

  const getValidTextClass = ({ value }) => {
    if (
      value === AppConstants.expertObrStatus.statusAccepted ||
      value === AppConstants.yText
    ) {
      return `${classes.obrGreenText}`;
    } else if (
      value === AppConstants.expertObrStatus.statusRejected ||
      value === AppConstants.nText
    ) {
      return `${classes.obrRedText}`;
    } else if (value === AppConstants.expertObrStatus.statusDraft) {
      return `${classes.obrOrangeColorText}`;
    } else if (value === AppConstants.expertObrStatus.statusInReview) {
      return `${classes.obrBlueDarkText}`;
    }
  };

  function getSKillValueString(skills) {
    let skillIntegerArrays = skills?.split(',').map(Number);
    return (
      props.skillDetails
        .filter((item) => {
          return skillIntegerArrays?.some(
            (skillId) => skillId === item.skillUniqueId
          );
        })
        ?.map((item) => item.skill)
        .join(',') || AppConstants.notAvailableText
    );
  }

  const sendOBR = async (data, index) => {
    try {
      let payload = {};
      if (data.leadType !== AppConstants.leadTypes.expert) {
        payload = {
          obrState: data.obrState,
          leadUniqueId: data.leadUniqueId,
          agencyHeadCity: data.city,
          agencyHeadCountry: data.country,
          agencyHeadCountryId: data.countryId,
          agencyHeadEmail: data.email,
          agencyHeadState: data.state,
          agencyHeadFirstName: data.firstName,
          agencyHeadLastName: data.lastName,
          agencyHeadPhoneNumber: data.phoneNumber,
          agencyName: data.instituteName,
          agencyType: data.leadType,
          listingVisibilityFlag: data.listingEnabled,
          userSlug: data.userSlug,
          agencyWebsiteLink: data.websiteLink || AppConstants.nullText,
          selectedSkills:
            data.leadType === AppConstants.leadTypes.academy
              ? getSelectedSkills(data)
              : AppConstants.nullText,
        };
      } else {
        payload = {
          obrState: data.obrState,
          leadUniqueId: data.leadUniqueId,
          countryId: data.countryId,
          expertCountry: data.country,
          expertCity: data.city,
          expertEmail: data.email,
          expertFirstName: data.firstName,
          expertLastName: data.lastName,
          expertState: data.state,
          phoneNumber: data.phoneNumber,
          selectedSkills: getSelectedSkills(data),
          expertDob: data.dob,
          listingVisibilityFlag: data.listingEnabled,
          userSlug: data.userSlug,
          websiteLink: data.websiteLink || AppConstants.nullText,
          expertOrganizationDetails: data.instituteName
            ? {
                organizationName: data.instituteName,
              }
            : null,
        };
      }

      setSubmitObrSpinner((submitObrSpinner) =>
        submitObrSpinner.map((state, idx) =>
          idx === index ? AppConstants.trueText : state
        )
      );

      const submitObrUrl =
        data.leadType === AppConstants.leadTypes.expert
          ? AppConstants.submitObrAPI
          : AppConstants.postAgencyObrDetailsAPI;

      const response = await AuthorizedDataService.postRequest(
        submitObrUrl,
        payload,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (
        response &&
        (response?.status === AppConstants.httpResponseCodes.responseCode200 ||
          response?.status === AppConstants.httpResponseCodes.responseCode201)
      ) {
        //clear form values
        setSubmitObrSpinner((submitObrSpinner) =>
          submitObrSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        if (
          response.status === AppConstants.httpResponseCodes.responseCode200
        ) {
          setSubmitObrMessage(SuccessMessage.obrUpdatedSuccessfully);
        } else if (
          response.status === AppConstants.httpResponseCodes.responseCode201
        ) {
          setSubmitObrMessage(
            `${SuccessMessage.obrCreatedSuccessfully}${AppConstants.space}${data.firstName}${AppConstants.space}${data.lastName}`
          );
        }
        props.refreshNonOnboardedLeads();
        setAlertVarient(AppConstants.alertVarient[0]);
        setTimeout(() => {
          setSubmitObrMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else {
        setSubmitObrMessage(ErrorMessages.obrSubmitError);
        setSubmitObrSpinner((submitObrSpinner) =>
          submitObrSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setAlertVarient(AppConstants.alertVarient[1]);
        setTimeout(() => {
          setSubmitObrMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      }
    } catch (error) {
      setSubmitObrMessage(ErrorMessages.sendOBRFromLeadTableError);
      setSubmitObrSpinner((submitObrSpinner) =>
        submitObrSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setSubmitObrMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const sendPaymentLinkEmail = async (data, index) => {
    try {
      setSubmitObrSpinner((submitObrSpinner) =>
        submitObrSpinner.map((state, idx) =>
          idx === index ? AppConstants.trueText : state
        )
      );
      const sendPaymentLinkUrl = AppConstants.sendPaymentLinkEmailAPI;
      let leadManagementDto = {};
      leadManagementDto = {
        businessType: data.leadType,
        firstName: data.firstName,
        leadIntegerId: data.leadIntegerId,
        leadUniqueId: data.leadUniqueId,
        lastName: data.lastName,
        dob: data.dob,
        email: data.email,
        phoneNumber: data.phoneNumber,
        country: data.country,
        state: data.state,
        city: data.city,
        selectedSkills: getSelectedSkills(data),
        instituteName: data.instituteName,
        websiteLink: data.websiteLink || AppConstants.nullText,
        source: data.source,
        leadType: data.leadType,
        listingEnabled: data.listingEnabled,
        countryId: data.countryId,
        countryMobileNumberCode: data.countryMobileNumberCode,
        rating: data.rating,
        topCreatorFlag: data.topCreatorFlag,
      };

      const payload = {
        membershipPlanDto: subscriptionResponse,
        leadManagementDto: leadManagementDto,
      };

      const response = await AuthorizedDataService.postRequest(
        sendPaymentLinkUrl,
        payload,
        AppConstants.emptyString,
        AppConstants.emptyString
      );

      if (
        response &&
        response?.status === AppConstants.httpResponseCodes.responseCode200
      ) {
        //clear form values
        setSubmitObrSpinner((submitObrSpinner) =>
          submitObrSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setSubmitObrMessage(
          `${SuccessMessage.paymentLinkSentSuccessfully}${AppConstants.space}${
            data.leadType === 'Academy'
              ? data.instituteName
              : `${data.firstName}${AppConstants.space}${data.lastName}`
          }`
        );
        props.refreshNonOnboardedLeads();
        setAlertVarient(AppConstants.alertVarient[0]);
        setTimeout(() => {
          setSubmitObrMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      } else {
        setSubmitObrMessage(ErrorMessages.paymentLinkSendingError);
        setSubmitObrSpinner((submitObrSpinner) =>
          submitObrSpinner.map((state, idx) =>
            idx === index ? AppConstants.falseText : state
          )
        );
        setAlertVarient(AppConstants.alertVarient[1]);
        setTimeout(() => {
          setSubmitObrMessage(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);
      }
    } catch (error) {
      setSubmitObrMessage(ErrorMessages.paymentLinkSendingError);
      setSubmitObrSpinner((submitObrSpinner) =>
        submitObrSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setSubmitObrMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const copyLink = (event, data, index) => {
    let obrLink =
      data.leadType === AppConstants.leadTypes.expert
        ? `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expertOnboardingText}${AppConstants.forwardSlash}${data.expertObrUniqueId}`
        : `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agencyOnboardingText}${AppConstants.forwardSlash}${data.agencyObrUniqueId}`;
    event.preventDefault();
    navigator.clipboard.writeText(obrLink);
    setCopyObrLinkSuccess((copyObrLinkSuccess) =>
      copyObrLinkSuccess.map((state, idx) =>
        idx === index ? AppConstants.trueText : state
      )
    );
    setTimeout(() => {
      setCopyObrLinkSuccess((copyObrLinkSuccess) =>
        copyObrLinkSuccess.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
    }, 3000);
  };

  const redirectToListingPage = (data) => {
    let listingPageLink =
      data.leadType === AppConstants.leadTypes.expert
        ? `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expert}${AppConstants.forwardSlash}${data.userSlug}`
        : `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${data.userSlug}`;
    window.open(listingPageLink, AppConstants.openInNewTab);
  };

  const sendReminderEmail = async (data, index) => {
    const url = AppConstants.sendObrReminderEmailAPI;
    setSendReminderSpinner((sendReminderSpinner) =>
      sendReminderSpinner.map((state, idx) =>
        idx === index ? AppConstants.trueText : state
      )
    );

    const response = await AuthorizedDataService.postRequest(
      url,
      data,
      AppConstants.emptyString,
      AppConstants.emptyString
    );

    if (response && response.ok) {
      setReminderAlertMessage(
        `${SuccessMessage.obrReminderEmailSentSuccessfully}${AppConstants.space}${data.firstName}${AppConstants.space}${data.lastName}`
      );
      setSendReminderSpinner((sendReminderSpinner) =>
        sendReminderSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setReminderAlertVarient(AppConstants.alertVarient[0]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    } else {
      setReminderAlertMessage(ErrorMessages.sendReminderFromLeadError);
      setSendReminderSpinner((sendReminderSpinner) =>
        sendReminderSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setReminderAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const sendSubscriptionReminderEmail = async (data, index) => {
    const url = `${AppConstants.sendFirstMembershipPaymentReminderAPI}?creatorEmail=${encodeURIComponent(data.email)}&paymentLink=${encodeURIComponent(data.paymentLink)}`;
    setSendReminderSpinner((sendReminderSpinner) =>
      sendReminderSpinner.map((state, idx) =>
        idx === index ? AppConstants.trueText : state
      )
    );

    const response = await AuthorizedDataService.getRequestWithResponseCode(
      url,
      '',
      ''
    );

    if (response === 1) {
      setReminderAlertMessage(
        `${SuccessMessage.membershipReminderEmailSentSuccessfully}${AppConstants.space}${
          data.leadType === 'Academy'
            ? data.instituteName
            : `${data.firstName}${AppConstants.space}${data.lastName}`
        }`
      );
      setSendReminderSpinner((sendReminderSpinner) =>
        sendReminderSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setReminderAlertVarient(AppConstants.alertVarient[0]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    } else {
      setReminderAlertMessage(ErrorMessages.membershipReminderError);
      setSendReminderSpinner((sendReminderSpinner) =>
        sendReminderSpinner.map((state, idx) =>
          idx === index ? AppConstants.falseText : state
        )
      );
      setReminderAlertVarient(AppConstants.alertVarient[1]);
      setTimeout(() => {
        setReminderAlertMessage(AppConstants.emptyString);
      }, AppConstants.messageDisappearTime);
    }
  };

  const onBtnExport = useCallback(() => {
    const selectedColumns = [
      'leadId',
      'createdOn',
      'name',
      'skill',
      'phoneNumber',
      'email',
      'leadType',
      'source',
      'listingEnabled',
      'demoDate',
      'leadStatus',
      'leadStatusUpdatedOn',
      'paymentStatus',
      'paymentLinkMailSentOn',
    ];

    const csvData = nonOnboardedRef.current?.api.getDataAsCsv({
      columnKeys: selectedColumns,
      columnSeparator: AppConstants.agGridColumnSeparator,
      suppressQuotes: AppConstants.trueText,
    });

    FileOperationsUtil.downloadCsvDataAsExcel(
      csvData,
      'Non-Onboarded-Leads.xlsx'
    );
  }, []);

  const nonOnboardedColumnDefs = [
    {
      headerName: `Lead ID`,
      field: 'leadId',
      minWidth: 110,
      tooltipValueGetter: ({ data }) => `${data.leadId}`,
      valueGetter: (params) => {
        return params.data.leadId;
      },
    },
    {
      headerName: 'Created On',
      field: 'createdOn',
      width: 300,
      tooltipValueGetter: ({ data }) =>
        data.createdOn
          ? `${moment.utc(data.createdOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.createdOn
          ? `${moment.utc(data.createdOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
    },
    {
      headerName: `Name`,
      field: 'name',
      width: 400,
      tooltipValueGetter: ({ data }) => {
        return data.leadType === AppConstants.leadTypes.expert
          ? `${data.firstName} ${data.lastName}`
          : data.instituteName;
      },
      valueGetter: ({ data }) => {
        return data.leadType === AppConstants.leadTypes.expert
          ? `${data.firstName} ${data.lastName}`
          : data.instituteName;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Skill',
      field: 'skill',
      width: 400,
      tooltipValueGetter: (params) => {
        return getSKillValueString(params.data.skills);
      },
      valueGetter: (params) => {
        return getSKillValueString(params.data.skills);
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: `Mobile No.`,
      field: 'phoneNumber',
      minWidth: 110,
      tooltipValueGetter: ({ data }) =>
        `${data.countryMobileNumberCode}${data.phoneNumber}`,
      valueGetter: ({ data }) => {
        return `${data.countryMobileNumberCode}${data.phoneNumber}`;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: `Email`,
      field: 'email',
      minWidth: 200,
      tooltipValueGetter: ({ data }) => `${data.email}`,
      valueGetter: (params) => {
        return `${params.data.email}`;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Type',
      field: 'leadType',
      width: 400,
      tooltipValueGetter: ({ data }) => `${data.leadType}`,
      valueGetter: (params) => {
        return `${params.data.leadType}`;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Source',
      field: 'source',
      maxWidth: 80,
      tooltipValueGetter: ({ data }) => `${data.source}`,
      valueGetter: (params) => {
        return `${params.data.source}`;
      },
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Listing Enabled',
      field: 'listingEnabled',
      maxWidth: 70,
      tooltipValueGetter: ({ data }) => `${data.listingEnabled}`,
      valueGetter: (params) => {
        return `${params.data.listingEnabled}`;
      },
      filter: 'agTextColumnFilter',
      cellClass: getValidTextClass,
      headerClass: 'multi-line-header',
    },
    {
      headerName: 'Demo Date',
      field: 'demoDate',
      width: 300,
      tooltipValueGetter: ({ data }) =>
        data.demoDate
          ? `${moment.utc(data.demoDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notScheduledText,
      valueGetter: ({ data }) => {
        return data.demoDate
          ? `${moment.utc(data.demoDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notScheduledText;
      },
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
    },
    {
      headerName: 'Lead Status',
      field: 'leadStatus',
      // maxWidth: 140,
      minWidth: 200,
      tooltipValueGetter: ({ data }) => `${data.leadStatus}`,
      valueGetter: (params) => {
        return `${params.data.leadStatus}`;
      },
      filter: 'agTextColumnFilter',
      cellRendererFramework: (params) => {
        return <LeadStatus status={params.value} />;
      },
    },
    {
      headerName: 'Lead Status Date',
      field: 'leadStatusUpdatedOn',
      width: 300,
      tooltipValueGetter: ({ data }) =>
        data.leadStatusUpdatedOn
          ? `${moment.utc(data.leadStatusUpdatedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.leadStatusUpdatedOn
          ? `${moment.utc(data.leadStatusUpdatedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
    },
    {
      headerName: 'Payment Status',
      field: 'paymentStatus',
      minWidth: 120,
      tooltipValueGetter: ({ data }) =>
        data.paymentStatus ? `${data.paymentStatus}` : 'N/A',
      valueGetter: (params) => {
        return params.data.paymentStatus
          ? `${params.data.paymentStatus}`
          : 'N/A';
      },
      filter: 'agTextColumnFilter',
      cellRendererFramework: (params) => {
        return <PaymentStatusChip status={params.value} />;
      },
    },
    {
      headerName: 'Payment Link Sent On',
      field: 'paymentLinkMailSentOn',
      width: 300,
      // minWidth: 180,
      tooltipValueGetter: ({ data }) =>
        data.paymentLinkMailSentOn
          ? `${moment.utc(data.paymentLinkMailSentOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText,
      valueGetter: ({ data }) => {
        return data.paymentLinkMailSentOn
          ? `${moment.utc(data.paymentLinkMailSentOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? '(UTC)' : ''}`
          : AppConstants.notAvailableText;
      },
      filter: 'agTextColumnFilter',
      headerClass: 'multi-line-header',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellStyle: { textAlign: 'center' },
      cellRendererFramework: (params) => (
        <div className={classes.obrGridActionBox}>
          <Button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => toggleViewComponent(params.data)}
            disabled={params.data.isActiveFlag === AppConstants.falseFlag}
          >
            <BsEye />
          </Button>
          <Button
            disabled={
              params.data.listingEnabled !== AppConstants.yText ||
              params.data.listingEnabled ===
                AppConstants.expertObrStatus.statusRejected
            }
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Listing Page Link"
            className={`gridIconButton ${classes.gridIcon}`}
            onClick={() => redirectToListingPage(params.data)}
          >
            <img
              src={getIconPath(icons.brandingPageIconWhite)}
              alt="brandingpage"
              className={classes.imageIcons}
            />
          </Button>
          {params.data.leadType !== AppConstants.leadTypes.expert &&
            params.data.leadType !== AppConstants.leadTypes.academy && (
              <Button
                disabled={
                  submitObrSpinner[params.rowIndex] ||
                  params.obrState ===
                    AppConstants.expertObrStatus.statusRejected
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={
                  params.data.isObrSentFlag === AppConstants.yText
                    ? 'Copy OBR Link'
                    : 'Send OBR'
                }
                onClick={(e) => {
                  params.data.isObrSentFlag === AppConstants.yText
                    ? copyLink(e, params.data, params.rowIndex)
                    : sendOBR(params.data, params.rowIndex);
                }}
                className={`gridIconButton ${classes.gridIcon} ${copyObrLinkSuccess[params.rowIndex] && classes.copySuccess}`}
              >
                {params.data.isObrSentFlag === AppConstants.yText ? (
                  copyObrLinkSuccess[params.rowIndex] ? (
                    <FaCheck />
                  ) : (
                    <FaRegCopy />
                  )
                ) : submitObrSpinner[params.rowIndex] ? (
                  <BiLoaderAlt className="spinner" />
                ) : (
                  <RiShareForwardFill />
                )}
              </Button>
            )}
        </div>
      ),
      minWidth: 100,
    },
    {
      headerName: 'Payment',
      field: 'paymentAction',
      cellStyle: { textAlign: 'center' },
      minWidth: 120,
      cellRendererFramework: (params) => (
        <div className={classes.obrGridActionBox}>
          {params.data.paymentStatus !==
            AppConstants.leadPaymentStatus.Success &&
            (params.data.leadType === AppConstants.leadTypes.academy ||
              params.data.leadType === AppConstants.leadTypes.expert) && (
              <Button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Send Payment Link"
                onClick={(e) => {
                  sendPaymentLinkEmail(params.data, params.rowIndex);
                }}
                className={`gridIconButton ${classes.gridIcon}`}
              >
                {submitObrSpinner[params.rowIndex] ? (
                  <BiLoaderAlt className="spinner" />
                ) : (
                  <FaAmazonPay />
                )}
              </Button>
            )}
          {params.data.paymentStatus !==
            AppConstants.leadPaymentStatus.Success &&
            (params.data.leadType === AppConstants.leadTypes.academy ||
              params.data.leadType === AppConstants.leadTypes.expert) && (
              <Button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Send Reminder to make payment"
                className={`gridIconButton ${classes.gridIcon}`}
                onClick={() =>
                  sendSubscriptionReminderEmail(params.data, params.rowIndex)
                }
              >
                {sendReminderSpinner[params.rowIndex] ? (
                  <BiLoaderAlt className="spinner" />
                ) : (
                  <FaRegClock />
                )}
              </Button>
            )}
        </div>
      ),
    },
  ];

  return (
    <div className={classes.sectionContainer}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="ag-theme-alpine">
            <AgGridReact
              ref={nonOnboardedRef}
              suppressExcelExport={true}
              className="expertObrDetailsGrid"
              columnDefs={nonOnboardedColumnDefs}
              pagination={true}
              paginationPageSize={AppConstants.adminDashboardPaginationSize}
              rowData={props.leadsList || []}
              enableBrowserTooltips={true}
              domLayout="autoHeight"
              suppressRowHoverHighlight={true}
              defaultColDef={{
                filter: true,
                sortable: true,
                flex: 1,
                minWidth: 100,
                resizable: true,
                suppressMenu: true,
                suppressHorizontalScroll: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                floatingFilter: true,
              }}
              enableFilter={true}
              enableSorting={true}
              enableSearching={true}
              headerClass={classes.obrGridHeader}
              rowClass={classes.obrGridTable}
            />
          </div>
        </Col>
        {submitObrMessage && (
          <ErrorSuccessAlertMessage
            message={submitObrMessage}
            varient={alertVarient}
          />
        )}
        {reminderAlertMessage && (
          <ErrorSuccessAlertMessage
            message={reminderAlertMessage}
            varient={reminderAlertVarient}
          />
        )}
      </Row>
      {props.leadsList && props.leadsList?.length > 0 && (
        <Row className="mt-4">
          <div>
            <Button className={classes.exportButton} onClick={onBtnExport}>
              Download
            </Button>
          </div>
        </Row>
      )}
      {showLeadDetailsModal && (
        <LeadDetailsModal
          refreshNonOnboardedLeads={props.refreshNonOnboardedLeads}
          skillDetails={props.skillDetails}
          selectedLead={selectedLead}
          closeLeadDetailsModal={closeLeadDetailsModal}
          stateList={props.stateList}
          countryList={props.countryList}
          onboardedFlag={AppConstants.falseText}
        />
      )}
    </div>
  );
};
